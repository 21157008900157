import { useState, useEffect, FC } from 'react';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { FlexBox } from 'src/components/atoms';
import { Checkbox } from 'src/components/molecules';
import InventoryItem from './InventoryItem';

// Icons
import { NoWarehouseIcon, PlusBlueIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { AddProductInventoryPresentationalProps, InventoryData } from './AddProductInventory.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

// Styles
import './_addProductInventory.scss';

const AddProductInventoryPresentational: FC<AddProductInventoryPresentationalProps> = ({
  inventory,
  setInventory,
  OpenCloseWareHouse,
  CloseWareHouse,
  warehouseList,
  WareHouseSelect,
  addAddInventories,
  changeCheckBox,
  InventoryQuantityValueChange,
  tableData,
  optionChecked,
  optionDone
}) => {
  const [inventoryList, setInventoryList] = useState<InventoryData[]>([]);
  // const [inventoryIDs, setInventoryIDs] = useState<(string | undefined)[]>([]);

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  useEffect(() => {
    const realInventories = inventory?.inventoryData?.filter(
      (item) => item?.inventoryWarehouseId !== undefined && item?.inventoryQuantityValue
    );
    setInventoryList(realInventories);
  }, [inventory.inventoryData]);

  // useEffect(() => {
  //   if (tableData) {
  //     const idGroup = tableData[0]?.warehouseInventory.map((item) => item.warehouseId);
  //     setInventoryIDs(idGroup);
  //   }
  // }, [tableData]);

  const handleAddNewWarehouse = () => {
    window.open('/settings/shop-details', '_blank');
  };

  const handleAddInventory = () => {
    // @ts-ignore
    setInventory((prev) => ({
      ...prev,
      inventoryData: [
        ...prev.inventoryData,
        {
          id: undefined,
          inventoryQuantityValue: 0,
          inventoryWarehouseValue: '',
          isDropDown: true,
          inventoryWarehouseId: undefined
        }
      ]
    }));
  };
  return (
    <div className="card w-100">
      <h4 className="section-title m-b-32">{constVariables.common.inventory.key}</h4>
      <div className="row gx-3">
        {warehouseList &&
          warehouseList?.length > 0 &&
          inventory?.inventoryData &&
          inventory?.inventoryData?.map((invData, invIndex) => (
            <InventoryItem
              invData={invData}
              invIndex={invIndex}
              key={invIndex}
              CloseWareHouse={CloseWareHouse}
              OpenCloseWareHouse={OpenCloseWareHouse}
              warehouseList={warehouseList}
              WareHouseSelect={WareHouseSelect}
              InventoryQuantityValueChange={InventoryQuantityValueChange}
              tableData={tableData}
              optionChecked={optionChecked}
              optionDone={optionDone}
            />
          ))}
      </div>
      <FlexBox className="text-center justify-content-start">
        <button
          className="btn btn-light-primary btn-color-primary btn-active-light-primary text-center mx-0"
          onClick={handleAddInventory}
        >
          <img src={PlusBlueIcon} className="px-2" />
          {constVariables.common.inventory.assignToWarehouse}
        </button>
      </FlexBox>

      {/* {inventoryList && inventoryList.length >= 1 && (
          <button
            onClick={addAddInventories}
            className="d-flex align-items-center justify-content-center btn btn-color-primary btn-active-light-primary  mt-4"
            disabled={inventory.inventoryData.findIndex((data) => !data.inventoryQuantityValue) === -1 ? false : true}
          >
            <img src={PlusBlueIcon} className="me-3" />
            <span className=" fs-6">{constVariables.common.inventory.addWarehouse}</span>
          </button>
        )} */}

      {inventoryList?.length === 0 && warehouseList?.length === 0 && (
        <FlexBox className="flex-column w-100 no-warehouse-section">
          <img src={NoWarehouseIcon} alt="" />
          <p className="poppins-regular">{constVariables.common.inventory.noWarehouse}</p>
          <button type="button" className="btn btn-md btn-primary" onClick={handleAddNewWarehouse}>
            {/* {constVariables.common.inventory.addWarehouse} */}
            Go to the Shop Details page for adding warehouse
          </button>
        </FlexBox>
      )}
      {warehouseList && warehouseList.length > 0 && (
        <div className="m-t-24">
          <Checkbox
            tagclassname="poppins-medium ps-4 final-scale-checkbox"
            name={constVariables.common.inventory.continueSelling}
            value={inventory.continueSellingChecked}
            onChangeValue={changeCheckBox}
            noVerticalPadding
            disable={role === UserRoles.FULFILMENT_ASSOCIATE}
          />
        </div>
      )}
    </div>
  );
};

export default AddProductInventoryPresentational;
