import { FC, useEffect, useState } from 'react';

// Apollo
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { GET_SHIPPING_PROVIDERS } from 'src/apollo/queries';

// Components
import { Loader } from 'src/components/atoms';

// Icons
import { FilledCheckCircle } from 'src/assets/icons';

// ConstVariables
import { constVariables, envUrls } from 'src/constants';
import { InputBox } from 'src/components/molecules';
import useAsymmetricEncryption from 'src/utils/encrypt/useAsymmetricEncryption';
import { CONFIGURE_SHIPSTATION } from 'src/apollo/mutations/configureShipstation';

const Shipstation: FC = () => {
  const { loading: isLoading, data } = useQuery(GET_SHIPPING_PROVIDERS);
  const { cache } = useApolloClient();
  const [configureShipstation, { loading: isConfiguringShipstation }] = useMutation(CONFIGURE_SHIPSTATION);

  const { encrypt } = useAsymmetricEncryption(!data?.getShippingProviders?.isShipStationConnectd && !isLoading);

  const [apiKey, setAPIKey] = useState('');
  const [apiKeySecret, setAPIKeySecret] = useState('');

  useEffect(() => {
    console.log({ cache });
  }, [cache]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const encryptedApikey = await encrypt(apiKey);
    const encryptedApiKeySecret = await encrypt(apiKeySecret);

    await configureShipstation({
      variables: {
        input: {
          apikey: encryptedApikey,
          apiSecret: encryptedApiKeySecret
        }
      }
    });
  };

  useEffect(() => {
    console.log({ ss: data?.getShippingProviders?.isShipStationConnectd });
  }, [data]);

  return (
    <div className="card w-100">
      <h4>Shipstation</h4>
      <div className="mt-10 d-flex align-items-center ">
        {isLoading && <Loader type="page" />}
        {!isLoading &&
          (data?.getShippingProviders?.isShipStationConnectd ? (
            <>
              <img src={FilledCheckCircle} />
              <span className="ms-2 text-success">{constVariables.shipping.shipStation?.accountConnectedMessage}</span>
            </>
          ) : (
            <>
              <div className="modal-body">
                <InputBox
                  title="API Key"
                  value={apiKey}
                  onChangeText={setAPIKey}
                  name="apikey"
                  inputClass="form-control-lg"
                  mainclass="my-2"
                  type="text"
                  placeholder="Enter API key"
                />
                <InputBox
                  title="API Secret"
                  value={apiKeySecret}
                  onChangeText={setAPIKeySecret}
                  name="apiSecret"
                  inputClass="form-control-lg"
                  mainclass="my-2"
                  type="password"
                  placeholder="Enter API Secret"
                />
                <button className="btn btn-primary btn-md" disabled={isConfiguringShipstation} onClick={onSubmit} type="button">
                  {isConfiguringShipstation ? 'Saving' : 'Save'}
                  {isConfiguringShipstation && <Loader type="button" className="h-10px w-10px" />}
                </button>
              </div>
            </>
          ))}
      </div>
    </div>
  );
};

export default Shipstation;
