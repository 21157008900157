import { FC } from 'react';

// Components
import CartProductsTableCell from './CartProductsTableCell';

// Types
import { CartProductItem } from './liveshowCartDetails.types';

interface CartProductsTableProps {
  products: CartProductItem[];
}

const CartProductsTable: FC<CartProductsTableProps> = ({ products }) => {
  return (
    <table
      className="table w-100 cart-details-modal-table"
      style={{
        borderRadius: '10px',
        overflow: 'hidden'
      }}
    >
      <thead className="table-dark-strip">
        <tr>
          <th className="ps-2">Product</th>
          <th>Quantity</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        {products.map((product, index) => (
          <CartProductsTableCell key={index} product={product} />
        ))}
      </tbody>
    </table>
  );
};

export default CartProductsTable;
