/* eslint-disable no-extra-boolean-cast */
import { FC, useEffect, useState } from 'react';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { VideoControllerButton, StopStreamButton, Tooltip } from 'src/components/molecules';
import IvsPlayer from './components/IvsPlayer';

// Hooks && Utils && Helpers
import useWindowDimensions from 'src/utils/UseWindowDimensions/useWindowDimensions';

// Icons
import {
  LiveShowFacebook,
  Microphone,
  MicrophoneDisabled,
  ShopperApp,
  VideoCamera,
  VideoCameraDisabled,
  ActiveMobileIcon,
  ActiveFacebookIcon
} from 'src/assets/icons';

// Types
import { IvsConfigOutput, StoreStreamConfiguratorType } from './LiveshowManager.types';
import { getTimeElapse, pad } from 'src/utils/countdown';
import { useCustomEventListener } from 'react-custom-events';

interface LiveshowFeedDesktopProps {
  liveshowRef: any;
  micMuted: boolean;
  camMuted: boolean;
  handleCameraMute: () => void;
  handleMicMute: () => void;
  showConfirmationModal: () => void;
  isStreamingFromCurrentDevice: boolean;
  liveVideo: IvsConfigOutput;
  liveStartedAt: string;
  storeStreamConfigurator: StoreStreamConfiguratorType;
}

const LiveshowFeedDesktop: FC<LiveshowFeedDesktopProps> = ({
  liveshowRef,
  micMuted,
  camMuted,
  isStreamingFromCurrentDevice,
  handleMicMute,
  handleCameraMute,
  showConfirmationModal,
  liveStartedAt,
  liveVideo,
  storeStreamConfigurator
}) => {
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: 0,
    height: 0
  });
  const [isControlsOverlayVisible, setIsControlsOverlayVisible] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState<string | null>(null);

  const { width, height } = useWindowDimensions();

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const showControlsOverlay = () => {
    setIsControlsOverlayVisible(true);
  };

  const hideControlsOverlay = () => {
    setIsControlsOverlayVisible(false);
  };

  useEffect(() => {
    let tempHeight;
    if (width >= 1280 && width <= 1920) {
      tempHeight = (height / 14 - (width === 1920 ? 7.42 : 3.42)) / 2 - 2.84;
    }
    if (width > 1920 && width <= 2150) {
      tempHeight = (height / 18 - 7.42) / 2 - 2.84;
    }
    if (width > 2150 && width <= 2560) {
      tempHeight = (height / 20 - 7.42) / 2 - 2.84;
    }
    if (width > 2560 && width <= 3050) {
      tempHeight = (height / 25 - 7.42) / 2 - 2.84;
    }
    setCanvasDimensions({
      width: (tempHeight * 9) / 16,
      height: tempHeight
    });
  }, [width, height]);

  useEffect(() => {
    const getElapsedTime = () => {
      if (liveStartedAt) {
        let hours: string | number = '';
        let minutes: string | number = '';
        let seconds: string | number = '';

        setInterval(() => {
          const duration = getTimeElapse(liveStartedAt);

          hours = duration.hours();
          minutes = pad(duration.minutes());
          seconds = pad(duration.seconds());

          if (hours) {
            setTimeElapsed(`${pad(hours)}:${minutes}:${seconds}`);
          } else {
            setTimeElapsed(`${minutes}:${seconds}`);
          }
        }, 1000);
      }
    };

    getElapsedTime();
  }, [liveStartedAt]);

  const [isStartingVideo, setIsStartingVideo] = useState(false);

  useCustomEventListener('addVideoLayerDone', () => {
    setIsStartingVideo(false);
  });

  const getBtnImage = () => {
    // eslint-disable-next-line no-nested-ternary
    return isStartingVideo ? VideoCamera : camMuted ? VideoCameraDisabled : VideoCamera;
  };

  return (
    <div className="livestreaming-container card p-20 h-100 m-0">
      <div className="livevideo-stream-wrapper h-100">
        <div
          className="livevideo-stream-wrapper-inner h-100"
          onMouseEnter={showControlsOverlay}
          onMouseLeave={hideControlsOverlay}
          // style={{ width: `${canvasDimensions.width}rem`, height: `${canvasDimensions.height}rem` }}
        >
          <div className="shopper-app-status-wrapper">
            {timeElapsed && (
              <div className="live-timer">
                <span>{timeElapsed}</span>
              </div>
            )}
            <div className="shopper-app-status">
              <img
                src={!!storeStreamConfigurator?.isLiveOnApp ? ActiveMobileIcon : ShopperApp}
                className="mx-1 cursor-pointer mobile-icon"
                alt="ShopperApp"
                data-tip
                data-for="shopperAppIcon"
              />
              <Tooltip
                tooltipId="shopperAppIcon"
                place="bottom"
                content={() => {
                  return <>{storeStreamConfigurator?.isLiveOnApp ? 'Shopper app connected' : 'Connecting to shopper app'}</>;
                }}
              />
              {storeStreamConfigurator.medialiveChannelState && (
                <>
                  <img
                    src={storeStreamConfigurator.isLiveOnFB ? ActiveFacebookIcon : LiveShowFacebook}
                    className="mx-1 cursor-pointer facebook-icon"
                    alt="facebookIcon"
                    data-tip
                    data-for="facebookIcon"
                  />
                  <Tooltip
                    tooltipId="shopperAppIcon"
                    place="bottom"
                    content={() => {
                      return <>{storeStreamConfigurator?.isLiveOnFB ? 'Facebook connected' : 'Connecting to Facebook'}</>;
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div
            className={`controls-overlay ${
              isControlsOverlayVisible || ((camMuted || micMuted) && isStreamingFromCurrentDevice) ? '' : 'controls-hidden'
            }`}
          >
            {isStreamingFromCurrentDevice && (
              <>
                <VideoControllerButton
                  small
                  isMuted={camMuted && !isStartingVideo}
                  onClick={() => {
                    if (camMuted) {
                      setIsStartingVideo(true);
                    }
                    handleCameraMute();
                  }}
                  image={getBtnImage()}
                />
                <VideoControllerButton
                  small
                  isMuted={micMuted}
                  onClick={handleMicMute}
                  image={micMuted ? MicrophoneDisabled : Microphone}
                />
              </>
            )}
            {role === 'ROLE_OWNER' && <StopStreamButton onClick={showConfirmationModal} />}
          </div>
          {isStartingVideo && (
            <div className="no-video-float-feed-placeholder">
              <span>Starting camera...</span>
            </div>
          )}
          {isStreamingFromCurrentDevice ? (
            <canvas key="STREAM_LIVE_VIDEO" className="live-video-canvas-desktop" id="cam-video-live" ref={liveshowRef}></canvas>
          ) : (
            <IvsPlayer playbackUrl={liveVideo?.isStreaming ? liveVideo?.playbackUrl : ''} showControls={false} />
          )}
        </div>
      </div>
    </div>
  );
};

export default LiveshowFeedDesktop;
