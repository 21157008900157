import React from 'react';
import { FlexBox } from 'src/components/atoms';
import './_stopStreamButton.scss';

interface StopStreamButtonProps {
  onClick: () => void;
}

const StopStreamButton: React.FC<StopStreamButtonProps> = ({ onClick }) => {
  return (
    <FlexBox className="stop-stream-button-wrapper">
      <div className="stop-stream-button cursor-pointer" onClick={onClick}>
        End
      </div>
    </FlexBox>
  );
};

export default StopStreamButton;
