import React, { FC } from 'react';

// Components
import { FlexBox, Img } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { DefaultImage } from 'src/assets/icons';

interface LiveProductVariantRowProps {
  variant: {
    id: string;
    title: string;
    imageUrl: string;
    orders: {
      live: number;
      vod: number;
      total: number;
    };
    price: {
      live: number;
      vod: number;
      total: number;
    };
    revenue: {
      live: number;
      vod: number;
      total: number;
    };
  };
  getSelectValue?: (obj) => number | string;
}

const LiveProductVariantRow: FC<LiveProductVariantRowProps> = (props) => {
  const { variant, getSelectValue } = props;

  return (
    <tr>
      <td className="align-middle w-60px ps-5 pe-2 py-0 table-top-border">
        <div className="form-check form-check-sm form-check-custom" />
      </td>
      <td className="align-middle w-60px px-2 py-0 table-top-border"></td>
      <td className="align-middle w-300px text-muted px-2 py-0 table-top-border">
        <FlexBox className="align-items-center">
          <div className="symbol me-5">
            <Img
              src={setImageSrc(variant?.imageUrl ? generateCompressedImageURL(variant?.imageUrl, '50') : DefaultImage)}
              placeholderImg={DefaultImage}
              errorImg={DefaultImage}
              className="object-fit-scale-down bg-white border border-light border-2"
            />
          </div>
          <FlexBox className="justify-content-start flex-column">
            <span className="d-block main-cell no-decoration">{variant.title}</span>
          </FlexBox>
        </FlexBox>
      </td>
      <td className="align-middle minBoxSize text-muted px-2 py-0 table-top-border" />
      <td className="align-middle minBoxSize text-muted px-2 py-0 table-top-border" />
      <td className="align-middle minMidSize text-muted px-2 py-0 table-top-border">
        <div
          className="d-flex"
          style={{
            flexDirection: 'column'
          }}
        >
          <div>{`${getSelectValue && getSelectValue(variant?.orders)}`}</div>
        </div>
      </td>
      <td className="align-middle minMidSize text-muted px-2 py-0 table-top-border">
        <div
          className="d-flex"
          style={{
            flexDirection: 'column'
          }}
        >
          <div>{`${getSelectValue && getSelectValue(variant?.price)}`}</div>
        </div>
      </td>
      <td className="align-middle minMidSize text-muted px-2 py-0 table-top-border">
        <div
          className="d-flex"
          style={{
            flexDirection: 'column'
          }}
        >
          <div>{`${getSelectValue && getSelectValue(variant?.revenue)}`}</div>
        </div>
      </td>
    </tr>
  );
};

export default React.memo(LiveProductVariantRow);
