import React, { FC, useState, useEffect, createRef, useCallback, useRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { DropdownSelector } from 'src/components/atoms';
import { Portal } from 'src/components/molecules';
import SelectDropdownMenu from './SelectDropdownMenu';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { SearchIcon, CloseIcon } from 'src/assets/icons';

// Types
import { SearchSelectDropdownProps } from './SearchSelectDropdown.types';

const SearchSelectDropdown: FC<SearchSelectDropdownProps> = ({
  type,
  hideTitle,
  options,
  handleAddOption,
  handleUpdateOption,
  handleDeleteOption,
  optionItemId,
  setOptionItemId,
  isControllable,
  handleAddItemLink,
  isUseOutsideConfirmModal,
  handleShowDeleteConfirmModal,
  setSelectedOptionItem,
  inputPlaceholderText,
  isControllableDirection,
  className,
  isDisableSelector
}) => {
  const containerRef = createRef<any>();
  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(false);
  const [isShowSearchInput, setIsShowSearchInput] = useState(false);
  const [selectedOptionName, setSelectedOptionName] = useState<string | undefined>('');
  const [searchText, setSearchText] = useState<string>('');
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0,
    width: 0
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchText(value);
  };

  const closeDropdown = () => {
    setIsShowDropdown(false);
    setIsShowSearchInput(false);
  };

  useEffect(() => {
    setSelectedOptionName(options.find((option) => option.id === optionItemId)?.name);
  }, [optionItemId, options]);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      closeDropdown();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const filteredData =
        (options.length > 0 && options.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()))) || options;
      if (filteredData.length > 0) {
        return;
      } else {
        handleAddOption && handleAddOption(searchText);
        setSelectedOptionName(searchText);
        closeDropdown();
        setSearchText('');
      }
    }
  };

  const handleShowInputAndDropdown = (e) => {
    e.stopPropagation();
    setIsShowSearchInput(true);
    setIsShowDropdown(true);

    if (isControllableDirection && containerRef.current) {
      const position = containerRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropdownPositions({
        top: spaceBelow < 320 ? window.scrollY + position.top - 325 : window.scrollY + position.top + position.height,
        left: position.left,
        width: position.width
      });
    }
  };

  const lowercasedFilter = searchText.toLowerCase();
  const [keyboardSelectIndex, setKeyboardSelectIndex] = useState(-1);

  function isFiltered(element: { id: number; name: string }, index: number, array: Array<{ id: number; name: string }>) {
    return element.name.toLowerCase().includes(lowercasedFilter);
  }
  const handleKeyDownSelect = (event) => {
    if (isShowDropdown) {
      if (event.key === 'ArrowDown' && keyboardSelectIndex < options.length - 1) {
        setKeyboardSelectIndex((i) => i + 1);
      } else if (event.key === 'ArrowUp' && keyboardSelectIndex >= 0) {
        setKeyboardSelectIndex((i) => i - 1);
      } else if (event.key === 'Enter' && keyboardSelectIndex >= 0) {
        // If Enter key is pressed, select the highlighted option
        const filteredData = (options.length > 0 && options.filter(isFiltered)) || options;
        if (keyboardSelectIndex === undefined) {
          return;
        }
        const option = filteredData[keyboardSelectIndex];
        setOptionItemId && setOptionItemId(option.id);
        setSelectedOptionName && setSelectedOptionName(option.name);
        setSearchText('');
        closeDropdown();
      }
    }
  };

  useEffect(() => {
    if (isShowDropdown) {
      window.addEventListener('keydown', handleKeyDownSelect);
    } else {
      window.removeEventListener('keydown', handleKeyDownSelect);
    }

    // Cleanup the event listener when component unmounts or dropdown closes
    return () => window.removeEventListener('keydown', handleKeyDownSelect);
  }, [isShowDropdown, keyboardSelectIndex]);

  return (
    <div className={`position-relative ${type ? 'product-types-section m-t-24' : ''}`}>
      {!hideTitle && <div className="text-nowrap input-title">{type}</div>}
      <div ref={containerRef}>
        {isShowSearchInput ? (
          <div
            className={`d-flex align-items-center search-input form-control form-control-lg position-relative ${
              isShowDropdown ? 'dropdown-box-active' : ''
            }`}
          >
            <input
              autoComplete="off"
              type="text"
              className="border-none w-100"
              value={searchText}
              name="search"
              onChange={handleSearch}
              onKeyDown={(e) => handleKeyDown(e)}
              autoFocus
            />
            <KTSVG
              path={SearchIcon}
              className="svg-icon-2 svg-icon-gray-500 position-absolute top-50 translate-middle-y search-icon"
            />
            <KTSVG
              path={CloseIcon}
              className="svg-icon-2 svg-icon-gray-500 position-absolute top-50 close-icon translate-middle-y cursor-pointer"
              onClick={() => setSearchText('')}
            />
          </div>
        ) : (
          <DropdownSelector
            className={`form-control form-control-lg px-5 justify-content-between align-items-center cursor-pointer dropdown-box ${
              isShowDropdown ? 'dropdown-box-active' : ''
            } ${isDisableSelector ? 'disable-background' : ''} `}
            onClick={handleShowInputAndDropdown}
            selectedValue={selectedOptionName}
            text={inputPlaceholderText ? inputPlaceholderText : `Select ${type}`}
          />
        )}
      </div>
      {isControllableDirection && isShowDropdown ? (
        <Portal id="kt_body">
          <OutsideClickHandler onOutsideClick={closeDropdown}>
            <div
              className="position-absolute"
              style={{ top: dropdownPositions.top, left: dropdownPositions.left, width: dropdownPositions.width }}
            >
              <SelectDropdownMenu
                type={type}
                options={options}
                isVisibleDropdown={isShowDropdown}
                setOptionItemId={setOptionItemId}
                closeDropdown={closeDropdown}
                handleDeleteOption={handleDeleteOption}
                searchText={searchText}
                setSearchText={setSearchText}
                handleUpdateOption={handleUpdateOption}
                isControllable={isControllable}
                handleAddItemLink={handleAddItemLink}
                setSelectedOptionName={setSelectedOptionName}
                isUseOutsideConfirmModal={isUseOutsideConfirmModal}
                handleShowDeleteConfirmModal={handleShowDeleteConfirmModal}
                setSelectedOptionItem={setSelectedOptionItem}
                className={className}
                keyboardSelectIndex={keyboardSelectIndex}
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      ) : (
        <OutsideClickHandler onOutsideClick={closeDropdown}>
          <SelectDropdownMenu
            type={type}
            options={options}
            isVisibleDropdown={isShowDropdown}
            setOptionItemId={setOptionItemId}
            closeDropdown={closeDropdown}
            handleDeleteOption={handleDeleteOption}
            searchText={searchText}
            setSearchText={setSearchText}
            handleUpdateOption={handleUpdateOption}
            isControllable={isControllable}
            handleAddItemLink={handleAddItemLink}
            setSelectedOptionName={setSelectedOptionName}
            isUseOutsideConfirmModal={isUseOutsideConfirmModal}
            handleShowDeleteConfirmModal={handleShowDeleteConfirmModal}
            setSelectedOptionItem={setSelectedOptionItem}
            className={className}
            keyboardSelectIndex={keyboardSelectIndex}
          />
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default SearchSelectDropdown;
