import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { GET_PUBLIC_ENCRYPTION_KEY } from 'src/apollo/queries/getEncryptionKey';

interface IUseAsymmetricEncryption {
  publicKey?: string;
  encrypt: (input: string) => Promise<string>;
  decrypt: (input: string) => Promise<string>;
}

const useAsymmetricEncryption = (isEncrypt: boolean = false, isDecrypt: boolean = false): IUseAsymmetricEncryption => {
  const [getPublicKey, { data }] = useLazyQuery(GET_PUBLIC_ENCRYPTION_KEY);
  const [keyPair, setKeyPair] = useState<CryptoKeyPair>();
  const [publicKey, setPublicKey] = useState<string>();

  useEffect(() => {
    if (isEncrypt && !data) {
      getPublicKey();
    }
  }, [isEncrypt]);

  useEffect(() => {
    if (!keyPair && isDecrypt) {
      generateKeyPair();
    }
  }, [isDecrypt]);

  const generateKeyPair = useCallback(async () => {
    try {
      const kp = await window.crypto.subtle.generateKey(
        {
          name: 'RSA-OAEP', // Algorithm name
          modulusLength: 2048, // Key size (2048 bits is a good balance)
          publicExponent: new Uint8Array([1, 0, 1]), // Standard public exponent
          hash: { name: 'SHA-256' } // Hash function for RSA-OAEP
        },
        true, // Whether the key is extractable (i.e. can be exported)
        ['encrypt', 'decrypt'] // Key usages
      );

      setKeyPair(kp);

      const exported = await window.crypto.subtle.exportKey('spki', kp.publicKey);
      const binary = Array.from(new Uint8Array(exported))
        .map((b) => String.fromCharCode(b))
        .join('');
      const pubKey = window.btoa(binary);
      setPublicKey(pubKey);
    } catch (err) {
      console.error('Error generating key pair:', err);
    }
  }, []);

  const encrypt = async (input: string) => {
    try {
      let publicKmsKey = data?.getEncryptionKey;

      if (!publicKmsKey) {
        const res = await getPublicKey();
        publicKmsKey = res?.data?.getEncryptionKey;
      }

      const binaryDerString = window.atob(publicKmsKey);
      const binaryDer = new Uint8Array([...binaryDerString].map((char) => char.charCodeAt(0)));
      const publicKey = await window.crypto.subtle.importKey(
        'spki',
        binaryDer.buffer,
        {
          name: 'RSA-OAEP',
          hash: 'SHA-256'
        },
        true,
        ['encrypt']
      );
      const encodedData = new TextEncoder().encode(input);
      const encryptedData = await window.crypto.subtle.encrypt(
        {
          name: 'RSA-OAEP'
        },
        publicKey,
        encodedData
      );
      const encryptedArray = new Uint8Array(encryptedData);
      const encryptedBase64 = window.btoa(String.fromCharCode(...encryptedArray));
      return encryptedBase64;
    } catch (err) {
      console.error('Error encrypting data', err);
      return input;
    }
  };

  const decrypt = async (base64Data: string) => {
    try {
      const encryptedData = Uint8Array.from(Buffer.from(base64Data, 'base64'));
      const decrypted = await window.crypto.subtle.decrypt(
        {
          name: 'RSA-OAEP',
          hash: { name: 'SHA-256' } as Algorithm
        } as RsaOaepParams,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        keyPair?.privateKey!,
        encryptedData
      );

      const decodedData = new TextDecoder().decode(decrypted);
      return decodedData;
    } catch (err) {
      console.error('Error decrypting data:', err);
      return '';
    }
  };

  return {
    encrypt,
    decrypt,
    publicKey
  };
};

export default useAsymmetricEncryption;
