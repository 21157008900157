import { useQuery } from '@apollo/client';
import { TOGGLE_SEZZLE } from 'src/apollo/mutations';
import {
  GET_APP_THEME,
  GET_AVALARA_CONFIGURATION,
  GET_LOGGEDIN_FB_PROFILE,
  GET_ROLES,
  GET_SHIPPING_PROVIDERS,
  GET_SHOPIFY_ADMIN_BASE_URL,
  GET_STORE_DETAILS,
  GET_STORE_USERS,
  GET_TIKTOK_STORE,
  GET_WAREHOUSE,
  SEZZLE_CONNECTION_STATUS
} from 'src/apollo/queries';
import { GET_CONNECTION_STATUS } from 'src/apollo/queries/getConnectionStatus';
import { GET_SHIPPING_SETTINGS } from 'src/apollo/queries/getShippingSettings';
import { STRIPE_CONNECTION_STATUS } from 'src/apollo/queries/getStripeConnectionStatus';

const useSettingsCacher = () => {
  useQuery(GET_CONNECTION_STATUS);
  useQuery(GET_TIKTOK_STORE);
  useQuery(GET_APP_THEME);
  useQuery(GET_STORE_DETAILS);
  useQuery(SEZZLE_CONNECTION_STATUS);
  useQuery(STRIPE_CONNECTION_STATUS);
  useQuery(GET_SHIPPING_PROVIDERS);
  useQuery(GET_SHIPPING_SETTINGS);
  useQuery(GET_SHIPPING_SETTINGS);
  useQuery(GET_SHOPIFY_ADMIN_BASE_URL);
  useQuery(GET_AVALARA_CONFIGURATION);
  useQuery(GET_ROLES);
  useQuery(GET_WAREHOUSE, {
    variables: {
      input: {
        pageInfo: { skipCount: 0, limitCount: 50 },
        status: 'ALL'
      }
    }
  });
  useQuery(GET_LOGGEDIN_FB_PROFILE);
  useQuery(GET_STORE_USERS, {
    variables: {
      input: { filters: { searchText: '' }, sortBy: { fieldID: 'NAME', type: 'ASC' } }
    }
  });
  useQuery(GET_ROLES);

  return {};
};

export default useSettingsCacher;
