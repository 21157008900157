import { ReactElement } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router';

// Components
import { MasterLayout } from 'src/components/MasterLayout';
import { LayoutProvider } from '../components/core';
import {
  ProductPage,
  Signup,
  Login,
  CreateStore,
  VendorManagement,
  WarehouseManagement,
  Shipping,
  AllOrdersPage,
  Taxes,
  SettingsScreen,
  CreateOrder,
  FulfillmentCenter,
  ShippoRedirection,
  CatalogPage,
  CustomerDetails,
  AddCatalog,
  Customer,
  InventoryManagementPage,
  WaitlistsPage,
  AddProduct,
  AllPurchaseOrder,
  VendorDetails,
  ProductCategory,
  CreateCollection,
  Payments,
  OrderDetails,
  LiveShowCalendar,
  LiveShowReport,
  FeaturedProducts,
  AllCouponsPage,
  CreateCoupon,
  LiveshowManager,
  ResetPassword,
  VerifyEmail,
  ForgotPassword,
  VerifyEmailToken,
  SalesChannels,
  ShopDetails,
  OrderSettings,
  UserPermissions,
  AppCustomizer,
  InviteSignup,
  HomePage,
  Account,
  ShopifyIntegrations,
  ShopifyCallback,
  TikTokCallback,
  TiktokIntegration
} from 'src/components/pages';
import { Hamburger } from 'src/components/atoms';
import { OrdersPrint } from 'src/components/oraganisms';

// ConstVariables
import { ROUTES } from 'src/constants/routes';
import VerifyChangeEmail from 'src/components/pages/auth/VerifyChangeEmailToken/VerifyChangeEmailToken';

// Types
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';
import useWarmupApiOnNavigation from 'src/utils/hooks/usePrefetchOnNavigation';

const RestrictedRoute = ({ component: Component, ...rest }: any) => {
  const history = useHistory();
  useWarmupApiOnNavigation(rest?.path);

  const token = localStorage.getItem('token');
  const storeId = localStorage.getItem('storeId');
  const userId = localStorage.getItem('userId');
  const userRole = localStorage.getItem('userRole');
  if (!token || !storeId || !userId || !userRole) {
    history.replace('/login');
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/signup/',
                state: { from: props.location }
              }}
            />
          );
        }
      }}
    />
  );
};

const PublicRoute = ({ component: Component, path, ...rest }: any) => {
  const history = useHistory();
  const token = localStorage.getItem('token');
  const storeId = localStorage.getItem('storeId');
  const userId = localStorage.getItem('userId');
  const userRole = localStorage.getItem('userRole');
  if (path === ROUTES.authentication.signup.verifyScreen || path === ROUTES.Shopify.auth || path === ROUTES.TikTok.auth) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return <Component {...props} />;
        }}
      />
    );
  } else if (token && storeId && userId && userRole) {
    if (userRole === UserRoles.OWNER || userRole === UserRoles.ADMIN) {
      history.replace(ROUTES.home.main);
    } else if (userRole === UserRoles.ADMIN_ASSOCIATE) {
      history.replace(ROUTES.products.allProducts.main);
    } else if (userRole === UserRoles.WAREHOUSE_MANAGER) {
      history.replace(ROUTES.products.inventory.main);
    } else if (userRole === UserRoles.FULFILMENT_ASSOCIATE) {
      history.replace(ROUTES.orders.allOrders.fulfillmentCenter);
    } else if (userRole === UserRoles.WAREHOUSE_ASSOCIATE) {
      history.replace(ROUTES.products.inventory.main);
    }
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};

export const Navigation = (): ReactElement => {
  return (
    <LayoutProvider>
      <Switch>
        {/* Signup Routes */}
        <PublicRoute exact path={ROUTES.authentication.signup.changeEmail} component={VerifyChangeEmail} />
        <PublicRoute exact path={ROUTES.authentication.signup.resetPassword} component={ResetPassword} />
        <PublicRoute exact path={ROUTES.authentication.signup.forgotPassword} component={ForgotPassword} />
        <PublicRoute exact path={ROUTES.Shopify.auth} component={ShopifyCallback} />
        <PublicRoute exact path={ROUTES.TikTok.auth} component={TikTokCallback} />
        <PublicRoute exact path={`/`} component={Login} />
        <PublicRoute exact path={`/login`} component={Login} />
        <PublicRoute exact path={ROUTES.authentication.signup.main} component={Signup} />
        <PublicRoute exact path={ROUTES.authentication.signup.storeCreation} component={CreateStore} />
        <PublicRoute exact path={ROUTES.authentication.signup.resetPassword} component={ResetPassword} />
        <PublicRoute exact path={ROUTES.authentication.signup.verifyScreen} component={VerifyEmail} />
        <PublicRoute exact path={ROUTES.authentication.signup.verifyEmail} component={VerifyEmailToken} />
        <PublicRoute exact path={ROUTES.authentication.signup.inviteSignup} component={InviteSignup} />

        <MasterLayout>
          <Switch>
            {/* Home Routes */}
            <RestrictedRoute exact path={ROUTES.home.main} component={HomePage} />

            {/* User Account Routes  */}
            <RestrictedRoute exact path={ROUTES.Account.main} component={Account} />
            {/* Products Routes */}
            <RestrictedRoute path={ROUTES.products.main}>
              <RestrictedRoute exact path={ROUTES.products.allProducts.main} component={ProductPage} />
              <RestrictedRoute exact path={ROUTES.products.allProducts.addProduct} component={AddProduct} />
              <RestrictedRoute exact path={ROUTES.products.inventory.main} component={InventoryManagementPage} />
              <RestrictedRoute
                exact
                path={`${ROUTES.products.productCategory.collections.editCollections}:collectionId`}
                component={CreateCollection}
              />
              <RestrictedRoute
                exact
                path={ROUTES.products.productCategory.collections.createCollection}
                component={CreateCollection}
              />
              <RestrictedRoute
                exact
                path={ROUTES.products.productCategory.collections.allCollections}
                component={ProductCategory}
              />
              <RestrictedRoute exact path={`${ROUTES.products.allProducts.editProduct}:productId`} component={AddProduct} />
            </RestrictedRoute>

            {/* LiveShow Routes */}
            <RestrictedRoute path={ROUTES.liveShow.main}>
              <RestrictedRoute exact path={ROUTES.liveShow.calendar.main} component={LiveShowCalendar} />
              <RestrictedRoute exact path={ROUTES.liveShow.calendar.search} component={LiveShowCalendar} />
              <RestrictedRoute exact path={ROUTES.liveShow.management.main} component={LiveshowManager} />
              <RestrictedRoute exact path={`${ROUTES.liveShow.report.main}:liveId`} component={LiveShowReport} />
            </RestrictedRoute>

            {/* Vendor Management Routes */}
            <RestrictedRoute path={ROUTES.vendorManagement.main}>
              <RestrictedRoute exact path={ROUTES.vendorManagement.vendors.main} component={VendorManagement} />
              <RestrictedRoute exact path={`${ROUTES.vendorManagement.vendors.vendorDetail}:vendorId`} component={VendorDetails} />
              <RestrictedRoute exact path={ROUTES.vendorManagement.purchaseOrders.main} component={AllPurchaseOrder} />
            </RestrictedRoute>

            {/* Customers Routes */}
            <RestrictedRoute path={ROUTES.customers.main}>
              <RestrictedRoute exact path={ROUTES.customers.allCustomers.main} component={Customer} />
              <RestrictedRoute
                exact
                path={`${ROUTES.customers.allCustomers.customerDetails}/:customerId`}
                component={CustomerDetails}
              />
            </RestrictedRoute>

            {/* Orders Routes */}
            <RestrictedRoute path={ROUTES.orders.main}>
              <RestrictedRoute exact path={ROUTES.orders.allOrders.main} component={AllOrdersPage} />
              <RestrictedRoute exact path={ROUTES.orders.allOrders.fulfillmentCenter} component={FulfillmentCenter} />
              <RestrictedRoute exact path={ROUTES.orders.allOrders.waitlists} component={WaitlistsPage} />
              <RestrictedRoute exact path={`${ROUTES.orders.allOrders.orderDetails}/:orderId`} component={OrderDetails} />
              <RestrictedRoute exact path={ROUTES.orders.allOrders.createOrder} component={CreateOrder} />
              <RestrictedRoute exact path={ROUTES.orders.allOrders.printOrder} component={OrdersPrint} />
            </RestrictedRoute>

            {/* Marketing Routes */}
            <RestrictedRoute path={ROUTES.marketing.main}>
              <RestrictedRoute exact path={ROUTES.marketing.catalogs.allCatalogs} component={CatalogPage} />
              <RestrictedRoute exact path={ROUTES.marketing.catalogs.addCatalog} component={AddCatalog} />
              <RestrictedRoute exact path={ROUTES.marketing.featuredProducts.main} component={FeaturedProducts} />
              <RestrictedRoute exact path={`${ROUTES.marketing.catalogs.editCatalog}/:catalogId`} component={AddCatalog} />
              <RestrictedRoute exact path={ROUTES.marketing.tiktokIntegration.main} component={TiktokIntegration} />
            </RestrictedRoute>
            <RestrictedRoute path={ROUTES.coupons.main}>
              <RestrictedRoute exact path={ROUTES.coupons.allCoupons.main} component={AllCouponsPage} />
              <RestrictedRoute exact path={`${ROUTES.coupons.createCoupon.main}`} component={CreateCoupon} />
              <RestrictedRoute exact path={`${ROUTES.coupons.createCoupon.main}/:couponId`} component={CreateCoupon} />
            </RestrictedRoute>

            {/* Settings Routes */}
            <RestrictedRoute exact path={ROUTES.settings.main} component={SettingsScreen} />
            <RestrictedRoute exact path={ROUTES.settings.shopDetails.main} component={ShopDetails} />
            <RestrictedRoute exact path={ROUTES.settings.warehouse.main} component={WarehouseManagement} />
            <RestrictedRoute exact path={ROUTES.settings.shipping.main} component={Shipping} />
            <RestrictedRoute exact path={ROUTES.settings.payments.main} component={Payments} />
            <RestrictedRoute exact path={ROUTES.settings.taxes.main} component={Taxes} />
            <RestrictedRoute exact path={ROUTES.settings.shippoOnboard.main} component={ShippoRedirection} />
            <RestrictedRoute exact path={ROUTES.settings.salesChannels.main} component={SalesChannels} />
            <RestrictedRoute exact path={ROUTES.settings.orders.main} component={OrderSettings} />
            <RestrictedRoute exact path={ROUTES.settings.userPermissions.main} component={UserPermissions} />
            <RestrictedRoute exact path={ROUTES.settings.appCustomizer.main} component={AppCustomizer} />
            <RestrictedRoute exact path={ROUTES.settings.integrations.main} component={ShopifyIntegrations} />
            <RestrictedRoute path={'*'} component={Hamburger} />
          </Switch>
        </MasterLayout>
      </Switch>
    </LayoutProvider>
  );
};
