import {
  ClipboardIcon,
  CoinIcon,
  LocationIcon,
  ShopIcon,
  TreeStructureIcon,
  TruckIcon,
  OrderSettingsIcon,
  UserCircleIcon,
  ScannerIcon,
  IntegrationsIcon,
  WebstoreCmsIcon
  // BackSpaceIcon,
  // CrownIconGrey,
  // EyeIcon,
  // ScaleIcon,
  // TwoPeopleIcon,
} from 'src/assets/icons';
// import { ROUTES } from 'src/constants';

//                 <AsideMenuSubItem to="/settings/sezzle/connect" subMenuItem={constVariables.SideMenuBar.sezzleConnect} />

const SettingsScreenData = [
  {
    icon: ShopIcon,
    heading: 'Shop Details',
    description: `Manage your store's name, address, and other key information.`,
    pageLink: '/settings/shop-details',
    isAccessFree: true
  },
  // {
  //   icon: CrownIconGrey,
  //   heading: 'Plan',
  //   description: 'Contrary to popular belief, Lorem Ipsum is not.',
  //   pageLink: undefined
  // },
  // {
  //   icon: TwoPeopleIcon,
  //   heading: 'Users and Permissions',
  //   description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  //   pageLink: undefined
  // },
  // {
  //   icon: ScannerIcon,
  //   heading: 'Size Guide',
  //   description: 'Contrary to popular belief, Lorem Ipsum is not.',
  //   pageLink: undefined
  // },
  {
    id: 2,
    icon: CoinIcon,
    heading: 'Payments',
    description: 'Configure and manage your payment methods and settings.',
    pageLink: '/settings/payments',
    isAccessFree: false
  },
  {
    id: 3,
    icon: TruckIcon,
    heading: 'Shipping',
    description: 'Set up and manage your shipping options and rates.',
    pageLink: '/settings/shipping',
    isAccessFree: false
  },
  {
    icon: ClipboardIcon,
    heading: 'Taxes',
    description: 'View and update your store’s tax information and settings.',
    pageLink: '/settings/taxes',
    isAccessFree: false
  },
  {
    icon: LocationIcon,
    heading: 'Warehouses',
    description: 'Manage and track your inventory across multiple warehouse locations.',
    pageLink: 'settings/warehouses',
    isAccessFree: true
  },
  {
    icon: TreeStructureIcon,
    heading: 'Sales Channels',
    description: 'Integrate and manage various sales channels for your store.',
    pageLink: 'settings/sales-channels',
    isAccessFree: false
  },
  {
    icon: OrderSettingsIcon,
    heading: 'Orders',
    description: 'Review and manage orders settings.',
    pageLink: 'settings/orders',
    isAccessFree: true
  },
  {
    icon: UserCircleIcon,
    heading: 'Users & Permissions',
    description: 'Manage user roles, permissions, and access levels.',
    pageLink: 'settings/user-permissions',
    isAccessFree: true
  },
  {
    icon: ScannerIcon,
    heading: 'App Customizer',
    description: 'Customize the look and feel of your store’s application.',
    pageLink: 'settings/app-customizer',
    isAccessFree: true
  },
  {
    icon: WebstoreCmsIcon,
    heading: 'Webstore Customizer',
    description: 'Customize the look and feel of your webstore’s application.',
    pageLink: 'https://tina-cloud-starter-kappa-eight-29.vercel.app/admin#/~',
    isAccessFree: true,
    isExternalLink: true
  },
  {
    icon: IntegrationsIcon,
    heading: 'Integrations',
    description: 'Connect and manage third-party integrations for your store.',
    pageLink: 'settings/integrations',
    isAccessFree: false
  }
  // {
  //   icon: BackSpaceIcon,
  //   heading: 'Returns',
  //   description: 'Contrary to popular belief, Lorem Ipsum is not.',
  //   pageLink: '/settings/return'
  // },
  // {
  //   icon: ScaleIcon,
  //   heading: 'Legal',
  //   description: 'Contrary to popular belief, Lorem Ipsum is not.',
  //   pageLink: undefined
  // }
];

export default SettingsScreenData;
