import { useQuery } from '@apollo/client';
import { GET_TIKTOK_PRODUCTS } from 'src/apollo/queries';
import { ROUTES } from 'src/constants';

const useWarmupApiOnNavigation = (path: string) => {
  useQuery(GET_TIKTOK_PRODUCTS, {
    variables: {
      input: {
        status: ['ACTIVE'],
        searchString: '',
        pageInfo: {
          skipCount: 0,
          limitCount: 50
        }
      }
    },
    skip: path !== ROUTES.marketing.main
  });

  return {};
};

export default useWarmupApiOnNavigation;
