import { FC, useState, useReducer } from 'react';
import { useHistory } from 'react-router';

// Apollo
import { useMutation } from '@apollo/client';
import { SIGN_UP_BACK_STORE } from 'src/apollo/mutations';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';
import { validateStoreName } from 'src/utils/validate';
import { getAccountDetailsFromToken } from 'src/utils/authentication/decodeToken';
import { refreshToken } from 'src/helpers/InitFirebase';
import { setStoreIdToOSPlayer } from 'src/services/pushnotification/DataTags';

// Icons && Images
import { SignUpBySocial } from 'src/assets/images';

// constVariables
import { constVariables, envUrls } from 'src/constants';

// Styles
import 'src/components/oraganisms/CustomModal/modalStyles.scss';
import 'src/components/pages/auth/Signup/_signUp.scss';

const CreateStore: FC = (props) => {
  const { showToast } = useToast();

  const [storeName, setStoreName] = useState('');
  const [checkValidation, setCheckValidation] = useState(false);
  const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), {
    isLoading: false,
    storeName: {
      value: '',
      validateOnChange: true,
      error: ''
    }
  });

  const history = useHistory();
  if (!history?.location?.state?.detail) {
    history.goBack();
  }
  const { email, fullName, medium, profileUrl } = history?.location?.state?.detail;

  const [signupBackstoreUser] = useMutation(SIGN_UP_BACK_STORE, {
    onCompleted: async (data) => {
      const idToken = await refreshToken();
      localStorage.setItem('token', idToken);

      const accountInfo = getAccountDetailsFromToken(state.token);
      localStorage.setItem('storeId', accountInfo.storeId);
      localStorage.setItem('userId', accountInfo.userId);
      localStorage.setItem('firebaseUserId', accountInfo.user_id);
      localStorage.setItem('name', accountInfo.name);
      localStorage.setItem('picture', accountInfo.picture);
      localStorage.setItem('email', accountInfo.email);

      // TODO: Add error handling for setStoreIdToOSPlayer onError
      // await setStoreIdToOSPlayer(accountInfo.storeId);

      setState({
        isLoading: false
      });
      history.replace('/products');
    },
    onError: (e) => {
      const error: any = e?.graphQLErrors[0];
      if (error?.errorType === 'STORE_ALREADY_EXIST') {
        showToast({
          title: `Error`,
          message: error?.message
        });
      } else {
        showToast({
          title: 'Error',
          message: 'Something went wrong. Please try again later.'
        });
      }
      setState({
        isLoading: false
      });
      history.replace('/');
    }
  });

  const createStore = (userData: any) => {
    const storeNameError = validateStoreName(storeName);

    if ([storeNameError].every((e) => e === false)) {
      setState({
        isLoading: true,
        storeName: {
          ...state.storeName,
          validateOnChange: true,
          error: ''
        }
      });

      const { email, fullName, medium, profileUrl } = userData;
      const token = localStorage.getItem('token');

      signupBackstoreUser({
        context: {
          headers: {
            Authorization: token,
            'x-api-key': envUrls.authXApiKey,
            tenantid: envUrls.firebaseTenantId
          }
        },
        variables: {
          input: {
            email,
            fullName,
            medium,
            storeName,
            profileUrl
          }
        }
      });
    } else {
      setState({
        storeName: {
          ...state.storeName,
          validateOnChange: true,
          error: storeNameError
        }
      });
    }
  };

  return (
    <>
      <div className="signup d-flex gradient flex-row ">
        {/* Left panel */}
        <div className="form-container padding-style">
          <div className={`p-6rem container-height container-width overflow-hidden bg-white content-container content-wrapper`}>
            <img src={SignUpBySocial} alt={SignUpBySocial} className="image-dimension" />
            <div>
              <div className="mt-50px">
                <div className="title-style">
                  <p>{constVariables.signUp.createStore} </p>
                  <div className="fs-16px loggedin-message poppins-regular">
                    <p className="m-0 sub-title-style">
                      {constVariables.verifyEmail.linkMessage}
                      <span className="text-primary cursor-pointer poppins-regular">{email || ''}</span>
                    </p>
                  </div>
                </div>
                <div className="mt-30px d-flex flex-column create-store-input-container">
                  <div className="fs-14px">
                    {constVariables.storeCreation.storeName}{' '}
                    <span className="loggedin-message poppins-regular">{constVariables.signUp.changeLater}</span>
                  </div>
                  <input
                    autoComplete="off"
                    className={`form-control form-control-solid hp-50px ${
                      state.storeName.error.trim().length ? 'red-border' : 'grey-border'
                    }`}
                    id="storeName"
                    name="storeName"
                    value={storeName}
                    onChange={(e) => {
                      setStoreName(e.target.value);
                    }}
                    type="text"
                    placeholder="Enter store name"
                  />
                  <div className={`text-danger fs-8 ${state.storeName.error.trim().length ? '' : 'visibility-hidden'}`}>
                    {state.storeName.error}
                  </div>
                </div>
                <div>
                  <button
                    disabled={state.isLoading}
                    className="btn btn-primary w-100 button-padding flex-center"
                    onClick={() => {
                      setCheckValidation(true);
                      if (storeName) {
                        createStore({ email, fullName, medium, profileUrl });
                      }
                    }}
                  >
                    {constVariables.signUp.createStore}
                    {state.isLoading && <div className="spinner-border text-primary"></div>}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateStore;
