import React, { FC, useState, useEffect, createRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useMutation } from '@apollo/client';
import { MODIFY_WAREHOUSE } from 'src/apollo/mutations';

// Components
import { FlexBox } from 'src/components/atoms';
import { Dropdown, Portal, SearchBar, SortDropdown, DataTable } from 'src/components/molecules';
import { CustomModal, DeleteAndMoveWarehouseModal } from 'src/components/oraganisms';
import ActionMenu from './components/ActionMenu';
import SortableTableHeader from 'src/components/molecules/DataTable/components/SortableTableHeader';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers/components/KTSVG';

// Icons
import { BookmarkIcon, DownArrowIcon, FilterIcon, SortIcon, WarehouseBuilding } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';
import { SortIds } from './data';

// Types
import { DropdownOptions, WarehouseListProps } from './WarehouseList.types';
import { useToast } from '../../../utils/hooks/useToast';
import MoveWarehouseModal from '../CustomModal/MoveWarehouseModal';
const WarehouseStatusCell = ({ rowData, runChangeProductStatus }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const statusRef = React.createRef<any>();
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0
  });

  const selectStatusClass = (rowData) => {
    return rowData.isActive ? 'active' : 'inactive';
  };

  const handleStatus = () => {
    setShowDropdown(true);

    if (statusRef.current) {
      const position = statusRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropdownPositions({
        top: spaceBelow < 180 ? window.scrollY + position.top - 170 : window.scrollY + position.top - 20,
        left: position.left - 30
      });
    }
  };

  return (
    <>
      <div>
        <FlexBox
          className={`p-2 poppins-medium cursor-pointer justify-content-between ${selectStatusClass(rowData)}-status-box`}
          onClick={handleStatus}
        >
          <div className="d-flex justify-content-between" ref={statusRef}>
            <span className="text-capitalize">{selectStatusClass(rowData)}</span>
            <KTSVG path={DownArrowIcon} className={`m-auto-0 ${selectStatusClass(rowData)}-arrow`} />
          </div>
        </FlexBox>
        {showDropdown && (
          <Portal id="kt_body">
            <OutsideClickHandler
              onOutsideClick={() => {
                if (showDropdown) setShowDropdown(false);
              }}
            >
              <div className="position-absolute" style={{ top: dropdownPositions.top, left: dropdownPositions.left }}>
                <Dropdown
                  data={constVariables.activeInactivestatusDropdownData}
                  selected={showDropdown}
                  value={rowData.isActive ? 'Active' : 'Inactive'}
                  className="top-55"
                  onSelect={(data) => {
                    if (
                      (rowData.isActive && data.name.toUpperCase() === 'INACTIVE') ||
                      (!rowData.isActive && data.name.toUpperCase() === 'ACTIVE')
                    )
                      runChangeProductStatus(rowData.id);
                  }}
                  closeDropdown={() => setShowDropdown(false)}
                />
              </div>
            </OutsideClickHandler>
          </Portal>
        )}
      </div>
    </>
  );
};

const WarehouseList: FC<WarehouseListProps> = ({
  warehouseList,
  setSortBy,
  sortBy,
  rapidActionOpen,
  refresh,
  setWarehouseData,
  isLoading
}) => {
  const { showToast } = useToast();
  const [openSortDropdown, setOpenSortDropdown] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState(-1);
  const [selectedWareHouseName, setselectedWareHouseName] = useState('');
  const [delSuccessToast, setDelSuccessToast] = useState(false);
  const [beingDeleted, setBeingDeleted] = useState(false);

  const closeToast = () => {
    setDelSuccessToast(false);
  };
  const [callModifyProducts, { loading: isDeleting }] = useMutation(MODIFY_WAREHOUSE, {
    onCompleted: () => {
      setShowDeleteModal(false);
      refresh();

      if (beingDeleted) {
        setDelSuccessToast(true);
        setBeingDeleted(false);
      }
    },
    onError: () => {
      setBeingDeleted(false);
      showToast({
        errorText: 'Error in Modify Warehouse',
        message: `Error in Modify Warehouse`
      });
    }
  });

  const ChangeSortBy = (field: string, id: number) => {
    if (sortBy.catagory === field) {
      if (sortBy.type === 'DESC') {
        setSortBy({
          ...sortBy,
          type: 'ASC'
        });
      } else {
        setSortBy({
          ...sortBy,
          type: 'DESC'
        });
      }
    } else {
      setSortBy({
        catagory: field,
        id,
        type: 'DESC'
      });
    }
    setOpenSortDropdown(false);
  };

  const runChangeProductStatus = (data: number) => {
    callModifyProducts({
      variables: {
        input: {
          id: data,
          action: 'TOGGLE_STATUS'
        }
      }
    });
  };

  const deleteProduct = () => {
    if (selectedWarehouse !== -1) {
      callModifyProducts({
        variables: {
          input: {
            id: selectedWarehouse,
            action: 'DELETE_WAREHOUSE'
          }
        }
      }).then((r) => {
        showToast({
          errorText: 'Bulk Supply warehouse deleted',
          message: 'Your warehouse have been successfully deleted from the warehouse listing. If you want it back you can undo.'
        });
      });
    }
  };

  const deleteAndMove = (moveToWarehouseId) => {
    if (selectedWarehouse !== -1 && moveToWarehouseId) {
      callModifyProducts({
        variables: {
          input: {
            id: selectedWarehouse,
            action: 'DELETE_WAREHOUSE',
            moveProductsToAnotherWarehouse: true,
            moveProductsToWarehouseId: moveToWarehouseId
          }
        }
      }).then((r) => {
        showToast({
          errorText: 'All products successfully moved & Bulk Supply warehouse deleted',
          message: 'Your warehouse have been successfully deleted from the warehouse listing. If you want it back you can undo.'
        });
      });
    }
  };

  const move = (moveToWarehouseId) => {
    if (selectedWarehouse !== -1 && moveToWarehouseId) {
      setShowMoveModal(false);
      // callModifyProducts({
      //   variables: {
      //     input: {
      //       id: selectedWarehouse,
      //       action: 'DELETE_WAREHOUSE',
      //       moveProductsToAnotherWarehouse: true,
      //       moveProductsToWarehouseId: moveToWarehouseId
      //     }
      //   }
      // }).then((r) => {
      //   showToast({
      //     errorText: 'All products successfully moved & Bulk Supply warehouse deleted',
      //     message: 'Your warehouse have been successfully deleted from the warehouse listing. If you want it back you can undo.'
      //   });
      // });
    }
  };

  useEffect(() => {
    if (delSuccessToast) {
      setTimeout(() => {
        setDelSuccessToast(false);
      }, 2000);
    }
  }, [delSuccessToast]);

  const warehouseOptions = (data: DropdownOptions, warehouse: any) => {
    if (data.name.toUpperCase() === 'DELETE') {
      setSelectedWarehouse(warehouse?.id);
      setselectedWareHouseName(warehouse?.name);
      setShowDeleteModal(true);
      setBeingDeleted(true);
    }
    if (data.name.toUpperCase() === 'MOVE PRODUCTS') {
      setSelectedWarehouse(warehouse?.id);
      setselectedWareHouseName(warehouse?.name);
      setShowMoveModal(true);
    }
    if (data.name.toUpperCase() === 'MAKE DEFAULT') {
      callModifyProducts({
        variables: {
          input: {
            id: warehouse?.id,
            action: 'MAKE_DEFAULT_WAREHOUSE'
          }
        }
      });
    }
    if (data.name.toUpperCase() === 'EDIT') {
      setWarehouseData({ ...warehouse });
    }
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0
  });

  const columnOptions = [
    {
      dataKey: 'storeName',
      name: 'Store Name',
      width: 300,
      headerRenderer: () => {
        return <SortableTableHeader label="Store Name" sortHeader={() => ChangeSortBy('NAME', 31)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return (
          <FlexBox className="align-items-center">
            <div className="h-40px w-40px  d-flex align-items-center justify-content-center me-5 border border-light rounded">
              <KTSVG svgClassName="h-20px w-20px" svgColor={getRandomColor()} path={WarehouseBuilding} className={`m-auto-0`} />
            </div>
            <FlexBox className="justify-content-start flex-column">
              <span className="main-cell">{rowData.name}</span>
            </FlexBox>
          </FlexBox>
        );
      }
    },
    {
      dataKey: 'address',
      name: 'Address',
      width: 300,
      headerRenderer: () => {
        return <SortableTableHeader label="Address" sortHeader={() => ChangeSortBy('ADDRESS', 32)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return `${rowData?.addressLine1}, ${rowData?.city}, ${rowData?.state}, ${rowData?.pincode}`;
      }
    },
    {
      dataKey: 'bookmark',
      name: '',
      width: 200,
      cellRenderer: ({ rowData }) => {
        return <>{rowData.isDefault && <img src={BookmarkIcon} />}</>;
      }
    },
    {
      dataKey: 'status',
      name: 'Status',
      width: 250,
      cellRenderer: ({ rowData }) => {
        return <WarehouseStatusCell rowData={rowData} runChangeProductStatus={runChangeProductStatus} />;
      }
    },
    {
      dataKey: 'action',
      name: '',
      width: 50,
      cellRenderer: ({ rowData }) => {
        return <ActionMenu warehouse={rowData} warehouseOptions={warehouseOptions} />;
      }
    }
  ];

  const closeDeleteModal = () => {
    document.body.style.overflow = 'unset';
    setShowDeleteModal(false);
    setBeingDeleted(false);
  };

  const closeMoveModal = () => {
    document.body.style.overflow = 'unset';
    setShowMoveModal(false);
    setBeingDeleted(false);
  };

  return (
    <>
      <div className="w-100 h-100 ">
        {/* <FlexBox className="justify-content-between w-100">
          <span className="searchBar">
            <SearchBar placeholder={constVariables.warehouseManagement.searchForWarehouse} />
          </span>
          <FlexBox className="align-items-center">
            <div className="position-relative">
              <OutsideClickHandler
                onOutsideClick={() => {
                  if (openSortDropdown) setOpenSortDropdown(false);
                }}
              >
                <button
                  className={`btn optBtn btn-sm btn-flex btn-icon-text align-items-center ${
                    openSortDropdown ? 'btn-primary' : 'btn-active-primary'
                  }`}
                  onClick={() => {
                    setOpenSortDropdown(!openSortDropdown);
                  }}
                >
                  <KTSVG path={SortIcon} className="svg-icon-8 svg-icon-gray-500 me-1" />
                  <span className="my-auto me-0 poppins-semibold f-14px">Sort</span>
                </button>
                <SortDropdown
                  data={SortIds}
                  value={sortBy}
                  onSelect={ChangeSortBy}
                  selected={openSortDropdown}
                  closeDropdown={() => setOpenSortDropdown(false)}
                />
              </OutsideClickHandler>
            </div>
            <button className="btn optBtn btn-sm btn-flex btn-icon-text align-items-center ms-3">
              <KTSVG path={FilterIcon} className="me-1" />
              <span className="my-auto me-0">Filter</span>
            </button>
          </FlexBox>
        </FlexBox> */}
        <div className="p-t-12">
          <DataTable
            page="warehouse"
            rowData={warehouseList}
            columnOptions={columnOptions}
            isLoading={isLoading}
            searchText=""
            selectedIndex={-1}
            selectedIDs={[]}
          />
        </div>
      </div>
      {showDeleteModal && (
        <CustomModal show={showDeleteModal} bodyClassname="w-90 w-md-50" closeModal={closeDeleteModal}>
          <DeleteAndMoveWarehouseModal
            warehouseList={warehouseList}
            showDeleteModal={showDeleteModal}
            actionBtnHandler={deleteProduct}
            cancelBtnHandler={closeDeleteModal}
            deleteAndMove={deleteAndMove}
            isDeleting={isDeleting}
          />
        </CustomModal>
      )}
      {showMoveModal && (
        <CustomModal show={showMoveModal} bodyClassname="w-90 w-md-50" closeModal={closeMoveModal}>
          <MoveWarehouseModal
            warehouseList={warehouseList}
            showDeleteModal={showMoveModal}
            actionBtnHandler={deleteProduct}
            cancelBtnHandler={closeMoveModal}
            deleteAndMove={move}
            isDeleting={isDeleting}
          />
        </CustomModal>
      )}
    </>
  );
};

export default WarehouseList;
