import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { Divider } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { CloseIcon, DefaultImage } from 'src/assets/icons';

// ConstVariables
import { ROUTES } from 'src/constants';

// Types
import { TikTokProduct } from 'src/components/pages/tiktok-integration/TiktokIntegration.types';

interface TiktokResponseModalProps {
  product?: TikTokProduct;
  closeModal: () => void;
}

const TiktokResponseModal: FC<TiktokResponseModalProps> = ({ product, closeModal }) => {
  const tiktokResponse = JSON.parse(product?.error);
  let allErrors = [];
  if (tiktokResponse?.buysocial && tiktokResponse?.buysocial?.length > 0) {
    // @ts-ignore
    allErrors = [...allErrors, ...tiktokResponse?.buysocial];
  }
  if (tiktokResponse?.tiktok?.failReasons && tiktokResponse?.tiktok?.failReasons?.length > 0) {
    // @ts-ignore
    allErrors = [...allErrors, ...tiktokResponse?.tiktok?.failReasons];
  }
  return (
    <div className="modal-content tiktok-response-modal">
      <div className="modal-header">
        <h5 className="modal-title">TikTok Response</h5>
        <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={closeModal}>
          <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
        </div>
      </div>
      <div className="modal-body p-x-30 p-y-20">
        <FlexBox className="align-items-center">
          <div className="symbol m-r-20">
            <Img
              src={setImageSrc(product?.imageUrl ? generateCompressedImageURL(product?.imageUrl, '50') : DefaultImage)}
              placeholderImg={DefaultImage}
              errorImg={DefaultImage}
              className="object-fit-scale-down bg-white border border-light border-2 productList"
            />
          </div>
          <div>
            <Link
              to={{
                pathname: `/products/allProducts/edit/${product?.id}`,
                state: { tiktokError: tiktokResponse, from: ROUTES.marketing.tiktokIntegration.main, rowData: product }
              }}
              className="main-cell product-main-cell"
            >
              <span className="d-block m-b-8" style={{ maxWidth: 400 }}>
                {product?.name}
              </span>
            </Link>
            <span className="product-price">{product?.price ? `$${product?.price.toFixed(2)}` : ''}</span>
          </div>
        </FlexBox>
        <div className="error-recommendation-content">
          <Divider classname="m-y-20" />
          {allErrors.length > 0 && (
            <div className="tiktok-response-item">
              <h5 className="error-title">Errors</h5>
              <ul>
                {tiktokResponse?.buysocial?.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
                {tiktokResponse?.tiktok?.failReasons?.map((error, index) => (
                  <li key={index}>{error?.message}</li>
                ))}
              </ul>
            </div>
          )}
          {tiktokResponse?.tiktok?.diagnoses && <Divider classname="m-y-20" />}
          {tiktokResponse?.tiktok?.diagnoses?.map((diagnoseItem, index) => (
            <Fragment key={index}>
              <div className="tiktok-response-item m-y-12">
                <h5>{diagnoseItem?.field?.toLowerCase()}</h5>
                <ul>
                  {diagnoseItem?.diagnosisResults?.map((solution, sIndex) => (
                    <li key={sIndex}>{solution?.howToSolve}</li>
                  ))}
                </ul>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TiktokResponseModal;
