/* eslint-disable no-nested-ternary */
import { FC, useState, useEffect, memo, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import clsx from 'clsx';

// Apollo
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  refreshActiveList,
  refreshAllProductList,
  refreshArchivedList,
  refreshDraftList,
  refreshInactiveList
} from 'src/apollo/reactiveVariables';
import { GET_COLLECTIONS, GET_CATALOG_LIST, GET_PRODUCTS_V3, GET_SHOPIFY_ADMIN_BASE_URL } from 'src/apollo/queries';
import {
  DELETE_PRODUCTS,
  PERFORM_RAPID_ACTIONS,
  EDIT_PRODUCTS,
  ADD_PRODUCT_TO_COLLECTION,
  ADD_PRODUCTS_TO_CATALOGUE
} from 'src/apollo/mutations';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { PLInventory, SearchBar, SortDropdown, RightFilterMenu, ShopifySyncTooltip, DataTable } from 'src/components/molecules';
import {
  BulkEditorModal,
  MoreActionDropdown,
  CustomModal,
  DiscardModal,
  NewBulkEditorModal,
  LabelPrintModal
} from 'src/components/oraganisms';
import { FlexBox, Img } from 'src/components/atoms';
import ProductStatus from './components/ProductStatus';
import ProductFilterChips from './components/ProductFilterChips';
import ProductCheckbox from './components/ProductCheckbox';
import ProductTags from './components/ProductTags';
import SortableTableHeader from 'src/components/molecules/DataTable/components/SortableTableHeader';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers/components/KTSVG';
import { setImageSrc } from 'src/utils/setImageSrc';
import { useToast } from 'src/utils/hooks/useToast';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { DefaultImage, RightArrowIcon, FilterIcon, SortIcon, ShopifyIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';
import { SortIds } from './data';

// Types
import { ProductListProps } from './ProductList.types';
import { ProductDetailType } from '../NewBulkEditorModal/NewBulkEditorModal.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';
import { moreActionMenuItem } from '../MoreActionDropdown/MoreActionDropdown.types';

// Styles
import './ProductList.scss';

const productFilterMenuList = ['vendors', 'tags', 'status', 'productTypes', 'collections'];

const ProductList: FC<ProductListProps> = ({
  productList,
  setProductList,
  setSortBy,
  sortBy,
  refreshList,
  showBulkEditorModal,
  editColumnData,
  setShowBulkEditorModal,
  selectedProducts,
  setSelectedProducts,
  isLoading,
  setProductInput,
  getProductInput,
  totalCount,
  toggles,
  productFilters,
  setProductFilters,
  handleUpdateProductFilters,
  showFilterChips,
  setShowFilterChips,
  handleClearProductFilter,
  selectedTab,
  setSelectedTab
}) => {
  const { showToast } = useToast();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showMoreAction, setShowMoreAction] = useState(false);
  const [catalogsModal, showCatalogsModal] = useState(false);
  const [loadFeatured, setLoadFeatured] = useState(false);
  const [collectionModal, showCollectionModal] = useState(false);
  const [openSortDropdown, setOpenSortDropdown] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeColumns, setActiveColumns] = useState<
    {
      name: string;
      status: boolean;
    }[]
  >([]);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [searchCatalogText, setSearchCatalogText] = useState<string>('');
  const [searchCollectionText, setSearchCollectionText] = useState<string>('');
  const [selectedProductsList, setSelectedProductsList] = useState<ProductDetailType[]>([]);
  const [showLabelPrintModal, setShowLabelPrintModal] = useState(false);
  const [labelPrintType, setLabelPrintType] = useState('');
  const [expandedViewLabelPrintModal, setExpandedViewLabelPrintModal] = useState(false);

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const [isDeletingProduct, setIsDeletingProduct] = useState(false);
  const [callDeleteProducts, { data: delProductData }] = useMutation(DELETE_PRODUCTS, {
    onCompleted: (data) => {
      if (data.deleteProducts && data.deleteProducts.length > 0) {
        const failed = data.deleteProducts.filter((p) => !p.success);
        if (failed.length > 0) {
          const errorMessage = failed[0].message;
          showToast({
            errorText: `Delete Products error ${errorMessage}`,
            message: `Delete Products error ${errorMessage}`
          });
          setIsDeletingProduct(false);
          return;
        }
      }
      refreshList();
      setIsDeletingProduct(false);
      closeDeleteConfirmModal();
    },
    onError: (err) => {
      console.error('Delete Products err', err);
    }
  });

  const { data: { shopifyAdminBaseUrl } = { shopifyAdminBaseUrl: null } } = useQuery(GET_SHOPIFY_ADMIN_BASE_URL);

  const [getProductDetails, { loading: isProducDetailsLoading }] = useLazyQuery(GET_PRODUCTS_V3, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      if (res) {
        const productItem = res?.getAllProductsV3?.products[0];
        const updatedInventoryList = productList?.map((product) => {
          if (product.productId === productItem?.productId) {
            return productItem;
          }
          return product;
        });
        setProductList(updatedInventoryList);
      }
    },
    onError: (error) => {
      console.log('error productDetails ::', error);
    }
  });

  const [callPerformRapidAction, { data: rapidActionData }] = useMutation(PERFORM_RAPID_ACTIONS, {
    onCompleted: (data) => {
      const prods = selectedProducts.length;
      showToast({
        title: `${prods} product${prods !== 1 ? 's' : ''} ${prods !== 1 ? 'were' : 'was'} added successfully to`,
        message: `${constVariables.FeaturedProducts.addingToFeatProducts.messageOne} ${prods} product${prods !== 1 ? 's' : ''} ${
          constVariables.FeaturedProducts.addingToFeatProducts.messageTwo
        }`,
        // eslint-disable-next-line no-nested-ternary
        successText: data.performRapidAction.message.includes('FLASH_SALE')
          ? 'Flash Sale!'
          : data.performRapidAction.message.includes('MYSTERY_LIST')
          ? 'Mystery List!'
          : 'Featured Products!'
      });
      setLoadFeatured(false);
      refreshList();
    },
    onError: (error) => {
      let prods = 0;
      if (JSON.parse(error.message).message === 'Product already in list!') {
        prods = JSON.parse(error.message).count;
      }
      showToast({
        title: `${prods} product${prods !== 1 ? 's' : ''} already exist on`,
        message: `${prods} product${prods !== 1 ? 's' : ''} ${constVariables.FeaturedProducts.addingToFeatProducts.errorMessage}`,
        errorText: constVariables.FeaturedProducts.addingToFeatProducts.featuredList
      });
      setLoadFeatured(false);
      resetList();
      setShowMoreAction(false);
      setSelectedProducts([]);
    }
  });

  const [callEditProducts, { data: editProductData }] = useMutation(EDIT_PRODUCTS, {
    onCompleted: (response) => {
      if (response) {
        if (response) {
          const updatedProduct = JSON.parse(response?.editProducts[0]?.additionalData);
          const updatedProductList = productList.map((product) => {
            if (product.productId === updatedProduct.productIds[0]) {
              return { ...product, productStatus: updatedProduct.status };
            } else {
              return product;
            }
          });
          setProductList(updatedProductList);
        }
        // refreshList();
      }
    }
  });

  const [addProductsToCatalog, { loading: isAddingProductToCatalog }] = useMutation(ADD_PRODUCTS_TO_CATALOGUE);
  const [addProductToCollection, { loading: isAddingProductCollection }] = useMutation(ADD_PRODUCT_TO_COLLECTION);

  useEffect(() => {
    if (editColumnData?.length > 0) {
      const arr = Array.from(editColumnData);
      editColumnData.map((column, index) => {
        if (!column.status) {
          arr.splice(arr.indexOf(column), 1);
        }
        return null;
      });
      setActiveColumns([...arr]);
      localStorage.setItem('productsTable', JSON.stringify(editColumnData));
    }
  }, [editColumnData]);

  useEffect(() => {
    if (!catalogsModal || !collectionModal) {
      document.body.style.overflow = 'unset';
    }
  }, [catalogsModal, collectionModal]);

  const history = useHistory();

  const goToInventoryPage = useCallback(() => history.push(`${ROUTES.products.inventory.main}`), [history]);

  useEffect(() => {
    const filteredProductsList = productList.filter((product) => selectedProducts.includes(product.productId));
    setSelectedProductsList(filteredProductsList);
  }, [productList, selectedProducts]);

  useEffect(() => {
    setSelectedProducts([]);
  }, [productList]);

  const [getCatalogList, { data: catalogList, loading: isCatalogLoading }] = useLazyQuery(GET_CATALOG_LIST, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        catalogIds: [],
        filters: { searchText: searchCatalogText }
      }
    }
  });

  const [getCollections, { data: collections, loading: isCollectionLoading }] = useLazyQuery(GET_COLLECTIONS, {
    fetchPolicy: 'cache-and-network',
    variables: { input: { setDroppableByStatus: false, filters: { searchText: searchCollectionText } } }
  });

  const runEditProduct = (value: string) => {
    callEditProducts({
      variables: {
        input: [
          {
            productIds: selectedProducts,
            status: value.toUpperCase()
          }
        ]
      }
    }).then((res) => {
      if (selectedTab !== 'ALL PRODUCTS') {
        const updatedProduct = JSON.parse(res?.data?.editProducts[0]?.additionalData);
        setSelectedTab(updatedProduct.status);
      }
    });
  };

  const runChangeProductStatus = useCallback(
    (data: any, productId) => {
      if (data.name.toUpperCase() === constVariables.ProductsTab.active.toUpperCase()) {
        refreshActiveList(true);
        refreshAllProductList(true);
      } else if (data.name.toUpperCase() === constVariables.ProductsTab.draft.toUpperCase()) {
        refreshDraftList(true);
        refreshAllProductList(true);
      } else if (data.name.toUpperCase() === constVariables.ProductsTab.inactive.toUpperCase()) {
        refreshInactiveList(true);
        refreshAllProductList(true);
      } else {
        refreshArchivedList(true);
        refreshAllProductList(true);
      }
      callEditProducts({
        variables: {
          input: {
            productIds: [productId],
            status: data.name.toUpperCase()
          }
        }
      }).then((res) => {
        if (selectedTab !== 'ALL PRODUCTS') {
          const updatedProduct = JSON.parse(res?.data?.editProducts[0]?.additionalData);
          setSelectedTab(updatedProduct.status);
        }
      });
    },
    [callEditProducts]
  );

  const setupShowCatalogModal = (visible: boolean) => {
    setShowMoreAction(!visible);
    showCatalogsModal(visible);
    getCatalogList();
  };

  const setupShowCollectionModal = (visible: boolean) => {
    setShowMoreAction(!visible);
    showCollectionModal(visible);
    getCollections();
  };

  const runDeleteProducts = () => {
    setIsDeletingProduct(true);
    callDeleteProducts({
      variables: {
        input: {
          productIds: selectedProducts
        }
      }
    });
  };

  const runPerformRapidActions = (listType: string) => {
    callPerformRapidAction({
      variables: {
        input: {
          productIds: selectedProducts,
          listType
        }
      }
    });
  };

  const ChangeSortBy = useCallback(
    (field: string, id: number, isDefault?: boolean) => {
      if (sortBy.catagory === field) {
        if (!isDefault && sortBy.type === 'DESC') {
          setSortBy({
            ...sortBy,
            type: 'ASC'
          });
        } else {
          setSortBy({
            ...sortBy,
            type: 'DESC'
          });
        }
      } else {
        setSortBy({
          catagory: field,
          id,
          type: 'DESC'
        });
      }
      // setOpenSortDropdown(false);
    },
    [setSortBy, sortBy]
  );

  const handleSearch = (value) => {
    setProductInput((productInput) => {
      return {
        ...productInput,
        filters: {
          ...productInput.filters,
          searchText: value
        }
      };
    });
  };

  const selectProduct = useCallback(
    (e: any, productId?: any) => {
      if (e === 'All') {
        setSelectedProducts(productList.map((product) => product.productId));
      } else if (e.target.checked) {
        setSelectedProducts([...selectedProducts, productId]);
      } else {
        setSelectedProducts(selectedProducts.filter((productItemId) => productItemId !== productId));
      }
    },
    [productList, selectedProducts, setSelectedProducts]
  );

  const handleAddProductsToCatalog = (categoryIds: number[]) => {
    addProductsToCatalog({
      variables: {
        input: {
          catalogIds: categoryIds,
          productIds: selectedProducts
        }
      }
    })
      .then(() => {
        showCatalogsModal(false);
        const moreProduct = selectedProducts.length > 1;
        const moreCatalog = categoryIds.length > 1;
        showToast({
          title: `${selectedProducts.length} product${moreProduct ? 's' : ''} ${moreProduct ? 'were' : 'was'} added `,
          message: `You have added successfully ${selectedProducts.length} products to ${categoryIds.length} catalogs.`,
          successText: `successfully to ${categoryIds.length} Catalog${moreCatalog ? 's' : ''}`
        });
        // resetList();
      })
      .catch((err) => {
        showCatalogsModal(false);
        showToast({
          errorText: err.message,
          message: `Error while adding products to catalogs: ${err.message}`
        });
        // resetList();
      });
  };

  const handleAddProductsToCollection = (collectionIds: number[]) => {
    addProductToCollection({
      variables: {
        input: {
          collectionIds,
          productIds: selectedProducts
        }
      }
    })
      .then(() => {
        showCollectionModal(false);
        const moreProduct = selectedProducts.length > 1;
        const moreCollection = collectionIds.length > 1;
        showToast({
          title: `${selectedProducts.length} product${moreProduct ? 's' : ''} ${moreProduct ? 'were' : 'was'} added `,
          message: `You have added successfully ${selectedProducts.length} products to ${collectionIds.length} collections.`,
          successText: `successfully to ${collectionIds.length} Collection${moreCollection ? 's' : ''}!`
        });
        // resetList();
      })
      .catch((err) => {
        showCollectionModal(false);
        showToast({
          errorText: err.message,
          message: `Error while adding products to collections: ${err.message}`
        });
        // resetList();
      });
  };

  const resetList = useCallback(() => {
    setShowBulkEditorModal(false);
    setSelectedProducts([]);
    // refreshList();
    showCatalogsModal(false);
    showCollectionModal(false);
    refreshActiveList(true);
    refreshAllProductList(true);
    refreshDraftList(true);
    refreshInactiveList(true);
    refreshArchivedList(true);
  }, [setSelectedProducts, setShowBulkEditorModal]);

  const cancelOperation = useCallback(() => {
    document.body.style.overflow = 'unset';
    setShowBulkEditorModal(false);
    showCatalogsModal(false);
    showCollectionModal(false);
    setSelectedProducts([]);
  }, [setSelectedProducts, setShowBulkEditorModal]);

  useEffect(() => {
    if (
      (delProductData?.deleteProducts?.length > 0 && delProductData?.deleteProducts[0].success) ||
      rapidActionData?.performRapidAction?.success ||
      (editProductData?.editProducts?.length > 0 && editProductData?.editProducts[0].success)
    ) {
      resetList();
      setShowMoreAction(false);
      setSelectedProducts([]);
    }
  }, [delProductData, rapidActionData, editProductData, resetList, setSelectedProducts]);

  const handleProductFilter = (filters) => {
    setProductInput((getProductInput) => {
      return {
        ...getProductInput,
        filters: {
          ...getProductInput.filters,
          vendorIDs: filters.vendorIDs ? filters.vendorIDs.map((vendor) => vendor.id) : [],
          filterStatus: filters.filterStatus
            ? filters.filterStatus.map((item) => {
                if (item.name === 'Archived') {
                  return 'ARCHIVE';
                }
                return item.name.toUpperCase();
              })
            : [],
          tags: filters.tags ? filters.tags.map((tag) => tag.id) : [],
          productTypes: filters.productTypes ? filters.productTypes.map((productType) => productType.id) : [],
          collections: filters.collections ? filters.collections.map((collection) => collection.id) : []
        }
      };
    });
    setProductFilters((productFilters) => {
      return {
        ...productFilters,
        vendorIDs: filters.vendorIDs,
        tags: filters.tags,
        filterStatus: filters.filterStatus,
        productTypes: filters.productTypes,
        collections: filters.collections
      };
    });
    setShowFilterChips(true);
  };

  const handleOpenFilterMenu = () => {
    document.body.style.overflow = 'hidden';
    setShowFilterMenu(true);
  };

  const handleCloseFilterMenu = () => {
    document.body.style.overflow = 'unset';
    setShowFilterMenu(false);
  };

  const handleUpdateProductList = (updatedProducts) => {
    const idGroups = updatedProducts?.map((product) => product?.productId);
    const newProductList = productList?.map((product) => {
      if (idGroups?.includes(product?.productId)) {
        const index = idGroups.indexOf(product?.productId);
        return updatedProducts[index];
      }
      return product;
    });
    setProductList(newProductList);
  };

  const expandProductDetails = (id: number, index: number) => {
    if (index === selectedIndex) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(index);
      getProductDetails({
        variables: {
          input: {
            productIdsString: JSON.stringify([id]),
            detailed: true,
            filters: {
              locationIdsString: '[]'
            }
          }
        }
      });
    }
  };

  const goToShopifyPage = (shopifyProductId) => {
    if (shopifyAdminBaseUrl) window.open(`${shopifyAdminBaseUrl}products/${shopifyProductId}`, '_blank');
  };

  const initialColumnOptions = [
    {
      dataKey: 'index',
      name: 'Index',
      width: 40,
      headerRenderer: () => {
        return (
          <div className="form-check form-check-sm form-check-custom">
            <input
              className="me-5 form-check-input widget-9-check"
              type="checkbox"
              checked={selectedProducts?.length > 0 && productList.length === selectedProducts.length}
              id="select-all-products"
              disabled={role === UserRoles.FULFILMENT_ASSOCIATE}
              onChange={() => {
                if (productList.length === selectedProducts.length) {
                  setSelectedProducts([]);
                } else {
                  selectProduct('All');
                }
              }}
            />
          </div>
        );
      },
      cellRenderer: ({ rowData }) => {
        return <ProductCheckbox productId={rowData.productId} selectProduct={selectProduct} selectedProducts={selectedProducts} />;
      },
      staticColumn: true
    },
    {
      dataKey: 'productName',
      name: 'Products',
      width: 400,
      headerRenderer: () => {
        return <SortableTableHeader label="Product" sortHeader={() => ChangeSortBy('Product name', 21)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return (
          <FlexBox className="align-items-center">
            <div className="symbol m-r-20">
              <Img
                src={setImageSrc(
                  rowData?.productMediasJson
                    ? generateCompressedImageURL(rowData?.productMediasJson[0]?.productMediaUrl, '50')
                    : DefaultImage
                )}
                placeholderImg={DefaultImage}
                errorImg={DefaultImage}
                className="object-fit-scale-down bg-white border border-light border-2 productList"
              />
              {rowData?.externalProductProvider === 'SHOPIFY' && (
                <>
                  <img
                    src={ShopifyIcon}
                    className="cursor-pointer ms-1 shopify-icon position-absolute"
                    data-tip
                    data-for="shopifySyncIcon"
                    onClick={() => goToShopifyPage(rowData?.externalProductId)}
                  />
                  <ShopifySyncTooltip tooltipText="Product from Shopify" />
                </>
              )}
            </div>
            <Link to={`/products/allProducts/edit/${rowData?.productId}`} className="main-cell product-main-cell">
              <span className="d-block">{rowData?.productName}</span>
            </Link>
          </FlexBox>
        );
      },
      staticColumn: true
    },
    {
      dataKey: 'productQty',
      name: 'Inventory',
      width: 150,
      headerRenderer: () => {
        return <SortableTableHeader label="Inventory" sortHeader={() => ChangeSortBy('Inventory status', 22)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return <PLInventory goToInventoryPage={goToInventoryPage} quantityInStock={rowData?.productQty} />;
      }
    },
    {
      dataKey: 'productTypeName',
      name: 'Type',
      width: 150,
      headerRenderer: () => {
        return <SortableTableHeader label="Type" sortHeader={() => ChangeSortBy('Product type', 23)} type={sortBy.type} />;
      }
    },
    {
      dataKey: 'vendorName',
      name: 'Vendor',
      width: 150,
      headerRenderer: () => {
        return <SortableTableHeader label="Vendor" sortHeader={() => ChangeSortBy('Vendor name', 24)} type={sortBy.type} />;
      }
    },
    {
      dataKey: 'salePrice',
      name: 'Sale price',
      width: 120,
      headerRenderer: () => {
        return <SortableTableHeader label="Sale Price" sortHeader={() => ChangeSortBy('price', 28)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return `$ ${Number(rowData?.salePrice).toFixed(2)}`;
      }
    },
    {
      label: 'Status',
      name: 'Status',
      dataKey: 'productStatus',
      width: 120,
      cellRenderer: ({ rowData }) => {
        return <ProductStatus product={rowData} runChangeProductStatus={runChangeProductStatus} />;
      }
    },
    {
      label: 'Margin',
      name: 'Margin',
      dataKey: 'margin',
      width: 120,
      cellRenderer: ({ rowData }) => {
        return `${Math.round(rowData?.margin)}%`;
      }
    },
    {
      label: 'Profit',
      name: 'Profit',
      dataKey: 'profit',
      width: 120,
      cellRenderer: ({ rowData }) => {
        return `$ ${rowData?.profit !== null ? Number(rowData?.profit).toFixed(2) : '-'}`;
      }
    },
    {
      label: 'Item cost',
      name: 'Item cost',
      dataKey: 'perItemCost',
      width: 120,
      cellRenderer: ({ rowData }) => {
        return `$ ${rowData?.perItemCost !== null ? rowData?.perItemCost : '-'}`;
      }
    },
    {
      label: 'Potential revenue',
      name: 'Potential revenue',
      dataKey: 'potentialRevenue',
      width: 120,
      cellRenderer: ({ rowData }) => {
        return `$ ${rowData?.profit !== null ? Number(rowData?.profit * rowData?.productQty).toFixed(2) : '-'}`;
      }
    },
    {
      label: 'Barcode',
      name: 'Barcode',
      dataKey: 'productBarcode',
      width: 120,
      cellRenderer: ({ rowData }) => {
        return `${rowData?.productBarcode ? rowData?.productBarcode : '-'}`;
      }
    },
    {
      label: 'SKU',
      name: 'SKU',
      dataKey: 'productSku',
      width: 120,
      cellRenderer: ({ rowData }) => {
        return `${rowData?.productSku ? rowData?.productSku : '-'}`;
      }
    },
    {
      label: 'Tags',
      name: 'Tags',
      dataKey: 'tags',
      width: 180,
      cellRenderer: ({ rowData }) => {
        return <ProductTags product={rowData} />;
      }
    },
    {
      label: 'Shipping cost',
      name: 'Shipping cost',
      dataKey: 'shippingPrice',
      width: 120,
      cellRenderer: ({ rowData }) => {
        return `$ ${rowData?.shippingPrice}`;
      }
    },
    {
      label: 'Free shipping',
      name: 'Free shipping',
      dataKey: 'freeShipping',
      width: 120,
      cellRenderer: ({ rowData }) => {
        return `$ ${rowData?.freeShipping}`;
      }
    }
    // {
    //   width: 50,
    //   name: 'Action',
    //   dataKey: 'action',
    //   cellRenderer: ({ rowData, rowIndex }) => {
    //     return (
    //       <div
    //         onClick={() => {
    //           expandProductDetails(rowData?.productId, rowIndex);
    //         }}
    //         className={`h-20x w-20x p-8 d-flex align-items-center justify-content-center m-r-16 cursor-pointer ${
    //           rowData?.productHasVariants ? '' : 'opacity-0'
    //         }`}
    //       >
    //         <img src={RightArrowIcon} className={`h-10px w-10px ${rowIndex === selectedIndex ? 'rotatedArrowIcon' : ''}`} />
    //       </div>
    //     );
    //   },
    //   staticColumn: true
    // }
  ];

  const columnOptions = useMemo(() => {
    const activeColumnNames = activeColumns?.map((column) => column.name);
    // eslint-disable-next-line array-callback-return
    const filteredColumns = initialColumnOptions
      ?.filter((column) => {
        if (column.staticColumn || activeColumnNames?.includes(column.name)) return true;
        return false;
      })
      .sort(function (a, b) {
        // if (a.dataKey === 'action') return 1;
        return activeColumnNames.indexOf(a.name) - activeColumnNames.indexOf(b.name);
      });
    return filteredColumns;
  }, [activeColumns, selectedProducts, selectedIndex, productList, shopifyAdminBaseUrl]);

  const handleOpenBulkEditorModal = () => {
    // document.body.style.overflow = 'hidden';
    document.body.classList.add('modal-open');
    setShowBulkEditorModal(true);
  };

  const handleCloseBulkEditorModal = () => {
    document.body.classList.remove('modal-open');
    setShowBulkEditorModal(false);
  };

  const handleShowLabelPrintModal = (labelType) => {
    setShowLabelPrintModal(true);
    setLabelPrintType(labelType);
  };

  const handleCloseLabelPrintModal = () => {
    document.body.style.overflow = 'unset';
    setShowLabelPrintModal(false);
  };

  const handleInitialCheckbox = () => {
    setSelectedProducts([]);
  };

  const handleDeleteProducts = () => {
    setShowMoreAction(false);
    setShowDeleteModal(true);
  };

  const moreActionMenus: Array<moreActionMenuItem[]> = [
    [
      {
        id: 1,
        text: 'Add to featured',
        onClick: () => runPerformRapidActions('FEATURED')
      },
      {
        id: 2,
        text: 'Add to mystery list',
        onClick: () => runPerformRapidActions('MYSTERY_LIST')
      }
    ],
    [
      {
        id: 3,
        text: 'Add to collection',
        onClick: () => setupShowCollectionModal(true)
      },
      {
        id: 4,
        text: 'Add to catalog',
        onClick: () => setupShowCatalogModal(true)
      }
    ],
    [
      {
        id: 5,
        text: 'Set as active',
        onClick: () => runEditProduct('ACTIVE')
      },
      {
        id: 6,
        text: 'Set as Inactive',
        onClick: () => runEditProduct('INACTIVE')
      },
      {
        id: 7,
        text: 'Set as draft',
        onClick: () => runEditProduct('DRAFT')
      },
      {
        id: 8,
        text: 'Archive products',
        onClick: () => runEditProduct('ARCHIVE')
      }
    ],
    [
      {
        id: 9,
        text: 'Print SKU',
        onClick: () => handleShowLabelPrintModal('sku')
      },
      {
        id: 10,
        text: 'Print barcode',
        onClick: () => handleShowLabelPrintModal('barcode')
      }
    ],
    [
      {
        id: 11,
        text: 'Delete products',
        onClick: () => handleDeleteProducts(),
        deleteOption: true
      }
    ]
  ];

  const closeDeleteConfirmModal = () => {
    document.body.style.overflow = 'unset';
    setShowDeleteModal(false);
  };

  const closeMoreActionDropdown = () => {
    setShowMoreAction(false);
  };

  return (
    <>
      <div className="w-100">
        <div className={clsx({ 'display-none': totalCount === 0 && !getProductInput.filters.searchText })}>
          <FlexBox className="justify-content-between w-auto">
            <span className="searchBar">
              <SearchBar placeholder="Search for products" onChange={handleSearch} />
            </span>
            <FlexBox className="align-items-center">
              <div className="position-relative">
                <OutsideClickHandler
                  onOutsideClick={() => {
                    if (openSortDropdown) setOpenSortDropdown(false);
                  }}
                >
                  <button
                    className={`btn btn-sm btn-flex btn-icon-text align-items-center ${
                      openSortDropdown ? 'btn-primary' : 'btn-active-primary'
                    }`}
                    onClick={() => {
                      setOpenSortDropdown(!openSortDropdown);
                    }}
                  >
                    <KTSVG path={SortIcon} className="me-1" />
                    <span className="my-auto me-0">Sort</span>
                  </button>
                  <SortDropdown
                    data={SortIds}
                    value={sortBy}
                    onSelect={ChangeSortBy}
                    selected={openSortDropdown}
                    closeDropdown={() => setOpenSortDropdown(false)}
                  />
                </OutsideClickHandler>
              </div>
              <button
                className={`btn btn-sm btn-flex btn-icon-text align-items-center ms-3 ${showFilterMenu ? 'btn-primary' : ''}`}
                onClick={handleOpenFilterMenu}
              >
                <KTSVG path={FilterIcon} className="me-1" />
                <span>Filter</span>
              </button>
            </FlexBox>
          </FlexBox>
        </div>

        {!isLoading && totalCount === 0 && !getProductInput.filters.searchText ? (
          ''
        ) : showFilterChips ? (
          <ProductFilterChips
            filters={productFilters}
            setProductFilters={setProductFilters}
            updateProductFilters={handleUpdateProductFilters}
            handleClearProductFilter={handleClearProductFilter}
          />
        ) : (
          ''
        )}

        {selectedProducts.length > 0 && (
          <FlexBox className="m-t-24 justify-content-start align-items-center">
            <button className="btn btn-outlined-primary btn-sm me-2" onClick={handleInitialCheckbox}>
              <div className="d-flex align-items-center">
                <input
                  className={'form-check-input small-checkbox-size shadow-none opacity-100 bg-primary rounded-sm m-0'}
                  type="checkbox"
                  disabled={true}
                  checked={selectedProducts.length > 0}
                  ref={(input) => {
                    if (input) {
                      input.indeterminate = true;
                    }
                  }}
                />
                <span className="ms-2">{selectedProducts.length} Selected</span>
              </div>
            </button>
            <button className="btn btn-outlined-primary btn-sm me-2" onClick={handleOpenBulkEditorModal}>
              {constVariables.ProductsTab.editProducts}
            </button>
            <div className="position-relative">
              <button
                className={`btn btn-sm ${showMoreAction ? 'btn-primary' : 'btn-outlined-primary'}`}
                onClick={() => setShowMoreAction(true)}
              >
                {constVariables.ProductsTab.moreActions}
              </button>
              <OutsideClickHandler onOutsideClick={closeMoreActionDropdown}>
                <MoreActionDropdown selected={showMoreAction} options={moreActionMenus} closeDropdown={closeMoreActionDropdown} />
              </OutsideClickHandler>
            </div>
          </FlexBox>
        )}
        <div className="p-t-12">
          <DataTable
            page="product"
            rowData={productList}
            columnOptions={columnOptions}
            isLoading={isLoading}
            searchText={getProductInput.filters.searchText}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            selectedIDs={selectedProducts}
            isDetailsLoading={isProducDetailsLoading}
          />
        </div>
      </div>
      {showDeleteModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showDeleteModal} closeModal={closeDeleteConfirmModal}>
          <DiscardModal
            deleteProductMedia
            title={constVariables.common.productList.title}
            message={constVariables.common.productList.mainMessage}
            actionBtnTitle={constVariables.common.productList.action}
            cancelBtnTitle={constVariables.common.productList.cancel}
            actionBtnHandler={runDeleteProducts}
            cancelBtnHandler={closeDeleteConfirmModal}
            isLoading={isDeletingProduct}
          />
        </CustomModal>
      )}

      {selectedProductsList?.length > 0 && showBulkEditorModal && (
        <NewBulkEditorModal
          cancelOperation={cancelOperation}
          show={showBulkEditorModal}
          refreshList={resetList}
          data={selectedProductsList}
          closeModal={handleCloseBulkEditorModal}
          dataType="PRODUCTS"
          updateProductList={handleUpdateProductList}
        />
      )}

      {catalogsModal && (
        <BulkEditorModal
          cancelOperation={cancelOperation}
          show={catalogsModal}
          refreshList={resetList}
          data={catalogList?.getCatalogList?.catalogues}
          dataType="CATALOGS"
          handleAddProductsToCatalog={handleAddProductsToCatalog}
          searchText={searchCatalogText}
          setSearchText={setSearchCatalogText}
          isLoading={isCatalogLoading}
          closeModal={cancelOperation}
          isAdding={isAddingProductToCatalog}
        />
      )}

      {collectionModal && (
        <BulkEditorModal
          cancelOperation={cancelOperation}
          show={collectionModal}
          refreshList={resetList}
          data={collections?.getCollections?.collections?.length > 0 ? collections?.getCollections.collections : []}
          dataType="COLLECTION"
          handleAddProductsToCatalog={handleAddProductsToCollection}
          searchText={searchCollectionText}
          setSearchText={setSearchCollectionText}
          isLoading={isCollectionLoading}
          closeModal={cancelOperation}
          isAdding={isAddingProductCollection}
        />
      )}

      {showLabelPrintModal && (
        <CustomModal
          show={showLabelPrintModal}
          closeModal={handleCloseLabelPrintModal}
          bodyClassname={`${expandedViewLabelPrintModal ? 'm-0' : ''}`}
        >
          <LabelPrintModal
            closeModal={handleCloseLabelPrintModal}
            labelPrintType={labelPrintType}
            expandedViewLabelPrintModal={expandedViewLabelPrintModal}
            toggleExpand={() => setExpandedViewLabelPrintModal(!expandedViewLabelPrintModal)}
            selectedProducts={selectedProducts}
          />
        </CustomModal>
      )}
      <RightFilterMenu
        closeMenu={handleCloseFilterMenu}
        showFilterMenu={showFilterMenu}
        handleFilter={handleProductFilter}
        filterData={productFilters}
        setFilterData={setProductFilters}
        updateFilterData={handleUpdateProductFilters}
        setProductInput={setProductInput}
        getProductInput={getProductInput}
        handleClearProductFilter={handleClearProductFilter}
        menuList={productFilterMenuList}
      />
    </>
  );
};

export default memo(ProductList);
