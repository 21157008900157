import { FC, useState, useReducer } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

// Apollo
import { useMutation } from '@apollo/client';
import { RESET_PASSWORD } from 'src/apollo/mutations';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';
import { validatePassword } from 'src/utils/validate';
import { getAccountDetailsFromToken } from 'src/utils/authentication/decodeToken';

// Images && Icons
import { SignUpBySocial } from 'src/assets/images';
import { HideIcon, ShowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, envUrls } from 'src/constants';

// Styles
import 'src/components/oraganisms/CustomModal/modalStyles.scss';
import 'src/components/pages/auth/Signup/_signUp.scss';

const ResetPassword: FC = (props) => {
  const { token } = useParams<{ token: string }>();
  const accountInfo = getAccountDetailsFromToken(token);
  const { showToast } = useToast();
  const [showPassword, setShowPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const checkEqualPassword = () => {
    if (state.password.value !== state.confirmPassword.value) {
      return 'Passwords do not match';
    } else {
      return false;
    }
  };

  const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), {
    password: {
      value: '',
      validateOnChange: true,
      error: ''
    },

    confirmPassword: {
      value: '',
      validateOnChange: true,
      error: ''
    }
  });
  const resetData = () => {
    setState({
      password: {
        value: '',
        validateOnChange: true,
        error: ''
      },

      confirmPassword: {
        value: '',
        validateOnChange: true,
        error: ''
      }
    });
  };

  const history = useHistory();

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      showToast({
        title: `Reset password`,
        message: 'Your password is reset.'
      });
      resetData();
      setIsLoading(false);
      history.push('/login');
    },
    onError: (e) => {
      console.error('resetpassword onError - ', e);
      console.error('e?.graphQLErrors[0] - ', e?.graphQLErrors[0]);
      const error: any = e?.graphQLErrors[0];
      if (error.errorType === 'INVALID_RESET_PASSWORD_TOKEN') {
        showToast({
          title: `Error`,
          message: error.message
        });
      } else {
        showToast({
          title: `Error`,
          message: 'Something went wrong. Please try again later.'
        });
      }
      history.replace('/login');
      setIsLoading(false);
      resetData();
    }
  });

  const handleResetPassword = () => {
    setIsLoading(true);
    resetPassword({
      context: {
        headers: {
          tenantid: accountInfo.tenantId,
          'x-api-key': envUrls.authXApiKey,
          token
        }
      },
      variables: {
        input: {
          email: accountInfo.email,
          newPassword: state.password.value,
          isBackStoreUser: true
        },
        token,
        tenantid: accountInfo.tenantId
      }
    });
  };

  const handleChange = async (e: any) => {
    const field = e.target.name;
    const fieldVal = e.target.value;

    if (field) {
      setState({
        [field]: {
          ...state[field],
          value: fieldVal,
          error: ''
        }
      });
    }
  };

  return (
    <>
      <div className="signup d-flex gradient flex-row ">
        <div className="form-container padding-style">
          {/* left panel */}
          <div className={`p-8rem reset-pwd container-height container-width overflow-hidden bg-white content-container`}>
            <img src={SignUpBySocial} alt={SignUpBySocial} className="image-dimension" />
            <p className="poppins-regular title-style m-0 auth-header">
              <p>{constVariables.signUp.resetPassword}</p>
            </p>
            <div>
              <div className="d-flex inner-gap  flex-column">
                <div className="input-gap">
                  <p className="fs-14px mb-1">{constVariables.signUp.newPassword}</p>
                  <div className={`input-group password-section ${state.password.error ? 'red-border' : 'grey-border'}`}>
                    <input
                      autoComplete="off"
                      className={`form-control form-control-solid hp-50px no-border`}
                      id="password"
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      value={state.password.value}
                      onChange={(e) => handleChange(e)}
                      placeholder="Set New Password"
                    />
                    <div className="input-group-addon">
                      <img
                        src={!showPassword ? HideIcon : ShowIcon}
                        className="cursor-pointer"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    </div>
                  </div>
                  <div className={`text-danger fs-8`}>{state.password.error}</div>
                </div>
                <div className="">
                  <p className="fs-14px mb-1">{constVariables.signUp.confirmPassword}</p>
                  <div className={`input-group password-section ${state.confirmPassword.error ? 'red-border' : 'grey-border'}`}>
                    <input
                      autoComplete="off"
                      className={`form-control form-control-solid hp-50px no-border`}
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      value={state.confirmPassword.value}
                      onChange={(e) => handleChange(e)}
                      placeholder="Re-Enter Password"
                    />
                  </div>
                  <div className={`text-danger fs-8`}>{state.confirmPassword.error}</div>
                </div>
                <div className="button-container">
                  <div className="button margin-style">
                    <button
                      disabled={isLoading}
                      className="btn btn-primary social-btn w-100 button-padding flex-center"
                      onClick={() => {
                        const passwordError = validatePassword(state.password.value);
                        let confirmPasswordError = validatePassword(state.confirmPassword.value);

                        if (!passwordError && !confirmPasswordError) {
                          if (checkEqualPassword()) {
                            confirmPasswordError = 'Passwords do not match';
                          }
                        }

                        if ([passwordError, confirmPasswordError].every((e) => e === false)) {
                          handleResetPassword();
                        } else {
                          // update the state with errors

                          setState({
                            password: {
                              ...state.password,
                              validateOnChange: true,
                              error: passwordError
                            },
                            confirmPassword: {
                              ...state.confirmPassword,
                              validateOnChange: true,
                              error: confirmPasswordError
                            }
                          });
                        }
                      }}
                    >
                      {constVariables.signUp.resetPassword}
                      {isLoading && <div className="spinner-border text-primary auth-flow"></div>}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  */}
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
