import { gql } from '@apollo/client';

export const GET_TIKTOK_CATEGORIES = gql`
  query GetCategories @api(name: "tiktokAPI") {
    getCategories {
      id
      name
      is_leaf
      children {
        id
        name
        is_leaf
        parent_id
        children {
          id
          name
          is_leaf
          parent_id
          children {
            id
            name
            is_leaf
            parent_id
            children {
              id
              name
              is_leaf
              parent_id
              children {
                id
                name
                is_leaf
                parent_id
                children {
                  id
                  name
                  is_leaf
                  parent_id
                }
              }
            }
          }
        }
      }
      parent_id
    }
  }
`;
