import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

// Apollo
import { useQuery, useMutation } from '@apollo/client';
import { GET_STORE_DETAILS } from 'src/apollo/queries';
import { TOGGLE_COMBINE_ORDER } from 'src/apollo/mutations';
import { modifyCacheForAutoCombineOrders } from 'src/apollo/cacheModifiers/autoCombine';

// Components
import { FlexBox, Loader } from 'src/components/atoms';
import { Tooltip } from 'src/components/molecules';

// Hooks && Utils && Helpers
import useTitle from 'src/utils/hooks/useTitle';

// Icons
import { BackRoundedIcon, InfoItalicIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';

// Styles
import './_orderSettings.scss';

const OrderSettings: FC = () => {
  const history = useHistory();
  useTitle('Settings - Orders');
  const [isCombinedOrders, setIsCombinedOrders] = useState(false);

  const returnBack = () => {
    history.push(ROUTES.settings.main);
  };

  const { loading, refetch, data } = useQuery(GET_STORE_DETAILS, {
    onCompleted: (res) => {
      if (res?.getStoreDetails) {
        setIsCombinedOrders(res?.getStoreDetails?.combineOrders);
      }
    }
  });

  const [toggleCombineOrder] = useMutation(TOGGLE_COMBINE_ORDER, {
    onCompleted: (res) => {
      if (res) {
        setIsCombinedOrders(res?.toggleCombineOrders);
      }
    },
    onError: () => {
      setIsCombinedOrders((b) => !b);
    },
    update(cache, { data: { toggleCombineOrders } }) {
      modifyCacheForAutoCombineOrders(cache, toggleCombineOrders);
    }
  });

  const handleToggleCombineOrders = (e) => {
    setIsCombinedOrders(e.target.checked);
    toggleCombineOrder({
      variables: {
        allowAutomaticCombining: e.target.checked
      }
    });
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="order-settings-page p-40">
      <div className="order-settings-page-container">
        <FlexBox className="align-items-center p-y-40">
          <img src={BackRoundedIcon} alt={BackRoundedIcon} onClick={returnBack} className="cursor-pointer" />
          <h2 className="page-title px-4 mb-0">{constVariables.orderSettings.pageName}</h2>
        </FlexBox>
        <div className="card px-10 py-8 m-0">
          <h3 className="mb-11">Orders combining</h3>

          {loading && !data?.getStoreDetails ? (
            <div className={`d-flex flex-center h-50px`}>
              <Loader type="button" className="m-0" />
            </div>
          ) : (
            <div className="d-flex align-item-center">
              <div className="input-title mb-0 me-1">Combine orders automatically</div>
              <img src={InfoItalicIcon} className="cursor-pointer h-15px w-15px ms-1 me-3" data-tip data-for="orderSettingsIcon" />
              <Tooltip
                tooltipId="orderSettingsIcon"
                className="toolTipShip"
                place="top"
                content={() => {
                  return (
                    <div>
                      When enabled, the orders of the same customer with the same shipping address and shipping method will be
                      combined automatically when printing packing slips.
                    </div>
                  );
                }}
              />

              <label className="form-check form-check-custom form-check-solid form-switch form-switch-sm ms-3">
                <input
                  style={{ outline: 'none' }}
                  className="form-check-input"
                  type="checkbox"
                  checked={isCombinedOrders}
                  onChange={handleToggleCombineOrders}
                />
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderSettings;
