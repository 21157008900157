import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';

// Components
import { FlexBox, Loader, SelectionCircle } from 'src/components/atoms';
import { Divider, Dropdown } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { MoveWarehouseIcon, CloseIcon, WarehouseBuilding, DownArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { DeleteAndMoveWarehouseModalProps } from './Modal.types';

// Styles
import './modalStyles.scss';
import OutsideClickHandler from 'react-outside-click-handler';
import { WarehouseOptionItem } from '../../pages/inventory/InventoryManagementPage.type';

const DeleteAndMoveWarehouseModal: FC<DeleteAndMoveWarehouseModalProps> = ({
  warehouseList,
  cancelBtnHandler,
  actionBtnHandler,
  deleteAndMove,
  showDeleteModal,
  isDeleting
}) => {
  useEffect(() => {
    setSelectedMove(false);
    setSelectedDelete(false);
  }, [showDeleteModal]);

  const [selectedMove, setSelectedMove] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState(false);

  const checkUnCheckMove = () => {
    if (selectedMove) {
      setSelectedMove(false);
    } else {
      setSelectedMove(true);
      setSelectedDelete(false);
    }
  };
  const checkUnCheckDelete = () => {
    if (selectedDelete) {
      setSelectedDelete(false);
    } else {
      setSelectedDelete(true);
      setSelectedMove(false);
    }
  };

  const [isWarehouseDropdown, setIsWarehouseDropdown] = useState(false);
  const [selectedWareHouse, setSelectedWareHouse] = useState<WarehouseOptionItem>();
  const openWarehouseDropdown = () => {
    setIsWarehouseDropdown(true);
  };
  const closeWarehouseDropdown = () => {
    setIsWarehouseDropdown(false);
  };

  const selectWarehouse = (data: any) => {
    setSelectedWareHouse(data);
    closeWarehouseDropdown();
  };
  const WarehouseSelector = () => (
    <div className="position-relative ms-5">
      <OutsideClickHandler onOutsideClick={closeWarehouseDropdown}>
        <FlexBox
          className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box ${
            isWarehouseDropdown ? 'dropdown-box-active' : ''
          }`}
          onClick={openWarehouseDropdown}
        >
          {selectedWareHouse ? (
            <FlexBox className="align-items-center">
              <KTSVG svgColor="#706FD3" path={WarehouseBuilding} />
              <div className="ms-2">{selectedWareHouse.name}</div>
            </FlexBox>
          ) : (
            <FlexBox className="align-items-center">
              <KTSVG svgColor="#706FD3" path={WarehouseBuilding} />
              <div className="ms-2">Select Warehouse</div>
            </FlexBox>
          )}
          <div>
            <KTSVG path={DownArrowIcon} />
          </div>
        </FlexBox>
        {warehouseList ? (
          <Dropdown
            className="dropdown-custom-width mt-3"
            data={warehouseList}
            selected={isWarehouseDropdown}
            value={selectedWareHouse?.name}
            onSelect={selectWarehouse}
            closeDropdown={() => setIsWarehouseDropdown(false)}
          />
        ) : null}
      </OutsideClickHandler>
    </div>
  );

  return (
    <div className={clsx('modal-content modal-large-content delete-and-move-modal')}>
      <div className="w-100 position-relative">
        <KTSVG
          path={CloseIcon}
          className="svg-icon cursor-pointer svg-icon-2x position-absolute top-10 end-10"
          onClick={cancelBtnHandler}
        />
        <div className="p-t-40 p-b-20 px-4 text-center title-container">
          <div className="logo-container">
            <KTSVG path={MoveWarehouseIcon} className="svg-icon svg-icon-5x my-2" />
          </div>
          <h5 className="modal-title text-center">{constVariables.common.MoveWarehouse.titleForDelete}</h5>
        </div>
        <Divider />
        <div className="m-6  ">
          <div className="d-flex align-items-center">
            <SelectionCircle className="me-3" onClick={checkUnCheckMove} isSelected={selectedMove} />
            <div>{constVariables.common.MoveWarehouse.allowMe}</div>
          </div>
          <div className="d-flex">
            <div className="me-3" />
            <div className="helper-text leftPaddingDelMoveModal  fs-8 mt-2">{constVariables.common.MoveWarehouse.allowMeText}</div>
          </div>
        </div>
        {selectedMove && (
          <div className="mb-6">
            <WarehouseSelector />
          </div>
        )}
        <Divider />
        <div className="m-6  ">
          <div className="d-flex align-items-center">
            <SelectionCircle className="me-3" onClick={checkUnCheckDelete} isSelected={selectedDelete} />
            <div>{constVariables.common.MoveWarehouse.deleteThem}</div>
          </div>
          <div className="d-flex">
            <div className="me-3" />
            <div className="helper-text leftPaddingDelMoveModal  fs-8 mt-2">
              {constVariables.common.MoveWarehouse.deleteThemText}
            </div>
          </div>
        </div>
        {selectedMove && (
          <div className="modal-footer">
            <button type="button" className="btn btn-outlined-secondary btn-md" data-bs-dismiss="modal" onClick={cancelBtnHandler}>
              Cancel
            </button>
            <button
              disabled={!selectedWareHouse || isDeleting}
              type="button"
              className="btn btn-danger btn-md"
              onClick={() => selectedWareHouse && deleteAndMove(selectedWareHouse.id)}
            >
              {isDeleting && <Loader type="button" className="h-10px w-10px ms-0" />}
              Move & Delete Warehouse
            </button>
          </div>
        )}
        {selectedDelete && (
          <div className="modal-footer">
            <button type="button" className="btn btn-outlined-secondary btn-md" data-bs-dismiss="modal" onClick={cancelBtnHandler}>
              Cancel
            </button>
            <button disabled={isDeleting} type="button" className="btn btn-danger btn-md" onClick={actionBtnHandler}>
              {isDeleting && <Loader type="button" className="h-10px w-10px ms-0" />}
              <span className="ms-2">Delete It Permanently</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeleteAndMoveWarehouseModal;
