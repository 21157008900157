import { FC, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import clsx from 'clsx';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { FlexBox, DropdownSelector } from 'src/components/atoms';
import { Dropdown, EditColumn, Checkbox, Tooltip } from 'src/components/molecules';
import { CustomModal, VariantImagesModal } from 'src/components/oraganisms';
import CustomTableRow from './CustomTableRow';

// Icons
import { DownArrowBlueIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { DropdownDataProps, ProductVariationTableProps, TableData } from '../AddProductVariant.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

// Styles
import '../AddProductVariants.scss';

const ProductVariationTable: FC<ProductVariationTableProps> = ({
  tableData,
  changeFieldValue,
  changeValue,
  options,
  setOptions,
  setTableData,
  setSelectedVariantOption,
  selectedVariantOption,
  setCheckedVariantOption,
  checkedVariantOption,
  setOptionDone,
  setOptionChecked,
  pricing,
  productMedia,
  updateProductMedia,
  inventory,
  formChanges,
  setLabelPrintType,
  setShowLabelPrintModal,
  setShowProductSavingModal,
  selectedVariants,
  setSelectedVariants,
  setSelectedVariantIds
}) => {
  const { productId } = useParams<{ productId: string }>();

  const [updateAllCheck, setUpdateAllCheck] = useState(false);
  const [price, setPrice] = useState('');
  const [weight, setWeight] = useState('');
  const [sku, setSku] = useState('');
  const [barcode, setBarcode] = useState('');
  const [openImagesModal, setOpenImagesModal] = useState(false);
  const [selectedItemToChangeImage, setSelectedItemToChangeImage] = useState(-1);
  const [optionMenu, setOptionMenu] = useState(false);
  const [optionsDropdownData, setoptionsDropdownData] = useState<DropdownDataProps[]>();
  const [selectedImageURL, SelectedImageURL] = useState('');
  const [openEditColumnDropdown, setOpenEditColumnDropdown] = useState(false);
  const [isScrollLeft, setIsScrollLeft] = useState(false);
  const [variantStatus, setVariantStatus] = useState('');
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [variantQuantityByWareHouseId, setVariantQuantityByWareHouseId] = useState({});
  const [showMoreActions, setShowMoreActions] = useState(false);

  const [editColumnData, setEditColumnData] = useState<
    {
      name: string;
      status: boolean;
      warehouseId?: number;
      main: boolean;
    }[]
  >([
    { name: 'Price', status: true, main: true },
    { name: 'SKU', status: true, main: true },
    { name: 'Barcode', status: true, main: true },
    { name: 'Status', status: false, main: true },
    { name: 'Weight', status: false, main: true }
  ]);

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  // useEffect(() => {
  //   setPrice(pricing.salePrice);
  // }, [pricing.salePrice]);

  useEffect(() => {
    const inventoryColumns = inventory?.inventoryData
      ?.filter((item) => item?.inventoryWarehouseId)
      // .filter((item) => !editColumnData.map((column) => column.name).includes(item.inventoryWarehouseValue))
      .map((item) => {
        return { name: item.inventoryWarehouseValue, status: true, warehouseId: item.inventoryWarehouseId, main: false };
      });
    if (inventoryColumns?.length > 0) {
      const oldColumnData = editColumnData.filter((column) => column.main);
      const updatedColumnData = [...oldColumnData.slice(0, 1), ...inventoryColumns, ...oldColumnData.slice(1)];
      setEditColumnData(updatedColumnData);
    }
  }, [inventory]);

  const opneOptionMenu = () => {
    if (role === UserRoles.FULFILMENT_ASSOCIATE) return;
    setOptionMenu(true);
  };
  const closeOptionMenu = () => {
    setOptionMenu(false);
  };
  const selectOption = (data: any) => {
    setSelectedVariantOption(data.name);
    closeOptionMenu();
  };
  const openImagesModalFun = (id: number) => {
    setOpenImagesModal(true);
    setSelectedItemToChangeImage(id);
    SelectedImageURL(tableData[id].img);
  };
  const closeImagesModalFun = () => {
    document.body.style.overflow = 'unset';
    setOpenImagesModal(false);
  };
  useEffect(() => {
    if (options) {
      const temp = options.map((data, index) => {
        return { name: data.title, id: index };
      });

      const optionTemp = options.map((data, index) => data.title);
      if (optionTemp.length > 0) {
        if (optionTemp.indexOf(selectedVariantOption) === -1) {
          setSelectedVariantOption(optionTemp[0]);
        }
      }
      if (temp) {
        setoptionsDropdownData(temp);
      }
      if (selectedVariantOption === '') {
        setSelectedVariantOption(options[0]?.title);
      }
    }
  }, [options]);

  const onChangeCheckBox = (item: any) => {
    const variantIndex = selectedVariants.findIndex((i) => i.variantType === item.variantType);
    if (variantIndex > -1) {
      let arr = [...selectedVariants];
      arr = arr.filter((i) => i.variantType !== item.variantType);
      setSelectedVariants(arr);
    } else {
      setSelectedVariants((prev) => [...prev, item]);
    }
  };
  const checkCheckboxValue = (item: string) => {
    const found = selectedVariants.findIndex((i) => i.variantType === item);
    return found >= 0;
  };

  const updateAllRowHandler = () => {
    if (updateAllCheck) {
      setUpdateAllCheck(false);
      setSelectedVariants([]);
    } else {
      setSelectedVariants(tableData);
      setUpdateAllCheck(true);
    }
  };

  const onDeleteVariant = () => {
    const arr = [...tableData];
    const toDelete = new Set(selectedVariants?.map((variant) => variant?.variantType));

    const newVariantArray = arr.filter((item) => {
      return !toDelete.has(item.variantType);
    });

    if (newVariantArray.length === 0) {
      setOptionChecked(false);
      setSelectedVariants([]);
      setOptionDone(false);
      setOptions([{ title: 'Size', value: [], errTitle: false, errVariant: false }]);
      setTableData([]);
    } else {
      const tempVariantTypes = newVariantArray.map((item) => item.variantType).join('');

      const newOptionsArray = options.map((option) => {
        let str = '';
        for (let j = 0; j < option.value.length; j++) {
          const elem = option.value[j].trim();
          if (!tempVariantTypes.includes(elem)) {
            str = elem;
          }
        }
        return {
          ...option,
          value: option.value.filter((item) => item.trim() !== str.trim())
        };
      });
      setSelectedVariants([]);
      setOptions(newOptionsArray);
      setTableData(newVariantArray);
    }
  };

  useEffect(() => {
    if (selectedVariants.length === 0) {
      setUpdateAllCheck(false);
    }
  }, [selectedVariants, onDeleteVariant]);

  const changeVariantImage = (variantImage) => {
    if (selectedItemToChangeImage !== -1) {
      const tempindex = tableData.findIndex((data, index) => index === selectedItemToChangeImage);
      const selectedVariantOptionIndex = options.findIndex((data) => data.title === selectedVariantOption);

      let tempdata;
      if (updateAllCheck) {
        tempdata = tableData.map((data) => {
          return {
            ...data,
            img: variantImage.productMediaUrl,
            media: { ...data.media, url: variantImage.productMediaUrl, fileimage: variantImage.fileimage }
          };
        });
      } else if (checkedVariantOption && selectedVariantOptionIndex !== -1) {
        const selectedVariantOption = options[selectedVariantOptionIndex].value.find((x) =>
          tableData[tempindex].variantType.includes(x)
        );
        if (selectedVariantOption) {
          if (options.length === 1) {
            tempdata = tableData.map((data) => {
              return {
                ...data,
                img: variantImage.productMediaUrl,
                media: { ...data.media, url: variantImage.productMediaUrl, fileimage: variantImage.fileimage }
              };
            });
          } else {
            tempdata = tableData.map((data) =>
              data.variantType.includes(selectedVariantOption)
                ? {
                    ...data,
                    img: variantImage.productMediaUrl,
                    media: { ...data.media, url: variantImage.productMediaUrl, fileimage: variantImage.fileimage }
                  }
                : data
            );
          }
        }
      } else {
        tempdata = tableData.map((data, index) =>
          index === selectedItemToChangeImage
            ? {
                ...data,
                img: variantImage.productMediaUrl,
                media: { ...data.media, url: variantImage.productMediaUrl, fileimage: variantImage.fileimage }
              }
            : data
        );
      }

      if (tempdata) {
        setTableData(tempdata);
      }
    }
  };

  const handleChangeAllStatusAtOnce = (item, e) => {
    setVariantStatus(item.name);
    changeValue({ e, fieldname: 'status', value: item.name.toUpperCase() });
  };

  const renderTableWidget = (widgetCell, index) => {
    switch (widgetCell.name) {
      case 'Price':
        return (
          <th className="align-middle py-0 border-none" key={index}>
            <div className="price-widget position-relative">
              <span className="position-absolute">$</span>
              <input
                autoComplete="off"
                type="text"
                name="variantprice"
                onChange={(e) => e.target.validity.valid && setPrice(e.target.value)}
                pattern="(\d*\.\d{0,2}|\d+)"
                className="form-control table-input-field"
                value={price}
                onKeyDown={(e) => changeValue({ e, fieldname: 'price', value: price })}
                onBlur={(e) => changeValue({ e, fieldname: 'price', value: price })}
                // disabled={!updateAllCheck}
              />
            </div>
          </th>
        );
      case 'Status':
        return (
          <th className="align-middle py-0 border-none" key={index}>
            <div className={`position-relative ${!updateAllCheck ? 'disabled-cell' : ''}`}>
              <DropdownSelector
                className={`justify-content-between align-items-center cursor-pointer dropdown-box status-box ${
                  showStatusDropdown ? 'dropdown-box-active' : ''
                }`}
                onClick={() => {
                  setShowStatusDropdown(true);
                }}
                selectedValue={variantStatus}
                text="Select"
              />
              <OutsideClickHandler
                onOutsideClick={() => {
                  setShowStatusDropdown(false);
                }}
              >
                <Dropdown
                  className="full-width-dropdown"
                  data={constVariables.activeInactivestatusDropdownData}
                  selected={showStatusDropdown}
                  value={variantStatus}
                  onSelect={handleChangeAllStatusAtOnce}
                  closeDropdown={() => setShowStatusDropdown(false)}
                />
              </OutsideClickHandler>
            </div>
          </th>
        );
      case 'Weight':
        return (
          <th className="align-middle border-none py-0" key={index}>
            <input
              autoComplete="off"
              className="form-control table-input-field"
              type="text"
              pattern="([0-9]|[1-9][0-9]{1,3}|10000)"
              name="weight"
              value={weight}
              onChange={(e) => {
                e.target.validity.valid && setWeight(e.target.value);
              }}
              onKeyDown={(e) => changeValue({ e, fieldname: 'weight', value: weight })}
              onBlur={(e) => changeValue({ e, fieldname: 'weight', value: weight })}
              disabled={!updateAllCheck}
            />
          </th>
        );
      case 'SKU':
        return (
          <th key={index} className="text-muted pe-4 align-middle border-none py-0">
            <input
              autoComplete="off"
              className="form-control table-input-field"
              name="sku"
              type="text"
              value={sku}
              onChange={(e) => {
                e.target.validity.valid && setSku(e.target.value);
              }}
              onKeyDown={(e) => changeValue({ e, fieldname: 'sku', value: sku })}
              onBlur={(e) => changeValue({ e, fieldname: 'sku', value: sku })}
              // disabled={!updateAllCheck}
            />
          </th>
        );
      case 'Barcode':
        return (
          <th key={index} className="text-muted pe-4 align-middle border-none py-0">
            <input
              autoComplete="off"
              className="form-control table-input-field"
              name="barcode"
              type="text"
              value={barcode}
              onChange={(e) => {
                e.target.validity.valid && setBarcode(e.target.value);
              }}
              onKeyDown={(e) => changeValue({ e, fieldname: 'barcode', value: barcode })}
              onBlur={(e) => changeValue({ e, fieldname: 'barcode', value: barcode })}
              // disabled={!updateAllCheck}
            />
          </th>
        );
      default:
        return (
          <th key={index} className="text-muted pe-4 align-middle border-none py-0">
            <div className="price-widget position-relative">
              <input
                autoComplete="off"
                type="number"
                name="variantQuantity"
                onChange={(e) => {
                  const updatedQty = parseInt(e.target.value, 10);
                  variantQuantityByWareHouseId[widgetCell.warehouseId] = updatedQty;
                  // @ts-ignore
                  setVariantQuantityByWareHouseId({
                    ...variantQuantityByWareHouseId
                  });
                  e.type = 'blur';
                  changeValue({
                    e,
                    fieldname: 'warehouseInventory',
                    value: { warehouseId: widgetCell.warehouseId, quantity: updatedQty }
                  });
                }}
                min={0}
                className="form-control table-input-field"
                value={variantQuantityByWareHouseId[widgetCell.warehouseId]}
                // onKeyDown={(e) => {
                //   changeValue({
                //     e,
                //     fieldname: 'warehouseInventory',
                //     value: { warehouseId: widgetCell.warehouseId, quantity: variantQuantity }
                //   });
                // }}
                // onBlur={(e) =>
                //   changeValue({
                //     e,
                //     fieldname: 'warehouseInventory',
                //     value: { warehouseId: widgetCell.warehouseId, quantity: variantQuantity }
                //   })
                // }
              />
            </div>
          </th>
        );
    }
  };

  const handleTableScroll = (event) => {
    if (event.currentTarget.scrollLeft === 0) {
      setIsScrollLeft(false);
    } else {
      setIsScrollLeft(true);
    }
  };

  const closeMoreActionDropdown = () => {
    setShowMoreActions(false);
  };

  const handleMoreActions = (option) => {
    setShowMoreActions(false);
    setLabelPrintType(option);
    if (!productId || selectedVariants.some((item) => !item.id) || formChanges) {
      localStorage.setItem('shouldLabelPrinting', 'true');
      setShowProductSavingModal(true);
    } else {
      const selectedVariantIds = selectedVariants.map((variant) => variant.id);
      setSelectedVariantIds(selectedVariantIds);
      setShowLabelPrintModal(true);
    }
  };

  useEffect(() => {
    if (selectedVariants?.length > 0) {
      const selectedVariantTypes = selectedVariants?.map((item) => item.variantType);
      const updatedSelectedVariants = tableData?.filter((item) => selectedVariantTypes?.includes(item.variantType));
      setSelectedVariants(updatedSelectedVariants);
    }
  }, [tableData]);

  const MenuOptionItem = ({ value, optionText, printType }) => {
    const isDisablePrint = useMemo(() => {
      if (printType === 'sku' && value.some((item) => !item.sku)) {
        return true;
      }
      if (printType === 'barcode' && value.some((item) => !item.barcode)) {
        return true;
      }
      return false;
    }, [value]);

    return (
      <div
        onClick={() => {
          if (!isDisablePrint) handleMoreActions(printType);
        }}
        className={`d-flex justfy-content-center align-items-center dropdown-item dropdown-height`}
      >
        <>
          {isDisablePrint ? (
            <>
              <div
                aria-labelledby="dropdownMenuButton"
                className="dropdown-item-option opacity-50"
                data-tip
                data-for="printLabelAlert"
              >
                <span className="option-text poppins-medium">{optionText}</span>
              </div>
              <Tooltip
                tooltipId="printLabelAlert"
                place="top"
                content={() => {
                  return <>Add {printType === 'sku' ? 'SKU' : 'Barcode'} to print</>;
                }}
              />
            </>
          ) : (
            <div aria-labelledby="dropdownMenuButton" className="dropdown-item-option cursor-pointer">
              <span className="option-text poppins-medium">{optionText}</span>
            </div>
          )}
        </>
      </div>
    );
  };

  return (
    <>
      <div className="w-100">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="poppins-medium m-auto-0 text-nowrap variant-subtitle">{constVariables.common.variantions.subKey}</h3>
        </div>
        <div className="d-flex align-items-center justify-content-between m-t-24">
          <div className="d-flex align-items-center">
            <Checkbox
              labelclassname="me-0"
              name={constVariables.common.variantions.subVariantTitle}
              onChangeValue={() => setCheckedVariantOption(!checkedVariantOption)}
              value={checkedVariantOption}
              // disable={options.length < 2}
              disable={role === UserRoles.FULFILMENT_ASSOCIATE}
            />
            <OutsideClickHandler onOutsideClick={closeOptionMenu}>
              <div className="position-relative">
                <div onClick={opneOptionMenu} className="d-flex align-items-center  cursor-pointer">
                  <span className="helper-text-one fs-6 ms-2 text-primary">{selectedVariantOption}</span>
                  <img src={DownArrowBlueIcon} className="ps-1" alt={DownArrowBlueIcon} />
                </div>
                {optionsDropdownData ? (
                  <Dropdown
                    data={optionsDropdownData}
                    selected={optionMenu}
                    value={selectedVariantOption}
                    onSelect={selectOption}
                    closeDropdown={closeOptionMenu}
                  />
                ) : null}
              </div>
            </OutsideClickHandler>
          </div>
          {role !== UserRoles.FULFILMENT_ASSOCIATE && (
            <OutsideClickHandler
              onOutsideClick={() => {
                setOpenEditColumnDropdown(false);
              }}
            >
              <div className="position-relative">
                <div
                  className="text-primary cursor-pointer text-btn poppins-regular fw-normal"
                  onClick={() => setOpenEditColumnDropdown(!openEditColumnDropdown)}
                >
                  {constVariables.common.variantions.editColumns}
                </div>
                <EditColumn
                  show={openEditColumnDropdown}
                  columns={editColumnData}
                  setEditColumnData={setEditColumnData}
                  allSwitchable={true}
                />
              </div>
            </OutsideClickHandler>
          )}
        </div>
        {selectedVariants.length > 0 && (
          <FlexBox className="align-items-center mt-10">
            <button className="btn btn-outlined-primary btn-sm m-0 m-r-16" onClick={() => setSelectedVariants([])}>
              <div className="d-flex align-items-center">
                <input
                  className={'form-check-input small-checkbox-size shadow-none opacity-100 bg-primary rounded-sm m-0'}
                  type="checkbox"
                  disabled={true}
                  ref={(input) => {
                    if (input) {
                      input.indeterminate = true;
                    }
                  }}
                />
                <span className="ms-2">{selectedVariants.length} Selected</span>
              </div>
            </button>
            <button onClick={onDeleteVariant} className="btn btn-sm btn-danger m-0 m-r-16">
              Delete
            </button>
            <div className="position-relative">
              <button
                className={`btn btn-sm m-0 ${showMoreActions ? 'btn-primary' : 'btn-outlined-primary'}`}
                onClick={() => {
                  setShowMoreActions(!showMoreActions);
                }}
              >
                {constVariables.ProductsTab.moreActions}
              </button>
              <OutsideClickHandler onOutsideClick={closeMoreActionDropdown}>
                <div className={clsx(`dropdown-menu z-index-dropdown custom-dropdown`, { show: showMoreActions })}>
                  <MenuOptionItem value={selectedVariants} optionText="Print SKU" printType="sku" />
                  <MenuOptionItem value={selectedVariants} optionText="Print barcode" printType="barcode" />
                </div>
              </OutsideClickHandler>
            </div>
          </FlexBox>
        )}

        <div className="m-t-36 w-100 table-wrapper">
          <div className="table-responsive" onScroll={handleTableScroll}>
            <table className={`table gy-7 variant-table ${isScrollLeft ? 'scrolling-left' : ''}`} id="variant-table">
              <thead className="border-none">
                <tr className="fw-bolder border-transparent">
                  <th className="pt-0 px-2 variant-table-header-cell border-none">
                    <Checkbox
                      normal
                      inputclassname="outline-info"
                      labelclassname="form-check form-check-sm form-check-custom pe-0"
                      onChangeValue={updateAllRowHandler}
                      value={updateAllCheck}
                      disable={role === UserRoles.FULFILMENT_ASSOCIATE}
                      noVerticalPadding
                    />
                  </th>
                  <th className="pt-0 px-0 variant-table-header-cell border-none">Image</th>
                  <th className="pt-0 variant-table-header-cell variant-type-cell border-0">Variants</th>
                  {editColumnData
                    .filter((column) => column.status)
                    .map((column, index) => (
                      <th key={index} className="pt-0 variant-table-header-cell border-none">
                        {column.name}
                      </th>
                    ))}
                </tr>
                <tr className="table-dark-strip h-70px">
                  <th className="align-middle px-2 py-0 border-none"></th>
                  <th colSpan={2} className="align-middle variant-type-cell py-0 px-4 border-none">
                    Update all row at once
                  </th>
                  {editColumnData.filter((item) => item.status).map((item, index) => renderTableWidget(item, index))}
                </tr>
              </thead>
              <tbody className="table-border-bottom variant-table-content">
                {tableData.map((item: TableData, index: number) => (
                  <CustomTableRow
                    key={index}
                    item={item}
                    price={item.price}
                    variantType={item.variantType}
                    status={item.status}
                    barcode={item.barcode}
                    sku={item.sku}
                    warehouseInventory={item.warehouseInventory}
                    img={item.media}
                    weight={item.weight}
                    changeFieldValue={changeFieldValue}
                    index={index}
                    onChangeCheckBox={onChangeCheckBox}
                    checkboxValue={checkCheckboxValue(item.variantType)}
                    openImagesModalFun={openImagesModalFun}
                    editColumnData={editColumnData}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {openImagesModal && (
        <CustomModal bodyClassname="w-90 w-md-50" show={openImagesModal} closeModal={closeImagesModalFun}>
          <VariantImagesModal
            changeVariantImage={changeVariantImage}
            closeImagesModalFun={closeImagesModalFun}
            selectedImageURL={selectedImageURL}
            productMedia={productMedia}
            updateProductMedia={updateProductMedia}
          />
        </CustomModal>
      )}
    </>
  );
};

export default ProductVariationTable;
