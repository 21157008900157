/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_PRODUCTS_V3, GET_LOCATIONS, GET_SHOPIFY_ADMIN_BASE_URL } from 'src/apollo/queries';
import { orderWithErrors } from 'src/apollo/reactiveVariables';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { SearchBar, SortDropdown, RightFilterMenu, ShopifySyncTooltip, DataTable } from 'src/components/molecules';
import { CustomModal, LabelPrintModal, MoreActionDropdown } from 'src/components/oraganisms';
import InventoryLocation from './components/InventoryLocation';
import InventoryHistoryModal from '../InventoryHistoryModal/InventoryHistoryModal';
import InventoryStockLevel from './components/InventoryStockLevel';
import InventoryLowStockAlert from './components/InventoryLowStockAlert';
import ActionMenu from './components/ActionMenu';
import AvailableSale from './components/AvailableSale';
import ProductFilterChips from '../ProductList/components/ProductFilterChips';
import SortableTableHeader from 'src/components/molecules/DataTable/components/SortableTableHeader';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers/components/KTSVG';
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { DefaultImage, RightArrowIcon, FilterIcon, SortIcon, ShopifyIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';
import { SortIds } from './data';

// Types
import { InventoryManagementListData, LocationItem } from 'src/components/pages/inventory/InventoryManagementPage.type';
import { InventoryListProps } from './InventoryList.types';
import { moreActionMenuItem } from '../MoreActionDropdown/MoreActionDropdown.types';

// Styles
import './_inventoryList.scss';

const inventoryFilterMenuList = ['productTypes', 'tags', 'stockAvailability', 'locations'];

const InventoryList: FC<InventoryListProps> = ({
  inventoryProductList,
  setInventoryList,
  rapidActionOpen,
  setSortBy,
  sortBy,
  isLoading,
  totalCount,
  selectedWarehouseId,
  updateInventoryList,
  activeColumns,
  inventoryFilters,
  setInventoryFilters,
  getInventoryInput,
  setInventoryInput,
  handleClearInventoryFilter,
  showFilterChips,
  setShowFilterChips,
  handleUpdateInventoryFilters
}) => {
  const [inventoryListToDisplay, setInventoryListToDisplay] = useState([]);

  useEffect(() => {
    if (selectedWarehouseId) {
      const updated = inventoryProductList.map((product) => {
        if (product.inventory) {
          const parsedInventory = JSON.parse(product.inventory);
          if (parsedInventory.inventory && parsedInventory.inventory.length > 0) {
            const found = parsedInventory.inventory.find((i) => Number(i.warehouseId) === Number(selectedWarehouseId));
            if (found) {
              return {
                ...product,
                productQty: found.inventoryQuantity
              };
              // TODO if usersQuantity, committedQuantity by warehouseId?
              // productItem.usersQuantity = found.inventoryQuantity - productItem.committedQuantity;
            }
          }
        }
        return product;
      });
      // @ts-ignore
      return setInventoryListToDisplay(updated);
    }
    // @ts-ignore
    return setInventoryListToDisplay(inventoryProductList);
  }, [inventoryProductList, selectedWarehouseId]);
  const [ordersWithError, setOrdersWithError] = useState<Array<InventoryManagementListData>>(orderWithErrors() || []);
  const [selectedInventoryIDs, setSelectedInventoryIDs] = useState<Array<number>>([]);
  const [openSortDropdown, setOpenSortDropdown] = useState(false);
  const [allExpanded, setAllExpanded] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [wearhouseLocations, setWearhouseLocations] = useState<LocationItem[]>([]);
  const [showMoreActions, setShowMoreActions] = useState<boolean>(false);
  const [showLabelPrintModal, setShowLabelPrintModal] = useState(false);
  const [labelPrintType, setLabelPrintType] = useState('');
  const [expandedViewLabelPrintModal, setExpandedViewLabelPrintModal] = useState(false);
  const [printProductIds, setPrintProductIds] = useState<number[]>([]);
  const [printVariantIds, setPrintVariantIds] = useState<number[]>([]);

  const { data: { shopifyAdminBaseUrl } = { shopifyAdminBaseUrl: null } } = useQuery(GET_SHOPIFY_ADMIN_BASE_URL);

  useQuery(GET_LOCATIONS, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'network-only',
    variables: {
      input: {
        warehouseId: selectedWarehouseId
      }
    },
    onCompleted: (response) => {
      if (response) {
        setWearhouseLocations(
          response.getLocations.map((item) => {
            return {
              id: item.id,
              name: item.name,
              totalQuantity: item.totalQuantity,
              totalProducts: item.totalProducts,
              totalProductVariants: item.totalProductVariants
            };
          })
        );
      }
    }
  });

  const { loading: isProductVariantsLoading } = useQuery(GET_PRODUCTS_V3, {
    variables: {
      input: {
        warehouseIds: selectedWarehouseId ? `[${selectedWarehouseId.toString()}]` : undefined,
        productIdsString: JSON.stringify(
          inventoryProductList && inventoryProductList.length > 0 && selectedIndex !== -1 && inventoryProductList[selectedIndex]
            ? [inventoryProductList[selectedIndex]?.productId]
            : []
        ),
        detailed: true,
        filters: {
          locationIdsString: '[]'
        }
      }
    },
    skip: selectedIndex < 0,
    onCompleted: (res) => {
      if (res) {
        const productItem = res?.getAllProductsV3?.products[0];
        const updatedInventoryList = inventoryProductList.map((inventory) => {
          if (inventory.productId === productItem?.productId) {
            return productItem;
          }
          return inventory;
        });
        setInventoryList(updatedInventoryList);
      }
    },
    onError: (error) => {
      console.log('error InventoryList ::', error);
    }
  });

  useEffect(() => {
    setSelectedInventoryIDs([]);
  }, [getInventoryInput?.pageInfo?.limitCount, inventoryProductList]);

  useEffect(() => {
    setSelectedIndex(-1);
  }, [getInventoryInput?.pageInfo?.skipCount]);

  useEffect(() => {
    setOrdersWithError([...orderWithErrors()]);
  }, [orderWithErrors()]);

  const ChangeSortBy = useCallback(
    (field: string, id: number) => {
      if (sortBy.catagory === field) {
        if (sortBy.type === 'DESC') {
          setSortBy({
            ...sortBy,
            type: 'ASC'
          });
        } else {
          setSortBy({
            ...sortBy,
            type: 'DESC'
          });
        }
      } else {
        setSortBy({
          catagory: field,
          id,
          type: 'DESC'
        });
      }
      // setOpenSortDropdown(false);
    },
    [setSortBy, sortBy]
  );

  const selectInventory = useCallback(
    (e: any, inventory?: any) => {
      if (e === 'All') {
        setSelectedInventoryIDs(inventoryProductList.map((product) => product.productId));
      } else if (e.target.checked) {
        setSelectedInventoryIDs([...selectedInventoryIDs, inventory.productId]);
      } else {
        setSelectedInventoryIDs(selectedInventoryIDs.filter((inventoryItemId) => inventoryItemId !== inventory.productId));
      }
    },
    [inventoryProductList, selectedInventoryIDs]
  );

  const expandAllFun = useCallback(() => {
    let temp: number[] = [];
    if (inventoryProductList) {
      inventoryProductList.map((data) => (temp = [...temp, data.id]));
    }
    setAllExpanded(true);
  }, [inventoryProductList]);

  const shrinkAllFun = () => {
    setAllExpanded(false);
  };

  const expandInventory = (id: number, index: number) => {
    if (index === selectedIndex) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(index);
    }
  };

  const handleActionMenus = (level: string, productId: number, printType: string, variantId?: number) => {
    setLabelPrintType(printType);
    setShowLabelPrintModal(true);
    setPrintProductIds([productId]);
    setPrintVariantIds(variantId ? [variantId] : []);
  };

  const goToShopifyPage = (shopifyProductId) => {
    if (shopifyAdminBaseUrl) window.open(`${shopifyAdminBaseUrl}products/${shopifyProductId}`, '_blank');
  };

  const initialColumnOptions = [
    {
      dataKey: 'productName',
      name: 'Products',
      width: 400,
      headerRenderer: () => {
        return (
          <div className="form-check form-check-sm form-check-custom">
            <input
              className="m-r-20 form-check-input widget-9-check"
              type="checkbox"
              checked={inventoryProductList?.length > 0 && inventoryProductList.length === selectedInventoryIDs.length}
              onChange={() => {
                if (inventoryProductList.length === selectedInventoryIDs.length) {
                  setSelectedInventoryIDs([]);
                } else {
                  selectInventory('All');
                }
              }}
            />
            <div className="opacity-0 h-20x w-20x p-8 d-flex align-items-center justify-content-center rounded m-r-16">
              <img src={RightArrowIcon} className={`h-10px w-10px opacity-0 ${allExpanded ? 'rotatedArrowIcon' : ''}`} />
            </div>
            <SortableTableHeader label="Products" sortHeader={() => ChangeSortBy('Product name', 21)} type={sortBy.type} />
          </div>
        );
      },
      cellRenderer: ({ rowData, rowIndex }) => {
        return (
          <div className="d-flex align-items-center">
            <div className="form-check form-check-sm form-check-custom">
              <input
                className="m-r-20 form-check-input widget-9-check"
                type="checkbox"
                checked={selectedInventoryIDs.includes(rowData.productId)}
                onChange={(e) => {
                  selectInventory(e, rowData);
                }}
              />
            </div>
            <div
              onClick={() => {
                if (rowData?.productHasVariants) {
                  expandInventory(rowData.productId, rowIndex);
                }
              }}
              className={`h-20x w-20x p-8 d-flex align-items-center justify-content-center m-r-16 cursor-pointer ${
                rowData?.productHasVariants ? '' : 'opacity-0'
              }`}
            >
              <img src={RightArrowIcon} className={`h-10px w-10px ${rowIndex === selectedIndex ? 'rotatedArrowIcon' : ''}`} />
            </div>
            <div className="align-items-center d-flex">
              <div className="symbol me-5">
                <Img
                  src={setImageSrc(
                    rowData?.productMediasJson
                      ? generateCompressedImageURL(rowData?.productMediasJson[0]?.productMediaUrl, '50')
                      : DefaultImage
                  )}
                  placeholderImg={DefaultImage}
                  errorImg={DefaultImage}
                  height={50}
                  width={50}
                  className="object-fit-scale-down bg-white border border-light border-2"
                />
                {rowData?.externalProductProvider === 'SHOPIFY' && (
                  <>
                    <img
                      src={ShopifyIcon}
                      className="cursor-pointer ms-1 shopify-icon position-absolute"
                      data-tip
                      data-for="shopifySyncIcon"
                      title="Product from Shopify"
                      onClick={() => goToShopifyPage(rowData?.externalProductId)}
                    />
                    {/* <ShopifySyncTooltip tooltipText="Product from Shopify" /> */}
                  </>
                )}
              </div>
              {rowData.productName ? (
                <Link
                  to={{
                    pathname: `/products/allProducts/edit/${rowData.productId}`,
                    state: { from: ROUTES.products.inventory.main }
                  }}
                  className="main-cell"
                >
                  <span className="d-block">{rowData.productName}</span>
                </Link>
              ) : null}
            </div>
          </div>
        );
      },
      staticColumn: true
    },
    {
      dataKey: 'sku',
      name: 'SKU',
      width: 150,
      headerRenderer: () => {
        return <SortableTableHeader label="Sku" sortHeader={() => ChangeSortBy('sku', 82)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return <FlexBox className="align-items-center">{rowData.productSku ? rowData.productSku : '-'}</FlexBox>;
      }
    },
    {
      dataKey: 'location',
      name: 'Location',
      width: 200,
      headerRenderer: () => {
        return <SortableTableHeader label="Location" sortHeader={() => ChangeSortBy('location', 83)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return (
          <InventoryLocation
            type="PRODUCT"
            id={rowData?.productId}
            productId={rowData?.productId}
            defaultLocationId={rowData?.productLocation ? rowData?.productLocation[0]?.productLocationId : undefined}
            locationName={rowData?.productLocation ? rowData?.productLocation[0].productLocationName : '-'}
            showEditIcon={rowData?.productHasVariants ? false : true}
            selectedWarehouseId={selectedWarehouseId}
            updateInventoryList={updateInventoryList}
            wearhouseLocations={wearhouseLocations}
          />
        );
      }
    },
    {
      label: constVariables.inventoryEditColumns.availableSale,
      dataKey: 'availableSale',
      name: 'Available for sale',
      width: 200,
      headerRenderer: () => {
        return (
          <SortableTableHeader
            label="Available for sale"
            sortHeader={() => ChangeSortBy('availableStocks', 22)}
            type={sortBy.type}
          />
        );
      },
      cellRenderer: ({ rowData }) => {
        return <AvailableSale quantity={rowData?.usersQuantity} />;
      }
    },
    {
      dataKey: 'committed',
      name: 'Committed',
      width: 200,
      headerRenderer: () => {
        return (
          <SortableTableHeader label="Committed" sortHeader={() => ChangeSortBy('committedQuantity', 84)} type={sortBy.type} />
        );
      },
      cellRenderer: ({ rowData }) => {
        return <FlexBox className="align-items-center">{rowData?.committedQuantity ? rowData?.committedQuantity : '-'}</FlexBox>;
      }
    },
    {
      label: constVariables.inventoryEditColumns.lowStockAlert,
      dataKey: 'lowStockAlert',
      name: 'Low stock alert',
      width: 200,
      cellRenderer: ({ rowData }) => {
        return (
          <InventoryLowStockAlert
            type="PRODUCT"
            id={rowData?.productId}
            productId={rowData?.productId}
            quantity={rowData?.alertThreshold}
            showEditIcon={rowData?.productHasVariants ? false : true}
            updateInventoryList={updateInventoryList}
          />
        );
      },
      headerRenderer: () => {
        return (
          <SortableTableHeader label="Low stock alert" sortHeader={() => ChangeSortBy('lowStockAlert', 85)} type={sortBy.type} />
        );
      }
    },
    {
      label: constVariables.inventoryEditColumns.stockLevel,
      dataKey: 'stockLevel',
      name: 'Stock level',
      width: 200,
      headerRenderer: () => {
        return <SortableTableHeader label="Stock level" sortHeader={() => ChangeSortBy('stockLevel', 86)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return (
          <InventoryStockLevel
            type="PRODUCT"
            id={rowData?.productId}
            productId={rowData?.productId}
            locationId={rowData?.productLocation ? rowData?.productLocation[0]?.productLocationId : undefined}
            quantity={rowData?.productQty}
            showEditIcon={rowData?.productHasVariants ? false : true}
            selectedWarehouseId={selectedWarehouseId}
            updateInventoryList={updateInventoryList}
          />
        );
      }
    },
    {
      dataKey: 'action',
      name: '',
      width: 50,
      cellRenderer: ({ rowData }) => {
        return <ActionMenu level="product" productId={rowData?.productId} handleActionMenus={handleActionMenus} />;
      },
      staticColumn: true
    }
  ];

  const columnOptions = useMemo(() => {
    const activeColumnNames = activeColumns?.map((column) => column.name);
    // eslint-disable-next-line array-callback-return
    const filteredColumns = initialColumnOptions
      ?.filter((column) => {
        if (column.staticColumn || activeColumnNames?.includes(column?.name)) return true;
        return false;
      })
      .sort(function (a, b) {
        if (a.dataKey === 'action') return 1;
        return activeColumnNames.indexOf(a.name) - activeColumnNames.indexOf(b.name);
      });
    return filteredColumns;
  }, [activeColumns, selectedInventoryIDs, selectedIndex, inventoryProductList, shopifyAdminBaseUrl]);

  const handleOpenHistoryModal = () => {
    document.body.classList.add('modal-open');
    setShowHistoryModal(true);
  };

  const handleCloseHistoryModal = () => {
    document.body.classList.remove('modal-open');
    setShowHistoryModal(false);
  };

  const handleSearch = (value) => {
    setInventoryInput((getInventoryInput) => {
      return {
        ...getInventoryInput,
        filters: {
          ...getInventoryInput.filters,
          searchText: value
        }
      };
    });
  };

  const handleOpenFilterMenu = () => {
    document.body.style.overflow = 'hidden';
    setShowFilterMenu(true);
  };

  const handleCloseFilterMenu = () => {
    document.body.style.overflow = 'unset';
    setShowFilterMenu(false);
  };

  const handleInventoryFilter = (filters) => {
    setInventoryInput((getInventoryInput) => {
      return {
        ...getInventoryInput,
        filters: {
          ...getInventoryInput.filters,
          tags: filters.tags ? filters.tags.map((tag) => tag.id) : [],
          productTypes: filters.productTypes ? filters.productTypes.map((productType) => productType.id) : [],
          stockAvailability: filters?.stockAvailability ? filters?.stockAvailability : undefined
        }
      };
    });
    setInventoryFilters((inventoryFilters) => {
      return {
        ...inventoryFilters,
        tags: filters.tags,
        productTypes: filters.productTypes,
        stockAvailability: filters?.stockAvailability ? filters?.stockAvailability : undefined
      };
    });
    setShowFilterChips(true);
  };

  const closeMoreActionDropdown = () => {
    setShowMoreActions(false);
  };

  const handleMoreActions = (labelType) => {
    setPrintProductIds(selectedInventoryIDs);
    setLabelPrintType(labelType);
    setShowMoreActions(false);
    setShowLabelPrintModal(true);
  };

  const handleCloseLabelPrintModal = () => {
    document.body.style.overflow = 'unset';
    setShowLabelPrintModal(false);
  };

  const moreActionMenus: Array<moreActionMenuItem[]> = [
    [
      {
        id: 1,
        text: 'Print SKU',
        onClick: () => handleMoreActions('sku')
      },
      {
        id: 2,
        text: 'Print barcode',
        onClick: () => handleMoreActions('barcode')
      }
    ]
  ];

  return (
    <>
      <div className="w-100">
        <div className={clsx({ 'display-none': totalCount === 0 && !getInventoryInput.filters.searchText })}>
          <FlexBox className="justify-content-between w-100">
            <div className="searchBar">
              <SearchBar placeholder={constVariables.WaitlistsMenu.searchForProducts} onChange={handleSearch} />
            </div>
            <FlexBox className="pb-1 align-items-center">
              <div className="text-btn text-primary cursor-pointer" onClick={handleOpenHistoryModal}>
                Inventory History
              </div>
              <div className="position-relative ms-3">
                <button
                  className={`btn optBtn btn-sm btn-flex btn-light fw-bolder ${
                    openSortDropdown ? 'btn-primary' : 'btn-active-primary'
                  }`}
                  onClick={() => {
                    setOpenSortDropdown(!openSortDropdown);
                  }}
                >
                  <KTSVG path={SortIcon} className="svg-icon-8 svg-icon-gray-500 me-1" />
                  <span className="my-auto me-0 poppins-semibold f-14px">Sort</span>
                </button>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    if (openSortDropdown) setOpenSortDropdown(false);
                  }}
                >
                  <SortDropdown
                    data={SortIds}
                    value={sortBy}
                    onSelect={ChangeSortBy}
                    selected={openSortDropdown}
                    closeDropdown={() => setOpenSortDropdown(false)}
                  />
                </OutsideClickHandler>
              </div>
              <button
                className="btn optBtn btn-sm btn-flex btn-light btn-active-primary fw-bolder ms-3"
                onClick={handleOpenFilterMenu}
              >
                <KTSVG path={FilterIcon} className="svg-icon-8 svg-icon-gray-500 me-1" />
                <span className="my-auto me-0 poppins-semibold f-14px">Filter</span>
              </button>
            </FlexBox>
          </FlexBox>
        </div>

        {!isLoading && totalCount === 0 && !getInventoryInput.filters.searchText ? (
          ''
        ) : showFilterChips ? (
          <ProductFilterChips
            filters={inventoryFilters}
            setProductFilters={setInventoryFilters}
            updateProductFilters={handleUpdateInventoryFilters}
            handleClearProductFilter={handleClearInventoryFilter}
          />
        ) : (
          ''
        )}

        {selectedInventoryIDs.length > 0 && (
          <FlexBox className="m-t-24 justify-content-start align-items-center">
            <button className="btn btn-outlined-primary btn-sm me-2" onClick={() => setSelectedInventoryIDs([])}>
              <div className="d-flex align-items-center">
                <input
                  className={'form-check-input small-checkbox-size shadow-none opacity-100 bg-primary rounded-sm m-0'}
                  type="checkbox"
                  disabled={true}
                  ref={(input) => {
                    if (input) {
                      input.indeterminate = true;
                    }
                  }}
                />
                <span className="ms-2">{selectedInventoryIDs.length} Selected</span>
              </div>
            </button>
            <div className="position-relative">
              <button
                className={`btn btn-sm m-r-16 ${showMoreActions ? 'btn-primary' : 'btn-outlined-primary'}`}
                onClick={() => {
                  setShowMoreActions(!showMoreActions);
                }}
              >
                {constVariables.ProductsTab.moreActions}
              </button>
              <OutsideClickHandler onOutsideClick={closeMoreActionDropdown}>
                <MoreActionDropdown selected={showMoreActions} options={moreActionMenus} closeDropdown={closeMoreActionDropdown} />
              </OutsideClickHandler>
            </div>
          </FlexBox>
        )}

        <div className="p-t-12">
          <DataTable
            page="inventory"
            rowData={inventoryListToDisplay}
            columnOptions={columnOptions}
            isLoading={isLoading}
            searchText={getInventoryInput.filters.searchText}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            selectedWarehouseId={selectedWarehouseId}
            updateInventoryList={updateInventoryList}
            selectedIDs={selectedInventoryIDs}
            isDetailsLoading={isProductVariantsLoading}
            wearhouseLocations={wearhouseLocations}
            handleInventoryActionMenus={handleActionMenus}
          />
        </div>
      </div>
      <RightFilterMenu
        closeMenu={handleCloseFilterMenu}
        showFilterMenu={showFilterMenu}
        handleFilter={handleInventoryFilter}
        filterData={inventoryFilters}
        setFilterData={setInventoryFilters}
        setProductInput={setInventoryInput}
        getProductInput={getInventoryInput}
        handleClearProductFilter={handleClearInventoryFilter}
        menuList={inventoryFilterMenuList}
      />
      {showHistoryModal && <InventoryHistoryModal show={true} closeModal={handleCloseHistoryModal} />}
      {showLabelPrintModal && (
        <CustomModal
          show={showLabelPrintModal}
          closeModal={handleCloseLabelPrintModal}
          bodyClassname={`${expandedViewLabelPrintModal ? 'm-0' : ''}`}
        >
          <LabelPrintModal
            closeModal={handleCloseLabelPrintModal}
            labelPrintType={labelPrintType}
            expandedViewLabelPrintModal={expandedViewLabelPrintModal}
            toggleExpand={() => setExpandedViewLabelPrintModal(!expandedViewLabelPrintModal)}
            selectedProducts={printProductIds}
            variantIds={printVariantIds}
          />
        </CustomModal>
      )}
    </>
  );
};

export default React.memo(InventoryList);
