import React, { FC, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';

// Apollo
import { useLazyQuery, useQuery } from '@apollo/client';
import { CALCULATE_SHIPPING_CHARGES, GET_SHOPIFY_ADMIN_BASE_URL } from 'src/apollo/queries';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { DropdownSelector, FlexBox, Img, RadioButton } from 'src/components/atoms';
import { Dropdown, Checkbox, InputBox, Divider, FixedHeaderTable, ShopifySyncTooltip, Tooltip } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { checkInvalidCharacter } from 'src/utils/validate';

// Icons
import { DefaultImage, InfoIcon, InfoItalicIcon, ShopifyIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';
// Types
import { ShippingCostObject, ShippingSection } from 'src/components/pages/products/AddProduct/AddProduct.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

// Styles
import './_shippingSettingsFressShippingCoupons.scss';
import { RowObjectProps, TablesProps } from '../../molecules/Table/Table.types';
import { GET_ALL_COUPONS_LIST } from '../../../apollo/queries/getAllCouponsList';
import { Link } from 'react-router-dom';

const AllCouponStatus = ['Active', 'Scheduled', 'Expired', 'Inactive'];

const selectCouponStatusClass = (status: string | undefined) => {
  if (status) {
    return status.toLowerCase();
  }
  return '';
};

const renderProductStatus = (couponStatus) => {
  return AllCouponStatus.find((status) => {
    return couponStatus.toLowerCase() === status?.toLowerCase();
  });
};

const ShippingSettingsFressShippingCoupons = () => {
  const [total, setTotal] = useState(0);
  const [couponsList, setCouponsList] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [getCoupons, { loading: isCouponLoading }] = useLazyQuery(GET_ALL_COUPONS_LIST, {
    onCompleted: (res) => {
      console.log('GET_ALL_COUPONS_LIST', res.getAllCouponsList.couponsList);
      const count = res.getAllCouponsList.total;
      if (couponsList.length === 0) {
        setCouponsList(res.getAllCouponsList.couponsList);
      } else {
        const all = [...couponsList, ...res.getAllCouponsList.couponsList];
        // @ts-ignore
        setCouponsList(all);
        if (count === all?.length) {
          setIsLoadingMore(false);
        }
      }
      setTotal(count);
    }
  });
  const [sortBy, setSortby] = useState({
    column: 'NAME',
    order: 'ASC',
    id: 881,
    displayName: 'Coupon'
  });

  useEffect(() => {
    setCouponsList([]);
    setTotal(0);
    getCoupons({
      variables: {
        input: {
          pagination: {
            skipCount: 0,
            limitCount: 50
          },
          status: '',
          sortBy: sortBy.column,
          sortType: sortBy.order,
          filters: {
            searchText: '',
            isFreeShippingCoupon: true
          }
        }
      }
    });
  }, [sortBy]);
  const ChangeSortBy = (field: string, id: number, displayName: string) => {
    if (sortBy.column === field) {
      if (sortBy.order === 'DESC') {
        setSortby({ ...sortBy, order: 'ASC' });
      } else {
        setSortby({ ...sortBy, order: 'DESC' });
      }
    } else {
      setSortby({ column: field, id, displayName, order: 'DESC' });
    }
  };

  const { data: { shopifyAdminBaseUrl } = { shopifyAdminBaseUrl: null } } = useQuery(GET_SHOPIFY_ADMIN_BASE_URL);

  const goToShopifyPage = (shopifyCouponId) => {
    if (shopifyAdminBaseUrl) window.open(`${shopifyAdminBaseUrl}discounts/${shopifyCouponId}`, '_blank');
  };

  const tableData: TablesProps = useMemo(() => {
    const arr: Array<RowObjectProps> = [];
    if (couponsList.length > 0) {
      couponsList.map((product: any, index) => {
        const cells = [
          {
            className: `align-middle ps-5 py-0 product-name-cell w-80 ${index !== 0 ? 'table-top-border' : 'border-0'}`,
            value: (
              <FlexBox className="align-items-center justify-content-start me-5">
                <Link to={`${ROUTES.coupons.createCoupon.main}/${product.id}`} className="coupon-link">
                  <span>{product.name}</span>
                </Link>
                {product?.externalDiscountProvider === 'SHOPIFY' && (
                  <>
                    <img
                      src={ShopifyIcon}
                      className="cursor-pointer m-l-12"
                      data-tip
                      data-for="shopifySyncIcon"
                      onClick={() => goToShopifyPage(product?.externalDiscountId)}
                    />
                    <ShopifySyncTooltip tooltipText="Coupon from Shopify" />
                  </>
                )}
              </FlexBox>
            )
          },
          {
            className: `align-middle p-0 position-relative ${index !== 0 ? 'table-top-border' : 'border-0'}`,
            value: (
              <div
                className={`p-2 poppins-medium text-center points-none ${selectCouponStatusClass(
                  product?.status?.toLowerCase()
                )}-status-box`}
              >
                <span>{renderProductStatus(product?.status)}</span>
              </div>
            )
          }
        ];
        arr.push({
          className: `position-relative h-fit-content table-row cursor-pointer`,
          id: product.id,
          cells
        });
        return null;
      });
    }
    return {
      isCatalog: true,
      sortByCategory: sortBy.column,
      sortByType: sortBy.order,
      isDragDisabled: true,
      headerClassName: 'catalog-header bg-light',
      className: 'overflow-visible',
      rows: arr
    };
  }, [sortBy, couponsList]);

  tableData.headers = [
    {
      id: 2,
      className: `align-middle p-0 border-bottom-0 table-header-background ps-5 w-80`,
      colName: 'Coupon',
      sorting: true,
      applySort: () => {
        // ChangeSortBy('NAME', 619, 'Product');
        ChangeSortBy('NAME', 881, 'Coupon');
      },
      category: 'name'
    },
    {
      id: 5,
      className: 'align-middle border-bottom-0 table-header-background',
      colName: 'Status'
    }
  ];

  const handleTableScroll = (event: React.UIEvent<HTMLDivElement>) => {
    debouncedScroll(event);
  };

  const debouncedScroll = debounce(async (event) => {
    const scrollPosition = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const offsetHeight = event.target.offsetHeight;
    const skipCount = couponsList.length;

    if (isCouponLoading || skipCount === 0) return;

    if (scrollPosition + offsetHeight > scrollHeight - 10) {
      if (total <= couponsList.length) {
        setIsLoadingMore(false);
        return;
      }
      setIsLoadingMore(true);
      getCoupons({
        variables: {
          input: {
            pagination: {
              skipCount,
              limitCount: 50
            },
            status: '',
            sortBy: sortBy.column,
            sortType: sortBy.order,
            filters: {
              searchText: '',
              isFreeShippingCoupon: true
            }
          }
        }
      });
    }
  }, 1000);

  return (
    <div className="card w-100">
      <h4 className="section-title m-b-24">Free Shipping Coupons</h4>
      <div className="table-wrapper rules-engine-product-table">
        <FixedHeaderTable
          headers={tableData.headers}
          sortByType={tableData.sortByType}
          sortByCategory={tableData.sortByCategory}
          rows={tableData.rows}
          isLoading={isCouponLoading}
          loadingPosition={isLoadingMore ? 'bottom' : 'center'}
          // searchText={getProductInput.filters.searchText}
          isCatalog={tableData.isCatalog}
          categoryInfo={tableData.categoryInfo}
          type="coupon"
          containerHeight={500}
          onScroll={handleTableScroll}
          noMargin
        />
      </div>
    </div>
  );
};

export default ShippingSettingsFressShippingCoupons;
