/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';

// Components
import { FlexBox } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { SortDownArrow, SortUpArrow } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { SortDropdownProps } from './SortDropdown.types';

// Styles
import './sortDropdown.scss';
import { DEFAULT_SORT_BY_ID } from '../../oraganisms/OrdersList/data';

const SortDropdown: FC<SortDropdownProps> = ({ data, selected, onSelect, value, className, type, closeDropdown }) => {
  const [hoverEffect, setHoverEffect] = useState(true);
  const defaultSort = data.find((sort) => sort.default);

  const shiftLeft: {
    width: string;
    top: string;
    zIndex: number;
    left?: string;
  } = {
    width: '180px',
    top: '45px',
    zIndex: 150
  };

  if (type !== 'collectionProduct') {
    shiftLeft.left = '-225px';
  }

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      closeDropdown();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  return (
    <div
      className={clsx(
        `menu menu-sub menu-background menu-sub-dropdown menu-column menu-rounded menu-gray-600 w-300px position-absolute p-0 sort-dropdown ${className}`,
        { show: selected }
      )}
      style={shiftLeft}
      data-kt-menu="true"
    >
      <FlexBox className="border-bottom border-bottom-secondary w-100 justify-content-between dropdown-header">
        <div className="poppins-medium">{constVariables.ProductsTab.sortBy}</div>
        {type !== 'order' && (
          <div
            className="text-primary cursor-pointer"
            onClick={() => {
              defaultSort ? onSelect(defaultSort.name, defaultSort.id, true) : onSelect('Default', 0);
            }}
          >
            {constVariables.ProductsTab.default}
          </div>
        )}
      </FlexBox>
      <div className="dropdown-content">
        {data?.map((item, index) => {
          return (
            <div className="menu-item" key={index}>
              <a
                className={`dropdown-item menu-link mb-0 ${value.id === item.id ? 'text-primary' : ''} ${
                  hoverEffect ? '' : 'no-hover'
                }`}
                onClick={() => {
                  if (value.catagory !== item.name) onSelect(item.name, item.id);
                }}
              >
                <FlexBox className="w-100 justify-content-between align-items-center">
                  <div>{item.label ? item.label : item.name}</div>
                  {item.id !== DEFAULT_SORT_BY_ID && (
                    <div
                      onClick={() => {
                        onSelect(item.name, item.id);
                      }}
                      className={`d-flex sort-arrow-div align-items-center justify-content-center ${
                        value.catagory !== item.name ? 'invisible' : ''
                      }`}
                      onMouseEnter={() => {
                        if (value.id === item.id) setHoverEffect(false);
                      }}
                      onMouseLeave={() => {
                        setHoverEffect(true);
                      }}
                    >
                      <KTSVG path={value.type === 'ASC' ? SortUpArrow : SortDownArrow} className="sort-icon" />
                    </div>
                  )}
                </FlexBox>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SortDropdown;
