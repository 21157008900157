import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { format, addWeeks, startOfWeek, subWeeks, addDays, lastDayOfWeek, isSameDay } from 'date-fns';
// import FullCalendar, { EventClickArg } from '@fullcalendar/react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import clsx from 'clsx';
import moment from 'moment';

// Components
import { Img } from 'src/components/atoms';
import { MidEventContent, Tooltip } from 'src/components/molecules';
import { LiveshowSearchDropdown, LiveshowSearchComponent } from 'src/components/oraganisms';

// Icons
import { TinyBlueLeftArrow, TinyBlueRightArrow, TinyLeftArrow, TinyRightArrow } from 'src/assets/icons';

// ConstVariables
import { ROUTES } from 'src/constants';

// Types
import { RenderEventInfoType } from './LiveshowCalendarComponent.types';
import { LiveShowCalendarEvents } from 'src/components/pages/liveshow/LiveShowCalendar/LiveShowCalendar.types';

// Styles
import './_liveshowCalendarComponent.scss';

export interface LiveShowCalendarProps {
  eventList: LiveShowCalendarEvents[];
  showEventDetail: (eventObj: LiveShowCalendarEvents) => void;
  createLiveShow: () => void;
  setCurrentMonth: React.Dispatch<React.SetStateAction<Date>>;
  currentMonth: Date;
  toggleLiveShowModal: () => void;
  setDefaultStartTime: (time: string) => void;
}

const LiveshowCalendarComponent: FC<LiveShowCalendarProps> = ({
  eventList,
  showEventDetail,
  setCurrentMonth,
  currentMonth,
  toggleLiveShowModal,
  setDefaultStartTime
}) => {
  const location = useLocation();
  const calendarRef = useRef<any>(null);
  // State Params
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [calendarEvents, setCalendarEvents] = useState<any[]>([]);
  const [leftArrHover, setLeftArrHover] = useState(false);
  const [rightArrHover, setRightArrHover] = useState(false);

  const getDate = (dayString: string) => {
    const today = new Date();
    const year = today.getFullYear().toString();
    let month = today.getMonth().toString();

    if (month.length === 1) {
      month = 0 + month;
    }
    return dayString?.replace('YEAR', year).replace('MONTH', month);
  };

  const getEvenBackCol = (startTime: string, endTime: string, isLive) => {
    const givenStartTime = moment(startTime);
    const givenEndTime = moment(endTime);
    const currentTime = moment(new Date());
    if (givenEndTime.isBefore(currentTime)) {
      return '#F8F8FA';
    } else if (currentTime.isBefore(givenStartTime)) {
      return '#F2FBFF';
    } else {
      if (isLive) {
        return '#FFF6F7';
      } else {
        return '#F2FBFF';
      }
    }
  };
  const getEvenBorderCol = (startTime: string, endTime: string, isLive) => {
    const givenStartTime = moment(startTime);
    const givenEndTime = moment(endTime);
    const currentTime = moment(new Date());
    if (givenEndTime.isBefore(currentTime)) {
      return '#1E27494D';
    } else if (currentTime.isBefore(givenStartTime)) {
      return '#00A3FF';
    } else {
      if (isLive) {
        return '#F64E60';
      } else {
        return '#00A3FF';
      }
    }
  };

  useEffect(() => {
    const eventData = eventList.map((mapData) => {
      return {
        id: mapData.id,
        title: mapData.title,
        start: getDate(mapData.startingAt),
        end:
          mapData.startingAt === mapData.endingAt
            ? getDate(new Date(new Date(mapData.endingAt).setMinutes(new Date(mapData.endingAt).getMinutes() + 1)).toISOString())
            : getDate(mapData.endingAt),
        extendedProps: { products: mapData.products },
        backgroundColor: getEvenBackCol(mapData.startingAt, mapData.endingAt, mapData.isLive),
        borderColor: getEvenBorderCol(mapData.startingAt, mapData.endingAt, mapData.isLive),
        textColor: 'black',
        isLive: mapData.isLive
      };
    });
    setCalendarEvents(eventData);
  }, [eventList]);

  const renderHeader = () => {
    const prevDateFormat = 'MMM';
    const dateFormat = 'MMM yyyy';
    const startMonth = startOfWeek(currentMonth, { weekStartsOn: 0 });
    const endMonth = lastDayOfWeek(currentMonth, { weekStartsOn: 0 });

    const renderHead = () => {
      if (startMonth.getMonth() > endMonth.getMonth()) {
        return <div>{`${format(startMonth, prevDateFormat)} - ${format(endMonth, dateFormat)}`}</div>;
      }
      if (startMonth.getMonth() < endMonth.getMonth()) {
        return <div>{`${format(startMonth, prevDateFormat)} - ${format(endMonth, dateFormat)}`}</div>;
      }

      return <div>{`${format(currentMonth, dateFormat)}`}</div>;
    };

    return (
      <div className="position-relative m-b-20 d-flex align-items-center justify-content-center">
        <div className="position-absolute absoluteLeft d-flex flex-direction-row ">
          <div
            className="cursor-pointer moveButtons roundedLeft d-flex align-items-center justify-content-center w-40px h-40px"
            onClick={() => {
              changeWeekHandle('prev');
            }}
            onMouseEnter={() => {
              setLeftArrHover(true);
            }}
            onMouseLeave={() => {
              setLeftArrHover(false);
            }}
            data-tip
            data-for="prevWeekToolTip"
          >
            {leftArrHover ? (
              <Img className="rotateArr h-10px w-10px" src={TinyBlueLeftArrow} />
            ) : (
              <Img className="h-10px w-10px" src={TinyLeftArrow} />
            )}
          </div>
          <Tooltip
            tooltipId="prevWeekToolTip"
            place="top"
            content={() => {
              return <>Previous week</>;
            }}
          />

          <div
            className="cursor-pointer moveButtons roundedRight d-flex align-items-center justify-content-center w-40px h-40px ms-1"
            onClick={() => {
              changeWeekHandle('next');
            }}
            onMouseEnter={() => {
              setRightArrHover(true);
            }}
            onMouseLeave={() => {
              setRightArrHover(false);
            }}
            data-tip
            data-for="nextWeekToolTip"
          >
            {rightArrHover ? (
              <Img className="h-10px w-10px" src={TinyBlueRightArrow} />
            ) : (
              <Img className="h-10px w-10px" src={TinyRightArrow} />
            )}
          </div>
          <Tooltip
            tooltipId="nextWeekToolTip"
            place="top"
            content={() => {
              return <>Next week</>;
            }}
          />

          <div
            className="ms-4 fs-8 moveText cursor-pointer moveButtons rounded d-flex align-items-center justify-content-center px-5  h-40px"
            onClick={goToToday}
            data-tip
            data-for="todayToolTip"
          >
            Today
          </div>
          <Tooltip
            tooltipId="todayToolTip"
            place="top"
            content={() => {
              return <>Today</>;
            }}
          />
        </div>

        <div className="w-100 headerMonthStyle d-flex align-items-center justify-content-center">{renderHead()}</div>

        <LiveshowSearchDropdown handleSelectLiveshow={showEventDetail} />
      </div>
    );
  };

  const renderDays = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 0 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 0 });
    const startWeek = startOfWeek(currentMonth, { weekStartsOn: 0 });
    const rows: React.ReactElement[] = [];
    let days: React.ReactElement[] = [];
    const weekDateFormat = 'EEE';
    let formattedDate = '';
    const dateFormat = 'd';
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        days.push(
          <div className={`d-flex align-items-center justify-content-center`} key={i}>
            <div className={`w-50px py-1 rounded ${isSameDay(day, selectedDate) ? 'bg-primary color-white' : ''}`}>
              <div
                className={`d-flex align-items-center justify-content-center ${
                  isSameDay(day, selectedDate) ? 'color-white' : 'helper-text'
                }`}
              >
                {format(addDays(startWeek, i), weekDateFormat)}
              </div>

              <div className={`d-flex align-items-center justify-content-center date-number-text`} key={day?.getTime()}>
                <span className="number">{formattedDate}</span>
              </div>
            </div>
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="DatesSelectorWeekContainer ps-15" key={day?.getTime()}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="m-b-20">{rows}</div>;
  };

  const changeWeekHandle = (btnType: string) => {
    const calendarApi = calendarRef.current.getApi();
    if (btnType === 'prev') {
      calendarApi.prev();
      setCurrentMonth(subWeeks(currentMonth, 1));
    }
    if (btnType === 'next') {
      calendarApi.next();
      setCurrentMonth(addWeeks(currentMonth, 1));
    }
  };

  const goToToday = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.today();
    setCurrentMonth(new Date());
    setSelectedDate(new Date());
  };

  const renderEventContent = (eventInfo: RenderEventInfoType) => {
    return <MidEventContent hidden={false} event={eventInfo.event} showEventDetail={showEventDetail} eventList={eventList} />;
  };

  const handleEventClick = (eventInfo): void => {
    // get event object from id and pass the detail to slider
    const eventData = eventList.find((event) => event.id.toString() === eventInfo.event.id);
    if (eventData) {
      showEventDetail(eventData);
    }
  };

  const onSelectTimeSlot = (info) => {
    if (moment(info.startStr).isAfter(moment())) {
      setDefaultStartTime(info.startStr);
      toggleLiveShowModal();
    }
  };

  const handleCalendarScroll = () => {
    const cardElements = Array.from(document.getElementsByClassName('evenContentDB') as HTMLCollectionOf<HTMLElement>);
    cardElements.forEach((element) => {
      element.style.display = 'none';
    });
  };

  const handleViewDidMount = () => {
    const startElement = document.querySelector('td[data-time="08:00:00"]');
    if (startElement) {
      startElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  return (
    <>
      {location.pathname.includes(ROUTES.liveShow.calendar.search) ? (
        <LiveshowSearchComponent handleSelectLiveshow={showEventDetail} />
      ) : (
        <div className={clsx('calendar-view d-flex flex-column p-20 shadow-sm bg-white rounded fw-bold')}>
          {renderHeader()}
          {renderDays()}
          <div className="overflow-scroll liveshow-calendar-section" onScroll={handleCalendarScroll}>
            <FullCalendar
              ref={calendarRef}
              initialView="timeGridWeek"
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              events={calendarEvents}
              eventClick={handleEventClick}
              firstDay={0}
              displayEventTime={false}
              contentHeight="auto"
              eventContent={renderEventContent}
              nowIndicator
              selectable
              select={onSelectTimeSlot}
              slotEventOverlap={false}
              viewDidMount={handleViewDidMount}
              // slotMinTime="08:00:00"
              scrollTime="08:00:00"
              // scrollTimeReset={false}

              // slotDuration="00:10:00"
              // themeSystem="Simplex"
              // eventMinWidth={300}
              // eventShortHeight={50}
              // snapDuration={'00:15'}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default LiveshowCalendarComponent;
