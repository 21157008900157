import { gql } from '@apollo/client';

export const PRINT_SHIPPING_LABEL = gql`
  mutation PrintShippingLabel($input: PrintShippingLabelInput!) @api(name: "ordersAPI") {
    printShippingLabel(input: $input) {
      shipementId
      labelURL
    }
  }
`;
