import { FC, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useQuery } from '@apollo/client';
import { addErrorFilter, orderWithErrors } from 'src/apollo/reactiveVariables';
import { GET_ORDERS_LIST, GET_WAREHOUSE, GET_ORDER_STATUS_COUNT } from 'src/apollo/queries';

// Components
import { Dropdown } from 'src/components/molecules';
import { FlexBox } from 'src/components/atoms';
import AllOrdersPagePresentational from './AllOrdersPage.presentational';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import useTitle from 'src/utils/hooks/useTitle';

// Icons
import { DownArrowIcon, WarehouseBuilding } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { WarehouseListProp } from 'src/components/oraganisms/AddProductInventory/AddProductInventory.types';
import { OrderStatus } from 'src/components/oraganisms/OrdersList/OrdersList.types';
import { OrdersListData } from 'src/components/oraganisms/OrdersList/OrdersList.types';
import { useHistory, useLocation } from 'react-router-dom';
import { getStatusByTab } from 'src/helpers/orders/getStatusByTab';
import { DEFAULT_SORT_BY_ID } from '../../../oraganisms/OrdersList/data';

const AllOrdersPage: FC = () => {
  useTitle('Orders');
  const [ordersList, setOrdersList] = useState<OrdersListData[]>([]);
  const [orderStatusCountList, setOrderStatusCountList] = useState<any>();
  const [selectedWareHouse, setSelectedWareHouse] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [warehousePageInfo] = useState({
    skipCount: 0,
    limitCount: 1
  });

  const [searchedValue, setSearchedValue] = useState<string>('');
  const [warehouseList, setWarehouseList] = useState<WarehouseListProp[]>();

  const [ordersPageInfo, setOrdersPageInfo] = useState({
    skipCount: 0,
    limitCount: 50
  });
  const [selectedTab, setSelectedTab] = useState('TO PROCESS');
  const [selectedStatus, setSelectedStatus] = useState<OrderStatus[]>([]);
  const [isWarehouseDropdown, setIsWarehouseDropdown] = useState(false);
  const [selectedDates, setSelectedDates] = useState<any>();
  const [selectedOrderType, setSelectedOrderType] = useState<'INDIVIDUAL' | 'COMBINED'>('INDIVIDUAL');
  // const allStatues = ['pending', 'awaiting_processing', 'awaiting_fulfillment', 'awaiting_shipment', 'shipped'];

  const search = useLocation().search;

  const history = useHistory();
  useEffect(() => {
    const searchParams = Object.fromEntries(new URLSearchParams(search).entries());
    if (searchParams.tab && searchParams.tab !== selectedTab) {
      setSelectedTab(searchParams.tab);
    }
  }, [search]);

  const setSelectedTabAndUrl = (tab) => {
    setSelectedTab(tab);
    history.push(`/orders/allOrders?tab=${tab}`);
  };

  const [sortBy, setSortBy] = useState({
    catagory: 'Priority',
    id: DEFAULT_SORT_BY_ID,
    type: 'ASC'
  });

  const changeOrderType = (data: 'INDIVIDUAL' | 'COMBINED') => {
    if (data === 'COMBINED') {
      setSelectedOrderType(data);
    } else {
      setSelectedOrderType('INDIVIDUAL');
    }
  };

  const changingDate = (date: string) => {
    const today = new Date();

    if (date === 'TODAY') {
      setSelectedDates({
        from: new Date(`${new Date().toDateString()} 00:00 UTC`).toISOString(),
        to: today.toISOString()
      });
    } else if (date === 'YESTERDAY') {
      const previousDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1).toDateString();
      setSelectedDates({
        from: new Date(`${previousDay} 00:00 UTC`).toISOString(),
        to: new Date(`${previousDay} 11:59 UTC`).toISOString()
      });
    } else if (date === 'LAST WEEK') {
      const previousDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7).toDateString();
      setSelectedDates({
        from: new Date(`${previousDay} 00:00 UTC`).toISOString(),
        to: today.toISOString()
      });
    } else if (date === 'LAST MONTH') {
      const lastMonthDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()).toDateString();
      setSelectedDates({
        from: new Date(`${lastMonthDate} 00:00 UTC`).toISOString(),
        to: today.toISOString()
      });
    } else if (date === 'LAST 3 MONTHS') {
      const threeMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate()).toDateString();
      setSelectedDates({
        from: new Date(`${threeMonthsAgo} 00:00 UTC`).toISOString(),
        to: today.toISOString()
      });
    } else if (date === 'LAST YEAR') {
      const lastYearDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()).toDateString();
      setSelectedDates({
        from: new Date(`${lastYearDate} 00:00 UTC`).toISOString(),
        to: today.toISOString()
      });
    } else if (date === 'ALL TIME') {
      setSelectedDates(undefined);
    }
  };

  const customDateSelector = (data: any) => {
    setSelectedDates(data);
  };

  useQuery(GET_WAREHOUSE, {
    variables: {
      input: {
        pageInfo: warehousePageInfo,
        status: 'ACTIVE'
      }
    },

    onCompleted: (wareHouseData) => {
      if (wareHouseData) {
        const tempList = wareHouseData.getWarehouse.warehouses.map((data) => {
          return {
            name: data.name,
            id: data.id,
            icon: WarehouseBuilding
          };
        });
        setWarehouseList(tempList);
      }
    },

    onError: (error) => {
      console.error('err', error);
    }
  });

  const openWarehouseDropdown = () => {
    setIsWarehouseDropdown(true);
  };
  const closeWarehouseDropdown = () => {
    setIsWarehouseDropdown(false);
  };

  useEffect(() => {
    const arr = Array.from(orderWithErrors());
    if (arr.length > 0) {
      setSelectedStatus([OrderStatus.AWAITING_PROCESSING]);
      setSelectedTabAndUrl('TO PROCESS');
    }
  }, []);

  useEffect(() => {
    if (selectedTab) {
      setSortBy({
        catagory: 'DATE',
        id: 1,
        type: selectedTab.toUpperCase() === 'ALL' || selectedTab.toUpperCase() === 'SHIPPED' ? 'DESC' : 'ASC'
      });

      getStatusByTab(selectedTab, setSelectedStatus);

      addErrorFilter(false);

      // Change the Order Type
      if (selectedTab.toUpperCase() === 'PENDING' || selectedTab.toUpperCase() === 'TO PROCESS') {
        setSelectedOrderType('INDIVIDUAL');
      }

      setOrdersPageInfo({
        ...ordersPageInfo,
        skipCount: 0
      });
    }
  }, [selectedTab]);

  useEffect(() => {
    setOrdersPageInfo({
      ...ordersPageInfo,
      skipCount: 0
    });
  }, [searchedValue]);

  const { refetch: refetchOrdersList, loading: isLoading } = useQuery(GET_ORDERS_LIST, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    variables: {
      input: {
        pagination: ordersPageInfo,
        status: selectedStatus,
        dates: selectedDates,
        sort: {
          column: sortBy?.catagory === 'Priority' ? 'DATE' : sortBy?.catagory.toUpperCase(),
          order: sortBy?.type.toUpperCase()
        },
        search: searchedValue,
        combined: true
      }
    },
    onCompleted: (ordersListData) => {
      if (ordersListData) {
        setOrdersList(ordersListData.getOrderDetailsList.orders);
        setTotalCount(ordersListData.getOrderDetailsList.total);
      }
    },
    onError: (error) => {
      console.error('err', error);
    }
  });

  const { refetch: refetchOrderStatusCount } = useQuery(GET_ORDER_STATUS_COUNT, {
    variables: {
      input: {
        combined: true
      }
    },
    onCompleted: (orderStatusCounts) => {
      if (orderStatusCounts && orderStatusCounts.getOrderStatusCounts) {
        const statusCounts = orderStatusCounts.getOrderStatusCounts;
        setOrderStatusCountList({
          [constVariables.TabForOrders[0]]: statusCounts.all ? statusCounts.all : 0,
          [constVariables.TabForOrders[1]]: statusCounts.pending ? statusCounts.pending : 0,
          [constVariables.TabForOrders[2]]: statusCounts.awaitingProcessing ? statusCounts.awaitingProcessing : 0,
          [constVariables.TabForOrders[3]]: statusCounts.awaitingFulfillment ? statusCounts.awaitingFulfillment : 0,
          [constVariables.TabForOrders[4]]: statusCounts.awaitingShipment ? statusCounts.awaitingShipment : 0,
          [constVariables.TabForOrders[5]]: statusCounts.shipped ? statusCounts.shipped : 0
        });
      }
    },
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      console.error('orderStatusCounts err', error);
    }
  });

  useEffect(() => {
    refetchOrderStatusCount();
  }, [ordersList]);

  const selectWarehouse = (data: any) => {
    setSelectedWareHouse(data.name);
    closeWarehouseDropdown();
  };
  const WarehouseSelector = () => (
    <div className="position-relative w-300px ms-5">
      <OutsideClickHandler onOutsideClick={closeWarehouseDropdown}>
        <FlexBox
          className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box ${
            isWarehouseDropdown ? 'dropdown-box-active' : ''
          }`}
          onClick={openWarehouseDropdown}
        >
          {selectedWareHouse ? (
            <FlexBox className="align-items-center">
              <KTSVG path={WarehouseBuilding} />
              <div className="ms-2">{selectedWareHouse}</div>
            </FlexBox>
          ) : (
            <FlexBox className="align-items-center">
              <KTSVG svgColor="#706FD3" path={WarehouseBuilding} />
              <div className="ms-2">Select Warehouse</div>
            </FlexBox>
          )}
          <div>
            <KTSVG path={DownArrowIcon} />
          </div>
        </FlexBox>
        {warehouseList ? (
          <Dropdown
            className="dropdown-custom-width mt-3"
            data={warehouseList}
            selected={isWarehouseDropdown}
            value={selectedWareHouse}
            onSelect={selectWarehouse}
            closeDropdown={() => setIsWarehouseDropdown(false)}
          />
        ) : null}
      </OutsideClickHandler>
    </div>
  );
  return (
    <AllOrdersPagePresentational
      ordersList={ordersList}
      setOrdersList={setOrdersList}
      sortBy={sortBy}
      setSortBy={setSortBy}
      ordersPageInfo={ordersPageInfo}
      setOrdersPageInfo={setOrdersPageInfo}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTabAndUrl}
      refresh={refetchOrdersList}
      HeadingExtenstion={WarehouseSelector}
      changingDate={changingDate}
      customDateSelector={customDateSelector}
      selectedDates={selectedDates}
      countList={orderStatusCountList}
      searchText={searchedValue}
      onSearch={setSearchedValue}
      changeOrderType={changeOrderType}
      selectedOrderType={selectedOrderType}
      isLoading={isLoading}
      totalCount={totalCount}
    />
  );
};

export default AllOrdersPage;
