import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_PRODUCTS_V3, GET_LABEL_TEMPLATES, PRINT_LABELS } from 'src/apollo/queries';

// Components
import { DropdownSelector, Loader } from 'src/components/atoms';
import { ProductLabel, Divider, Dropdown } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { useApolloBroadcaster } from 'src/utils/hooks/useApolloBroadcaster';

// Icons
import { CloseIcon, MinimiseBulkEditor, StretchIcon } from 'src/assets/icons';

// Types
import { LabelPrintModalProps } from './Modal.types';
import { ProductLabelItem, LabelTemplateItem } from 'src/components/molecules/ProductLabel/ProductLabel.types';
import { SPLITER } from '../../../constants';

const _ = require('lodash');

const LabelPrintModal: FC<LabelPrintModalProps> = ({
  closeModal,
  labelPrintType,
  expandedViewLabelPrintModal,
  toggleExpand,
  selectedProducts,
  variantIds
}) => {
  useApolloBroadcaster();

  const [productLabels, setProductLabels] = useState<ProductLabelItem[]>([]);
  const [totalLabels, setTotalLabels] = useState(0);
  const [showLabelTemplateDropdown, setShowLabelTemplateDropdown] = useState<boolean>(false);
  const [labelTemplates, setLabelTemplates] = useState<LabelTemplateItem[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<LabelTemplateItem>();

  const [getLabelTemplates, { loading: isTemplatesLoading }] = useLazyQuery(GET_LABEL_TEMPLATES, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      if (res?.getLabelTemplates) {
        const templates = res?.getLabelTemplates?.map((template) => {
          return {
            id: template.id,
            name: template.type,
            paperType: template.paperType,
            originalDymension: template.originalDymension
          };
        });
        setLabelTemplates(templates);
        const labelTemplateId = localStorage.getItem('labelTemplateId');
        if (labelTemplateId) {
          const defaultLabelTemplate = templates.find((template) => template.id === labelTemplateId);
          setSelectedTemplate(defaultLabelTemplate);
        }
      }
    }
  });

  const mapVariants = useCallback(
    (variants) => {
      if (variantIds && variantIds?.length > 0) {
        return variants?.filter((variant) => variantIds?.includes(variant.productVariantId));
      }
      return variants;
    },
    [variantIds]
  );

  const { loading: isProductsLoading } = useQuery(GET_PRODUCTS_V3, {
    variables: {
      input: {
        productIdsString: JSON.stringify(selectedProducts),
        detailed: true,
        filters: {
          locationIdsString: '[]'
        }
      }
    },
    skip: selectedProducts.length === 0,
    onCompleted: (response) => {
      if (response?.getAllProductsV3) {
        const updatedProducts = response?.getAllProductsV3?.products?.map((product) => {
          const exitingProductLabel = productLabels?.find((productLabel) => productLabel?.productId === product.productId);
          return {
            productId: product?.productId,
            productName: product?.productName,
            productQty: product?.productQty,
            productPrice: product?.salePrice,
            productMediaUrl: product?.productMediasJson?.filter((media) => media?.productMediaType === 'IMAGE')[0]?.productMediaUrl,
            productSku: product?.productSku,
            productBarcode: product?.productBarcode,
            labelNumber: exitingProductLabel ? exitingProductLabel.labelNumber : 0,
            variants: product?.variantLocationDetails
              ? mapVariants(
                  product?.variantLocationDetails?.map((variant) => {
                    const exitingVaraintLabel = exitingProductLabel?.variants?.find(
                      (variantLabel) => variantLabel.productVariantId === variant.productVariantId
                    );
                    return {
                      productVariantId: variant?.productVariantId,
                      productVariantPrice: variant?.productVariantPrice,
                      productVariantQty: variant?.productVariantQty,
                      productVariantSku: variant?.productVariantSku,
                      productVariantBarcode: variant?.productVariantBarcode,
                      variantTypes: variant?.variantTypes,
                      labelNumber: exitingVaraintLabel ? exitingVaraintLabel?.labelNumber : 0
                    };
                  })
                )
              : []
          };
        });
        setProductLabels(updatedProducts);
      }
    },
    onError: (err) => {
      console.log('err::', err);
    }
  });

  const [printLabels, { loading: isLabelPrinting }] = useLazyQuery(PRINT_LABELS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      closeModal();
      if (res?.printLabels) {
        window.open(res?.printLabels?.imageURL, '_blank');
      }
    },
    onError: (err) => {
      console.error('err::', err);
    }
  });

  const isLoading = useMemo(() => {
    if (!isProductsLoading && !isTemplatesLoading) return false;
    return true;
  }, [isProductsLoading, isTemplatesLoading]);

  useEffect(() => {
    const total = productLabels.reduce((sum, productLabel) => {
      if (productLabel.variants.length) {
        const subSum = productLabel.variants.reduce((s, variant) => {
          return s + variant.labelNumber;
        }, 0);
        sum += subSum;
      } else {
        sum += productLabel.labelNumber;
      }
      return sum;
    }, 0);
    setTotalLabels(total);
  }, [productLabels]);

  useEffect(() => {
    getLabelTemplates();
  }, []);

  const handleUpdateLabelCount = (labelNumber, productId, labelProductType, variantId) => {
    if (!variantId && labelProductType === 'product') {
      const updatedProductLabels = productLabels.map((label) => {
        if (label.productId === productId) {
          return {
            ...label,
            labelNumber,
            variants: label?.variants?.map((variantItem) => {
              return { ...variantItem, labelNumber };
            })
          };
        }
        return label;
      });
      setProductLabels(updatedProductLabels);
    }

    if (variantId && labelProductType === 'variant') {
      const updatedProductLabels = productLabels.map((label) => {
        if (label.productId === productId) {
          return {
            ...label,
            variants: label?.variants?.map((variantItem) => {
              if (variantItem?.productVariantId === variantId) {
                return { ...variantItem, labelNumber };
              }
              return variantItem;
            })
          };
        }
        return label;
      });
      setProductLabels(updatedProductLabels);
    }
  };

  const handleChangeTemplate = (value) => {
    localStorage.setItem('labelTemplateId', value.id);
    const selectedTemplate = labelTemplates.find((template) => template.id === value.id);
    setSelectedTemplate(selectedTemplate);
    setShowLabelTemplateDropdown(false);
  };

  const templatePreview = (dymension) => {
    const dimensions = dymension
      ?.split('-')[1]
      ?.split('x')
      ?.map((item) => parseInt(item.trim().replace('mm', ''), 10));
    const width = Math.max(...dimensions);
    const height = Math.min(...dimensions);
    return { width, height };
  };

  const handleLabelsPrint = () => {
    const products = _.flattenDepth(
      productLabels.map((productLabel) => {
        if (productLabel?.variants?.length > 0) {
          return productLabel?.variants.map((variantItem) => {
            return {
              productName: productLabel?.productName,
              variantName: variantItem.variantTypes?.map((varType) => varType.variantTypeValue).join(SPLITER),
              barcodeOrSKU: labelPrintType === 'sku' ? variantItem.productVariantSku : variantItem.productVariantBarcode,
              numberOfCopies: variantItem.labelNumber
            };
          });
        }
        return [
          {
            productName: productLabel?.productName,
            variantName: '',
            barcodeOrSKU: labelPrintType === 'sku' ? productLabel.productSku : productLabel.productBarcode,
            numberOfCopies: productLabel.labelNumber
          }
        ];
      }),
      1
    )?.filter((label) => label.numberOfCopies > 0);

    const noContentLabels = products?.filter((product) => product.barcodeOrSKU === '');
    if (noContentLabels?.length > 0) return;

    printLabels({
      variables: {
        input: {
          printerId: selectedTemplate?.id,
          products
        }
      }
    });
  };

  const isDisabledPrintButton = useMemo(() => {
    if (totalLabels > 0 && selectedTemplate?.id) return false;
    return true;
  }, [selectedTemplate?.id, totalLabels]);

  return (
    <div
      className={`modal-content product-label-print-modal ${
        expandedViewLabelPrintModal ? 'vh-100 vw-100 b-0 rounded-0' : 'default-view'
      }`}
    >
      <div className="modal-header justify-content-between">
        <h5 className="modal-title">Print labels</h5>
        <div className="d-flex align-items-center">
          <img
            src={expandedViewLabelPrintModal ? MinimiseBulkEditor : StretchIcon}
            className="ms-2 me-2 cursor-pointer h-15px w-15px"
            onClick={toggleExpand}
          />
          <div
            className="btn btn-xs btn-active-light-primary p-0 m-0 border-none"
            onClick={() => {
              document.body.style.overflow = 'unset';
              closeModal();
            }}
          >
            <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
          </div>
        </div>
      </div>
      <div className={`modal-body p-x-40 p-y-32 product-label-print-modal-body ${expandedViewLabelPrintModal ? 'expanded' : ''}`}>
        {isLoading ? (
          <Loader type="page" className="h-100" />
        ) : (
          <div className={`${expandedViewLabelPrintModal ? 'product-label-print-modal-body-container' : ''}`}>
            <div>
              <div className="m-b-24">
                <div className="text-nowrap input-title">Label template</div>
                <div className="position-relative">
                  <DropdownSelector
                    className={`form-control form-control-lg  justify-content-between align-items-center cursor-pointer dropdown-box ${
                      showLabelTemplateDropdown ? 'dropdown-box-active' : ''
                    }`}
                    onClick={() => setShowLabelTemplateDropdown(!showLabelTemplateDropdown)}
                    selectedValue={selectedTemplate?.name}
                    text="Select"
                  />
                  <OutsideClickHandler onOutsideClick={() => setShowLabelTemplateDropdown(false)}>
                    <Dropdown
                      className="full-width-dropdown"
                      data={labelTemplates}
                      selected={showLabelTemplateDropdown}
                      onSelect={handleChangeTemplate}
                      closeDropdown={() => setShowLabelTemplateDropdown(false)}
                    />
                  </OutsideClickHandler>
                </div>
              </div>
              {selectedTemplate && (
                <div className="d-flex label-template-preview">
                  <div>
                    <div
                      className="label-template-preview-dimension"
                      style={{
                        width: `${templatePreview(selectedTemplate?.originalDymension).width}px`,
                        height: `${templatePreview(selectedTemplate?.originalDymension).height}px`
                      }}
                    ></div>
                  </div>
                  <div className="m-l-16 label-template-preview-description">
                    <h5>{selectedTemplate?.name}</h5>
                    <p>{selectedTemplate?.originalDymension}</p>
                    <p className="m-0">{selectedTemplate?.paperType}</p>
                  </div>
                </div>
              )}
            </div>
            <div className="m-t-32">
              {productLabels?.map((productLabel, index) => (
                <Fragment key={index}>
                  <ProductLabel
                    productLabel={productLabel}
                    labelPrintType={labelPrintType}
                    handleUpdateLabelCount={handleUpdateLabelCount}
                  />
                  {index + 1 < selectedProducts?.length && <Divider classname="m-y-16" />}
                </Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="modal-footer justify-content-between">
        <span className="p-0">{totalLabels} labels</span>
        <div className="d-flex align-items-center m-0">
          <button type="button" className="btn btn-outlined-secondary btn-md" onClick={closeModal}>
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary btn-md"
            disabled={isDisabledPrintButton || isLabelPrinting}
            onClick={handleLabelsPrint}
          >
            {isLabelPrinting ? 'Printing' : 'Print'} {totalLabels > 0 ? `${totalLabels} labels` : ''}
            {isLabelPrinting && <Loader type="button" className="h-10px w-10px" />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LabelPrintModal;
