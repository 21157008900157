import { FC, useEffect, useState } from 'react';

// Components
import { FlexBox } from 'src/components/atoms';
import { InputBox, Checkbox } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon, SearchIcon } from 'src/assets/icons';

// Types
import { OptionItem, FilterMenuItemProps } from './RightFilterMenu.types';

const FilterMenuItem: FC<FilterMenuItemProps> = ({
  typeTitle,
  title,
  inputPlaceholder,
  inputName,
  options,
  showSearchBox,
  filters,
  updateFilterData,
  fieldName
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<any>(filters);
  const [searchedOptions, setSearchedOptions] = useState<any>(options);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    setFilteredOptions(filters);
  }, [filters]);

  useEffect(() => {
    setSearchedOptions(options);
  }, [options]);

  const handleOption = (option: OptionItem) => {
    if (filteredOptions && filteredOptions !== undefined) {
      const filteredItems = filteredOptions.map((optionItem) => optionItem.id).includes(option.id)
        ? filteredOptions.filter((optionItem) => optionItem.id !== option.id)
        : [...filteredOptions, option];
      setFilteredOptions(filteredItems);
      updateFilterData && updateFilterData(fieldName, filteredItems);
    }
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
    const searchResult = options && options.filter((option) => option.name.toLowerCase().includes(value.toLowerCase()));
    setSearchedOptions(searchResult);
  };

  const handleClearOptions = () => {
    setFilteredOptions([]);
    updateFilterData && updateFilterData(fieldName, []);
  };

  return (
    <div className="filter-menu-item">
      <FlexBox className="filter-menu-item-header align-items-center justify-content-between">
        <FlexBox>
          <KTSVG
            path={DownArrowIcon}
            className={`svg-icon-2 cursor-pointer me-2 ${showOptions ? '' : 'rotate-icon'}`}
            onClick={() => setShowOptions(!showOptions)}
          />
          <h6 className="m-0 poppins-regular">{title}</h6>
        </FlexBox>
        {filteredOptions && filteredOptions !== undefined && filteredOptions.length > 0 && (
          <div className="cursor-pointer clear-btn poppins-regular" onClick={handleClearOptions}>
            Clear
          </div>
        )}
      </FlexBox>
      {showOptions && (
        <div className="filter-menu-item-content">
          {showSearchBox && inputName && (
            <div className="position-relative">
              <InputBox
                mainclass="mb-4 py-2"
                inputClass="valueColor valueInput d-flex align-items-center"
                value={searchText}
                onChangeText={handleSearch}
                name={inputName}
                placeholder={inputPlaceholder}
                inputIcon={SearchIcon}
                inputIconColor="#787D92"
              />
            </div>
          )}
          {searchedOptions.length > 0 ? (
            searchedOptions.map((option, index) => (
              <Checkbox
                key={index}
                labelclassname="filter-menu-checkbox"
                name={option.name}
                value={filteredOptions && filteredOptions.map((option) => option.id).includes(option.id)}
                onChangeValue={() => handleOption(option)}
              />
            ))
          ) : (
            <p>{typeTitle} not found</p>
          )}
        </div>
      )}
    </div>
  );
};

export default FilterMenuItem;
