import { FC, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useLazyQuery } from '@apollo/client';
import { CALCULATE_SHIPPING_CHARGES } from 'src/apollo/queries';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { DropdownSelector, RadioButton } from 'src/components/atoms';
import { Dropdown, Checkbox, InputBox, Divider } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { checkInvalidCharacter } from 'src/utils/validate';

// Icons
import { InfoIcon, InfoItalicIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { ShippingCostObject, ShippingSection } from 'src/components/pages/products/AddProduct/AddProduct.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

// Styles
import './_shippingSettingsFreeShippingWindow.scss';

const ShippingSettingsFreeShippingWindow = ({ shippingSettings, setShippingSettings, errorMessages, setErrorMessages }) => {
  const [enableWindowToggle, setEnableWindowToggle] = useState(false);
  useEffect(() => {
    if (shippingSettings.isFreeShippingWindow && !enableWindowToggle) {
      setEnableWindowToggle(true);
    }
  }, [shippingSettings]);
  return (
    <div className="card w-100">
      <h4 className="section-title m-b-24">Free Shipping Window</h4>
      <div
        className="row"
        style={{
          position: 'relative',
          left: '24px',
          marginBottom: '8px'
        }}
      >
        <label className="form-check form-check-custom form-check-solid form-switch form-switch-normal toggle-absolute">
          <input
            style={{ outline: 'none' }}
            className="form-check-input"
            type="checkbox"
            name="layout-builder[layout][header][fixed][desktop]"
            checked={enableWindowToggle}
            onChange={() => {
              setEnableWindowToggle(!enableWindowToggle);
              setShippingSettings({
                ...shippingSettings,
                isFreeShippingWindow: !enableWindowToggle
              });
            }}
          />
        </label>
        <label
          className="mb-0 ms-2 opacity-80 thicccboi-bold"
          style={{
            paddingLeft: '32px',
            paddingTop: '1px'
          }}
        >
          Activate store wide free shipping window based on the settings.
        </label>
      </div>
      {shippingSettings.isFreeShippingWindow && (
        <>
          <Divider classname="divider-status m-y-24" />
          <div className="row">
            <h4 className="section-title m-b-24">Conditions</h4>
            <div className="d-flex align-items-center w-100 condition-option">
              <RadioButton
                id="conditionNone"
                name="shippingCondition"
                isChecked={shippingSettings.freeShippingWindowCondition === 'NONE'}
                handleChange={(e) => {
                  if (e.target.checked) {
                    setShippingSettings({
                      ...shippingSettings,
                      freeShippingWindowCondition: 'NONE'
                    });
                  }
                }}
                noMargin
                className={'radio-lg'}
              />
              <label className="mb-0 ms-2 opacity-80 thicccboi-bold">None</label>
            </div>
            <div className="row">
              <div className="d-flex align-items-center w-100 condition-option">
                <RadioButton
                  id="conditionPurchase"
                  name="shippingCondition"
                  isChecked={shippingSettings.freeShippingWindowCondition === 'MINIMUM_PURCHASE'}
                  handleChange={(e) => {
                    if (e.target.checked) {
                      setShippingSettings({
                        ...shippingSettings,
                        freeShippingWindowCondition: 'MINIMUM_PURCHASE'
                      });
                    }
                  }}
                  noMargin
                  className={'radio-lg'}
                />
                <label className="mb-0 ms-2 opacity-80 thicccboi-bold">Minimum purchase amount ($ - USD)</label>
              </div>
              {shippingSettings.freeShippingWindowCondition === 'MINIMUM_PURCHASE' && (
                <div style={{ width: '190px' }}>
                  <InputBox
                    onKeyDown={checkInvalidCharacter}
                    // dollarSign={pricing.salePrice.toString().length > 0}
                    dollarSign={true}
                    inputClass="form-control-lg"
                    mainclass="m-b-24 sale-price-title"
                    value={isNaN(shippingSettings.minimumPurchaseAmount) ? '' : shippingSettings.minimumPurchaseAmount || ''}
                    onChangeText={(e) => {
                      if (!isNaN(e)) {
                        setShippingSettings({
                          ...shippingSettings,
                          minimumPurchaseAmount: e
                        });
                        setErrorMessages({
                          ...errorMessages,
                          minimumPurchaseAmount: ''
                        });
                      }
                    }}
                    name={'minimumPurchaseAmount'}
                    warningText={errorMessages.minimumPurchaseAmount}
                  />
                </div>
              )}
            </div>
            <div className="row">
              <div className="d-flex align-items-center w-100 condition-option">
                <RadioButton
                  id="conditionQuantity"
                  name="shippingCondition"
                  isChecked={shippingSettings.freeShippingWindowCondition === 'MINIMUM_QUANTITY'}
                  handleChange={(e) => {
                    if (e.target.checked) {
                      setShippingSettings({
                        ...shippingSettings,
                        freeShippingWindowCondition: 'MINIMUM_QUANTITY'
                      });
                    }
                  }}
                  noMargin
                  className={'radio-lg'}
                />
                <label className="mb-0 ms-2 opacity-80 thicccboi-bold">Minimum quantity of items</label>
              </div>
              {shippingSettings.freeShippingWindowCondition === 'MINIMUM_QUANTITY' && (
                <div style={{ width: '190px' }}>
                  <InputBox
                    onKeyDown={checkInvalidCharacter}
                    inputClass="form-control-lg"
                    mainclass="m-b-24 sale-price-title"
                    value={isNaN(shippingSettings.minimumQtyOfItems) ? '' : shippingSettings.minimumQtyOfItems || ''}
                    onChangeText={(e) => {
                      if (!isNaN(e)) {
                        setShippingSettings({
                          ...shippingSettings,
                          minimumQtyOfItems: Number(e)
                        });
                        setErrorMessages({
                          ...errorMessages,
                          minimumQtyOfItems: ''
                        });
                      }
                    }}
                    name={'minimumQtyOfItems'}
                    warningText={errorMessages.minimumQtyOfItems}
                  />
                </div>
              )}
            </div>
          </div>

          <Divider classname="divider-status m-y-24" />
          <div className="row">
            <h4 className="section-title m-b-24">Shipping Window Open For</h4>
            <div className="row">
              <div className="d-flex align-items-center w-100 condition-option">
                <RadioButton
                  id="windowOpenHour"
                  name="windowOpen"
                  isChecked={shippingSettings.freeShippingWindowOpenFor === 'HOURS'}
                  handleChange={(e) => {
                    if (e.target.checked) {
                      setShippingSettings({
                        ...shippingSettings,
                        freeShippingWindowOpenFor: 'HOURS'
                      });
                    }
                  }}
                  noMargin
                  className={'radio-lg'}
                />
                <label className="mb-0 ms-2 opacity-80 thicccboi-bold">Hour(s)</label>
              </div>
              {shippingSettings.freeShippingWindowOpenFor === 'HOURS' && (
                <div>
                  <InputBox
                    onKeyDown={checkInvalidCharacter}
                    // dollarSign={pricing.salePrice.toString().length > 0}
                    inputClass="form-control-lg narrow-input"
                    mainclass="m-b-24 sale-price-title"
                    value={
                      isNaN(shippingSettings.freeShippingWindowDuration) ? '' : shippingSettings.freeShippingWindowDuration || ''
                    }
                    onChangeText={(e) => {
                      if (!isNaN(e)) {
                        setShippingSettings({
                          ...shippingSettings,
                          freeShippingWindowDuration: Number(e)
                        });
                        setErrorMessages({
                          ...errorMessages,
                          freeShippingWindowDuration: ''
                        });
                      }
                    }}
                    name={'freeShippingWindowDurationHours'}
                    warningText={errorMessages.freeShippingWindowDuration}
                  />
                </div>
              )}
            </div>
            <div className="row">
              <div className="d-flex align-items-center w-100 condition-option">
                <RadioButton
                  id="windowOpenDay"
                  name="windowOpen"
                  isChecked={shippingSettings.freeShippingWindowOpenFor === 'DAYS'}
                  handleChange={(e) => {
                    if (e.target.checked) {
                      setShippingSettings({
                        ...shippingSettings,
                        freeShippingWindowOpenFor: 'DAYS'
                      });
                    }
                  }}
                  noMargin
                  className={'radio-lg'}
                />
                <label className="mb-0 ms-2 opacity-80 thicccboi-bold">Day(s)</label>
              </div>
              {shippingSettings.freeShippingWindowOpenFor === 'DAYS' && (
                <div>
                  <InputBox
                    onKeyDown={checkInvalidCharacter}
                    inputClass="form-control-lg narrow-input"
                    mainclass="m-b-24 sale-price-title"
                    value={
                      isNaN(shippingSettings.freeShippingWindowDuration) ? '' : shippingSettings.freeShippingWindowDuration || ''
                    }
                    onChangeText={(e) => {
                      if (!isNaN(e)) {
                        setShippingSettings({
                          ...shippingSettings,
                          freeShippingWindowDuration: Number(e)
                        });
                        setErrorMessages({
                          ...errorMessages,
                          freeShippingWindowDuration: ''
                        });
                      }
                    }}
                    name={'freeShippingWindowDurationDays'}
                    warningText={errorMessages.freeShippingWindowDuration}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ShippingSettingsFreeShippingWindow;
