import { FC, useEffect, useMemo, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'react-router-dom';

// Apollo
// import { useMutation } from '@apollo/client';
// import { SAVE_OR_GET_ORDER_TAGS } from 'src/apollo/mutations';

// Components
import { FlexBox, Img, Loader } from 'src/components/atoms';
import { ContactInfoDetails, Dropdown, Divider, Tooltip } from 'src/components/molecules';
import { AddNotes } from 'src/components/oraganisms';
import OrderDetailsTable from './OrderDetailsTable';
import OrderPaymentDetails from './OrderPaymentDetails';
import OrderDetailsTimeLine from './OrderDetailsTimeLine';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';
import { pascalCaseConversion } from 'src/utils/convertText';

// Icons
import { BookmarkIcon, DefaultImage, FilledCheckCircle, InfoIcon, RedExclaimationIcon, ThreeDotsIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

// Types
import { OrderDetailsPresentationProps } from './OrderDetails.types';

// Styles
import './OrderDetails.scss';

const OrderDetailsPresentational: FC<OrderDetailsPresentationProps> = ({
  orderDetails,
  notesData,
  setNotesData,
  addNewNote,
  deleteOldNote,
  editOldNote,
  orderId,
  isError,
  markUserAddressVerified,
  verifiedManually,
  isVerifyingAddress,
  isDelete,
  setIsDelete,
  openAddProduct,
  addressesCustomerDetails,
  setAddressesCustomerDetails,
  saveAdressFromList,
  isSave,
  setIsSave,
  isEdit,
  setIsEdit,
  selectedAdressId,
  setSelectedAdressId,
  openAddressModal,
  deleteAddress,
  editAddress,
  getAdrressList,
  isEditCustomerAddress,
  setIsEditCustomerAddress,
  updateOrderSummaryFormChanges,
  updatedFormData,
  orderSummaryFormData,
  timeLineData
}) => {
  // const [allTags, setAllTags] = useState<string[]>([]);
  const [addressMenu, setAddressMenu] = useState<number>(-1);
  const [isShowEditBtn, setIsShowEditBtn] = useState(false);
  // const [changeOrGetTags] = useMutation(SAVE_OR_GET_ORDER_TAGS, {
  //   onCompleted: (data) => {
  //     if (data?.saveOrGetOrderTags?.tags) {
  //       const newTags = JSON.parse(data.saveOrGetOrderTags.tags).tags;
  //       setAllTags(newTags);
  //     }
  //   },
  //   onError: (error) => {
  //     console.error(error);
  //   }
  // });

  // const handleTagsSubmission = (finalTags: string[]) => {
  //   changeOrGetTags({
  //     variables: {
  //       input: {
  //         operation: 'SET',
  //         orderId: orderDetails.id,
  //         tags: JSON.stringify({ tags: finalTags })
  //       }
  //     }
  //   });
  // };

  // const submitTagValue = (e: React.KeyboardEvent<HTMLInputElement>, item: string) => {
  //   if (e.key === 'Enter') {
  //     const newtags = [...allTags, item].filter((item, pos, arr) => arr.indexOf(item) === pos);
  //     setAllTags(newtags);
  //     handleTagsSubmission(newtags);
  //   }
  // };

  // const removeTag = (id: number) => {
  //   const newtags = allTags.filter((item, index) => index !== id);
  //   setAllTags(newtags);
  //   handleTagsSubmission(newtags);
  // };

  const handleSaveButton = () => {
    saveAdressFromList && saveAdressFromList(selectedAdressId);
  };

  useEffect(() => {
    // if (orderDetails.id) {
    //   changeOrGetTags({
    //     variables: {
    //       input: {
    //         operation: 'GET',
    //         orderId: orderDetails.id
    //       }
    //     }
    //   });
    // }
    getAdrressList && getAdrressList();
  }, [orderDetails.id]);

  useEffect(() => {
    if (
      orderDetails.orderStatus === 'awaiting_shipment' ||
      orderDetails.orderStatus === 'awiting_pickup' ||
      orderDetails.orderStatus === 'cancelled' ||
      orderDetails.orderStatus === 'completed' ||
      orderDetails.orderStatus === 'refunded' ||
      orderDetails.orderStatus === 'shipped'
    ) {
      setIsEdit && setIsEdit(false);
      setIsShowEditBtn(false);
    }
  }, [orderDetails.orderStatus]);

  const openAddressMenu = (id) => {
    setAddressMenu(id);
  };

  const closeAddressMenu = () => {
    setAddressMenu(-1);
  };

  const selectedOption = (data, address) => {
    if (data.name.toUpperCase() === 'EDIT ADDRESS') {
      // EDITING ADDRESS
      setSelectedAdressId(address.id);
      setIsEditCustomerAddress && setIsEditCustomerAddress(true);
      openAddressModal && openAddressModal();
    } else if (data.name.toUpperCase() === 'DELETE ADDRESS') {
      // DELETING ADDRESS
      setSelectedAdressId(address.id);
      deleteAddress && deleteAddress(address.id);
    }
    closeAddressMenu();
  };

  const selectAdress = (e: any, add?: any) => {
    if (selectedAdressId === add.id) {
      setSelectedAdressId(-1);
    } else {
      setSelectedAdressId(add.id);
      setIsSave(true);
    }
  };

  const editAdress = () => {
    setIsEdit && setIsEdit(true);
    setIsShowEditBtn(false);
  };

  const confirmAddressBtnRender = () => {
    if (orderDetails?.shippingAddress?.id && orderDetails?.shippingAddress?.isVerified === false) {
      if (isVerifyingAddress) {
        return (
          <button disabled className="mt-2 btn btn-sm success-btn">
            <Loader type="button" className="fs-9 h-15px w-15px text-success" />
          </button>
        );
      } else if (!orderDetails?.shippingAddress?.isVerified) {
        return (
          <button className="mt-2 btn btn-sm text-success success-btn w-100" onClick={markUserAddressVerified}>
            Confirm Address
          </button>
        );
      }
    }
    return null;
  };

  const shouldShowEdit = useMemo(() => {
    if (
      orderDetails.orderStatus === 'awaiting_shipment' ||
      orderDetails.orderStatus === 'awiting_pickup' ||
      orderDetails.orderStatus === 'cancelled' ||
      orderDetails.orderStatus === 'completed' ||
      orderDetails.orderStatus === 'refunded' ||
      orderDetails.orderStatus === 'shipped' ||
      isEdit
    ) {
      return false;
    } else {
      return true;
    }
  }, [orderDetails, isEdit]);

  return (
    <div className="main-detail-layout">
      <div className="left-pane">
        <div className="card w-100">
          <OrderDetailsTable
            isDelete={isDelete}
            setIsDelete={setIsDelete}
            orderDetails={orderDetails}
            isError={isError}
            openAddProduct={openAddProduct}
            updateOrderSummaryFormChanges={updateOrderSummaryFormChanges}
            updatedFormData={updatedFormData}
            orderSummaryFormData={orderSummaryFormData}
          />
        </div>
        <div className="card w-100">
          <OrderPaymentDetails orderDetails={orderDetails} orderSummaryFormData={orderSummaryFormData} />
        </div>
        {timeLineData?.length > 0 && (
          <div className="card w-100 mb-0">
            <OrderDetailsTimeLine orderDetails={orderDetails} timeLineData={timeLineData} />
          </div>
        )}
      </div>

      {/* Side Column  */}
      <div className="right-pane order-details-page">
        <div className="card">
          <h4 className="section-title m-b-32">
            {pascalCaseConversion(constVariables.orders.customer)}
            <span className="poppins-regular m-auto-0 text-nowrap text-center text-success float-right">
              {`$${addressesCustomerDetails.availableStoreCredit?.toFixed(2)}`}
              <img src={InfoIcon} className="ms-2 cursor-pointer h-20px w-20px mb-2" data-tip data-for="customerStoreCredit" />
              <Tooltip
                tooltipId="customerStoreCredit"
                place="top"
                content={() => {
                  return <>Customer store credit</>;
                }}
              />
            </span>
          </h4>
          <FlexBox className="align-items-center">
            <Img
              className="h-40px w-40px customerLogo"
              src={setImageSrc(orderDetails?.thumbnailUrl)}
              placeholderImg={DefaultImage}
              errorImg={DefaultImage}
            />
            <div className="p-l-8">
              <Link to={`/customers/customerDetails/${orderDetails.customerId}`} className="greyText mb-2">
                <span className="d-block">{orderDetails.customer}</span>
              </Link>
              {/* <img src={Truck} className="px-2" /> */}
              <span className="greyText">
                {orderDetails?.customerOrders ? orderDetails?.customerOrders : 0}
                <span className="greyText ps-1">{constVariables.orderDetails.order}</span>
              </span>
            </div>
          </FlexBox>
          <Divider classname="m-y-24" />
          <ContactInfoDetails
            email={orderDetails?.customerEmail}
            isEmailVerified={orderDetails?.isEmailVerified}
            phone={orderDetails?.customerPhoneNo}
            isPhoneVerified={orderDetails?.isPhoneNoVerified}
          />
        </div>
        <div id="order-address" className="card">
          <div className="d-flex align-items-center justify-content-between pb-0">
            <h4 className="section-title mb-0">{pascalCaseConversion(constVariables.orders.shippingAddress)}</h4>
            <div id="order-address-edit" className="d-flex align-items-center">
              {shouldShowEdit ? (
                <div className="cursor-pointer" onClick={editAdress}>
                  <p className="float-right text-primary mb-0">{constVariables.orderDetails.edit}</p>
                </div>
              ) : null}

              {isSave && (
                <div className="cursor-pointer" onClick={handleSaveButton}>
                  <p className="float-right text-primary mb-0">{constVariables.orderDetails.save}</p>
                </div>
              )}
            </div>
          </div>
          {isError &&
            (!orderDetails?.shippingAddress?.isVerified ? (
              <FlexBox className="align-items-center">
                <KTSVG path={RedExclaimationIcon} />
                <span className="danger-text ms-2">{constVariables.orderDetails.shippingAddress.statues.UnverifiedAddress}</span>
              </FlexBox>
            ) : (
              <>
                {verifiedManually && (
                  <FlexBox className="align-items-center">
                    <img src={FilledCheckCircle} />
                    <span className="text-success ms-2">
                      {constVariables.orderDetails.shippingAddress.statues.ConfirmedManually}
                    </span>
                  </FlexBox>
                )}
              </>
            ))}
          {!isEdit ? (
            <div className="d-flex align-items-center justify-content-between mt-4">
              <div className="">
                <p className="fw-600 fs-20px">
                  {orderDetails?.shippingAddress?.type === 'OTHERS' ? 'OTHER' : orderDetails?.shippingAddress?.type}
                </p>
                {orderDetails?.shippingAddress?.addressLine1 ? (
                  <div className="mt-2 helper-text fs-7">{orderDetails?.shippingAddress?.addressLine1}</div>
                ) : null}
                <div className="helper-text mt-2 fs-7">{`${
                  orderDetails?.shippingAddress?.city ? orderDetails?.shippingAddress?.city : null
                }, ${orderDetails?.shippingAddress?.state ? orderDetails?.shippingAddress?.state : null} ${
                  orderDetails?.shippingAddress?.pincode ? orderDetails?.shippingAddress?.pincode : null
                }`}</div>
              </div>
            </div>
          ) : (
            <div className="my-2">
              {addressesCustomerDetails.userAddress &&
                addressesCustomerDetails.userAddress.length > 0 &&
                addressesCustomerDetails.userAddress.map((address, index) => {
                  return (
                    <div className="my-2" key={index}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div key={address.id} className="form-check form-check-sm form-check-custom">
                            <input
                              className="form-check-input widget-9-check"
                              type="checkbox"
                              checked={selectedAdressId === address.id}
                              onChange={(e) => {
                                selectAdress(e, address);
                              }}
                            />
                          </div>
                          <div className="ms-4 fs-5">{address.type === 'OTHERS' ? 'OTHER' : address.type}</div>
                          {address.isPrimary ? <img src={BookmarkIcon} className="ms-2 h-20px" /> : null}
                        </div>
                        <OutsideClickHandler
                          onOutsideClick={() => {
                            if (addressMenu === address.id) {
                              closeAddressMenu();
                            }
                          }}
                        >
                          <span className="position-relative ms-4">
                            <img
                              src={ThreeDotsIcon}
                              className="dropdown-toggle cursor-pointer h-10px "
                              id="shippingAddressDropdownToggle"
                              onClick={() => {
                                if (addressMenu === address.id) {
                                  closeAddressMenu();
                                } else {
                                  openAddressMenu(address.id);
                                }
                              }}
                            />
                            <Dropdown
                              data={
                                address.isPrimary
                                  ? constVariables.shippingCustomerAddressOptionsPrimaryDropdownData
                                  : constVariables.shippingCustomerAddressOptionsDropdownData
                              }
                              onSelect={(e) => selectedOption(e, address)}
                              shiftLeft
                              shiftLeftValue={'-150px'}
                              selected={address.id === addressMenu}
                              className="mt-5"
                              closeDropdown={closeAddressMenu}
                            />
                          </span>
                        </OutsideClickHandler>
                      </div>
                      {address.addressLine1 ? <div className="mt-2 fs-6">{address.addressLine1}</div> : null}
                      <div className="helper-text mt-2 fs-7">{`${address.city ? address.city : null}, ${
                        address.state ? address.state : null
                      } ${address.pincode ? address.pincode : null}`}</div>
                      <Divider classname="my-4" />
                    </div>
                  );
                })}
              <div className="mt-6 w-100 ">
                <button
                  onClick={() => {
                    setIsEditCustomerAddress && setIsEditCustomerAddress(false);
                    openAddressModal && openAddressModal();
                  }}
                  type="button"
                  className="w-100 btn btn-secondary btn-sm"
                >
                  Add New Address
                </button>
              </div>
            </div>
          )}

          {confirmAddressBtnRender()}
        </div>

        <div className="card mb-0">
          <AddNotes
            id={Number(orderId)}
            editOldNote={editOldNote}
            deleteOldNote={deleteOldNote}
            addNewNote={addNewNote}
            notesData={notesData}
            setNotesData={setNotesData}
          />
        </div>

        {/* <div className="card">
          <TagsDetails allTags={allTags} submitTagValue={submitTagValue} removeTag={removeTag} />
        </div> */}
      </div>
    </div>
  );
};

export default OrderDetailsPresentational;
