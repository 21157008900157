import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';

// Apollo
import { useLazyQuery } from '@apollo/client';
import { GET_PRODUCTS_V3 } from 'src/apollo/queries';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { SearchBar, DataTable } from 'src/components/molecules';
import SortableTableHeader from 'src/components/molecules/DataTable/components/SortableTableHeader';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { DefaultImage, RightArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';

// Types
import { WaitlistsListProps } from './WaitlistsList.types';

// Styles
import './_waitlistsList.scss';

const WaitlistsList: FC<WaitlistsListProps> = ({
  waitlists,
  setWaitlists,
  setSortBy,
  sortBy,
  rapidActionOpen,
  isLoading,
  editColumnData
}) => {
  const [selectedWaitlistIDs, setSelectedWaitlistIDs] = useState<Array<number>>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const [activeColumns, setActiveColumns] = useState<
    {
      name: string;
      status: boolean;
    }[]
  >([]);

  const [getProductDetail, { loading: isProductVariantsLoading }] = useLazyQuery(GET_PRODUCTS_V3, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      if (res) {
        const productItem = res?.getAllProductsV3?.products[0];
        const updatedWaitList = waitlists.map((waitListItem) => {
          if (waitListItem.id === productItem?.productId) {
            return { ...waitListItem, variantLocationDetails: productItem?.variantLocationDetails };
          }
          return waitListItem;
        });
        setWaitlists(updatedWaitList);
      }
    },
    onError: (error) => {
      console.log('error InventoryList ::', error);
    }
  });

  const ChangeSortBy = (field: string, id: number) => {
    if (sortBy.catagory === field) {
      if (sortBy.type === 'DESC') {
        setSortBy({
          ...sortBy,
          type: 'ASC'
        });
      } else {
        setSortBy({
          ...sortBy,
          type: 'DESC'
        });
      }
    } else {
      setSortBy({
        catagory: field,
        id,
        type: 'DESC'
      });
    }
    // setOpenSortDropdown(false);
  };

  const selectWaitlist = useCallback(
    (e: any, productId?: any) => {
      if (e === 'All') {
        setSelectedWaitlistIDs(waitlists.map((product) => product.id));
      } else if (e.target.checked) {
        setSelectedWaitlistIDs([...selectedWaitlistIDs, productId]);
      } else {
        setSelectedWaitlistIDs(selectedWaitlistIDs.filter((productItemId) => productItemId !== productId));
      }
    },
    [waitlists, selectedWaitlistIDs]
  );

  const expandWaitlist = (id: number, index: number) => {
    if (index === selectedIndex) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(index);
      getProductDetail({
        variables: {
          input: {
            // productIds: [id],
            productIdsString: JSON.stringify([id]),
            detailed: true,
            filters: {
              // locationIds: []
              locationIdsString: '[]'
            }
          }
        }
      });
    }
  };

  useEffect(() => {
    if (editColumnData?.length > 0) {
      const arr = Array.from(editColumnData);
      editColumnData.map((column, index) => {
        if (!column.status) {
          arr.splice(arr.indexOf(column), 1);
        }
        return null;
      });
      setActiveColumns([...arr]);
      localStorage.setItem('waitlistTable', JSON.stringify(editColumnData));
    }
  }, [editColumnData]);

  const initialColumnOptions = [
    {
      dataKey: 'products',
      name: 'Products',
      width: 400,
      headerRenderer: () => {
        return (
          <div className="form-check form-check-sm form-check-custom">
            <input
              className="me-5 form-check-input widget-9-check"
              type="checkbox"
              onChange={() => {
                if (waitlists.length === selectedWaitlistIDs.length) {
                  setSelectedWaitlistIDs([]);
                } else {
                  selectWaitlist('All');
                }
              }}
            />
            <div className="opacity-0 h-20x w-20x p-2 d-flex align-items-center justify-content-center rounded me-4">
              <img src={RightArrowIcon} className="h-10px w-10px " />
            </div>
            <div>Products</div>
          </div>
        );
      },
      cellRenderer: ({ rowData, rowIndex }) => {
        return (
          <div className="d-flex align-items-center">
            <div className="form-check form-check-sm form-check-custom">
              <input
                className="me-5 form-check-input widget-9-check"
                type="checkbox"
                onChange={(e) => {
                  selectWaitlist(e, rowData.id);
                }}
              />
            </div>
            <div
              onClick={() => {
                expandWaitlist(rowData.id, rowIndex);
              }}
              className="h-20x w-20x p-2 d-flex align-items-center justify-content-center cursor-pointer rounded me-4"
            >
              <img src={RightArrowIcon} className={`h-10px w-10px ${selectedIndex === rowIndex ? 'rotatedArrow' : ''}`} />
            </div>
            <div className="align-items-center d-flex">
              <div className="symbol me-5">
                <Img
                  src={setImageSrc(rowData?.mediaUrl ? generateCompressedImageURL(rowData.mediaUrl, '50') : DefaultImage)}
                  placeholderImg={DefaultImage}
                  errorImg={DefaultImage}
                  className="object-fit-scale-down bg-white border border-light border-2"
                />
              </div>
              <div className="text-left">
                <Link
                  to={{
                    pathname: `/products/allProducts/edit/${rowData?.id}`,
                    state: { from: ROUTES.orders.allOrders.waitlists }
                  }}
                  className="main-cell"
                >
                  <span className="d-block">{rowData.name}</span>
                </Link>
              </div>
            </div>
          </div>
        );
      },
      staticColumn: true
    },
    // {
    //   dataKey: 'commited',a
    //   name: 'Commited',
    //   width: 120,
    //   headerRenderer: () => {
    //     return <SortableTableHeader label="Commited" sortHeader={() => ChangeSortBy('Commited', 82)} type={sortBy.type} />;
    //   },
    //   cellRenderer: ({ rowData }) => {
    //     return <FlexBox className="align-items-center">{rowData.committedQuantity ? rowData.comittedQuantity : '-'}</FlexBox>;
    //   }
    // },
    {
      dataKey: 'waitlist',
      name: 'Waitlist',
      width: 120,
      headerRenderer: () => {
        return <SortableTableHeader label="Waitlist" sortHeader={() => ChangeSortBy('WAITLIST_QUANTITY', 83)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return <FlexBox className="align-items-center">{rowData.waitlistQuantity ? rowData.waitlistQuantity : '-'}</FlexBox>;
      }
    },
    {
      dataKey: 'waitlistAuthorized',
      name: 'Waitlist Authorized',
      width: 200,
      headerRenderer: () => {
        return (
          <SortableTableHeader
            label="Waitlist Authorized"
            sortHeader={() => ChangeSortBy('WAITLIST_AUTH_QUANTITY', 84)}
            type={sortBy.type}
          />
        );
      },
      cellRenderer: ({ rowData }) => {
        return (
          <FlexBox className="align-items-center">{rowData.waitlistAuthQuantity ? rowData.waitlistAuthQuantity : '-'}</FlexBox>
        );
      }
    },
    {
      dataKey: 'type',
      name: 'Type',
      width: 120,
      headerRenderer: () => {
        return <SortableTableHeader label="Type" sortHeader={() => ChangeSortBy('Type', 84)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return (
          <FlexBox className="align-items-center">
            <div className="d-flex align-items-center justify-content-center">-</div>
          </FlexBox>
        );
      }
    },
    {
      dataKey: 'vendor',
      name: 'Vendor',
      width: 120,
      headerRenderer: () => {
        return <SortableTableHeader label="Vendor" sortHeader={() => ChangeSortBy('Vendor', 84)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return (
          <FlexBox className="align-items-center">
            <div className="d-flex align-items-center justify-content-center">-</div>
          </FlexBox>
        );
      }
    },
    {
      dataKey: 'price',
      name: 'Price',
      width: 120,
      headerRenderer: () => {
        return <SortableTableHeader label="Price" sortHeader={() => ChangeSortBy('PRICE', 84)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return <FlexBox className="align-items-center">{rowData.price ? `$${rowData.price.toFixed(2)}` : '-'}</FlexBox>;
      }
    },
    {
      dataKey: 'restockStatus',
      name: 'Restock status',
      width: 120,
      headerRenderer: () => {
        return <SortableTableHeader label="Price" sortHeader={() => ChangeSortBy('Restock status', 84)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return (
          <FlexBox className="align-items-center">
            <div>-</div>
          </FlexBox>
        );
      }
    },
    {
      dataKey: 'estimatedReorderProfit',
      name: 'Estimated reorder profit',
      width: 200,
      headerRenderer: () => {
        return (
          <SortableTableHeader
            label="Estimated reorder profit"
            sortHeader={() => ChangeSortBy('Estimated reorder profit', 84)}
            type={sortBy.type}
          />
        );
      },
      cellRenderer: ({ rowData }) => {
        return (
          <FlexBox className="align-items-center">
            <div className="d-flex align-items-center justify-content-center">-</div>
          </FlexBox>
        );
      }
    }
  ];

  const columnOptions = useMemo(() => {
    const activeColumnNames = activeColumns?.map((column) => column.name);
    // eslint-disable-next-line array-callback-return
    const filteredColumns = initialColumnOptions
      ?.filter((column) => {
        if (column.staticColumn || activeColumnNames?.includes(column?.name)) return true;
        return false;
      })
      .sort(function (a, b) {
        return activeColumnNames.indexOf(a.name) - activeColumnNames.indexOf(b.name);
      });
    return filteredColumns;
  }, [activeColumns, selectedWaitlistIDs, selectedIndex]);

  return (
    <div className="w-100">
      <FlexBox className="justify-content-between w-100">
        <span className="searchBar">
          <SearchBar placeholder={constVariables.WaitlistsMenu.searchForProducts} />
        </span>
        {/* <FlexBox className="pb-1">
          <div className="position-relative ms-3">
            <OutsideClickHandler
              onOutsideClick={() => {
                if (openSortDropdown) setOpenSortDropdown(false);
              }}
            >
              <button
                className={`btn optBtn btn-sm btn-flex btn-light fw-bolder ${
                  openSortDropdown ? 'btn-primary' : 'btn-active-primary'
                }`}
                onClick={() => {
                  setOpenSortDropdown(!openSortDropdown);
                }}
              >
                <KTSVG path={SortIcon} className="svg-icon-8 svg-icon-gray-500 me-1" />
                <span className="my-auto me-0 poppins-semibold f-14px">Sort</span>
              </button>
              <SortDropdown data={SortIds} value={sortBy} onSelect={ChangeSortBy} selected={openSortDropdown} closeDropdown={() => setOpenSortDropdown(false)} />
            </OutsideClickHandler>
          </div>
          <button className="btn optBtn btn-sm btn-flex btn-light btn-active-primary fw-bolder ms-3">
            <KTSVG path={FilterIcon} className="svg-icon-8 svg-icon-gray-500 me-1" />
            <span className="my-auto me-0 poppins-semibold f-14px">Filter</span>
          </button>
        </FlexBox> */}
      </FlexBox>
      {selectedWaitlistIDs.length > 0 && (
        <FlexBox className="m-t-24 justify-content-start align-items-center">
          <button className="btn btn-outlined-primary btn-sm me-2" onClick={() => setSelectedWaitlistIDs([])}>
            <div className="d-flex align-items-center">
              <input
                className={'form-check-input small-checkbox-size shadow-none opacity-100 bg-primary rounded-sm m-0'}
                type="checkbox"
                disabled={true}
                ref={(input) => {
                  if (input) {
                    input.indeterminate = true;
                  }
                }}
              />
              <span className="ms-2">{selectedWaitlistIDs.length} Selected</span>
            </div>
          </button>

          <button className="btn btn-outlined-primary btn-sm me-2">{constVariables.ProductsTab.moreActions}</button>
        </FlexBox>
      )}

      <div className="p-t-12">
        <DataTable
          page="waitlist"
          rowData={waitlists}
          columnOptions={columnOptions}
          isLoading={isLoading}
          searchText=""
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          selectedIDs={selectedWaitlistIDs}
          isDetailsLoading={isProductVariantsLoading}
        />
      </div>
    </div>
  );
};

export default WaitlistsList;
