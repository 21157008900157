import { gql } from '@apollo/client';

export const GET_SHIPPING_PROVIDERS = gql`
  query GetShippingProviders @api(name: "ordersAPI") {
    getShippingProviders {
      __typename
      id
      defaultProvider
      isShippoConnectd
      isShipStationConnectd
    }
  }
`;
