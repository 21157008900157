import React, { FC, useEffect, useMemo, useState, useCallback } from 'react';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_VENDORS, GET_PRODUCT_TYPES, GET_COLLECTIONS, GET_TAGS_LIST } from 'src/apollo/queries';

// Components
import { FlexBox } from 'src/components/atoms';
import FilterMenuItem from './FilterMenuItem';
import FilterRadioGroup from './FilterRadioGroup';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon } from 'src/assets/icons';

// Types
import { RightFilterMenuProps, OptionItem, FilterFields } from './RightFilterMenu.types';

// Styles
import './_rightFilterMenu.scss';

const statusData: OptionItem[] = [
  { id: 1, name: 'Active' },
  { id: 2, name: 'Inactive' },
  { id: 3, name: 'Draft' },
  { id: 4, name: 'Archived' }
];

// const availabilityData: OptionItem[] = [
//   { id: 1, name: 'Unavailable on all channels' },
//   { id: 2, name: 'Available on Mobile app' },
//   { id: 3, name: 'Available on Online store' },
//   { id: 4, name: 'Available on Online store' }
// ];

const stockData: OptionItem[] = [
  { id: 1, name: 'In-stock', textValue: 'IN_STOCK' },
  { id: 2, name: 'Out of stock', textValue: 'OUT_OF_STOCK' },
  { id: 3, name: 'Low stock', textValue: 'LOW_STOCK' }
];

const RightFilterMenu: FC<RightFilterMenuProps> = ({
  closeMenu,
  handleFilter,
  // updateFilterData,
  filterData,
  showFilterMenu,
  setFilterData,
  setProductInput,
  getProductInput,
  handleClearProductFilter,
  menuList
}) => {
  const [vendors, setVendors] = useState<OptionItem[]>([]);
  const [productTypes, setProductTypes] = useState<OptionItem[]>([]);
  const [tags, setTags] = useState<OptionItem[]>([]);
  const [collections, setCollections] = useState<OptionItem[]>([]);
  // const [statusList, setStatusList] = useState<OptionItem[]>(statusData);

  const [filters, setFilters] = useState<FilterFields>(filterData);

  const input = {
    ids: [],
    pagination: {
      skipCount: 0,
      limitCount: 1000
    },
    status: 'ACTIVE',
    sort: {
      column: 'NAME',
      order: 'ASC'
    }
  };

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      closeMenu();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  const sortOptions = (options: OptionItem[]) => {
    return options.sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  };

  const { data: vendorData } = useQuery(GET_VENDORS, {
    variables: {
      input
    },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    onError: (error) => {
      console.log('error', error);
    }
  });

  const { data: productTypesData } = useQuery(GET_PRODUCT_TYPES, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    onError: (error) => {
      console.log('error', error);
    }
  });

  const { data: tagsData } = useQuery(GET_TAGS_LIST, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    variables: {
      input: {
        filters: {
          searchText: ''
        }
      }
    },
    onError: (error) => {
      console.log('error', error);
    }
  });

  const { data: collectionsData } = useQuery(GET_COLLECTIONS, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    variables: {
      input: {
        setDroppableByStatus: false,
        pageInfo: {
          skipCount: 0,
          limitCount: 1000
        }
      }
    }
  });

  useEffect(() => {
    if (collectionsData?.getCollections?.collections) {
      setCollections(
        sortOptions(
          collectionsData?.getCollections?.collections?.map((item) => {
            return { id: item.id, name: item.name };
          })
        )
      );
    }
  }, [collectionsData]);

  useEffect(() => {
    if (vendorData?.getVendorDetails?.vendors) {
      setVendors(
        sortOptions(
          vendorData?.getVendorDetails?.vendors?.map((item) => {
            return { id: item.id, name: item.name };
          })
        )
      );
    }
  }, [vendorData]);

  useEffect(() => {
    if (tagsData?.getTagList) {
      setTags(
        sortOptions(
          tagsData?.getTagList?.map((item) => {
            return { id: item.id, name: item.name };
          })
        )
      );
    }
  }, [tagsData]);

  useEffect(() => {
    if (productTypesData?.getAllProductTypes) {
      setProductTypes(
        sortOptions(
          productTypesData?.getAllProductTypes?.map((item) => {
            return { id: item.id, name: item.name };
          })
        )
      );
    }
  }, [productTypesData]);

  const hasSelected = useMemo(() => {
    let has = false;
    const tempFilters = Object.keys(filters);
    for (let i = 0; i < tempFilters.length; i++) {
      if (filters[tempFilters[i]]?.length > 0) has = true;
    }
    return has;
  }, [filters]);

  const updateFilterData = (fieldName: string, value) => {
    setFilters((productFilters) => {
      return { ...productFilters, [fieldName]: value };
    });
  };

  const handleResetFilters = () => {
    // setProductInput((getProductInput) => {
    //   return {
    //     ...getProductInput,
    //     filters: {
    //       ...getProductInput.filters,
    //       vendorIDs: [],
    //       filterStatus: [],
    //       tags: [],
    //       productTypes: [],
    //       collections: [],
    //       stockAvailability: undefined
    //     }
    //   };
    // });

    setFilters({
      vendorIDs: null,
      tags: null,
      filterStatus: null,
      productTypes: null,
      collections: null,
      stockAvailability: undefined
    });

    // if (handleClearProductFilter) {
    //   handleClearProductFilter();
    // }
  };

  useEffect(() => {
    setFilters((filters) => {
      return {
        ...filters,
        vendorIDs: filterData.vendorIDs,
        tags: filterData.tags,
        filterStatus: filterData.filterStatus,
        productTypes: filterData.productTypes,
        collections: filterData.collections,
        stockAvailability: filterData.stockAvailability ? filterData.stockAvailability : undefined
      };
    });
  }, [filterData]);

  const renderFilterSection = (menu: string) => {
    switch (menu) {
      case 'vendors':
        return (
          <FilterMenuItem
            typeTitle="Vendor"
            title="Product Vendor"
            inputPlaceholder="Select product vendor"
            options={vendors}
            inputName="vendor"
            showSearchBox={true}
            filters={filters.vendorIDs}
            updateFilterData={updateFilterData}
            fieldName="vendorIDs"
          />
        );
      case 'tags':
        return (
          <FilterMenuItem
            typeTitle="Tag"
            title="Tagged with"
            inputPlaceholder="Search tag"
            options={tags}
            inputName="tag"
            showSearchBox={true}
            filters={filters.tags}
            updateFilterData={updateFilterData}
            fieldName="tags"
          />
        );
      case 'status':
        return (
          <FilterMenuItem
            title="Status"
            options={statusData}
            showSearchBox={false}
            fieldName="filterStatus"
            filters={filters.filterStatus}
            updateFilterData={updateFilterData}
          />
        );
      case 'productTypes':
        return (
          <FilterMenuItem
            title="Product type"
            typeTitle="Product type"
            inputPlaceholder="Search for products"
            options={productTypes}
            inputName="type"
            showSearchBox={true}
            fieldName="productTypes"
            filters={filters.productTypes}
            updateFilterData={updateFilterData}
          />
        );
      case 'collections':
        return (
          <FilterMenuItem
            typeTitle="Collection"
            title="Collection"
            inputPlaceholder="Search for collection"
            options={collections}
            inputName="collection"
            showSearchBox={true}
            fieldName="collections"
            filters={filters.collections}
            updateFilterData={updateFilterData}
          />
        );
      case 'stockAvailability':
        return (
          <FilterRadioGroup
            title="Stock availability"
            options={stockData}
            fieldName="stockAvailability"
            selectedValue={filters.stockAvailability}
            updateFilterData={updateFilterData}
          />
        );
      default:
        return '';
    }
  };

  return (
    <div
      id="kt_activities"
      className={`bg-body drawer drawer-end liveshow-event-preview overflow-visible ${showFilterMenu ? 'drawer-on' : ''}`}
      data-kt-drawer="true"
      data-kt-drawer-name="activities"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'419.5px', 'sm': '419.5px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_activities_toggle"
      data-kt-drawer-close="#kt_activities_close"
    >
      <div className="filter-menu w-100">
        <FlexBox className="filter-menu-header align-items-center justify-content-between">
          <h6 className="m-0 poppins-medium">Filter</h6>
          <KTSVG path={CloseIcon} className="svg-icon-2 close-icon cursor-pointer" onClick={closeMenu} />
        </FlexBox>
        <div className="filter-menu-content overflow-scroll">
          {menuList.map((menu, index) => (
            <React.Fragment key={index}>{renderFilterSection(menu)}</React.Fragment>
          ))}

          {/* <FilterMenuItem
            title="Availability"
            options={availabilityData}
            showSearchBox={false}
            fieldName="availability"
          /> */}
          {/* <FilterMenuItem title="Stock" options={stockData} showSearchBox={false} fieldName="stock" isFilterById={false} /> */}
        </div>

        <FlexBox className="filter-menu-footer align-items-center justify-content-end">
          <button className="btn btn-sm btn-secondary me-4" onClick={handleResetFilters}>
            Reset
          </button>
          <button className="btn btn-sm btn-primary" disabled={!hasSelected} onClick={() => handleFilter(filters)}>
            Apply
          </button>
        </FlexBox>
      </div>
    </div>
  );
};

export default RightFilterMenu;
