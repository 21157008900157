import { gql } from '@apollo/client';

export const GET_WAREHOUSE = gql`
  query GetWarehouse($input: GetWarehouseInput) @api(name: "productsAPI") {
    getWarehouse(input: $input) {
      __typename
      totalWarehouses
      warehouses {
        __typename
        id
        shippoId
        name
        storeId
        addressLine1
        city
        state
        country
        pincode
        phoneCountryCode
        phone
        isActive
        isAddressVerified
        isDefault
      }
    }
  }
`;
