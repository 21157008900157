import { useEffect, useState, FC } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_PRODUCTS_V3, GET_WAREHOUSE, GET_PRODUCTS_V3_BY_SCAN_SKU } from 'src/apollo/queries';

// Components
import { FlexBox } from 'src/components/atoms';
import { Dropdown } from 'src/components/molecules';
import InventoryManagementPagePresentational from './InventoryManagementPage.presentational';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import useTitle from 'src/utils/hooks/useTitle';

// Icons
import { DownArrowIcon, WarehouseBuilding } from 'src/assets/icons';

// Types
import { WarehouseListProp } from 'src/components/oraganisms/AddProductInventory/AddProductInventory.types';
import { InventoryManagementListData, WarehouseOptionItem, getInventoryInputType } from './InventoryManagementPage.type';
import { FilterFields } from 'src/components/molecules/RightFilterMenu/RightFilterMenu.types';
import { getProductFilterInput } from 'src/utils/convertProductAPIInput';

const WAREHOUSE_ID = 'warehouseId';
const InventoryManagementPage: FC = () => {
  useTitle('Inventory management');
  const [inventoryList, setInventoryList] = useState<InventoryManagementListData[]>([]);
  const [warehouseList, setWarehouseList] = useState<WarehouseListProp[]>();
  const [isWarehouseDropdown, setIsWarehouseDropdown] = useState(false);
  const [selectedWareHouse, setSelectedWareHouse] = useState<WarehouseOptionItem>();
  const [totalCount, setTotalCount] = useState(0);
  const [sortBy, setSortBy] = useState({
    catagory: 'Default',
    id: 1,
    type: 'ASC'
  });

  const [inventoryFilters, setInventoryFilters] = useState<FilterFields>({
    tags: [],
    productTypes: []
  });

  const [getInventoryInput, setInventoryInput] = useState<getInventoryInputType>({
    sortBy: {
      fieldID: sortBy.id,
      type: sortBy.type
    },
    filters: {
      searchText: '',
      PriceRange: {
        min: null,
        max: null
      },
      tags: [],
      productTypes: [],
      stockAvailability: undefined
    },
    pageInfo: {
      skipCount: 0,
      limitCount: 50
    },
    detailed: false,
    warehouseIds: undefined
  });

  useEffect(() => {
    setInventoryInput((inventoryInput) => {
      return {
        ...inventoryInput,
        sortBy: {
          fieldID: sortBy.id,
          type: sortBy.type
        }
      };
    });
  }, [sortBy]);

  const [showFilterChips, setShowFilterChips] = useState(false);

  const [warehousePageInfo] = useState({
    skipCount: 0,
    limitCount: 50
  });

  const [getProductByScanSku] = useLazyQuery(GET_PRODUCTS_V3_BY_SCAN_SKU, {
    onCompleted: (res) => {
      console.log('res::', res);
    },
    onError: (err) => {
      console.log('error::', err);
    }
  });

  useEffect(() => {
    let barcodeInput = '';
    let lastKeypressTime = 0;

    document.addEventListener('keydown', (e) => {
      const currentTime = Date.now();
      if (currentTime - lastKeypressTime > 200) {
        barcodeInput = '';
      }
      if (e.key.length === 1 && e.key !== 'Enter' && e.key !== 'Shift') {
        barcodeInput += e.key;
      }

      if (e.key === 'Enter') {
        let barcode = barcodeInput;
        barcodeInput = '';
        const productFilter = {
          ...getInventoryInput,
          filters: {
            ...getInventoryInput.filters,
            searchText: barcode
          },
          detailed: true,
          isForQRScan: true
        };

        getProductByScanSku({
          variables: {
            input: getProductFilterInput(productFilter)
          },
          context: {
            randomId: Math.random().toString(36).substring(7)
          }
        });
      }

      lastKeypressTime = currentTime;
    });

    return () => {};
  }, []);

  useQuery(GET_WAREHOUSE, {
    variables: {
      input: {
        pageInfo: warehousePageInfo,
        status: 'ACTIVE'
      }
    },

    onCompleted: (wareHouseData) => {
      if (wareHouseData) {
        const tempList: WarehouseOptionItem[] = wareHouseData.getWarehouse.warehouses.map((data) => {
          return {
            name: data.name,
            id: data.id,
            icon: WarehouseBuilding
          };
        });
        if (!selectedWareHouse) {
          const savedId = localStorage.getItem(WAREHOUSE_ID);
          if (savedId) {
            const saved = tempList.find((t) => Number(t.id) === Number(savedId));
            if (saved) {
              setSelectedWareHouse(saved);
            }
          } else {
            setSelectedWareHouse(tempList[0]);
          }
        }
        setWarehouseList(tempList);
      }
    },

    onError: (error) => {
      console.error('err', error);
    }
  });

  const openWarehouseDropdown = () => {
    setIsWarehouseDropdown(true);
  };
  const closeWarehouseDropdown = () => {
    setIsWarehouseDropdown(false);
  };

  sortBy.catagory === 'Default' && delete getInventoryInput.sortBy;
  const processedInput = getProductFilterInput(getInventoryInput);
  const { loading: isLoading, error } = useQuery(GET_PRODUCTS_V3, {
    errorPolicy: 'all',
    variables: {
      input: processedInput
    },
    onCompleted: (res) => {
      if (res) {
        const invList = res?.getAllProductsV3?.products.map((product) => {
          const productItem = {
            ...product,
            productMediasJson: product?.productMediasJson
              ? product?.productMediasJson.filter((mediaItem) => mediaItem.productMediaType === 'IMAGE')
              : []
          };
          return productItem;
        });
        setInventoryList(invList);
        setTotalCount(res?.getAllProductsV3?.totalProducts);
      }
    }
  });

  useEffect(() => {
    if (error) {
      console.error('InventoryManagementPage error', error);
    }
  }, [error]);

  useEffect(() => {
    setInventoryInput({
      ...getInventoryInput,
      pageInfo: {
        ...getInventoryInput.pageInfo,
        skipCount: 0
      }
    });
  }, [getInventoryInput.filters.searchText]);

  const selectWarehouse = (data: any) => {
    setSelectedWareHouse(data);
    closeWarehouseDropdown();
  };

  useEffect(() => {
    if (selectedWareHouse) {
      localStorage.setItem(WAREHOUSE_ID, selectedWareHouse.id.toString());
    }
    setInventoryInput({
      ...getInventoryInput,
      warehouseIds: selectedWareHouse ? `[${selectedWareHouse.id.toString()}]` : undefined
    });
  }, [selectedWareHouse]);
  const WarehouseSelector = () => (
    <div className="position-relative w-300px ms-5">
      <OutsideClickHandler onOutsideClick={closeWarehouseDropdown}>
        <FlexBox
          className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box ${
            isWarehouseDropdown ? 'dropdown-box-active' : ''
          }`}
          onClick={openWarehouseDropdown}
        >
          {selectedWareHouse ? (
            <FlexBox className="align-items-center">
              <KTSVG svgColor="#706FD3" path={WarehouseBuilding} />
              <div className="ms-2">{selectedWareHouse.name}</div>
            </FlexBox>
          ) : (
            <FlexBox className="align-items-center">
              <KTSVG svgColor="#706FD3" path={WarehouseBuilding} />
              <div className="ms-2">Select Warehouse</div>
            </FlexBox>
          )}
          <div>
            <KTSVG path={DownArrowIcon} />
          </div>
        </FlexBox>
        {warehouseList ? (
          <Dropdown
            className="dropdown-custom-width mt-3"
            data={warehouseList}
            selected={isWarehouseDropdown}
            value={selectedWareHouse?.name}
            onSelect={selectWarehouse}
            closeDropdown={() => setIsWarehouseDropdown(false)}
          />
        ) : null}
      </OutsideClickHandler>
    </div>
  );

  const stockQuantity = (adjustmentType, oldQuantity, changedQuantity) => {
    switch (adjustmentType) {
      case 'STOCK_RECEIVED':
        return oldQuantity + changedQuantity;
      case 'INVENTORY_RECOUNT':
        return changedQuantity;
      case 'DAMAGE':
        return oldQuantity - changedQuantity;
      case 'THEFT':
        return oldQuantity - changedQuantity;
      case 'LOSS':
        return oldQuantity - changedQuantity;
      case 'RESTOCK_RETURN':
        return oldQuantity + changedQuantity;
      case 'GIVEAWAY':
        return oldQuantity - changedQuantity;
      default:
        return;
    }
  };

  const updatedInventoryData = (updatedInventory, fieldName, oldInventory) => {
    switch (fieldName) {
      case 'productQty':
        return stockQuantity(updatedInventory.adjustmentType, oldInventory.productQty, updatedInventory.quantity);
      case 'productVariantQty':
        return stockQuantity(updatedInventory.adjustmentType, oldInventory.productVariantQty, updatedInventory.quantity);
      case 'alertThreshold':
        return updatedInventory.quantity;
      case 'variantAlertThreshold':
        return updatedInventory.quantity;
      case 'productLocation':
        return [{ productLocationId: updatedInventory.locationId, productLocationName: updatedInventory.name }];
      case 'variantLocation':
        return [
          {
            productVariantLocationId: updatedInventory.locationId,
            productVariantLocationName: updatedInventory.name,
            warehouseId: updatedInventory.warehouseId,
            productVariantLocationQuantity: oldInventory?.variantLocation[0]?.productVariantLocationQuantity
          }
        ];
      default:
        return;
    }
  };
  const updatedInventoryDataByWarehouseId = (updatedInventory, fieldName, oldInventory, warehouseId) => {
    if (fieldName === 'productQty') {
      if (oldInventory.inventory && typeof oldInventory.inventory === 'string' && warehouseId) {
        const parsed = JSON.parse(oldInventory.inventory);
        if (parsed && parsed.inventory && parsed.inventory.length > 0) {
          const foundInv = parsed.inventory.find((i) => Number(i.warehouseId) === Number(warehouseId));
          if (foundInv) {
            foundInv.inventoryQuantity = stockQuantity(
              updatedInventory.adjustmentType,
              foundInv.inventoryQuantity,
              updatedInventory.quantity
            );
          }
        }
        return JSON.stringify(parsed);
      }
      return oldInventory.inventory;
    }

    if (fieldName === 'productVariantQty') {
      const variantId = updatedInventory.variantId;
      const index = oldInventory.variantLocationDetails.findIndex((v) => Number(v.productVariantId) === Number(variantId));
      const detail = oldInventory.variantLocationDetails[index];
      if (detail && detail.variantLocation && detail.variantLocation.length > 0) {
        const copy = JSON.parse(JSON.stringify(oldInventory.variantLocationDetails));
        const found = detail.variantLocation.find((l) => Number(l.warehouseId) === Number(warehouseId));
        if (found) {
          const updatedInv = {
            ...found,
            productVariantLocationQuantity: stockQuantity(
              updatedInventory.adjustmentType,
              found.productVariantLocationQuantity,
              updatedInventory.quantity
            )
          };
          copy[index] = {
            ...detail,
            variantLocation: [updatedInv]
          };
          return copy;
        }
      }
      return oldInventory.variantLocationDetails;
    }
  };

  const handleUpdateInventoryList = (updatedInventory, fieldName, productId) => {
    if (updatedInventory.type === 'PRODUCT') {
      const updatedInventoryList = inventoryList.map((inventory) => {
        if (inventory.productId === updatedInventory.id) {
          if (fieldName === 'productQty' && selectedWareHouse) {
            return {
              ...inventory,
              inventory: updatedInventoryDataByWarehouseId(updatedInventory, fieldName, inventory, selectedWareHouse.id)
            };
          }
          if (fieldName === 'productVariantQty' && selectedWareHouse) {
            return {
              ...inventory,
              variantLocationDetails: updatedInventoryDataByWarehouseId(
                updatedInventory,
                fieldName,
                inventory,
                selectedWareHouse.id
              )
            };
          }
          return {
            ...inventory,
            [fieldName]: updatedInventoryData(updatedInventory, fieldName, inventory)
          };
        } else {
          return inventory;
        }
      });
      setInventoryList(updatedInventoryList);
    } else if (productId) {
      const updatedInventoryList = inventoryList.map((inventory) => {
        if (inventory.productId === productId) {
          const updatedVariants = inventory?.variantLocationDetails?.map((variant) => {
            if (variant.productVariantId === updatedInventory.id) {
              return {
                ...variant,
                [fieldName]: updatedInventoryData(updatedInventory, fieldName, variant)
              };
            } else {
              return variant;
            }
          });

          let totalProductQty;
          if (fieldName === 'productVariantQty') {
            totalProductQty = updatedVariants?.reduce((accumulator, object) => {
              return accumulator + object.productVariantQty;
            }, 0);
          }

          return {
            ...inventory,
            variantLocationDetails: updatedVariants,
            productQty: fieldName === 'productVariantQty' ? totalProductQty : inventory.productQty
          };
        } else {
          return inventory;
        }
      });

      setInventoryList(updatedInventoryList);
    } else if (fieldName === 'changeLocations') {
      const updatedInventoryList = inventoryList.map((item) => {
        return {
          ...item,
          productLocation: item.productLocation?.map((location) => {
            if (location.productLocationId === updatedInventory.id) {
              return { ...location, productLocationName: updatedInventory.name };
            }
            return location;
          }),
          variantLocationDetails: item.variantLocationDetails?.map((variant) => {
            return {
              ...variant,
              variantLocation: variant?.variantLocation?.map((variantLocationItem) => {
                if (variantLocationItem.productVariantLocationId === updatedInventory.id) {
                  return { ...variantLocationItem, productVariantLocationName: updatedInventory.name };
                }
                return variantLocationItem;
              })
            };
          })
        };
      });
      setInventoryList(updatedInventoryList);
    } else if (fieldName === 'deleteLocations') {
      const updatedInventoryList = inventoryList.map((item) => {
        return {
          ...item,
          productLocation:
            item?.productLocation && item?.productLocation[0]?.productLocationId === updatedInventory.id
              ? []
              : item.productLocation,
          variantLocationDetails: item?.variantLocationDetails?.map((variant) => {
            return {
              ...variant,
              variantLocation:
                variant?.variantLocation[0]?.productVariantLocationId === updatedInventory.id ? [] : variant?.variantLocation
            };
          })
        };
      });
      setInventoryList(updatedInventoryList);
    } else {
      const updatedLocation = { productLocationId: updatedInventory.id, name: updatedInventory.productLocationName };
      // const updatedInventoryList = inventoryList.map((inventory) => {
      //   if (inventory.productLocation.productLocationId === updatedInventory.id) {
      //     return {
      //       ...inventory,
      //       productLocation: updatedLocation
      //     };
      //   } else {
      //     return {
      //       ...inventory,
      //       variantLocationDetails: inventory?.variantLocationDetails?.map((variant) => {
      //         if (variant?.variantLocation?.id === updatedInventory.id) {
      //           return { ...variant, variantLocation: updatedLocation };
      //         }
      //         return variant;
      //       })
      //     };
      //   }
      // });
      // setInventoryList(updatedInventoryList);
    }
  };

  const handleUpdateInventoryFilters = (fieldName: string, value) => {
    setInventoryFilters((productFilters) => {
      return { ...productFilters, [fieldName]: value };
    });
  };

  const handleClearInventoryFilter = () => {
    setShowFilterChips(false);
    setInventoryFilters({
      tags: [],
      productTypes: []
    });
    setInventoryInput((getInventoryInput) => {
      return {
        ...getInventoryInput,
        filters: {
          ...getInventoryInput.filters,
          tags: [],
          productTypes: [],
          stockAvailability: undefined
        }
      };
    });
  };

  return (
    <InventoryManagementPagePresentational
      inventoryListData={inventoryList}
      setInventoryList={setInventoryList}
      updateInventoryList={handleUpdateInventoryList}
      sortBy={sortBy}
      setSortBy={setSortBy}
      getInventoryInput={getInventoryInput}
      setInventoryInput={setInventoryInput}
      HeadingExtenstion={WarehouseSelector}
      isLoading={isLoading}
      totalCount={totalCount}
      selectedWarehouseId={selectedWareHouse?.id}
      inventoryFilters={inventoryFilters}
      setInventoryFilters={setInventoryFilters}
      handleClearInventoryFilter={handleClearInventoryFilter}
      showFilterChips={showFilterChips}
      setShowFilterChips={setShowFilterChips}
      handleUpdateInventoryFilters={handleUpdateInventoryFilters}
    />
  );
};

export default InventoryManagementPage;
