import { gql } from '@apollo/client';

export const GET_LOGGEDIN_FB_PROFILE = gql`
  query getLoggedInFBProfile @api(name: "liveStreamingAPI") {
    getLoggedInFBProfile {
      __typename
      id
      name
      picture
      expireAt
    }
  }
`;
