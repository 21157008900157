import { FC, useEffect, useState } from 'react';

// Components
import { FlexBox, Loader } from 'src/components/atoms';
import { FeaturedProductsStatus, RulesEngineProducts } from 'src/components/molecules';
import { TypeSection } from 'src/components/templates';

// Icons
import { BackRoundedIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

// Types
import { FeaturedProductsPresentationalProps } from './FeaturedProducts.types';
import { productAssignmentMethod } from './FeaturedProducts.types';

// Styles
import './_featuredProducts.scss';

const FeaturedProductsPresentational: FC<FeaturedProductsPresentationalProps> = ({
  isPageLoading,
  goBackToCatalogScreen,
  setProductListData,
  setFeaturedProductsStatus,
  productListData,
  featuredProductsStatus,
  deleteProduct,
  // Rules engine
  selectedProductAssignmentMethod,
  selectProductAssignmentMethod,
  rulesEngineConditions,
  setRulesEngineConditions,
  fieldDropdown,
  setAllConditionMatch,
  allConditionMatch,
  conditionDropdownArray,
  setConditionDropdownArray,
  formChanges,
  setFormChanges,
  rulesChanges,
  setRulesChanges,
  saveFeaturedProducts
  // Rules engine
}) => {
  const [hasProductFilterConditions, setHasProductFilterConditions] = useState(false);

  useEffect(() => {
    if (rulesEngineConditions.length > 0) {
      setHasProductFilterConditions(true);
    } else {
      setHasProductFilterConditions(false);
    }
  }, [rulesEngineConditions]);

  const handleRemoveProductsFromFeaturedList = (ids) => {
    deleteProduct(ids);
    setFormChanges(true);
  };

  return (
    <div className="details-page">
      <div className={`details-page-container ${formChanges ? 'pt-0' : ''}`}>
        <FlexBox className={`align-items-center ${formChanges ? 'p-t-24 p-b-40' : 'p-y-40'}`}>
          {/* <img src={BackRoundedIcon} alt="Back" className="me-5 h-30px w-30px cursor-pointer" onClick={goBackToCatalogScreen} /> */}
          <h2 className="page-title m-0">{constVariables.FeaturedProducts.featuredProducts}</h2>
        </FlexBox>
        <div className="main-detail-layout">
          <div className="left-pane">
            <TypeSection
              title="Featured list"
              typeDescription="the featured list"
              selectedProductAssignmentMethod={selectedProductAssignmentMethod}
              selectProductAssignmentMethod={selectProductAssignmentMethod}
              rulesEngineConditions={rulesEngineConditions}
              setRulesEngineConditions={setRulesEngineConditions}
              fieldDropdown={fieldDropdown}
              conditionDropdownArray={conditionDropdownArray}
              setConditionDropdownArray={setConditionDropdownArray}
              allConditionMatch={allConditionMatch}
              setAllConditionMatch={setAllConditionMatch}
              setFormChanges={setFormChanges}
              rulesChanges={rulesChanges}
              setRulesChanges={setRulesChanges}
              saveModuleRules={saveFeaturedProducts}
            />
            {isPageLoading ? (
              <Loader type="page" className="placeholder-content card" />
            ) : (
              <RulesEngineProducts
                type="featured list"
                productListIds={
                  selectedProductAssignmentMethod === productAssignmentMethod.AUTOMATIC
                    ? productListData.autoMaticProductListIds
                    : productListData.manualProductListIds
                }
                selectedProductsIds={productListData.selectedProductsIds}
                setProductListData={setProductListData}
                isAutoType={selectedProductAssignmentMethod === productAssignmentMethod.AUTOMATIC ? true : false}
                hasConditions={hasProductFilterConditions}
                removeProducts={handleRemoveProductsFromFeaturedList}
                setFormChanges={setFormChanges}
              />
            )}
          </div>

          <div className="right-pane">
            <FeaturedProductsStatus
              featuredProductsStatus={featuredProductsStatus}
              setFeaturedProductsStatus={setFeaturedProductsStatus}
              selectedProductAssignmentMethod={selectedProductAssignmentMethod}
              setFormChanges={setFormChanges}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedProductsPresentational;
