import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

// Apollo
import { useLazyQuery } from '@apollo/client';
import { GET_PRODUCTS_V3 } from 'src/apollo/queries';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { FixedHeaderTable, Tooltip } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { RightArrowIcon, DefaultImage, GreenCheckArrow, PlusBlueBorder } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { CommonProductsTableProps } from './CommonProductsBlock.types';
import { TablesProps, RowObjectProps } from '../Table/Table.types';

const CommonProductsTable: FC<CommonProductsTableProps> = ({
  productList,
  setProductList,
  isLoading,
  disabledIds,
  selectedProducts,
  selectProduct,
  isLoadingMore,
  searchText,
  onScroll,
  shouldShowVariants,
  isShowingOnModal,
  isLiveshow,
  handleAddProductsToLivePreview
}) => {
  const [showAllVariants, setShowAllVariants] = useState(false);
  const [showVariantsState, setShowVariantsState] = useState<number[]>([]);

  const tableData: TablesProps = {
    isDragDisabled: true,
    headerClassName: 'liveshow-product-header bg-light',
    className: 'overflow-visible'
  };

  const [getProductVariants] = useLazyQuery(GET_PRODUCTS_V3, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      const productItem = res?.getAllProductsV3?.products[0];
      const updatedProductList = productList.map((product) => {
        if (product.productId === productItem?.productId) {
          return productItem;
        }
        return product;
      });
      setProductList(updatedProductList);
    },
    onError: (error) => {
      console.log('error CommonProductsTable ::', error);
    }
  });

  const renderProductStatus = (productStatus) => {
    return constVariables.statusDropdownData.find((status) => {
      if (productStatus === 'ARCHIVED') {
        return status.name.toLowerCase() === 'archive';
      }
      return status.name.toLowerCase() === productStatus?.toLowerCase();
    })?.name;
  };

  const selectProductStatusClass = (productStatus: string | undefined) => {
    switch (productStatus) {
      case constVariables.ProductsTab.active.toLowerCase():
        return 'active';
      case constVariables.ProductsTab.draft.toLowerCase():
        return 'draft';
      default:
        return 'archive';
    }
  };

  tableData.headers = [
    {
      id: 1,
      className: 'align-middle w-60px table-header-background ps-5 pe-2 py-0',
      colName: (
        <div className="form-check form-check-sm form-check-custom">
          <input
            name="liveshwo-addproduct-table"
            className="form-check-input widget-9-check"
            type="checkbox"
            onClick={(e) => {
              selectProduct(e, 'All');
            }}
            onChange={(e) => {
              selectProduct(e, 'All');
            }}
          />
        </div>
      )
    },
    {
      id: 2,
      className: `align-middle w-60px table-header-background px-2 py-0 ${!shouldShowVariants ? 'd-none' : ''}`,
      colName: (
        <KTSVG
          path={RightArrowIcon}
          className={`m-auto-0 cursor-pointer expand-icon ${showAllVariants ? 'rotate-icon' : ''}`}
          onClick={() => setShowAllVariants(!showAllVariants)}
        />
      )
    },
    {
      id: 3,
      className: 'align-middle w-300px table-header-background px-2 py-0',
      colName: 'Product'
    },
    {
      id: 4,
      className: `align-middle table-header-background px-2 py-0 ${isShowingOnModal ? 'minMidSize' : ''}`,
      colName: 'Inventory'
    },
    {
      id: 5,
      className: `align-middle table-header-background px-2 py-0 ${isShowingOnModal ? 'minBoxSize' : ''}`,
      colName: 'Price'
    },
    {
      id: 6,
      className: `align-middle table-header-background px-2 py-0 ${isShowingOnModal ? 'minBoxSize' : ''}`,
      colName: 'Status'
    }
  ];

  const arr: Array<RowObjectProps> = [];
  productList?.map((product, index) => {
    arr.push({
      className: `position-relative h-fit-content table-row cursor-pointer`,
      id: product.productId,
      cells: [
        {
          className: `align-middle w-60px ps-5 pe-2 py-0 ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <div className="form-check form-check-sm form-check-custom">
              <input
                name="liveshwo-addproduct-cell"
                className="form-check-input widget-9-check"
                type="checkbox"
                disabled={disabledIds && disabledIds.includes(product.productId)}
                checked={selectedProducts.includes(product.productId) || (disabledIds && disabledIds.includes(product.productId))}
                onClick={(e) => {
                  selectProduct(e, product);
                }}
                onChange={(e) => {
                  selectProduct(e, product);
                }}
              />
            </div>
          )
        },
        {
          className: `align-middle w-60px px-2 py-0 ${index !== 0 ? 'table-top-border' : 'border-0'} ${
            !shouldShowVariants ? 'd-none' : ''
          }`,
          value: product?.productHasVariants && (
            <div
              className="cursor-pointer"
              onClick={() => {
                expandVariants(product.productId);
              }}
            >
              <KTSVG
                path={RightArrowIcon}
                className={`m-auto-0 expand-icon ${
                  showVariantsState.findIndex((data) => data === product.productId) > -1 || showAllVariants ? 'rotate-icon' : ''
                }`}
              />
            </div>
          )
        },
        {
          className: `align-middle w-300px text-muted px-2 py-0 ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <FlexBox className="align-items-center">
              <div className="symbol me-5">
                <Img
                  src={setImageSrc(
                    product?.productMediasJson
                      ? generateCompressedImageURL(product?.productMediasJson[0]?.productMediaUrl, '50')
                      : DefaultImage
                  )}
                  placeholderImg={DefaultImage}
                  errorImg={DefaultImage}
                  className="object-fit-scale-down bg-white border border-light border-2 commonProductTable"
                />
              </div>
              <FlexBox className="justify-content-start flex-column">
                <Link to={`/products/allProducts/edit/${product.productId}`} className="main-cell" target="_blank">
                  <span className="d-block list-product-name">{product.productName}</span>
                </Link>
              </FlexBox>
            </FlexBox>
          )
        },
        {
          className: `align-middle text-muted px-2 py-0 ${index !== 0 ? 'table-top-border' : 'border-0'} ${
            isShowingOnModal ? 'minMidSize' : ''
          }`,
          value: (
            <div
              className="d-flex"
              style={{
                flexDirection: 'column'
              }}
            >
              <div>{product?.productQty ? product?.productQty : 0} in stock</div>
              <div
                style={{
                  fontSize: '0.85rem'
                }}
              >
                {product?.variants && product?.variants?.length > 0 && `${product?.variants?.length} variants`}
              </div>
            </div>
          )
        },
        {
          className: `align-middle text-muted px-2 py-0 ${index !== 0 ? 'table-top-border' : 'border-0'} ${
            isShowingOnModal ? 'minBoxSize' : ''
          }`,
          value: `$ ${Number(product?.salePrice).toFixed(2)}`
        },
        {
          className: `align-middle text-muted px-2 py-0 ${index !== 0 ? 'table-top-border' : 'border-0'} ${
            isShowingOnModal ? 'minBoxSize' : ''
          }`,
          value: (
            <FlexBox className="align-items-center">
              <FlexBox
                className={`p-2 poppins-medium justify-content-center ${selectProductStatusClass(
                  product?.productStatus?.toLowerCase()
                )}-status-box m-r-12`}
              >
                <span>{renderProductStatus(product?.productStatus)}</span>
              </FlexBox>
              {isLiveshow &&
                (disabledIds?.includes(product.productId) ? (
                  <>
                    <img
                      src={disabledIds?.includes(product.productId) ? GreenCheckArrow : PlusBlueBorder}
                      onClick={(e) => {
                        if (!disabledIds?.includes(product.productId)) {
                          handleAddProductsToLivePreview(e, {
                            productId: product?.productId,
                            productName: product?.productName,
                            productImg: product?.productMediasJson?.[0]?.productMediaUrl,
                            productPrice: product?.salePrice
                          });
                        }
                      }}
                      className={`icon-down-arrow cursor-pointer w-20px`}
                      data-tip
                      data-for="productAlreadyAdded"
                    />
                    <Tooltip
                      tooltipId="productAlreadyAdded"
                      place="top"
                      content={() => {
                        return <>Product already added to live</>;
                      }}
                    />
                  </>
                ) : (
                  <img
                    src={disabledIds?.includes(product.productId) ? GreenCheckArrow : PlusBlueBorder}
                    onClick={(e) => {
                      if (!disabledIds?.includes(product.productId)) {
                        handleAddProductsToLivePreview(e, {
                          productId: product?.productId,
                          productName: product?.productName,
                          productImg: product?.productMediasJson?.[0]?.productMediaUrl,
                          productPrice: product?.salePrice
                        });
                      }
                    }}
                    className={`icon-down-arrow cursor-pointer w-20px`}
                  />
                ))}
            </FlexBox>
          )
        }
      ],
      showVariantDetails: showVariantsState,
      showAllVariants,
      variantDetail: {
        product,
        selectProduct
      }
    });
    return null;
  });

  tableData.rows = arr;

  const expandVariants = (productId) => {
    const tempArr = [...showVariantsState];
    if (showVariantsState.findIndex((data) => data === productId) > -1) {
      const index = tempArr.indexOf(productId);

      if (index > -1) {
        tempArr.splice(index, 1);
      }

      setShowVariantsState(tempArr);
      setShowAllVariants(false);
    } else {
      setShowVariantsState([...showVariantsState, productId]);
      setShowAllVariants(false);
      getProductVariants({
        variables: {
          input: {
            productIdsString: JSON.stringify([productId]),
            detailed: true,
            filters: {
              locationIdsString: '[]'
            }
          }
        }
      });
    }
  };

  return (
    <FixedHeaderTable
      headers={tableData.headers}
      rows={tableData.rows}
      type="Product List"
      loadingPosition={isLoadingMore ? 'bottom' : 'center'}
      searchText={searchText}
      isLoading={isLoading || isLoadingMore}
      containerHeight={isShowingOnModal ? 350 : 500}
      onScroll={onScroll}
      noMargin
    />
  );
};

export default CommonProductsTable;
