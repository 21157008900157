export const SortIds = [
  {
    id: 0,
    name: 'Priority'
  },
  {
    id: 21,
    name: 'Products'
  },
  {
    id: 84,
    name: 'Commited'
  },
  {
    id: 86,
    name: 'Stock level'
  }
];
