/* eslint-disable no-nested-ternary */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Apollo
import { useLazyQuery } from '@apollo/client';
import { GET_LIVE_STREAMS } from 'src/apollo/queries';

// Components
import { Img, Loader } from 'src/components/atoms';

import { setImageSrc } from 'src/utils/setImageSrc';

// Styles
import './LiveshowSearch.scss';
import { addWeeks, addYears, isToday, lastDayOfWeek, startOfWeek, subWeeks } from 'date-fns';
import { LiveshowSearchDropdown } from '../index';
import { useCustomEventListener } from 'react-custom-events';
import { useHistory } from 'react-router';
import { generateCompressedImageURL, generateLivePreviewImageURL } from '../../../utils/generateCompressedImageUrl';
import { DefaultImage } from '../../../assets/icons';

const PAGE_COUNT = 50;

const LiveShowListView = ({ handleSelectLiveshow }) => {
  const [eventList, setEventList] = useState<any[]>([]);
  const [pastList, setPastList] = useState<any[]>([]);
  const [nextList, setNextList] = useState<any[]>([]);

  window.history.replaceState({}, document.title);
  const processResponse = (item, index, items) => {
    const day = new Date(item.startingAt).toLocaleDateString('en-US', {
      weekday: 'long'
    });
    const date = new Date(item.startingAt).toLocaleDateString('en-US', {
      day: 'numeric'
    });
    const monthYear = new Date(item.startingAt).toLocaleDateString('en-US', {
      month: 'long',
      year: 'numeric'
    });
    const startTime = new Date(item.startingAt).toLocaleTimeString('en-US', { timeStyle: 'short' });
    const endTime = new Date(item.endingAt).toLocaleTimeString('en-US', { timeStyle: 'short' });
    const startDate = new Date(item.startingAt);
    const prevItemDate = new Date(items?.[index - 1]?.startingAt).toLocaleDateString('en-US', {
      month: 'long',
      year: 'numeric',
      day: 'numeric'
    });
    const nextItemDate = new Date(items?.[index + 1]?.startingAt).toLocaleDateString('en-US', {
      month: 'long',
      year: 'numeric',
      day: 'numeric'
    });
    const currentItemDate = new Date(item.startingAt).toLocaleDateString('en-US', {
      month: 'long',
      year: 'numeric',
      day: 'numeric'
    });

    return {
      id: item.id,
      name: item.title,
      thumbnailUrl: item.thumbnailUrl || '',
      timings: `${startTime} - ${endTime}`,
      upcoming: new Date(startDate) > new Date(),
      isLive: new Date() > new Date(startDate) && new Date() < new Date(item.endingAt),
      canceled: item.endingAt && new Date() > new Date(item.endingAt) && !item.startedAt && !item.endedAt && !item.isComplete,
      day,
      date,
      monthYear,
      showDate: prevItemDate !== currentItemDate,
      showBorder: nextItemDate !== currentItemDate,
      productImages: item.products?.map((product) => product.productImage),
      data: item
    };
  };

  const [hasMore, setHasMore] = useState(true);
  const [getLiveStreams, { data, loading, error }] = useLazyQuery(GET_LIVE_STREAMS, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      console.log('getLiveStreams data', currentMonth, data.getLiveStreams);
      if (data.getLiveStreams && data.getLiveStreams.length > 0) {
        // TODO, merge and process all
        const processed = data.getLiveStreams?.map((item, index) => processResponse(item, index, data.getLiveStreams));
        setEventList((prevResults) => [...prevResults, ...processed]);
        if (data.getLiveStreams.length < PAGE_COUNT) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    }
  });

  const [currentMonth, setCurrentMonth] = useState(new Date());

  useCustomEventListener('refreshLiveList', () => {
    setEventList([]);
    setPastList([]);
    setNextList([]);
    setHasMore(true);
    getLiveStreams({
      variables: {
        input: {
          // startTime: start,
          // endTime: end,
          pageInfo: {
            limitCount: PAGE_COUNT,
            skipCount: 0
          }
        }
      }
    });
  });

  const loadMore = useCallback(() => {
    if (!loading && hasMore) {
      getLiveStreams({
        variables: {
          input: {
            pageInfo: {
              limitCount: PAGE_COUNT,
              skipCount: eventList.length
            }
          }
        }
      });
    }
  }, [loading, eventList, hasMore]);

  useEffect(() => {
    getLiveStreams({
      variables: {
        input: {
          pageInfo: {
            limitCount: PAGE_COUNT,
            skipCount: 0
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    const upcommings = eventList.filter((r) => r.upcoming);
    const pasts = eventList.filter((r) => !r.upcoming);
    setPastList(pasts);
    setNextList(upcommings);
  }, [eventList]);

  const sentinelRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          console.log('LOADER MORE!!!', currentMonth, loading);
          loadMore();
        }
      },
      { threshold: 0.1 }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (sentinelRef.current) observer.unobserve(sentinelRef.current);
    };
  }, [loadMore]);

  const history = useHistory();
  const goLiveReport = (id: number) => history.push(`/liveShow/report/${id}`);
  return (
    <div
      className={`${eventList?.length > 8 ? 'h-auto' : 'h-700px'} 
      ${loading && eventList?.length === 0 ? 'align-items-center justify-content-center' : ''}
      mb-15 d-flex flex-column mt-1 shadow-sm bg-white rounded fw-bold`}
      style={{
        padding: '32px'
      }}
    >
      {loading && eventList?.length === 0 ? (
        <Loader type="page" />
      ) : (
        <>
          <div className="position-relative d-flex align-items-center justify-content-between pb-5">
            <p className="live-show-list-view-title">Next Liveshows</p>
            <LiveshowSearchDropdown handleSelectLiveshow={(d) => history.push(`/liveShow/report/${d.id}`)} />
          </div>
          <table className="table-row-black-300 align-middle gs-0 gy-4">
            {nextList?.map((item, ind) => (
              <tr
                key={ind}
                className={`table-row live-list-view-table-row ${item.showBorder ? 'border-bottom' : ''}`}
                onClick={() => handleSelectLiveshow(item.data)}
              >
                {item.showDate ? (
                  <td>
                    <div className="d-flex flex-grow">
                      <div className="dateText">{item.date}</div>
                      <div className="ms-2 monthYearText">{`${item.monthYear} • ${item.day}`}</div>
                    </div>
                  </td>
                ) : (
                  <td className="h-65px"> </td>
                )}

                <td>
                  <span className="timeText">{item.timings}</span>
                </td>
                <td className={`${!item.showBorder ? 'border-bottom' : ''}`}>
                  <div className={`my-3 d-flex flex-grow  flex-row flex-nowrap align-items-center`}>
                    <Img
                      className="h-40px w-40px rounded-2"
                      alt={item.name}
                      src={item.thumbnailUrl ? generateLivePreviewImageURL(setImageSrc(item.thumbnailUrl)) : DefaultImage}
                    />
                    <div
                      className="ms-3"
                      style={{
                        fontWeight: 600
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                </td>
                <td className={`${!item.showBorder ? 'border-bottom' : ''}`}>
                  <div className="d-flex flex-grow">
                    {item.isLive ? (
                      <div className="badge badge-light-danger cursor-pointer p-3 fw-normal rounded-pill">Live</div>
                    ) : item.upcoming ? (
                      <div className="badge badge-light-primary cursor-pointer p-3 fw-normal rounded-pill">Upcoming</div>
                    ) : item.canceled ? (
                      <div className="badge badge-light cursor-pointer p-3 fw-normal rounded-pill">Cancelled</div>
                    ) : (
                      <div className="badge badge-light-success p-3 cursor-pointer fw-normal rounded-pill">Completed</div>
                    )}
                  </div>
                </td>
                <td className={`${!item.showBorder ? 'border-bottom' : ''} `}>
                  <div className="d-flex flex-row flex-nowrap align-items-center ">
                    {item.productImages &&
                      item.productImages
                        .slice(0, 10)
                        .map((productImage, ind) => (
                          <Img
                            key={ind}
                            className="h-30px w-30px rounded-circle overlappingImage"
                            alt={item.name}
                            src={productImage ? generateCompressedImageURL(setImageSrc(productImage), '50') : DefaultImage}
                          />
                        ))}
                  </div>
                </td>
              </tr>
            ))}
            <tr>
              <p className="live-show-list-view-title live-show-list-view-title-sub">Past Liveshows</p>
            </tr>
            {pastList?.map((item, ind) => (
              <tr
                key={ind}
                className={`table-row live-list-view-table-row ${item.showBorder ? 'border-bottom' : ''}`}
                onClick={() => goLiveReport(item.data.id)}
              >
                {item.showDate ? (
                  <td>
                    <div className="d-flex flex-grow">
                      <div className="dateText">{item.date}</div>
                      <div className="ms-2 monthYearText">{`${item.monthYear} • ${item.day}`}</div>
                    </div>
                  </td>
                ) : (
                  <td className="h-65px"> </td>
                )}

                <td>
                  <span className="timeText">{item.timings}</span>
                </td>
                <td className={`${!item.showBorder ? 'border-bottom' : ''}`}>
                  <div className={`my-3 d-flex flex-grow  flex-row flex-nowrap align-items-center`}>
                    <Img
                      className="h-40px w-40px rounded-2"
                      alt={item.name}
                      src={item.thumbnailUrl ? generateLivePreviewImageURL(setImageSrc(item.thumbnailUrl)) : DefaultImage}
                    />
                    <Link to={`/liveShow/report/${item.data.id}`} className="main-cell">
                      <div
                        className="ms-3"
                        style={{
                          fontWeight: 600
                        }}
                      >
                        {item.name}
                      </div>
                    </Link>
                  </div>
                </td>
                <td className={`${!item.showBorder ? 'border-bottom' : ''}`}>
                  <div className="d-flex flex-grow">
                    {item.isLive ? (
                      <div className="badge badge-light-danger cursor-pointer p-3 fw-normal rounded-pill">Live</div>
                    ) : item.upcoming ? (
                      <div className="badge badge-light-primary cursor-pointer p-3 fw-normal rounded-pill">Upcoming</div>
                    ) : item.canceled ? (
                      <div className="badge badge-light cursor-pointer p-3 fw-normal rounded-pill">Cancelled</div>
                    ) : (
                      <div className="badge badge-light-success p-3 cursor-pointer fw-normal rounded-pill">Completed</div>
                    )}
                  </div>
                </td>
                <td className={`${!item.showBorder ? 'border-bottom' : ''} `}>
                  <div className="d-flex flex-row flex-nowrap align-items-center ">
                    {item.productImages &&
                      item.productImages
                        .slice(0, 10)
                        .map((productImage, ind) => (
                          <Img
                            key={ind}
                            className="h-30px w-30px rounded-circle overlappingImage"
                            alt={item.name}
                            src={productImage ? generateCompressedImageURL(setImageSrc(productImage), '50') : DefaultImage}
                          />
                        ))}
                  </div>
                </td>
              </tr>
            ))}
            {/* Sentinel div for observing scroll position */}
            <div ref={sentinelRef} style={{ height: '1px', visibility: 'hidden' }} />
            <tr
              className="no-hover-row"
              style={{
                opacity: loading ? 1 : 0
              }}
            >
              <td colSpan={5} className="w-100 text-center border-none align-middle">
                <Loader type="page" />
              </td>
            </tr>
          </table>
        </>
      )}
    </div>
  );
};

export default LiveShowListView;
