import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { debounce, uniq } from 'lodash';
import moment from 'moment';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_INVENTORY_HISTORY, GET_LIVE_STREAMS } from 'src/apollo/queries';
import { ADD_PRODUCT_TO_LIVE_SHOW } from 'src/apollo/mutations';

// Components
import { FlexBox, Img, Loader } from 'src/components/atoms';
import { EditColumn, FixedHeaderTable, DatesDropdownSelector, SearchBar } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { KTSVG } from 'src/helpers';
import getDeviceData from 'src/utils/UseDevice/useDevice';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// ConstVariables
import { constVariables } from 'src/constants';

// Icons
import { CloseIcon, FilterIcon, DefaultImage, CalendarIcon, EmptyDataIcon, NoProductIcon } from 'src/assets/icons';

// Styles
import './_inventoryHistoryModal.scss';
import { addYears, lastDayOfWeek, startOfWeek, subWeeks } from 'date-fns';

import { UpArrowIcon, DownArrowIcon } from 'src/assets/icons';
import { useToast } from 'src/utils/hooks/useToast';
import { EmptyList } from '../index';

interface InventoryHistoryModalProps {
  show: boolean;
  closeModal: () => void;
  selectedProductIDs: string[];
  singleProductId: undefined | string;
}

interface SortableTableHeaderProps {
  label: string;
  sortBy: string;
  type: string;
  sortHeader: () => void;
}

export const SortableTableHeader: FC<SortableTableHeaderProps> = ({ label, sortBy, type, sortHeader }) => {
  return (
    <button className="d-flex align-items-center bg-transparent border border-transparent" onClick={sortHeader}>
      <div>{label}</div>
      <KTSVG
        path={type === 'DESC' && sortBy === label ? UpArrowIcon : DownArrowIcon}
        className={`expand-arrow-icon ${label === sortBy ? 'active' : ''}`}
        svgClassName="cursor-pointer collapseFilter collapseIcon"
      />
    </button>
  );
};

const MoveLiveProductModal: FC<InventoryHistoryModalProps> = ({ show, closeModal, selectedProductIDs, singleProductId }) => {
  const { showToast } = useToast();

  const [addProductToShow, { loading: isAddingProduct }] = useMutation(ADD_PRODUCT_TO_LIVE_SHOW);

  async function addProductsToMultipleShowsSequentially(liveShowIds, selectedProductIDs) {
    try {
      for (const liveShowId of liveShowIds) {
        await addProductToShow({
          variables: {
            input: {
              liveShowId,
              productIds: singleProductId ? [singleProductId] : selectedProductIDs
            }
          }
        });
      }
      closeModal();
      const productNumber = singleProductId ? 1 : selectedProductIDs.length;
      showToast({
        successText: 'Products added successfully',
        message: `${productNumber} product${productNumber > 1 ? 's' : ''} were added to ${liveShowIds.length} liveshow${
          liveShowIds.length > 1 ? 's' : ''
        }`
      });
    } catch (error) {
      showToast({
        // @ts-ignore
        errorText: `${error && error.message}`,
        // @ts-ignore
        message: `Error occurred while adding products: ${error && error.message}`
      });
    }
  }

  const handleAdd = () => {
    addProductsToMultipleShowsSequentially(selectedLiveIds, selectedProductIDs);
  };
  const deviceData = getDeviceData();

  const processResponse = (item, index, items) => {
    const day = new Date(item.startingAt).toLocaleDateString('en-US', {
      weekday: 'long'
    });
    const date = new Date(item.startingAt).toLocaleDateString('en-US', {
      day: 'numeric'
    });
    const month = new Date(item.startingAt).toLocaleDateString('en-US', {
      month: 'short'
    });
    const year = new Date(item.startingAt).toLocaleDateString('en-US', {
      year: 'numeric'
    });
    const monthYear = new Date(item.startingAt).toLocaleDateString('en-US', {
      month: 'long',
      year: 'numeric'
    });
    const startTime = new Date(item.startingAt).toLocaleTimeString('en-US', { timeStyle: 'short' });
    const endTime = new Date(item.endingAt).toLocaleTimeString('en-US', { timeStyle: 'short' });
    const startDate = new Date(item.startingAt);
    const prevItemDate = new Date(items?.[index - 1]?.startingAt).toLocaleDateString('en-US', {
      month: 'long',
      year: 'numeric',
      day: 'numeric'
    });
    const nextItemDate = new Date(items?.[index + 1]?.startingAt).toLocaleDateString('en-US', {
      month: 'long',
      year: 'numeric',
      day: 'numeric'
    });
    const currentItemDate = new Date(item.startingAt).toLocaleDateString('en-US', {
      month: 'long',
      year: 'numeric',
      day: 'numeric'
    });

    return {
      id: item.id,
      name: item.title,
      thumbnailUrl: item.thumbnailUrl || '',
      timings: `${startTime} - ${endTime}`,
      upcoming: new Date(startDate) > new Date(),
      isLive: new Date() > new Date(startDate) && new Date() < new Date(item.endingAt),
      canceled: item.endingAt && new Date() > new Date(item.endingAt) && !item.startedAt && !item.endedAt && !item.isComplete,
      day,
      date: `${month} ${date}, ${year}`,
      monthYear,
      data: item
    };
  };

  const [eventList, setEventList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [getLiveStreams, { error }] = useLazyQuery(GET_LIVE_STREAMS, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      console.log('getLiveStreams data', data.getLiveStreams);
      if (data.getLiveStreams && data.getLiveStreams.length > 0) {
        const processed = data.getLiveStreams?.map((item, index) => processResponse(item, index, data.getLiveStreams));
        setEventList((prevResults) => [...prevResults, ...processed]);
      }
      setIsLoading(false);
    }
  });

  const [currentMonth, setCurrentMonth] = useState(new Date());

  useEffect(() => {
    const start = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const end = lastDayOfWeek(addYears(new Date(), 1), { weekStartsOn: 1 });
    getLiveStreams({
      variables: {
        input: {
          startTime: start,
          endTime: end
        }
      }
    });
  }, [currentMonth]);

  const [nextList, setNextList] = useState<any[]>([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const upcommings = eventList.filter((r) => r.upcoming).filter((r) => r.name.toLowerCase().includes(searchText.toLowerCase()));
    setNextList(upcommings);
  }, [eventList, searchText]);

  const [selectedLiveIds, setSelectedLiveIds] = useState<Array<number>>([]);

  const selectLive = useCallback(
    (e: any, live?: any) => {
      if (e.target.checked) {
        setSelectedLiveIds([...selectedLiveIds, live.id]);
      } else {
        setSelectedLiveIds(selectedLiveIds.filter((id) => id !== live.id));
      }
      setAllSelected(false);
    },
    [nextList, selectedLiveIds]
  );

  const handleSearch = (value) => {
    setSearchText(value);
  };
  const showEmpty = useMemo(() => {
    return searchText && nextList.length === 0 && !isLoading;
  }, [searchText, nextList, isLoading]);

  const history = useHistory();

  const [allSelected, setAllSelected] = useState<boolean>(false);

  useEffect(() => {
    if (allSelected) {
      const ids = nextList.map((l) => l.id);
      setSelectedLiveIds(ids);
    }
  }, [allSelected, nextList]);

  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');

  useEffect(() => {
    if (sortBy === 'Liveshow') {
      if (sortType === 'ASC') {
        const sortedItems = [...nextList].sort((a, b) => a.name.localeCompare(b.name));
        return setNextList(sortedItems);
      }
      if (sortType === 'DESC') {
        const sortedItems = [...nextList].sort((a, b) => b.name.localeCompare(a.name));
        return setNextList(sortedItems);
      }
    }

    if (sortBy === 'Date') {
      if (sortType === 'ASC') {
        // @ts-ignore
        const sortedItems = [...nextList].sort((a, b) => new Date(a.data.startingAt) - new Date(b.data.startingAt));
        return setNextList(sortedItems);
      }
      if (sortType === 'DESC') {
        // @ts-ignore
        const sortedItems = [...nextList].sort((a, b) => new Date(b.data.startingAt) - new Date(a.data.startingAt));
        return setNextList(sortedItems);
      }
    }

    if (sortBy === 'Time') {
      if (sortType === 'ASC') {
        // @ts-ignore
        const sortedItems = [...nextList].sort((a, b) => a.timings.localeCompare(b.timings));
        return setNextList(sortedItems);
      }
      if (sortType === 'DESC') {
        // @ts-ignore
        const sortedItems = [...nextList].sort((a, b) => b.timings.localeCompare(a.timings));
        return setNextList(sortedItems);
      }
    }
  }, [sortBy, sortType, nextList]);
  return (
    <div
      className={clsx('modal fade opacity-100', {
        show
      })}
      aria-modal="true"
      tabIndex={-1}
      id="kt_modal_1"
    >
      <div
        className={`${
          deviceData.isWeb ? 'modal-dialog modal-dialog-centered flex-center' : 'w-90 w-md-75'
        } inventory-history-modal`}
      >
        <div className={`modal-content ${deviceData.isWeb ? 'w-1068px' : ''}`}>
          <FlexBox className="modal-header justify-content-between">
            <h5 className="modal-title">Liveshow Selection</h5>
            <FlexBox className="d-flex align-items-center">
              <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={closeModal}>
                <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
              </div>
            </FlexBox>
          </FlexBox>
          {showEmpty ? (
            <EmptyList
              title=""
              description="No upcoming liveshows"
              addButtonText={constVariables.LiveShow.createLiveShow}
              icon={NoProductIcon}
              onAddButton={() => history.push('/liveShow/calendar?create=true')}
            />
          ) : (
            <div
              className="modal-body pane"
              style={{
                position: 'relative',
                minHeight: '196px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              {isLoading ? (
                <Loader type="page" className="h-100" />
              ) : (
                <>
                  <SearchBar placeholder="Search for liveshows" onChange={handleSearch} />
                  <table
                    className="table-row-black-300 align-middle gs-0 gy-4"
                    style={{
                      width: '100%',
                      marginTop: '20px'
                    }}
                  >
                    <thead>
                      <th
                        className="scrollbar-cell table-header-background px-2"
                        style={{
                          width: '24px'
                        }}
                      >
                        <FlexBox className="flex-row">
                          <div className="form-check form-check-sm form-check-custom">
                            <input
                              className="m-r-20 form-check-input widget-9-check"
                              type="checkbox"
                              checked={allSelected}
                              onChange={(e) => {
                                setAllSelected(!allSelected);
                              }}
                            />
                          </div>
                          <SortableTableHeader
                            label={'Liveshow'}
                            sortBy={sortBy}
                            type={sortType}
                            sortHeader={() => {
                              if (sortBy !== 'Liveshow') {
                                setSortType('ASC');
                              } else {
                                if (sortType === 'ASC') {
                                  return setSortType('DESC');
                                }
                                setSortType('ASC');
                              }
                              setSortBy('Liveshow');
                            }}
                          />
                        </FlexBox>
                      </th>
                      <th className="scrollbar-cell table-header-background px-2">
                        <SortableTableHeader
                          label={'Date'}
                          sortBy={sortBy}
                          type={sortType}
                          sortHeader={() => {
                            if (sortBy !== 'Date') {
                              setSortType('ASC');
                            } else {
                              if (sortType === 'ASC') {
                                return setSortType('DESC');
                              }
                              setSortType('ASC');
                            }
                            setSortBy('Date');
                          }}
                        />
                      </th>
                      <th className="scrollbar-cell table-header-background px-2">
                        <SortableTableHeader
                          label={'Time'}
                          sortBy={sortBy}
                          type={sortType}
                          sortHeader={() => {
                            if (sortBy !== 'Time') {
                              setSortType('ASC');
                            } else {
                              if (sortType === 'ASC') {
                                return setSortType('DESC');
                              }
                              setSortType('ASC');
                            }
                            setSortBy('Time');
                          }}
                        />
                      </th>
                    </thead>
                    {nextList?.map((item, index) => (
                      <tr
                        key={index}
                        className={`table-row ${index !== nextList.length - 1 ? 'border-bottom' : ''}`}
                        // onClick={() => handleSelectLiveshow(item.data)}
                      >
                        <td className="px-2">
                          <div className={`my-3 d-flex flex-grow  flex-row flex-nowrap align-items-center`}>
                            <div className="form-check form-check-sm form-check-custom">
                              <input
                                className="m-r-20 form-check-input widget-9-check"
                                type="checkbox"
                                checked={selectedLiveIds.includes(item.id)}
                                onChange={(e) => {
                                  selectLive(e, item);
                                }}
                              />
                            </div>
                            <Img className="h-40px w-40px rounded-2" alt={item.name} src={setImageSrc(item.thumbnailUrl)} />
                            <div className="ms-3">{item.name}</div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex flex-grow">
                            <div className="ms-2 monthYearText">{`${item.date} • ${item.day}`}</div>
                          </div>
                        </td>

                        <td>
                          <span className="timeText">{item.timings}</span>
                        </td>
                      </tr>
                    ))}
                  </table>
                </>
              )}
            </div>
          )}
          <div className="modal-footer">
            <button className="btn btn-outlined-secondary btn-sm" onClick={closeModal}>
              Cancel
            </button>
            {!showEmpty && (
              <button
                className="btn btn-primary btn-sm"
                disabled={selectedLiveIds.length === 0}
                onClick={handleAdd}
                style={{
                  pointerEvents: 'auto'
                }}
              >
                Add
                {isAddingProduct && <Loader type="button" className="h-15px w-15px" />}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoveLiveProductModal;
