import { FC } from 'react';

// Components
import { Img } from 'src/components/atoms';

// Icons
import {
  LiveViewerIcon,
  LiveViewerTimeIcon,
  LiveCTRIcon,
  LiveCartIcon,
  LiveBuyersIcon,
  LiveLikesIcon,
  LiveSalesIcon,
  LiveProfitIcon,
  LivePaidOrdersIcon,
  LiveUnpaidOrdersIcon
} from 'src/assets/icons';

// Types
import { numberWithCommas } from 'src/utils/numberWithCommas';

export interface AnalyticsCardProps {
  type: string;
  value: number | string | undefined;
  text: string;
  hasSign?: boolean;
}

const AnalyticsCardData: FC<AnalyticsCardProps> = ({ value, text, hasSign }) => {
  const renderPercentClassName = (percent: number | undefined) => {
    if (percent === undefined || percent === null) return '';
    if (percent > 0) {
      return 'positive-value';
    } else if (percent < 0) {
      return 'negative-value';
    } else {
      return '';
    }
  };

  const renderPercent = (percent: number | undefined) => {
    if (percent === undefined || percent === null) return 'N/A';
    if (percent > 0) {
      return `+${percent}%`;
    } else {
      return `${percent}%`;
    }
  };

  return (
    <div>
      <h2 className="poppins-semibold m-b-8">{typeof value === 'string' ? value : numberWithCommas(value, hasSign)}</h2>
      <p className="mb-0">
        {text}
        {/*{' '}*/}
        {/*<span className={`poppins-semibold ${renderPercentClassName(comparedPercent)}`}>{renderPercent(comparedPercent)}</span>*/}
      </p>
    </div>
  );
};

const AnalyticsCardForLive: FC<AnalyticsCardProps> = (props) => {
  const { type } = props;
  const renderIcon = (type: string) => {
    switch (type) {
      case 'viewer':
        return LiveViewerIcon;
      case 'viewerTime':
        return LiveViewerTimeIcon;
      case 'ctr':
        return LiveCTRIcon;
      case 'cart':
        return LiveCartIcon;
      case 'sales':
        return LiveSalesIcon;
      case 'profit':
        return LiveProfitIcon;
      case 'paidOrders':
        return LivePaidOrdersIcon;
      case 'unpaidOrders':
        return LiveUnpaidOrdersIcon;
      case 'buyers':
        return LiveBuyersIcon;
      case 'likes':
        return LiveLikesIcon;
      default:
        return LiveBuyersIcon;
    }
  };

  return (
    <div className="card home-analytics-card">
      <Img src={renderIcon(type)} width={32} height={32} className="m-b-8" />
      <AnalyticsCardData {...props} />
    </div>
  );
};

export default AnalyticsCardForLive;
