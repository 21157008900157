import { gql } from '@apollo/client';

export const GET_INVENTORY_HISTORY = gql`
  query OngetInventoryHistoryQuery($input: InventoryHistoryInput) @api(name: "productsAPI") {
    getInventoryHistory(input: $input) {
      totalRecords
      logs {
        id
        media
        productName
        productId
        variantValue
        locationName
        locationIdProduct
        locationIdVariant
        adjustment
        adjustmentType
        ownerName
        ownerId
        adjustmentCreatedOn
        productQtyBeforeAdjustment
        productQtyAfterAdjustment
        variantQtyBeforeAdjustment
        variantQtyAfterAdjustment
        locationNameBeforeAdjustment
        locationNameAfterAdjustment
      }
    }
  }
`;
