import { gql } from '@apollo/client';

export const CONFIGURE_SHIPSTATION = gql`
  mutation ConfigureShipStation($input: ShipstationConfigInput!) @api(name: "ordersAPI") {
    configureShipStation(input: $input) {
      __typename
      id
      defaultProvider
      isShippoConnectd
      isShipStationConnectd
    }
  }
`;
