import React, { createRef, memo, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import OutsideClickHandler from 'react-outside-click-handler';
import { areEqual } from 'react-window';

// Components
import { Img } from 'src/components/atoms';
import { Portal, Dropdown, Tooltip, FirstTimeCustomerTooltip, VipCustomerTooltip } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';
import useWindowDimensions from 'src/utils/UseWindowDimensions/useWindowDimensions';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';
// import CommentCircleBar from './CommentCircleBar';

// Icons
import {
  DefaultImage,
  CartIcon,
  CommentDollar,
  ThreeDotsIcon,
  PinIcon,
  FacebookConnectedIcon,
  CrownIcon,
  FirstTime,
  PinnedIcon
  // ReplyIcon,
} from 'src/assets/icons';

// ConstVariables
import { ROUTES } from 'src/constants';

const getLiveShowCommentDropdownData = (isBlocked) => {
  return [
    {
      id: 1,
      name: 'Delete comment'
    },
    {
      id: 2,
      name: isBlocked ? 'Unblock user' : 'Block user'
    },
    {
      id: 3,
      name: 'Add store credit'
    },
    {
      id: 4,
      name: 'Dump cart'
    }
  ];
};

const LiveShowComment: React.FC<any> = memo(({ data, index, style }) => {
  const {
    comments,
    userIdToDetails,
    onBlockUser,
    onDeleteComment,
    onAddStoreCredit,
    onShowCart,
    onDumpCart,
    onPinComment,
    setRowHeight,
    containerWidth
  } = data;
  const comment = comments[index];
  const { width } = useWindowDimensions();

  const menuIcon = createRef<any>();
  const rowRef = useRef<HTMLDivElement>(null);

  const details = userIdToDetails.get(Number(comment.userId));

  const timeNow = moment(new Date());
  const minutes = moment(timeNow).diff(moment(comment.sendTime), 'minutes');

  const [visible, setVisible] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [menuPositions, setMenuPositions] = useState({
    top: 0,
    left: 0
  });

  useEffect(() => {
    if (rowRef.current) {
      setRowHeight(index, rowRef.current.clientHeight);
    }
    // eslint-disable-next-line
  }, [rowRef, comment, containerWidth]);

  const closeDropDown = () => setShowDropDown(false);
  const openDropDown = (e) => {
    e.stopPropagation();
    setShowDropDown(true);

    if (menuIcon.current) {
      const position = menuIcon.current.getBoundingClientRect();
      const { top, left } = position;
      const spaceBelow = window.innerHeight - position.bottom;
      setMenuPositions({
        top: spaceBelow < 190 ? window.scrollY + top - 184 : window.scrollY + top + 40,
        left
      });
    }
  };

  const selectingMenuItem = (option) => {
    if (option.id === 1) {
      onDeleteComment(comment.id);
    }
    if (option.id === 2) {
      onBlockUser(details);
    }
    if (option.id === 3) {
      onAddStoreCredit(details);
    }
    if (option.id === 4) {
      if (details?.noOfCartItems > 0) {
        onDumpCart(details);
      }
    }
    closeDropDown();
  };

  // const renderReply = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     Reply
  //   </Tooltip>
  // );

  const onShowCartDetails = () => {
    onShowCart(details);
  };

  const handlePinComment = () => {
    onPinComment(comment);
  };

  return (
    <>
      <div style={style}>
        <div
          ref={rowRef}
          className="comment-item"
          onMouseEnter={() => setVisible(true)}
          onMouseLeave={() => setVisible(false)}
          id={index === 0 ? 'liveshow-latest-comment' : ''}
        >
          <div className={`block-overlay position-absolute ${details && details.isLiveBlocked ? '' : 'hidden'}`}></div>
          <div className="comment-item-image-container">
            {comment.facebookId && (
              <img className="facebook-connected-status position-absolute" src={FacebookConnectedIcon} alt="" />
            )}
            <Img
              src={setImageSrc(comment?.userImage ? generateCompressedImageURL(comment?.userImage, '50') : DefaultImage)}
              placeholderImg={DefaultImage}
              errorImg={DefaultImage}
              className="object-fit-cover bg-white border border-light border-2 rounded-circle"
            />
            {/* {details && <CommentCircleBar percentage={details.cartProgress} />} */}
          </div>
          <div className="name-content-container">
            <div className="name-line m-b-4">
              <Link
                to={`${ROUTES.customers.allCustomers.customerDetails}/${comment.userId}`}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                <span className="name">{comment.userName || (details ? details.firstName : '')}</span>
              </Link>
              {details && details.amountSpent > 0 && <KTSVG path={CommentDollar} className="dollar-icon" />}
              {details && details.amountSpent > 0 && <span className="dollar-number">{details.amountSpent}</span>}
              {details && details.isVIPCustomer && (
                <>
                  <img src={CrownIcon} className="crown-icon" data-tip data-for="vipCustomerIcon" />
                  <VipCustomerTooltip />
                </>
              )}
              {details && details.isFirstTimeCustomer && (
                <>
                  <img src={FirstTime} className="first-time-icon" data-tip data-for="firstTimeCustomerIcon" />
                  <FirstTimeCustomerTooltip />
                </>
              )}
              {details?.noOfCartItems > 0 && (
                <>
                  <div
                    className="cart-icon-container tooltips-container cursor-pointer"
                    onClick={onShowCartDetails}
                    data-tip
                    data-for="cartIndicator"
                  >
                    <KTSVG path={CartIcon} className="cart-icon" />
                    <span className="cart-number">{details && details.noOfCartItems}</span>
                  </div>
                  <Tooltip
                    tooltipId="cartIndicator"
                    place="top"
                    content={() => {
                      return <>Cart</>;
                    }}
                  />
                </>
              )}
              <span className="time">{minutes} mn</span>
            </div>
            <div className="content">
              {comment.comment}
              {/* {visible && (
              <OverlayTrigger placement="top" overlay={renderReply}>
                <span className="cart-icon-container">
                  <KTSVG path={ReplyIcon} className="reply-icon" />
                </span>
              </OverlayTrigger>
            )} */}
            </div>
          </div>
          <div className="live-show-comment-item-more position-absolute d-flex">
            <span
              className={`pin-icon tooltips-container position ${comment.isPinned ? 'pinned' : ''} ${
                visible || comment.isPinned ? '' : 'opacity-0'
              }`}
              data-tip
              data-for={comment.isPinned ? 'unpinIndicator' : 'pinIndicator'}
            >
              <img src={comment.isPinned ? PinnedIcon : PinIcon} alt="PinIcon" onClick={handlePinComment} />
            </span>
            {comment.isPinned && (
              <Tooltip
                tooltipId="unpinIndicator"
                place="top"
                content={() => {
                  return <>Unpin Comments</>;
                }}
              />
            )}
            {!comment.isPinned && (
              <Tooltip
                tooltipId="pinIndicator"
                place="top"
                content={() => {
                  return <>Pin Comments</>;
                }}
              />
            )}
            <span ref={menuIcon}>
              <KTSVG onClick={openDropDown} className={`cursor-pointer ${visible ? '' : 'opacity-0'}`} path={ThreeDotsIcon} />
            </span>
          </div>
        </div>
      </div>
      {showDropDown && (
        <Portal id="kt_body">
          <OutsideClickHandler onOutsideClick={closeDropDown}>
            <div
              style={{ top: menuPositions.top, left: menuPositions.left, zIndex: 10000 }}
              className="position-absolute comment-action-drop-down"
            >
              <Dropdown
                data={getLiveShowCommentDropdownData(Boolean(details && details.isLiveBlocked))}
                onSelect={selectingMenuItem}
                selected={showDropDown}
                className={` ${comments?.length - index <= 1 ? 'up-direction' : ''}`}
                closeDropdown={() => setShowDropDown(false)}
                shiftLeft
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </>
  );
}, areEqual);

export default LiveShowComment;
