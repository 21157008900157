import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_WAREHOUSE } from 'src/apollo/queries';

// Components
import WarehouseManagementPresentational from 'src/components/pages/settings/WarehouseManagement/WarehouseManagement.presentational';

// Hooks && Utils && Helpers
import useTitle from 'src/utils/hooks/useTitle';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { WarehousesProps } from './WarehouseManagement.type';

const WarehouseManagement: FC = () => {
  useTitle('Settings - Warehouses');
  const location = useLocation();
  const [warehouseList, setWarehouseList] = useState<WarehousesProps[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [editWarehouseData, setEditWarehouseData] = useState<WarehousesProps | null>(null);
  const [addWarehouseModal, setAddWarehouseModal] = useState(false);
  const [sortBy, setSortBy] = useState({
    catagory: 'Default',
    id: 1,
    type: 'DESC'
  });

  useEffect(() => {
    const query = queryString.parse(location.search, { parseBooleans: true });
    if (query.isAddModal) {
      setAddWarehouseModal(true);
    }
  }, [location]);

  const [warehousePageInfo, setWarehousePageInfo] = useState({
    skipCount: 0,
    limitCount: 50
  });
  const [selectedTab, setSelectedTab] = useState(constVariables.Tabs.allWarehouses.toUpperCase());

  const input: {
    sortBy?: string;
    sortType?: string;
    status: string;
    pageInfo: {
      skipCount: number;
      limitCount: number;
    };
  } = {
    pageInfo: warehousePageInfo,
    status: selectedTab === 'ALL WAREHOUSES' ? 'ALL' : selectedTab
  };

  if (sortBy.catagory !== 'Default') {
    input.sortBy = sortBy.catagory;
    input.sortType = sortBy.type;
  }

  const {
    loading: isLoading,
    refetch: refetchWarehouseList,
    data
  } = useQuery(GET_WAREHOUSE, {
    variables: {
      input
    },
    onCompleted: (data) => {
      if (data) {
        setWarehouseList(data.getWarehouse.warehouses);
        setTotalCount(data.getWarehouse.totalWarehouses);
      }
    },
    onError: (error) => {
      console.error('err', error);
    }
  });
  const addWarehouse = () => {
    setAddWarehouseModal(!addWarehouseModal);
    refetchWarehouseList();
  };
  const setWarehouseData = (warehouseInput: WarehousesProps) => {
    setEditWarehouseData(warehouseInput);
    setAddWarehouseModal(true);
  };
  useEffect(() => {
    refetchWarehouseList();
  }, [selectedTab]);

  return (
    <WarehouseManagementPresentational
      addWarehouse={addWarehouse}
      addWarehouseModal={addWarehouseModal}
      warehouseListData={warehouseList}
      sortBy={sortBy}
      setSortBy={setSortBy}
      warehousePageInfo={warehousePageInfo}
      setWarehousePageInfo={setWarehousePageInfo}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      refresh={refetchWarehouseList}
      setWarehouseData={setWarehouseData}
      editWarehouseData={editWarehouseData}
      isLoading={isLoading && !data?.getWarehouse}
      totalCount={totalCount}
    />
  );
};

export default WarehouseManagement;
