import { FC, useEffect, useState } from 'react';
import { lastDayOfWeek, startOfWeek } from 'date-fns';

// Apollo
import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_FACEBOOK_CHANNELS, GET_LIVE_STREAM_DETAILS, GET_LIVE_STREAMS } from 'src/apollo/queries';
import { DELETE_LIVE_STREAM } from 'src/apollo/mutations';

// Components
import LiveShowCalendarPresentational from './LiveShowCalendar.presentational';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';
import useTitle from 'src/utils/hooks/useTitle';

// Types
import { LiveShowCalendarEvents } from './LiveShowCalendar.types';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';

export interface LiveShowCalendarProps {
  showEventDetail: (eventObj: LiveShowCalendarEvents) => void;
}

const LiveShowCalendar: FC = () => {
  useTitle('All Liveshows');
  const { showToast } = useToast();
  const { cache } = useApolloClient();
  // State Params
  const [events, setEvents] = useState<LiveShowCalendarEvents[]>([]);
  const [eventData, setEventData] = useState<any>();
  const [showLiveShowModal, setShowLiveShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [startMonth, setStartMonth] = useState<Date>();
  const [endMonth, setEndMonth] = useState<Date>();

  useEffect(() => {
    setStartMonth(startOfWeek(currentMonth, { weekStartsOn: 0 }));
    setEndMonth(lastDayOfWeek(currentMonth, { weekStartsOn: 0 }));
    refetchLiveStreams();
  }, [currentMonth]);

  useQuery(GET_FACEBOOK_CHANNELS);

  const { refetch: refetchLiveStreams } = useQuery(GET_LIVE_STREAMS, {
    variables: {
      input: {
        startTime: startMonth,
        endTime: endMonth
      }
    },
    onCompleted: (data) => {
      setEvents(data.getLiveStreams);
    },
    onError: (error) => {
      console.error('Get Live Stream Error', error);
    },
    fetchPolicy: 'cache-and-network'
  });

  const [deleteLiveStream] = useMutation(DELETE_LIVE_STREAM, {
    onCompleted: () => {
      refetchLiveStreams();
      showToast({
        title: 'Live Stream ',
        successText: 'Deleted Successfully',
        message: 'Live stream has been successfully deleted'
      });
    },
    onError: (error) => {
      showToast({ errorText: error.message, message: `Error orccured while deleting the live stream: ${error.message}` });
    }
  });

  const [getEventDetails] = useLazyQuery(GET_LIVE_STREAM_DETAILS);

  const deleteEvent = (eventId: string): void => {
    setShowDeleteModal(false);
    const updatedEventsList = events.filter((event) => event.id !== eventId);
    setEvents(updatedEventsList);
    deleteLiveStream({ variables: { id: eventId } });
  };

  const editEvent = async (eventId: string, isComplete: boolean): Promise<void> => {
    const variables = {
      id: eventId
    };

    const cached: any = cache.readQuery({
      query: GET_LIVE_STREAM_DETAILS,
      variables
    });

    let eventData = cached?.getLiveStreamDetails;

    if (!eventData) {
      const res = await getEventDetails({
        variables
      });

      if (res?.data?.getLiveStreamDetails) {
        eventData = res?.data?.getLiveStreamDetails;
      } else {
        console.log('Event not found');
      }
    }
    if (eventData) {
      setEventData({
        ...eventData,
        isComplete
      });
      setShowLiveShowModal(true);
    }
  };

  const history = useHistory();
  const location = useLocation();
  const queryString = location.search;
  useEffect(() => {
    if (queryString) {
      const searchParams = Object.fromEntries(new URLSearchParams(queryString).entries());
      if (searchParams.create === 'true') {
        setTimeout(() => {
          setShowLiveShowModal(true);
        }, 500);
      }
    }
  }, [queryString]);

  useEffect(() => {
    if (!showLiveShowModal) {
      history.replace('/liveShow/calendar');
    }
  }, [showLiveShowModal]);
  return (
    <LiveShowCalendarPresentational
      currentMonth={currentMonth}
      setCurrentMonth={setCurrentMonth}
      showLiveShowModal={showLiveShowModal}
      setShowLiveShowModal={setShowLiveShowModal}
      updateData={refetchLiveStreams}
      editEventData={eventData}
      deleteEvent={deleteEvent}
      editEvent={editEvent}
      eventList={events}
      showDeleteModal={showDeleteModal}
      setShowDeleteModal={setShowDeleteModal}
    />
  );
};

export default LiveShowCalendar;
