import { FC, useState, createRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { Dropdown, Portal } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers/components/KTSVG';

// Icons
import { ThreeDotsIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

interface ActionMenuProps {
  id: number;
  isBlocked: boolean;
  onDeleteCustomer: (id: number) => void;
  onBlockCustomer: (action: string, id: number) => void;
}

const PARENT_ID = 'dataTableContent';
const ActionMenu: FC<ActionMenuProps> = ({ id, isBlocked, onDeleteCustomer, onBlockCustomer }) => {
  const menuBtnRef = createRef<any>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0
  });

  const handleShowDropdown = () => {
    setShowDropdown(true);

    const parentElement = document.getElementById(PARENT_ID);
    if (menuBtnRef.current && parentElement) {
      const position = menuBtnRef.current.getBoundingClientRect();
      const parentRect = parentElement.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropdownPositions({
        // top: spaceBelow < 250 ? window.scrollY + position.top - 225 : window.scrollY + position.top + 30,
        // left: position.left + 70
        top: position.top - parentRect.top,
        left: position.left - parentRect.left + 90
      });
    }
  };

  return (
    <>
      <button onClick={handleShowDropdown} className="bg-transparent border border-transparent" ref={menuBtnRef}>
        <KTSVG path={ThreeDotsIcon} svgClassName="cursor-pointer collapseFilter collapseIcon" />
      </button>
      {showDropdown && (
        <Portal id={PARENT_ID}>
          <OutsideClickHandler
            onOutsideClick={() => {
              if (showDropdown) setShowDropdown(false);
            }}
          >
            <div className="position-absolute" style={{ top: dropdownPositions.top, left: dropdownPositions.left }}>
              <Dropdown
                data={isBlocked ? constVariables.customerOptionBlockDropdownData : constVariables.customerOptionActiveDropdownData}
                selected={showDropdown}
                onSelect={(data) => {
                  if (data.name === 'Delete customer') {
                    onDeleteCustomer(id);
                  } else if (data.name === 'Block customer') {
                    onBlockCustomer('BLOCK', id);
                  } else if (data.name === 'Unblock customer') {
                    onBlockCustomer('UNBLOCK', id);
                  }
                }}
                shiftLeft
                shiftLeftValue="-250px"
                closeDropdown={() => setShowDropdown(false)}
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </>
  );
};

export default ActionMenu;
