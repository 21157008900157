import { FC, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useLazyQuery } from '@apollo/client';
import { CALCULATE_SHIPPING_CHARGES } from 'src/apollo/queries';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { DropdownSelector, RadioButton } from 'src/components/atoms';
import { Dropdown, Checkbox, InputBox, Divider, Tooltip } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { checkInvalidCharacter } from 'src/utils/validate';

// Icons
import { InfoIcon, InfoItalicIcon } from 'src/assets/icons';

// Styles
import './_shippingSettingsShippingMode.scss';

const ShippingSettingsShippingMode = ({ shippingSettings, setShippingSettings, errorMessages, setErrorMessages }) => {
  const [autoShippingRateToggle, setAutoShippingRateToggle] = useState(false);
  const [variableShippingRateToggle, setVariableShippingRateToggle] = useState(false);

  useEffect(() => {
    if (shippingSettings.variableRate && !variableShippingRateToggle) {
      setVariableShippingRateToggle(true);
    }

    if (shippingSettings.maximumRate && !autoShippingRateToggle) {
      setAutoShippingRateToggle(true);
    }
  }, [shippingSettings]);
  return (
    <div className="card w-100">
      <h4 className="section-title m-b-24">Free Shipping Coupons</h4>
      <div className="row">
        <h6 className="section-subtitle m-b-24">Select Shipping Mode</h6>

        <div className="d-flex align-items-center w-100">
          <RadioButton
            id="flatShippingRate"
            name="shippingMode"
            isChecked={shippingSettings.mode === 'FLAT_SHIPPING_RATE'}
            handleChange={(e) => {
              if (e.target.checked) {
                setShippingSettings({
                  ...shippingSettings,
                  mode: 'FLAT_SHIPPING_RATE'
                });
              }
            }}
            noMargin
            className={'radio-normal'}
          />
          <label className="mb-0 ms-2 opacity-80 thicccboi-bold">Flat Shipping Rate</label>
        </div>

        <div className="d-flex align-items-center ml-3 w-100">
          <p className="ms-5 opacity-50 mb-0">
            A fixed shipping rate will be applied to all orders, regardless of the order size or destination.
          </p>
        </div>

        <Divider classname="divider-status m-y-24" />

        <div className="d-flex align-items-center w-100">
          <RadioButton
            id="automaticShippingRate"
            name="shippingMode"
            isChecked={shippingSettings.mode === 'AUTOMATIC_SHIPPING_RATE'}
            handleChange={(e) => {
              if (e.target.checked) {
                setShippingSettings({
                  ...shippingSettings,
                  mode: 'AUTOMATIC_SHIPPING_RATE'
                });
              }
            }}
            noMargin
            className={'radio-normal'}
          />
          <label className="mb-0 ms-2 opacity-80 thicccboi-bold">Automatic Shipping Rate</label>
        </div>

        <div className="d-flex align-items-center ml-3 w-100">
          <p className="ms-5 opacity-50 mb-0">
            Real-time shipping rates calculated at checkout based on the order’s size and destination.
          </p>
        </div>
      </div>
      <div className="row">
        <h6 className="section-subtitle m-y-24">Rates</h6>
        {shippingSettings.mode === 'FLAT_SHIPPING_RATE' && (
          <div className="col-12">
            <div className="row gx-3">
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                style={{
                  paddingTop: '4px'
                }}
              >
                <InputBox
                  onKeyDown={checkInvalidCharacter}
                  dollarSign={true}
                  inputClass="form-control-lg"
                  mainclass="m-b-24 sale-price-title"
                  title={'Flat Rate'}
                  imageRight={
                    <div className="ms-1 d-flex justify-content-between align-item-center">
                      <div className="text-danger">*</div>
                    </div>
                  }
                  value={isNaN(shippingSettings.flatRate) ? '' : shippingSettings.flatRate || ''}
                  onChangeText={(e) => {
                    if (!isNaN(e)) {
                      setShippingSettings({
                        ...shippingSettings,
                        flatRate: e
                      });
                      setErrorMessages({
                        ...errorMessages,
                        flatRate: ''
                      });
                    }
                  }}
                  name={'flatRateNumber'}
                  warningText={errorMessages.flatRate}
                  // placeholder={constVariables.common.pricing.pricePlaceholder}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <InputBox
                  onKeyDown={checkInvalidCharacter}
                  dollarSign={true}
                  disable={!variableShippingRateToggle}
                  inputClass={`form-control-lg ${variableShippingRateToggle ? '' : 'disabled-input'}`}
                  mainclass="m-b-24 sale-price-title"
                  title={'Variable Shipping Rate'}
                  imageRight={
                    <div className="ms-1 d-flex justify-content-between align-item-center">
                      <div data-tip data-for="variableShippingInfo">
                        <KTSVG className="ms-1" path={InfoItalicIcon} data-tip data-for="variableShippingInfo" />
                      </div>

                      <label className="form-check form-check-custom form-check-solid form-switch form-switch-sm toggle-absolute">
                        <input
                          style={{ outline: 'none' }}
                          className="form-check-input"
                          type="checkbox"
                          name="layout-builder[layout][header][fixed][desktop]"
                          checked={variableShippingRateToggle}
                          onChange={() => {
                            setVariableShippingRateToggle(!variableShippingRateToggle);
                          }}
                        />
                      </label>
                      <Tooltip
                        tooltipId="variableShippingInfo"
                        className="toolTipShipMode"
                        place="right"
                        content={() => {
                          return (
                            <div>
                              <div className="toolTipShipModeLine">Apply different costs for additional items in an order.</div>
                              <div className="toolTipShipModeLine">Example: $4 for first item, $1 for each extra item.</div>
                              <div className="toolTipShipModeLine">3-item order: $4 + $1 + $1 = $6</div>
                            </div>
                          );
                        }}
                      />
                    </div>
                  }
                  value={isNaN(shippingSettings.variableRate) ? '' : shippingSettings.variableRate || ''}
                  onChangeText={(e) => {
                    if (!isNaN(e)) {
                      setShippingSettings({
                        ...shippingSettings,
                        variableRate: e
                      });
                    }
                  }}
                  name={'variableRate'}
                  // placeholder={constVariables.common.pricing.pricePlaceholder}
                  // warningText={!retailPriceToggle ? '' : errorMessages?.retailPrice}
                />
              </div>
            </div>
          </div>
        )}
        {shippingSettings.mode === 'AUTOMATIC_SHIPPING_RATE' && (
          <div className="col-12">
            <div className="row gx-3">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <InputBox
                  onKeyDown={checkInvalidCharacter}
                  dollarSign={true}
                  disable={!autoShippingRateToggle}
                  inputClass={`form-control-lg ${autoShippingRateToggle ? '' : 'disabled-input'}`}
                  mainclass="m-b-24 sale-price-title"
                  title={'Maximum Shipping Rate'}
                  imageRight={
                    <div className="ms-1 d-flex justify-content-between align-item-center">
                      <KTSVG
                        className="ms-1"
                        path={InfoItalicIcon}
                        // data-tip data-for="variableShippingInfo"
                      />

                      <label className="form-check form-check-custom form-check-solid form-switch form-switch-sm toggle-absolute">
                        <input
                          style={{ outline: 'none' }}
                          className="form-check-input"
                          type="checkbox"
                          name="layout-builder[layout][header][fixed][desktop]"
                          checked={autoShippingRateToggle}
                          onChange={() => {
                            setAutoShippingRateToggle(!autoShippingRateToggle);
                          }}
                        />
                      </label>
                      {/*<Tooltip*/}
                      {/*    tooltipId="variableShippingInfo"*/}
                      {/*    className="toolTipShipMode"*/}
                      {/*    place="right"*/}
                      {/*    content={() => {*/}
                      {/*      return (*/}
                      {/*          <div>*/}
                      {/*            <div className="toolTipShipModeLine">Apply different costs for additional items in an order.</div>*/}
                      {/*            <div className="toolTipShipModeLine">Example: $4 for first item, $1 for each extra item.</div>*/}
                      {/*            <div className="toolTipShipModeLine">3-item order: $4 + $1 + $1 = $6</div>*/}
                      {/*          </div>*/}
                      {/*      );*/}
                      {/*    }}*/}
                      {/*/>*/}
                    </div>
                  }
                  value={isNaN(shippingSettings.maximumRate) ? '' : shippingSettings.maximumRate || ''}
                  onChangeText={(e) => {
                    if (!isNaN(e)) {
                      setShippingSettings({
                        ...shippingSettings,
                        maximumRate: e
                      });
                      setErrorMessages({
                        ...errorMessages,
                        maximumRate: ''
                      });
                    }
                  }}
                  name={'variableRate'}
                  warningText={errorMessages.maximumRate}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShippingSettingsShippingMode;
