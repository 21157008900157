import { FC, useMemo, useState, createRef, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_TIKTOK_CATEGORIES } from 'src/apollo/queries';

// Components
import { DropdownSelector } from 'src/components/atoms';
import { Dropdown, Portal } from 'src/components/molecules';

// Types
import { TikTokProduct, TIKTOK_PRODUCT_STATUS, TiktokCategoryOptionItem } from '../TiktokIntegration.types';
import CategoryDropdown from './CategoryDropdown';

interface CategoryCellProps {
  data: TikTokProduct;
  categories: any[];
  allCategorySearchOptions: any[];
  isLoading: boolean;
  updateCategory: (productId: number, categoryId: string) => void;
}

const getDisplayLabel = (c) => {
  if (c.l7) {
    return `${c?.l6} - ${c?.l7}`;
  }
  if (c.l6) {
    return `${c?.l5} - ${c?.l6}`;
  }
  if (c.l5) {
    return `${c?.l4} - ${c?.l5}`;
  }
  if (c.l4) {
    return `${c?.l3} - ${c?.l4}`;
  }
  return `${c?.l2} - ${c?.l3}`;
};
const CategoryCell: FC<CategoryCellProps> = ({ data, updateCategory, categories, allCategorySearchOptions, isLoading }) => {
  const dropdownRef = createRef<any>();
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0
  });

  const [filteredCategories, setFilteredCategories] = useState<TiktokCategoryOptionItem[]>([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    if (!data?.category) {
      setSelectedCategory('');
    } else {
      // setSelectedCategory(`${data?.category?.l1} - ${data?.category?.l2} - ${data?.category?.l3}`);
      setSelectedCategory(getDisplayLabel(data?.category));
    }
  }, [data?.category]);

  // useEffect(() => {
  //   if (searchText !== '') {
  //     const newCategories = categories?.filter((category) => category?.name?.toLowerCase()?.includes(searchText.toLowerCase()));
  //     setFilteredCategories(newCategories);
  //   } else {
  //     setFilteredCategories(categories);
  //   }
  // }, [searchText, categories]);

  const closeDropdown = () => {
    setShowDropdown(false);
    setShowSearchInput(false);
  };

  const disabledDropdown = useMemo(() => {
    if (data.status === TIKTOK_PRODUCT_STATUS.PROCESSING) return true;
    return false;
  }, [data.status]);

  const handleShowDropdown = () => {
    setShowSearchInput(true);
    setShowDropdown(true);

    if (dropdownRef.current) {
      const position = dropdownRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropdownPositions({
        top: spaceBelow < 190 ? window.scrollY + position.top - 316 + 4 : window.scrollY + position.top + position.height + 4,
        left: position.left + position.width
      });
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showDropdown]);

  const handleChangeCategory = (optionId, fullOptionName) => {
    closeDropdown();
    setSearchText('');
    setSelectedCategory(fullOptionName);
    updateCategory(data?.id, optionId);
  };

  return (
    <>
      <div className="tiktok-category">
        {data.status === TIKTOK_PRODUCT_STATUS.PROCESSING ||
        data.status === TIKTOK_PRODUCT_STATUS.UPDATES_REQUIRED ||
        data.status === TIKTOK_PRODUCT_STATUS.DRAFT ? (
          <div
            className={`position-relative ${disabledDropdown ? 'disable-background' : ''} ${
              showDropdown ? 'dropdown-box-active' : ''
            } ${!selectedCategory && !disabledDropdown ? 'dropdown-box-error' : ''}`}
          >
            <div className="d-flex position-relative input-group px-3" ref={dropdownRef} onClick={handleShowDropdown}>
              <DropdownSelector selectedValue={selectedCategory} text="Please select a category" />
            </div>
          </div>
        ) : (
          <div>{selectedCategory}</div>
        )}
      </div>
      {showDropdown && (
        <Portal id="kt_body">
          <OutsideClickHandler onOutsideClick={closeDropdown}>
            <div
              className="position-absolute"
              style={{
                top: dropdownPositions.top,
                left: dropdownPositions.left - 720,
                width: 720,
                zIndex: 999,
                boxShadow: '0 10px 30px rgba(19, 16, 57, 0.2)',
                borderRadius: '7px',
                overflow: 'hidden'
              }}
            >
              <CategoryDropdown
                currentValue={data?.category}
                allCategoryList={categories}
                handleChangeCategory={handleChangeCategory}
                isLoading={isLoading}
                allCategorySearchOptions={allCategorySearchOptions}
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </>
  );
};

export default CategoryCell;
