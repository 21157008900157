import { FC, useEffect, useState, useReducer } from 'react';
import { useHistory } from 'react-router';

// Apollo
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_RAPID_LIST, GET_FEATURED_PRODUCT_CONDITIONS, GET_FEATURED_PRODUCT_RULE } from 'src/apollo/queries';
import { CREATE_FEATURED_PRODUCT_RULES, DELETE_FROM_RAPID_ACTION_LIST, MANAGE_RAPID_ACTION_CONFIG } from 'src/apollo/mutations';

// Components
import { CustomModal, DiscardModal, SaveHeader } from 'src/components/oraganisms';
import FeaturedProductsPresentational from './FeaturedProducts.presentational';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';
import useTitle from 'src/utils/hooks/useTitle';

// ConstVariables
import { ROUTES, envUrls, constVariables } from 'src/constants';

// Types
import {
  CatalogProductListType,
  FeaturedProductsStatusType,
  CatalogTypeType,
  productAssignmentMethod
} from './FeaturedProducts.types';
import { ConditionType, FieldConditionType } from './FeaturedProducts.types';

const FeaturedProducts: FC = () => {
  useTitle('Featured products');
  const history = useHistory();
  const { showToast } = useToast();
  const [featuredProductsStatus, setFeaturedProductsStatus] = useState<FeaturedProductsStatusType>({ value: undefined });
  const [featuredProductsType, setFeaturedProductsType] = useState<CatalogTypeType>({ value: undefined });
  const [isDiscardModal, setIsDiscardModal] = useState<boolean>(false);
  const [isLeaveModal, setIsLeaveModal] = useState<boolean>(false);
  const [isDataChanged, setDataChanged] = useState<boolean>(false);
  const [targettedPath, setTargettedPath] = useState<string>();
  const [formChanges, setFormChanges] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showBar] = useState<boolean>(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [rulesChanges, setRulesChanges] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

  const [productListData, setProductListData] = useState<CatalogProductListType>({
    autoMaticProductListIds: [],
    manualProductListIds: [],
    selectedProductsIds: []
  });
  const [searchText, setSearchText] = useState('');
  const closeDelModal = () => {
    document.body.style.overflow = 'unset';
    setShowDeleteConfirmModal(false);
  };

  const [manageRapidAction] = useMutation(MANAGE_RAPID_ACTION_CONFIG, {
    onCompleted: (data) => {
      setFeaturedProductsStatus({ value: data.manageRapidActionConfig?.featured?.status });
      setFeaturedProductsType({ value: data.manageRapidActionConfig?.featured?.productAssignmentMode });
    }
  });

  useEffect(() => {
    setIsPageLoading(true);
    manageRapidAction({
      context: {
        headers: {
          Authorization: localStorage.getItem('token') || '',
          tenantid: envUrls.firebaseTenantId,
          'x-api-key': envUrls.authXApiKey
        }
      },
      variables: {
        input: {
          action: 'GET'
        }
      }
    });
    featuredListQuery();
  }, []);

  const [featuredListQuery, { loading: isLoading }] = useLazyQuery(GET_RAPID_LIST, {
    context: {
      headers: {
        Authorization: localStorage.getItem('token') || '',
        tenantid: envUrls.firebaseTenantId,
        'x-api-key': envUrls.authXApiKey
      }
    },
    variables: {
      input: {
        type: 'FEATURED',
        searchText
      }
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getRapidActionList) {
        setProductListData({ ...productListData, autoMaticProductListIds: data.getRapidActionList.productIds });
      }
    },
    onError: (err) => {
      console.log('err::', err);
    }
  });

  const [deleteFeaturedProduct] = useMutation(DELETE_FROM_RAPID_ACTION_LIST, {
    onCompleted: () => {
      featuredListQuery();
    }
  });

  const deleteProduct = (ids: number[]) => {
    deleteFeaturedProduct({
      variables: {
        input: {
          productIds: ids,
          listType: 'FEATURED'
        }
      }
    });
  };

  const saveFeaturedConfig = (status: FeaturedProductsStatusType['value'], productAssignmentMode: productAssignmentMethod) => {
    manageRapidAction({
      variables: {
        input: {
          action: 'SET',
          featured: {
            status: status || 'ACTIVE',
            productAssignmentMode: productAssignmentMode || 'MANUAL'
          }
        }
      }
    });
  };

  const updateFeaturedStatus = (status) => {
    setState({ featuredListStatus: status.value });

    saveFeaturedConfig(status.value, state.selectedProductAssignmentMethod);
    setFeaturedProductsStatus(status);
    setFormChanges(true);
  };

  // const updateFeaturedtype = (state) => {
  //   saveFeaturedConfig(featuredProductsStatus.value, state.value);
  //   setFeaturedProductsType(state);
  // };

  const closeDiscardModal = () => {
    document.body.style.overflow = 'unset';
    setIsDiscardModal(false);
  };
  const closeLeaveModal = () => {
    document.body.style.overflow = 'unset';
    setIsLeaveModal(false);
  };
  const openDiscardModal = () => {
    setIsDiscardModal(true);
  };
  const discardAllChanges = () => {
    document.body.style.overflow = 'unset';
    setTimeout(() => {
      history.push(ROUTES.marketing.catalogs.allCatalogs);
    }, 100);

    setIsDiscardModal(false);
    setIsLeaveModal(false);
    setDataChanged(false);
  };

  const goBackToCatalogScreen = () => {
    setTargettedPath(ROUTES.marketing.catalogs.allCatalogs);
    if (formChanges) {
      setIsLeaveModal(true);
    } else {
      history.push(ROUTES.marketing.catalogs.allCatalogs);
    }
  };

  const leavePageHandler = () => {
    setDataChanged(false);
    // NOTE: Push to targetted path or products, handled with timeout for now.
    setTimeout(() => {
      history.push(targettedPath ? targettedPath : '/products/allProducts');
    }, 100);
  };

  useEffect(() => {
    if (showBar) {
      setFormChanges(true);
      setDataChanged(true);
    } else {
      setFormChanges(false);
      setDataChanged(false);
    }
  }, [featuredProductsType]);

  useEffect(() => {
    const preventRoute = history.block((e: any) => {
      setTargettedPath(e.pathname);
      if (isDataChanged) {
        setIsLeaveModal(true);
        return false;
      } else {
        return true;
      }
    });
    return () => {
      preventRoute();
    };
  }, [history, isDataChanged]);

  // Rules engine

  const [createFeaturedProducRules] = useMutation(CREATE_FEATURED_PRODUCT_RULES, {
    onCompleted: (response) => {
      if (response?.createFeaturedProductRules) {
        const allData = response?.createFeaturedProductRules;

        if (allData?.productIds) {
          if (allData?.productAssignmentMethod === productAssignmentMethod.AUTOMATIC) {
            setProductListData((productlistData) => {
              return {
                ...productlistData,
                autoMaticProductListIds: allData?.productIds?.length > 0 ? allData?.productIds : []
              };
            });
          } else {
            setProductListData((productlistData) => {
              return {
                ...productlistData,
                manualProductListIds: allData?.productIds?.length > 0 ? allData?.productIds : []
              };
            });
          }
        }

        setFormChanges(false);
        setRulesChanges(false);
        featuredListQuery();
        saveFeaturedConfig(state.featuredListStatus, state.selectedProductAssignmentMethod);
        setDisableSaveButton(false);
        showToast({
          successText: 'Featured product saved successfully',
          message: `Featured product has been edited sucessfully`
        });
      }
    },
    onError: (err) => {
      console.log('err', err);
      setDisableSaveButton(false);
      setRulesChanges(false);
    }
  });
  const [featuredProductRulesId, setFeaturedProductRulesId] = useState(null);
  const [fieldDropdown, setFieldDropdown] = useState<FieldConditionType[]>([]);
  const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), {
    selectedProductAssignmentMethod: productAssignmentMethod.MANUAL,
    featuredListStatus: 'ACTIVE'
  });
  const [allConditionMatch, setAllConditionMatch] = useState<boolean>(false);
  const [rulesEngineConditions, setRulesEngineConditions] = useState<ConditionType[]>([
    {
      field: {
        id: 0,
        name: ''
      },
      condition: '',
      category: '',
      errorMessage: '',
      openFieldDropdown: false,
      openConditionDropdown: false,
      openCategoryDropdown: false
    }
  ]);

  const [conditionDropdownArray, setConditionDropdownArray] = useState<
    {
      categoryDropdown: {
        name: string;
        id: number;
      }[];
    }[]
  >([
    {
      categoryDropdown: []
    }
  ]);

  const [callFeaturedProductConditions, { data: featuredProductConditionData }] = useLazyQuery(GET_FEATURED_PRODUCT_CONDITIONS, {
    onCompleted: () => {
      setTimeout(() => {
        getFeaturedProductRule();
      }, 150);
    }
  });

  const [getFeaturedProductRule] = useLazyQuery(GET_FEATURED_PRODUCT_RULE, {
    context: {
      headers: {
        Authorization: localStorage.getItem('token') || '',
        tenantid: envUrls.firebaseTenantId,
        'x-api-key': envUrls.authXApiKey
      }
    },
    variables: {
      input: {
        type: 'FEATURED'
      }
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getFeaturedProductsRules?.productAssignmentRulesLayout) {
        setFeaturedProductRulesId(data.getFeaturedProductsRules?.id);
        setState({
          selectedProductAssignmentMethod: data.getFeaturedProductsRules?.type
        });

        if (data.getFeaturedProductsRules?.productIds) {
          if (data.getFeaturedProductsRules?.type === productAssignmentMethod.AUTOMATIC) {
            setProductListData((productlistData) => {
              return {
                ...productlistData,
                autoMaticProductListIds: data.getFeaturedProductsRules?.productIds
              };
            });
          } else {
            setProductListData((productlistData) => {
              return {
                ...productlistData,
                manualProductListIds: data.getFeaturedProductsRules?.productIds
              };
            });
          }
        }

        const productAssignmentRulesLayout = JSON.parse(data.getFeaturedProductsRules?.productAssignmentRulesLayout);
        const arr = [] as any;
        if (productAssignmentRulesLayout.length > 0) {
          setRulesEngineConditions(productAssignmentRulesLayout);
          productAssignmentRulesLayout.forEach((element, index) => {
            const obj = Object.assign({}, arr[index]) as any;
            obj.categoryDropdown = element.categoryOptions;
            arr[index] = obj as any;
            setConditionDropdownArray([...arr]);
          });
        }
        setAllConditionMatch(data.getFeaturedProductsRules?.rulesMatch === 'ALL' ? true : false);
      }
      setIsPageLoading(false);
    },
    onError: () => {
      //
    }
  });

  useEffect(() => {
    if (featuredProductConditionData?.getFeaturedProductConditions) {
      const arr: { id: number; name: string; operations: string[] }[] = Array.from(
        featuredProductConditionData?.getFeaturedProductConditions
      );
      const fieldDropdownArr: FieldConditionType[] = [];
      arr?.map((condition: { id: number; name: string; operations: string[] }, index: number) => {
        const operationArr: { name: string }[] = [];
        const obj: FieldConditionType = {
          id: 0,
          name: '',
          operations: [
            {
              name: ''
            }
          ]
        };
        condition?.operations?.map((operation, i) => {
          operationArr.push({ name: operation?.replace(/_/g, ' ').toLowerCase() });
          return null;
        });
        obj.id = condition.id;
        obj.name = condition.name;
        obj.operations = operationArr;
        fieldDropdownArr.push(obj);
        return null;
      });
      setFieldDropdown(fieldDropdownArr);
    }
  }, [featuredProductConditionData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    callFeaturedProductConditions();
  }, []);

  const selectProductAssignmentMethod = (productAssignmentMethod) => {
    setFormChanges(true);
    setState({
      selectedProductAssignmentMethod: productAssignmentMethod
    });
  };

  useEffect(() => {
    featuredListQuery();
  }, [searchText]);

  const saveFeaturedProducts = () => {
    let isError = false;
    const productAssignmentRules = [] as any;

    if (state.selectedProductAssignmentMethod === productAssignmentMethod.AUTOMATIC) {
      rulesEngineConditions.forEach((element) => {
        const ruleObject = {} as any;
        switch (element.field.name) {
          case 'Product name':
            ruleObject.fact = 'productName';
            break;
          case 'Product type':
            ruleObject.fact = 'productType';
            break;
          case 'Vendor Name':
            ruleObject.fact = 'vendorName';
            break;
          case 'Product tag':
            ruleObject.fact = 'productTag';
            break;
          case 'Product price':
            ruleObject.fact = 'productPrice';
            break;
          case 'Product cost':
            ruleObject.fact = 'productCost';
            break;
          case 'Product stock':
            ruleObject.fact = 'inventoryStock';
            break;
          case 'Collection Name':
            ruleObject.fact = 'collection';
            break;
        }
        switch (element.condition) {
          case 'is equal to':
            ruleObject.operator = 'equal';
            break;
          case 'is not equal to':
            ruleObject.operator = 'notEqual';
            break;
          case 'is greater than':
            ruleObject.operator = 'greaterThan';
            break;
          case 'is less then':
            ruleObject.operator = 'lessThan';
            break;
          case 'contains':
            ruleObject.operator = 'stringContains';
            break;
          case 'does not contains':
            ruleObject.operator = 'stringNotContains';
            break;
        }
        ruleObject.value = element.category;

        if (typeof element.category == 'string' && element.category.trim().length === 0) {
          isError = true;
          element.errorMessage = `Field can't be empty`;
        } else {
          element.errorMessage = ``;
        }
        productAssignmentRules.push(ruleObject);
      });

      if (!rulesEngineConditions.length) {
        isError = true;
        showToast({
          errorText: 'Error',
          message: `Product condition not specified`
        });
      }
    }

    if (isError) {
      setFormChanges(false);
    }

    setDisableSaveButton(true);
    setRulesChanges(true);

    const variablesInput: any = {
      id: null,
      type: state.selectedProductAssignmentMethod,
      rulesMatch: allConditionMatch ? 'ALL' : 'ANY',
      productAssignmentRules:
        state.selectedProductAssignmentMethod !== 'MANUAL' ? JSON.stringify(productAssignmentRules) : JSON.stringify([]),
      productAssignmentRulesLayout:
        state.selectedProductAssignmentMethod !== 'MANUAL' ? JSON.stringify(rulesEngineConditions) : JSON.stringify([]),
      status: 'ACTIVE',
      productIds:
        state.selectedProductAssignmentMethod !== 'MANUAL'
          ? productListData?.autoMaticProductListIds
          : productListData.manualProductListIds
    };

    if (featuredProductRulesId) {
      variablesInput.id = featuredProductRulesId;
    }

    if (isError) {
      setFormChanges(false);
    }

    if (!isError) {
      createFeaturedProducRules({
        variables: {
          input: variablesInput
        }
      });
    }
  };

  return (
    <>
      {formChanges ? (
        <SaveHeader onSave={saveFeaturedProducts} saveDisabled={disableSaveButton} onDiscard={openDiscardModal} />
      ) : null}
      {isDiscardModal && (
        <CustomModal bodyClassname="w-90 w-md-50" show={isDiscardModal} closeModal={closeDiscardModal}>
          <DiscardModal
            actionBtnTitle={constVariables.common.discardForm.action}
            cancelBtnTitle={constVariables.common.discardForm.cancel}
            message={constVariables.common.discardForm.message}
            title={constVariables.common.discardForm.title}
            actionBtnHandler={discardAllChanges}
            cancelBtnHandler={closeDiscardModal}
          />
        </CustomModal>
      )}
      {isLeaveModal && (
        <CustomModal show={isLeaveModal} bodyClassname="w-90 w-md-50" closeModal={closeLeaveModal}>
          <DiscardModal
            actionBtnTitle={constVariables.common.LeaveForm.action}
            cancelBtnTitle={constVariables.common.LeaveForm.cancel}
            message={constVariables.common.LeaveForm.message}
            title={constVariables.common.LeaveForm.title}
            actionBtnHandler={leavePageHandler}
            cancelBtnHandler={closeLeaveModal}
            deleteProductMedia
          />
        </CustomModal>
      )}
      {showDeleteConfirmModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showDeleteConfirmModal} closeModal={closeDelModal}>
          <DiscardModal
            deleteProductMedia
            actionBtnTitle={constVariables.common.deleteCatalog.action}
            cancelBtnTitle={constVariables.common.deleteCatalog.cancel}
            message={constVariables.common.deleteCatalog.mainMessage}
            title={constVariables.common.deleteCatalog.titleSingle}
            actionBtnHandler={() => null}
            cancelBtnHandler={closeDelModal}
          />
        </CustomModal>
      )}
      <FeaturedProductsPresentational
        isPageLoading={isPageLoading}
        setFeaturedProductsStatus={updateFeaturedStatus}
        featuredProductsStatus={featuredProductsStatus}
        goBackToCatalogScreen={goBackToCatalogScreen}
        setProductListData={setProductListData}
        productListData={productListData}
        deleteProduct={deleteProduct}
        // Rules engine
        rulesEngineConditions={rulesEngineConditions}
        setRulesEngineConditions={setRulesEngineConditions}
        fieldDropdown={fieldDropdown}
        setAllConditionMatch={setAllConditionMatch}
        allConditionMatch={allConditionMatch}
        conditionDropdownArray={conditionDropdownArray}
        setConditionDropdownArray={setConditionDropdownArray}
        selectedProductAssignmentMethod={state.selectedProductAssignmentMethod}
        selectProductAssignmentMethod={selectProductAssignmentMethod}
        formChanges={formChanges}
        setFormChanges={setFormChanges}
        rulesChanges={rulesChanges}
        setRulesChanges={setRulesChanges}
        saveFeaturedProducts={saveFeaturedProducts}
        // Rules engine
      />
    </>
  );
};

export default FeaturedProducts;
