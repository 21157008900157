import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';

// Apollo
import { useMutation } from '@apollo/client';
import { PRINT_SHIPPING_LABEL, REFUND_SHIPPING_LABEL } from 'src/apollo/mutations';

// Components
import { FlexBox, Img, Loader } from 'src/components/atoms';
import { CustomModal, LabelPurchaseAndPrintModal } from 'src/components/oraganisms';
import RefundShippingLabel from './refundShippingLabel';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';
import { useToast } from 'src/utils/hooks/useToast';

// Icons
import { DefaultImage, NoShippingRateImage, RefreshIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { LableBoxProps, ShippingRateObj, ShippingRateProps } from './ShippingRatesFulfillmentCenter.types';
import { debounce } from 'lodash';
const ShippingRatesFulfillmentCenter: FC<ShippingRateProps> = ({
  shippingRateList,
  refetchShippingRate,
  isLoading,
  totalProducts,
  packageData,
  pkgUnitValue,
  pkgWeightValue,
  orderId,
  useWeightCheck,
  insurance,
  addShipmentInsurance,
  signatureConfirmation,
  orderShipment,
  refetchOrderDetails,
  refetchOrderShipment,
  refetchOrderList,
  selectedStatus,
  setSelectedStatus,
  openRefundModalRemotely,
  setOpenRefundModalRemotely,
  selectedOrder
}) => {
  const { showToast } = useToast();

  const [selectedBlock, setSelectedBlock] = useState({
    objectId: '',
    amount: 0,
    amountLocal: 0,
    attributes: '',
    currency: '',
    currencyLocal: '',
    provider: '',
    providerImage75: '',
    providerImage200: '',
    estimatedDays: 0,
    arrivesBy: '',
    durationTerms: '',
    zone: ''
  });
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [listRefreshed, setListRefreshed] = useState(false);
  const [showRefundError, setShowRefundError] = useState(false);
  const selectBlock = (data: ShippingRateObj) => {
    setSelectedBlock(data);
  };

  useEffect(() => {
    if (openRefundModalRemotely) {
      setShowRefundModal(true);
      setOpenRefundModalRemotely(false);
    }
  }, [openRefundModalRemotely]);

  useEffect(() => {
    if (listRefreshed) {
      setTimeout(() => {
        setListRefreshed(false);
      }, 1000);
    }
  }, [listRefreshed]);

  const [printShippingLabel, { loading }] = useMutation(PRINT_SHIPPING_LABEL, {
    onCompleted: (data) => {
      if (data?.printShippingLabel?.labelURL) {
        const refundedOrderId = localStorage.getItem('refundedOrderId');
        if (refundedOrderId && parseInt(refundedOrderId, 10) === orderId) {
          localStorage.removeItem('refundedOrderId');
        }
        closePurchaseModal();
        if (refetchOrderDetails) refetchOrderDetails();
        refetchOrderShipment();
        refetchOrderList();
        const link = document.createElement('a');
        link.href = data?.printShippingLabel?.labelURL;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
      }
    },
    onError: (err) => {
      console.log({ err });
      showToast({ errorText: 'Error in printing shipping label', message: '' });
      closePurchaseModal();
    }
  });

  const [onRefundShippingLabel, { loading: refundLoading }] = useMutation(REFUND_SHIPPING_LABEL, {
    onCompleted: (data) => {
      if (data?.refundShippingLabel?.success) {
        localStorage.setItem('refundedOrderId', orderId.toString());
        showToast({
          successText: `${constVariables.FulfillmentCenter.modal.title} is ${constVariables.FulfillmentCenter.modal.success}`,
          message: constVariables.shipping.modal.message
        });
        setShowRefundError(false);
        closeRefundModal();
        setSelectedStatus(['awaiting_fulfillment']);
        if (refetchOrderDetails) refetchOrderDetails();
        refetchOrderShipment();
        refetchOrderList();
      } else setShowRefundError(true);
    },
    onError: (error) => {
      setShowRefundError(true);
    }
  });

  const onPrintShippingLabel = () => {
    const input: {
      orderId: string;
      rateId: string;
      packageInfo: string;
      rateInfo: string;
      useWeightOfItems: boolean;
      packageWeight: number;
      packageWeightUnit: string;
      needsSignatureConfirmation: boolean;
      hasInsurance: boolean;
      insurance?: {
        amount: number;
        content: string;
      };
    } = {
      orderId: orderId.toString(),
      rateId: selectedBlock.objectId,
      useWeightOfItems: useWeightCheck,
      packageInfo: JSON.stringify(packageData),
      rateInfo: JSON.stringify(selectedBlock),
      packageWeight: pkgWeightValue,
      packageWeightUnit: pkgUnitValue,
      needsSignatureConfirmation: signatureConfirmation,
      hasInsurance: addShipmentInsurance
    };

    if (addShipmentInsurance) {
      input.insurance = insurance;
    }
    printShippingLabel({
      variables: {
        input
      }
    });
  };

  useEffect(() => {
    if (orderShipment.id) {
      setSelectedBlock(JSON.parse(orderShipment.rateInfo));
    }
  }, [orderShipment]);

  const LableBox = memo(({ data }: LableBoxProps) => {
    const { provider, estimatedDays, amount, providerImage200 } = data;
    return (
      <div
        onClick={() => {
          if (!orderShipment.id) selectBlock(data);
        }}
        className={`cursor-pointer bg-white border rounded shipping-rate-item ${
          selectedBlock.objectId === data.objectId && !orderShipment.id ? 'border-primary' : null
        } `}
      >
        <div className="d-flex align-items-center ">
          <Img
            src={setImageSrc(providerImage200)}
            placeholderImg={DefaultImage}
            errorImg={DefaultImage}
            className="fulfilmentProfilePicture h-50px w-50px rounded shipping-rate-icon"
          />
          <div className="w-100">
            <div className={`shipping-rate-item-provider ${selectedBlock.objectId === data.objectId ? 'text-primary' : null}`}>
              {provider}
            </div>
            <div className="d-flex flex-wrap align-items-center justify-content-between mt-1 w-100">
              <div className="shipping-rate-item-amount">$ {amount?.toFixed(2)}</div>
              <div className="shipping-rate-item-dates poppins-regular">
                <span>{estimatedDays}</span>
                <span>
                  {' '}
                  {estimatedDays === 1
                    ? constVariables.FulfillmentCenter.businessDay
                    : constVariables.FulfillmentCenter.businessDays}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const closePurchaseModal = () => {
    document.body.style.overflow = 'unset';
    setShowPurchaseModal(false);
  };

  const closeRefundModal = () => {
    document.body.style.overflow = 'unset';
    setShowRefundModal(false);
  };

  const [cheapest, setCheapest] = useState<ShippingRateObj | undefined>(undefined);
  const [showCheapest, setShowCheapest] = useState(false);
  useEffect(() => {
    if (!isLoading && shippingRateList && shippingRateList.length > 0) {
      const smallestObj = shippingRateList.reduce(
        (minRate, rate) => (rate.amount < minRate.amount ? rate : minRate),
        shippingRateList[0]
      );

      setCheapest(smallestObj);
      setTimeout(() => {
        setShowCheapest(true);
      }, 100);
    } else {
      setShowCheapest(false);
      setCheapest(undefined);
    }
  }, [shippingRateList, isLoading, selectedOrder]);

  const [height, setHeight] = useState(0); // State to store the element's height
  const elementRef = useRef(null);

  useEffect(() => {
    const element = elementRef.current;

    // ResizeObserver callback
    const resizeObserver = new ResizeObserver(
      debounce((entries) => {
        for (let entry of entries) {
          console.log('entry.contentRect.height', entry.contentRect.height);
          // 68
          // 79.5
          setHeight(entry.contentRect.height); // Update height state
        }
      }, 100)
    );

    if (element) {
      resizeObserver.observe(element); // Start observing
    }

    return () => {
      if (element) {
        resizeObserver.unobserve(element); // Cleanup observer
      }
    };
  }, []);

  return (
    <>
      {showPurchaseModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showPurchaseModal} closeModal={closePurchaseModal}>
          <LabelPurchaseAndPrintModal
            message={'mic check'}
            actionBtnTitle={`${constVariables.confirmLabelPurchaseModal.actionTitle} ($${selectedBlock.amount})`}
            cancelBtnTitle={constVariables.confirmLabelPurchaseModal.cancelTitle}
            actionBtnHandler={() => {
              onPrintShippingLabel();
            }}
            cancelBtnHandler={() => {
              setSelectedBlock({
                objectId: '',
                amount: 0,
                amountLocal: 0,
                attributes: '',
                currency: '',
                currencyLocal: '',
                provider: '',
                providerImage75: '',
                providerImage200: '',
                estimatedDays: 0,
                arrivesBy: '',
                durationTerms: '',
                zone: ''
              });
              closePurchaseModal();
            }}
            totalProducts={totalProducts}
            packageData={packageData}
            selectedBlock={selectedBlock}
            pkgUnitValue={pkgUnitValue}
            pkgWeightValue={pkgWeightValue}
            isLoading={loading}
          />
        </CustomModal>
      )}
      {showRefundModal && (
        <CustomModal show={showRefundModal} closeModal={closeRefundModal}>
          <RefundShippingLabel
            title={constVariables.FulfillmentCenter.refundModal.title}
            actionBtnTitle={constVariables.FulfillmentCenter.refundModal.actionButtonTitle}
            cancelBtnTitle={constVariables.FulfillmentCenter.refundModal.cancelButtonTitle}
            actionBtnHandler={() => {
              onRefundShippingLabel({
                variables: {
                  input: {
                    shipmentId: orderShipment.id.toString()
                  }
                }
              });
            }}
            cancelBtnHandler={() => {
              setShowRefundError(false);
              closeRefundModal();
            }}
            cancelOrder={true}
            loading={refundLoading}
            error={showRefundError}
          />
        </CustomModal>
      )}
      <div
        className="h-100 w-100 d-flex"
        style={{
          background: 'white'
        }}
      >
        <div className="d-flex flex-column flex-grow-1 w-100 position-relative">
          <FlexBox className="align-items-center justify-content-between shipping-rates-header px-4 pt-4">
            <div className="poppins-regular fulfill-title">{constVariables.FulfillmentCenter.shippingRates}</div>
            {shippingRateList?.length > 0 && (
              <KTSVG
                path={RefreshIcon}
                className={`cursor-pointer ${orderShipment.id ? 'd-none' : ''}`}
                onClick={() => {
                  setShowCheapest(false);
                  setCheapest(undefined);
                  setListRefreshed(true);
                  refetchShippingRate();
                  setSelectedBlock({
                    objectId: '',
                    amount: 0,
                    amountLocal: 0,
                    attributes: '',
                    currency: '',
                    currencyLocal: '',
                    provider: '',
                    providerImage75: '',
                    providerImage200: '',
                    estimatedDays: 0,
                    arrivesBy: '',
                    durationTerms: '',
                    zone: ''
                  });
                }}
              />
            )}
          </FlexBox>
          {!orderShipment.id && (
            <div
              className={`cheapest-label-slide-in overflow-scroll ${showCheapest && cheapest ? 'active' : ''}`}
              style={{
                height: height > 78 ? '116px' : '103px'
              }}
            >
              <div className="cheapest-label px-4">Cheapest label</div>
              <div ref={elementRef} className="px-4 cheapest-label-slide-in-item">
                {cheapest && <LableBox data={cheapest} key={`${cheapest.objectId}`} />}
              </div>
            </div>
          )}
          <div
            className={`d-flex flex-column flex-grow-1 h-10px position-relative overflow-scroll shipping-list-container px-4 label-list ${
              showCheapest && cheapest ? 'active' : ''
            }`}
            style={{
              // eslint-disable-next-line no-nested-ternary
              marginTop: !orderShipment.id && showCheapest && cheapest ? (height > 78 ? '128px' : '110px') : undefined
            }}
          >
            {shippingRateList?.length > 0 || orderShipment.id ? (
              <>
                {(isLoading && shippingRateList?.length === 0) || listRefreshed ? (
                  <Loader type="page" className="mt-20" />
                ) : (
                  <>
                    {orderShipment.id ? (
                      <LableBox data={JSON.parse(orderShipment.rateInfo)} key="label-box-0" />
                    ) : (
                      shippingRateList?.map((data, index) => <LableBox data={data} key={`label-box-${index + 1}`} />)
                    )}
                    {orderShipment.id && (
                      <div>
                        <button
                          className={`btn btn-primary btn-sm w-100 mb-3 m-0`}
                          onClick={() => {
                            const link = document.createElement('a');
                            link.href = orderShipment?.labelUrl;
                            link.target = '_blank';
                            document.body.appendChild(link);
                            link.click();
                          }}
                        >
                          Re-print label
                        </button>
                        <button
                          className={`btn btn-secondary btn-sm w-100 m-0`}
                          onClick={() => {
                            setShowRefundModal(true);
                          }}
                        >
                          Refund label
                        </button>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {isLoading || listRefreshed ? (
                  <Loader type="page" className="mt-20" />
                ) : (
                  <img className="p-15" src={NoShippingRateImage} />
                )}
              </>
            )}
          </div>

          {!orderShipment.id && !(isLoading || listRefreshed) && shippingRateList.length > 0 && (
            <div
              className={`w-100 mt-2 d-flex align-items-center justify-content-center buy-shipping-label px-4 py-2 button-slide-in ${
                cheapest ? 'active' : ''
              }`}
            >
              <button
                className={`w-100 btn btn-sm ${selectedBlock.objectId !== '' ? 'btn-primary' : 'btn-secondary'} m-0`}
                disabled={selectedBlock.objectId === ''}
                onClick={() => {
                  setShowPurchaseModal(true);
                }}
              >
                {constVariables.FulfillmentCenter.buyShippingLabel}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ShippingRatesFulfillmentCenter;
