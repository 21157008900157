import { FC, useState, useCallback, useEffect, useMemo, useRef, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import clsx from 'clsx';

// Apollo
import { useQuery, useMutation } from '@apollo/client';
import { GET_TIKTOK_CATEGORIES, GET_TIKTOK_PRODUCTS, GET_TIKTOK_PRODUCTS_STATUS_COUNTS } from 'src/apollo/queries';
import {
  ADD_PRODUCT_TO_TIKTOK,
  CHANGE_PRODUCT_TIKTOK_CATEGORY,
  PUSH_PRODUCT_TO_TIKTOK,
  UPDATE_PRODUCT_TYPE
} from 'src/apollo/mutations';

// Components
import { FlexBox, Img, Loader } from 'src/components/atoms';
import {
  CustomModal,
  AddingProductsModal,
  EmptyList,
  TiktokResponseModal,
  ConfirmPushTiktokModal
} from 'src/components/oraganisms';
import { Tabs, SearchBar, PLInventory, DataTable, Pagination, Tooltip, Portal, ShopifySyncTooltip } from 'src/components/molecules';
import CategoryCell from './components/CategoryCell';
import StatusCell from './components/StatusCell';
import ActionMenu from './components/ActionMenu';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';
import { useToast } from 'src/utils/hooks/useToast';

// Icons
import { InfoIcon, DefaultImage, EmptyDataIcon, TikTokTagIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';

// Types
import { TikTokProduct, tiktokInputType, TIKTOK_PRODUCT_STATUS } from './TiktokIntegration.types';

// Styles
import './_tiktokIntegration.scss';
import { TIKTOK_CHANGE_PRODUCT_STATUS } from '../../../apollo/mutations/tiktokChangeProductStatus';
import { REMOVE_PRODUCTS_FROM_DRAFTS } from '../../../apollo/mutations/removeProductsFromDrafts';
import StatusCellForActive from './components/StatusCellForActive';

const TiktokIntegration: FC = () => {
  const history = useHistory();
  const { showToast } = useToast();
  const [selectedTab, setSelectedTab] = useState('ACTIVE');
  const [statusCountList, setStatusCountList] = useState<any>();
  const [tiktokProducts, setTiktokProducts] = useState<TikTokProduct[]>([]);
  const [selectedProductIDs, setSelectedProductIDs] = useState<number[]>([]);
  const [totalCounts, setTotalCounts] = useState(0);
  const [isShowAddingProductsModal, setIsShowAddingProductsModal] = useState(false);
  const [showTiktokResponseModal, setShowTiktokResponseModal] = useState(false);
  const [responsedProduct, setResponsedProduct] = useState<TikTokProduct>();
  const [pushTiktokConfirmModal, setPushTiktokConfirmModal] = useState(false);

  const [tiktokInput, setTiktokInput] = useState<tiktokInputType>({
    status: ['ACTIVE'],
    searchString: '',
    pageInfo: {
      skipCount: 0,
      limitCount: 50
    }
  });

  const [changeTiktokProductStatus, { loading: isChangingProductStatus }] = useMutation(TIKTOK_CHANGE_PRODUCT_STATUS, {
    onCompleted: (response) => {
      if (response) {
        refecthStatusCounts();
        refetchTiktokProducts();
      }
    },
    onError: (error) => {
      showToast({
        errorText: error.message,
        message: `Error occured while updating Product status: ${error.message}`
      });
    }
  });

  useEffect(() => {
    let syncStatus;
    setSelectedProductIDs([]);
    if (selectedTab === 'IN REVIEW') {
      syncStatus = ['IN_REVIEW', 'QUEUED'];
    } else if (selectedTab === 'DRAFT') {
      syncStatus = ['PROCESSING', 'DRAFT', 'UPDATES_REQUIRED'];
    } else if (selectedTab === 'ALL PRODUCTS') {
      syncStatus = [];
    } else {
      syncStatus = [selectedTab];
    }

    setTiktokInput({
      ...tiktokInput,
      status: syncStatus,
      pageInfo: {
        ...tiktokInput.pageInfo,
        skipCount: 0
      }
    });
  }, [selectedTab, tiktokInput.searchString]);

  const search = useLocation().search;

  useEffect(() => {
    const searchParams = Object.fromEntries(new URLSearchParams(search).entries());
    if (searchParams.tab && searchParams.tab !== selectedTab) {
      setSelectedTab(searchParams.tab);
    }
  }, [search]);

  const setSelectedTabAndUrl = (tab) => {
    setSelectedTab(tab);
    history.push(`/marketing/tiktok-integration?tab=${tab}`);
  };

  const { data, refetch: refecthStatusCounts } = useQuery(GET_TIKTOK_PRODUCTS_STATUS_COUNTS, {
    fetchPolicy: 'no-cache',
    pollInterval: 30000,
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      if (res?.getTiktokProductStatusCounts) {
        const statusCounts = res.getTiktokProductStatusCounts;
        setStatusCountList({
          [constVariables.TabForTikTokIntegration[0]]: statusCounts.totalCount ? statusCounts.totalCount : 0,
          [constVariables.TabForTikTokIntegration[1]]: statusCounts.activeCount ? statusCounts.activeCount : 0,
          [constVariables.TabForTikTokIntegration[2]]: statusCounts.inactiveCount ? statusCounts.inactiveCount : 0,
          [constVariables.TabForTikTokIntegration[3]]: statusCounts.inReviewTabCount ? statusCounts.inReviewTabCount : 0,
          [constVariables.TabForTikTokIntegration[4]]: statusCounts.draftTabCount ? statusCounts.draftTabCount : 0,
          [constVariables.TabForTikTokIntegration[5]]: statusCounts.failedCount ? statusCounts.failedCount : 0,
          [constVariables.TabForTikTokIntegration[6]]: statusCounts.suspendedCount ? statusCounts.suspendedCount : 0
        });
      }
    },
    onError: (error) => {
      console.log('error::', error);
    }
  });

  const {
    loading: isProductsLoading,
    refetch: refetchTiktokProducts,
    data: tiktokProductsResponse
  } = useQuery(GET_TIKTOK_PRODUCTS, {
    variables: {
      input: tiktokInput
    },
    fetchPolicy: 'no-cache',
    pollInterval: 30000,
    // TODO onCompleted invoked once when the query is successfully fetched for the first time. It does not run on subsequent polling intervals.
    onCompleted: (res) => {
      if (res?.getTikTokProducts) {
        setTotalCounts(res?.getTikTokProducts?.totalProducts);
        setTiktokProducts(res?.getTikTokProducts?.products);
      }
    },
    onError: (error) => {
      console.log('error::', error);
    }
  });

  useEffect(() => {
    console.log('tiktokProductsResponse', tiktokProductsResponse);
    if (tiktokProductsResponse?.getTikTokProducts) {
      setTotalCounts(tiktokProductsResponse?.getTikTokProducts?.totalProducts);
      setTiktokProducts(tiktokProductsResponse?.getTikTokProducts?.products);
    }
  }, [tiktokProductsResponse]);

  const fetchTimerRef = useRef(null);

  const [addProductToTiktok, { loading: isAddingProduct }] = useMutation(ADD_PRODUCT_TO_TIKTOK, {
    onError: (error) => {
      console.log('error::', error);
      handleCloseAddingProductsModal();
    }
  });

  const [removeProductsFromDrafts, { loading: isRemovingProduct }] = useMutation(REMOVE_PRODUCTS_FROM_DRAFTS, {
    onError: (error) => {
      console.log('error::', error);
      handleCloseAddingProductsModal();
    }
  });

  const [changeTiktokProductCategory] = useMutation(CHANGE_PRODUCT_TIKTOK_CATEGORY, {
    onError: (error) => {
      console.log('error::', error);
    }
  });

  const [pushProductToTiktok, { loading: isPushingProduct }] = useMutation(PUSH_PRODUCT_TO_TIKTOK, {
    onError: (err) => {
      console.log('err::', err);
      closeConfirmPushTiktokModal();
    }
  });

  const showAddingProductModal = () => {
    setIsShowAddingProductsModal(true);
  };

  const handleCloseAddingProductsModal = () => {
    document.body.style.overflow = 'unset';
    setIsShowAddingProductsModal(false);
  };

  const handleAddProducts = (productIds: number[]) => {
    addProductToTiktok({
      variables: {
        input: {
          productIds
        }
      }
    }).then((res) => {
      if (res?.data?.addProductToTiktok?.success) {
        handleCloseAddingProductsModal();
        setSelectedTabAndUrl('DRAFT');
        showToast({
          successText: `<span style="color: #1e2749;">${productIds?.length} products<span> <span style="color:#50cd89;">are being processed.</span>`,
          message: 'Initial review in progress to ensure all product information is accurate.'
        });
        refecthStatusCounts();
        refetchTiktokProducts();
      }
    });
  };

  const handleRemoveProducts = () => {
    removeProductsFromDrafts({
      variables: {
        input: {
          productIds: selectedProductIDs
        }
      }
    }).then((res) => {
      if (res?.data?.removeProductsFromDrafts?.success) {
        showToast({ successText: 'Product removed from draft successfully', message: `Product removed from draft successfully` });
        refecthStatusCounts();
        refetchTiktokProducts();
        setSelectedProductIDs([]);
      }
    });
  };

  const handleUpdateProductTiktokCategory = (productId, categoryId) => {
    changeTiktokProductCategory({
      variables: {
        input: {
          productId,
          categoryId
        }
      }
    }).then((res) => {
      if (res?.data?.changeProductCategory) {
        const { status } = res?.data?.changeProductCategory;
        const updateadProducts = tiktokProducts.map((product) => {
          if (product.id === productId) {
            return { ...product, status };
          }
          return product;
        });
        setTiktokProducts(updateadProducts);
      }
    });
  };

  const handleSearch = (value) => {
    setTiktokInput({
      ...tiktokInput,
      searchString: value
    });
  };

  const selectTiktokProduct = useCallback(
    (e: any, productId?: any) => {
      if (e === 'All') {
        setSelectedProductIDs(tiktokProducts.map((product) => product.id));
      } else if (e.target.checked) {
        setSelectedProductIDs([...selectedProductIDs, productId]);
      } else {
        setSelectedProductIDs(selectedProductIDs.filter((productItemId) => productItemId !== productId));
      }
    },
    [tiktokProducts, selectedProductIDs]
  );

  const goToInventoryPage = useCallback(() => history.push(`${ROUTES.products.inventory.main}`), [history]);

  const handleShowResponse = (product) => {
    setShowTiktokResponseModal(true);
    setResponsedProduct(product);
  };

  const filterMenu = (status) => {
    // if (status === TIKTOK_PRODUCT_STATUS.ACTIVE) {
    //   return constVariables.tiktokOptionsDropdownData.filter((o) => o.id === 2);
    // }
    // if (status === TIKTOK_PRODUCT_STATUS.INACTIVE) {
    //   return constVariables.tiktokOptionsDropdownData.filter((o) => o.id === 1);
    // }
    return [];
  };

  const handleUpdate = (product, value) => {
    if (value.name === 'Active') {
      return handleMenu({ id: 1 }, product);
    }
    if (value.name === 'Inactive') {
      return handleMenu({ id: 2 }, product);
    }
  };

  const handleMenu = (selectedOption, product) => {
    switch (selectedOption.id) {
      case 1:
        changeTiktokProductStatus({
          variables: {
            input: {
              status: 'ACTIVE',
              productIds: [product.id]
            }
          }
        }).then(() => {
          setSelectedTabAndUrl('ACTIVE');
          showToast({
            successText: `<span style="color: #1e2749;">1 products are now <span> <span style="color:#50cd89;">Active</span>`,
            message: 'Your products are available on your TikTok store!'
          });
        });
        break;
      case 2:
        changeTiktokProductStatus({
          variables: {
            input: {
              status: 'INACTIVE',
              productIds: [product.id]
            }
          }
        }).then(() => {
          setSelectedTabAndUrl('INACTIVE');
          showToast({
            successText: `<span style="color: #1e2749;">${1} products are now <span> <span style="color:#f1416c;">inactive</span>`,
            message: 'You can activate them whenever needed.'
          });
        });
        break;
    }
  };

  const handleActivate = () => {
    if (selectedProductIDs.length > 0) {
      const count = selectedProductIDs.length;
      changeTiktokProductStatus({
        variables: {
          input: {
            status: 'ACTIVE',
            productIds: selectedProductIDs
          }
        }
      }).then(() => {
        setSelectedTabAndUrl('ACTIVE');
        showToast({
          successText: `<span style="color: #1e2749;">${count} products are now <span> <span style="color:#50cd89;">Active</span>`,
          message: 'Your products are available on your TikTok store!'
        });
      });
    }
  };

  const handleDeactivate = () => {
    if (selectedProductIDs.length > 0) {
      const count = selectedProductIDs.length;
      changeTiktokProductStatus({
        variables: {
          input: {
            status: 'INACTIVE',
            productIds: selectedProductIDs
          }
        }
      }).then(() => {
        setSelectedTabAndUrl('INACTIVE');
        showToast({
          successText: `<span style="color: #1e2749;">${count} products are now <span> <span style="color:#f1416c;">inactive</span>`,
          message: 'You can activate them whenever needed.'
        });
      });
    }
  };

  const renderStatusTooltip = useMemo(() => {
    switch (selectedTab) {
      case 'DRAFT':
        return (
          <Fragment>
            <span>Products that need updates or are undergoing initial processing.</span>
            <br />
            <span className="m-b-8">“Ready” products can be pushed to TikTok.</span>
          </Fragment>
        );
      case 'IN REVIEW':
        return (
          <Fragment>
            <span>Products under TikTok review will move to “Active” once approved.</span>
            <br />
            <span className="m-b-8">Items in queue are waiting due to TikTok's daily limit and will be reviewed</span>
            <br />
            <span className="m-b-8">as soon as possible.</span>
          </Fragment>
        );
      default:
        return null;
    }
  }, [selectedTab]);
  const goToTiktokPage = (url) => {
    window.open(url, '_blank');
  };

  const getOptions = (parents, currentCategory, allOptions) => {
    // console.log('getOptions', parents, currentCategory);
    const temp = [...parents];
    if (currentCategory.children && currentCategory.children.length > 0) {
      temp.push(currentCategory.name);
      currentCategory.children.forEach((c) => {
        getOptions(temp, c, allOptions);
      });
    } else {
      allOptions.push({
        id: currentCategory.id,
        name: `${parents.join(' - ')} - ${currentCategory.name}`
      });
    }
  };

  const [categories, setCategories] = useState([]);
  const [allCategorySearchOptions, setAllCategorySearchOptions] = useState([]);
  const { loading: isCategoryLoading } = useQuery(GET_TIKTOK_CATEGORIES, {
    onCompleted: (res) => {
      setCategories(res.getCategories);
      if (allCategorySearchOptions.length === 0) {
        const allOptions = [];
        res.getCategories.forEach((c) => {
          getOptions([], c, allOptions);
        });
        console.log('allOptions', allOptions);
        setAllCategorySearchOptions(allOptions);
      }
    },
    onError: (error) => {
      console.log('error::', error);
    }
  });

  const initialColumnOptions = [
    {
      dataKey: 'checkbox',
      name: '',
      width: 50,
      headerRenderer: () => {
        return (
          <div className="form-check form-check-sm form-check-custom">
            <input
              className="form-check-input widget-9-check"
              type="checkbox"
              checked={tiktokProducts?.length === selectedProductIDs?.length}
              onChange={() => {
                if (tiktokProducts?.length === selectedProductIDs?.length) {
                  setSelectedProductIDs([]);
                } else {
                  selectTiktokProduct('All');
                }
              }}
            />
          </div>
        );
      },
      cellRenderer: ({ rowData }) => {
        return (
          <div
            className="form-check form-check-sm form-check-custom"
            data-tip={rowData?.status === TIKTOK_PRODUCT_STATUS.UPDATES_REQUIRED}
            data-for={rowData?.status === TIKTOK_PRODUCT_STATUS.UPDATES_REQUIRED ? 'checkboxTooltip' : null}
            style={{
              cursor:
                (selectedTab !== 'DRAFT' && selectedTab !== 'ACTIVE' && selectedTab !== 'INACTIVE') ||
                rowData?.status === TIKTOK_PRODUCT_STATUS.PROCESSING
                  ? 'not-allowed'
                  : 'pointer'
            }}
          >
            <input
              className="form-check-input widget-9-check tiktok-checkbox"
              type="checkbox"
              checked={selectedProductIDs.includes(rowData.id)}
              onChange={(e) => {
                selectTiktokProduct(e, rowData.id);
              }}
              onClick={(e) => {
                selectTiktokProduct(e, rowData.id);
              }}
              disabled={
                (selectedTab !== 'DRAFT' && selectedTab !== 'ACTIVE' && selectedTab !== 'INACTIVE') ||
                rowData?.status === TIKTOK_PRODUCT_STATUS.PROCESSING
              }
            />
            {rowData?.status === TIKTOK_PRODUCT_STATUS.UPDATES_REQUIRED && (
              <Tooltip
                tooltipId="checkboxTooltip"
                place="top"
                content={() => {
                  return <>Product is not ready</>;
                }}
              />
            )}
          </div>
        );
      }
    },
    {
      dataKey: 'productName',
      name: 'Products',
      width: 330,
      cellRenderer: ({ rowData }) => {
        return (
          <FlexBox className="align-items-center">
            <div className="symbol m-r-20">
              <Img
                src={setImageSrc(rowData?.imageUrl ? generateCompressedImageURL(rowData?.imageUrl, '50') : DefaultImage)}
                placeholderImg={DefaultImage}
                errorImg={DefaultImage}
                className="object-fit-scale-down bg-white border border-light border-2 productList"
              />
              {rowData?.isLive && (
                <>
                  <img
                    src={TikTokTagIcon}
                    className="cursor-pointer ms-1 shopify-icon position-absolute"
                    data-tip
                    data-for="shopifySyncIcon"
                    onClick={() => goToTiktokPage(rowData?.url)}
                  />
                  <ShopifySyncTooltip tooltipText="Product in Tiktok" />
                </>
              )}
            </div>
            <Link
              to={{
                pathname: `/products/allProducts/edit/${rowData?.id}`,
                state: {
                  tiktokError: JSON.parse(rowData?.error) ? JSON.parse(rowData?.error) : undefined,
                  from: ROUTES.marketing.tiktokIntegration.main,
                  rowData
                }
              }}
              className="main-cell tiktok-product"
            >
              <span className="d-block">{rowData?.name}</span>
            </Link>
          </FlexBox>
        );
      }
    },
    {
      dataKey: 'productQty',
      name: 'Inventory',
      width: 120,
      cellRenderer: ({ rowData }) => {
        return <PLInventory goToInventoryPage={goToInventoryPage} quantityInStock={rowData?.inventory} />;
      }
    },
    {
      dataKey: 'category',
      name: 'Category',
      width: 300,
      cellRenderer: ({ rowData }) => {
        return (
          <CategoryCell
            data={rowData}
            updateCategory={handleUpdateProductTiktokCategory}
            categories={categories}
            allCategorySearchOptions={allCategorySearchOptions}
            isLoading={isCategoryLoading}
          />
        );
      }
    },
    {
      dataKey: 'status',
      name: 'Sync Status',
      headerRenderer: () => {
        return (
          <div>
            <div className="d-flex align-items-center" data-tip data-for="tiktokStatusDraftInfo">
              <span className="me-2">Sync Status</span>
              <img src={InfoIcon} />
            </div>
            <Tooltip
              tooltipId={'tiktokStatusDraftInfo'}
              place="top"
              content={() => {
                return <>{renderStatusTooltip}</>;
              }}
            />
          </div>
        );
      },
      width: 140,
      cellRenderer: ({ rowData }) => {
        if (rowData.status === TIKTOK_PRODUCT_STATUS.ACTIVE || rowData.status === TIKTOK_PRODUCT_STATUS.INACTIVE) {
          return <StatusCellForActive id={rowData.id} product={rowData} handleUpdate={handleUpdate} />;
        }
        return <StatusCell data={rowData} handleShowResponse={handleShowResponse} />;
      }
    },
    {
      dataKey: 'action',
      name: '',
      width: 50,
      cellRenderer: ({ rowData }) => {
        return <ActionMenu product={rowData} filterMenu={filterMenu} handleMenu={handleMenu} />;
      }
    }
  ];

  const closeTiktokResponseModal = () => {
    document.body.style.overflow = 'unset';
    setShowTiktokResponseModal(false);
  };

  const handlePushTiktokModal = () => {
    setPushTiktokConfirmModal(true);
  };

  const closeConfirmPushTiktokModal = () => {
    document.body.style.overflow = 'unset';
    setPushTiktokConfirmModal(false);
  };

  const handlePushToTiktok = (includeBothReady: boolean) => {
    let productIds;

    let selectedProducts;
    if (selectedProductIDs.length > 0) {
      let idSet = new Set(selectedProductIDs);
      selectedProducts = tiktokProducts.filter((p) => idSet.has(p.id));
    } else {
      selectedProducts = tiktokProducts;
    }
    console.log('handlePushToTiktok', selectedProducts);
    if (includeBothReady) {
      productIds = selectedProducts
        ?.filter((product) => product.status === TIKTOK_PRODUCT_STATUS.DRAFT)
        ?.map((product) => product.id);
    } else {
      productIds = selectedProducts
        ?.filter((product) => {
          if (product.error) {
            const errorObject = JSON.parse(product.error);
            return product.status === TIKTOK_PRODUCT_STATUS.DRAFT && !errorObject.tiktok;
          }
          return product.status === TIKTOK_PRODUCT_STATUS.DRAFT;
        })
        ?.map((product) => product.id);
    }

    if (productIds?.length === 0) return;

    pushProductToTiktok({
      variables: {
        input: {
          productIds,
          pushPrdWithSuggestions: includeBothReady
        }
      }
    }).then((res) => {
      if (res?.data?.pushProductToTikTok) {
        closeConfirmPushTiktokModal();
        setSelectedTabAndUrl('IN REVIEW');
        showToast({
          successText: `<span style="color: #1e2749;">${productIds?.length} products<span> <span style="color:#50cd89;">were sent to review!</span>`,
          message: 'Your products will become Active as TikTok review them.'
        });
        refecthStatusCounts();
        refetchTiktokProducts();
        setSelectedProductIDs([]);
      }
    });
  };

  const readyStatusCount = useMemo(() => {
    return tiktokProducts
      ?.filter((p) => {
        let idSet = new Set(selectedProductIDs);
        if (selectedProductIDs.length === 0) {
          return true;
        }
        return idSet.has(p.id);
      })
      ?.filter((product) => {
        if (product.error) {
          const errorObject = JSON.parse(product.error);
          return product.status === TIKTOK_PRODUCT_STATUS.DRAFT && !errorObject.tiktok;
        }
        return product.status === TIKTOK_PRODUCT_STATUS.DRAFT;
      })?.length;
  }, [tiktokProducts, selectedProductIDs]);

  const suggestionCount = useMemo(() => {
    return tiktokProducts
      ?.filter((p) => {
        let idSet = new Set(selectedProductIDs);
        if (selectedProductIDs.length === 0) {
          return true;
        }
        return idSet.has(p.id);
      })
      ?.filter((product) => {
        if (product.error) {
          const errorObject = JSON.parse(product.error);
          return product.status === TIKTOK_PRODUCT_STATUS.DRAFT && product.error && errorObject.tiktok;
        }
        return false;
      })?.length;
  }, [tiktokProducts, selectedProductIDs]);

  const remainingStatusCount = useMemo(() => {
    return tiktokProducts
      ?.filter((p) => {
        let idSet = new Set(selectedProductIDs);
        if (selectedProductIDs.length === 0) {
          return true;
        }
        return idSet.has(p.id);
      })
      ?.filter(
        (product) =>
          product.status === TIKTOK_PRODUCT_STATUS.PROCESSING || product.status === TIKTOK_PRODUCT_STATUS.UPDATES_REQUIRED
      )?.length;
  }, [tiktokProducts, selectedProductIDs]);

  return (
    <>
      <div className="w-100 details-page p-t-40" id="tablePage">
        <div className="details-page-container">
          <FlexBox className="w-100 mx-0 justify-content-between custom-page-header">
            <FlexBox className="align-items-center">
              <h2 className="page-title m-0">{constVariables.TikTokIntegration.title}</h2>
              <img src={InfoIcon} className="cursor-pointer m-l-12" data-tip data-for="infoItemIcon" />
            </FlexBox>
            <button className="btn btn-primary btn-md me-0" onClick={showAddingProductModal}>
              {constVariables.TikTokIntegration.addProducts}
            </button>
          </FlexBox>
          <div className="card m-b-40">
            <h4 className="section-title m-b-30">{constVariables.TikTokIntegration.overview}</h4>
            <FlexBox className="align-items-center tiktok-overview">
              <div className="tiktok-overview-item">
                <h5>{constVariables.TikTokIntegration.inProcess}</h5>
                <p>{data?.getTiktokProductStatusCounts?.inProcessCount || 0}</p>
              </div>
              <div className="tiktok-overview-item">
                <h5>{constVariables.TikTokIntegration.readyToPush}</h5>
                <p>{data?.getTiktokProductStatusCounts?.readyCount || 0}</p>
              </div>
              <div className="tiktok-overview-item">
                <h5>{constVariables.TikTokIntegration.inQueue}</h5>
                <p>{data?.getTiktokProductStatusCounts?.queuedCount || 0}</p>
              </div>
              <div className="tiktok-overview-item">
                <h5>{constVariables.TikTokIntegration.inReview}</h5>
                <p>{data?.getTiktokProductStatusCounts?.inReviewCount || 0}</p>
              </div>
              <div className="tiktok-overview-item">
                <h5>{constVariables.TikTokIntegration.dailyLimit}</h5>
                <p>
                  {data?.getTiktokProductStatusCounts?.productSyncedToday || 0} /{' '}
                  {data?.getTiktokProductStatusCounts?.productSyncDailyLimit || 100}
                </p>
              </div>
            </FlexBox>
          </div>
          <div className="card w-100 mb-0 p-0">
            <div className="p-x-32 p-t-32">
              <h4 className="section-title m-b-30">{constVariables.TikTokIntegration.products}</h4>
              <Tabs
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTabAndUrl}
                tabs={constVariables.TabForTikTokIntegration}
                showCount
                countValue={statusCountList}
              />
              {!isProductsLoading && totalCounts === 0 && !tiktokInput.searchString && (
                <EmptyList datatype="product" status={selectedTab} icon={EmptyDataIcon} />
              )}
              <div
                className={`${!isProductsLoading && totalCounts === 0 && !tiktokInput.searchString ? 'display-none' : 'm-t-24'}`}
              >
                <div className="m-b-24">
                  <SearchBar placeholder="Search for products" onChange={handleSearch} />
                </div>
                <div className="m-b-8">
                  {selectedProductIDs.length > 0 && (
                    <button className="btn btn-outlined-primary btn-sm me-2" onClick={() => setSelectedProductIDs([])}>
                      <div className="d-flex align-items-center">
                        <input
                          className={'form-check-input small-checkbox-size shadow-none opacity-100 bg-primary rounded-sm m-0'}
                          type="checkbox"
                          disabled={true}
                          checked={selectedProductIDs.length > 0}
                          ref={(input) => {
                            if (input) {
                              input.indeterminate = true;
                            }
                          }}
                        />
                        <span className="ms-2">{selectedProductIDs.length} Selected</span>
                      </div>
                    </button>
                  )}
                  {(selectedTab === 'ALL PRODUCTS' || selectedTab === 'DRAFT') && (
                    <button className="btn btn-primary btn-sm me-2" onClick={handlePushTiktokModal}>
                      Push to TikTok ({readyStatusCount + suggestionCount})
                    </button>
                  )}
                  {selectedProductIDs.length > 0 && selectedTab === 'INACTIVE' && (
                    <button className="btn btn-primary btn-sm me-2" onClick={handleActivate}>
                      Set as active
                      {isChangingProductStatus && <Loader type="button" className="h-10px w-10px" />}
                    </button>
                  )}
                  {selectedProductIDs.length > 0 && selectedTab === 'ACTIVE' && (
                    <button className="btn btn-primary btn-sm me-2" onClick={handleDeactivate}>
                      Set as Inactive
                      {isChangingProductStatus && <Loader type="button" className="h-10px w-10px" />}
                    </button>
                  )}
                  {selectedProductIDs.length > 0 && selectedTab === 'DRAFT' && (
                    <button className="btn btn-danger btn-sm m-0" onClick={handleRemoveProducts}>
                      Remove from Drafts
                      {isRemovingProduct && <Loader type="button" className="h-10px w-10px" />}
                    </button>
                  )}
                </div>
                <DataTable
                  page="tiktok"
                  rowData={tiktokProducts}
                  columnOptions={initialColumnOptions}
                  isLoading={isProductsLoading}
                  searchText={tiktokInput.searchString}
                  selectedIndex={-1}
                  selectedIDs={selectedProductIDs}
                />
              </div>
            </div>
            <div className={clsx({ 'display-none': totalCounts === 0 && !tiktokInput.searchString })}>
              <Pagination
                skipCount={tiktokInput?.pageInfo?.skipCount}
                limitCount={tiktokInput?.pageInfo?.limitCount}
                setSkipCount={(num: number) => {
                  setTiktokInput((prev) => ({
                    ...prev,
                    pageInfo: {
                      ...prev.pageInfo,
                      skipCount: num
                    }
                  }));
                }}
                total={totalCounts}
              />
            </div>
          </div>
        </div>
      </div>
      {isShowAddingProductsModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={isShowAddingProductsModal} closeModal={handleCloseAddingProductsModal}>
          <AddingProductsModal
            title={constVariables.TikTokIntegration.addProductsToTiktok}
            closeModal={handleCloseAddingProductsModal}
            shouldShowVariants={false}
            handleAddProducts={handleAddProducts}
            isShowOnlyActiveProducts={true}
            isAddingProduct={isAddingProduct}
            isAddingToTiktok={true}
          />
        </CustomModal>
      )}
      {showTiktokResponseModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showTiktokResponseModal} closeModal={closeTiktokResponseModal}>
          <TiktokResponseModal product={responsedProduct} closeModal={closeTiktokResponseModal} />
        </CustomModal>
      )}
      {pushTiktokConfirmModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={pushTiktokConfirmModal} closeModal={closeConfirmPushTiktokModal}>
          <ConfirmPushTiktokModal
            readyStatusCount={readyStatusCount}
            readyInfoStatusCount={suggestionCount}
            remainingStatusCount={remainingStatusCount}
            closeModal={closeConfirmPushTiktokModal}
            handlePushToTiktok={handlePushToTiktok}
            isPushingProduct={isPushingProduct}
          />
        </CustomModal>
      )}
    </>
  );
};

export default TiktokIntegration;
