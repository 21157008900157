export const getProductFilterInput = (input: any) => {
  console.log('getProductFilterInput input', input);

  const keyMappings = {
    productIds: 'productIdsString',
    variantTypeIDs: 'variantTypeIDsString',
    vendorIDs: 'vendorIDsString',
    productTypes: 'productTypesString',
    tags: 'tagsString',
    categoryIds: 'categoryIdsString',
    collections: 'collectionsString',
    locationIds: 'locationIdsString',
    filterStatus: 'filterStatusString'
  };

  const processKeys = (obj: any, mappings: any) => {
    const result: any = { ...obj };
    Object.keys(mappings).forEach((key) => {
      if (Array.isArray(obj[key])) {
        // Create a copy of the array before sorting
        const sortedArray = [...obj[key]].sort();
        result[mappings[key]] = JSON.stringify(sortedArray);
        delete result[key];
      }
    });
    return result;
  };

  // Process root-level keys
  let result = processKeys(input, keyMappings);

  // Process nested filters object if it exists
  if (input.filters) {
    result.filters = processKeys(input.filters, keyMappings);
  }
  result.tenantId = localStorage.getItem('storeId');
  result.warehouseIds = input.warehouseIds;
  return result;
};
