import { FC, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import clsx from 'clsx';

// Apollo
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  refreshActiveList,
  refreshAllProductList,
  refreshArchivedList,
  refreshDraftList,
  refreshInactiveList
} from 'src/apollo/reactiveVariables';
import { GET_PRODUCTS_V3 } from 'src/apollo/queries';
import { MANAGE_RAPID_ACTION_CONFIG } from 'src/apollo/mutations';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { EditColumn, Pagination, Tabs } from 'src/components/molecules';
import { PageHeader, ProductList, EmptyList } from 'src/components/oraganisms';
import { FlexBox } from 'src/components/atoms';
import ProductFilterChips from 'src/components/oraganisms/ProductList/components/ProductFilterChips';

// Hooks && Utils && Helpers
import useTitle from 'src/utils/hooks/useTitle';

// Icons
import { EmptyDataIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, envUrls } from 'src/constants';

// Types
import { getProductInputType } from './productPage.types';
import { FilterFields } from 'src/components/molecules/RightFilterMenu/RightFilterMenu.types';
import { ProductDetailType } from 'src/components/oraganisms/BulkEditorModal/BulkEditorModal.types';
import { ColumnType } from 'src/components/molecules/Table/Table.types';
import { UserRoles } from '../../settings/UserPermissions/UserPermissions.type';
import { getProductFilterInput } from 'src/utils/convertProductAPIInput';

// Styles
import './_productPage.scss';

const _ = require('lodash');

const initialProductsTableColumns: ColumnType[] = [
  { name: 'Inventory', status: true },
  { name: 'Type', status: true },
  { name: 'Vendor', status: true },
  { name: 'Sale price', status: true },
  { name: 'Status', status: true },
  { name: 'Tags', status: false },
  { name: 'Margin', status: false },
  { name: 'Profit', status: false },
  { name: 'Item cost', status: false },
  { name: 'Potential revenue', status: false },
  // { name: 'Free shipping', status: false },
  // { name: 'Shipping cost', status: false },
  { name: 'SKU', status: false },
  { name: 'Barcode', status: false }
];

const statusData = [
  { id: 1, name: 'Active' },
  { id: 2, name: 'Inactive' },
  { id: 3, name: 'Draft' },
  { id: 4, name: 'Archived' }
];

const Product: FC = () => {
  const history = useHistory();
  useTitle('Products');
  const [selectedTab, setSelectedTab] = useState('ACTIVE');
  const [showBulkEditorModal, setShowBulkEditorModal] = useState(false);
  const [productList, setProductList] = useState<ProductDetailType[]>([]);
  const [reloadRapidAction, setReloadRapidAction] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<Array<number>>([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [sortBy, setSortBy] = useState({
    catagory: 'Default',
    id: 1,
    type: 'ASC'
  });
  const [editColumnData, setEditColumnData] = useState<ColumnType[]>([]);
  const [openEditColumnDropdown, setOpenEditColumnDropdown] = useState(false);
  const [toggles, setToggles] = useState({ featured: false, flashSale: false });
  const [showFilterChips, setShowFilterChips] = useState(false);

  const search = useLocation().search;

  useEffect(() => {
    const searchParams = Object.fromEntries(new URLSearchParams(search).entries());
    if (searchParams.tab && searchParams.tab !== selectedTab) {
      setSelectedTab(searchParams.tab);
    }
  }, [search]);

  const setSelectedTabAndUrl = (tab) => {
    setSelectedTab(tab);
    history.push(`/products/allProducts?tab=${tab}`);
  };

  const [productFilters, setProductFilters] = useState<FilterFields>({
    vendorIDs: [],
    tags: [],
    filterStatus: [],
    productTypes: [],
    collections: []
  });
  const productInput = useRef({});

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const handleUpdateProductFilters = (fieldName: string, value) => {
    setProductFilters((productFilters) => {
      return { ...productFilters, [fieldName]: value };
    });
  };

  const handleClearProductFilter = () => {
    setShowFilterChips(false);
    setProductFilters({
      vendorIDs: [],
      tags: [],
      filterStatus: [],
      productTypes: [],
      collections: []
    });
    setProductInput((getProductInput) => {
      return {
        ...getProductInput,
        filters: {
          ...getProductInput.filters,
          vendorIDs: [],
          filterStatus: [],
          tags: [],
          productTypes: [],
          collections: []
        }
      };
    });
  };

  useEffect(() => {
    const tableColumns = localStorage.getItem('productsTable');
    if (tableColumns) {
      setEditColumnData(JSON.parse(tableColumns));
    } else {
      setEditColumnData(initialProductsTableColumns);
    }
  }, []);

  const [getProductInput, setProductInput] = useState<getProductInputType>({
    sortBy: {
      fieldID: sortBy.id,
      type: sortBy.type
    },
    tenantId: localStorage.getItem('storeId'),
    productIds: [],
    filters: {
      searchText: '',
      variantTypeIDs: [],
      PriceRange: {
        min: null,
        max: null
      },
      categoryIds: [],
      vendorIDs: [],
      filterStatus: [],
      status: selectedTab === constVariables.AllProductsMenu.allProducts.toUpperCase() ? null : selectedTab,
      tags: [],
      collections: [],
      productTypes: []
    },
    pageInfo: {
      limitCount: 50,
      skipCount: 0
    },
    detailed: false
  });

  sortBy.catagory === 'Default' && delete getProductInput.sortBy;

  const [onGetProductDetails, { loading: isLoading }] = useLazyQuery(GET_PRODUCTS_V3, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      if (response?.getAllProductsV3) {
        const updatedProducts = response?.getAllProductsV3?.products?.map((product) => {
          return {
            ...product,
            productTagsJson: product?.productTagsJson ? product?.productTagsJson : [],
            productMediasJson: product?.productMediasJson ? product?.productMediasJson : [],
            productImages: product?.productMediasJson
              ? product?.productMediasJson.filter((mediaItem) => mediaItem.productMediaType === 'IMAGE')
              : [],
            productVideos: product?.productMediasJson
              ? product?.productMediasJson.filter((mediaItem) => mediaItem.productMediaType === 'VIDEO')
              : [],
            productCollectionJson: product?.productCollectionJson
              ? product?.productCollectionJson?.map((collection) => {
                  return { ...collection, disabled: collection?.type === 'AUTOMATIC' };
                })
              : [],
            isFreeShipping: product?.shippingPrice === 0 ? true : false,
            margin: product?.margin?.toFixed(2),
            collectionIds: product?.collectionIds ? product?.collectionIds?.map((collectionId) => parseInt(collectionId, 10)) : []
          };
        });
        setProductList(updatedProducts);
        setTotalProducts(response?.getAllProductsV3?.totalProducts);
      }
      if (!response?.getAllProductsV3) {
        setProductList([]);
      }
    },
    onError: (error) => {
      console.log('GET_PRODUCTS_V3 we get error', error);
    }
  });

  useEffect(() => {
    console.log('getProductInput.filters - ', getProductInput.filters);

    setProductInput({
      ...getProductInput,
      pageInfo: {
        ...getProductInput.pageInfo,
        skipCount: 0
      }
    });
  }, [getProductInput.filters.searchText, selectedTab]);

  const capitalizeFirstLetter = (letters: string) => {
    return letters.charAt(0).toUpperCase() + letters.slice(1).toLowerCase();
  };

  useEffect(() => {
    if (!_.isEqual(getProductInput, productInput.current)) {
      console.log('productInput.current is not equal - ');
      productInput.current = getProductInput;
      console.log('getProductInput we have - ', getProductInput);

      const processedInput = getProductFilterInput(getProductInput);

      onGetProductDetails({
        variables: {
          input: processedInput
          // state: Math.random()
        }
      });
    } else {
      console.log('productInput.current is equal - ');
    }
  }, [getProductInput]);

  useEffect(() => {
    if (history?.location?.state?.tab) {
      const tabName = history?.location?.state?.tab;
      const selectedTabName = capitalizeFirstLetter(tabName);
      const selectedItem = statusData.find((item) => item.name === selectedTabName);
      if (selectedItem) {
        // setProductFilters((productFilters) => {
        //   return { ...productFilters, filterStatus: [selectedItem] };
        // });
        setSelectedTabAndUrl(history?.location?.state?.tab?.toUpperCase());
      }
    }
  }, [history?.location?.state?.tab]);

  useEffect(() => {
    // if (selectedTab === constVariables.ProductsTab.active.toUpperCase() && refreshActiveList()) {
    //   refreshActiveList(false);
    // }
    // if (selectedTab === constVariables.ProductsTab.inactive.toUpperCase() && refreshInactiveList()) {
    //   refreshInactiveList(false);
    // }
    // if (selectedTab === constVariables.ProductsTab.archive.toUpperCase() && refreshArchivedList()) {
    //   refreshArchivedList(false);
    // }
    // if (selectedTab === constVariables.ProductsTab.draft.toUpperCase() && refreshDraftList()) {
    //   refreshDraftList(false);
    // }
    // if (selectedTab === constVariables.ProductsTab.allProducts.toUpperCase() && refreshAllProductList()) {
    //   refreshAllProductList(false);
    // }

    // if (selectedTab !== constVariables.ProductsTab.allProducts.toUpperCase()) {
    //   const selectedTabName = selectedTab === 'ARCHIVE' ? 'Archived' : capitalizeFirstLetter(selectedTab);
    //   const selectedItem = statusData.find((item) => item.name === selectedTabName);
    //   if (selectedItem) {
    //     setProductFilters((productFilters) => {
    //       return { ...productFilters, filterStatus: [selectedItem] };
    //     });
    //   }
    // }

    setProductInput((productInput) => {
      return {
        ...productInput,
        sortBy: {
          fieldID: sortBy.id,
          type: sortBy.type
        },
        filters: {
          ...productInput.filters,
          filterStatus: [],
          status: selectedTab === constVariables.AllProductsMenu.allProducts.toUpperCase() ? null : selectedTab
        }
      };
    });

    setSelectedProducts([]);

    const productTableContainer = document.getElementById('product-table-container');
    if (productTableContainer) {
      productTableContainer.scrollTo(0, 0);
    }
  }, [sortBy, selectedTab]);

  useEffect(() => {
    if (productFilters?.filterStatus && productFilters?.filterStatus?.length > 1) {
      setSelectedTabAndUrl('ALL PRODUCTS');
    }
    if (productFilters?.filterStatus?.length === 1) {
      setSelectedTabAndUrl(
        productFilters?.filterStatus[0]?.name === constVariables.ProductsTab.archived
          ? constVariables.ProductsTab.archive.toUpperCase()
          : productFilters?.filterStatus[0]?.name.toUpperCase()
      );
    }
  }, [productFilters.filterStatus]);

  const refreshList = () => {
    console.log('refreshList call');
    const processedInput = getProductFilterInput(getProductInput);
    console.log(processedInput);

    onGetProductDetails({
      variables: {
        input: processedInput
        // state: Math.random()
      }
    });

    setSelectedProducts([]);
    setReloadRapidAction(!reloadRapidAction);
    return null;
  };

  useEffect(() => {
    callList();
  }, []);

  const [manageRapidAction] = useMutation(MANAGE_RAPID_ACTION_CONFIG, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setToggles({
        // featured: false,
        featured: data.manageRapidActionConfig?.featured?.productAssignmentMode === 'MANUAL',
        flashSale: data.manageRapidActionConfig?.flashSale?.productAssignmentMode === 'AUTOMATIC'
      });
    }
  });

  const callList = () => {
    manageRapidAction({
      context: {
        headers: {
          Authorization: localStorage.getItem('token') || '',
          tenantid: envUrls.firebaseTenantId,
          'x-api-key': envUrls.authXApiKey
        }
      },
      variables: {
        input: {
          action: 'GET'
        }
        // state: Math.random()
      }
    });
  };

  return (
    <div className="width-animation w-100 p-40" id="tablePage">
      <PageHeader
        pageTitle={constVariables.AllProductsMenu.allProducts}
        addButton={role === UserRoles.FULFILMENT_ASSOCIATE ? '' : constVariables.AllProductsMenu.addProduct}
        onAddButton={() => history.push('/products/allProducts/add')}
        onlyImport={role === UserRoles.FULFILMENT_ASSOCIATE ? false : true}
        removeExportImport={true}
      />
      <FlexBox className="justify-content-between align-items-center mb-10">
        <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTabAndUrl} tabs={constVariables.TabForProduct} />
        <OutsideClickHandler
          onOutsideClick={() => {
            setOpenEditColumnDropdown(false);
          }}
        >
          <div className="position-relative">
            <div
              className="text-primary cursor-pointer text-btn pt-2"
              onClick={() => setOpenEditColumnDropdown(!openEditColumnDropdown)}
            >
              {constVariables.AllProductsMenu.editColumns}
            </div>
            <EditColumn
              show={openEditColumnDropdown ? openEditColumnDropdown : false}
              columns={editColumnData}
              setEditColumnData={setEditColumnData}
            />
          </div>
        </OutsideClickHandler>
      </FlexBox>
      <div className="card w-100 mb-0 p-0">
        <div className="p-x-32 p-t-32">
          {!isLoading && totalProducts === 0 && !getProductInput.filters.searchText && (
            <>
              {showFilterChips && (
                <ProductFilterChips
                  filters={productFilters}
                  setProductFilters={setProductFilters}
                  updateProductFilters={handleUpdateProductFilters}
                  handleClearProductFilter={handleClearProductFilter}
                />
              )}
              {selectedTab === constVariables.ProductsTab.allProducts.toUpperCase() ? (
                <EmptyList
                  title={constVariables.emptyDataList.addProducts}
                  description={constVariables.emptyDataList.addProductsDescription}
                  addButtonText={constVariables.emptyDataList.addProducts}
                  onAddButton={() => history.push('/products/allProducts/add')}
                  icon={EmptyDataIcon}
                />
              ) : (
                <EmptyList datatype="product" status={selectedTab} icon={EmptyDataIcon} />
              )}
            </>
          )}
          <ProductList
            toggles={toggles}
            productList={productList}
            setProductList={setProductList}
            setSortBy={setSortBy}
            sortBy={sortBy}
            refreshList={refreshList}
            showBulkEditorModal={showBulkEditorModal}
            setShowBulkEditorModal={setShowBulkEditorModal}
            editColumnData={editColumnData}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            isLoading={isLoading}
            setProductInput={setProductInput}
            getProductInput={getProductInput}
            totalCount={totalProducts}
            productFilters={productFilters}
            setProductFilters={setProductFilters}
            handleUpdateProductFilters={handleUpdateProductFilters}
            handleClearProductFilter={handleClearProductFilter}
            showFilterChips={showFilterChips}
            setShowFilterChips={setShowFilterChips}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTabAndUrl}
          />
        </div>
        <div className={clsx({ 'display-none': totalProducts === 0 && !getProductInput.filters.searchText })}>
          <Pagination
            skipCount={getProductInput.pageInfo.skipCount}
            limitCount={getProductInput.pageInfo.limitCount}
            setSkipCount={(num: number) => {
              setProductInput((prev) => ({
                ...prev,
                pageInfo: {
                  ...prev.pageInfo,
                  skipCount: num
                }
              }));
            }}
            total={totalProducts}
          />
        </div>
      </div>
    </div>
  );
};

export default Product;
