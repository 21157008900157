import React, { FC, useEffect, useState, createRef } from 'react';
import { Link } from 'react-router-dom';

// Apollo
import { useLazyQuery } from '@apollo/client';
import { GET_LIVE_VARIANT_ANALYTICS } from 'src/apollo/queries';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { Dropdown, FixedHeaderTable, Portal, Tooltip } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { RightArrowIcon, DefaultImage, GreenCheck, ThreeDotsIcon, UpArrowIcon, DownArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { CommonProductsTableProps } from './LiveProductsBlock.types';
import { TablesProps, RowObjectProps } from '../Table/Table.types';
import OutsideClickHandler from 'react-outside-click-handler';
import { SortableTableHeader } from '../../oraganisms/MoveLiveProductModal/MoveLiveProductModal';

const ActionMenu = ({ openModal }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const moreActionRef = createRef<any>();
  const [dropDownPositions, setDropDownPositions] = useState({
    top: 0,
    left: 0
  });

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const handleOpenMoreActionDropdown = () => {
    if (!showDropdown) {
      setShowDropdown(true);
      if (moreActionRef.current) {
        const position = moreActionRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - position.bottom;
        setDropDownPositions({
          top: spaceBelow < 180 ? window.scrollY + position.top - 146 : window.scrollY + position.top + 30,
          left: position.left - 135
        });
      }
    }
  };

  return (
    <>
      <div className="position-relative">
        <button onClick={handleOpenMoreActionDropdown} className="bg-transparent border border-transparent" ref={moreActionRef}>
          <KTSVG path={ThreeDotsIcon} svgClassName="cursor-pointer collapseFilter collapseIcon" />
        </button>
      </div>
      {showDropdown && (
        <Portal id="kt_body">
          <OutsideClickHandler
            onOutsideClick={() => {
              if (showDropdown) setShowDropdown(false);
            }}
          >
            <div className="position-absolute" style={{ top: dropDownPositions.top, left: dropDownPositions.left }}>
              <Dropdown
                data={constVariables.liveReportDropdownData}
                selected={showDropdown}
                onSelect={(data) => {
                  // TODO with data?
                  openModal();
                  closeDropdown();
                }}
                closeDropdown={closeDropdown}
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </>
  );
};

const LiveProductsTable: FC<CommonProductsTableProps> = ({
  productList,
  setProductList,
  isLoading,
  disabledIds,
  selectedProducts,
  selectProduct,
  isLoadingMore,
  searchText,
  onScroll,
  shouldShowVariants,
  isShowingOnModal,
  getSelectValue,
  openModal,
  sortType,
  setSortType,
  sortBy,
  setSortBy
}) => {
  const [showAllVariants, setShowAllVariants] = useState(false);
  const [showVariantsState, setShowVariantsState] = useState<string[]>([]);
  const [loadingVariantState, setLoadingVariantState] = useState<string[]>([]);

  const tableData: TablesProps = {
    isDragDisabled: true,
    headerClassName: 'liveshow-product-header bg-light',
    className: 'overflow-visible'
  };

  const scrollToRow = (rowId) => {
    console.log('scrollToRow', rowId);
    const rowElement = document.getElementById(`live-product-${rowId}`);
    if (rowElement) {
      rowElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const [getProductVariants] = useLazyQuery(GET_LIVE_VARIANT_ANALYTICS, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      if (!preFetched) {
        return;
      }
      const id = showVariantsState[showVariantsState.length - 1];
      const updatedProductList = productList.map((product) => {
        if (product.id === id) {
          return {
            ...product,
            variantAnalytics: res.getLiveVariantAnalytics
          };
        }
        return product;
      });
      setProductList(updatedProductList);
    },
    onError: (error) => {
      console.log('error CommonProductsTable ::', error);
    }
  });

  tableData.headers = [
    {
      id: 1,
      className: 'align-middle w-60px table-header-background ps-5 pe-2 py-0',
      colName: (
        <div className="form-check form-check-sm form-check-custom">
          <input
            name="liveshwo-addproduct-table"
            className="form-check-input widget-9-check"
            type="checkbox"
            onClick={(e) => {
              selectProduct(e, 'All');
            }}
            onChange={(e) => {
              selectProduct(e, 'All');
            }}
          />
        </div>
      )
    },
    {
      id: 2,
      className: `align-middle w-60px table-header-background px-2 py-0 
      ${!shouldShowVariants ? 'd-none' : ''}
      `,
      colName: ''
    },
    {
      id: 3,
      className: 'align-middle w-300px table-header-background px-2 py-0',
      colName: (
        <SortableTableHeader
          label={'Product'}
          sortBy={sortBy}
          type={sortType}
          sortHeader={() => {
            if (sortBy !== 'Product') {
              setSortType('ASC');
            } else {
              if (sortType === 'ASC') {
                return setSortType('DESC');
              }
              setSortType('ASC');
            }
            setSortBy('Product');
          }}
        />
      )
    },
    {
      id: 4,
      className: `align-middle table-header-background px-2 py-0 ${isShowingOnModal ? 'minMidSize' : ''}`,
      colName: (
        <SortableTableHeader
          label={'Likes'}
          sortBy={sortBy}
          type={sortType}
          sortHeader={() => {
            if (sortBy !== 'Likes') {
              setSortType('ASC');
            } else {
              if (sortType === 'ASC') {
                return setSortType('DESC');
              }
              setSortType('ASC');
            }
            setSortBy('Likes');
          }}
        />
      )
    },
    {
      id: 5,
      className: `align-middle table-header-background px-2 py-0 ${isShowingOnModal ? 'minBoxSize' : ''}`,
      colName: (
        <SortableTableHeader
          label={'CTR'}
          sortBy={sortBy}
          type={sortType}
          sortHeader={() => {
            if (sortBy !== 'CTR') {
              setSortType('ASC');
            } else {
              if (sortType === 'ASC') {
                return setSortType('DESC');
              }
              setSortType('ASC');
            }
            setSortBy('CTR');
          }}
        />
      )
    },
    {
      id: 6,
      className: `align-middle table-header-background px-2 py-0 ${isShowingOnModal ? 'minBoxSize' : ''}`,
      colName: (
        <SortableTableHeader
          label={'Orders'}
          sortBy={sortBy}
          type={sortType}
          sortHeader={() => {
            if (sortBy !== 'Orders') {
              setSortType('ASC');
            } else {
              if (sortType === 'ASC') {
                return setSortType('DESC');
              }
              setSortType('ASC');
            }
            setSortBy('Orders');
          }}
        />
      )
    },
    {
      id: 7,
      className: `align-middle table-header-background px-2 py-0 ${isShowingOnModal ? 'minBoxSize' : ''}`,
      colName: (
        <SortableTableHeader
          label={'Price'}
          sortBy={sortBy}
          type={sortType}
          sortHeader={() => {
            if (sortBy !== 'Price') {
              setSortType('ASC');
            } else {
              if (sortType === 'ASC') {
                return setSortType('DESC');
              }
              setSortType('ASC');
            }
            setSortBy('Price');
          }}
        />
      )
    },
    {
      id: 8,
      className: `align-middle table-header-background px-2 py-0 ${isShowingOnModal ? 'minBoxSize' : ''}`,
      colName: (
        <SortableTableHeader
          label={'Revenue'}
          sortBy={sortBy}
          type={sortType}
          sortHeader={() => {
            if (sortBy !== 'Revenue') {
              setSortType('ASC');
            } else {
              if (sortType === 'ASC') {
                return setSortType('DESC');
              }
              setSortType('ASC');
            }
            setSortBy('Revenue');
          }}
        />
      )
    },
    {
      id: 9,
      className: `align-middle table-header-background live-header-action`,
      colName: ''
    }
  ];

  const arr: Array<RowObjectProps> = [];
  productList?.map((product, index) => {
    arr.push({
      className: `position-relative h-fit-content table-row cursor-pointer`,
      id: product.id,
      cells: [
        {
          className: `align-middle w-60px ps-5 pe-2 py-0 ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <div className="form-check form-check-sm form-check-custom">
              <input
                name="liveshwo-addproduct-cell"
                className="form-check-input widget-9-check"
                type="checkbox"
                disabled={disabledIds && disabledIds.includes(product.productId)}
                checked={selectedProducts.includes(product.productId) || (disabledIds && disabledIds.includes(product.productId))}
                onClick={(e) => {
                  selectProduct(e, product);
                }}
                onChange={(e) => {
                  selectProduct(e, product);
                }}
              />
            </div>
          )
        },
        {
          className: `align-middle w-60px px-2 py-0 ${index !== 0 ? 'table-top-border' : 'border-0'} 
          ${!shouldShowVariants ? 'd-none' : ''}
          `,
          value: product?.hasVariants && (
            <div
              className="cursor-pointer"
              onClick={() => {
                expandVariants(product.id);
              }}
            >
              <KTSVG
                path={RightArrowIcon}
                className={`m-auto-0 expand-icon ${
                  showVariantsState.findIndex((data) => data === product.id) > -1 || showAllVariants ? 'rotate-icon' : ''
                }`}
              />
            </div>
          )
        },
        {
          className: `align-middle w-300px text-muted px-2 py-0 ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <FlexBox className="align-items-center">
              <div className="symbol me-5">
                <Img
                  src={setImageSrc(product?.imageUrl ? generateCompressedImageURL(product?.imageUrl, '50') : DefaultImage)}
                  placeholderImg={DefaultImage}
                  errorImg={DefaultImage}
                  className="object-fit-scale-down bg-white border border-light border-2 commonProductTable"
                />
              </div>
              <FlexBox className="justify-content-start  flex-row align-items-center">
                <Link to={`/products/allProducts/edit/${product.productId}`} className="main-cell" target="_blank">
                  <span className="d-block list-product-name">{product.title}</span>
                </Link>
                {product.isDisplayed && (
                  <img
                    data-tip
                    data-for="checkboxTooltip"
                    src={GreenCheck}
                    alt="GreenCheck"
                    style={{
                      width: '13px',
                      height: '13px',
                      marginLeft: '6px'
                    }}
                  />
                )}
                <Tooltip
                  tooltipId="checkboxTooltip"
                  place="top"
                  content={() => {
                    return <>Product displayed on Liveshow</>;
                  }}
                />
              </FlexBox>
            </FlexBox>
          )
        },
        {
          className: `align-middle text-muted px-4 py-0 ${index !== 0 ? 'table-top-border' : 'border-0'} ${
            isShowingOnModal ? 'minMidSize' : ''
          }`,
          value: (
            <div
              className="d-flex"
              style={{
                flexDirection: 'column'
              }}
            >
              <div>{getSelectValue(product?.impressions)}</div>
              {/*<div*/}
              {/*  style={{*/}
              {/*    fontSize: '0.85rem'*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {product?.variants && product?.variants?.length > 0 && `${product?.variants?.length} variants`}*/}
              {/*</div>*/}
            </div>
          )
        },
        {
          className: `align-middle text-muted px-4 py-0 ${index !== 0 ? 'table-top-border' : 'border-0'} ${
            isShowingOnModal ? 'minBoxSize' : ''
          }`,
          value: `${getSelectValue(product?.ctr)}%`
        },
        {
          className: `align-middle text-muted px-4 py-0 ${index !== 0 ? 'table-top-border' : 'border-0'} ${
            isShowingOnModal ? 'minBoxSize' : ''
          }`,
          value: `${getSelectValue(product?.orders)}`
        },
        {
          className: `align-middle text-muted px-4 py-0 ${index !== 0 ? 'table-top-border' : 'border-0'} ${
            isShowingOnModal ? 'minBoxSize' : ''
          }`,
          value: `${getSelectValue(product?.price)}`
        },
        {
          className: `align-middle text-muted px-4 py-0 ${index !== 0 ? 'table-top-border' : 'border-0'} ${
            isShowingOnModal ? 'minBoxSize' : ''
          }`,
          value: `${getSelectValue(product?.revenue)}`
        },
        {
          className: `align-middle text-muted px-2 py-0 live-header-action ${index !== 0 ? 'table-top-border' : 'border-0'} ${
            isShowingOnModal ? 'minBoxSize' : ''
          }`,
          value: <ActionMenu openModal={() => openModal(product?.productId)} />
        }
      ],
      showVariantAnalytics: showVariantsState,
      showAllVariantAnalytics: showAllVariants,
      loadingVariantState,
      analyticDetail: {
        product,
        selectProduct,
        getSelectValue
      }
    });
    return null;
  });

  tableData.rows = arr;

  const expandVariants = (productId) => {
    const tempArr = [...showVariantsState];
    if (showVariantsState.findIndex((data) => data === productId) > -1) {
      const index = tempArr.indexOf(productId);

      if (index > -1) {
        tempArr.splice(index, 1);
      }

      setShowVariantsState(tempArr);
      setShowAllVariants(false);
    } else {
      setShowVariantsState([...showVariantsState, productId]);
      setShowAllVariants(false);

      const targetProduct = productList.find((product) => product.id === productId);
      if (targetProduct && targetProduct.variantAnalytics) {
        setTimeout(() => {
          scrollToRow(productId);
        }, 100);
        return;
      }

      setLoadingVariantState((prevState) => [...prevState, productId]);
      getProductVariants({
        variables: {
          id: productId
        }
      }).then((res) => {
        setLoadingVariantState((prevState) => prevState.filter((id) => id !== productId));
        setTimeout(() => {
          scrollToRow(productId);
        }, 200);
      });
    }
  };

  const [preFetched, setPreFetched] = useState(false);
  useEffect(() => {
    if (preFetched) {
      return;
    }
    if (!isLoading && productList.length > 0) {
      setPreFetched(true);
      getProductVariants({
        variables: {
          id: productList[0].id
        }
      });
    }
  }, [isLoading, productList, preFetched]);
  return (
    <FixedHeaderTable
      headers={tableData.headers}
      rows={tableData.rows}
      type="Live Product"
      loadingPosition={'center'}
      searchText={searchText}
      isLoading={isLoading}
      onScroll={onScroll}
      noMargin
    />
  );
};

export default LiveProductsTable;
