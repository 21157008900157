/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';

// Apollo
import { useMutation, useQuery } from '@apollo/client';
import { GET_LIVE_SHOW_PRODUCTS } from 'src/apollo/queries';
import { ADD_PRODUCT_TO_LIVE_SHOW, DELETE_PRODUCT_IN_SHOW } from 'src/apollo/mutations';

// Components
import { FlexBox, Loader } from 'src/components/atoms';
import { CustomModal, AddingProductsModal } from 'src/components/oraganisms';
import { Tooltip } from 'src/components/molecules';
import LiveshowProductLineItem from './LiveshowProductLineItem';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';
import { KTSVG } from 'src/helpers';

// Icons
import { RedExclaimationIcon, NoProductInList, LeftArrowIcon } from 'src/assets/icons';

// Types
import { ILiveProduct } from 'src/components/pages/liveshow/LiveshowManager/LiveshowManager.types';

// Styles
import './_liveshowProductList.scss';

interface LiveshowProductListProps {
  selectedProduct: any;
  setSelectedProduct: React.Dispatch<React.SetStateAction<any>>;
  liveshowId: string;
  productsList: any[];
  lowStockProductsList: any[];
  visitedProductIds: number[];
  disabledIds: number[];
  onIncommingProductData: (prds: ILiveProduct[]) => void;
}

const PARENT_ID = 'LiveProductList';
const LiveshowProductList: React.FC<LiveshowProductListProps> = ({
  selectedProduct,
  setSelectedProduct,
  liveshowId,
  productsList,
  onIncommingProductData,
  visitedProductIds,
  disabledIds,
  lowStockProductsList
}) => {
  const { showToast } = useToast();

  const [isLowStockMode, setIsLowStockMode] = useState(false);
  const [showAddProductModal, setShowAddProductModal] = useState(false);

  const { loading: isLoadingProducts, data } = useQuery(GET_LIVE_SHOW_PRODUCTS, {
    variables: {
      id: liveshowId
    },
    skip: !liveshowId,
    onError: (error: any) => {
      showToast({
        errorText: error.message,
        message: `Error occured while get live show products: ${error.message}`
      });
    }
  });

  useEffect(() => {
    if (data?.getLiveShowProducts) {
      onIncommingProductData(data?.getLiveShowProducts);
    }
  }, [data]);

  const [addProductToShow, { loading: isAddingProduct }] = useMutation(ADD_PRODUCT_TO_LIVE_SHOW, {
    onCompleted: (response) => {
      if (response) {
        handleCloseAddingProductsModal();
        showToast({ successText: 'Product added successfully', message: `The Product has been added sucessfully` });
      }
    },
    onError: (error) => {
      handleCloseAddingProductsModal();
      showToast({
        errorText: error.message,
        message: `Error occured while adding Product: ${error.message}`
      });
    }
  });

  const [deleteProductInShow] = useMutation(DELETE_PRODUCT_IN_SHOW, {
    onCompleted: (response) => {
      if (response) {
        // getLiveShowProducts({
        //   variables: {
        //     id: liveshowId
        //   }
        // });
        handleCloseAddingProductsModal();
        showToast({ successText: 'Product deleted successfully', message: `The Product has been deleted sucessfully` });
      }
    },
    onError: (error) => {
      handleCloseAddingProductsModal();
      showToast({
        errorText: error.message,
        message: `Error occured while delete Product in show: ${error.message}`
      });
    }
  });
  const handleDeleteProduct = (productId: number) => {
    deleteProductInShow({
      variables: {
        input: {
          liveShowId: liveshowId,
          liveProductId: productId
        }
      }
    });
  };

  const handleAddProducts = async (productIds) => {
    if (productIds?.length === 0) return;
    addProductToShow({
      variables: {
        input: {
          liveShowId: liveshowId,
          productIds
        }
      }
    });
  };

  const handleCloseAddingProductsModal = () => {
    document.body.style.overflow = 'unset';
    setShowAddProductModal(false);
  };

  const showLoadingProducts = useMemo(() => !liveshowId || isLoadingProducts, [liveshowId, isLoadingProducts]);

  return (
    <div className="w-100">
      <FlexBox className="align-items-center justify-content-between m-b-16">
        <div className="d-flex align-items-center">
          {isLowStockMode && (
            <div className="ms-2 cursor-pointer" onClick={() => setIsLowStockMode(false)}>
              <KTSVG path={LeftArrowIcon} className="svg-icon svg-icon-x" />
            </div>
          )}
          <h4 className="liveshow-section-subtitle">{isLowStockMode ? 'Low Stock Inventory' : 'Products list'}</h4>
          {!isLowStockMode && lowStockProductsList.length > 0 && (
            <>
              <img
                src={RedExclaimationIcon}
                className="ms-2 cursor-pointer"
                data-tip
                data-for="RedExclaimationIcon"
                onClick={() => setIsLowStockMode(true)}
              />
              <Tooltip
                tooltipId="RedExclaimationIcon"
                place="top"
                content={() => {
                  return <>Low stock alert</>;
                }}
              />
            </>
          )}
        </div>
        {productsList?.length > 0 && (
          <button className="btn btn-primary btn-xs" onClick={() => setShowAddProductModal(true)}>
            Add Product
          </button>
        )}
      </FlexBox>
      <div className="live-product-list-container">
        {productsList?.length > 0 ? (
          <table id={PARENT_ID} className="w-100">
            <thead className="product-list-table-header align-items-center w-100 position-sticky">
              <tr>
                <th className="th-product-main">Products</th>
                <th>Stock</th>
                <th>Order</th>
                <th>Paid</th>
                <th>Revenue</th>
              </tr>
            </thead>
            <tbody>
              {isLowStockMode
                ? lowStockProductsList?.map((product: any, index) => {
                    return (
                      <LiveshowProductLineItem
                        key={`${product.id + index}`}
                        product={product}
                        setSelectedProduct={setSelectedProduct}
                        selectedProduct={selectedProduct}
                        handleDeleteProduct={handleDeleteProduct}
                        visitedProductIds={visitedProductIds}
                      />
                    );
                  })
                : productsList?.map((product: any) => {
                    return (
                      <LiveshowProductLineItem
                        key={product.id}
                        product={product}
                        setSelectedProduct={setSelectedProduct}
                        selectedProduct={selectedProduct}
                        handleDeleteProduct={handleDeleteProduct}
                        visitedProductIds={visitedProductIds}
                      />
                    );
                  })}
            </tbody>
          </table>
        ) : (
          <FlexBox className="empty-product-list flex-column justify-content-center align-items-center">
            {showLoadingProducts && <Loader type="" className={'m-0'} />}
            {!showLoadingProducts && <img src={NoProductInList} alt="no product in list" />}
            {!showLoadingProducts && (
              <>
                <span className="text-center">Add Products</span>
                <h6 className="no-select-text-product-in-list text-center">Add products to liveshow</h6>
                <button className="btn btn-primary btn-xs" onClick={() => setShowAddProductModal(true)}>
                  Add Products
                </button>
              </>
            )}
          </FlexBox>
        )}
      </div>
      {showAddProductModal && (
        <CustomModal
          bodyClassname="d-flex justify-content-center w-90 w-md-150"
          show={showAddProductModal}
          closeModal={handleCloseAddingProductsModal}
        >
          <AddingProductsModal
            closeModal={handleCloseAddingProductsModal}
            shouldShowVariants={true}
            handleAddProducts={handleAddProducts}
            disabledIds={disabledIds}
            isAddingProduct={isAddingProduct}
            isShowOnlyActiveProducts={true}
          />
        </CustomModal>
      )}
    </div>
  );
};

export default LiveshowProductList;
