import { useState, useEffect, useRef, FC } from 'react';
import { debounce } from 'lodash';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useLazyQuery } from '@apollo/client';
import { GET_PRODUCTS_V3 } from 'src/apollo/queries';

// Components
import { FlexBox } from 'src/components/atoms';
import { SearchBar, CatalogSortDropdown } from 'src/components/molecules';
import { EmptyList } from 'src/components/oraganisms';
import CommonProductsTable from './CommonProductsTable';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { EmptyDataIcon, SortIcon, DownArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';
import { SortIds } from './data';

// Types
import { CommonProductsBlockProps, queryResult } from './CommonProductsBlock.types';
import { ProductDetailType } from 'src/components/oraganisms/BulkEditorModal/BulkEditorModal.types';
import { getProductInputType } from 'src/components/pages/products/ProductPage/productPage.types';
import { getProductFilterInput } from 'src/utils/convertProductAPIInput';

const _ = require('lodash');

const CommonProductsBlock: FC<CommonProductsBlockProps> = ({
  selectedProductIDs,
  handleUpdateSelectedProductIDs,
  shouldShowVariants,
  isShowingOnModal,
  disabledIds,
  isLiveshow,
  isShowOnlyActiveProducts,
  handleAddProductsToLivePreview,
  isAddingToTiktok
}) => {
  const productInput = useRef({});
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [totalProducts, setTotalProducts] = useState(0);
  const [productList, setProductList] = useState<ProductDetailType[]>([]);
  // const [openSortDropdown, setOpenSortDropdown] = useState(false);
  // const [sortBy, setSortby] = useState({
  //   column: 'NAME',
  //   order: 'ASC',
  //   id: 619,
  //   displayName: 'Product'
  // });

  // const getSortColumnId = (column: string): number => {
  //   switch (column) {
  //     case 'NAME':
  //       return 21;
  //     case 'QUANTITY':
  //       return 22;
  //     case 'VENDOR':
  //       return 24;
  //     case 'PRICE':
  //       return 28;
  //     case 'STATUS':
  //       return 25;
  //     default:
  //       return 21;
  //   }
  // };

  const [getProductInput, setProductInput] = useState<getProductInputType>({
    // sortBy: {
    //   fieldID: getSortColumnId(sortBy.column),
    //   type: sortBy.order
    // },
    productIds: [],
    filters: {
      searchText: '',
      variantTypeIDs: [],
      PriceRange: {
        min: null,
        max: null
      },
      categoryIds: [],
      vendorIDs: [],
      status: isShowOnlyActiveProducts ? 'ACTIVE' : null,
      tags: [],
      collections: [],
      filterStatus: [],
      productTypes: []
    },
    pageInfo: {
      limitCount: 60,
      skipCount: 0
    }
  });

  useEffect(() => {
    if (!_.isEqual(getProductInput, productInput.current)) {
      productInput.current = getProductInput;
      const processedInput = getProductFilterInput(getProductInput);
      onGetProductsList({
        variables: {
          input: processedInput
        }
      });
    } else {
      console.log('productInput.current is equal - ');
    }
  }, [getProductInput]);

  const [onGetProductsList, { data: products, fetchMore, loading: isLoading }] = useLazyQuery(GET_PRODUCTS_V3, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      if (response?.getAllProductsV3) {
        if (isAddingToTiktok) {
          const tiktokProducts = response?.getAllProductsV3?.products?.filter((product) => !product.tiktokSync);
          setProductList(tiktokProducts);
        } else {
          setProductList(response?.getAllProductsV3?.products);
        }
        setTotalProducts(response?.getAllProductsV3?.totalProducts);
        if (totalProducts === response?.getAllProductsV3?.products?.length) {
          setIsLoadingMore(false);
        }
      }
    },
    onError: (error) => {
      setIsLoadingMore(false);
    }
  });

  const handleSearch = (value) => {
    setProductInput((getProductInput) => {
      return {
        ...getProductInput,
        filters: {
          ...getProductInput.filters,
          searchText: value
        }
      };
    });
  };

  const selectProduct = (e: any, product?: any) => {
    if (!isLiveshow) {
      // Return only product ids
      if (product === 'All') {
        const tempArr: Array<number> = productList.map((product) => product.productId);
        if (!e.target.checked) {
          handleUpdateSelectedProductIDs([]);
        } else {
          handleUpdateSelectedProductIDs(tempArr);
        }
      } else if (e.target.checked) {
        handleUpdateSelectedProductIDs([...selectedProductIDs, product.productId]);
      } else {
        const arr = selectedProductIDs;
        arr.forEach((item, index) => {
          if (item === product.productId) arr.splice(index, 1);
        });
        handleUpdateSelectedProductIDs([...arr]);
      }
    } else {
      // Return products array
      if (product === 'All') {
        if (!e.target.checked) {
          handleUpdateSelectedProductIDs([]);
        } else {
          const updatedProducts = productList.map((product) => {
            return {
              productId: product?.productId,
              productName: product?.productName,
              productImg: product?.productMediasJson?.[0]?.productMediaUrl,
              productPrice: product?.salePrice
            };
          });
          handleUpdateSelectedProductIDs(updatedProducts);
        }
      } else if (e.target.checked) {
        const selectedProducts = productList.filter((product) => selectedProductIDs.includes(product.productId));
        const updatedProducts = [...selectedProducts, product].map((product) => {
          return {
            productId: product?.productId,
            productName: product?.productName,
            productImg: product?.productMediasJson?.[0]?.productMediaUrl,
            productPrice: product?.salePrice
          };
        });
        handleUpdateSelectedProductIDs(updatedProducts);
      } else {
        const arr = selectedProductIDs;
        arr.forEach((item, index) => {
          if (item === product.productId) arr.splice(index, 1);
        });
        const updatedProducts = productList
          .filter((product) => arr.includes(product.productId))
          .map((product) => {
            return {
              productId: product?.productId,
              productName: product?.productName,
              productImg: product?.productMediasJson?.[0]?.productMediaUrl,
              productPrice: product?.salePrice
            };
          });
        handleUpdateSelectedProductIDs(updatedProducts);
      }
    }
  };

  const handleAddProductsToLiveshowPreview = (e: any, product: any) => {
    handleAddProductsToLivePreview && handleAddProductsToLivePreview([product]);
  };

  const debouncedScroll = debounce(async (event) => {
    const scrollPosition = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const offsetHeight = event.target.offsetHeight;
    const skipCount = products?.getAllProductsV3?.products?.length;

    if (isLoading) return;

    if (scrollPosition + offsetHeight > scrollHeight - 20) {
      if (totalProducts <= products?.getAllProductsV3?.products?.length) {
        setIsLoadingMore(false);
        return;
      }
      setIsLoadingMore(true);
      await fetchMore({
        variables: {
          input: {
            ...getProductInput,
            pageInfo: {
              ...getProductInput.pageInfo,
              skipCount
            }
          }
        },
        updateQuery: (previousQueryResult: queryResult, { fetchMoreResult }) => {
          if (fetchMoreResult?.getAllProductsV3?.products.length === 0) {
            return previousQueryResult;
          }

          let products;
          if (isAddingToTiktok) {
            products = [
              ...previousQueryResult.getAllProductsV3?.products?.filter((product) => !product?.tiktokSync),
              ...fetchMoreResult?.getAllProductsV3?.products?.filter((product) => !product?.tiktokSync)
            ];
          } else {
            products = [...previousQueryResult.getAllProductsV3?.products, ...fetchMoreResult?.getAllProductsV3?.products];
          }

          return {
            getAllProductsV3: {
              totalProducts: fetchMoreResult?.getAllProductsV3?.totalProducts,
              products
            }
          };
        }
      });
    }
  }, 1000);

  const handleTableScroll = (event: React.UIEvent<HTMLDivElement>) => {
    debouncedScroll(event);
  };

  // const ChangeSortBy = (field: string, id: number, displayName: string) => {
  //   if (sortBy.column === field) {
  //     if (sortBy.order === 'DESC') {
  //       setSortby({ ...sortBy, order: 'ASC' });
  //     } else {
  //       setSortby({ ...sortBy, order: 'DESC' });
  //     }
  //   } else {
  //     setSortby({ column: field, id, displayName, order: 'DESC' });
  //   }
  //   setOpenSortDropdown(false);
  // };

  return (
    <div>
      <FlexBox className="justify-content-between w-auto search-bar-container m-b-32">
        <SearchBar placeholder="Search for products" onChange={handleSearch} />
        {/* <div className="position-relative ms-3">
          <OutsideClickHandler
            onOutsideClick={() => {
              if (openSortDropdown) setOpenSortDropdown(false);
            }}
          >
            <button
              className={`btn btn-sm btn-flex btn-icon-text align-items-center ${
                openSortDropdown ? 'btn-primary' : 'btn-active-primary'
              }`}
              onClick={() => {
                setOpenSortDropdown(!openSortDropdown);
              }}
            >
              <KTSVG path={SortIcon} className="me-1" />
              <span className="my-auto me-0">
                Sort:
                <span className="mx-2">{sortBy.displayName}</span>
                <KTSVG path={DownArrowIcon} className="mx-1" />
              </span>
            </button>
            <CatalogSortDropdown data={SortIds} value={sortBy} onSelect={ChangeSortBy} selected={openSortDropdown} />
          </OutsideClickHandler>
        </div> */}
      </FlexBox>
      <div>
        {/*{!isLoading && totalProducts === 0 && !getProductInput.filters.searchText && (*/}
        {/*  <>*/}
        {/*    <EmptyList*/}
        {/*      title={constVariables.emptyDataList.addProducts}*/}
        {/*      description={constVariables.emptyDataList.addProductsDescription}*/}
        {/*      addButtonText={constVariables.emptyDataList.addProducts}*/}
        {/*      icon={EmptyDataIcon}*/}
        {/*      // onAddButton={() => refreshList()}*/}
        {/*    />*/}
        {/*  </>*/}
        {/*)}*/}
        <CommonProductsTable
          productList={productList}
          setProductList={setProductList}
          isLoading={isLoading}
          selectProduct={selectProduct}
          selectedProducts={selectedProductIDs}
          disabledIds={disabledIds}
          isLoadingMore={isLoadingMore}
          searchText={getProductInput.filters.searchText}
          onScroll={handleTableScroll}
          shouldShowVariants={shouldShowVariants}
          isShowingOnModal={isShowingOnModal}
          isLiveshow={isLiveshow}
          handleAddProductsToLivePreview={handleAddProductsToLiveshowPreview}
        />
      </div>
    </div>
  );
};

export default CommonProductsBlock;
