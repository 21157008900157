import { FC, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { DropdownSelector } from 'src/components/atoms';
import { Dropdown } from 'src/components/molecules';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

// Types
import { FeaturedProductsStatusTypeSection } from 'src/components/pages/featured-products/FeaturedProducts.types';

const FeaturedProductsStatus: FC<FeaturedProductsStatusTypeSection> = ({
  featuredProductsStatus,
  setFeaturedProductsStatus,
  setFormChanges,
  selectedProductAssignmentMethod
}) => {
  const [isCatalogDropdown, setIsCatalogDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState('Select');
  useEffect(() => {
    if (featuredProductsStatus.value === 'ACTIVE') {
      setSelectedValue('Active');
    } else if (featuredProductsStatus.value === 'INACTIVE') {
      setSelectedValue('Inactive');
    } else if (featuredProductsStatus.value === 'SCHEDULED') {
      setSelectedValue('Scheduled');
    } else {
      setSelectedValue('Select');
    }
  }, [featuredProductsStatus]);

  const openWarehouseDropdown = () => {
    setIsCatalogDropdown(true);
  };
  const closeWarehouseDropdown = () => {
    setIsCatalogDropdown(false);
  };

  const selectWarehouse = (data: { id: number; name: string }) => {
    closeWarehouseDropdown();
    if (data.name.toUpperCase() === 'ACTIVE') {
      setFeaturedProductsStatus({ value: 'ACTIVE' });
    } else if (data.name.toUpperCase() === 'INACTIVE') {
      setFeaturedProductsStatus({ value: 'INACTIVE' });
    } else if (data.name.toUpperCase() === 'SCHEDULED') {
      setFeaturedProductsStatus({ value: 'SCHEDULED' });
    }
    setFormChanges(true);
  };

  return (
    <div className="card w-100 mb-0">
      <h4 className="section-title m-b-32">{constVariables.FeaturedProducts.status.featuredListStatus}</h4>
      <div className="input-title">{constVariables.FeaturedProducts.status.featuredListStatusTwo}</div>
      <div className="position-relative">
        <DropdownSelector
          className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box ${
            isCatalogDropdown ? 'dropdown-box-active' : ''
          }`}
          onClick={openWarehouseDropdown}
          selectedValue={selectedValue}
          text="Select"
          isShowColor={true}
        />
        <OutsideClickHandler onOutsideClick={closeWarehouseDropdown}>
          <Dropdown
            className="dropdown-custom-width mt-1"
            data={constVariables.featuredProductsStatusDropDownData}
            selected={isCatalogDropdown}
            onSelect={selectWarehouse}
            value={selectedValue}
            closeDropdown={() => setIsCatalogDropdown(false)}
          />
        </OutsideClickHandler>
      </div>
      <div className="info_radiobutton text-muted poppins-regular fs-8 m-t-8">
        {constVariables.FeaturedProducts.status.statusDesc}
      </div>
    </div>
  );
};

export default FeaturedProductsStatus;
