import React, { FC, useState, useEffect, createRef, useMemo, useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import clsx from 'clsx';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useMutation } from '@apollo/client';
import { UPDATE_INVENTORY_STOCK_LEVEL } from 'src/apollo/mutations';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { FlexBox } from 'src/components/atoms';
import {
  EditInventoryCommonSubtract,
  EditInventoryStockRecived,
  EditInventoryRecount,
  Portal,
  FloatingInventoryBoxLayout
} from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers/components/KTSVG';
import { useToast } from 'src/utils/hooks/useToast';

// Icons
import { HollowPencilIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { InventoryStockLevelProps } from '../InventoryList.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';
import { InventoryStockLevelTypes } from '../InventoryList.types';
import { GET_PRODUCTS_V3 } from 'src/apollo/queries';

const PARENT_ID = 'dataTableContent';
const InventoryStockLevel: FC<InventoryStockLevelProps> = ({
  type,
  id,
  productId,
  locationId,
  quantity,
  showEditIcon,
  selectedWarehouseId,
  updateInventoryList
}) => {
  const { showToast } = useToast();
  const stockLevelMenuRef = createRef<any>();

  const [floatingValue, setFloatingValue] = useState<string>('');
  const [floatingTotal, setFloatingTotal] = useState(0);

  const [disableFloatingSaveButton, setDisableFloatingSaveButton] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [levelType, setLevelType] = useState('GENERAL');

  const [isUpDirectionMenu, setIsUpDirectionMenu] = useState(false);
  const [positionStockLevelMenus, setPositionStockLevelMenus] = useState({
    top: 0,
    left: 0
  });

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const initialMenu = () => {
    setShowMenu(false);
    setLevelType('GENERAL');
  };

  useEffect(() => {
    if (quantity) {
      setFloatingTotal(quantity);
    }
  }, [quantity]);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      initialMenu();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  useEffect(() => {
    if (showMenu) {
      setFloatingValue(quantity.toString());
    }
  }, [showMenu]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [showMenu]);

  const universalBackButton = () => {
    setPositionStockLevelMenus((positionStockLevelMenus) => {
      return {
        ...positionStockLevelMenus,
        top: isUpDirectionMenu ? positionStockLevelMenus.top - 194 : positionStockLevelMenus.top
      };
    });
    setShowMenu(true);
    setLevelType('GENERAL');
  };

  const isEditButtonClicked = useMemo(() => {
    if (showMenu) {
      return true;
    }
    return false;
  }, [showMenu]);

  const handleOpenMainMenu = () => {
    const parentElement = document.getElementById(PARENT_ID);
    if (stockLevelMenuRef.current && parentElement) {
      setShowMenu(true);
      const position = stockLevelMenuRef.current.getBoundingClientRect();
      const parentRect = parentElement.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setIsUpDirectionMenu(spaceBelow < 387 ? true : false);
      setPositionStockLevelMenus({
        top: position.top - parentRect.top + 40,
        left: position.left - parentRect.left - 190
      });
    }
  };

  const opneStockComponents = (key: string, id: number, total: number) => {
    setShowMenu(true);
    setLevelType(key);
    setPositionStockLevelMenus((positionStockLevelMenus) => {
      return {
        ...positionStockLevelMenus,
        top: isUpDirectionMenu ? positionStockLevelMenus.top + 140 : positionStockLevelMenus.top
      };
    });
    const holder: any = '1';
    setFloatingValue(key === InventoryStockLevelTypes.INVENTORY_RECOUNT ? total : holder);
    setFloatingTotal(total);
  };

  const [updateInventoryStockMut] = useMutation(UPDATE_INVENTORY_STOCK_LEVEL, {
    onCompleted(response) {},
    onError(err) {
      setDisableFloatingSaveButton(false);
      showToast({
        errorText: err.message === 'LOCATION_NOT_FOUND' ? 'Please add the location to the inventory first.' : err.message,
        message: `Error occured while updating stock level: ${err.message}`
      });
    },
    refetchQueries: [
      {
        query: GET_PRODUCTS_V3,
        variables: {
          input: {
            // productIds: [productId],
            warehouseIds: selectedWarehouseId ? `[${selectedWarehouseId.toString()}]` : undefined,
            productIdsString: JSON.stringify([productId]),
            detailed: true,
            filters: {
              // locationIds: []
              locationIdsString: '[]'
            }
          }
        }
      }
    ]
  });

  const updateInventoryStockPath = (id: number, type: string, locationId: number, quantity: number, adjustmentType: string) => {
    // if (locationId === undefined) {
    //   showToast({
    //     errorText: 'Please add the location to the inventory first.',
    //     message: `Please add the location to the inventory first.`
    //   });
    //   return;
    // }
    updateInventoryStockMut({
      variables: {
        input: {
          productId,
          warehouseId: selectedWarehouseId,
          locationId,
          quantity,
          adjustmentType,
          variantId: type === 'PRODUCT' ? undefined : id
        }
      }
    });

    updateInventoryList &&
      updateInventoryList(
        {
          id: productId,
          productId,
          warehouseId: selectedWarehouseId,
          locationId,
          quantity,
          adjustmentType,
          type: 'PRODUCT',
          variantId: type === 'PRODUCT' ? undefined : id
        },
        type === 'PRODUCT' ? 'productQty' : 'productVariantQty',
        productId
      );
    initialMenu();
  };

  const handleUpdateInventoryStock = (quantity, levelType) => {
    updateInventoryStockPath(id, type, locationId, parseInt(quantity, 10), levelType);
  };

  const renderMenuContent = () => {
    if (levelType === InventoryStockLevelTypes.GENERAL) {
      return (
        <>
          {constVariables.inventoryReasonsList.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  opneStockComponents(item.key, id, quantity);
                }}
                className="menu-link dropdown-item cursor-pointer p-x-12 p-y-8"
              >
                {item.name}
              </div>
            );
          })}
        </>
      );
    } else if (levelType === InventoryStockLevelTypes.INVENTORY_RECOUNT) {
      return (
        <EditInventoryRecount
          value={floatingValue}
          setValue={setFloatingValue}
          levelType={levelType}
          handleUpdateInventoryStock={handleUpdateInventoryStock}
        />
      );
    } else if (levelType === InventoryStockLevelTypes.STOCK_RECEIVED || levelType === InventoryStockLevelTypes.RESTOCK_RETURN) {
      return (
        <EditInventoryStockRecived
          total={floatingTotal}
          value={floatingValue}
          setValue={setFloatingValue}
          levelType={levelType}
          handleUpdateInventoryStock={handleUpdateInventoryStock}
        />
      );
    } else {
      return (
        <EditInventoryCommonSubtract
          value={floatingValue}
          setValue={setFloatingValue}
          total={floatingTotal}
          levelType={levelType}
          handleUpdateInventoryStock={handleUpdateInventoryStock}
        />
      );
    }
  };

  const title = useMemo(() => {
    switch (levelType) {
      case InventoryStockLevelTypes.GENERAL:
        return 'Adjustment reason';
      case InventoryStockLevelTypes.STOCK_RECEIVED:
        return 'Stock received';
      case InventoryStockLevelTypes.INVENTORY_RECOUNT:
        return 'Inventory re-count';
      case InventoryStockLevelTypes.DAMAGE:
        return 'Damage';
      case InventoryStockLevelTypes.THEFT:
        return 'Theft';
      case InventoryStockLevelTypes.LOSS:
        return 'Loss';
      case InventoryStockLevelTypes.RESTOCK_RETURN:
        return 'Restock return';
      case InventoryStockLevelTypes.GIVEAWAY:
        return 'Giveaway';
      default:
        return '';
    }
  }, [levelType]);

  const isVisibleRedButton = useMemo(() => {
    if (
      levelType === InventoryStockLevelTypes.DAMAGE ||
      levelType === InventoryStockLevelTypes.GIVEAWAY ||
      levelType === InventoryStockLevelTypes.LOSS ||
      levelType === InventoryStockLevelTypes.THEFT
    ) {
      return true;
    }
    return false;
  }, [levelType]);

  return (
    <>
      <FlexBox className="align-items-center">
        <FlexBox className="align-items-center justify-content-center">{quantity}</FlexBox>
        {role !== UserRoles.FULFILMENT_ASSOCIATE && (
          <div
            className="position-relative"
            style={{
              padding: '1px'
            }}
          >
            {showEditIcon && (
              <div
                onClick={handleOpenMainMenu}
                className={`${isEditButtonClicked ? 'pencilSelected' : 'pencilHover'} p-8 rounded m-l-8`}
                ref={stockLevelMenuRef}
              >
                <KTSVG
                  path={HollowPencilIcon}
                  svgColor={isEditButtonClicked ? '#00A3FF' : ''}
                  className="cursor-pointer d-flex align-items-center"
                />
              </div>
            )}
          </div>
        )}
      </FlexBox>
      {showMenu ? (
        <Portal id={PARENT_ID}>
          <OutsideClickHandler
            onOutsideClick={() => {
              if (showMenu) {
                initialMenu();
              }
            }}
          >
            <div
              className={clsx('menu menu-sub menu-background menu-sub-dropdown menu-column menu-rounded position-absolute', {
                show: showMenu
              })}
              style={{ top: positionStockLevelMenus.top, left: positionStockLevelMenus.left, width: 220 }}
            >
              <FloatingInventoryBoxLayout
                disableSaveButton={disableFloatingSaveButton || floatingValue === '0' || !floatingValue}
                onCancelClick={initialMenu}
                title={title}
                isBackButton={levelType !== InventoryStockLevelTypes.GENERAL}
                onBackClick={() => {
                  universalBackButton();
                }}
                onActionClick={() => {
                  if (type && levelType && updateInventoryStockPath) {
                    updateInventoryStockPath(id, type, locationId, parseInt(floatingValue, 10), levelType);
                  }
                }}
                blueButtonText={levelType === InventoryStockLevelTypes.INVENTORY_RECOUNT ? 'Update' : 'Add'}
                redButtons={isVisibleRedButton}
                noBottomButton={levelType === InventoryStockLevelTypes.GENERAL}
                isMorePaddingTitle={levelType === InventoryStockLevelTypes.GENERAL}
              >
                {renderMenuContent()}
              </FloatingInventoryBoxLayout>
            </div>
          </OutsideClickHandler>
        </Portal>
      ) : null}
    </>
  );
};

export default React.memo(InventoryStockLevel);
