import IVSBroadcastClient from 'amazon-ivs-web-broadcast';
import { useCallback, useEffect, useState } from 'react';
import { constVariables } from 'src/constants';
import { useToast } from './useToast';
import { CamOffLive } from 'src/assets/images';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_CAMERA_OFF_IMAGE } from 'src/apollo/queries/getCameraOffImage';
import { emitCustomEvent } from 'react-custom-events';

interface ILayer {
  device: null;
  name: string;
  index: number;
  visible: boolean;
  x: number;
  y: number;
  width: number;
  height: number;
  type: string;
  cameraStream?: MediaStream;
}
interface IUseLayers {
  camMuted: boolean;
  setCamMuted: React.Dispatch<React.SetStateAction<boolean>>;
  addVideoLayer: (layer: ILayer) => void;
  removeLayer: (layer: ILayer) => void;
  resetLayers: () => void;
}

const useLayers = (
  client: React.MutableRefObject<IVSBroadcastClient.AmazonIVSBroadcastClient | undefined>['current']
): IUseLayers => {
  const { showToast } = useToast();

  const [layers, setLayers] = useState<ILayer[]>([]);
  const [camMuted, setCamMuted] = useState(false);
  const [getCameraOffImage] = useLazyQuery(GET_CAMERA_OFF_IMAGE, {
    onError: () => {
      showToast({
        errorText: 'App theme not configured',
        message: `Please configure App theme for the store`
      });
    }
  });

  const addDefaultImageLayer = useCallback(
    async (client) => {
      const { data: camOffImg } = await getCameraOffImage();
      await fetch(camOffImg?.getCameraOffImage);

      const dimensions = client.getCanvasDimensions();
      const layer = {
        name: `camOff`,
        imageSrc: camOffImg?.getCameraOffImage,
        index: 1,
        x: 0,
        y: (dimensions?.height || 1920) / 4 - (dimensions?.width || 1080) / 16,
        width: dimensions?.width,
        height: dimensions?.width,
        type: 'IMAGE'
      };
      await addImageLayer(layer);
    },
    [client]
  );

  useEffect(() => {
    if (client) {
      addDefaultImageLayer(client);
    }
  }, [client]);

  const storeMaxResolution = (deviceId: string, resolution: number) => {
    localStorage.setItem(`max_resolution_${deviceId}`, `${resolution}`);
  };

  const tryGettingVideo = async (deviceId: string, resolution: number) => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { exact: deviceId },
          aspectRatio: 9 / 16,
          height: resolution
        },
        audio: false,
        preferCurrentTab: true
      });
      const settings = mediaStream?.getVideoTracks()[0]?.getSettings();

      for (const track of mediaStream.getTracks()) {
        track.stop();
      }

      if (settings?.aspectRatio === 9 / 16) {
        storeMaxResolution(deviceId, resolution);
        return resolution;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const getMaxSupportedHeight = async (deviceId: string) => {
    const storedReolution = localStorage.getItem(`max_resolution_${deviceId}`);

    if (storedReolution) {
      return +storedReolution;
    }

    const R1920 = await tryGettingVideo(deviceId, 1920);
    if (R1920) {
      return R1920;
    }

    const R1440 = await tryGettingVideo(deviceId, 1440);
    if (R1440) {
      return R1440;
    }

    const R1080 = await tryGettingVideo(deviceId, 1080);
    if (R1080) {
      return R1080;
    }

    const R720 = await tryGettingVideo(deviceId, 720);
    if (R720) {
      return R720;
    }
    storeMaxResolution(deviceId, 480);

    return 480;
  };

  const addVideoLayer = async (layer) => {
    try {
      if (layer.visible && client) {
        const { name, device, ...layerProps } = layer;

        if (client.getVideoInputDevice(layer?.name)) {
          await removeLayer(layer);
        }

        const cameraStream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: { exact: device.deviceId },
            aspectRatio: 9 / 16,
            height: await getMaxSupportedHeight(device.deviceId)
          },
          audio: false,
          preferCurrentTab: true
        });
        const { height } = cameraStream.getVideoTracks()[0].getSettings();
        if (height && height < 720) {
          showToast({
            errorText: constVariables.LiveShow.unSupportedResolution,
            message: constVariables.LiveShow.unSupportedResolutionDescription
          });
        } else if (height && height < 1920) {
          showToast({
            errorText: constVariables.LiveShow.lowResolution,
            message: constVariables.LiveShow.lowResolutionDescription
          });
        }

        await client.addVideoInputDevice(cameraStream, name, layerProps);
        setLayers((d) => [d[0], { ...layer, cameraStream }]);
        setCamMuted(false);
        emitCustomEvent('addVideoLayerDone');
      }

      // TODO enable for flip
      // const cvs = document.getElementById('cam-video-preview') as HTMLCanvasElement;
      // if (cvs) {
      //   const ctx = cvs.getContext('2d');
      //   if (ctx) {
      //     ctx.translate(1080, 0);
      //     ctx.scale(-1, 1);
      //   }
      // }
    } catch (err: any) {
      console.log({ err });
      showToast({
        errorText: constVariables.LiveShow.cameraCannotBeCaptured,
        message: constVariables.LiveShow.cameraCannotBeCapturedDescription
      });
    }
  };
  const addImageLayer = async (layer) => {
    try {
      if (!client) {
        return;
      }
      const { name, imageSrc, type, ...layerProps } = layer;

      if (client.getVideoInputDevice(layer?.name)) {
        await removeLayer(layer);
      }
      const img = new Image();
      img.src = `${imageSrc}`;

      img.addEventListener(
        'load',
        async () => {
          await client.addImageSource(img, name, layerProps);
          setLayers([layer]);
        },
        { once: true }
      );
    } catch (err: any) {
      console.log(err);
    }
  };

  const removeLayer = async (layer) => {
    if (!layer || !client) return;
    try {
      const { name } = layer;
      if (!name) return;

      if (layer.type === 'VIDEO') {
        const videoStream = client.getVideoInputDevice(name);
        if (videoStream) {
          for (const track of videoStream.source.getVideoTracks()) {
            track.stop();
          }
        }
        await client.removeVideoInputDevice(name);
        setCamMuted(true);
      } else {
        await client.removeImage(name);
      }
      setLayers((prev) => {
        return prev.filter((layer) => layer?.name !== name);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const resetLayers = async () => {
    const localLayers = layers;

    for (let i = 0; i < localLayers.length; i++) {
      const layer = localLayers[i];
      if (!layer) break;
      try {
        await removeLayer(layer);
      } catch (err) {
        console.error(err);
      }
    }
  };

  return {
    camMuted,
    setCamMuted,
    addVideoLayer,
    removeLayer,
    resetLayers
  };
};

export default useLayers;
