import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

// Apollo
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_ORDERS_DETAILS, GET_ORDERS_LIST, GET_ORDER_SHIPMENT, GET_PARCEL_TEMPLATES } from 'src/apollo/queries';

// Components
import FulfillmentCenterPresentational from './FulfillmentCenter.presentational';

// Hooks && Utils && Helpers
import useTitle from 'src/utils/hooks/useTitle';

// Types
import { orderDetailsData } from 'src/components/oraganisms/OrdersList/OrdersList.types';
import { OrdersListDataProps } from '../AllOrdersPage/AllOrdersPage.type';
import { OrderShipmentProps } from './FulfillmentCenter.type';

const FulfillmentCenter: FC = () => {
  useTitle('Fulfillment center');
  const [ordersList, setOrdersList] = useState<OrdersListDataProps>();
  const [ordersToFulfillList, setOrdersToFulfillList] = useState<OrdersListDataProps>();
  const [ordersPageInfo] = useState({
    skipCount: 0,
    limitCount: 100
  });
  const [selectedStatus, setSelectedStatus] = useState(['awaiting_fulfillment']);
  const [searchOrderValue, setSearchOrderValue] = useState('');
  const [isScannedOrderValue, setScannedOrderValue] = useState<boolean>(false);
  const [orderDetails, setOrderDetails] = useState<orderDetailsData>();
  const [leaveModal, setLeaveModal] = useState(false);
  const [targettedPath, setTargettedPath] = useState<string>();
  const [openRefundModalRemotely, setOpenRefundModalRemotely] = useState(false);
  const [orderShipment, setOrderShipment] = useState<OrderShipmentProps>({
    id: '',
    rateId: '',
    packageInfo: '',
    rateInfo: '',
    useWeightOfItems: true,
    packageWeight: 0.0,
    packageWeightUnit: '',
    needsSignatureConfirmation: false,
    hasInsurance: false,
    trackingNumber: '',
    labelId: '',
    labelUrl: '',
    insurance: {
      amount: 0,
      content: ''
    }
  });
  const [totalProducts, setTotalProducts] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(-1);
  const [errorModal, setErrorModal] = useState(false);
  const [errorModalObj, setErrorModalObj] = useState({
    action: '',
    message: '',
    title: ''
  });
  const changeSearchOrderValue = (data: string, isScannedOrder?: boolean) => {
    setSearchOrderValue(data);
    setScannedOrderValue(isScannedOrder ? true : false);
  };
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.state) {
      if (location.state.id) {
        setSelectedOrder(location.state.id);
        setTimeout(() => {
          setOpenRefundModalRemotely(true);
        }, 500);
      }
      if (location.state.openId && location.state.orderCode) {
        setSelectedOrder(location.state.openId);
        setSearchOrderValue(location.state.orderCode);
      }
    }
  }, [location]);
  useEffect(() => {
    const preventRoute = history.block((e: any) => {
      setTargettedPath(e.pathname);
      if (selectedOrder !== -1 && !orderShipment.labelUrl) {
        setLeaveModal(true);
        return false;
      } else {
        return true;
      }
    });
    return () => {
      preventRoute();
    };
  }, [history, selectedOrder, orderShipment]);
  useQuery(GET_PARCEL_TEMPLATES);

  const [getOrderShipment, { refetch: refetchOrderShipment }] = useLazyQuery(GET_ORDER_SHIPMENT, {
    onCompleted: (data) => {
      if (data?.getOrderShipment?.length > 0) {
        setOrderShipment(data?.getOrderShipment[0]);
      } else {
        setOrderShipment({
          id: '',
          rateId: '',
          packageInfo: '',
          rateInfo: '',
          useWeightOfItems: true,
          packageWeight: 0.0,
          packageWeightUnit: '',
          needsSignatureConfirmation: false,
          hasInsurance: false,
          trackingNumber: '',
          labelId: '',
          labelUrl: '',
          insurance: {
            amount: 0,
            content: ''
          }
        });
      }
    }
  });

  useEffect(() => {
    if (selectedOrder !== -1) {
      getOrderDetails({
        variables: {
          input: {
            ids: [Number(selectedOrder)],
            status: ['awaiting_fulfillment', 'awaiting_shipment']
          }
        }
      });
      getOrderShipment({
        variables: {
          input: {
            orderId: selectedOrder
          }
        }
      });
    }
  }, [selectedOrder]);

  // useEffect(() => {
  //   if (orderDetails?.orderCode) {
  //     setSearchOrderValue(orderDetails?.orderCode);
  //   }
  // }, [orderDetails]);

  const leavePageHandler = () => {
    setSelectedOrder(-1);
    // NOTE: Push to targetted path or products, handled with timeout for now.
    setTimeout(() => {
      history.push(targettedPath ? targettedPath : '/orders/fulfillmentCenter');
    }, 100);
  };

  const {
    data: ordersListData,
    refetch: refetchOrderList,
    loading: isOrdersLoading
  } = useQuery(GET_ORDERS_LIST, {
    variables: {
      input: {
        pagination: ordersPageInfo,
        status: selectedStatus,
        search: searchOrderValue.trim(),
        combined: true
      }
    },
    onError: (error) => {
      console.log('fulfillment center error');
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (
      ordersListData && // eslint-disable-next-line eqeqeq
      searchOrderValue != orderDetails?.orderCode
    ) {
      if (ordersListData.getOrderDetailsList && ordersListData.getOrderDetailsList.orders.length <= 0 && isScannedOrderValue) {
        setErrorModal(true);
        setErrorModalObj({
          action: 'Try again',
          title: 'Wrong order scanned!',
          message: 'You have scanned wrong order number, please try another order. '
        });
      } else {
        if (selectedStatus.includes('awaiting_fulfillment')) setOrdersToFulfillList(ordersListData.getOrderDetailsList);
        else setOrdersList(ordersListData.getOrderDetailsList);
        let count = 0;
        ordersListData?.getOrderDetailsList?.orders?.map((order, index) => {
          count += order?.items;
          return null;
        });
        if (selectedStatus.includes('awaiting_fulfillment')) setTotalProducts(count);
      }
    }
  }, [ordersListData]);

  const [getOrderDetails, { refetch: refetchOrderDetails, loading: isLoadingOrderDetails }] = useLazyQuery(GET_ORDERS_DETAILS, {
    onCompleted: (data) => {
      if (data?.getOrderDetailsList?.orders?.length > 0) {
        let renderIt = true;
        data?.getOrderDetailsList?.orders[0]?.orderDetails.forEach((element) => {
          if (element.name === null) {
            renderIt = false;
          }
        });
        if (renderIt) {
          setOrderDetails(data?.getOrderDetailsList?.orders[0]);
        }
      }
    },
    onError: (err) => {
      console.error('GET_ORDERS_DETAILS error - ', err);
    },
    fetchPolicy: 'cache-and-network'
  });

  const handleUpdateOrderDetails = (orderId, updatedItems) => {
    const updatedOrderDetails = orderDetails?.orderDetails?.map((orderItem) => {
      if (orderItem.id === orderId) {
        return { ...orderItem, items: updatedItems };
      } else {
        return orderItem;
      }
    });
    if (orderDetails) {
      const updatedOrderData = { ...orderDetails, orderDetails: updatedOrderDetails };
      setOrderDetails(updatedOrderData);
    }
  };

  return (
    <div className="fulfilmentCenter p-40">
      <FulfillmentCenterPresentational
        isPageloading={isOrdersLoading}
        isLoadingOrderDetails={isLoadingOrderDetails}
        searchOrderValue={searchOrderValue}
        changeSearchOrderValue={changeSearchOrderValue}
        ordersListData={ordersList}
        ordersToFulfillListData={ordersToFulfillList}
        selectedOrder={selectedOrder}
        setSelectedOrder={setSelectedOrder}
        orderDetails={orderDetails}
        totalProducts={totalProducts}
        refetchOrderDetails={refetchOrderDetails}
        orderShipment={orderShipment}
        refetchOrderShipment={refetchOrderShipment}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        refetchOrderList={refetchOrderList}
        leaveModal={leaveModal}
        leavePageHandler={leavePageHandler}
        setLeaveModal={setLeaveModal}
        errorModal={errorModal}
        setErrorModal={setErrorModal}
        errorModalObj={errorModalObj}
        setErrorModalObj={setErrorModalObj}
        setOpenRefundModalRemotely={setOpenRefundModalRemotely}
        openRefundModalRemotely={openRefundModalRemotely}
        updateOrderDetails={handleUpdateOrderDetails}
      />
    </div>
  );
};

export default FulfillmentCenter;
