import { FC, useState, useReducer, useEffect } from 'react';
import { useHistory } from 'react-router';

// Apollo
import { useMutation, useLazyQuery } from '@apollo/client';
import { CHECK_BACKSTORE_USER, GET_USER_BASIC_INFO } from 'src/apollo/queries';
import { SIGN_UP_BACK_STORE, FORGET_PASSWORD } from 'src/apollo/mutations';

// Redux
import { useAppDispatch } from 'src/redux/hooks';
import { getUserInfoSuccess } from 'src/redux/reducer/userInfoReducer';

// Hooks && Utils && Helpers
import {
  createFirebaseUser,
  sigupWithGoogle,
  sigupWithFacebook,
  sigupWithApple,
  signInWithEmail,
  refreshToken
} from 'src/helpers/InitFirebase';
import { getAccountDetailsFromToken } from 'src/utils/authentication/decodeToken';
import { validateRequired, validateEmail } from 'src/utils/validate';
import { setStoreIdToOSPlayer } from 'src/services/pushnotification/DataTags';

// Images && Icons
import { SignUpBySocial } from 'src/assets/images';
import { HideIcon, ShowIcon, GoogleIcon1, AppleIcon1, Facebook1 } from 'src/assets/icons';

// ConstVariables
import { ROUTES, envUrls, constVariables } from 'src/constants';

// Types
import { UserRoles } from '../../settings/UserPermissions/UserPermissions.type';

// Styles
import 'src/components/oraganisms/CustomModal/modalStyles.scss';
import 'src/components/pages/auth/Signup/_signUp.scss';

enum mediumType {
  EMAIL,
  GOOGLE,
  FACEBOOK,
  APPLE
}

const Login: FC = (props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), {
    email: {
      value: '',
      error: ''
    },
    fullName: {
      value: '',
      error: ''
    },
    storeName: {
      value: '',
      error: ''
    },
    password: {
      value: '',
      error: ''
    },
    genericError: '',
    profileUrl: '',
    token: '',
    isLoading: false,
    medium: mediumType[0]
  });
  const resetData = () => {
    setState({
      email: {
        value: '',
        error: ''
      },
      fullName: {
        value: '',
        error: ''
      },
      storeName: {
        value: '',
        error: ''
      },
      password: {
        value: '',
        error: ''
      },
      profileUrl: '',
      token: '',
      isLoading: false,
      medium: mediumType[0],
      genericError: ''
    });
  };

  const [checkUserData, { data: userData, error: checkUserError }] = useLazyQuery(CHECK_BACKSTORE_USER, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (userData.checkBackstoreUser.isUserExist && state.medium !== mediumType[0]) {
        const accountInfo = getAccountDetailsFromToken(state.token);
        localStorage.setItem('storeId', accountInfo.storeId);
        localStorage.setItem('userId', accountInfo.userId);
        localStorage.setItem('firebaseUserId', accountInfo.user_id);
        localStorage.setItem('name', userData.checkBackstoreUser.fullName);
        localStorage.setItem('picture', accountInfo.picture);
        localStorage.setItem('email', accountInfo.email);
        localStorage.setItem('userRole', userData.checkBackstoreUser.role);
        console.log('accountInfo::', accountInfo);

        // TODO: Add error handling for setStoreIdToOSPlayer onError
        // setStoreIdToOSPlayer(accountInfo.storeId);

        setState({
          isLoading: false
        });

        history.replace(ROUTES.home.main);
      } else if (
        !userData.checkBackstoreUser.isUserExist &&
        (state.medium === mediumType[1] || state.medium === mediumType[2] || state.medium === mediumType[3])
      ) {
        setState({
          isLoading: false
        });

        history.push({
          pathname: '/createstore',
          state: {
            detail: {
              email: state.email.value,
              fullName: state.fullName.value,
              medium: state.medium,
              profileUrl: state.profileUrl
            }
          }
        });
      } else if (!userData.checkBackstoreUser.isUserExist && state.medium === mediumType[0]) {
        setState({
          isLoading: false
        });

        const signupWithEmailAndPassword = async () => {
          const {
            success,
            userCredential = {},
            error = ''
          }: { success: boolean; userCredential?: any; error?: any } = await createFirebaseUser(
            state.email.value,
            state.password.value
          );
          if (success) {
            const user = userCredential.user;
            const idToken = await user.getIdToken(true).then();
            setState({
              token: idToken
            });
            localStorage.setItem('token', idToken);
            signupBackstoreUser({
              context: {
                headers: {
                  Authorization: idToken || state.token,
                  tenantid: envUrls.firebaseTenantId,
                  'x-api-key': envUrls.authXApiKey
                }
              },
              variables: {
                input: {
                  email: state.email.value,
                  fullName: state.fullName.value,
                  medium: state.medium,
                  storeName: state.storeName
                }
              }
            });
          } else if (!success && error) {
            setState({
              isLoading: false,
              genericError: 'This user is already exist'
            });
          }
        };
        signupWithEmailAndPassword();
      }
    },
    onError: (e) => {
      console.log('checkUserError - ', checkUserError);
      localStorage.clear();
      const error: any = e?.graphQLErrors[0];
      if (error?.errorType === 'USER_ALREADY_EXIST') {
        setState({
          genericError: 'This email is already used in a registered account.'
        });
      } else {
        setState({
          genericError: 'Something went wrong. Please try again later.'
        });
      }

      setState({
        isLoading: false
      });
    }
  });

  const [getUserBasicInfo, { data: userBasicInfoData, error: userBasicInfoError }] = useLazyQuery(GET_USER_BASIC_INFO, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const accountInfo = getAccountDetailsFromToken(state.token);
      localStorage.setItem('storeId', accountInfo.storeId);
      localStorage.setItem('userId', accountInfo.userId);
      localStorage.setItem('firebaseUserId', accountInfo.user_id);
      localStorage.setItem('firstName', res?.getUserBasicInfo?.userInfo?.firstName);
      localStorage.setItem('lastName', res?.getUserBasicInfo?.userInfo?.lastName);
      localStorage.setItem('picture', res?.getUserBasicInfo?.userInfo?.profileURL);
      localStorage.setItem('email', accountInfo.email);
      localStorage.setItem('userRole', res?.getUserBasicInfo?.userInfo?.role);
      console.log('userInfo::', res?.getUserBasicInfo?.userInfo);
      localStorage.setItem('permission', res?.getUserBasicInfo?.userInfo?.permission);
      setState({ isLoading: false });
      dispatch(getUserInfoSuccess(res.getUserBasicInfo.userInfo));

      // Go to the different first the page by user role::
      const userRole = res.getUserBasicInfo.userInfo.role;
      if (userRole === UserRoles.OWNER || userRole === UserRoles.ADMIN) {
        history.replace(ROUTES.home.main);
      } else if (userRole === UserRoles.ADMIN_ASSOCIATE) {
        history.replace(ROUTES.products.allProducts.main);
      } else if (userRole === UserRoles.WAREHOUSE_MANAGER) {
        history.replace(ROUTES.products.inventory.main);
      } else if (userRole === UserRoles.FULFILMENT_ASSOCIATE) {
        history.replace(ROUTES.orders.allOrders.fulfillmentCenter);
      } else if (userRole === UserRoles.WAREHOUSE_ASSOCIATE) {
        history.replace(ROUTES.products.inventory.main);
      }

      // TODO: Add error handling for setStoreIdToOSPlayer onError
      // setStoreIdToOSPlayer(accountInfo.storeId);
    },
    onError: (e) => {
      console.error('eerror we get - userBasicInfoError', userBasicInfoError);
      const errorMsg = userBasicInfoError?.message;
      localStorage.clear();
      setState({
        isLoading: false,
        genericError:
          errorMsg === 'User not verified'
            ? 'Your account has not been verified yet. Please check your email inbox for a verification link.'
            : 'Somthing went wrong. Please try again later.'
      });
    }
  });

  const [forgetPassword] = useMutation(FORGET_PASSWORD, {
    onCompleted: () => {
      setState({
        isLoading: false
      });
      history.push({
        pathname: '/forgot-password',
        state: {
          detail: {
            email: state.email.value
          }
        }
      });
    },
    onError: (e) => {
      console.error('resetpassword onError - ', e);
      console.error('e?.graphQLErrors[0] - ', e?.graphQLErrors[0]);
      const error: any = e?.graphQLErrors[0];
      if (error.errorType === 'USER_NOT_FOUND') {
        setState({
          isLoading: false,
          genericError: error.message
        });
      } else {
        setState({
          genericError: 'Somthing went wrong. Please try again later.'
        });
      }

      setState({
        isLoading: false
      });
    }
  });

  const [signupBackstoreUser] = useMutation(SIGN_UP_BACK_STORE, {
    onCompleted: async (data) => {
      const idToken = await refreshToken();
      localStorage.setItem('token', idToken);
      const accountInfo = getAccountDetailsFromToken(idToken);
      localStorage.setItem('storeId', accountInfo.storeId);
      localStorage.setItem('userId', accountInfo.userId);
      localStorage.setItem('firebaseUserId', accountInfo.user_id);
      localStorage.setItem('name', accountInfo.name);
      localStorage.setItem('picture', accountInfo.picture);
      localStorage.setItem('email', accountInfo.email);
      await setStoreIdToOSPlayer(accountInfo.storeId);

      setState({
        isLoading: false
      });

      history.replace('/products');
      if (state.medium === mediumType[0]) {
        // history.push('/signup/verifyEmail');
      } else {
        // history.push('/products');
      }
    },
    onError: (e) => {
      console.error('signupBackstoreUser onError - ', e);
      console.error('e?.graphQLErrors[0] - ', e?.graphQLErrors[0]);
      localStorage.clear();
      const error: any = e?.graphQLErrors[0];
      if (error?.errorType === 'STORE_ALREADY_EXIST') {
        setState({
          genericError: error?.message
        });
      } else {
        setState({
          genericError: 'Somthing went wrong. Please try again later.'
        });
      }

      setState({
        isLoading: false
      });
    }
  });

  const [input, setInput] = useState('');
  const [lastTime, setLastTime] = useState(Date.now());
  const [message, setMessage] = useState('');

  const onClickForget = () => {
    const emailError = validateEmail(state.email.value);
    if (emailError) {
      setState({
        email: {
          ...state.email,
          error: emailError
        }
      });
    } else {
      setState({
        isLoading: true
      });

      forgetPassword({
        context: {
          headers: {
            tenantid: '',
            'x-api-key': envUrls.authXApiKey
          }
        },
        variables: {
          input: {
            email: state.email.value,
            isBackStoreUser: true
          }
        }
      });
    }
  };

  const signInWIthEmail = async () => {
    setState({ medium: mediumType[0], isLoading: true });

    try {
      const {
        success,
        error = '',
        idToken
      }: { success: boolean; userCredential?: any; error?: any; idToken?: any } = await signInWithEmail(
        state.email.value,
        state.password.value
      );
      if (success) {
        setState({ token: idToken });
        localStorage.setItem('token', idToken);

        getUserBasicInfo({
          context: {
            headers: {
              Authorization: idToken || '',
              tenantid: '',
              'x-api-key': envUrls.authXApiKey
            }
          },
          variables: {
            input: {
              email: state.email.value,
              isBackStoreUser: true
            }
          }
        });
      } else if (!success && error) {
        console.error('signInWIthEmail error - ', error);
        setState({ isLoading: false });

        if (error.code && error.message) {
          const inErr = error?.code === 'auth/internal-error' ? JSON.parse(error?.message) : '';
          if (
            error.code === 'auth/user-not-found' ||
            error.code === 'auth/wrong-password' ||
            inErr?.error?.message === 'INVALID_LOGIN_CREDENTIALS'
          ) {
            setState({
              genericError: 'Wrong email or password'
            });
          } else {
            setState({
              genericError: error.message
            });
          }
        } else {
          setState({
            genericError: 'Somthing went wrong. Please try again later.'
          });
        }
      }
    } catch (error) {
      console.error('signInWIthEmail catch error - ', error);
      setState({
        isLoading: false,
        genericError: 'Somthing went wrong. Please try again later.'
      });
    }
  };

  const signUpWithGoogle = async () => {
    const {
      success,
      userCredential = {},
      error = '',
      idToken
    }: { success: boolean; userCredential?: any; error?: any; idToken?: any } = await sigupWithGoogle();
    if (success) {
      setState({ isLoading: true, medium: mediumType[1] });
      localStorage.setItem('token', idToken);

      setState({
        email: {
          value: userCredential.user.email,
          error: ''
        },
        fullName: {
          value: userCredential.user.displayName,
          error: ''
        },
        profileUrl: userCredential.user.photoURL,

        token: idToken
      });

      checkUserData({
        context: {
          headers: {
            Authorization: idToken || '',
            tenantid: '',
            'x-api-key': envUrls.authXApiKey
          }
        },
        variables: {
          input: {
            email: userCredential.user.email,
            medium: mediumType[1]
          }
        }
      });
    } else if (error) {
      if (error.code !== 'auth/popup-closed-by-user') {
        if (error.code && error.message) {
          setState({
            genericError: error.message
          });
        } else {
          setState({
            genericError: 'Something went wrong. Please try again later.'
          });
        }
      }
      resetData();
      setState({
        isLoading: false
      });
    }
  };

  const signUpWithFacebook = async () => {
    const {
      success,
      userCredential = {},
      error = '',
      idToken
    }: { success: boolean; userCredential?: any; error?: any; idToken?: any } = await sigupWithFacebook();
    if (success) {
      setState({
        isLoading: true,
        medium: mediumType[2]
      });
      localStorage.setItem('token', idToken);
      setState({
        email: {
          value: userCredential.user.email,
          error: ''
        },
        fullName: {
          value: userCredential.user.displayName,
          error: ''
        },
        profileUrl: userCredential.user.photoURL,
        token: idToken
      });

      checkUserData({
        context: {
          headers: {
            Authorization: idToken || '',
            tenantid: '',
            'x-api-key': envUrls.authXApiKey
          }
        },
        variables: {
          input: {
            email: userCredential.user.email,
            medium: mediumType[2]
          }
        }
      });
    } else if (error) {
      if (error.code !== 'auth/popup-closed-by-user') {
        if (error.code && error.message) {
          setState({
            genericError: error.message
          });
        } else {
          setState({
            genericError: 'Something went wrong. Please try again later.'
          });
        }
      }

      resetData();
      setState({
        isLoading: false
      });
    }
  };

  const signUpWithApple = async () => {
    const {
      success,
      userCredential = {},
      error = '',
      idToken
    }: { success: boolean; userCredential?: any; error?: any; idToken?: any } = await sigupWithApple();

    if (success) {
      setState({
        isLoading: true,
        medium: mediumType[3]
      });
      localStorage.setItem('token', idToken);

      setState({
        email: {
          value: userCredential.user.email || '',
          error: ''
        },
        fullName: {
          value: userCredential.user.displayName || '',
          error: ''
        },

        profileUrl: userCredential.user.photoURL || '',
        token: idToken
      });

      checkUserData({
        context: {
          headers: {
            Authorization: idToken || '',
            tenantid: '',
            'x-api-key': envUrls.authXApiKey
          }
        },
        variables: {
          input: {
            email: userCredential.user.email,
            medium: mediumType[3]
          }
        }
      });
    } else if (error) {
      if (error.code !== 'auth/popup-closed-by-user') {
        if (error.code && error.message) {
          setState({
            genericError: error.message
          });
        } else {
          setState({
            genericError: 'Somthing went wrong. Please try again later.'
          });
        }
      }

      setState({
        isLoading: false
      });
      resetData();
    }
  };

  const handleChange = async (e: any) => {
    const field = e.target.name;
    const fieldVal = e.target.value;

    if (field) {
      setState({
        [field]: {
          ...state[field],
          value: fieldVal,
          error: ''
        }
      });
    }
  };

  return (
    <>
      <div className="signup d-flex gradient flex-row ">
        <div className="form-container padding-style">
          <div className={`p-4rem container-height container-width overflow-hidden bg-white content-container`}>
            <img src={SignUpBySocial} alt={SignUpBySocial} className="mt-40px image-dimension" />
            <div className="poppins-regular title-style m-0 auth-header">
              <p>Sign In</p>
            </div>

            <div className={`text-danger fs-8`}>{state.genericError}</div>

            <div>
              <div className="mt-30px">
                <div className="d-flex flex-row">
                  <div
                    className="social-btn1  mr-10px cursor-pointer"
                    onClick={() => {
                      resetData();
                      signUpWithGoogle();
                    }}
                  >
                    <div className="social-btn1 d-flex flex-center p-0">
                      <div className="d-flex btn-content-wrapper">
                        <img src={GoogleIcon1} height={12} className=" social-icon" /> <span>{constVariables.signUp.google}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="social-btn1  mr-10px cursor-pointer"
                    onClick={() => {
                      resetData();
                      signUpWithFacebook();
                    }}
                  >
                    <div className="social-btn1 d-flex flex-center p-0">
                      <div className="d-flex btn-content-wrapper">
                        <img src={Facebook1} height={12} className=" social-icon" /> <span>{constVariables.signUp.facebook}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="social-btn1  mr-10px cursor-pointer"
                    onClick={() => {
                      resetData();
                      signUpWithApple();
                    }}
                  >
                    <div className="social-btn1 d-flex flex-center p-0">
                      <div className="d-flex btn-content-wrapper">
                        <img src={AppleIcon1} height={12} className=" social-icon" /> <span>{constVariables.signUp.apple}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="or-container mt-30px">
                <div className="border-container" />
                <p className="fs-14px my-8 orText">{constVariables.signUp.or}</p>
                <div className="border-container" />
              </div>
              <div className="d-flex  inner-gap flex-column mt-30px">
                <div className="input-gap">
                  <p className="fs-14px mb-1">{constVariables.signUp.email}</p>
                  <input
                    autoComplete="off"
                    className={`form-control form-control-solid hp-50px ${state.email.error ? 'red-border' : 'grey-border'}`}
                    id="email"
                    name="email"
                    value={state.medium === mediumType[0] ? state.email.value : ''}
                    onChange={(e) => handleChange(e)}
                    type="text"
                    placeholder="e.g. John@demo.com"
                  />
                  <div className={`text-danger fs-8`}>{state.email.error}</div>
                </div>
                <div>
                  <p className="fs-14px mb-1">{constVariables.signUp.password}</p>
                  <div className={`input-group password-section ${state.password.error ? 'red-border' : 'grey-border'}`}>
                    <input
                      autoComplete="off"
                      className={`form-control form-control-solid hp-50px no-border`}
                      id="password"
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      value={state.medium === mediumType[0] ? state.password.value : ''}
                      onChange={(e) => handleChange(e)}
                      placeholder="Set Password"
                    />
                    <div className="input-group-addon">
                      <img
                        src={!showPassword ? HideIcon : ShowIcon}
                        className="cursor-pointer"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className={`text-danger fs-8`}>{state.password.error}</div>
                    <p
                      className="fs-14px m-0 loggedin-message poppins-regular align-end forgot-pass-gap"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        onClickForget();
                      }}
                    >
                      {constVariables.signUp.forgetPassword}
                    </p>
                  </div>
                </div>
                <div className="button-container">
                  <div className="button w-100 px-0 button-padding">
                    <button
                      disabled={state.isLoading}
                      className="btn btn-primary w-100 flex-center"
                      onClick={() => {
                        const emailError = validateEmail(state.email.value);
                        const passwordError = validateRequired(state.password.value, 'Password');

                        if ([passwordError, emailError].every((e) => e === false)) {
                          signInWIthEmail();
                        } else {
                          setState({
                            email: {
                              ...state.email,
                              error: emailError
                            },

                            password: {
                              ...state.password,
                              error: passwordError
                            }
                          });
                        }
                      }}
                    >
                      {constVariables.signUp.signIn}
                      {state.isLoading && <div className="spinner-border text-primary auth-flow"></div>}
                    </button>
                  </div>
                  <div className="loggedin-message poppins-regular already-have-an-account text-color-exp">
                    Don’t have an account?{' '}
                    <span
                      className="text-primary cursor-pointer poppins-regular"
                      onClick={() => {
                        history.push('/signup');
                        resetData();
                      }}
                    >
                      Create Account
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
