import { FC, useEffect, useState, memo, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import clsx from 'clsx';
import moment from 'moment';

// Apollo
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_ORDERS_DETAILS,
  GET_ORDER_SHIPMENT,
  GET_COMBINED_ORDER_DETAIL,
  GET_MANIFESTS,
  GET_CREATED_ORDER_SHIPMENTS,
  GET_SHOPIFY_ADMIN_BASE_URL
} from 'src/apollo/queries';
import { CANCEL_ORDER, CREATE_MANIFEST } from 'src/apollo/mutations';
import { addErrorFilter, showOrderError } from 'src/apollo/reactiveVariables';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { FlexBox, Img, Loader } from 'src/components/atoms';
import {
  DatesDropdown,
  DatesDropdownSelector,
  OrderTagsBlock,
  SearchBar,
  SortDropdown,
  ManifestsDropdown,
  Tooltip,
  FirstTimeCustomerTooltip,
  ExpeditedShippingTooltip,
  VipCustomerTooltip,
  PreviewTooltip,
  ShopifySyncTooltip,
  DataTable
} from 'src/components/molecules';
import { ErrorModal, CustomModal, ConfirmationCombinedOrderModal } from 'src/components/oraganisms';
import CancelOrder from 'src/components/pages/orders/OrderDetails/CancelOrder';
import OrderMenu from './components/OrderMenu';
import SortableTableHeader from 'src/components/molecules/DataTable/components/SortableTableHeader';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers/components/KTSVG';
import { setImageSrc } from 'src/utils/setImageSrc';
import { useToast } from 'src/utils/hooks/useToast';
import { mapCombinedOrderToOrder } from './helpers/mapCombinedOrderToOrder';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';
import { usePrintPackingSlip } from './hooks/usePrintPackingSlip';
import { setStatusColor, setStatusText } from './helpers/orderStatus';

// Icons
import {
  CalendarIcon,
  InfoIcon,
  PreviewMagnifyingGlassIcon,
  PrinterWhite,
  SortIcon,
  RedExclaimationIcon,
  YellowExclaimationIcon,
  CloseIcon,
  DefaultImage,
  CheckedOffSquareIcon,
  WhiteAddIcon,
  UpArrowIcon,
  DownArrowIcon,
  RightArrowIcon,
  FirstTime,
  Truck,
  VipCustomerIcon,
  ShopifyIcon
} from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';
import { DateListData, SortIds } from './data';

// Types
import { ColumnType } from 'src/components/molecules/Table/Table.types';
import {
  DropdownOptions,
  orderDetailsData,
  OrderRefundType,
  OrdersListData,
  OrdersListProps,
  OrderStatus
} from './OrdersList.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

// Styles
import './_ordersList.scss';

const OrdersList: FC<OrdersListProps> = ({
  ordersList,
  setOrdersList,
  setSortBy,
  sortBy,
  rapidActionOpen,
  refresh,
  changingDate,
  customDateSelector,
  selectedDates,
  setOrderDetails,
  openOrderShippingPreview,
  openShipmentDetails,
  sendDate,
  selectedTab,
  searchForOrders,
  selectedOrderType,
  isLoading,
  searchText,
  totalCount,
  editColumnData,
  setIsOpenOrderPreview,
  countList
}) => {
  const history = useHistory();
  const { showToast } = useToast();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedStatus, setSelectedStatus] = useState(-1);
  const [openSortDropdown, setOpenSortDropdown] = useState(false);
  const [openDatesOption, setOpenDatesOption] = useState(false);
  const [chooseDatesOption, setChooseDatesOption] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);
  const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([]);
  const [errorOrderIds, setErrorOrderIds] = useState({});
  const [activeOrderList, setActiveOrderList] = useState<Array<OrdersListData>>(ordersList);
  const [showErrorFilter, setShowErrorFilter] = useState(false);
  const [isCancelOrder, setIsCancelOrder] = useState(false);
  const [showManifestsDropdown, setShowManifestsDropdown] = useState(false);
  const [activeColumns, setActiveColumns] = useState<ColumnType[]>([]);
  const [selectedDatesOption, setSelectedDatesOption] = useState('Date');

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const { data: { shopifyAdminBaseUrl } = { shopifyAdminBaseUrl: null } } = useQuery(GET_SHOPIFY_ADMIN_BASE_URL);

  const {
    checkForOrdrErrors,
    checkOrdersForCombining,
    printPackingSlip,
    combineAndPrintPackingSlip,
    setIsShowConfirmCombinedOrderModal,
    isShowConfirmCombinedOrderModal,
    isPrintingPackingSlip,
    isAutomaticCombiningEnabled
  } = usePrintPackingSlip(selectedOrderIds, refresh, ordersList, setErrorOrderIds, setShowErrorModal);
  const noOferrorOrderIds = useMemo(() => Object.keys(errorOrderIds).length, [errorOrderIds]);

  const goToShopifyPage = (shopifyOrderId) => {
    if (shopifyAdminBaseUrl) window.open(`${shopifyAdminBaseUrl}orders/${shopifyOrderId}`, '_blank');
  };

  const initialColumnOptions = [
    {
      dataKey: 'index',
      name: 'Index',
      width: 230,
      headerRenderer: () => {
        return (
          <div className="minBigBoxSize form-check form-check-sm form-check-custom">
            <input
              className="me-5 form-check-input widget-9-check"
              type="checkbox"
              checked={activeOrderList.length > 0 && activeOrderList.length === selectedOrderIds.length}
              onChange={() => {
                if (activeOrderList.length === selectedOrderIds.length) {
                  setSelectedOrderIds([]);
                } else {
                  selectOrder('All');
                }
              }}
            />
            <div>Orders</div>
            <button
              className="bg-transparent border border-transparent"
              onClick={() => {
                ChangeSortBy('Order', 71);
              }}
            >
              <KTSVG
                path={sortBy.catagory === 'Order' && sortBy.type === 'ASC' ? UpArrowIcon : DownArrowIcon}
                className="expand-arrow-icon"
                svgClassName="cursor-pointer collapseFilter collapseIcon"
              />
            </button>
          </div>
        );
      },
      cellRenderer: ({ rowData, rowIndex }) => {
        return (
          <div>
            <div className="d-flex align-items-center m-b-8">
              {rowData.isPartiallyShipped && selectedOrderType === 'INDIVIDUAL' ? (
                <div className="d-flex me-1">
                  <div className="bg-danger text-white p-1 px-2 roundButton order-status-error-text">
                    {constVariables.orderItemStatuses.PartialShipping}
                  </div>
                </div>
              ) : null}
              <FlexBox>
                {!rowData?.isOrderAddressVerified && (
                  <FlexBox className="me-1 align-items-center bg-white br-50 p-1 px-2">
                    <img src={YellowExclaimationIcon} className="h-10px w-10px me-1" />
                    <span className="warning-text order-status-error-text">Shipping Address</span>
                  </FlexBox>
                )}
              </FlexBox>
            </div>
            <div className="d-flex align-items-center">
              <div className="form-check form-check-sm form-check-custom">
                <input
                  className="me-5 form-check-input widget-9-check"
                  type="checkbox"
                  checked={selectedOrderIds?.includes(rowData?.id)}
                  onClick={(e) => {
                    selectOrder(e, rowData?.id);
                  }}
                  onChange={(e) => {
                    selectOrder(e, rowData?.id);
                  }}
                />
              </div>

              {(rowData.orderStatus === 'completed' || rowData.orderStatus === 'shipped') && selectedOrderType === 'INDIVIDUAL' ? (
                <div
                  onClick={() => {
                    expandOrder(rowData.id, rowIndex);
                  }}
                  className="cursor-pointer me-4"
                >
                  <img src={RightArrowIcon} className={`h-10px w-10px ${selectedIndex === rowIndex ? 'rotatedArrowIcon' : ''}`} />
                </div>
              ) : null}

              {rowData?.isCombOrdrParent ? (
                <div
                  onClick={() => {
                    expandChildOrders(rowData.id, rowIndex);
                  }}
                  className="cursor-pointer me-4"
                >
                  <img src={RightArrowIcon} className={`h-10px w-10px ${selectedIndex === rowIndex ? 'rotatedArrowIcon' : ''}`} />
                </div>
              ) : null}
              <FlexBox className="align-items-center">
                <FlexBox className="align-items-center justify-content-center m-r-16">
                  <Link to={`/orders/orderDetails/${rowData.id}`} className="main-cell">
                    <span className="d-block">#{rowData.orderCode}</span>
                  </Link>
                </FlexBox>
                {rowData?.shopifyId && (
                  <>
                    <img
                      src={ShopifyIcon}
                      className="cursor-pointer m-r-16"
                      data-tip
                      data-for="shopifySyncIcon"
                      onClick={() => goToShopifyPage(rowData?.shopifyId)}
                    />
                    <ShopifySyncTooltip tooltipText="Order from Shopify" />
                  </>
                )}
                <>
                  <img
                    src={PreviewMagnifyingGlassIcon}
                    className="cursor-pointer"
                    data-tip
                    onClick={() => {
                      openOrderPreview(rowData?.id, rowData?.isCombOrdrParent, rowData);
                    }}
                    data-for="previewIcon"
                  />
                  <PreviewTooltip />
                </>
              </FlexBox>
            </div>
          </div>
        );
      },
      staticColumn: true
    },
    {
      dataKey: 'date',
      name: constVariables.orderEditColumns.date,
      width: 250,
      headerRenderer: () => {
        return <SortableTableHeader label="Date" sortHeader={() => ChangeSortBy('Date', 72)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return (
          <FlexBox className="align-items-center">
            {rowData?.createdAt ? moment(rowData.createdAt).format('D MMM YYYY, hh:mm a') : '-'}
          </FlexBox>
        );
      }
    },
    {
      dataKey: 'status',
      name: constVariables.orderEditColumns.status,
      width: 150,
      headerRenderer: () => {
        return <SortableTableHeader label="Status" sortHeader={() => ChangeSortBy('Status', 74)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return (
          <div
            className={` ${setStatusColor(
              rowData.orderStatus,
              rowData.isPartiallyShipped
            )} order-status-box text-nowrap poppins-medium`}
            onClick={() => {
              if (selectedStatus === rowData.id) setSelectedStatus(-1);
              else setSelectedStatus(rowData.id);
            }}
          >
            <div className="text-capitalize">{setStatusText(rowData.orderStatus, rowData.isPartiallyShipped)}</div>
          </div>
        );
      }
    },
    {
      dataKey: 'customer',
      name: constVariables.orderEditColumns.customer,
      width: 300,
      headerRenderer: () => {
        return <SortableTableHeader label="Customer" sortHeader={() => ChangeSortBy('Customer', 73)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return (
          <div className="align-items-center d-flex">
            <Img
              src={setImageSrc(rowData?.thumbnailUrl ? generateCompressedImageURL(rowData?.thumbnailUrl, '50') : DefaultImage)}
              placeholderImg={DefaultImage}
              errorImg={DefaultImage}
              height={40}
              width={40}
              className="rounded-circle objectFit me-4"
            />

            <div className="inline-row align-items-center">
              <Link to={`/customers/customerDetails/${rowData.customerId}`} target="_blank" className="order-customer-link">
                <span className="d-block">{rowData.customer}</span>
              </Link>
              {rowData.isFirstTimeCustomer && (
                <>
                  <img src={FirstTime} className="cursor-pointer mx-4" data-tip data-for="firstTimeCustomerIcon" />
                  <FirstTimeCustomerTooltip />
                </>
              )}

              {rowData.isExpeditedShipping && (
                <>
                  <img src={Truck} className="cursor-pointer mx-4" data-tip data-for="truckIcon" />
                  <ExpeditedShippingTooltip />
                </>
              )}
              {rowData.isVIPCustomer && (
                <>
                  <img src={VipCustomerIcon} className="cursor-pointer mx-4" data-tip data-for="vipCustomerIcon" />
                  <VipCustomerTooltip />
                </>
              )}
            </div>
          </div>
        );
      }
    },
    {
      dataKey: 'total',
      name: constVariables.orderEditColumns.total,
      width: 120,
      headerRenderer: () => {
        return <SortableTableHeader label="Total" sortHeader={() => ChangeSortBy('Total', 75)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return <FlexBox className="align-items-center">{rowData.total ? `${rowData.total.toFixed(2)}` : '-'}</FlexBox>;
      }
    },
    {
      dataKey: 'items',
      name: constVariables.orderEditColumns.items,
      width: 180,
      headerRenderer: () => {
        return (
          <SortableTableHeader
            label={
              <span>
                <span className="me-2">Items</span>
                <img src={InfoIcon} className="cursor-pointer" data-tip data-for="infoItemIcon" />
                <Tooltip
                  tooltipId="infoItemIcon"
                  place="top"
                  content={() => {
                    return <>Available items / Total items</>;
                  }}
                />
              </span>
            }
            sortHeader={() => ChangeSortBy('Items', 76)}
            type={sortBy.type}
          />
        );
      },
      cellRenderer: ({ rowData }) => {
        return (
          <FlexBox className="align-items-center">
            {rowData.items ? (
              <>
                <span className="available-item-quantity">{rowData.availableItemsQuantity}</span>/{rowData.items} Available Items
              </>
            ) : (
              '-'
            )}
          </FlexBox>
        );
      }
    },
    {
      dataKey: 'tags',
      name: constVariables.orderEditColumns.tags,
      width: 160,
      headerRenderer: () => {
        return <SortableTableHeader label="Tags" sortHeader={() => ChangeSortBy('Tags', 77)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return (
          <>
            <div>
              <OrderTagsBlock tags={rowData.tags} isCombined={rowData.isCombOrdrParent || false} />
            </div>
          </>
        );
      }
    },
    {
      width: 50,
      name: '',
      dataKey: 'action',
      cellRenderer: ({ rowData }) => {
        return <OrderMenu orders={rowData} filterOrderMenu={filterOrderMenu} handleOrderMenu={handleOrderMenu} />;
      },
      staticColumn: true
    }
  ];

  const columnOptions = useMemo(() => {
    const activeColumnNames = activeColumns?.map((column) => column.name);
    // eslint-disable-next-line array-callback-return
    const filteredColumns = initialColumnOptions
      ?.filter((column) => {
        if (column.staticColumn || activeColumnNames?.includes(column.name)) return true;
        return false;
      })
      .sort(function (a, b) {
        if (a.dataKey === 'action') return 1;
        return activeColumnNames.indexOf(a.name) - activeColumnNames.indexOf(b.name);
      });
    return filteredColumns;
  }, [activeColumns, selectedOrderIds, selectedIndex, activeOrderList, shopifyAdminBaseUrl]);

  useEffect(() => {
    if (editColumnData && editColumnData?.length > 0) {
      const arr = Array.from(editColumnData);
      editColumnData.map((column, index) => {
        if (!column.status) {
          arr.splice(arr.indexOf(column), 1);
        }
        return null;
      });
      setActiveColumns([...arr]);
      localStorage.setItem('orderTable', JSON.stringify(editColumnData));
    }
  }, [editColumnData]);

  const [createManifest, { loading: isCreatingManifest }] = useMutation(CREATE_MANIFEST, {
    onCompleted: (res) => {
      if (res) {
        showToast({
          successText: `<span style="color:black;">${res.createManifest.numberOfManifests} Manifests were<span> <span style="color:#50cd89;">created successfully!</span>`,
          message: `<span style="color: #a1a5b7;">Please find them on  </span><span class="text-primary">Recent manifests tab</span>`
        });
      }
    },
    onError: (err) => {
      if (err.message === 'NO_SHIPPING_LABLE_FOUND_FOR_CREATING_MANIFEST') {
        showToast({
          errorText: `There is no shipping lable for creating manifest.`,
          message: `Please fulfill any order firstly.`
        });
      }
    },
    refetchQueries: [
      {
        query: GET_MANIFESTS,
        variables: {
          input: {
            pageInfo: {
              skipCount: 0,
              limitCount: 10
            }
          }
        }
      }
    ]
  });

  const [getOrderShipment] = useLazyQuery(GET_ORDER_SHIPMENT);

  const [cancelOrderFun, { loading: isCanCelOrderLoading }] = useMutation(CANCEL_ORDER, {
    onError: (err) => {
      console.error('Cancel order Failed', err);
    }
  });

  useEffect(() => {
    showOrderError(false);
    setShowErrorModal(false);
    addErrorFilter(false);
    setShowErrorFilter(false);
  }, []);

  const [getOrderDetails, { data: orderDetailData }] = useLazyQuery(GET_ORDERS_DETAILS, {
    onCompleted: (data) => {
      if (data?.getOrderDetailsList?.orders?.length > 0) {
        setOrderDetails(data?.getOrderDetailsList?.orders[0]);
      }
      // setIsOpenOrderPreview(true);
    }
  });

  const [getCombinedOrderDetail, { data: combinedOrderData, loading: isLoadingCombinedOrderDetails }] = useLazyQuery(
    GET_COMBINED_ORDER_DETAIL,
    {
      onCompleted: (res) => {
        if (res.getCombineOrderDetail) {
          setOrderDetails(mapCombinedOrderToOrder(res));
          const updatedOrdersList = ordersList.map((orderItem) => {
            if (orderItem.id === res.getCombineOrderDetail.id) {
              return { ...orderItem, childOrders: res.getCombineOrderDetail.childOrders };
            }
            return orderItem;
          });
          setOrdersList(updatedOrdersList);
        }
        // setIsOpenOrderPreview(true);
      },
      onError: (err) => console.log(err)
    }
  );

  const [callOrderShipmentQuery, { loading: isLoadingOrderShipments }] = useLazyQuery(GET_CREATED_ORDER_SHIPMENTS);

  const handleOrderRefund = (orderId: number, refundType: string) => {
    cancelOrderFun({
      variables: {
        input: {
          orderId,
          refundType:
            refundType === OrderRefundType.STORE_CREDIT ? OrderRefundType.STORE_CREDIT : OrderRefundType.ORIGINAL_PAYMENT_METHOD
        }
      }
    }).then((res) => {
      if (res?.data?.cancelOrder.success) {
        const updatedOrdersList = ordersList.filter((orderItem) => orderItem.id !== orderId);
        setOrdersList(updatedOrdersList);
        closeCancelOrderModal();
        showToast({
          successText: 'Order cancelled successfully!',
          message: `Order cancelled successfully. Refund will be initiated shortly.`
        });
      }
    });
  };

  const ChangeSortBy = (field: string, id: number) => {
    field = field === 'Order' ? 'ORDER_CODE' : field;
    setSortBy((d) => ({
      catagory: field,
      id,
      type: d.type === 'DESC' ? 'ASC' : 'DESC'
    }));

    setOpenSortDropdown(false);
  };

  useEffect(() => {
    if (showErrorFilter && selectedTab && selectedTab?.toUpperCase() === 'TO PROCESS') {
      const filtered = ordersList.filter((obj) => errorOrderIds[+obj?.id]);
      setActiveOrderList(filtered);
    } else {
      setActiveOrderList(ordersList);
    }
  }, [showErrorFilter, ordersList]);

  const selectOrder = useCallback(
    (e: any, orderId?: any) => {
      if (e === 'All') {
        setSelectedOrderIds(activeOrderList.map((order) => order.id));
      } else if (e.target.checked) {
        setSelectedOrderIds([...selectedOrderIds, orderId]);
      } else {
        setSelectedOrderIds(selectedOrderIds.filter((orderItemId) => orderItemId !== orderId));
      }
    },
    [activeOrderList, selectedOrderIds]
  );

  const viewOrderDetails = (id: number) => history.push(`/orders/orderDetails/${id}`);

  const printShippingLabel = (id: number) => {
    getOrderShipment({
      variables: {
        input: {
          orderId: id
        }
      }
    }).then((res) => {
      if (res.data?.getOrderShipment?.length > 0) {
        const shipmentDetails = res.data?.getOrderShipment[0];
        const link = document.createElement('a');
        link.href = shipmentDetails?.labelUrl;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
      } else {
        showToast({
          errorText: 'No shipment found!',
          message: 'Please create a shipment in order to view the shipment label.'
        });
      }
    });
  };

  const filterOrderMenu = (orderStatus: string) => {
    if (orderStatus === OrderStatus.SHIPPED) {
      return constVariables.ordersOptionsDropdownData.filter((item) => item.id === 2 || item.id === 4);
    }
    if (orderStatus === OrderStatus.AWAITING_PROCESSING) {
      return constVariables.ordersOptionsDropdownData.filter((item) =>
        role === UserRoles.ADMIN_ASSOCIATE || role === UserRoles.WAREHOUSE_ASSOCIATE
          ? item.id === 2
          : item.id === 2 || item.id === 3
      );
    }
    if (orderStatus === OrderStatus.PENDING) {
      return role === UserRoles.ADMIN_ASSOCIATE || role === UserRoles.WAREHOUSE_ASSOCIATE
        ? []
        : constVariables.ordersOptionsDropdownData.filter((item) => item.id === 3);
    }

    return role === UserRoles.ADMIN_ASSOCIATE || role === UserRoles.WAREHOUSE_ASSOCIATE
      ? []
      : constVariables.ordersOptionsDropdownData.filter((item) => item.id === 2 || item.id === 3);
  };

  const handleOrderMenu = (selectedOption: DropdownOptions, order: OrdersListData) => {
    setSelectedId(order.id);

    switch (selectedOption.id) {
      case 1:
        viewOrderDetails(order.id);
        break;
      case 2:
        checkForOrdrErrors();
        break;
      case 3:
        setIsCancelOrder(true);
        break;
      // case 2:
      //   printPackagingSlip(order.id);
      //   break;
      // case 3:
      //   printShippingLabel(order.id);
      //   break;
      case 4:
        if (order.orderStatus === OrderStatus.AWAITING_FULFILLMENT) {
          history.push(ROUTES.orders.allOrders.fulfillmentCenter, { openId: order.id, orderCode: order.orderCode });
        } else {
          showToast({
            errorText: 'Order cannot be fulfilled!',
            message: 'Current order cannot be fulfilled as it is not yet moved to Fulfill status'
          });
        }
        break;
      case 6:
        break;
      case 7:
        setIsCancelOrder(true);
        break;
      default:
        break;
    }
  };

  const expandOrder = (id: number, index: number) => {
    if (index === selectedIndex) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(index);
      callOrderShipmentQuery({
        variables: {
          input: {
            orderId: id
          }
        }
      })
        .then((res) => {
          if (res.data.getCreatedOrderShipments) {
            const updatedOrdersList = ordersList.map((orderItem) => {
              if (orderItem.id === id) {
                return { ...orderItem, orderShipments: res.data.getCreatedOrderShipments };
              }
              return orderItem;
            });
            setOrdersList(updatedOrdersList);
          }
        })
        .catch((error) => {
          console.log('error::', error);
        });
    }
  };

  const expandChildOrders = (id: number, index: number) => {
    if (index === selectedIndex) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(index);
      getCombinedOrderDetail({
        variables: {
          orderId: id
        }
      });
    }
  };

  useEffect(() => {
    showOrderError(false);
    setShowErrorModal(false);
    addErrorFilter(false);
    setShowErrorFilter(false);
  }, [selectedTab]);

  const openOrderPreview = (id: number, isCombOrdrParent: boolean, order?: OrdersListData) => {
    document.body.style.overflow = 'hidden';
    if (+combinedOrderData?.getCombineOrderDetail?.id === +id) {
      return setOrderDetails(mapCombinedOrderToOrder(combinedOrderData));
    }

    if (+orderDetailData?.getOrderDetailsList?.orders[0]?.id === +id) {
      return setOrderDetails(orderDetailData?.getOrderDetailsList?.orders[0]);
    }

    if (order) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const od: orderDetailsData = {
        id: order?.id,
        createdAt: order?.createdAt,
        orderCode: `${order?.orderCode}`,
        isCombinedOrder: order?.isCombOrdrParent,
        customer: order?.customer,
        thumbnailUrl: order?.thumbnailUrl,
        orderStatus: order?.orderStatus
      };

      setOrderDetails(od);
      setIsOpenOrderPreview(true);
    }

    setIsOpenOrderPreview(true);

    if (!isCombOrdrParent) {
      getOrderDetails({
        variables: {
          input: {
            ids: [id],
            status: [],
            combined: false
          }
        }
      });
    } else {
      getCombinedOrderDetail({
        variables: {
          orderId: id
        }
      });
    }
  };

  const onSelectDate = (data: any) => {
    if (data.name) {
      changingDate(data.name.toUpperCase());
      setSelectedDatesOption(data.name);
      if (data.name.toUpperCase() === 'DATE RANGE') {
        setChooseDatesOption(true);
      }
    }
    setOpenDatesOption(false);
  };
  const openDateOptionsBlock = () => {
    setOpenDatesOption(true);
    setChooseDatesOption(false);
  };
  const closeDateOptionsBlock = () => {
    setOpenDatesOption(false);
    setChooseDatesOption(false);
  };

  useEffect(() => {
    setSelectedOrderIds([]);
  }, [ordersList]);

  const goToFulfilmentCenter = () => {
    history.push(ROUTES.orders.allOrders.fulfillmentCenter);
  };
  const createManifestAPICall = () => {
    createManifest();
  };

  const FulfilOrdersButton = () => (
    <button onClick={goToFulfilmentCenter} className="btn btn-success btn-sm ms-2">
      <img className="h-15px w-15px" src={CheckedOffSquareIcon} />
      <span className="ms-2">{constVariables.orders.fulfillOrders}</span>
    </button>
  );
  const CreateManifestButton = () => (
    <button onClick={createManifestAPICall} className="btn btn-primary d-flex align-items-center btn-sm ms-2">
      {!isCreatingManifest && <img className="h-20px w-20px" src={WhiteAddIcon} />}
      <span className="ms-2">{constVariables.orders.createManifest}</span>
      {isCreatingManifest && <Loader type="button" className="h-20px w-20px" />}
    </button>
  );

  const ErrorHandlingPrintPickSheets = () => {
    return (
      <FlexBox className="align-items-center">
        <button
          onClick={() => checkForOrdrErrors()}
          className="btn btn-primary btn-sm ms-2 d-flex align-items-center justify-content-center"
        >
          <img className="h-15px w-15px" src={PrinterWhite} />
          {isPrintingPackingSlip ? (
            <Loader type="button" className="text-light h-15px w-15px" />
          ) : (
            <span className="ms-2">{constVariables.orders.print}</span>
          )}
        </button>
        {showErrorFilter ? (
          <FlexBox className="align-items-center">
            <div className={`error-badge p-2 mx-2 rounded`}>
              <span className="d-flex justify-content-center align-items-center">
                Errors{' '}
                <KTSVG
                  path={CloseIcon}
                  className="cursor-pointer"
                  svgClassName="badge-error"
                  onClick={() => {
                    showOrderError(false);
                    setShowErrorModal(false);
                    addErrorFilter(false);
                    setShowErrorFilter(false);
                  }}
                />
              </span>
            </div>
            <span
              className="text-primary cursor-pointer"
              onClick={() => {
                showOrderError(false);
                setShowErrorModal(false);
                addErrorFilter(false);
                setShowErrorFilter(false);
              }}
            >
              Clear All
            </span>
          </FlexBox>
        ) : (
          <FlexBox className={`${noOferrorOrderIds > 0 ? 'ms-3 align-items-center' : 'd-none'}`}>
            <KTSVG path={RedExclaimationIcon} />
            <span className="danger-text ms-2">Some orders contain errors</span>
          </FlexBox>
        )}
      </FlexBox>
    );
  };

  const searchOrders = (value) => {
    searchForOrders(value);
  };

  const handleCustomDate = (date: any) => {
    customDateSelector(date);
    if (date) {
      const from = new Date(date.from);
      const to = new Date(date.to);
      const formattedDate = `${moment(from).format('D MMM, YYYY')} - ${moment(to).format('D MMM,YYYY')}`;
      setSelectedDatesOption(formattedDate);
    }
  };

  const closeCancelOrderModal = () => {
    document.body.style.overflow = 'unset';
    setIsCancelOrder(false);
  };

  const closeErrorModal = () => {
    document.body.style.overflow = 'unset';
    setShowErrorModal(false);
  };

  const closeCombinedOrderModal = () => {
    document.body.style.overflow = 'unset';
    setIsShowConfirmCombinedOrderModal(false);
  };

  return (
    <>
      <div className="w-100 orderList">
        <div className={clsx({ 'display-none': totalCount === 0 && !searchText && !selectedDates?.from })}>
          <FlexBox className="justify-content-between w-100">
            <span className="searchBar">
              <SearchBar placeholder={constVariables.AllOrdersMenu.searchForOrders} onChange={searchOrders} />
            </span>
            <FlexBox className="align-items-center pb-1">
              {selectedTab?.toUpperCase() === 'TO SHIP' && (
                <div className="position-relative">
                  <div className="text-primary text-btn cursor-pointer me-3" onClick={() => setShowManifestsDropdown(true)}>
                    Recent Manifests
                  </div>
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      if (showManifestsDropdown) setShowManifestsDropdown(false);
                    }}
                  >
                    <ManifestsDropdown isOpen={showManifestsDropdown} closeDropdown={() => setShowManifestsDropdown(false)} />
                  </OutsideClickHandler>
                </div>
              )}
              <div className="position-relative">
                <OutsideClickHandler onOutsideClick={closeDateOptionsBlock}>
                  <button
                    onClick={openDateOptionsBlock}
                    className="btn optBtn btn-sm btn-flex btn-icon-text align-items-center ms-3"
                  >
                    <KTSVG path={CalendarIcon} className="me-2" />
                    <span className="my-auto me-0">{selectedDatesOption ? selectedDatesOption : 'Date'}</span>
                  </button>
                  <DatesDropdown
                    onselect={onSelectDate}
                    data={DateListData}
                    selected={selectedDatesOption}
                    isOpen={openDatesOption}
                  />
                  <DatesDropdownSelector
                    onselect={handleCustomDate}
                    isOpen={chooseDatesOption}
                    closeDropdown={() => setChooseDatesOption(false)}
                    noRangePicker={false}
                  />
                </OutsideClickHandler>
              </div>
              <div className="position-relative ms-3">
                <OutsideClickHandler
                  onOutsideClick={() => {
                    if (openSortDropdown) setOpenSortDropdown(false);
                  }}
                >
                  <button
                    className={`btn optBtn btn-sm btn-flex btn-icon-text align-items-center ${
                      openSortDropdown ? 'btn-primary' : 'btn-active-primary'
                    }`}
                    onClick={() => {
                      setOpenSortDropdown(!openSortDropdown);
                    }}
                  >
                    <KTSVG path={SortIcon} className="me-2" />
                    <span className="my-auto me-0">Sort</span>
                  </button>
                  <SortDropdown
                    data={SortIds}
                    value={sortBy}
                    onSelect={ChangeSortBy}
                    selected={openSortDropdown}
                    closeDropdown={() => setOpenSortDropdown(false)}
                    type="order"
                  />
                </OutsideClickHandler>
              </div>
              {/* <button className="btn optBtn btn-sm btn-flex btn-light btn-active-primary fw-bolder ms-3">
              <KTSVG path={FilterIcon} className="svg-icon-8 svg-icon-gray-500 me-1" />
              <span className="my-auto me-0 poppins-semibold f-14px">Filter</span>
            </button> */}
            </FlexBox>
          </FlexBox>

          <FlexBox className="m-t-24 justify-content-start align-items-center">
            {selectedOrderIds.length > 0 && (
              <>
                <button className="btn btn-outlined-primary btn-sm me-2" onClick={() => setSelectedOrderIds([])}>
                  <div className="d-flex align-items-center">
                    <input
                      className={'form-check-input small-checkbox-size shadow-none opacity-100 bg-primary rounded-sm mt-0'}
                      type="checkbox"
                      disabled={true}
                      ref={(input) => {
                        if (input) {
                          input.indeterminate = true;
                        }
                      }}
                    />
                    <span className="ms-2">{selectedOrderIds.length} Selected</span>
                  </div>
                </button>
                {/* <button className="btn btn-outlined-primary btn-sm me-2">{constVariables.ProductsTab.moreActions}</button> */}
              </>
            )}

            {selectedTab?.toUpperCase() === 'TO PROCESS' ? <ErrorHandlingPrintPickSheets /> : null}
            {selectedTab?.toUpperCase() === 'TO FULFILL' ? <FulfilOrdersButton /> : null}
            {selectedTab?.toUpperCase() === 'TO SHIP' && totalCount > 0 ? <CreateManifestButton /> : null}
          </FlexBox>
        </div>
        <div className="p-t-12">
          <DataTable
            page="order"
            rowData={activeOrderList}
            columnOptions={columnOptions}
            isLoading={isLoading}
            searchText={searchText}
            selectedIndex={selectedIndex}
            selectedIDs={selectedOrderIds}
            isDetailsLoading={isLoadingCombinedOrderDetails || isLoadingOrderShipments}
            openOrderPreview={openOrderPreview}
            openOrderShippingPreview={openOrderShippingPreview}
            openShipmentDetails={openShipmentDetails}
            sendDate={sendDate}
          />
        </div>
      </div>
      {isCancelOrder && (
        <CustomModal show={isCancelOrder} closeModal={closeCancelOrderModal}>
          <CancelOrder
            title={constVariables.orderDetails.orderCancelModal.title}
            message={constVariables.orderDetails.orderCancelModal.title}
            cancelBtnTitle={constVariables.orderDetails.orderCancelModal.cancel}
            actionBtnHandlerOriginal={() => handleOrderRefund(selectedId, OrderRefundType.ORIGINAL_PAYMENT_METHOD)}
            actionBtnHandlerStoreCredit={() => handleOrderRefund(selectedId, OrderRefundType.STORE_CREDIT)}
            cancelBtnHandler={closeCancelOrderModal}
            cancelOrder={true}
            disableRefundBtn={false}
            isRefunding={isCanCelOrderLoading}
          />
        </CustomModal>
      )}
      {showErrorModal && (
        <CustomModal show={showErrorModal} closeModal={closeErrorModal}>
          <ErrorModal
            type="errorHandling"
            title={constVariables.common.onSaveError.message}
            message=""
            actionTitle={constVariables.common.errorHandlingModal.action}
            actionHandler={() => checkOrdersForCombining()}
            cancelHandler={closeErrorModal}
            correctHandler={() => {
              addErrorFilter(true);
              setShowErrorFilter(true);
              setShowErrorModal(false);
              setSelectedOrderIds([]);
              closeErrorModal();
            }}
            cancelTitle={constVariables.common.errorHandlingModal.cancelMessage}
            errors={['Shipping Address is unverified', 'Incorrect item weight']}
          />
        </CustomModal>
      )}
      {isShowConfirmCombinedOrderModal && (
        <CustomModal show={isShowConfirmCombinedOrderModal} closeModal={closeCombinedOrderModal}>
          <ConfirmationCombinedOrderModal
            closeModal={closeCombinedOrderModal}
            onNo={() => printPackingSlip()}
            handleOrdersCombineAndPackingSlip={() => combineAndPrintPackingSlip()}
            isAutomaticCombiningEnabled={isAutomaticCombiningEnabled}
          />
        </CustomModal>
      )}
    </>
  );
};

export default memo(OrdersList);
