import { FC, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import WorldData from 'country-state-city-plus';

// Apollo
import { useLazyQuery, useMutation } from '@apollo/client';
import { AUTO_COMPLETE_ADDRESS } from 'src/apollo/queries';
import { DELETE_USER_ADDRESS, UPDATE_PROFILE_INFO, ADD_ADDRESS } from 'src/apollo/mutations';

// Components
import { FlexBox, RadioButton } from 'src/components/atoms';
import { Dropdown, InputBox } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { BookmarkIcon, DownArrowIcon, NoAddressIllustration, ThreeDotsIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { AddressBlockCDType, AddressCDType } from 'src/components/pages/customers/CustomerDetails/CustomerDetails.types';
import {
  CustomerAddressDataProps,
  CustomerAddressDropdownProps
} from 'src/components/oraganisms/AddCustomerAddress/AddCustomerAddress.types';

interface ShippingAddressType extends AddressCDType {
  openAddressModal: () => void;
  refetchCustomerDetails: () => void;
  customerId: string;
}
const ShippingAddressDetails: FC<ShippingAddressType> = ({
  userAddress,
  customerId,
  freeShipping,
  openAddressModal,
  refetchCustomerDetails
}) => {
  const [countryList, setCountryList] = useState<CustomerAddressDropdownProps[]>();
  const [cityList, setCityList] = useState<CustomerAddressDropdownProps[]>();
  const [countryListFiltered, setCountryListFiltered] = useState<CustomerAddressDropdownProps[]>();
  const [addressListFiltered, setAddressListFiltered] = useState<CustomerAddressDropdownProps[]>();
  const [stateList, setStateList] = useState<CustomerAddressDropdownProps[]>();
  const [cityListFiltered, setCityListFiltered] = useState<CustomerAddressDropdownProps[]>();
  const [selectedAddressId, setSelectedAddressId] = useState(-1);
  const [addressList, setAddressList] = useState([]);
  const [customerAddressData, setCustomerAddressData] = useState<CustomerAddressDataProps>({
    reciverName: '',
    emailAddress: '',
    addressLineOne: '',
    addressError: false,
    addressLineTwo: '',
    postalCode: '',
    phoneNumber: '',
    countryDropDown: false,
    selectedCountryCode: '',
    selectedCountryId: '',
    selectedCountry: '',
    cityDropDown: false,
    selectedCity: '',
    stateDropDown: false,
    selectedState: '',
    addressDropDownOne: false,
    addressDropDownTwo: false,
    selectedStateCode: '',
    phoneDropDown: false,
    selectedPhone: '',
    addressType: '',
    defaultShippingAddress: false
  });

  const [freeShippingChecked, setFreeShippingChecked] = useState(false);
  const [addressMenu, setAddressMenu] = useState<number>(-1);
  const [editAddressCheck, setEditAddressCheck] = useState(-1);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  const [autocompleteAddress] = useLazyQuery(AUTO_COMPLETE_ADDRESS, {
    variables: { input: { street: customerAddressData.addressLineOne } },
    onCompleted: (data) => {
      if (data?.autoComplete) {
        const tempList = data?.autoComplete.map((data, index) => {
          return { id: index, name: `${data.street_line}, ${data.city}, ${data.state}` };
        });
        setAddressList(data?.autoComplete);
        setAddressListFiltered(tempList);
      }
    }
  });

  const [callEditAddress] = useMutation(ADD_ADDRESS, {
    onCompleted: () => {
      setEditAddressCheck(-1);
      setSaveButtonDisabled(false);
      refetchCustomerDetails();
    },
    onError: (error) => {
      setSaveButtonDisabled(false);
      console.error('Edit address error', error);
    }
  });
  const [callDeleteAddress] = useMutation(DELETE_USER_ADDRESS, {
    onCompleted: () => {
      closeAddressMenu();
      refetchCustomerDetails();
    },
    onError: (error) => {
      closeAddressMenu();
      console.error('Remove Address error', error);
    }
  });
  const editCustomerAddress = () => {
    setSaveButtonDisabled(true);

    callEditAddress({
      variables: {
        input: {
          userId: customerId,
          id: selectedAddressId,
          receiverName: customerAddressData.reciverName,
          receiverMobileCountryCode: customerAddressData.selectedPhone,
          receiverMobileNumber: customerAddressData.phoneNumber,
          addressLine1: customerAddressData.addressLineOne,
          city: customerAddressData.selectedCity,
          state: customerAddressData.selectedState,
          country: customerAddressData.selectedCountry,
          pincode: customerAddressData.postalCode,
          addressType: customerAddressData.addressType,
          isPrimary: customerAddressData.defaultShippingAddress,
          phoneNumber: customerAddressData.phoneNumber
        }
      }
    });
  };
  const deleteCustomerAddress = (idData) => {
    callDeleteAddress({
      variables: {
        input: {
          userId: customerId,
          addressId: idData
        }
      }
    });
  };
  const openAddressMenu = (id) => {
    setAddressMenu(id);
  };
  const closeAddressMenu = () => {
    setAddressMenu(-1);
  };

  const toggleFreeShipping = () => {
    setFreeShippingChecked(!freeShippingChecked);
  };
  const [setDefaultAddressMutation] = useMutation(UPDATE_PROFILE_INFO, {
    onCompleted: () => {
      refetchCustomerDetails();
    },
    onError: (error) => {
      console.error('Set default address error- ', error);
    }
  });
  const selectedOption = (data) => {
    const value = userAddress.findIndex((filterData) => filterData.id === addressMenu);
    if (data.name.toUpperCase() === 'EDIT ADDRESS') {
      setEditAddressCheck(addressMenu);

      if (userAddress[value]) {
        setSelectedAddressId(userAddress[value].id);
        setCustomerAddressData((prev) => ({
          ...prev,
          selectedCountry: userAddress[value].country,
          addressLineOne: userAddress[value].addressLine1,
          postalCode: userAddress[value].pincode,
          selectedCity: userAddress[value].city,
          addressType: userAddress[value].type
        }));
      }
    } else if (data.name.toUpperCase() === 'DELETE ADDRESS') {
      if (userAddress[value]) {
        setTimeout(() => {
          deleteCustomerAddress(userAddress[value].id);
        }, 500);
      }
    } else if (data.name.toUpperCase() === 'MAKE AS PRIMARY ADDRESS') {
      if (userAddress[value]) {
        setDefaultAddressMutation({
          variables: {
            input: {
              userId: customerId,
              updateProfileInfoType: 'PRIMARY_ADDRESS',
              newValue: userAddress[value].id
            }
          }
        });
      }
    }
    closeAddressMenu();
  };

  const cancelEditAddressCheck = () => {
    setEditAddressCheck(-1);
    setCustomerAddressData({
      reciverName: '',
      emailAddress: '',
      addressLineOne: '',
      addressError: false,
      addressLineTwo: '',
      postalCode: '',
      phoneNumber: '',
      countryDropDown: false,
      selectedCountryCode: '',
      selectedCountryId: '',
      selectedCountry: '',
      cityDropDown: false,
      selectedCity: '',
      stateDropDown: false,
      selectedState: '',
      addressDropDownOne: false,
      addressDropDownTwo: false,
      selectedStateCode: '',
      phoneDropDown: false,
      selectedPhone: '',
      addressType: '',
      defaultShippingAddress: false
    });
  };

  const getCountryList = () => {
    const getAllCountries = WorldData.getAllCountries();
    if (getAllCountries) {
      const tempCountryList = getAllCountries.map((data, index) => {
        return { id: index, name: data.name, extraData: data.id, countryCode: data.sortname };
      });
      setCountryList(tempCountryList);
    }
  };
  const getStateData = () => {
    const tempStateArray = WorldData.getStatesOfCountry(customerAddressData.selectedCountryId);
    if (tempStateArray) {
      const tempList = tempStateArray.map((data, index) => {
        return { id: index, name: data.name, extraData: data.id };
      });
      setStateList(tempList);
    }
  };
  const getCityData = () => {
    const tempCityArray = WorldData.getCitiesOfState(customerAddressData.selectedStateCode);

    if (tempCityArray) {
      const tempList = tempCityArray.map((data, index) => {
        return { id: index, name: data.name };
      });
      setCityList(tempList);
    }
  };

  const onSelectCountryCode = (data: any) => {
    setCustomerAddressData((prev) => ({
      ...prev,
      selectedCountry: data.countryCode,
      selectedCountryId: data.extraData,
      selectedCountryCode: data.countryCode,
      countryDropDown: !prev.countryDropDown
    }));
  };

  const toggleAddressDropDownOne = () => {
    setCustomerAddressData((prev) => ({
      ...prev,
      addressDropDownOne: !prev.addressDropDownOne
    }));
  };

  const changeCountry = (data: string) => {
    const tempList = countryList?.filter((listData) => listData.name.toUpperCase().indexOf(data.toUpperCase()) !== -1);
    setCountryListFiltered(tempList);
    setCustomerAddressData((prev) => ({
      ...prev,
      selectedCountry: data
    }));
  };
  const toggleCityDropDown = () => {
    if (cityList) {
      setCustomerAddressData((prev) => ({
        ...prev,
        cityDropDown: !prev.cityDropDown
      }));
    }
  };

  const toggleCountryCodeDropDown = () => {
    if (countryList) {
      setCustomerAddressData((prev) => ({
        ...prev,
        countryDropDown: !prev.countryDropDown
      }));
    }
  };
  const toggleAddressDropDownTwo = () => {
    setCustomerAddressData((prev) => ({
      ...prev,
      addressDropDownTwo: !prev.addressDropDownTwo
    }));
  };
  const changeAddressLineOne = (data: string) => {
    setCustomerAddressData((prev) => ({
      ...prev,
      addressLineOne: data
    }));
  };
  const changePostalCode = (data: any) => {
    if (!isNaN(data)) {
      setCustomerAddressData((prev) => ({
        ...prev,
        postalCode: data
      }));
    }
  };
  const changeAddressLineTwo = (data: string) => {
    setCustomerAddressData((prev) => ({
      ...prev,
      addressLineTwo: data
    }));
  };
  const changeCity = (data: string) => {
    const tempList = cityList?.filter((listData) => listData.name.toUpperCase().indexOf(data.toUpperCase()) !== -1);
    setCityListFiltered(tempList);
    setCustomerAddressData((prev) => ({
      ...prev,
      selectedCity: data
    }));
  };
  const onSelectCity = (data: any) => {
    setCustomerAddressData((prev) => ({
      ...prev,
      selectedCity: data.name,
      cityDropDown: !prev.cityDropDown
    }));
  };
  const onSelectAddress = (data: any) => {
    const result: {
      zipcode: string;
      state: string;
      city: string;
      // eslint-disable-next-line camelcase
      street_line: string;
    } =
      // esline-disable-next-line
      addressList.find((findData: any) => data.name === `${findData?.street_line}, ${findData.city}, ${findData.state}`) || {
        zipcode: '',
        state: '',
        city: '',
        // eslint-disable-next-line camelcase
        street_line: ''
      };
    setCustomerAddressData((prev) => ({
      ...prev,
      addressLineOne: result.street_line,
      addressDropDownOne: false,
      addressDropDownTwo: false,
      postalCode: result?.zipcode,
      selectedCountry: 'US',
      selectedCountryCode: 'US',
      selectedPhone: '+1',
      selectedState: result?.state,
      selectedCity: result?.city
    }));
  };
  const changeAddressType = (data) => {
    setCustomerAddressData((prev) => ({
      ...prev,
      addressType: data
    }));
  };
  const setHomeAddress = () => {
    changeAddressType('HOME');
  };
  const setOfficeAddress = () => {
    changeAddressType('WORK');
  };
  const setOtherAddress = () => {
    changeAddressType('OTHERS');
  };

  useEffect(() => {
    if (countryList) {
      setCountryListFiltered(countryList);
    }
  }, [countryList]);
  useEffect(() => {
    getCountryList();
  }, []);
  useEffect(() => {
    setFreeShippingChecked(freeShipping);
  }, [freeShipping]);
  useEffect(() => {
    autocompleteAddress();
  }, [customerAddressData.addressLineOne]);
  useEffect(() => {
    if (stateList) {
      getCityData();
    }
  }, [customerAddressData.selectedStateCode]);
  useEffect(() => {
    if (customerAddressData.selectedCountryCode) {
      getStateData();
    }
  }, [customerAddressData.selectedCountryCode]);
  useEffect(() => {
    if (cityList) {
      setCityListFiltered(cityList);
    }
  }, [cityList]);

  const EditAddressBlock: FC<AddressBlockCDType> = (address, index) => {
    return (
      <div className="my-3 py-3 border-top border-bottom" key={index}>
        {/* COUNTRY */}

        <div className="w-100 ">
          <div className="text-nowrap poppins-semibold fs-6 mb-2">Country/Region</div>
          <OutsideClickHandler
            onOutsideClick={() => {
              setCustomerAddressData((prev) => ({
                ...prev,
                countryDropDown: false
              }));
            }}
          >
            <div className="position-relative">
              <FlexBox
                className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box `}
                onClick={toggleCountryCodeDropDown}
              >
                <InputBox
                  value={customerAddressData?.selectedCountry}
                  onChangeText={changeCountry}
                  name={constVariables.customerDetails.addCustomerAddress.country}
                  placeholder={constVariables.customerDetails.addCustomerAddress.countryPH}
                  noBorders
                />
                <KTSVG path={DownArrowIcon} />
              </FlexBox>
              {countryListFiltered && countryListFiltered.length > 0 && (
                <Dropdown
                  className="dropdown-custom-width"
                  data={countryListFiltered}
                  selected={customerAddressData?.countryDropDown}
                  value={customerAddressData?.selectedCountryCode}
                  onSelect={onSelectCountryCode}
                  closeDropdown={() =>
                    setCustomerAddressData((prev) => ({
                      ...prev,
                      countryDropDown: false
                    }))
                  }
                />
              )}
            </div>
          </OutsideClickHandler>
        </div>

        {/* ADDRESS */}

        <div className="position-relative mt-3 w-100">
          <div className="text-nowrap poppins-semibold fs-6 mb-2">{constVariables.customerDetails.addCustomerAddress.address}</div>
          <div onClick={toggleAddressDropDownOne}>
            <InputBox
              inputClass="form-control-lg"
              value={customerAddressData?.addressLineOne}
              onChangeText={changeAddressLineOne}
              name={constVariables.customerDetails.addCustomerAddress.address}
              placeholder={constVariables.customerDetails.addCustomerAddress.addressPH}
            />
          </div>

          {addressListFiltered && customerAddressData?.addressLineOne.length > 0 && addressListFiltered.length > 0 && (
            <OutsideClickHandler
              onOutsideClick={() => {
                if (customerAddressData.addressDropDownOne) {
                  toggleAddressDropDownOne();
                }
              }}
            >
              <Dropdown
                className="dropdown-custom-width"
                data={addressListFiltered}
                selected={customerAddressData.addressDropDownOne}
                value={customerAddressData?.addressLineOne}
                onSelect={onSelectAddress}
                closeDropdown={() =>
                  setCustomerAddressData((prev) => ({
                    ...prev,
                    addressDropDownOne: false
                  }))
                }
              />
            </OutsideClickHandler>
          )}
        </div>

        {/* ADDRESS TWO */}

        <div className="position-relative mt-3 w-100 ">
          <div className="text-nowrap poppins-semibold fs-6 mb-2">
            {constVariables.customerDetails.addCustomerAddress.apartment}
          </div>
          <div onClick={toggleAddressDropDownTwo}>
            <InputBox
              inputClass="form-control-lg"
              value={customerAddressData?.addressLineTwo}
              onChangeText={changeAddressLineTwo}
              name={constVariables.customerDetails.addCustomerAddress.apartment}
              placeholder={constVariables.customerDetails.addCustomerAddress.apartmentPH}
            />
          </div>

          {addressListFiltered && customerAddressData?.addressLineTwo.length > 0 && addressListFiltered.length > 0 && (
            <OutsideClickHandler
              onOutsideClick={() => {
                if (customerAddressData.addressDropDownTwo) {
                  toggleAddressDropDownTwo();
                }
              }}
            >
              <Dropdown
                className="dropdown-custom-width"
                data={addressListFiltered}
                selected={customerAddressData.addressDropDownTwo}
                value={customerAddressData?.addressLineOne}
                onSelect={onSelectAddress}
                closeDropdown={() =>
                  setCustomerAddressData((prev) => ({
                    ...prev,
                    addressDropDownTwo: false
                  }))
                }
              />
            </OutsideClickHandler>
          )}
        </div>

        {/* POSTAL CODE */}

        <div className="w-100 mt-3">
          <div className="text-nowrap poppins-semibold fs-6 mb-2">
            {constVariables.customerDetails.addCustomerAddress.postalCode}
          </div>
          <InputBox
            inputClass="form-control-lg"
            value={customerAddressData?.postalCode}
            onChangeText={changePostalCode}
            name={constVariables.customerDetails.addCustomerAddress.postalCode}
            placeholder={constVariables.customerDetails.addCustomerAddress.postalCodePH}
            charLimit={10}
          />
        </div>

        {/* CITY */}

        <div className="w-100 mt-3">
          <div className="text-nowrap poppins-semibold fs-6 mb-2">{constVariables.customerDetails.addCustomerAddress.city}</div>
          <OutsideClickHandler
            onOutsideClick={() => {
              setCustomerAddressData((prev) => ({
                ...prev,
                cityDropDown: false
              }));
            }}
          >
            <div className="position-relative">
              <FlexBox
                className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box `}
                onClick={toggleCityDropDown}
              >
                <InputBox
                  value={customerAddressData?.selectedCity}
                  onChangeText={changeCity}
                  name={constVariables.customerDetails.addCustomerAddress.city}
                  placeholder={constVariables.customerDetails.addCustomerAddress.cityPH}
                  noBorders
                />
                <KTSVG path={DownArrowIcon} />
              </FlexBox>

              {cityListFiltered && cityListFiltered.length > 0 && (
                <Dropdown
                  className="dropdown-custom-width"
                  data={cityListFiltered}
                  selected={customerAddressData?.cityDropDown}
                  value={customerAddressData?.selectedCity}
                  onSelect={onSelectCity}
                  closeDropdown={() =>
                    setCustomerAddressData((prev) => ({
                      ...prev,
                      cityDropDown: false
                    }))
                  }
                />
              )}
            </div>
          </OutsideClickHandler>
        </div>

        {/* ADDRESS TYPE */}

        <div className=" w-100 mt-3">
          <div className="text-nowrap poppins-semibold fs-6 mb-2">
            {constVariables.customerDetails.addCustomerAddress.addressType}
          </div>
          <div className="form-control form-control-lg border-0 px-0 align-items-center d-flex">
            <div className="d-flex align-items-center">
              <RadioButton
                id="customerHomeAddress"
                name="customerHomeAddress"
                isChecked={customerAddressData?.addressType.toUpperCase() === 'HOME'}
                handleChange={setHomeAddress}
                noMargin
              />
              <div className="ms-2 poppins-medium fs-6">Home</div>
            </div>
            <div className="ms-2 d-flex align-items-center">
              <RadioButton
                id="customerOfficeAddress"
                name="customerOfficeAddress"
                isChecked={customerAddressData?.addressType.toUpperCase() === 'WORK'}
                handleChange={setOfficeAddress}
              />
              <div className="ms-2 poppins-medium fs-6">Office</div>
            </div>
            <div className="ms-2 d-flex align-items-center">
              <RadioButton
                id="customerOtherAddress"
                name="customerOtherAddress"
                isChecked={customerAddressData?.addressType.toUpperCase() === 'OTHERS'}
                handleChange={setOtherAddress}
              />
              <div className="ms-2 poppins-medium fs-6">Other</div>
            </div>
          </div>
        </div>

        {/* SAVE  CANCEL BUTTONS */}
        <div className="d-flex w-100 mt-5 align-items-center justify-content-end">
          <button onClick={cancelEditAddressCheck} type="button" className="btn btn-outlined-secondary me-2">
            Cancel
          </button>
          <button onClick={editCustomerAddress} type="button" className="btn btn-primary ms-2" disabled={saveButtonDisabled}>
            Save
          </button>
        </div>
      </div>
    );
  };

  const AddressBlock: FC<AddressBlockCDType> = ({ address }) => {
    return (
      <div className="mt-6">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="fs-5">{address.type}</div>
            {address.isPrimary ? <img src={BookmarkIcon} className="ms-2 h-20px" /> : null}
          </div>
          <OutsideClickHandler
            onOutsideClick={() => {
              if (addressMenu === address.id) {
                closeAddressMenu();
              }
            }}
          >
            <span className="position-relative ms-4">
              <img
                src={ThreeDotsIcon}
                className="dropdown-toggle cursor-pointer h-10px "
                id="shippingAddressDropdownToggle"
                onClick={() => {
                  if (addressMenu === address.id) {
                    closeAddressMenu();
                  } else {
                    openAddressMenu(address.id);
                  }
                }}
              />
              <Dropdown
                data={
                  address.isPrimary
                    ? constVariables.customerAddressOptionsPrimaryDropdownData
                    : constVariables.customerAddressOptionsDropdownData
                }
                onSelect={selectedOption}
                shiftLeft
                shiftLeftValue={address.isPrimary ? '-130px' : '-200px'}
                selected={address.id === addressMenu}
                className="mt-5"
                closeDropdown={closeAddressMenu}
              />
            </span>
          </OutsideClickHandler>
        </div>
        {address.addressLine1 ? <div className="mt-2 fs-6">{address.addressLine1}</div> : null}
        <div className="helper-text mt-2 fs-7">{`${address.city ? address.city : null}, ${address.state ? address.state : null} ${
          address.pincode ? address.pincode : null
        }`}</div>
      </div>
    );
  };
  return (
    <div>
      <h4 className="section-title m-b-32">{constVariables.customers.shippingAddress}</h4>
      {/* <div className="mt-4 d-flex align-items-center ">
        <div className="form-check form-switch form-switch-sm form-check-custom">
          <input className="form-check-input" type="checkbox" checked={freeShippingChecked} onChange={toggleFreeShipping} />
          <div className="ms-2">{constVariables.customers.freeShipping}</div>
        </div>
      </div> */}
      {userAddress ? (
        userAddress.map((data, index) =>
          data.id === editAddressCheck ? (
            EditAddressBlock({ address: data, key: index })
          ) : (
            <AddressBlock address={data} key={index} />
          )
        )
      ) : (
        <div className="d-flex align-items-center flex-column">
          <img className="mt-4" src={NoAddressIllustration} />
          <div className="mt-4">No Address Found</div>
          <div className="mt-4 helper-text text-center">The shipping address is empty, you can add address with below button.</div>
        </div>
      )}
      <div className="mt-6 w-100 ">
        <button onClick={openAddressModal} type="button" className="w-100 btn btn-secondary btn-md">
          Add New Address
        </button>
      </div>
    </div>
  );
};

export default ShippingAddressDetails;
