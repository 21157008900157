/* eslint-disable no-nested-ternary */
import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

// Apollo
import { useMutation } from '@apollo/client';
import { RESEND_VERIFY_USER_EMAIL, VERIFY_USER_EMAIL } from 'src/apollo/mutations';

// Hooks && Utils && Helpers
import { validateEmail } from 'src/utils/validate';
import { getAccountDetailsFromToken, isTokenExpired } from 'src/utils/authentication/decodeToken';

// Icons
import { Ring, SignUpBySocial } from 'src/assets/images';
import { CaretLeft } from 'src/assets/icons';

// ConstVariables
import { constVariables, envUrls } from 'src/constants';

// Styles
import './_verifyEmailToken.scss';
import 'src/components/pages/auth/Signup/_signUp.scss';
import 'src/components/oraganisms/CustomModal/modalStyles.scss';

const VerifyEmail: FC = (props) => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const accountInfo = getAccountDetailsFromToken(token);

  useEffect(() => {
    verifyMail({
      context: {
        headers: {
          token,
          tenantid: accountInfo.tenantId,
          'x-api-key': envUrls.authXApiKey
        }
      },
      variables: {
        input: {
          email: accountInfo.email,
          isBackStoreUser: true
        },
        token,
        tenantid: accountInfo.tenantId
      }
    });
  }, []);

  const [genericMsg, setGenericMsg] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('Please Enter Email');

  const [verifyMail, { loading: verifyMailLoading, error: verifyMailError }] = useMutation(VERIFY_USER_EMAIL, {
    onCompleted: () => {
      setInterval(() => {
        history.push('/login');
      }, 3000);
    },
    onError: (e) => {
      console.error(e);
    }
  });

  const [resendVerifyEmail, { loading }] = useMutation(RESEND_VERIFY_USER_EMAIL, {
    onCompleted: () => {
      setGenericMsg('Reset Link Send Successfully, Please check you mail!');
    },
    onError: (e) => {
      if (e.message === 'USER_NOT_FOUND') {
        setGenericMsg('This email is not valid or not registered');
      } else {
        setGenericMsg('Something went wrong! Please try again!');
      }
    }
  });

  const handleBack = () => {
    history.push('/signup');
  };

  const handleEmailInputChange = (validationFunc: any, e: any) => {
    const field = e.target.name;
    const fieldVal = e.target.value;

    setError(validationFunc(fieldVal));

    if (field) {
      setEmail(fieldVal);
    }
  };

  const handleResentVerifyEmailButton = () => {
    resendVerifyEmail({
      context: {
        headers: {
          authorization: token,
          tenantid: accountInfo.tenantId,
          'x-api-key': envUrls.authXApiKey
        }
      },
      variables: {
        input: {
          email,
          isBackStoreUser: true
        },
        tenantid: accountInfo.tenantId
      }
    });
  };

  return (
    <>
      {isTokenExpired(accountInfo.exp) ? (
        <div className="signup d-flex gradient flex-row ">
          <div className="form-container padding-style">
            {/* left panel */}
            <div className={`overflow-hidden bg-white content-container height-container`}>
              <div className="btn-back" onClick={handleBack}>
                <img src={CaretLeft} alt="Back" />
                <p>Back</p>
              </div>
              <img src={SignUpBySocial} alt={SignUpBySocial} className="image-dimension brand-logo" />

              <div className={`text-danger fs-8`}>{genericMsg}</div>

              <div className="poppins-regular title-style m-0 auth-header">
                <p>{constVariables.signUp.OopsLinkExpired}</p>
              </div>

              <div className="content-section">
                <span className="email-sentence">{constVariables.signUp.LinkExpiredContent}</span>
              </div>

              <div className="email-section">
                <span>Email</span>
              </div>

              <input
                autoComplete="off"
                className={`form-control form-control-solid hp-50px ${error ? 'red-border' : 'grey-border'}`}
                id="email"
                name="email"
                value={email}
                onChange={(e) => handleEmailInputChange(validateEmail, e)}
                type="text"
                placeholder="e.g. John@demo.com"
              />

              <div className={`text-danger fs-8`}>{error}</div>

              <div className="m-t-16">
                <div className="button-container">
                  <div className="button margin-style m-0">
                    <button
                      disabled={loading || !!error}
                      onClick={handleResentVerifyEmailButton}
                      className="btn btn-primary social-btn w-100 button-padding flex-center m-0"
                    >
                      {constVariables.signUp.sentVerificationEmail}
                      {loading && <div className="spinner-border text-primary auth-flow"></div>}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : verifyMailLoading ? (
        <div className="vw-100 vh-100 d-flex flex-column justify-content-center align-items-center">
          <div className="spinner">
            <img src={Ring} role="status"></img>
          </div>
          <p className="store-text">Your store is being created</p>
        </div>
      ) : verifyMailError ? (
        <div className="vw-100 vh-100 d-flex flex-column justify-content-center align-items-center">
          <p className="store-text">Some Error Occurred, please try again later!</p>
        </div>
      ) : (
        <div className="vw-100 vh-100 d-flex flex-column justify-content-center align-items-center">
          <p className="store-text">Your store is created Successfully</p>
        </div>
      )}
    </>
  );
};

export default VerifyEmail;
