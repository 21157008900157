export interface CatalogTypeType {
  value: 'MANUAL' | 'AUTOMATIC' | undefined;
}

export interface ProductsCategoryType {
  value: 'MANUAL' | 'AUTOMATIC' | undefined;
}
export interface FeaturedProductsStatusType {
  value: 'ACTIVE' | 'INACTIVE' | 'SCHEDULED' | undefined;
}
export interface CatalogProductListType {
  autoMaticProductListIds: number[];
  manualProductListIds: number[];
  selectedProductsIds: number[];
}

export interface FeaturedProductsTypeSection {
  selectedProductAssignmentMethod?: productAssignmentMethod;
  selectProductAssignmentMethod: (productAssignmentType: productAssignmentMethod) => void;
}

export interface FeaturedProductsStatusTypeSection {
  featuredProductsStatus: FeaturedProductsStatusType;
  setFeaturedProductsStatus: React.Dispatch<React.SetStateAction<FeaturedProductsStatusType>>;
  selectedProductAssignmentMethod?: productAssignmentMethod;
  setFormChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface FeaturedProductListTypes extends FeaturedProductListTypeSection {
  isAutoFeaturedProduct: boolean;
  hasConditions: boolean;
  searchText: string;
}
export interface FeaturedProductListTypeSection {
  productListData: CatalogProductListType;
  setProductListData: React.Dispatch<React.SetStateAction<CatalogProductListType>>;
  deleteProduct: (ids: number[]) => void;
}

export interface FeaturedProductsPresentationalProps
  extends FeaturedProductsStatusTypeSection,
    FeaturedProductsTypeSection,
    FeaturedProductListTypeSection {
  goBackToCatalogScreen: () => void;

  // Rules engine
  selectedProductAssignmentMethod?: productAssignmentMethod;
  allConditionMatch: boolean;
  setAllConditionMatch: React.Dispatch<React.SetStateAction<boolean>>;
  selectProductAssignmentMethod: (productAssignmentType: productAssignmentMethod) => void;
  rulesEngineConditions: {
    field: { id: number; name?: string };
    condition: string;
    category: string | { name: string; id: number }[];
    errorMessage?: string;
    openFieldDropdown?: boolean;
    openCategoryDropdown?: boolean;
    openConditionDropdown?: boolean;
  }[];
  setRulesEngineConditions: React.Dispatch<
    React.SetStateAction<
      {
        field: { id: number; name?: string };
        condition: string;
        category: string | { name: string; id: number }[];
        errorMessage?: string;
        openFieldDropdown?: boolean;
        openCategoryDropdown?: boolean;
        openConditionDropdown?: boolean;
      }[]
    >
  >;
  fieldDropdown: {
    id: number;
    name: string;
    operations: { name: string }[];
  }[];
  conditionDropdownArray: {
    categoryDropdown: { id: number; name: string }[];
  }[];
  setConditionDropdownArray: React.Dispatch<
    React.SetStateAction<
      {
        categoryDropdown: {
          name: string;
          id: number;
        }[];
      }[]
    >
  >;
  formChanges: boolean;
  setFormChanges: React.Dispatch<React.SetStateAction<boolean>>;
  rulesChanges: boolean;
  setRulesChanges: React.Dispatch<React.SetStateAction<boolean>>;
  saveFeaturedProducts: () => void;
  isPageLoading: boolean;
  // Rules engine
}

export type FieldConditionType = {
  id: number;
  name: string;
  operations: { name: string }[];
};

export type ConditionType = {
  field: {
    id: number;
    name?: string;
  };
  condition: string;
  category: string | { name: string; id: number }[];
  errorMessage?: string;
  openFieldDropdown?: boolean;
  openConditionDropdown?: boolean;
  openCategoryDropdown?: boolean;
};

export type JoinConditionType = {
  conditionId?: number;
  operation?: string;
  value?: string;
  field?: {
    id: number;
    name?: string;
  };
  condition?: string;
  category?: string | { name: string; id: number }[];
  openFieldDropdown?: boolean;
  openConditionDropdown?: boolean;
  openCategoryDropdown?: boolean;
};

export enum FeaturedProductStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SCHEDULED = 'SCHEDULED'
}

export enum productAssignmentMethod {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC'
}
