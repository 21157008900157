import { FC } from 'react';
import clsx from 'clsx';

// Components
import { FlexBox } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

// Types
import { AddOrSubtractModalProps } from './Modal.types';

// Styles
import './modalStyles.scss';

const AddOrSubtractModal: FC<AddOrSubtractModalProps> = ({
  title,
  message,
  actionBtnTitle,
  cancelBtnTitle,
  actionBtnHandler,
  cancelBtnHandler,
  value,
  setValue,
  selectedTitle,
  isSubtract
}) => {
  const onKeyDown = (e) => {
    if (e.which === 13 && +value > 0 && actionBtnHandler) {
      actionBtnHandler(e);
    } else if (e.which !== 8 && !(e.which >= 48 && e.which <= 57)) {
      e.preventDefault();
    }
  };

  const onChange = (e) => {
    if (e.target.value > 0) {
      setValue(e.target.value);
    }
  };

  return (
    <div
      className={clsx('modal-content', {
        'modal-large-content': true
      })}
    >
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={cancelBtnHandler}>
          <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
        </div>
      </div>
      <div className={clsx('modal-body p-x-30 p-y-36')}>
        <p className="poppins-medium f-16 m-b-16">{message}</p>
        <FlexBox className="ms-0 rounded">
          <div className="d-flex align-items-center w-200px border p-x-12 p-y-8 text-muted rounded poppins-semibold">
            <div>$</div>
            <input
              placeholder={constVariables.customers.addStoreCreditListPlaceholder}
              value={value}
              onChange={onChange}
              className="d-flex flex-fill border-none w-150px ms-2"
              // onKeyDown={onKeyDown}
              type="number"
            />
          </div>
        </FlexBox>
      </div>
      <div className="modal-footer justify-content-between">
        {selectedTitle && <div className="m-0">{selectedTitle}</div>}
        <FlexBox className="d-flex align-items-center justify-content-end m-0">
          <button type="button" className="btn btn-outlined-secondary btn-md" onClick={cancelBtnHandler}>
            {cancelBtnTitle}
          </button>
          {isSubtract ? (
            <button type="button" className="btn btn-danger m-0 btn-md" onClick={actionBtnHandler}>
              {actionBtnTitle}
            </button>
          ) : (
            <button type="button" className="btn btn-primary m-0 btn-md" onClick={actionBtnHandler}>
              {actionBtnTitle}
            </button>
          )}
        </FlexBox>
      </div>
    </div>
  );
};

export default AddOrSubtractModal;
