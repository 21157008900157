import { gql } from '@apollo/client/core';

export const GET_LIVE_VARIANT_ANALYTICS = gql`
  query GetLiveVariantAnalytics($id: String!) @api(name: "liveStreamingAPI") {
    getLiveVariantAnalytics(liveProductId: $id) {
      id
      title
      imageUrl
      orders {
        live
        vod
        total
      }
      price {
        live
        vod
        total
      }
      revenue {
        live
        vod
        total
      }
    }
  }
`;
