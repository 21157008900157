import { gql } from '@apollo/client';

export const TOGGLE_SEZZLE = gql`
  mutation ToggleSezzle($input: SezzleToggle) @api(name: "productsAPI") {
    toggleSezzle(input: $input) {
      __typename
      enable
    }
  }
`;
