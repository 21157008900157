import { FC, useState, useEffect } from 'react';

// Apollo
import { useLazyQuery } from '@apollo/client';
import { GET_PRODUCT_BASIC_DETAILS, GET_PRODUCT_BY_LABEL } from 'src/apollo/queries';

// Components
import CreateLiveShowProductsStepPresentational from './CreateLiveShowProductsStep.presentational';

// Hooks && Utils && Helpers
import { ScanBarcode } from 'src/utils/ScanBarcode/ScanBarcode';

// Types
import { LiveShowDetailInfo } from '../CreateLiveShowDetailStep/CreateLiveShowDetailStep.types';
import { CreateLiveShowProductsStepProps } from './CreateLiveShowProductsStep.types';

const CreateLiveShowProductsStep: FC<CreateLiveShowProductsStepProps> = ({
  bucketList,
  handleProductBucketList,
  selectedProductList,
  updateProductList
}) => {
  const [input, setInput] = useState('');
  const [lastTime, setLastTime] = useState(Date.now());
  const [message, setMessage] = useState('');

  useEffect(() => {
    const decoder = new TextDecoder('utf-8');

    // Remove focus from the currently focused element
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    // This variable will accumulate the keys pressed
    let barcodeInput = '';

    // This variable will store the timestamp of the last keydown event
    let lastKeypressTime = 0;

    document.addEventListener('keydown', (e) => {
      // Get the current time
      const currentTime = Date.now();

      // If the time between this keydown event and the last one is too large,
      // we assume that the new keypress is the start of a new barcode
      if (currentTime - lastKeypressTime > 200) {
        barcodeInput = '';
      }

      // Add the new character to the barcodeInput string
      // Ignore non-character keys
      if (e.key.length === 1 && e.key !== 'Enter' && e.key !== 'Shift') {
        // Add the new character to the barcodeInput string
        barcodeInput += e.key;
      }

      // If the Enter key was pressed, we assume that the barcode is complete
      if (e.key === 'Enter') {
        let barcode = barcodeInput;
        // barcode = barcode.replace(/[^A-Za-z0-9_-]/g, '');
        console.log('barcode scanned final :', barcode);
        barcodeInput = '';

        getProductByLabel({
          variables: {
            input: {
              label: barcode
            }
          },
          context: {
            randomId: Math.random().toString(36).substring(7) // Generate a random ID
          }
        });
      }

      // Update the lastKeypressTime
      lastKeypressTime = currentTime;
    });

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      // document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // State params
  const [liveshowDetailInfo, setLiveshowDetailInfo] = useState<LiveShowDetailInfo>();
  const [productBucketList, setProductBucketList] = useState(bucketList ? bucketList : []);
  // API call to add product via scanning
  const [getProductData] = useLazyQuery(GET_PRODUCT_BASIC_DETAILS, {
    onCompleted: (data) => {
      if (data?.getProduct?.length > 0) {
        const productDetail = data?.getProduct[0];
        console.log('productDetail', productDetail);
        if (productDetail) {
          manageProductList([
            {
              productId: Number(productDetail.id),
              productName: productDetail.name,
              productPrice: productDetail.salePrice?.toFixed(2),
              productImg: productDetail.medias?.[0]?.url || null
            }
          ]);
        }
      }
    }
  });

  const [getProductByLabel] = useLazyQuery(GET_PRODUCT_BY_LABEL, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      console.log('data we received... - ', data);

      if (data) {
        const productItem = data?.getProductByLabel;
        console.log('productItem', productItem);

        manageProductList([
          {
            productId: productItem.id,
            productName: productItem.name,
            productPrice: productItem.salePrice?.toFixed(2),
            productImg: productItem.imageUrl || null
          }
        ]);
      }
    },
    onError: (error) => {
      console.log('error productDetails ::', error);
    }
  });

  // Event handlers
  const updateInfo = (data: any): void => {
    setLiveshowDetailInfo(data);
  };
  const manageProductList = (newProductsToAdd) => {
    updateProductList && updateProductList([...selectedProductList, ...newProductsToAdd]);
  };
  const removeProductHandler = (productId: number) => {
    const newList = selectedProductList.filter((product) => product.productId !== productId);
    updateProductList && updateProductList(newList);
    if (bucketList?.filter((product) => product.productId !== productId)) {
      setProductBucketList(newList);
      handleProductBucketList && handleProductBucketList(newList);
    }
  };
  const selectedItemHandler = (itemList) => {
    setProductBucketList(itemList);
    handleProductBucketList && handleProductBucketList(itemList);
  };
  const onProductDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    selectedProductList.splice(result.destination.index, 0, selectedProductList.splice(result.source.index, 1)[0]);
    updateProductList && updateProductList(selectedProductList);
  };
  const handleScannedValue = (scannedValue: string) => {
    if (scannedValue && scannedValue !== '') {
      getProductData({
        variables: {
          input: {
            productIDs: [Number(scannedValue)],
            isStoreOwner: true
          }
        }
      });
    }
  };

  return (
    <>
      <CreateLiveShowProductsStepPresentational
        liveShowDetailInfo={liveshowDetailInfo}
        selectedProductList={selectedProductList}
        updateLiveShowDetailInfo={updateInfo}
        handleSelectedProducts={manageProductList}
        removeProduct={removeProductHandler}
        bucketList={productBucketList}
        handleSelectedItem={selectedItemHandler}
        onProductDragEnd={onProductDragEnd}
      />
      <ScanBarcode setValue={handleScannedValue} />
    </>
  );
};

export default CreateLiveShowProductsStep;
