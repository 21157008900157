import { FC, useState, useReducer } from 'react';
import { useHistory } from 'react-router';

// Apollo
import { useMutation, useLazyQuery } from '@apollo/client';
import { CHECK_BACKSTORE_USER } from 'src/apollo/queries';
import { SIGN_UP_BACK_STORE } from 'src/apollo/mutations';

// Hooks && Utils && Helpers
import { createFirebaseUser, sigupWithGoogle, sigupWithFacebook, sigupWithApple, refreshToken } from 'src/helpers/InitFirebase';
import { getAccountDetailsFromToken } from 'src/utils/authentication/decodeToken';
import { validateFullName, validateEmail, validatePassword, validateStoreName } from 'src/utils/validate';
import { setStoreIdToOSPlayer } from 'src/services/pushnotification/DataTags';

// Icons
import { SignUpBySocial } from 'src/assets/images';
import { HideIcon, ShowIcon, GoogleIcon1, AppleIcon1, Facebook1 } from 'src/assets/icons';

// ConstVariables
import { ROUTES, envUrls, constVariables } from 'src/constants';

// Styles
import './_signUp.scss';
import 'src/components/oraganisms/CustomModal/modalStyles.scss';

enum mediumType {
  EMAIL,
  GOOGLE,
  FACEBOOK,
  APPLE
}

const Signup: FC = (props) => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), {
    email: {
      value: '',
      validateOnChange: true,
      error: ''
    },
    fullName: {
      value: '',
      validateOnChange: true,
      error: ''
    },
    storeName: {
      value: '',
      validateOnChange: true,
      error: ''
    },
    password: {
      value: '',
      validateOnChange: true,
      error: ''
    },
    profileUrl: '',
    token: '',
    isLoading: false,
    medium: mediumType[0],
    genericError: ''
  });
  const resetData = () => {
    setState({
      email: {
        value: '',
        validateOnChange: true,
        error: ''
      },
      fullName: {
        value: '',
        validateOnChange: true,
        error: ''
      },
      storeName: {
        value: '',
        validateOnChange: true,
        error: ''
      },
      password: {
        value: '',
        validateOnChange: true,
        error: ''
      },
      profileUrl: '',
      token: '',
      isLoading: false,
      medium: mediumType[0],
      genericError: ''
    });
  };

  const [checkUserData, { data, error }] = useLazyQuery(CHECK_BACKSTORE_USER, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (data && data.checkBackstoreUser) {
        if (data.checkBackstoreUser.isUserExist && state.medium !== mediumType[0]) {
          const accountInfo = getAccountDetailsFromToken(state.token);
          localStorage.setItem('storeId', accountInfo.storeId);
          localStorage.setItem('userId', accountInfo.userId);
          localStorage.setItem('firebaseUserId', accountInfo.user_id);
          localStorage.setItem('name', accountInfo.name);
          localStorage.setItem('picture', accountInfo.picture);
          localStorage.setItem('email', accountInfo.email);

          // TODO: Add error handling for setStoreIdToOSPlayer onError
          // setStoreIdToOSPlayer(accountInfo.storeId);

          setState({
            isLoading: false
          });

          history.replace('/products');
        } else if (
          !data.checkBackstoreUser.isUserExist &&
          (state.medium === mediumType[1] || state.medium === mediumType[2] || state.medium === mediumType[3])
        ) {
          setState({
            isLoading: false
          });

          history.push({
            pathname: '/createstore',
            state: {
              detail: {
                email: state.email.value,
                fullName: state.fullName.value,
                medium: state.medium,
                profileUrl: state.profileUrl
              }
            }
          });
        } else if (!data.checkBackstoreUser.isUserExist && state.medium === mediumType[0]) {
          setState({
            isLoading: true
          });

          const signupWithEmailAndPassword = async () => {
            const {
              success,
              userCredential = {},
              error = ''
            }: { success: boolean; userCredential?: any; error?: any } = await createFirebaseUser(
              state.email.value,
              state.password.value
            );
            if (success) {
              setState({
                email: {
                  value: state.email.value,
                  validateOnChange: true,
                  error: ''
                }
              });

              const user = userCredential.user;
              const idToken = await user.getIdToken(true).then();
              setState({
                token: idToken
              });
              localStorage.setItem('token', idToken);
              signupBackstoreUser({
                context: {
                  headers: {
                    Authorization: idToken || state.token,
                    // tenantid: envUrls.firebaseTenantId,
                    tenantid: state.storeName.value,
                    'x-api-key': envUrls.authXApiKey
                  }
                },
                variables: {
                  input: {
                    email: state.email.value,
                    fullName: state.fullName.value,
                    storeName: state.storeName.value,
                    medium: state.medium
                  }
                }
              });
            } else if (!success && error) {
              if (error.code && error.message) {
                if (error.code === 'auth/email-already-in-use') {
                  setState({
                    email: {
                      ...state.email,
                      validateOnChange: true,
                      error: error.message
                    }
                  });
                }

                setState({
                  genericError: error.message
                });
              } else {
                setState({
                  genericError: 'Somthing went wrong. Please try again later.'
                });
              }

              setState({
                isLoading: false
              });
            }
          };

          signupWithEmailAndPassword();
        }
      }
    },
    onError: (e) => {
      localStorage.clear();
      const responseError: any = e?.graphQLErrors[0];
      console.error('checkUserData responseError - ', responseError);

      if (responseError?.errorType === 'USER_ALREADY_EXIST') {
        setState({
          genericError: 'This email is already used in a registered account.'
        });
      } else {
        setState({
          genericError: 'Something went wrong. Please try again later.'
        });
      }
      setState({
        isLoading: false
      });
    }
  });

  const [signupBackstoreUser] = useMutation(SIGN_UP_BACK_STORE, {
    onCompleted: async (data) => {
      const idToken = await refreshToken();
      localStorage.setItem('token', idToken);
      const accountInfo = getAccountDetailsFromToken(idToken);
      localStorage.setItem('storeId', accountInfo.storeId);
      localStorage.setItem('userId', accountInfo.userId);
      localStorage.setItem('firebaseUserId', accountInfo.user_id);
      localStorage.setItem('name', accountInfo.name);
      localStorage.setItem('picture', accountInfo.picture);
      localStorage.setItem('email', accountInfo.email);

      // TODO: Add error handling for setStoreIdToOSPlayer onError
      // await setStoreIdToOSPlayer(accountInfo.storeId);

      setState({
        isLoading: false
      });

      history.push({
        pathname: ROUTES.authentication.signup.verifyScreen,
        state: {
          detail: {
            email: state.email.value,
            store: state.storeName.value,
            token: idToken
          }
        }
      });
    },
    onError: (e) => {
      console.error('signupBackstoreUser onError - ', e);
      console.error('e?.graphQLErrors[0] - ', e?.graphQLErrors[0]);
      localStorage.clear();
      const error: any = e?.graphQLErrors[0];
      if (error?.errorType === 'STORE_ALREADY_EXIST') {
        setState({
          genericError: error?.message
        });
      } else {
        setState({
          genericError: 'Something went wrong. Please try again later.'
        });
      }
      setState({
        isLoading: false
      });
    }
  });

  const sigUpWithEmail = async () => {
    setState({ isLoading: true, medium: mediumType[0] });
    checkUserData({
      context: {
        headers: {
          tenantid: '',
          'x-api-key': envUrls.authXApiKey
        }
      },
      variables: {
        input: {
          email: state.email.value,
          medium: mediumType[0],
          storeName: state.storeName.value
        },
        state: Math.random()
      }
    });
  };

  const signUpWithGoogle = async () => {
    const {
      success,
      userCredential = {},
      error = '',
      idToken
    }: { success: boolean; userCredential?: any; error?: any; idToken?: any } = await sigupWithGoogle();
    if (success) {
      setState({ isLoading: true, medium: mediumType[1] });
      localStorage.setItem('token', idToken);
      setState({
        email: {
          value: userCredential.user.email,
          validateOnChange: true,
          error: ''
        },
        fullName: {
          value: userCredential.user.displayName,
          validateOnChange: true,
          error: ''
        },

        profileUrl: userCredential.user.photoURL,
        token: idToken
      });

      checkUserData({
        context: {
          headers: {
            Authorization: idToken || '',
            tenantid: '',
            'x-api-key': envUrls.authXApiKey
          }
        },
        variables: {
          input: {
            email: userCredential.user.email,
            medium: mediumType[1]
          },
          state: Math.random()
        }
      });
    } else if (error) {
      console.log('error::', error);
      if (error.code !== 'auth/popup-closed-by-user') {
        if (error.code && error.message) {
          setState({
            genericError: error.message
          });
        } else {
          setState({
            genericError: 'Something went wrong. Please try again later.'
          });
        }
      }

      setState({
        isLoading: false
      });
      resetData();
    }
  };

  const signUpWithFacebook = async () => {
    const {
      success,
      userCredential = {},
      error = '',
      idToken
    }: { success: boolean; userCredential?: any; error?: any; idToken?: any } = await sigupWithFacebook();
    if (success) {
      setState({ isLoading: true, medium: mediumType[2] });
      localStorage.setItem('token', idToken);
      setState({
        email: {
          value: userCredential.user.email,
          validateOnChange: true,
          error: ''
        },
        fullName: {
          value: userCredential.user.displayName,
          validateOnChange: true,
          error: ''
        },

        profileUrl: userCredential.user.photoURL,
        token: idToken
      });

      checkUserData({
        context: {
          headers: {
            Authorization: idToken || '',
            tenantid: '',
            'x-api-key': envUrls.authXApiKey
          }
        },
        variables: {
          input: {
            email: userCredential.user.email,
            medium: mediumType[2]
          }
        }
      });
    } else if (error) {
      if (error.code !== 'auth/popup-closed-by-user') {
        if (error.code && error.message) {
          setState({
            genericError: error.message
          });
        } else {
          setState({
            genericError: 'Something went wrong. Please try again later.'
          });
        }
      }

      resetData();
      setState({
        isLoading: false
      });
    }
  };

  const signUpWithApple = async () => {
    const {
      success,
      userCredential = {},
      error = '',
      idToken
    }: { success: boolean; userCredential?: any; error?: any; idToken?: any } = await sigupWithApple();

    if (success) {
      setState({ isLoading: true, medium: mediumType[3] });
      localStorage.setItem('token', idToken);

      setState({
        email: {
          value: userCredential.user.email || '',
          validateOnChange: true,
          error: ''
        },
        fullName: {
          value: userCredential.user.displayName || '',
          validateOnChange: true,
          error: ''
        },
        profileUrl: userCredential.user.photoURL || '',
        token: idToken
      });

      checkUserData({
        context: {
          headers: {
            Authorization: idToken || '',
            tenantid: '',
            'x-api-key': envUrls.authXApiKey
          }
        },
        variables: {
          input: {
            email: userCredential.user.email,
            medium: mediumType[3]
          }
        }
      });
    } else if (error) {
      if (error.code !== 'auth/popup-closed-by-user') {
        if (error.code && error.message) {
          setState({
            isLoading: false,
            genericError: error.message
          });
        } else {
          setState({
            isLoading: false,
            genericError: 'Something went wrong. Please try again later.'
          });
        }
      }

      resetData();
      setState({
        isLoading: false
      });
    }
  };

  const handleChange = async (e: any) => {
    const field = e.target.name;
    const fieldVal = e.target.value;

    if (field) {
      setState({
        [field]: {
          ...state[field],
          value: fieldVal,
          error: ''
        }
      });
    }
  };

  return (
    <div className="signup d-flex gradient flex-row ">
      <div className="form-container padding-style">
        <div className={` d-flex container-height container-width flex-column overflow-hidden bg-white content-container`}>
          <img src={SignUpBySocial} alt={SignUpBySocial} className="image-dimension" />
          <p className="poppins-regular title-style m-0 auth-header">
            <p>Sign Up</p>
          </p>

          <div className={`text-danger fs-8`}>{state.genericError}</div>

          <div className="d-flex flex-column form-wrapper">
            <div className="d-flex justify-content-center mt-30px">
              <div className="d-flex flex-row social-auth">
                <div
                  className="social-btn1 cursor-pointer"
                  onClick={() => {
                    resetData();
                    signUpWithGoogle();
                  }}
                >
                  <div className="social-btn1 d-flex flex-center p-0">
                    <div className="d-flex btn-content-wrapper">
                      <img src={GoogleIcon1} height={12} className=" social-icon" />{' '}
                      <span className="btn-label">{constVariables.signUp.google}</span>
                    </div>
                  </div>
                </div>
                <div
                  className="social-btn1 cursor-pointer"
                  onClick={() => {
                    resetData();
                    signUpWithFacebook();
                  }}
                >
                  <div className="social-btn1 d-flex flex-center p-0">
                    <div className="d-flex btn-content-wrapper">
                      <img src={Facebook1} height={12} className=" social-icon" /> <span>{constVariables.signUp.facebook}</span>
                    </div>
                  </div>
                </div>
                <div
                  className="social-btn1 cursor-pointer"
                  onClick={() => {
                    resetData();
                    signUpWithApple();
                  }}
                >
                  <div className="social-btn1 d-flex flex-center p-0">
                    <div className="d-flex btn-content-wrapper">
                      <img src={AppleIcon1} height={12} className=" social-icon" /> <span>{constVariables.signUp.apple}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="or-container">
              <div className="border-container" />
              <p className="fs-14px my-8 orText">{constVariables.signUp.or}</p>
              <div className="border-container" />
            </div>
            <div className="d-flex inner-gap   flex-column ">
              <div className="input-gap">
                <p className="fs-14px mb-1">{constVariables.signUp.fullName}</p>
                <input
                  autoComplete="off"
                  className={`form-control form-control-solid hp-50px ${state.fullName.error ? 'red-border' : 'grey-border'}`}
                  id="fullName"
                  name="fullName"
                  value={state.medium === mediumType[0] ? state.fullName.value : ''}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder="Enter full name"
                />
                <div className={`text-danger fs-8`}>{state.fullName.error}</div>
              </div>
              <div className="input-gap">
                <p className="fs-14px mb-1">{constVariables.signUp.email}</p>
                <input
                  autoComplete="off"
                  className={`form-control form-control-solid hp-50px ${state.email.error ? 'red-border' : 'grey-border'}`}
                  id="email"
                  name="email"
                  value={state.medium === mediumType[0] ? state.email.value : ''}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder="e.g. John@demo.com"
                />
                <div className={`text-danger fs-8 `}>{state.email.error}</div>
              </div>
              <div className="input-gap">
                <p className="fs-14px mb-1">{constVariables.signUp.password}</p>
                <div className={`input-group password-section ${state.password.error ? 'red-border' : 'grey-border'} `}>
                  <input
                    autoComplete="off"
                    className={`form-control form-control-solid hp-50px no-border`}
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={state.medium === mediumType[0] ? state.password.value : ''}
                    onChange={(e) => handleChange(e)}
                    placeholder="Set Password"
                  />
                  <div className="input-group-addon">
                    <img
                      src={!showPassword ? HideIcon : ShowIcon}
                      className="cursor-pointer"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    />
                  </div>
                </div>
                <div className={`text-danger fs-8`}>{state.password.error}</div>
              </div>
              <div className="d-flex flex-column field-wrapper ">
                <div className="fs-14px">
                  {constVariables.storeCreation.storeName}{' '}
                  <span className="loggedin-message poppins-regular">{constVariables.signUp.changeLater}</span>
                </div>
                <input
                  autoComplete="off"
                  className={`form-control form-control-solid hp-50px ${state.storeName.error ? 'red-border' : 'grey-border'}`}
                  id="storeName"
                  name="storeName"
                  value={state.medium === mediumType[0] ? state.storeName.value : ''}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder="Enter your store name"
                />
                <div className={`text-danger fs-8`}>{state.storeName.error}</div>
              </div>
              <div className="button-container mt-3">
                <div className="button margin-style">
                  <button
                    className="btn btn-primary w-100 flex-center"
                    disabled={state.isLoading}
                    onClick={(e) => {
                      e.preventDefault();
                      const nameError = validateFullName(state.fullName.value);
                      const storeNameError = validateStoreName(state.storeName.value);
                      const passwordError = validatePassword(state.password.value);
                      const emailError = validateEmail(state.email.value);

                      if ([nameError, storeNameError, passwordError, emailError].every((e) => e === false)) {
                        sigUpWithEmail();
                      } else {
                        // update the state with errors

                        setState({
                          fullName: {
                            ...state.fullName,
                            validateOnChange: true,
                            error: nameError
                          },
                          email: {
                            ...state.email,
                            validateOnChange: true,
                            error: emailError
                          },
                          storeName: {
                            ...state.storeName,
                            validateOnChange: true,
                            error: storeNameError
                          },
                          password: {
                            ...state.password,
                            validateOnChange: true,
                            error: passwordError
                          }
                        });
                      }
                    }}
                  >
                    {constVariables.signUp.createStore}
                    {state.isLoading && <div className="spinner-border text-primary auth-flow"></div>}
                  </button>
                </div>
                <div className="loggedin-message poppins-regular already-have-an-account text-color-exp">
                  Already have an account?{' '}
                  <span
                    className="text-primary cursor-pointer poppins-regular"
                    onClick={() => {
                      history.push('/login');
                      resetData();
                    }}
                  >
                    Sign in.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
