/* eslint-disable no-nested-ternary */
import { FC, useMemo, useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  registerables as registerablesjs
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

// Types
import { IStoreAnalytics } from './HomePage.type';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, ...registerablesjs);

const AnalyticsChart: FC<IStoreAnalytics['chart']> = ({ keys = [], values = [] }) => {
  const chartRef = useRef<ChartJS>(null);

  const options = useMemo(() => {
    const isEmpty = values.filter((v) => v === 0).length === values.length;
    const emptyYSettings = {
      ticks: {
        callback(value, index, values) {
          return `${index}k`;
        },
        maxTicksLimit: 4
      },
      beginAtZero: true,
      min: 0, // Set the minimum y-axis value
      max: 0.6, // Set the maximum y-axis value
      border: { dash: [4, 4] }, // for the grid lines
      grid: {
        display: true,
        color: (context) => {
          // Hide first grid line
          if (context.tick.value === 0) {
            return 'rgba(0,0,0,0)'; // Make it transparent
          }
          return '#E5E5EF'; // Default color
        }
      }
    };
    return {
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false
        },
        tooltip: {
          animation: {
            duration: 0
          },
          position: 'average' as const,
          callbacks: {
            title() {
              return '';
            },
            label(context) {
              return `$${context.formattedValue}`;
            }
          },
          yAlign: 'bottom' as const,
          displayColors: false,
          caretPadding: 10,
          backgroundColor: '#1e2749'
        }
      },
      scales: {
        x: {
          grid: {
            display: false
          },
          ticks: {
            maxRotation: 0,
            minRotation: 0,
            textStrokeWidth: 20,
            tickWidth: 20,
            lineWidth: 20
          }
        },
        y: isEmpty
          ? emptyYSettings
          : {
              bounds: 'ticks',
              ticks: {
                callback(value, index, values) {
                  const convertedNumber = value / 1000 > 1 ? `${value / 1000}k` : value > 1 ? value : '';
                  return convertedNumber;
                }
              },
              beginAtZero: true,
              border: { dash: [4, 4] }, // for the grid lines
              grid: {
                display: values.every((value) => value === 0) ? false : true
              }
            }
      }
    };
  }, [values]);

  const data = useMemo(
    () => ({
      labels: keys,
      datasets: [
        {
          fill: true,
          label: 'Sales',
          data: values,
          borderColor: values.every((value) => value === 0) ? 'transparent' : '#00a3ff',
          backgroundColor: values.every((value) => value === 0) ? 'transparent' : 'rgba(142, 214, 255, 0.4)',
          tension: 0.7
        }
      ]
    }),
    [keys, values]
  );

  return (
    <div className="m-t-32">
      <Chart ref={chartRef} type="line" options={options} data={data} height={97.5} />
    </div>
  );
};

export default AnalyticsChart;
