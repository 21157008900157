import { gql } from '@apollo/client';

export const GET_TIKTOK_STORE = gql`
  query GetTikTokStore @api(name: "tiktokAPI") {
    getTikTokStore {
      __typename
      status
      storeName
    }
  }
`;
