import { FC } from 'react';
import clsx from 'clsx';

// Components
import { Loader } from 'src/components/atoms';

// Types
import { SaveHeaderProps } from './SaveHeader.types';

// Styles
import './_saveHeader.scss';

const SaveHeader: FC<SaveHeaderProps> = ({ onSave, onDiscard, saveDisabled, saveDisabledText }) => {
  return (
    <div className={clsx('sticky-header bg-white d-flex align-items-center justify-content-between p-x-40 p-y-8 w-100')}>
      <div className="flex-grow-1 text-danger">Unsaved changes</div>
      <div>
        {!(saveDisabled && saveDisabledText) && (
          <button className="btn btn-outlined-secondary btn-md me-2" onClick={onDiscard}>
            Discard
          </button>
        )}
        <button disabled={saveDisabled} className="btn btn-primary btn-md me-0" onClick={onSave}>
          {saveDisabled ? saveDisabledText || 'Saving' : 'Save'}
          {saveDisabled && <Loader type="button" className="h-10px w-10px" />}
        </button>
      </div>
    </div>
  );
};

export default SaveHeader;
