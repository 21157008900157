import { gql } from '@apollo/client';

export const CONFIGURE_SHIPPO = gql`
  mutation ConfigureShippo($input: ShippoConfigInput!) @api(name: "ordersAPI") {
    configureShippo(input: $input) {
      __typename
      id
      defaultProvider
      isShippoConnectd
      isShipStationConnectd
    }
  }
`;
