import React, { Fragment, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CategoryArrowRightIcon, NotFoundIcon } from '../../../../assets/icons';
import { KTSVG } from '../../../../helpers';
import { SearchBar, Tooltip } from '../../../molecules';
import { Img, Loader } from '../../../atoms';
import { List } from 'react-virtualized';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Highlighter from 'react-highlight-words';
import { emitCustomEvent, useCustomEventListener } from 'react-custom-events';

export interface TiktokCategoryOptionItem {
  id: string;
  name: string;
  hasNext?: boolean;
  children: TiktokCategoryOptionItem[];
}

export interface TiktokCategorySearchOption {
  id: string;
  name: string;
}

const Breadcrumb = ({
  level1Category,
  level2Category,
  level3Category,
  level4Category,
  level5Category,
  level6Category,
  level7Category,
  goToSlide
}) => {
  const containerRef = useRef(null);
  const [isOverflow, setIsOverflow] = useState(false);

  const [is0Hide, setIs0Hide] = useState(false);
  const [is1Hide, setIs1Hide] = useState(false);
  const [is2Hide, setIs2Hide] = useState(false);
  const [is3Hide, setIs3Hide] = useState(false);
  const [is4Hide, setIs4Hide] = useState(false);

  const reset = () => {
    setIs0Hide(false);
    setIs1Hide(false);
    setIs2Hide(false);
    setIs3Hide(false);
    setIs4Hide(false);
  };

  const checkOverflow = () => {
    if (containerRef.current) {
      // @ts-ignore
      const hasOverflowX = containerRef.current.scrollWidth > containerRef.current.clientWidth;
      setIsOverflow(hasOverflowX);
      if (hasOverflowX) {
        if (!is0Hide) {
          return setIs0Hide(true);
        }
        if (!is1Hide) {
          return setIs1Hide(true);
        }
        if (!is2Hide) {
          return setIs2Hide(true);
        }
        if (!is3Hide) {
          return setIs3Hide(true);
        }
        if (!is4Hide) {
          return setIs4Hide(true);
        }
      }
    }
  };
  useEffect(() => {
    checkOverflow();
  }, [
    level1Category,
    level2Category,
    level3Category,
    level4Category,
    level5Category,
    level6Category,
    level7Category,
    is0Hide,
    is1Hide,
    is2Hide,
    is3Hide,
    is4Hide
  ]);

  // useEffect(() => {
  //   // level previous 1 / 2 still need reset!
  //    if ((!level4Category && is4Hide) || (!level3Category && is3Hide) || (!level2Category && is2Hide) || (!level1Category && is1Hide)) {
  //      return reset();
  //    }
  // }, [
  //     level1Category,
  //     level2Category,
  //     level3Category,
  //     level4Category,
  //     is1Hide,
  //     is2Hide,
  //     is3Hide,
  //     is4Hide]);

  useCustomEventListener('resetBreadcrumb', () => {
    reset();
  });

  return (
    <div
      ref={containerRef}
      className="poppins-medium"
      style={{
        display: 'flex',
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: 500,
        height: '46px',
        paddingLeft: '12px',
        alignItems: 'center',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        width: '660px'
      }}
      // data-tip
      // data-for='tiktok-category-breadcrumb-tooltip'
    >
      <span onClick={() => goToSlide(0)} className={`level-category-selected ${level1Category ? '' : 'active'}`}>
        {is0Hide ? '...' : 'All Categories'}
      </span>
      {level1Category && (
        <>
          {!is0Hide && <KTSVG path={CategoryArrowRightIcon} className="svg-icon-9 px-3" />}
          <span onClick={() => goToSlide(0)} className={`level-category-selected ${level2Category ? '' : 'active'}`}>
            {is1Hide ? '' : level1Category.name}
          </span>
        </>
      )}
      {level2Category && (
        <>
          {!is1Hide && <KTSVG path={CategoryArrowRightIcon} className="svg-icon-9 px-3" />}
          <span onClick={() => goToSlide(0)} className={`level-category-selected ${level3Category ? '' : 'active'}`}>
            {is2Hide ? '' : level2Category.name}
          </span>
        </>
      )}
      {level3Category && (
        <>
          {!is2Hide && <KTSVG path={CategoryArrowRightIcon} className="svg-icon-9 px-3" />}
          <span onClick={() => goToSlide(1)} className={`level-category-selected ${level4Category ? '' : 'active'}`}>
            {is3Hide ? '' : level3Category.name}
          </span>
        </>
      )}
      {level4Category && (
        <>
          {!is3Hide && <KTSVG path={CategoryArrowRightIcon} className="svg-icon-9 px-3" />}
          <span onClick={() => goToSlide(2)} className={`level-category-selected ${level5Category ? '' : 'active'}`}>
            {is4Hide ? '' : level4Category.name}
          </span>
        </>
      )}
      {level5Category && (
        <>
          <KTSVG path={CategoryArrowRightIcon} className="svg-icon-9 px-3" />
          <span onClick={() => goToSlide(3)} className={`level-category-selected ${level6Category ? '' : 'active'}`}>
            {level5Category.name}
          </span>
        </>
      )}
      {level6Category && (
        <>
          <KTSVG path={CategoryArrowRightIcon} className="svg-icon-9 px-3" />
          <span className={`level-category-selected ${level7Category ? '' : 'active'}`}>{level6Category.name}</span>
        </>
      )}
      {level7Category && (
        <>
          <KTSVG path={CategoryArrowRightIcon} className="svg-icon-9 px-3" />
          <span className={'level-category-selected active'}>{level7Category.name}</span>
        </>
      )}
    </div>
  );
};

const VirtualizedInfiniteListComponent = ({ isLoading, items, canLoadMore, next, handleChangeCategory, searchValue }) => {
  const [listWidth, setListWidth] = useState(904);

  const [sentryRef] = useInfiniteScroll({
    // callback: next,
    onLoadMore: () => {
      return next();
    },
    loading: isLoading,
    hasNextPage: canLoadMore
  });

  return (
    <div
      style={{
        height: '186px',
        overflowY: 'auto'
      }}
    >
      <List
        width={680}
        height={34 * items.length}
        rowHeight={34}
        rowCount={items.length}
        rowRenderer={({ key, index, style }) => {
          return (
            <SearchRow
              key={key}
              divKey={items[index].id}
              text={items[index].name}
              handleChangeCategory={handleChangeCategory}
              searchValue={searchValue}
            />
          );
        }}
      />
      <div ref={sentryRef}>{canLoadMore && isLoading && items.length > 0 ? 'Loading' : ''}</div>
      {/*{isLoading && <div>Loading...</div>}*/}
    </div>
  );
};

const SearchRow = ({ key, divKey, text, handleChangeCategory, searchValue }) => {
  const textRef = useRef(null);
  const [isOverflow, setIsOverflow] = useState(false);

  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      const element = textRef.current;
      if (element) {
        // @ts-ignore
        console.log('isOverflow', element.scrollWidth, element.clientWidth);
        // @ts-ignore
        setIsOverflow(element.scrollWidth > element.clientWidth);
      }
    };

    checkOverflow(); // Initial check
  }, [text]);
  const handleClick = () => {
    console.log(key, divKey, showTooltip, text);
    const subText = text.split(' - ');
    handleChangeCategory(divKey, `${subText[subText.length - 2]} - ${subText[subText.length - 1]}`);
  };
  return (
    <div
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      onClick={handleClick}
      key={divKey}
      className="tiktok-category-search-row"
    >
      <div
        ref={textRef}
        style={{
          width: '680px',
          height: '34px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }}
        data-tip
        data-for={`category-search-option-${divKey}`}
      >
        {/*{text}*/}
        <Highlighter highlightClassName="search-highlight" searchWords={[searchValue]} autoEscape={true} textToHighlight={text} />
        {/*{key}-{index}*/}
        {showTooltip && isOverflow && (
          <Tooltip
            tooltipId={`category-search-option-${divKey}`}
            place="top"
            content={() => {
              return (
                <Fragment>
                  <Highlighter
                    highlightClassName="search-highlight tool-tip"
                    searchWords={[searchValue]}
                    autoEscape={true}
                    textToHighlight={text}
                  />
                </Fragment>
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

const PAGE_SIZE = 20;

const CategoryDropdown = ({ currentValue, allCategoryList, handleChangeCategory, isLoading, allCategorySearchOptions }) => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlide, setTotalSlide] = useState(1);

  const CustomPrevArrow = ({ onClick }) => {
    if (currentSlide === 0) {
      return null;
    }
    return (
      <button onClick={onClick} className="level-category-previous-button">
        <KTSVG path={CategoryArrowRightIcon} className="svg-icon-9" />
      </button>
    );
  };

  const CustomNextArrow = ({ onClick }) => {
    if (currentSlide >= totalSlide - 2) {
      return null;
    }
    return (
      <button onClick={onClick} className="level-category-next-button">
        <KTSVG path={CategoryArrowRightIcon} className="svg-icon-9" />
      </button>
    );
  };

  const goToSlide = (index) => {
    if (sliderRef.current) {
      // @ts-ignore
      sliderRef.current.slickGoTo(index);
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    beforeChange: (current, next) => setCurrentSlide(next), // Update current slide index
    // autoplaySpeed: 2000,
    // @ts-ignore
    prevArrow: <CustomPrevArrow />,
    // @ts-ignore
    nextArrow: <CustomNextArrow />
  };

  const [level1Categories, setLevel1Categories] = useState<TiktokCategoryOptionItem[]>([]);
  const [level1Category, setLevel1Category] = useState<TiktokCategoryOptionItem | undefined>(undefined);

  const [level2Categories, setLevel2Categories] = useState<TiktokCategoryOptionItem[]>([]);
  const [level2Category, setLevel2Category] = useState<TiktokCategoryOptionItem | undefined>(undefined);

  const [level3Categories, setLevel3Categories] = useState<TiktokCategoryOptionItem[]>([]);
  const [level3Category, setLevel3Category] = useState<TiktokCategoryOptionItem | undefined>(undefined);

  const [level4Categories, setLevel4Categories] = useState<TiktokCategoryOptionItem[]>([]);
  const [level4Category, setLevel4Category] = useState<TiktokCategoryOptionItem | undefined>(undefined);

  const [level5Categories, setLevel5Categories] = useState<TiktokCategoryOptionItem[]>([]);
  const [level5Category, setLevel5Category] = useState<TiktokCategoryOptionItem | undefined>(undefined);

  const [level6Categories, setLevel6Categories] = useState<TiktokCategoryOptionItem[]>([]);
  const [level6Category, setLevel6Category] = useState<TiktokCategoryOptionItem | undefined>(undefined);

  const [level7Categories, setLevel7Categories] = useState<TiktokCategoryOptionItem[]>([]);
  const [level7Category, setLevel7Category] = useState<TiktokCategoryOptionItem | undefined>(undefined);

  useEffect(() => {
    console.log('currentValue', currentValue);
    if (currentValue && allCategoryList && !level1Category) {
      const { id, l1, l2, l3, l4, l5, l6, l7 } = currentValue;
      if (l1) {
        const level1 = allCategoryList.find((c) => c.name === l1);
        if (level1) {
          setLevel1Category(level1);
          setTotalSlide(1);
          if (level1.children && level1.children.length > 0 && l2) {
            const level2 = level1.children.find((c) => c.name === l2);
            if (level2) {
              setLevel2Categories(level1.children);
              setLevel2Category(level2);
              setTotalSlide(2);
              if (level2.children && level2.children.length > 0 && l3) {
                const level3 = level2.children.find((c) => c.name === l3);
                if (level3) {
                  setLevel3Categories(level2.children);
                  setLevel3Category(level3);
                  setTotalSlide(3);
                  if (level3.children && level3.children.length > 0 && l4) {
                    const level4 = level3.children.find((c) => c.name === l4);
                    if (level4) {
                      setLevel4Categories(level3.children);
                      setLevel4Category(level4);
                      setTotalSlide(4);
                      if (level4.children && level4.children.length > 0 && l5) {
                        const level5 = level4.children.find((c) => c.name === l5);
                        if (level5) {
                          setLevel5Categories(level4.children);
                          setLevel5Category(level5);
                          setTotalSlide(5);
                          if (level5.children && level5.children.length > 0 && l6) {
                            const level6 = level5.children.find((c) => c.name === l6);
                            if (level6) {
                              setLevel6Categories(level5.children);
                              setLevel6Category(level6);
                              setTotalSlide(6);
                              if (level6.children && level6.children.length > 0 && l7) {
                                const level7 = level6.children.find((c) => c.name === l7);
                                if (level7) {
                                  setLevel7Categories(level6.children);
                                  setLevel7Category(level7);
                                  setTotalSlide(7);
                                  setTimeout(() => goToSlide(5), 200);
                                }
                              } else {
                                setTimeout(() => goToSlide(4), 200);
                              }
                            }
                          } else {
                            setTimeout(() => goToSlide(3), 200);
                          }
                        }
                      } else {
                        setTimeout(() => goToSlide(2), 200);
                      }
                    }
                  } else {
                    setTimeout(() => goToSlide(1), 200);
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [currentValue, level1Category, allCategoryList]);

  useEffect(() => {
    if (allCategoryList && allCategoryList.length > 0) {
      setLevel1Categories(allCategoryList);
    }
  }, [allCategoryList]);

  const onSelectFirst = (c) => {
    emitCustomEvent('resetBreadcrumb');
    setLevel1Category(c);
    if (c.children && c.children.length > 0) {
      setLevel2Categories(c.children);
      setTotalSlide(2);
    } else {
      handleChangeCategory(c.id, c.name);
      setLevel2Categories([]);
      setLevel3Categories([]);
      setLevel4Categories([]);
      setLevel5Categories([]);
      setLevel6Categories([]);
      setLevel7Categories([]);
    }
    setLevel2Category(undefined);
    setLevel3Category(undefined);
    setLevel4Category(undefined);
    setLevel5Category(undefined);
    setLevel6Category(undefined);
    setLevel7Category(undefined);
  };

  const onSelectSecond = (c) => {
    if (level2Category) {
      emitCustomEvent('resetBreadcrumb');
    }
    setLevel2Category(c);
    if (c.children && c.children.length > 0) {
      setLevel3Categories(c.children);
      goToSlide(1);
      setTotalSlide(3);
    } else {
      // @ts-ignore
      handleChangeCategory(c.id, `${level1Category.name} - ${c.name}`);
      setLevel3Categories([]);
      setLevel4Categories([]);
      setLevel5Categories([]);
      setLevel6Categories([]);
      setLevel7Categories([]);
    }
    setLevel3Category(undefined);
    setLevel4Category(undefined);
    setLevel5Category(undefined);
    setLevel6Category(undefined);
    setLevel7Category(undefined);
  };

  const onSelectThird = (c) => {
    if (level3Category) {
      emitCustomEvent('resetBreadcrumb');
    }
    setLevel3Category(c);
    if (c.children && c.children.length > 0) {
      setLevel4Categories(c.children);
      // nextSlide();
      goToSlide(2);
      setTotalSlide(4);
    } else {
      // @ts-ignore
      handleChangeCategory(c.id, `${level2Category.name} - ${c.name}`);
      // handleChangeCategory(c.id, `${level1Category.name}-${level2Category.name}-${c.name}`);
      setLevel4Categories([]);
      setLevel5Categories([]);
      setLevel6Categories([]);
      setLevel7Categories([]);
    }
    setLevel4Category(undefined);
    setLevel5Category(undefined);
    setLevel6Category(undefined);
    setLevel7Category(undefined);
  };

  const onSelectForth = (c) => {
    if (level4Category) {
      emitCustomEvent('resetBreadcrumb');
    }
    setLevel4Category(c);
    if (c.children && c.children.length > 0) {
      setLevel5Categories(c.children);
      // nextSlide();
      goToSlide(3);
      setTotalSlide(5);
    } else {
      // @ts-ignore
      handleChangeCategory(c.id, `${level3Category.name} - ${c.name}`);
      // handleChangeCategory(c.id, `${level1Category.name}-${level2Category.name}-${level3Category.name}-${c.name}`);
      setLevel5Categories([]);
      setLevel6Categories([]);
      setLevel7Categories([]);
    }
    setLevel5Category(undefined);
    setLevel6Category(undefined);
    setLevel7Category(undefined);
  };

  const onSelectFifth = (c) => {
    setLevel5Category(c);
    if (c.children && c.children.length > 0) {
      setLevel6Categories(c.children);
      // nextSlide();
      goToSlide(4);
      setTotalSlide(6);
    } else {
      // @ts-ignore
      handleChangeCategory(c.id, `${level4Category.name} - ${c.name}`);
      // handleChangeCategory(c.id, `${level1Category.name}-${level2Category.name}-${level3Category.name}-${level4Category.name}-${c.name}`);
      setLevel6Categories([]);
      setLevel7Categories([]);
    }
    setLevel6Category(undefined);
    setLevel7Category(undefined);
  };

  const onSelectSixth = (c) => {
    setLevel6Category(c);
    if (c.children && c.children.length > 0) {
      setLevel7Categories(c.children);
      // nextSlide();
      goToSlide(5);
      setTotalSlide(7);
    } else {
      // @ts-ignore
      handleChangeCategory(c.id, `${level5Category.name} - ${c.name}`);
      // handleChangeCategory(c.id, `${level1Category.name}-${level2Category.name}-${level3Category.name}-${level4Category.name}-${c.name}`);
    }
  };

  const onSelectSeventh = (c) => {
    setLevel7Category(c);
    // @ts-ignore
    handleChangeCategory(c.id, `${level6Category.name} - ${c.name}`);
  };

  const [showSearchResult, setShowSearchResult] = useState(false);

  const [filtered, setFiltered] = useState<TiktokCategorySearchOption[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const handleSearch = (value) => {
    console.log('value', value);
    setSearchValue(value);
  };
  useEffect(() => {
    if (searchValue && searchValue.length > 0) {
      setShowSearchResult(true);
      setCanLoadMore(true);
      const result = allCategorySearchOptions.filter((c) => {
        return c.name.toLowerCase().includes(searchValue.toLowerCase());
      });
      console.log('result', result);
      setFiltered(result);
      const arr = result.slice(0, PAGE_SIZE);
      setItemsToDisplay(arr);
    } else {
      setFiltered([]);
      setShowSearchResult(false);
      setItemsToDisplay([]);
    }
  }, [searchValue, allCategorySearchOptions]);

  const [canLoadMore, setCanLoadMore] = useState(true);
  const [itemsToDisplay, setItemsToDisplay] = useState([]);

  const renderNextPage = () => {
    console.log('renderNextPage', filtered.length, itemsToDisplay.length);
    if (filtered.length > itemsToDisplay.length) {
      const arr = filtered.slice(itemsToDisplay.length, itemsToDisplay.length + PAGE_SIZE);
      // @ts-ignore
      setItemsToDisplay([...itemsToDisplay, ...arr]);
    } else {
      setCanLoadMore(false);
    }
  };

  const renderNoResult = () => {
    return <Img className="not-found-icon" src={NotFoundIcon} />;
  };

  return (
    <div
      style={{
        height: '316px',
        padding: '20px',
        background: '#FFFFFF'
      }}
    >
      {isLoading ? (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Loader type="page" />
        </div>
      ) : (
        <>
          <SearchBar classes="category-search" placeholder="Search" onChange={handleSearch} isTiktokCategory />
          {showSearchResult && (
            <div
              style={{
                marginTop: '20px',
                height: '186px'
              }}
            >
              {searchValue && filtered.length === 0 ? (
                renderNoResult()
              ) : (
                <VirtualizedInfiniteListComponent
                  items={itemsToDisplay}
                  canLoadMore={canLoadMore}
                  next={renderNextPage}
                  isLoading={false}
                  handleChangeCategory={handleChangeCategory}
                  searchValue={searchValue}
                />
              )}
            </div>
          )}

          {!showSearchResult && (
            <>
              <Breadcrumb
                level1Category={level1Category}
                level2Category={level2Category}
                level3Category={level3Category}
                level4Category={level4Category}
                level5Category={level5Category}
                level6Category={level6Category}
                level7Category={level7Category}
                goToSlide={goToSlide}
              />
              <div style={{ width: '680px', height: '186px', margin: '0 auto' }}>
                <Slider ref={sliderRef} {...settings}>
                  <div>
                    <div
                      style={{
                        height: '186px',
                        overflow: 'scroll',
                        marginRight: '10px'
                      }}
                    >
                      {level1Categories.map((c) => (
                        <div
                          key={c.id}
                          onClick={() => onSelectFirst(c)}
                          className={`level-category-option ${level1Category && c.id === level1Category?.id ? 'text-primary' : ''}`}
                        >
                          {c.name}
                          {c.children && c.children.length > 0 && <KTSVG path={CategoryArrowRightIcon} className="svg-icon-9" />}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        height: '186px',
                        overflow: 'scroll',
                        marginRight: '10px'
                      }}
                    >
                      {level2Categories.map((c) => (
                        <div
                          key={c.id}
                          onClick={() => onSelectSecond(c)}
                          className={`level-category-option ${level2Category && c.id === level2Category?.id ? 'text-primary' : ''}`}
                        >
                          {c.name}
                          {c.children && c.children.length > 0 && <KTSVG path={CategoryArrowRightIcon} className="svg-icon-9" />}
                        </div>
                      ))}
                    </div>
                  </div>
                  {level3Categories.length > 0 && (
                    <div>
                      <div
                        style={{
                          height: '186px',
                          overflow: 'scroll',
                          marginRight: '10px'
                        }}
                      >
                        {level3Categories.map((c) => (
                          <div
                            key={c.id}
                            className={`level-category-option ${
                              level3Category && c.id === level3Category?.id ? 'text-primary' : ''
                            }`}
                            onClick={() => onSelectThird(c)}
                          >
                            {c.name}
                            {c.children && c.children.length > 0 && <KTSVG path={CategoryArrowRightIcon} className="svg-icon-9" />}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {level4Categories.length > 0 && (
                    <div>
                      <div
                        style={{
                          height: '186px',
                          overflow: 'scroll',
                          marginRight: '10px'
                        }}
                      >
                        {level4Categories.map((c) => (
                          <div
                            key={c.id}
                            className={`level-category-option ${
                              level4Category && c.id === level4Category?.id ? 'text-primary' : ''
                            }`}
                            onClick={() => onSelectForth(c)}
                          >
                            {c.name}
                            {c.children && c.children.length > 0 && <KTSVG path={CategoryArrowRightIcon} className="svg-icon-9" />}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {level5Categories.length > 0 && (
                    <div>
                      <div
                        style={{
                          height: '186px',
                          overflow: 'scroll',
                          marginRight: '10px'
                        }}
                      >
                        {level5Categories.map((c) => (
                          <div
                            key={c.id}
                            className={`level-category-option ${
                              level5Category && c.id === level5Category?.id ? 'text-primary' : ''
                            }`}
                            onClick={() => onSelectFifth(c)}
                          >
                            {c.name}
                            {c.children && c.children.length > 0 && <KTSVG path={CategoryArrowRightIcon} className="svg-icon-9" />}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {level6Categories.length > 0 && (
                    <div>
                      <div
                        style={{
                          height: '186px',
                          overflow: 'scroll',
                          marginRight: '10px'
                        }}
                      >
                        {level6Categories.map((c) => (
                          <div
                            key={c.id}
                            className={`level-category-option ${
                              level6Category && c.id === level6Category?.id ? 'text-primary' : ''
                            }`}
                            onClick={() => onSelectSixth(c)}
                          >
                            {c.name}
                            {c.children && c.children.length > 0 && <KTSVG path={CategoryArrowRightIcon} className="svg-icon-9" />}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {level7Categories.length > 0 && (
                    <div>
                      <div
                        style={{
                          height: '186px',
                          overflow: 'scroll',
                          marginRight: '10px'
                        }}
                      >
                        {level7Categories.map((c) => (
                          <div
                            key={c.id}
                            className={`level-category-option ${
                              level7Category && c.id === level7Category?.id ? 'text-primary' : ''
                            }`}
                            onClick={() => onSelectSeventh(c)}
                          >
                            {c.name}
                            {c.children && c.children.length > 0 && <KTSVG path={CategoryArrowRightIcon} className="svg-icon-9" />}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </Slider>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CategoryDropdown;
