import { gql } from '@apollo/client';

export const TIKTOK_CHANGE_PRODUCT_STATUS = gql`
  mutation ChangeProductStatus($input: ChangeProductStatus!) @api(name: "tiktokAPI") {
    changeProductStatus(input: $input) {
      id
      success
      message
    }
  }
`;
