import { gql } from '@apollo/client';

export const GET_PARCEL_TEMPLATES = gql`
  query GetParcelTemplates @api(name: "ordersAPI") {
    getParcelTemplates {
      name
      type
      weight
      massUnit
      width
      height
      length
      distanceUnit
      token
      carrier
      packageCode
      carrierIds
    }
  }
`;
