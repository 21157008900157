import { FC, useEffect, createRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_USER_LIVE_CART } from 'src/apollo/queries/getUserLiveCart';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { Portal, Dropdown } from 'src/components/molecules';
import CartProductsTable from './CartProductsTable';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';
import useWindowDimensions from 'src/utils/UseWindowDimensions/useWindowDimensions';

// Icons
import { CloseIcon, LiveViewerFirst, LiveViewerCart, LiveViewerVIP, ThreeDotsIcon, DefaultImage } from 'src/assets/icons';

// Types
import { LiveshowCartDetailsProps, CartProductItem } from './liveshowCartDetails.types';

const getLiveShowViewerDropdownData = (isBlocked) => {
  return [
    {
      id: 1,
      name: isBlocked ? 'Unblock user' : 'Block user'
    },
    {
      id: 2,
      name: 'Add store credit'
    },
    {
      id: 3,
      name: 'Dump cart'
    }
  ];
};

const LiveshowCartDetails: FC<LiveshowCartDetailsProps> = ({
  customer,
  liveShowId,
  userId,
  closeModal,
  setIsBlockConfirm,
  setIsCreditStore,
  setShowDumpConfirm
}) => {
  const menuIcon = createRef<any>();
  const { width } = useWindowDimensions();
  const [showDropDown, setShowDropDown] = useState(false);
  const [menuPositions, setMenuPositions] = useState({
    top: 0,
    left: 0
  });
  const { data, refetch } = useQuery(GET_USER_LIVE_CART, {
    variables: {
      input: {
        liveShowId,
        userId: +userId
      }
    },

    onError: (error: any) => {
      console.log('error', error);
    },
    skip: !liveShowId || !userId
  });

  const closeDropDown = () => {
    setShowDropDown(false);
  };

  const openDropDown = (e) => {
    e.stopPropagation();
    setShowDropDown(true);

    if (menuIcon.current) {
      const position = menuIcon.current.getBoundingClientRect();
      const { top, left } = position;
      const spaceBelow = window.innerHeight - position.bottom;

      setMenuPositions({
        top: spaceBelow < 150 ? window.scrollY + top - 80 : window.scrollY + top + 40,
        left
      });
    }
  };

  const selectingMenuItem = (option) => {
    if (option.id === 1) {
      setIsBlockConfirm(true);
    }
    if (option.id === 2) {
      setIsCreditStore(true);
    }
    if (option.id === 3) {
      setShowDumpConfirm(true);
    }
    setShowDropDown(false);
  };

  useEffect(() => {
    if (customer?.id) {
      refetch();
    }
  }, [customer?.noOfCartItems]);

  return (
    <>
      <div className="modal-content modal-large-content">
        <div className="modal-header">
          <h5 className="modal-title">Cart details</h5>
          <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={closeModal}>
            <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
          </div>
        </div>
        <div className="modal-body">
          <FlexBox
            className="align-items-center justify-content-between"
            style={{
              paddingBottom: '25px',
              borderBottom: '1px solid #E9EAED',
              marginBottom: '25px'
            }}
          >
            <FlexBox className="align-items-center">
              <Img
                src={setImageSrc(customer?.profileUrl ? customer?.profileUrl : '')}
                placeholderImg={DefaultImage}
                errorImg={DefaultImage}
                className="object-fit-cover bg-white border border-light border-2"
                height={44}
                width={44}
              />
              <span className="viewer-name ms-3">{customer?.firstName}</span>
              <FlexBox className="align-items-center ms-3">
                {customer?.isFirstTimeCustomer && <img src={LiveViewerFirst} className="ms-2 viewer-icon" alt="First time user" />}
                {customer?.noOfCartItems > 0 && (
                  <div className="ms-2 d-flex align-items-center justify-content-center position-relative">
                    <span
                      className="cart-number"
                      style={{
                        color: '#ffffff',
                        position: 'absolute',
                        fontSize: '0.6rem',
                        top: '50%',
                        left: 'calc(50% + 1px)',
                        transform: 'translate(-50%, -50%)'
                      }}
                    >
                      {customer?.noOfCartItems}
                    </span>
                    <img src={LiveViewerCart} alt="Cart" className="viewer-icon" />
                  </div>
                )}
                {customer?.isVIPCustomer && <img className="ms-2 viewer-icon" src={LiveViewerVIP} alt="Vip Customer" />}
              </FlexBox>
            </FlexBox>
            <span
              ref={menuIcon}
              className={`viewer-action-menu d-flex align-items-center justify-content-center ${showDropDown ? 'active' : ''}`}
            >
              <KTSVG onClick={openDropDown} className="cursor-pointer" path={ThreeDotsIcon} />
            </span>
          </FlexBox>
          <CartProductsTable products={data?.getUserLiveCart || []} />
        </div>
      </div>
      {showDropDown && (
        <Portal id="kt_body">
          <OutsideClickHandler onOutsideClick={closeDropDown}>
            <div
              style={{ top: menuPositions.top, left: menuPositions.left, zIndex: 10000 }}
              className="position-absolute comment-action-drop-down"
            >
              <Dropdown
                data={getLiveShowViewerDropdownData(Boolean(customer && customer.isLiveBlocked))}
                onSelect={selectingMenuItem}
                selected={showDropDown}
                closeDropdown={() => setShowDropDown(false)}
                shiftLeft
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </>
  );
};

export default LiveshowCartDetails;
