import { FC, useEffect, useState, useCallback } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import moment from 'moment';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { Dropdown } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';
import getDeviceData from 'src/utils/UseDevice/useDevice';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import {
  CloseIcon,
  LeftNavigator,
  LiveRadioIcon,
  NoLiveProducts,
  RightNavigtor,
  ThreeDotsIcon,
  DefaultImage
} from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { LiveEventPreviewProps } from './LiveEventPreview.types';

// Styles
import './_liveEventPreview.scss';
import { useQuery } from '@apollo/client';
import { GET_LIVE_STREAM_DETAILS } from 'src/apollo/queries';
import { Link } from 'react-router-dom';

const LiveEventPreview: FC<LiveEventPreviewProps> = ({
  isVisible,
  eventDetails,
  sliderAction,
  setSliderAction,
  closeSlider,
  editEvent,
  deleteEvent,
  goToLiveShow
}) => {
  const deviceName = getDeviceData();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [eventStatus, setEventStatus] = useState<string>('upcoming');

  useQuery(GET_LIVE_STREAM_DETAILS, {
    variables: {
      id: eventDetails?.id
    },
    skip: !eventDetails?.id
  });

  useEffect(() => {
    if (eventDetails) {
      if (new Date() > new Date(eventDetails.startingAt) && new Date() < new Date(eventDetails.endingAt) && eventDetails.isLive) {
        setEventStatus('live');
      } else if (new Date(eventDetails.endingAt) > new Date()) {
        setEventStatus('upcoming');
      } else if (new Date(eventDetails.startingAt) < new Date()) {
        setEventStatus('past');
      }
    }
  }, [eventDetails]);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      closeSlider();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  const setStatusColor = (status: string) => {
    if (status === 'upcoming') {
      return 'upcoming-event';
    } else if (status === 'past') {
      return 'past-event';
    } else if (status === 'live') {
      return 'currently-live';
    }
    return '';
  };

  const setStatusText = (status: string) => {
    if (status === 'upcoming') {
      return 'Upcoming';
    } else if (status === 'past') {
      return 'Completed';
    } else if (status === 'live') {
      return 'Live Now';
    }
    return status;
  };

  const getEventDateTime = (): string => {
    return `${moment(eventDetails.startingAt).format('D MMMM YYYY, h:mm a')} - ${moment(eventDetails.endingAt).format('h:mm a')}`;
  };

  const openSelectedOption = (selectedOption: number): void => {
    if (selectedOption === 1) {
      editEvent(eventDetails.id, eventDetails.isComplete);
    } else if (selectedOption === 2) {
      deleteEvent(eventDetails.id);
    }
  };

  return (
    <div
      id="kt_activities"
      className={`bg-body drawer drawer-end liveshow-event-preview overflow-visible ${isVisible ? 'drawer-on' : ''}`}
      data-kt-drawer="true"
      data-kt-drawer-name="activities"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'419.5px', 'sm': '419.5px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_activities_toggle"
      data-kt-drawer-close="#kt_activities_close"
    >
      <div className="position-relative">
        {isVisible && (
          <div
            className="bar-toggle-arrow bg-primary"
            onClick={() => {
              closeSlider();
            }}
          >
            <img src={RightNavigtor} alt={sliderAction !== 'clicked' && deviceName.isWeb ? LeftNavigator : RightNavigtor} />
          </div>
        )}
        <div className={`${deviceName.isWeb ? 'ps-4' : 'ps-3'}`}>
          <div className="ps-2 h-100">
            <div className={`w-400px h-100`}>
              <div className="vh-100 overflow-auto hide-scrollbar">
                <div className="p-6">
                  <div className="pb-6">
                    {eventDetails && (
                      <div className="live-event-preview">
                        <FlexBox className="w-100 justify-content-between">
                          <div className="breakWord poppins-regular m-auto-0 dark-color live-event-title">
                            <div>{eventDetails.title}</div>
                          </div>
                          <img
                            src={CloseIcon}
                            className="cursor-pointer mt-3"
                            height={20}
                            onClick={() => {
                              setSliderAction('');
                              closeSlider();
                            }}
                          />
                        </FlexBox>
                        <p className="greyText mt-3 live-event-date-time">{getEventDateTime()}</p>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className={` ${setStatusColor(eventStatus)} m-0 order-status-box text-nowrap  poppins-medium`}>
                            <div className="text-capitalize">
                              {eventStatus === 'live' && <img src={LiveRadioIcon} className="cursor-pointer pe-2" />}
                              {setStatusText(eventStatus)}
                            </div>
                          </div>

                          <div className="position-relative">
                            <OutsideClickHandler
                              onOutsideClick={() => {
                                if (showDropdown) setShowDropdown(false);
                              }}
                            >
                              <button className="bg-transparent border border-transparent ">
                                <KTSVG
                                  path={ThreeDotsIcon}
                                  isDisabled={eventStatus === 'live'}
                                  svgClassName="cursor-pointer collapseFilter collapseIcon"
                                  onClick={() => setShowDropdown(!showDropdown)}
                                />
                              </button>
                              <Dropdown
                                data={constVariables.LiveShow.liveShowEditOptions}
                                selected={showDropdown}
                                onSelect={(selectedOption) => openSelectedOption(selectedOption.id)}
                                shiftLeft
                                shiftLeftValue="-155px"
                                closeDropdown={() => setShowDropdown(false)}
                              />
                            </OutsideClickHandler>
                          </div>
                        </div>
                        <div className=" mt-8 border-1 border-top border-secondary lineStyle mb-5 live-event-border-line" />
                        <h2 className="live-event-products-title">products on this live</h2>
                        <div className="live-product-info-div mt-5">
                          {eventDetails.products?.length > 0 ? (
                            <>
                              {eventDetails.products &&
                                eventDetails.products.map((eventProduct, index) => {
                                  return (
                                    <Link
                                      to={`/products/allProducts/edit/${eventProduct.productId}`}
                                      target="_blank"
                                      className="d-flex align-items-center poppins-semibold product-row justify-content-between p-8"
                                      key={index}
                                    >
                                      <FlexBox className="align-items-center">
                                        <div className="symbol me-2">
                                          <Img
                                            src={setImageSrc(
                                              eventProduct?.productImage
                                                ? generateCompressedImageURL(eventProduct?.productImage, '50')
                                                : DefaultImage
                                            )}
                                            placeholderImg={!eventProduct.productImage && DefaultImage}
                                            errorImg={DefaultImage}
                                            className="object-fit-scale-down bg-white border border-light border-2"
                                          />
                                        </div>
                                        <div className="d-flex product-title-info ps-2 align-items-start">
                                          <span className="product-name-class cursor-pointer">{eventProduct.productName}</span>
                                          <span className="fs-8 poppins-regular greyText pt-1">
                                            ${eventProduct.productPrice.toFixed(2)}
                                          </span>
                                        </div>
                                      </FlexBox>
                                      <FlexBox>
                                        <div className="d-flex product-id p-2 ps-4 pe-4 greyText">
                                          <span className="fs-8 poppins-regular fs-7">{101 + index}</span>
                                        </div>
                                      </FlexBox>
                                    </Link>
                                  );
                                })}
                            </>
                          ) : (
                            <>
                              <img className="p-15" src={NoLiveProducts} />
                              <h2 className="no-products-title">No products added</h2>
                              <p className="no-products-description">
                                You haven't added any products to this liveshow. Edit the liveshow to add products.
                              </p>
                            </>
                          )}
                        </div>
                        {eventStatus !== 'past' && (
                          <div className="my-auto ms-3 me-0 live-manager-btn" onClick={goToLiveShow}>
                            <button className="btn btn-primary btn-md">
                              {['live', 'upcoming'].includes(eventStatus) ? 'Go to Liveshow' : 'Go To Live Manager'}
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveEventPreview;
