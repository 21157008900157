import { FC, useEffect, useState, useReducer } from 'react';
import { useHistory, useParams } from 'react-router';

// Apollo
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_CATALOG_LIST, GET_CATALOG_PRODUCT_CONDITIONS } from 'src/apollo/queries';
import { CREATE_CATALOG, DELETE_CATALOG, UPDATE_CATALOG, EDIT_CATALOGUE_RULES } from 'src/apollo/mutations';

// Components
import { CustomModal, DiscardModal, SaveHeader } from 'src/components/oraganisms';
import AddCatalogPresentational from './AddCatalog.presentational';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';

// ConstVariables
import { ROUTES, constVariables } from 'src/constants';

// Types
import { GeneralInfo } from 'src/components/templates/GeneralInfoSection/GeneralInfoSection.types';
import {
  CatalogProductListType,
  ProductsCategoryType,
  CatalogStatusType,
  ErrorsInfo,
  ConditionType,
  FieldConditionType,
  productAssignmentMethod
} from './AddCatalog.types';

const AddCatalog: FC = () => {
  const history = useHistory();
  const { showToast } = useToast();
  const [productsCategory, setProductsCategory] = useState<ProductsCategoryType>({ value: 'AUTOMATIC' });
  const [thumbnailUrlData, setThumbnailUrlData] = useState<any>();
  const [catalogStatus, setCatalogStatus] = useState<CatalogStatusType>({ value: undefined });
  const [isDiscardModal, setIsDiscardModal] = useState<boolean>(false);
  const [productCategoryInput, setProductCategoryInput] = useState('');
  const [isLeaveModal, setIsLeaveModal] = useState<boolean>(false);
  const [targettedPath, setTargettedPath] = useState<string>();
  const [disabledDelBtn, setDisabledDelBtn] = useState(false);
  const { catalogId } = useParams<{ catalogId: string }>();
  const [formChanges, setFormChanges] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [rulesChanges, setRulesChanges] = useState<boolean>(false);

  const [productListData, setProductListData] = useState<CatalogProductListType>({
    autoMaticProductListIds: [],
    manualProductListIds: [],
    selectedProductsIds: []
  });

  const [generalInfo, setGeneralInfo] = useState<GeneralInfo>({
    link: constVariables.Catalogs.addCatalog.catalogDummyTitleUrl,
    placeHolder: constVariables.Catalogs.addCatalog.placeHolder,
    title: constVariables.Catalogs.addCatalog.catalogName,
    isCatalog: true,
    description: '',
    updatedAt: '',
    value: ''
  });
  const [errorMessages, setErrorMessage] = useState<ErrorsInfo>({
    generalInfoTitleError: '',
    statusError: ''
  });

  useEffect(() => {
    if (generalInfo.value.length > 0) {
      setErrorMessage((prev) => ({
        ...prev,
        generalInfoTitleError: ''
      }));
    }
  }, [generalInfo.value]);

  useEffect(() => {
    if (generalInfo.value || thumbnailUrlData?.url) {
      setFormChanges(true);
    } else {
      setFormChanges(false);
    }
  }, [generalInfo.value, thumbnailUrlData?.url]);

  const openDelModal = () => {
    setShowDeleteModal(true);
  };

  const [createCatalogMutation] = useMutation(CREATE_CATALOG, {
    onCompleted: (data) => {
      if (data?.createCatalog?.success) {
        setFormChanges(false);
        setDisableSaveButton(false);
        showToast({
          successText: 'Catalog added successfully',
          message: `The Catalog ${generalInfo.value} has been added sucessfully`
        });
      }
    },
    onError: (err) => {
      setDisableSaveButton(false);
      if (err.message === 'CATALOG_NAME_EXISTS') {
        setErrorMessage((errorMessages) => {
          return { ...errorMessages, generalInfoTitleError: 'Catalog with same name already exist, please enter a new name' };
        });
      } else if (err.message === 'CATALOG_CREATION_LIMIT_EXCEEDED') {
        showToast({
          errorText: 'Catalog Creation Limit Exceeded',
          message: `Can not add anymore catalogs.`
        });
      }
    }
  });
  const [deleteCatalogMutation] = useMutation(DELETE_CATALOG, {
    onCompleted: (data) => {
      setDisabledDelBtn(false);
      if (data?.deleteCatalog?.success) {
        history.push(ROUTES.marketing.catalogs.allCatalogs);
        showToast({
          successText: 'Catalog deleted successfully',
          message: `The Catalog ${generalInfo.value} has been deleted sucessfully`
        });
      }
    },
    onError: (error) => {
      setDisabledDelBtn(false);
      console.error('Delete catalog error', error);
    }
  });
  const [updateCatalogMutation] = useMutation(UPDATE_CATALOG, {
    onCompleted: (data) => {
      if (data?.updateCatalog?.success) {
        setFormChanges(false);
        setDisableSaveButton(false);
        getCatalogData();
        showToast({
          successText: 'Catalog saved successfully',
          message: `The Catalog ${generalInfo.value} has been edited sucessfully`
        });
      }
    },
    onError: (error) => {
      console.error('Update catalog error', error);
      setDisableSaveButton(false);
    }
  });
  const [getCatalogData] = useLazyQuery(GET_CATALOG_LIST, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    variables: {
      input: {
        catalogIds: [parseInt(catalogId, 10)]
      }
    },
    onCompleted: (catalogData) => {
      if (catalogData?.getCatalogList) {
        const allData = catalogData?.getCatalogList?.catalogues.find(c => c.id === parseInt(catalogId, 10));
        document.title = `Catalogs - ${allData.name ? allData.name : ''}`;
        setGeneralInfo({ ...generalInfo, value: allData.name, link: allData.link, updatedAt: allData.updatedAt });

        setThumbnailUrlData({ url: allData.thumbnailUrl });
        setProductsCategory({ value: allData.productCategory });
        setProductCategoryInput(allData?.categoryText);
        setCatalogStatus({ value: allData.status });

        if (allData?.type === productAssignmentMethod.AUTOMATIC) {
          setProductListData({ ...productListData, autoMaticProductListIds: allData.productIds });
        } else {
          setProductListData({ ...productListData, manualProductListIds: allData.productIds });
        }

        if (allData?.productAssignmentRulesLayout) {
          setState({
            selectedProductAssignmentMethod: allData?.type
          });
          const productAssignmentRulesLayout = JSON.parse(allData?.productAssignmentRulesLayout);
          const arr = [] as any;
          if (productAssignmentRulesLayout.length > 0) {
            setRulesEngineConditions(productAssignmentRulesLayout);
            productAssignmentRulesLayout.forEach((element, index) => {
              const obj = Object.assign({}, arr[index]) as any;
              obj.categoryDropdown = element.categoryOptions;
              arr[index] = obj as any;
              setConditionDropdownArray([...arr]);
            });
          }
          setAllConditionMatch(allData?.rulesMatch === 'ALL' ? true : false);
        }
        setFormChanges(false);
        setIsPageLoading(false);
      }
    },
    onError: (error) => {
      console.error('Get catalog details error', error);
    }
  });

  const openDiscardModal = () => {
    setIsDiscardModal(true);
  };
  const discardAllChanges = () => {
    document.body.style.overflow = 'unset';
    setTimeout(() => {
      history.push(ROUTES.marketing.catalogs.allCatalogs);
    }, 100);

    setIsDiscardModal(false);
    setIsLeaveModal(false);
    setFormChanges(false);
  };

  const goBackToCatalogScreen = () => {
    if (formChanges) {
      setIsLeaveModal(true);
    } else {
      history.goBack();
    }
  };

  const validationData = (value, fieldName) => {
    if (fieldName === 'name') {
      if (!value) {
        setErrorMessage((errorMessages) => {
          return { ...errorMessages, generalInfoTitleError: "Catalog name can't be left empty, please enter a name" };
        });
        return true;
      } else {
        setErrorMessage((errorMessages) => {
          return { ...errorMessages, generalInfoTitleError: '' };
        });
        return false;
      }
    } else if (fieldName === 'status') {
      if (!value) {
        setErrorMessage((errorMessages) => {
          return { ...errorMessages, statusError: 'Please select the catalog status' };
        });
        return true;
      } else {
        setErrorMessage((errorMessages) => {
          return { ...errorMessages, statusError: '' };
        });
        return false;
      }
    }
  };

  const createCatalogFun = () => {
    let isError = false;
    const productAssignmentRules = [] as any;

    if (state.selectedProductAssignmentMethod === productAssignmentMethod.AUTOMATIC) {
      rulesEngineConditions.forEach((element) => {
        const ruleObject = {} as any;
        switch (element.field.name) {
          case 'Product name':
            ruleObject.fact = 'productName';
            break;
          case 'Product type':
            ruleObject.fact = 'productType';
            break;
          case 'Vendor Name':
            ruleObject.fact = 'vendorName';
            break;
          case 'Product tag':
            ruleObject.fact = 'productTag';
            break;
          case 'Product price':
            ruleObject.fact = 'productPrice';
            break;
          case 'Product cost':
            ruleObject.fact = 'productCost';
            break;
          case 'Product stock':
            ruleObject.fact = 'inventoryStock';
            break;
          case 'Collection Name':
            ruleObject.fact = 'collection';
            break;
        }
        switch (element.condition) {
          case 'is equal to':
            ruleObject.operator = 'equal';
            break;
          case 'is not equal to':
            ruleObject.operator = 'notEqual';
            break;
          case 'is greater than':
            ruleObject.operator = 'greaterThan';
            break;
          case 'is less then':
            ruleObject.operator = 'lessThan';
            break;
          case 'contains':
            ruleObject.operator = 'stringContains';
            break;
          case 'does not contains':
            ruleObject.operator = 'stringNotContains';
            break;
        }
        ruleObject.value = element.category;

        if (typeof element.category == 'string' && element.category.trim().length === 0) {
          isError = true;
          element.errorMessage = `Field can't be empty`;
        } else {
          element.errorMessage = ``;
        }
        productAssignmentRules.push(ruleObject);
      });

      if (!rulesEngineConditions.length) {
        isError = true;
        showToast({
          errorText: 'Error',
          message: `Product condition not specified`
        });
      }
    }
    if (isError) {
      setFormChanges(false);
    }

    if (catalogId) {
      if (!isError) {
        setDisableSaveButton(true);
        updateCatalogMutation({
          variables: {
            input: {
              thumbnailUrl: thumbnailUrlData && thumbnailUrlData.url ? thumbnailUrlData.url : null,
              productIds:
                state.selectedProductAssignmentMethod !== 'MANUAL'
                  ? productListData.autoMaticProductListIds
                  : productListData.manualProductListIds,
              productCategory: productsCategory.value,
              categoryText: productCategoryInput,
              status: catalogStatus.value,
              name: generalInfo.value,
              type: state.selectedProductAssignmentMethod,
              id: parseInt(catalogId, 10),
              rulesMatch: allConditionMatch ? 'ALL' : 'ANY',
              productAssignmentRules:
                state.selectedProductAssignmentMethod !== 'MANUAL' ? JSON.stringify(productAssignmentRules) : JSON.stringify([]),
              productAssignmentRulesLayout:
                state.selectedProductAssignmentMethod !== 'MANUAL' ? JSON.stringify(rulesEngineConditions) : JSON.stringify([])
            }
          }
        });
      }
    } else {
      const catalogObj = {
        name: generalInfo.value,
        status: catalogStatus.value
      };

      const catalogKeys = Object.keys(catalogObj);
      for (let i = 0; i < catalogKeys.length; i++) {
        validationData(catalogObj[catalogKeys[i]], catalogKeys[i]);
      }

      const isValidationError =
        catalogKeys.map((key) => validationData(catalogObj[key], key)).filter((v) => v === true).length > 0 ? true : false;

      if (isValidationError) return;

      if (!isError) {
        setDisableSaveButton(true);
        createCatalogMutation({
          variables: {
            input: {
              thumbnailUrl: thumbnailUrlData && thumbnailUrlData.url ? thumbnailUrlData.url : null,
              productCategory: productsCategory.value,
              categoryText: productCategoryInput,
              status: catalogStatus.value,
              name: generalInfo.value,
              type: state.selectedProductAssignmentMethod,
              productIds:
                state.selectedProductAssignmentMethod !== 'MANUAL'
                  ? productListData.autoMaticProductListIds
                  : productListData.manualProductListIds,
              rulesMatch: allConditionMatch ? 'ALL' : 'ANY',
              productAssignmentRules:
                state.selectedProductAssignmentMethod !== 'MANUAL' ? JSON.stringify(productAssignmentRules) : JSON.stringify([]),
              productAssignmentRulesLayout:
                state.selectedProductAssignmentMethod !== 'MANUAL' ? JSON.stringify(rulesEngineConditions) : JSON.stringify([])
            }
          }
        });
      }
    }
  };
  const leavePageHandler = () => {
    setFormChanges(false);
    // NOTE: Push to targetted path or products, handled with timeout for now.
    setTimeout(() => {
      if (targettedPath) {
        history.push(targettedPath);
      } else {
        history.goBack();
      }
    }, 100);
  };
  const deleteCatalog = (id: string) => {
    setFormChanges(false);
    setDisabledDelBtn(true);
    deleteCatalogMutation({
      variables: {
        input: {
          catalogIds: id
        }
      }
    });
  };

  useEffect(() => {
    if (catalogId) {
      getCatalogData();
      setIsPageLoading(true);
    }
  }, [catalogId]);

  useEffect(() => {
    const preventRoute = history.block((e: any) => {
      setTargettedPath(e.pathname);
      if (formChanges) {
        setIsLeaveModal(true);
        return false;
      } else {
        return true;
      }
    });
    return () => {
      preventRoute();
    };
  }, [history, formChanges]);

  // Rules engine

  const [fieldDropdown, setFieldDropdown] = useState<FieldConditionType[]>([]);
  const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), {
    selectedProductAssignmentMethod: productAssignmentMethod.MANUAL
  });
  const [allConditionMatch, setAllConditionMatch] = useState<boolean>(false);
  const [rulesEngineConditions, setRulesEngineConditions] = useState<ConditionType[]>([
    {
      field: {
        id: 0,
        name: ''
      },
      condition: '',
      category: '',
      errorMessage: '',
      openFieldDropdown: false,
      openConditionDropdown: false,
      openCategoryDropdown: false
    }
  ]);

  const [conditionDropdownArray, setConditionDropdownArray] = useState<
    {
      categoryDropdown: {
        name: string;
        id: number;
      }[];
    }[]
  >([
    {
      categoryDropdown: []
    }
  ]);

  const [callCatalogProductConditions, { data: catalogProductConditionData }] = useLazyQuery(GET_CATALOG_PRODUCT_CONDITIONS, {
    onCompleted: () => {
      setTimeout(() => {
        //
      }, 150);
    }
  });

  useEffect(() => {
    if (catalogProductConditionData?.getCatalogProductConditions) {
      const arr: { id: number; name: string; operations: string[] }[] = Array.from(
        catalogProductConditionData?.getCatalogProductConditions
      );
      const fieldDropdownArr: FieldConditionType[] = [];
      arr?.map((condition: { id: number; name: string; operations: string[] }, index: number) => {
        const operationArr: { name: string }[] = [];
        const obj: FieldConditionType = {
          id: 0,
          name: '',
          operations: [
            {
              name: ''
            }
          ]
        };
        condition?.operations?.map((operation, i) => {
          operationArr.push({ name: operation?.replace(/_/g, ' ').toLowerCase() });
          return null;
        });
        obj.id = condition.id;
        obj.name = condition.name;
        obj.operations = operationArr;
        fieldDropdownArr.push(obj);
        return null;
      });
      setFieldDropdown(fieldDropdownArr);
    }
  }, [catalogProductConditionData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    callCatalogProductConditions();

    if (!catalogId) {
      document.title = 'Catalogs - New';
    }
  }, []);

  const selectProductAssignmentMethod = (productAssignmentMethod) => {
    setFormChanges(true);
    setState({
      selectedProductAssignmentMethod: productAssignmentMethod
    });
  };

  const [editCatalogueRules] = useMutation(EDIT_CATALOGUE_RULES, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response?.updtCatalogPrdAssignRules) {
        const filteredProductIds = JSON.parse(response?.updtCatalogPrdAssignRules?.additionalData)?.map((productId) =>
          parseInt(productId, 10)
        );
        setProductListData((productListData) => {
          return { ...productListData, autoMaticProductListIds: filteredProductIds };
        });
        setFormChanges(true);
        setRulesChanges(false);
        // showToast({
        //   successText: 'Catalogue Rules saved successfully',
        //   message: `The Catalogue ${generalInfo.value} has been edited sucessfully`
        // });
      }
    },
    onError: (e) => {
      const error: any = e?.graphQLErrors[0];
      console.log('editCatalogueRules error - ', error);
    }
  });

  const saveModuleRules = () => {
    let isError = false;
    const productAssignmentRules = [] as any;

    if (state.selectedProductAssignmentMethod === productAssignmentMethod.AUTOMATIC) {
      rulesEngineConditions.forEach((element) => {
        const ruleObject = {} as any;
        switch (element.field.name) {
          case 'Product name':
            ruleObject.fact = 'productName';
            break;
          case 'Product type':
            ruleObject.fact = 'productType';
            break;
          case 'Vendor Name':
            ruleObject.fact = 'vendorName';
            break;
          case 'Product tag':
            ruleObject.fact = 'productTag';
            break;
          case 'Product price':
            ruleObject.fact = 'productPrice';
            break;
          case 'Product cost':
            ruleObject.fact = 'productCost';
            break;
          case 'Product stock':
            ruleObject.fact = 'inventoryStock';
            break;
          case 'Collection Name':
            ruleObject.fact = 'collection';
            break;
        }
        switch (element.condition) {
          case 'is equal to':
            ruleObject.operator = 'equal';
            break;
          case 'is not equal to':
            ruleObject.operator = 'notEqual';
            break;
          case 'is greater than':
            ruleObject.operator = 'greaterThan';
            break;
          case 'is less then':
            ruleObject.operator = 'lessThan';
            break;
          case 'contains':
            ruleObject.operator = 'stringContains';
            break;
          case 'does not contains':
            ruleObject.operator = 'stringNotContains';
            break;
        }
        ruleObject.value = element.category;

        if (typeof element.category == 'string' && element.category.trim().length === 0) {
          isError = true;
          element.errorMessage = `Field can't be empty`;
        } else {
          element.errorMessage = ``;
        }
        productAssignmentRules.push(ruleObject);
      });

      if (!rulesEngineConditions.length) {
        isError = true;
        showToast({
          errorText: 'Error',
          message: `Product condition not specified`
        });
      }
    }

    if (isError) {
      setFormChanges(false);
      setRulesChanges(false);
      return;
    }

    if (!isError) {
      const input = {
        // id: catalogId ? parseInt(catalogId, 10) : undefined,
        id: undefined,
        type: state.selectedProductAssignmentMethod,
        rulesMatch: allConditionMatch ? 'ALL' : 'ANY',
        productAssignmentRules: JSON.stringify(productAssignmentRules),
        productAssignmentRulesLayout: JSON.stringify(rulesEngineConditions)
      };

      editCatalogueRules({
        variables: {
          input,
          state: Math.random()
        }
      });
    }
  };

  const closeDiscardModal = () => {
    document.body.style.overflow = 'unset';
    setIsDiscardModal(false);
  };

  const closeLeaveModal = () => {
    document.body.style.overflow = 'unset';
    setIsLeaveModal(false);
  };

  const closeDeleteModal = () => {
    document.body.style.overflow = 'unset';
    setShowDeleteModal(false);
  };

  return (
    <>
      {formChanges && <SaveHeader onSave={createCatalogFun} saveDisabled={disableSaveButton} onDiscard={openDiscardModal} />}
      <div className="details-page">
        <AddCatalogPresentational
          formChanges={formChanges}
          isPageLoading={isPageLoading}
          setProductCategoryInput={setProductCategoryInput}
          goBackToCatalogScreen={goBackToCatalogScreen}
          productCategoryInput={productCategoryInput}
          setProductCategory={setProductsCategory}
          setProductListData={setProductListData}
          setCatalogStatus={setCatalogStatus}
          thumbnailUrlData={thumbnailUrlData}
          setThumbnailUrlData={setThumbnailUrlData}
          productCategory={productsCategory}
          productListData={productListData}
          setGeneralInfo={setGeneralInfo}
          disabledDelBtn={disabledDelBtn}
          catalogStatus={catalogStatus}
          errorMessages={errorMessages}
          deleteCatalog={openDelModal}
          generalInfo={generalInfo}
          catalogId={catalogId}
          // Rules engine
          rulesEngineConditions={rulesEngineConditions}
          setRulesEngineConditions={setRulesEngineConditions}
          fieldDropdown={fieldDropdown}
          setAllConditionMatch={setAllConditionMatch}
          allConditionMatch={allConditionMatch}
          conditionDropdownArray={conditionDropdownArray}
          setConditionDropdownArray={setConditionDropdownArray}
          selectedProductAssignmentMethod={state.selectedProductAssignmentMethod}
          selectProductAssignmentMethod={selectProductAssignmentMethod}
          setFormChanges={setFormChanges}
          rulesChanges={rulesChanges}
          setRulesChanges={setRulesChanges}
          saveModuleRules={saveModuleRules}
          // Rules engine
        />
      </div>
      {isDiscardModal && (
        <CustomModal bodyClassname="w-90 w-md-50" show={isDiscardModal} closeModal={closeDiscardModal}>
          <DiscardModal
            actionBtnTitle={constVariables.common.discardForm.action}
            cancelBtnTitle={constVariables.common.discardForm.cancel}
            message={constVariables.common.discardForm.message}
            title={constVariables.common.discardForm.title}
            actionBtnHandler={discardAllChanges}
            cancelBtnHandler={closeDiscardModal}
          />
        </CustomModal>
      )}
      {isLeaveModal && (
        <CustomModal show={isLeaveModal} bodyClassname="w-90 w-md-50" closeModal={closeLeaveModal}>
          <DiscardModal
            actionBtnTitle={constVariables.common.LeaveForm.action}
            cancelBtnTitle={constVariables.common.LeaveForm.cancel}
            message={constVariables.common.LeaveForm.message}
            title={constVariables.common.LeaveForm.title}
            actionBtnHandler={leavePageHandler}
            cancelBtnHandler={closeLeaveModal}
            deleteProductMedia
          />
        </CustomModal>
      )}
      {showDeleteModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showDeleteModal} closeModal={closeDeleteModal}>
          <DiscardModal
            deleteProductMedia
            actionBtnTitle={constVariables.common.deleteCatalog.action}
            cancelBtnTitle={constVariables.common.deleteCatalog.cancel}
            message={constVariables.common.deleteCatalog.mainMessage}
            title={constVariables.common.deleteCatalog.titleSingle}
            actionBtnHandler={deleteCatalog}
            cancelBtnHandler={closeDeleteModal}
          />
        </CustomModal>
      )}
    </>
  );
};

export default AddCatalog;
