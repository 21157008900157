/* eslint-disable no-nested-ternary */
import { FC, useEffect, useState } from 'react';

// Apollo
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_SHIPPING_RATE, GET_STORE_DETAILS } from 'src/apollo/queries';

// Components
import { Loader } from 'src/components/atoms';
import {
  OrderDetailsBlockFulfillmentCenter,
  PackageAndShippingFulfillmentCenter,
  ShippingRatesFulfillmentCenter,
  Divider
} from 'src/components/molecules';
import { CustomModal } from 'src/components/oraganisms';
import LeaveFulfillmentCenterModal from 'src/components/pages/orders/FulfillmentCenter/LeaveFulfillmentCenterModal';

// Hooks && Utils && Helpers
import { convertWeight } from 'src/utils/weightConverter';

// Icons
import { NoOrderSelected } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { OrderDetailsFulfillmentCenterProps, ShippingRateInputProp } from './OrderDetailsFulfillmentCenter.types';
import { PackageData } from 'src/components/molecules/PackageAndShippingFulfillmentCenter/PackageAndShippingFulfillmentCenter.types';
import { client } from 'src/apollo/client';
import { useToast } from 'src/utils/hooks/useToast';

const defaultPackageData: PackageData = {
  carrier: '',
  carrierCode: '',
  packageCode: '',
  distanceUnit: '',
  height: 0.0,
  length: 0.0,
  massUnit: '',
  name: '',
  token: '',
  type: '',
  width: 0.0,
  weight: 0.0,
  carrierIds: []
};

const OrderDetailsFulfillmentCenter: FC<OrderDetailsFulfillmentCenterProps> = ({
  isLoading,
  orderDetails,
  refetchOrderDetails,
  orderShipment,
  refetchOrderShipment,
  refetchOrderList,
  selectedStatus,
  setSelectedStatus,
  openRefundModalRemotely,
  setOpenRefundModalRemotely,
  setErrorModal,
  setErrorModalObj,
  updateOrderDetails,
  selectedOrder,
  setSelectedOrder
}) => {
  const [unverifiedAddressModal, setUnverifiedAddressModal] = useState(false);
  const [addSignatureConfirmation, setAddSignatureConfirmation] = useState(false);
  const [addShipmentInsurance, setAddShipmentInsurance] = useState(false);
  const [totalOrderValue, setTotalOrderValue] = useState(0.0);
  const [pkgContentDescription, setPkgContentDescription] = useState('');
  const [pkgWeightValue, setPkgWeightValue] = useState(0.0);
  const [pkSelfWeightValue, setPkSelfWeightValue] = useState(0.0);
  const [showPackageData, setShowPackageData] = useState(false);
  const [pkgUnitValue, setPkgUnitValue] = useState('kg');
  const [useWeightCheck, setUseWeightCheck] = useState(false);
  const [packageData, setPackageData] = useState(defaultPackageData);

  const [scannedData, setScannedData] = useState({ weight: '0 Kg', scannedCount: 0 });

  const toggleunVerifiedAddModal = () => {
    setUnverifiedAddressModal(!unverifiedAddressModal);
  };

  const { data: storeDetails } = useQuery(GET_STORE_DETAILS);

  const [combinedShippingRates, setCombinedShippingRates] = useState([]);

  const [loadingRates, setLoadingRates] = useState(false);

  useEffect(() => {
    if (orderDetails?.orderDetails?.length) {
      if (orderShipment?.id) {
        setPackageData(JSON.parse(orderShipment.packageInfo));
        // setAddShipmentInsurance(orderShipment.hasInsurance);
        if (orderShipment.hasInsurance) {
          setTotalOrderValue(orderShipment.insurance?.amount || 0.0);
          setPkgContentDescription(orderShipment.insurance?.content || '');
        }
        setPkgWeightValue(
          convertWeight(
            Math.round(orderShipment.packageWeight * 100) / 100,
            orderShipment.packageWeightUnit,
            storeDetails?.getStoreDetails?.weightUnit
          )
        );
        setPkSelfWeightValue(
          convertWeight(
            Math.round(orderShipment.packageWeight * 100) / 100,
            orderShipment.packageWeightUnit,
            storeDetails?.getStoreDetails?.weightUnit
          )
        );
        setPkgUnitValue(storeDetails?.getStoreDetails?.weightUnit);

        // setAddSignatureConfirmation(orderShipment.needsSignatureConfirmation);
        setUseWeightCheck(orderShipment.useWeightOfItems);

        let scannedCount = 0;
        let weight = 0;
        orderDetails?.orderDetails?.map((order) => {
          JSON.parse(order?.items)?.map((item) => {
            const dimension = order?.dimensions ? JSON.parse(order?.dimensions) : {};
            if (+item.shipmentId === +orderShipment?.id) {
              if (item?.isScanned) {
                scannedCount += 1;
              }
              weight += convertWeight(
                +dimension?.weight?.value,
                dimension?.weight?.unit,
                storeDetails?.getStoreDetails?.weightUnit
              );
            }
            return null;
          });
          return null;
        });

        weight = +weight.toFixed(2);
        setScannedData({
          scannedCount,
          weight: `${weight} ${storeDetails?.getStoreDetails?.weightUnit}`
        });
      } else {
        let scannedCount = 0;
        let weight = 0;
        orderDetails?.orderDetails?.map((order) => {
          JSON.parse(order?.items)?.map((item) => {
            const dimension = order?.dimensions ? JSON.parse(order?.dimensions) : {};
            if (!item?.isPresold && !item.shipmentId) {
              if (item?.isScanned) {
                scannedCount += 1;
              }
              weight += convertWeight(
                +dimension?.weight?.value,
                dimension?.weight?.unit,
                storeDetails?.getStoreDetails?.weightUnit
              );
              // if (order?.addShipmentInsurance) {
              //   setAddShipmentInsurance(true);
              // }
              // if (order?.addSignatureConfirmation) {
              //   setAddSignatureConfirmation(true);
              // }
            }
            return null;
          });
          return null;
        });

        weight = +weight.toFixed(2);

        setPkgWeightValue(weight);
        setPkSelfWeightValue(weight);
        setPkgUnitValue(storeDetails?.getStoreDetails?.weightUnit);

        setScannedData({
          scannedCount,
          weight: `${weight} ${storeDetails?.getStoreDetails?.weightUnit}`
        });

        if (scannedCount && scannedCount === orderDetails?.availableItemsQuantity) {
          setShowPackageData(true);
        } else {
          setShowPackageData(false);
        }
      }
    }
  }, [orderDetails, orderShipment, storeDetails?.getStoreDetails?.weightUnit]);

  const { showToast } = useToast();
  const fetchRates = async () => {
    if (loadingRates) {
      return;
    }
    setLoadingRates(true);
    setCombinedShippingRates([]);
    let isAddedList = [];
    const promises = packageData.carrierIds.map((carrierId) => {
      const input: ShippingRateInputProp = {
        orderId: selectedOrder?.toString() || '',
        signatureConfirmation: addSignatureConfirmation,
        parcel: {
          width: packageData.width,
          height: packageData.height,
          length: packageData.length,
          weight: pkgWeightValue,
          distanceUnit: packageData.distanceUnit,
          massUnit: pkgUnitValue,
          // @ts-ignore
          carrierId,
          packageCode: packageData.packageCode
        },
        insurance: {
          amount: totalOrderValue,
          content: pkgContentDescription
        }
      };
      if (!addShipmentInsurance) {
        delete input.insurance;
      }
      return client
        .query({
          query: GET_SHIPPING_RATE,
          variables: {
            input
          }
        })
        .then((result) => {
          if (result && result.data && result.data.getShippingRates && result.data.getShippingRates.length > 0) {
            const isUsps = result.data.getShippingRates.find((r) => r.provider === 'usps');
            if (isUsps && isAddedList.length === 0) {
              // @ts-ignore
              setCombinedShippingRates((rates) => [...rates, ...result.data.getShippingRates]);
              isAddedList = result.data.getShippingRates;
            }
          }
          return result.data.getShippingRates;
        })
        .catch((err) => {
          console.error('Shipping rates error -', err.message);
          if (err.message === 'ADDRESS_NOT_VERIFIED') {
            setUnverifiedAddressModal(true);
          } else if (err.message === 'WAREHOUSE_ADDRESS_NOT_VERIFIED') {
            setUnverifiedAddressModal(true);
          } else {
            showToast({
              errorText: err.message,
              message: `Get shipping rates error: ${err.message}`
            });
          }
        });
    });
    const allRes = await Promise.all(promises);
    // @ts-ignore
    const added = new Set(isAddedList.map((r) => r.objectId));
    const notAdded = allRes.flat(1).filter((r) => !added.has(r.objectId));
    // @ts-ignore
    setCombinedShippingRates((rates) => [...rates, ...notAdded]);
    setLoadingRates(false);
  };

  useEffect(() => {
    if (packageData.name && pkgWeightValue > 0 && !orderShipment.id) {
      fetchRates();
    }
  }, [
    packageData,
    pkgUnitValue,
    pkgWeightValue,
    addSignatureConfirmation,
    totalOrderValue,
    pkgContentDescription,
    addShipmentInsurance,
    orderShipment,
    selectedOrder
  ]);

  const closeUnverifiedAddressModal = () => {
    document.body.style.overflow = 'unset';
    setUnverifiedAddressModal(false);
  };

  return (
    <>
      {unverifiedAddressModal && (
        <CustomModal show={unverifiedAddressModal} closeModal={closeUnverifiedAddressModal}>
          <LeaveFulfillmentCenterModal
            title={constVariables.FulfillmentCenter.unverifiedAddModal.title}
            actionBtnTitle={constVariables.FulfillmentCenter.unverifiedAddModal.actionButtonTitle}
            cancelBtnTitle={constVariables.FulfillmentCenter.unverifiedAddModal.cancelButtonTitle}
            message={constVariables.FulfillmentCenter.unverifiedAddModal.message}
            actionBtnHandler={toggleunVerifiedAddModal}
            cancelBtnHandler={closeUnverifiedAddressModal}
          />
        </CustomModal>
      )}
      <div className="d-flex flex-column flex-grow-1">
        <div className=" bg-white rounded flex-column flex-xl-row  w-100 d-flex flex-grow-1">
          {isLoading ? (
            <Loader type="page" className="h-100 card" />
          ) : selectedOrder !== -1 ? (
            <>
              <div className="w-100 w-xl-75 d-flex flex-grow-1">
                <OrderDetailsBlockFulfillmentCenter
                  orderDetails={orderDetails}
                  refetchOrderDetails={refetchOrderDetails}
                  orderShipment={orderShipment}
                  setErrorModal={setErrorModal}
                  setErrorModalObj={setErrorModalObj}
                  updateOrderDetails={updateOrderDetails}
                  setSelectedOrder={setSelectedOrder}
                  scannedCount={scannedData?.scannedCount}
                  weightStr={scannedData?.weight}
                />
              </div>
              <Divider vertical classname="h-100 m-0" />
              <div className=" w-100 w-xl-25 d-flex flex-column flex-grow-1">
                <Divider classname="packageShippingDivider" />
                <div className="d-flex mt-2 mt-xl-0 flex-grow-1 columnSize h-10px">
                  <PackageAndShippingFulfillmentCenter
                    showPackageData={showPackageData}
                    addSignatureConfirmation={addSignatureConfirmation}
                    addShipmentInsurance={addShipmentInsurance}
                    setAddSignatureConfirmation={setAddSignatureConfirmation}
                    setAddShipmentInsurance={setAddShipmentInsurance}
                    totalWeight={orderDetails?.totalWeight || 0}
                    pkgWeightValue={pkgWeightValue}
                    pkSelfWeightValue={pkSelfWeightValue}
                    pkgUnitValue={pkgUnitValue}
                    setPkgUnitValue={setPkgUnitValue}
                    setPkgWeightValue={setPkgWeightValue}
                    packageData={packageData}
                    setPackageData={setPackageData}
                    totalOrderValue={totalOrderValue}
                    setTotalOrderValue={setTotalOrderValue}
                    pkgContentDescription={pkgContentDescription}
                    setPkgContentDescription={setPkgContentDescription}
                    useWeightCheck={useWeightCheck}
                    setUseWeightCheck={setUseWeightCheck}
                    orderShipment={orderShipment}
                    orderId={orderDetails?.id}
                  />
                </div>
                <Divider />
                <div
                  className="d-flex flex-grow-1 columnSize h-10px overflow-hidden"
                  style={{
                    background: 'white'
                  }}
                >
                  <ShippingRatesFulfillmentCenter
                    isLoading={loadingRates}
                    shippingRateList={
                      showPackageData && pkgWeightValue && packageData.carrierIds && packageData.carrierIds.length > 0
                        ? combinedShippingRates
                        : []
                    }
                    pkgWeightValue={pkgWeightValue}
                    pkgUnitValue={pkgUnitValue}
                    packageData={packageData}
                    insurance={{ amount: totalOrderValue, content: pkgContentDescription }}
                    addShipmentInsurance={addShipmentInsurance}
                    signatureConfirmation={addSignatureConfirmation}
                    useWeightCheck={useWeightCheck}
                    orderId={orderDetails?.id || 0}
                    openRefundModalRemotely={openRefundModalRemotely}
                    setOpenRefundModalRemotely={setOpenRefundModalRemotely}
                    totalProducts={orderDetails?.items || 0}
                    refetchShippingRate={() => {
                      if (packageData.name && pkgWeightValue > 0) {
                        fetchRates();
                      }
                    }}
                    orderShipment={orderShipment}
                    refetchOrderShipment={refetchOrderShipment}
                    refetchOrderDetails={refetchOrderDetails}
                    refetchOrderList={refetchOrderList}
                    selectedStatus={selectedStatus}
                    setSelectedStatus={setSelectedStatus}
                    selectedOrder={selectedOrder}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="w-100 flex-center">
              <img src={NoOrderSelected} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OrderDetailsFulfillmentCenter;
