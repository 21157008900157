import { useState, useEffect, useRef, FC } from 'react';
import { debounce } from 'lodash';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_PRODUCTS_V3 } from 'src/apollo/queries';

// Components
import { FlexBox } from 'src/components/atoms';
import { SearchBar, CatalogSortDropdown } from 'src/components/molecules';
import { EmptyList } from 'src/components/oraganisms';
import LiveProductsTable from './LiveProductsTable';

// Types
import { LiveProductsBlockProps, LiveProductType, queryResult } from './LiveProductsBlock.types';
import { GET_LIVE_PRODUCT_ANALYTICS } from '../../../apollo/queries/getLiveProductAnalytics';
import FilterMenuDropdown from '../../pages/home/FilterMenuDropdown';
import { useToast } from '../../../utils/hooks/useToast';
import MoveLiveProductModal from '../../oraganisms/MoveLiveProductModal/MoveLiveProductModal';

const _ = require('lodash');

export interface LiveProductOptionItem {
  id: number;
  name: LiveProductFilter;
}
enum LiveProductFilter {
  'All Products' = 'All Products',
  'Displayed Products' = 'Displayed Products',
  'Undisplayed Products' = 'Undisplayed Products'
}

const productFilterList: LiveProductOptionItem[] = [
  { id: 1, name: LiveProductFilter['All Products'] },
  { id: 2, name: LiveProductFilter['Displayed Products'] },
  { id: 3, name: LiveProductFilter['Undisplayed Products'] }
];

const EMPTY_ARRAY = [];

const LiveProductsBlock: FC<LiveProductsBlockProps> = ({
  selectedProductIDs,
  handleUpdateSelectedProductIDs,
  shouldShowVariants,
  isShowingOnModal,
  disabledIds,
  isLiveshow,
  isShowOnlyActiveProducts,
  handleAddProductsToLivePreview,
  isAddingToTiktok,
  liveId,
  getSelectValue,
  isLoadingAnalytic
}) => {
  const { showToast } = useToast();
  const { loading: isLoadingProduct, data: liveProductResponse } = useQuery(GET_LIVE_PRODUCT_ANALYTICS, {
    variables: { id: liveId },
    onError: (error: any) => {
      showToast({
        errorText: error.message,
        message: `Error occured while get live show products: ${error.message}`
      });
    }
  });

  const liveProductList = (liveProductResponse && liveProductResponse.getLiveProductAnalytics) || EMPTY_ARRAY;

  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [selectedChannelFilter, setSelectedChannelFilter] = useState(productFilterList[0]);

  const [totalProducts, setTotalProducts] = useState(0);
  const [liveProductListToDisplay, setLiveProductListToDisplay] = useState<LiveProductType[]>([]);

  const [searchText, setSearchText] = useState('');
  const handleSearch = (value) => {
    setSearchText(value);
  };

  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');

  useEffect(() => {
    if (sortBy === 'Product') {
      if (sortType === 'ASC') {
        const sortedItems = [...liveProductListToDisplay].sort((a, b) => a.title.localeCompare(b.title));
        return setLiveProductListToDisplay(sortedItems);
      }
      if (sortType === 'DESC') {
        const sortedItems = [...liveProductListToDisplay].sort((a, b) => b.title.localeCompare(a.title));
        return setLiveProductListToDisplay(sortedItems);
      }
    }
    if (sortBy === 'Likes') {
      if (sortType === 'ASC') {
        const sortedItems = [...liveProductListToDisplay].sort(
          // @ts-ignore
          (a, b) => getSelectValue(a?.impressions) - getSelectValue(b?.impressions)
        );
        return setLiveProductListToDisplay(sortedItems);
      }
      if (sortType === 'DESC') {
        const sortedItems = [...liveProductListToDisplay].sort(
          // @ts-ignore
          (a, b) => getSelectValue(b?.impressions) - getSelectValue(a?.impressions)
        );
        return setLiveProductListToDisplay(sortedItems);
      }
    }
    if (sortBy === 'CTR') {
      if (sortType === 'ASC') {
        // @ts-ignore
        const sortedItems = [...liveProductListToDisplay].sort((a, b) => getSelectValue(a?.ctr) - getSelectValue(b?.ctr));
        return setLiveProductListToDisplay(sortedItems);
      }
      if (sortType === 'DESC') {
        // @ts-ignore
        const sortedItems = [...liveProductListToDisplay].sort((a, b) => getSelectValue(b?.ctr) - getSelectValue(a?.ctr));
        return setLiveProductListToDisplay(sortedItems);
      }
    }
    if (sortBy === 'Orders') {
      if (sortType === 'ASC') {
        // @ts-ignore
        const sortedItems = [...liveProductListToDisplay].sort((a, b) => getSelectValue(a?.orders) - getSelectValue(b?.orders));
        return setLiveProductListToDisplay(sortedItems);
      }
      if (sortType === 'DESC') {
        // @ts-ignore
        const sortedItems = [...liveProductListToDisplay].sort((a, b) => getSelectValue(b?.orders) - getSelectValue(a?.orders));
        return setLiveProductListToDisplay(sortedItems);
      }
    }
    if (sortBy === 'Price') {
      if (sortType === 'ASC') {
        // @ts-ignore
        const sortedItems = [...liveProductListToDisplay].sort((a, b) => getSelectValue(a?.price) - getSelectValue(b?.price));
        return setLiveProductListToDisplay(sortedItems);
      }
      if (sortType === 'DESC') {
        // @ts-ignore
        const sortedItems = [...liveProductListToDisplay].sort((a, b) => getSelectValue(b?.price) - getSelectValue(a?.price));
        return setLiveProductListToDisplay(sortedItems);
      }
    }
    if (sortBy === 'Revenue') {
      if (sortType === 'ASC') {
        // @ts-ignore
        const sortedItems = [...liveProductListToDisplay].sort((a, b) => getSelectValue(a?.revenue) - getSelectValue(b?.revenue));
        return setLiveProductListToDisplay(sortedItems);
      }
      if (sortType === 'DESC') {
        // @ts-ignore
        const sortedItems = [...liveProductListToDisplay].sort((a, b) => getSelectValue(b?.revenue) - getSelectValue(a?.revenue));
        return setLiveProductListToDisplay(sortedItems);
      }
    }
  }, [sortBy, sortType]);

  useEffect(() => {
    let products;
    if (selectedChannelFilter.id === 1) {
      products = liveProductList;
    }
    if (selectedChannelFilter.id === 2) {
      products = liveProductList.filter((p) => p.isDisplayed);
    }
    if (selectedChannelFilter.id === 3) {
      products = liveProductList.filter((p) => !p.isDisplayed);
    }
    if (searchText) {
      const filtered = products.filter((p) => p.title.toLowerCase().includes(searchText.toLowerCase()));
      setLiveProductListToDisplay(filtered);
      setTotalProducts(filtered.length);
    } else {
      setLiveProductListToDisplay(products);
      setTotalProducts(products.length);
    }

    setSortBy('');
    setSortType('');
  }, [searchText, selectedChannelFilter, liveProductList]);

  const selectProduct = (e: any, product?: any) => {
    // Return only product ids
    if (product === 'All') {
      const tempArr: Array<string> = liveProductList.map((product) => product.productId);
      if (!e.target.checked) {
        handleUpdateSelectedProductIDs([]);
      } else {
        handleUpdateSelectedProductIDs(tempArr);
      }
    } else if (e.target.checked) {
      handleUpdateSelectedProductIDs([...selectedProductIDs, product.productId]);
    } else {
      const arr = selectedProductIDs;
      arr.forEach((item, index) => {
        if (item === product.productId) arr.splice(index, 1);
      });
      handleUpdateSelectedProductIDs([...arr]);
    }
  };

  const handleTableScroll = (event: React.UIEvent<HTMLDivElement>) => {};

  const handleSelectChannelFilter = (selectedId: number) => {
    const selected = productFilterList.filter((filterItem) => filterItem.id === selectedId)[0];
    setSelectedChannelFilter(selected);
    setShowStatusDropdown(false);
  };

  const [showModal, setShowModal] = useState<boolean>(false);

  const [singleProductId, setSingleProductId] = useState();
  const openModal = (id) => {
    if (id) {
      setSingleProductId(id);
    }
    setShowModal(true);
  };

  if (isLoadingAnalytic) {
    return <div></div>;
  }
  return (
    <div>
      <FlexBox className="justify-content-between w-auto live-search-bar-container m-b-16">
        <SearchBar placeholder="Search for products" onChange={handleSearch} />
        <FlexBox className="align-items-center justify-content-between">
          <FlexBox className="align-items-center">
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowStatusDropdown(false);
              }}
            >
              <button
                className={`btn btn-sm btn-flex btn-icon-text align-items-center border-none m-0 ${
                  showStatusDropdown ? 'btn-primary' : 'btn-outlined-primary'
                }`}
                style={{
                  height: '44px',
                  background: showStatusDropdown ? undefined : '#F8F8FA'
                }}
                onClick={() => setShowStatusDropdown(true)}
              >
                <span className="poppins-regular">{selectedChannelFilter.name}</span>
              </button>
              <FilterMenuDropdown
                isShowDropdown={showStatusDropdown}
                data={productFilterList}
                selectedValue={selectedChannelFilter}
                onSelect={handleSelectChannelFilter}
              />
            </OutsideClickHandler>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {selectedProductIDs.length > 0 && (
        <div className="m-b-16">
          <button className="btn btn-outlined-primary btn-sm me-2" onClick={() => handleUpdateSelectedProductIDs([])}>
            <div className="d-flex align-items-center">
              <input
                className={'form-check-input small-checkbox-size shadow-none opacity-100 bg-primary rounded-sm m-0'}
                type="checkbox"
                disabled={true}
                checked={selectedProductIDs.length > 0}
                ref={(input) => {
                  if (input) {
                    input.indeterminate = true;
                  }
                }}
              />
              <span className="ms-2">{selectedProductIDs.length} Selected</span>
            </div>
          </button>
          {/*// @ts-ignore*/}
          <button className="btn btn-outlined-primary btn-sm me-2" onClick={() => openModal()}>
            Assign to Liveshow
          </button>
        </div>
      )}
      <div>
        <LiveProductsTable
          productList={liveProductListToDisplay}
          setProductList={setLiveProductListToDisplay}
          isLoading={isLoadingProduct}
          selectProduct={selectProduct}
          selectedProducts={selectedProductIDs}
          disabledIds={disabledIds}
          isLoadingMore={false}
          searchText={searchText}
          onScroll={handleTableScroll}
          shouldShowVariants={shouldShowVariants}
          isShowingOnModal={isShowingOnModal}
          isLiveshow={isLiveshow}
          getSelectValue={getSelectValue}
          openModal={openModal}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortType={sortType}
          setSortType={setSortType}
        />
      </div>
      {showModal && (
        <MoveLiveProductModal
          show={showModal}
          closeModal={() => {
            setSingleProductId(undefined);
            setShowModal(false);
          }}
          selectedProductIDs={selectedProductIDs}
          singleProductId={singleProductId}
        />
      )}
    </div>
  );
};

export default LiveProductsBlock;
