import { gql } from '@apollo/client/core';

export const GET_LIVE_SHOW_ANALYTICS = gql`
  query GetLiveshowAnalytics($id: String!) @api(name: "liveStreamingAPI") {
    getLiveshowAnalytics(id: $id) {
      id
      title
      startTime
      endTIme
      stats {
        viewers {
          live
          vod
          total
        }
        avgViewersTime {
          live
          vod
          total
        }
        ctr {
          live
          vod
          total
        }
        avgCartSize {
          live
          vod
          total
        }
        sales {
          live
          vod
          total
        }
        profit {
          live
          vod
          total
        }
        paidOrders {
          live
          vod
          total
        }
        unpaidOrders {
          live
          vod
          total
        }
        buyers {
          live
          vod
          total
        }
        likes {
          live
          vod
          total
        }
      }
    }
  }
`;
