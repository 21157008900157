import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import OutsideClickHandler from 'react-outside-click-handler';
import moment from 'moment';

// Components
import { Img, DropdownSelector } from 'src/components/atoms';
import { Dropdown, Divider, DatesDropdownSelector, Checkbox, TimePicker } from 'src/components/molecules';

// Icons
import { CalendarLightIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

// Types
import { StatusTypeSection } from 'src/components/pages/coupons/CreateCoupon/CreateCoupon.types';

const StatusCreateCoupon: FC<StatusTypeSection> = ({
  status,
  setStatus,
  setFormChanges,
  couponTimesError,
  setCouponTimesError
}) => {
  const { couponId } = useParams<{ couponId: string }>();
  const [isCatalogDropdown, setIsCatalogDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState('Select');
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);

  useEffect(() => {
    if (status.statusValue === 'ACTIVE') {
      setSelectedValue('Active');
    } else if (status.statusValue === 'INACTIVE') {
      setSelectedValue('Inactive');
    } else if (status.statusValue === 'SCHEDULED') {
      setSelectedValue('Scheduled');
    } else if (status.statusValue === 'EXPIRED') {
      setSelectedValue('Expired');
    } else {
      setSelectedValue('Select');
    }
  }, [status]);

  const toggleIsSetEndDate = () => {
    if (status.isSetEndDate) {
      setStatus((prev) => ({ ...prev, isSetEndDate: false }));
    } else {
      let tempDate = new Date();
      if (status.startDate) {
        tempDate = new Date(status.startDate);
      }
      tempDate.setDate(tempDate.getDate() + 7);
      // tempDate.setHours(0, 0, 0);

      setStatus((prev) => ({
        ...prev,
        isSetEndDate: true,
        endDate: tempDate,
        endTime: `${moment(new Date(tempDate)).format('hh:mm')} ${moment(new Date(tempDate)).format('a').toUpperCase()}`
        // endTime: `00:00AM`
      }));
    }
  };

  const toggleWarehouseDropdown = () => {
    setIsCatalogDropdown(!isCatalogDropdown);
  };
  const closeStatusDropdown = () => {
    setIsCatalogDropdown(false);
  };

  const selectStatus = (data: { id: number; name: string }) => {
    closeStatusDropdown();
    if (data.id === 1) {
      setStatus((prev) => ({ ...prev, statusValue: 'ACTIVE' }));
    } else if (data.id === 2) {
      setStatus((prev) => ({ ...prev, statusValue: 'INACTIVE' }));
    }
  };

  const [startTimeDropDownDataAm, setStartTimeDropDownDataAm] = useState<{ id: number; name: Date }[]>();
  const [startTimeDropDownDataPm, setStartTimeDropDownDataPm] = useState<{ id: number; name: Date }[]>();

  const [endTimeDropDownDataAm, setEndTimeDropDownDataAm] = useState<{ id: number; name: Date }[]>();
  const [endTimeDropDownDataPm, setEndTimeDropDownDataPm] = useState<{ id: number; name: Date }[]>();

  useEffect(() => {
    if (status.startDate) {
      const tempAm: { id: number; name: Date }[] = [];
      const tempPm: { id: number; name: Date }[] = [];

      tempAm.push({
        id: 12,
        name: new Date(new Date(status.startDate).setHours(0, 0))
      });
      tempAm.push({
        id: 124,
        name: new Date(new Date(status.startDate).setHours(0, 30))
      });
      for (let i = 1; i < 12; i++) {
        tempAm.push({
          id: i,
          name: new Date(new Date(status.startDate).setHours(i, 0))
        });
        tempAm.push({
          id: i * 12,
          name: new Date(new Date(status.startDate).setHours(i, 30))
        });
      }
      setStartTimeDropDownDataAm(tempAm);

      tempPm.push({
        id: 0,
        name: new Date(new Date(status.startDate).setHours(12, 0))
      });
      tempPm.push({
        id: 123,
        name: new Date(new Date(status.startDate).setHours(12, 30))
      });

      for (let i = 13; i < 24; i++) {
        tempPm.push({
          id: i,
          name: new Date(new Date(status.startDate).setHours(i, 0))
        });
        tempPm.push({
          id: i * 12,
          name: new Date(new Date(status.startDate).setHours(i, 30))
        });
      }
      setStartTimeDropDownDataPm(tempPm);
    }
  }, [status.startDate]);

  useEffect(() => {
    if (status.endDate) {
      const tempAm: { id: number; name: Date }[] = [];
      const tempPm: { id: number; name: Date }[] = [];

      tempAm.push({
        id: 12,
        name: new Date(new Date(status.endDate).setHours(0, 0))
      });
      tempAm.push({
        id: 124,
        name: new Date(new Date(status.endDate).setHours(0, 30))
      });
      for (let i = 1; i < 12; i++) {
        tempAm.push({
          id: i,
          name: new Date(new Date(status.endDate).setHours(i, 0))
        });
        tempAm.push({
          id: i * 12,
          name: new Date(new Date(status.endDate).setHours(i, 30))
        });
      }

      setEndTimeDropDownDataAm(tempAm);

      tempPm.push({
        id: 0,
        name: new Date(new Date(status.endDate).setHours(12, 0))
      });
      tempPm.push({
        id: 123,
        name: new Date(new Date(status.endDate).setHours(12, 30))
      });

      for (let i = 13; i < 24; i++) {
        tempPm.push({
          id: i,
          name: new Date(new Date(status.endDate).setHours(i, 0))
        });
        tempPm.push({
          id: i * 12,
          name: new Date(new Date(status.endDate).setHours(i, 30))
        });
      }
      setEndTimeDropDownDataPm(tempPm);
    }
  }, [status.endDate]);

  const updateStartTime = (selectedTime) => {
    const typeSelectedTime = typeof selectedTime;
    if (typeSelectedTime === 'string') {
      setStatus((status) => ({
        ...status,
        startTime: selectedTime
      }));
    } else {
      setStatus((status) => ({
        ...status,
        startTime: `${moment(new Date(selectedTime)).format('hh:mm')} ${moment(new Date(selectedTime)).format('a').toUpperCase()}`,
        startDate: new Date(selectedTime)
      }));
    }
    setFormChanges(true);
    validateStartTime();
  };

  const updateEndTime = (selectedTime) => {
    const typeSelectedTime = typeof selectedTime;
    if (typeSelectedTime === 'string') {
      setStatus((status) => ({
        ...status,
        endTime: selectedTime
      }));
    } else {
      setStatus((status) => ({
        ...status,
        endTime: `${moment(new Date(selectedTime)).format('hh:mm')} ${moment(new Date(selectedTime)).format('a').toUpperCase()}`,
        endDate: new Date(selectedTime)
      }));
    }
    setFormChanges(true);
  };

  const validateStartTime = () => {
    let formattedStartTime: Date | null = null;
    let startTime: string[];
    let formattedEndTime: Date | null = null;
    let endTime: string[];
    if (!status.isSetEndDate) {
      const currentTime = moment(couponId ? status?.startDate : new Date(), 'hh:mm a');
      if (status.startDate && status.startTime) {
        formattedStartTime = new Date(status.startDate);
        startTime = status.startTime.slice(0, -2).split(':');
        formattedStartTime.setHours(
          // eslint-disable-next-line no-nested-ternary
          status.startTime.includes('PM')
            ? Number(startTime[0]) !== 12
              ? Number(startTime[0]) + 12
              : Number(startTime[0])
            : Number(startTime[0]) === 12
            ? 0
            : Number(startTime[0])
        );
        formattedStartTime.setMinutes(Number(startTime[1]));
      }
      const selectedTime = moment(formattedStartTime);
      if (selectedTime.isSameOrAfter(currentTime)) {
        setCouponTimesError((timesError) => {
          return { ...timesError, isStartTimeError: false, startTimeErrorMessage: '' };
        });
      } else {
        setCouponTimesError((timesError) => {
          return { ...timesError, isStartTimeError: true, startTimeErrorMessage: 'Invalid Time' };
        });
      }
    } else {
      // validateEndTime();
      if (status.startDate && status.endDate) {
        if (moment(new Date(status.startDate)).format('YYYY MM DD') === moment(new Date(status.endDate)).format('YYYY MM DD')) {
          if (status.startTime) {
            formattedStartTime = new Date(status.startDate);
            startTime = status.startTime.slice(0, -2).split(':');
            formattedStartTime.setHours(
              // eslint-disable-next-line no-nested-ternary
              status.startTime.includes('PM')
                ? Number(startTime[0]) !== 12
                  ? Number(startTime[0]) + 12
                  : Number(startTime[0])
                : Number(startTime[0]) === 12
                ? 0
                : Number(startTime[0])
            );
            formattedStartTime.setMinutes(Number(startTime[1]));
          }
          if (status.endTime) {
            formattedEndTime = new Date(status.endDate);
            endTime = status.endTime.slice(0, -2).split(':');
            formattedEndTime.setHours(
              // eslint-disable-next-line no-nested-ternary
              status.endTime.includes('PM')
                ? Number(endTime[0]) !== 12
                  ? Number(endTime[0]) + 12
                  : Number(endTime[0])
                : Number(endTime[0]) === 12
                ? 0
                : Number(endTime[0])
            );
            formattedEndTime.setMinutes(Number(endTime[1]));
          }
          const selectedStartTime = moment(formattedStartTime);
          const selectedEndTime = moment(formattedEndTime);
          if (selectedEndTime.isSameOrAfter(selectedStartTime)) {
            setCouponTimesError((timesError) => {
              return { ...timesError, isStartTimeError: false, startTimeErrorMessage: '' };
            });
          } else {
            setCouponTimesError((timesError) => {
              return { ...timesError, isStartTimeError: true, startTimeErrorMessage: 'Invalid Time' };
            });
          }
        }
      }
    }
  };

  const validateEndTime = () => {
    let formattedStartTime: Date | null = null;
    let startTime: string[];
    let formattedEndTime: Date | null = null;
    let endTime: string[];
    if (status.startDate && status.endDate) {
      if (moment(new Date(status.startDate)).format('YYYY MM DD') === moment(new Date(status.endDate)).format('YYYY MM DD')) {
        if (status.startTime) {
          formattedStartTime = new Date(status.startDate);
          startTime = status.startTime.slice(0, -2).split(':');
          formattedStartTime.setHours(
            // eslint-disable-next-line no-nested-ternary
            status.startTime.includes('PM')
              ? Number(startTime[0]) !== 12
                ? Number(startTime[0]) + 12
                : Number(startTime[0])
              : Number(startTime[0]) === 12
              ? 0
              : Number(startTime[0])
          );
          formattedStartTime.setMinutes(Number(startTime[1]));
        }
        if (status.endTime) {
          formattedEndTime = new Date(status.endDate);
          endTime = status.endTime.slice(0, -2).split(':');
          formattedEndTime.setHours(
            // eslint-disable-next-line no-nested-ternary
            status.endTime.includes('PM')
              ? Number(endTime[0]) !== 12
                ? Number(endTime[0]) + 12
                : Number(endTime[0])
              : Number(endTime[0]) === 12
              ? 0
              : Number(endTime[0])
          );
          formattedEndTime.setMinutes(Number(endTime[1]));
        }
        const selectedStartTime = moment(formattedStartTime);
        const selectedEndTime = moment(formattedEndTime);
        if (selectedEndTime.isSameOrBefore(selectedStartTime)) {
          setCouponTimesError((timesError) => {
            return { ...timesError, isEndTimeError: false, endTimeErrorMessage: '' };
          });
        } else {
          setCouponTimesError((timesError) => {
            return { ...timesError, isEndTimeError: true, endTimeErrorMessage: 'Invalid Time' };
          });
        }
      }
    }
  };

  const handleStartDate = (date) => {
    const endDate = status.endDate;

    if (endDate) {
      if (date.getTime() > endDate.getTime()) {
        setStatus((prev) => ({ ...prev, startDate: endDate }));
      } else {
        setStatus((prev) => ({ ...prev, startDate: date }));
      }
    } else {
      setStatus((prev) => ({ ...prev, startDate: date }));
    }
    setIsStartDateOpen(false);
    setFormChanges(true);
  };

  const handleEndDate = (date) => {
    const startDate = status.startDate;

    if (startDate) {
      if (startDate.getTime() > date.getTime()) {
        setStatus((prev) => ({ ...prev, startDate: date }));
      }
      setStatus((prev) => ({ ...prev, endDate: date }));
    }
    setIsEndDateOpen(false);
    setFormChanges(true);
  };

  return (
    <div className="card w-100">
      <div className=" poppins-regular">
        <div className="section-title m-b-32">{constVariables.Coupons.AddCoupon.status.couponStatus}</div>
        <div className="statusSmallHeading">{constVariables.Coupons.AddCoupon.status.couponStatus}</div>
        <div className="m-t-8 position-relative">
          <OutsideClickHandler onOutsideClick={closeStatusDropdown}>
            <DropdownSelector
              className={`form-control form-control-lg  px-5 justify-content-between align-items-center dropdown-box ${
                isCatalogDropdown ? 'dropdown-box-active' : ''
              } ${selectedValue === 'Scheduled' || selectedValue === 'Expired' ? 'disabled-section text-muted' : 'cursor-pointer'}`}
              onClick={toggleWarehouseDropdown}
              selectedValue={selectedValue}
              text="Select"
            />

            <Dropdown
              className="dropdown-custom-width mt-1"
              data={constVariables.createCouponStatusDropDownData}
              selected={isCatalogDropdown}
              onSelect={selectStatus}
              value={selectedValue}
              closeDropdown={() => setIsCatalogDropdown(false)}
            />
          </OutsideClickHandler>
        </div>
      </div>
      <div className="m-t-32">
        <div className="section-title m-b-32">{constVariables.Coupons.AddCoupon.status.activeDates}</div>
        <div className="statusSmallHeading">{constVariables.Coupons.AddCoupon.status.startDate}</div>
        <div
          className="m-t-8 form-control position-relative"
          style={{
            height: '38px'
          }}
        >
          <div className="d-flex align-items-center cursor-pointer h-100 w-100" onClick={() => setIsStartDateOpen(true)}>
            <Img src={CalendarLightIcon} className="me-2 h-15px w-15px" />
            {status.startDate ? <div className="dateBoxText">{moment(status.startDate).format('L')}</div> : null}
          </div>
          <OutsideClickHandler onOutsideClick={() => setIsStartDateOpen(false)}>
            <DatesDropdownSelector
              onselect={(data) => console.info(data)}
              isOpen={isStartDateOpen}
              noRangePicker={true}
              onselectDate={(date) => {
                handleStartDate(date);
              }}
              defaultDate={status.startDate ? status.startDate : undefined}
              closeDropdown={() => setIsStartDateOpen(false)}
            />
          </OutsideClickHandler>
        </div>
        {/* HERE */}
        <div className="m-t-24 statusSmallHeading">{constVariables.Coupons.AddCoupon.status.startTime}</div>
        <div className="m-t-8">
          <TimePicker
            otherClasses="start-time-class"
            placeholder={constVariables.LiveShow.inputFields.liveShowTimePlaceholder}
            value={status.startTime}
            dateValue={status.startDate}
            isMandatory={false}
            setValue={updateStartTime}
            validateTimes={validateStartTime}
            timeDropDownDataAm={startTimeDropDownDataAm}
            timeDropDownDataPm={startTimeDropDownDataPm}
            isShowTimeSlots={true}
            comparedDate={couponId ? status?.startDate : new Date()}
            error={couponTimesError?.startTimeErrorMessage}
            isError={couponTimesError?.isStartTimeError}
          ></TimePicker>
        </div>
        <Checkbox
          tagclassname="statusSmallHeading ps-4"
          labelclassname="m-t-24"
          name={constVariables.Coupons.AddCoupon.status.setEndDate}
          value={status.isSetEndDate}
          onChangeValue={toggleIsSetEndDate}
          noVerticalPadding
        />
        {status.isSetEndDate && (
          <>
            <div className="m-t-24 statusSmallHeading">{constVariables.Coupons.AddCoupon.status.endDate}</div>
            <div
              className="m-t-8 form-control position-relative"
              style={{
                height: '38px'
              }}
            >
              <div className="d-flex align-items-center cursor-pointer h-100 w-100" onClick={() => setIsEndDateOpen(true)}>
                <Img src={CalendarLightIcon} className="me-2 h-15px w-15px" />
                {status.endDate ? <div className="dateBoxText">{moment(status.endDate).format('L')}</div> : null}
              </div>
              <OutsideClickHandler onOutsideClick={() => setIsEndDateOpen(false)}>
                <DatesDropdownSelector
                  onselect={(data) => console.info(data)}
                  isOpen={isEndDateOpen}
                  noRangePicker={true}
                  onselectDate={(date) => handleEndDate(date)}
                  defaultDate={status.endDate ? status.endDate : new Date()}
                  closeDropdown={() => setIsEndDateOpen(false)}
                />
              </OutsideClickHandler>
            </div>
            <div className="m-t-24 statusSmallHeading">{constVariables.Coupons.AddCoupon.status.endTime}</div>
            <div className="m-t-8">
              <TimePicker
                otherClasses="end-time-class"
                placeholder={constVariables.LiveShow.inputFields.liveShowTimePlaceholder}
                value={status.endTime}
                dateValue={status.endDate}
                isMandatory={false}
                setValue={updateEndTime}
                validateTimes={validateEndTime}
                timeDropDownDataAm={endTimeDropDownDataAm}
                timeDropDownDataPm={endTimeDropDownDataPm}
                isShowTimeSlots={true}
                comparedDate={status.endDate}
                isError={couponTimesError?.isEndTimeError}
                error={couponTimesError?.endTimeErrorMessage}
              ></TimePicker>
            </div>
          </>
        )}
      </div>

      {/* {status.statusValue?.toUpperCase() === 'ACTIVE' ? (
        <>
          <Divider classname="my-10" />
          <div>
            <div className="section-title">{constVariables.Coupons.AddCoupon.status.activeDates}</div>
            <div className="mt-6 statusSmallHeading">{constVariables.Coupons.AddCoupon.status.startDate}</div>
            <div className="mt-2 form-control form-control-lg position-relative">
              <div className="d-flex align-items-center cursor-pointer h-100 w-100" onClick={() => setIsStartDateOpen(true)}>
                <Img src={CalendarLightIcon} className="me-2 h-15px w-15px" />
                {status.startDate ? <div className="dateBoxText">{moment(status.startDate).format('L')}</div> : null}
              </div>
              <OutsideClickHandler onOutsideClick={() => setIsStartDateOpen(false)}>
                <DatesDropdownSelector
                  onselect={(data) => console.info(data)}
                  isOpen={isStartDateOpen}
                  noRangePicker={true}
                  onselectDate={(date) => {
                    setIsStartDateOpen(false);
                    setStatus((prev) => ({ ...prev, startDate: date }));
                  }}
                  defaultDate={status.startDate ? status.startDate : undefined}
                />
              </OutsideClickHandler>
            </div>            
            <div className="mt-6 statusSmallHeading">{constVariables.Coupons.AddCoupon.status.startTime}</div>
            <div className="mt-3">
              <TimePicker
                otherClasses="start-time-class"
                placeholder={constVariables.LiveShow.inputFields.liveShowTimePlaceholder}
                value={status.startTime}
                dateValue={status.startDate}
                isMandatory={false}
                setValue={updateStartTime}
                validateTimes={validateTimes}
                timeDropDownDataAm={startTimeDropDownDataAm}
                timeDropDownDataPm={startTimeDropDownDataPm}
                isShowTimeSlots={true}
                comparedDate={new Date()}
              ></TimePicker>
            </div>
            <Checkbox
              tagclassname="statusSmallHeading ps-4"
              labelclassname="mt-6"
              name={constVariables.Coupons.AddCoupon.status.setEndDate}
              value={status.isSetEndDate}
              onChangeValue={toggleIsSetEndDate}
            />
            {status.isSetEndDate && (
              <>
                <div className="mt-6 statusSmallHeading">{constVariables.Coupons.AddCoupon.status.endDate}</div>
                <div className="mt-2 form-control form-control-lg position-relative">
                  <div className="d-flex align-items-center cursor-pointer h-100 w-100" onClick={() => setIsEndDateOpen(true)}>
                    <Img src={CalendarLightIcon} className="me-2 h-15px w-15px" />
                    {status.endDate ? <div className="dateBoxText">{moment(status.endDate).format('L')}</div> : null}
                  </div>
                  <OutsideClickHandler onOutsideClick={() => setIsEndDateOpen(false)}>
                    <DatesDropdownSelector
                      onselect={(data) => console.info(data)}
                      isOpen={isEndDateOpen}
                      noRangePicker={true}
                      onselectDate={(date) => handleEndDate(date)}                      
                      defaultDate={status.endDate ? status.endDate : new Date()}
                    />
                  </OutsideClickHandler>
                </div>
                <div className="mt-6 statusSmallHeading">{constVariables.Coupons.AddCoupon.status.endTime}</div>
                <div className="mt-3">
                  <TimePicker
                    otherClasses="end-time-class"
                    placeholder={constVariables.LiveShow.inputFields.liveShowTimePlaceholder}
                    value={status.endTime}
                    dateValue={status.endDate}
                    isMandatory={false}
                    setValue={updateEndTime}
                    validateTimes={validateTimes}
                    timeDropDownDataAm={endTimeDropDownDataAm}
                    timeDropDownDataPm={endTimeDropDownDataPm}
                    isShowTimeSlots={true}
                    comparedDate={status.endDate}                    
                  ></TimePicker>
                </div>
              </>
            )}
          </div>
        </>
      ) : null} */}
    </div>
  );
};

export default StatusCreateCoupon;
