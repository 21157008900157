import { gql } from '@apollo/client';

export const GET_WAITLIST = gql`
  query GetProductsInWaitlist($input: GetProductsInWaitlist!) @api(name: "ordersAPI") {
    getProductsInWaitlist(input: $input) {
      total
      products {
        id
        name
        waitlistQuantity
        waitlistAuthQuantity
        mediaUrl
        price
      }
    }
  }
`;
