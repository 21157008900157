/* eslint-disable no-nested-ternary */
import { FC, useEffect, useState, useReducer } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Apollo
import { useLazyQuery, useMutation } from '@apollo/client';
import { DELETE_COLLECTION, GET_COLLECTIONS, GET_COLLECTION_NAMES, GET_COLLECTION_CONDITIONS } from 'src/apollo/queries';
import { CREATE_COLLECTION, EDIT_COLLECTION, EDIT_COLLECTION_RULES } from 'src/apollo/mutations';

// Components
import { FlexBox, Loader } from 'src/components/atoms';
import { SaveHeader, CustomModal, DeleteConfirmModal } from 'src/components/oraganisms';
import CreateCollectionPresentational from './CreateCollection.presentational';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';
import { validateForm } from 'src/utils/validate';

// Icons
import { BackRoundedIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';

// Types
import { GeneralInfo } from 'src/components/templates/GeneralInfoSection/GeneralInfoSection.types';
import { Organization } from 'src/components/templates/OrganizationSection/OrganizationSection.types';
import { Channels, DefaultChannels } from 'src/components/templates/StatusSection/StatusSection.types';
import { Modals } from '../../products/AddProduct/AddProduct.types';
import { CollectionStatus, CreateCollectionFormErrors, productAssignmentMethod } from './CreateCollection.types';
import { ConditionType, FieldConditionType } from './CollectionManagement.types';
import { RulesEngineProductListType } from 'src/components/molecules/RulesEngineProducts/RulesEngineProducts.types';
import { emitCustomEvent } from 'react-custom-events';

const CreateCollection: FC = () => {
  const history = useHistory();
  const { showToast } = useToast();

  const { collectionId } = useParams<{ collectionId: string }>();
  const [formChanges, setFormChanges] = useState<boolean>(false);
  const [rulesChanges, setRulesChanges] = useState<boolean>(false);
  const [colApiCall, setColApiCall] = useState(false);
  const [thumbnailUrlData, setThumbnailUrlData] = useState<any>();
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [modal, setModal] = useState<Modals>({
    discardModal: false,
    leaveModal: false,
    saveModal: false
  });
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [targettedPath, setTargettedPath] = useState<string>();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [productListData, setProductListData] = useState<RulesEngineProductListType>({
    autoMaticProductListIds: [],
    manualProductListIds: [],
    selectedProductsIds: []
  });
  const [collectionNames, setCollectionNames] = useState<string[]>([]);

  const [editCollection] = useMutation(EDIT_COLLECTION, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setFormChanges(false);
      setRulesChanges(false);
      setDisableSaveButton(false);
      showToast({
        successText: 'Collection saved successfully',
        message: `The Collection ${generalInfo.value} has been edited sucessfully`
      });
    },
    onError: (e) => {
      setDisableSaveButton(false);
      const error: any = e?.graphQLErrors[0];

      if (error?.errorType === 'NAME_ALREADY_EXIST') {
        setErrorMessages((prev) => ({
          ...prev,
          generalInfoTitleError: 'This collection already exists'
        }));
      }
    }
  });

  const [editCollectionRules] = useMutation(EDIT_COLLECTION_RULES, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response?.updtCollPrdAsignmntRules?.additionalData) {
        const filteredProductIds = JSON.parse(response?.updtCollPrdAsignmntRules?.additionalData)?.map((productId) =>
          parseInt(productId, 10)
        );
        setProductListData((productListData) => {
          return { ...productListData, autoMaticProductListIds: filteredProductIds };
        });
        setFormChanges(true);
        setRulesChanges(false);
      }
      emitCustomEvent('endSaveModuleRules');
      // showToast({
      //   successText: 'Collection Rules saved successfully',
      //   message: `The Collection ${generalInfo.value} has been edited sucessfully`
      // });
    },
    onError: (e) => {
      const error: any = e?.graphQLErrors[0];
      console.log('editCollectionRules error - ', error);
      emitCustomEvent('endSaveModuleRules');
    }
  });

  const [createCollection] = useMutation(CREATE_COLLECTION, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      setFormChanges(false);
      setRulesChanges(false);
      setDisableSaveButton(false);
      showToast({
        successText: 'Collection added successfully',
        message: `The Collection ${generalInfo.value} has been added sucessfully`
      });

      setTimeout(() => {
        history.push(ROUTES.products.productCategory.collections.allCollections);
      }, 1000);
    },
    onError: (error) => {
      setDisableSaveButton(false);
      console.log('error', error);
    }
  });

  // Rules engine specific
  const [fieldDropdown, setFieldDropdown] = useState<FieldConditionType[]>([]);
  const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), {
    selectedProductAssignmentMethod: productAssignmentMethod.MANUAL
  });
  const [allConditionMatch, setAllConditionMatch] = useState<boolean>(false);
  const [rulesEngineConditions, setRulesEngineConditions] = useState<ConditionType[]>([
    {
      field: {
        id: 0,
        name: ''
      },
      condition: '',
      category: '',
      errorMessage: '',
      openFieldDropdown: false,
      openConditionDropdown: false,
      openCategoryDropdown: false
    }
  ]);

  const [conditionDropdownArray, setConditionDropdownArray] = useState<
    {
      categoryDropdown: {
        name: string;
        id: number;
      }[];
    }[]
  >([
    {
      categoryDropdown: []
    }
  ]);

  // Rules engine specific

  const [getCollections, { data: getCollectionData, refetch }] = useLazyQuery(GET_COLLECTIONS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      if (res?.getCollections?.collections?.length > 0) {
        const allData = res?.getCollections?.collections[0];
        document.title = `Collections - ${allData?.name ? allData?.name : ''}`;
        if (allData?.productAssignmentRulesLayout) {
          const productAssignmentRulesLayout = JSON.parse(allData?.productAssignmentRulesLayout);
          const arr = [] as any;
          if (productAssignmentRulesLayout.length > 0) {
            setRulesEngineConditions(productAssignmentRulesLayout);
            productAssignmentRulesLayout.forEach((element, index) => {
              const obj = Object.assign({}, arr[index]) as any;
              obj.categoryDropdown = element.categoryOptions;
              arr[index] = obj as any;
              setConditionDropdownArray([...arr]);
            });
          }
        }

        if (allData?.productIds) {
          if (allData?.type === productAssignmentMethod.AUTOMATIC) {
            setProductListData((productlistData) => {
              return {
                ...productlistData,
                autoMaticProductListIds: allData?.productIds?.length > 0 ? allData?.productIds : []
              };
            });
          } else {
            setProductListData((productlistData) => {
              return {
                ...productlistData,
                manualProductListIds: allData?.productIds?.length > 0 ? allData?.productIds : []
              };
            });
          }
        }

        setState({
          selectedProductAssignmentMethod: allData?.type,
          formChanges: false
        });

        setSelectedOrganization(allData?.name || '');

        setThumbnailUrlData({ url: allData?.thumbnailUrl });
        setCollectionStatus(allData?.status.toLowerCase().charAt(0).toUpperCase() + allData?.status.toLowerCase().slice(1));

        setGeneralInfo({
          ...generalInfo,
          value: allData?.name || '',
          description: allData?.description || '',
          externalProvider: allData?.externalCollectionProvider ? allData?.externalCollectionProvider : undefined,
          externalId: allData?.externalCollectionId ? allData?.externalCollectionId : undefined
        });

        setIsPageLoading(false);
      }
    }
  });

  const [deleteCollection] = useMutation(DELETE_COLLECTION, {
    onCompleted: () => {
      history.push(ROUTES.products.productCategory.collections.allCollections);
    }
  });
  const delCol = () => {
    setShowConfirmModal(true);
  };

  const handleDeleteCollection = () => {
    deleteCollection({
      variables: {
        id: collectionId
      }
    });
  };

  const [getCollectionNames] = useLazyQuery(GET_COLLECTION_NAMES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data) {
        if (data?.getCollectionNames?.names) {
          setCollectionNames(data?.getCollectionNames?.names);
        }
      }
    }
  });

  const [callCollectionConditions, { data: collectionConditionData }] = useLazyQuery(GET_COLLECTION_CONDITIONS, {
    onCompleted: (res) => {
      // console.log('response', res);
    }
  });

  useEffect(() => {
    if (collectionId) {
      setIsPageLoading(true);
      getCollections({
        variables: {
          input: {
            ids: [parseInt(collectionId, 10)],
            setDroppableByStatus: false
          }
        }
      });
    } else {
      document.title = 'Collections - New';
    }
    getCollectionNames();
  }, []);

  const statusInfo = {
    title: constVariables.ProductCategoryMenu.collections.statusKey,
    placeholder: constVariables.ProductCategoryMenu.collections.statusKey,
    helperText: constVariables.ProductCategoryMenu.collections.statusHelperText
  };

  const [channels, setChannels] = useState<Channels>(DefaultChannels);
  const [collectionStatus, setCollectionStatus] = useState<string>('Active');

  // general Info
  const [generalInfo, setGeneralInfo] = useState<GeneralInfo>({
    value: '',
    description: '',
    title: constVariables.ProductCategoryMenu.collections.collectionName,
    placeHolder: constVariables.ProductCategoryMenu.collections.collectionName,
    helperText: constVariables.ProductCategoryMenu.collections.helperText,
    helperTextDescription: constVariables.ProductCategoryMenu.collections.helperTextdescription,
    name: 'collectionname'
  });

  const [errorMessages, setErrorMessages] = useState<CreateCollectionFormErrors>({
    generalInfoTitleError: ''
  });

  // origanization collections
  const [organization, setOrganization] = useState<Organization>({
    sensitiveProduct: false,
    collectionList: ['Bath', 'Kitchen']
  });

  // Screen back button handler
  const backbtnHandler = () => {
    if (formChanges) {
      setModal((prev) => ({ ...prev, leaveModal: true }));
    } else {
      history.goBack();
    }
  };

  // modal discard handler
  const onDiscardHandler = (state: string) => {
    if (state === 'discard') {
      setModal((prev) => ({ ...prev, discardModal: !modal.discardModal }));
      history.replace(ROUTES.products.productCategory.collections.allCollections);
    }
    if (state === 'saveError') {
      setModal((prev) => ({ ...prev, saveModal: !modal.saveModal }));
    }
    if (state === 'leave') {
      setModal((prev) => ({ ...prev, leaveModal: !modal.leaveModal }));
    }
    if (state === 'close') {
      setModal({
        discardModal: false,
        leaveModal: false,
        saveModal: false
      });
    }
  };

  const leavePageHandler = () => {
    setFormChanges(false);
    // NOTE: Push to targetted path or products, handled with timeout for now.
    setTimeout(() => {
      if (targettedPath) {
        history.push(targettedPath);
      } else {
        history.goBack();
      }
    }, 100);
  };

  // on edit of form, show save header
  useEffect(() => {
    if (validateForm(generalInfo.value) && colApiCall) {
      setFormChanges(true);
    } else {
      setFormChanges(false);
    }
  }, [generalInfo.value, generalInfo.description, collectionStatus, organization]);

  useEffect(() => {
    if (generalInfo.value.length > 0) {
      setErrorMessages((prev) => ({
        ...prev,
        generalInfoTitleError: ''
      }));
    }
  }, [generalInfo.value]);

  useEffect(() => {
    const preventRoute = history.block((e: any) => {
      setTargettedPath(e.pathname);
      if (formChanges) {
        setModal((prev) => ({ ...prev, leaveModal: true }));
        return false;
      } else {
        return true;
      }
    });

    return () => {
      preventRoute();
    };
  }, [history, formChanges]);

  // Rules engine
  useEffect(() => {
    if (collectionConditionData?.getCollectionConditions) {
      const arr: { id: number; name: string; operations: string[] }[] = Array.from(
        collectionConditionData?.getCollectionConditions
      );
      const fieldDropdownArr: FieldConditionType[] = [];
      arr?.map((condition: { id: number; name: string; operations: string[] }, index: number) => {
        const operationArr: { name: string }[] = [];
        const obj: FieldConditionType = {
          id: 0,
          name: '',
          operations: [
            {
              name: ''
            }
          ]
        };
        condition?.operations?.map((operation, i) => {
          operationArr.push({ name: operation?.replace(/_/g, ' ').toLowerCase() });
          return null;
        });
        obj.id = condition.id;
        obj.name = condition.name;
        obj.operations = operationArr;
        fieldDropdownArr.push(obj);
        return null;
      });
      setFieldDropdown(fieldDropdownArr);
    }
  }, [collectionConditionData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    callCollectionConditions();
  }, []);

  const getCollectionStatus = (givenStatus: string): CollectionStatus => {
    switch (givenStatus) {
      case 'Active':
        return CollectionStatus.ACTIVE;
      case 'Inactive':
        return CollectionStatus.INACTIVE;
      case 'Scheduled':
        return CollectionStatus.SCHEDULED;
      default:
        return CollectionStatus.ACTIVE;
    }
  };

  // Save Collection

  const saveCollection = () => {
    let isError = false;
    const productAssignmentRules = [] as any;

    if (state.selectedProductAssignmentMethod === productAssignmentMethod.AUTOMATIC) {
      rulesEngineConditions.forEach((element) => {
        const ruleObject = {} as any;
        switch (element.field.name) {
          case 'Product name':
            ruleObject.fact = 'productName';
            break;
          case 'Product type':
            ruleObject.fact = 'productType';
            break;
          case 'Vendor Name':
            ruleObject.fact = 'vendorName';
            break;
          case 'Product tag':
            ruleObject.fact = 'productTag';
            break;
          case 'Product price':
            ruleObject.fact = 'productPrice';
            break;
          case 'Product cost':
            ruleObject.fact = 'productCost';
            break;
          case 'Product stock':
            ruleObject.fact = 'inventoryStock';
            break;
          case 'Collection Name':
            ruleObject.fact = 'collection';
            break;
        }
        switch (element.condition) {
          case 'is equal to':
            ruleObject.operator = 'equal';
            break;
          case 'is not equal to':
            ruleObject.operator = 'notEqual';
            break;
          case 'is greater than':
            ruleObject.operator = 'greaterThan';
            break;
          case 'is less then':
            ruleObject.operator = 'lessThan';
            break;
          case 'contains':
            ruleObject.operator = 'stringContains';
            break;
          case 'does not contains':
            ruleObject.operator = 'stringNotContains';
            break;
        }
        ruleObject.value = element.category;

        if (typeof element.category == 'string' && element.category.trim().length === 0) {
          isError = true;
          element.errorMessage = `Field can't be empty`;
        } else {
          element.errorMessage = ``;
        }
        productAssignmentRules.push(ruleObject);
      });

      if (!rulesEngineConditions.length) {
        isError = true;
        showToast({
          errorText: 'Error',
          message: `Product condition not specified`
        });
      }
    }

    if (isError) {
      setFormChanges(false);
      setRulesChanges(false);
    }

    if (!isError) {
      if (collectionId) {
        setDisableSaveButton(true);
        editCollection({
          variables: {
            input: {
              id: parseInt(collectionId, 10),
              name: generalInfo.value,
              description: generalInfo.description,
              status: getCollectionStatus(collectionStatus),
              type: state.selectedProductAssignmentMethod,
              parentId: getCollectionData?.getCollections?.collections[0]?.parent,
              thumbnailUrl: thumbnailUrlData.url,
              productIds:
                state.selectedProductAssignmentMethod !== 'MANUAL'
                  ? productListData.autoMaticProductListIds?.length > 0
                    ? productListData.autoMaticProductListIds
                    : undefined
                  : productListData.manualProductListIds?.length > 0
                  ? productListData.manualProductListIds
                  : undefined,
              rulesMatch: allConditionMatch ? 'ALL' : 'ANY',
              productAssignmentRules:
                state.selectedProductAssignmentMethod !== 'MANUAL' ? JSON.stringify(productAssignmentRules) : JSON.stringify([]),
              productAssignmentRulesLayout:
                state.selectedProductAssignmentMethod !== 'MANUAL' ? JSON.stringify(rulesEngineConditions) : JSON.stringify([])
            }
          }
        });
      } else {
        if (collectionNames?.includes(generalInfo?.value)) {
          setErrorMessages((prev) => ({
            ...prev,
            generalInfoTitleError: 'This collection already exists'
          }));
        } else {
          if (generalInfo.value === '') {
            setErrorMessages((prev) => ({
              ...prev,
              generalInfoTitleError: `Collection name can't be left empty, please enter a name.`
            }));
          } else {
            setDisableSaveButton(true);
            createCollection({
              variables: {
                input: {
                  name: generalInfo.value,
                  description: generalInfo.description,
                  status: getCollectionStatus(collectionStatus),
                  type: state.selectedProductAssignmentMethod,
                  parentId: null,
                  productIds:
                    state.selectedProductAssignmentMethod !== 'MANUAL'
                      ? productListData.autoMaticProductListIds?.length > 0
                        ? productListData.autoMaticProductListIds
                        : undefined
                      : productListData.manualProductListIds?.length > 0
                      ? productListData.manualProductListIds
                      : undefined,
                  thumbnailUrl: thumbnailUrlData && thumbnailUrlData.url ? thumbnailUrlData.url : null,
                  rulesMatch: allConditionMatch ? 'ALL' : 'ANY',
                  productAssignmentRules:
                    state.selectedProductAssignmentMethod !== 'MANUAL'
                      ? JSON.stringify(productAssignmentRules)
                      : JSON.stringify([]),
                  productAssignmentRulesLayout:
                    state.selectedProductAssignmentMethod !== 'MANUAL' ? JSON.stringify(rulesEngineConditions) : JSON.stringify([])
                },
                state: Math.random()
              }
            });
          }
        }
      }
    }
  };

  // save rules

  const saveModuleRules = () => {
    let isError = false;
    const productAssignmentRules = [] as any;

    if (state.selectedProductAssignmentMethod === productAssignmentMethod.AUTOMATIC) {
      rulesEngineConditions.forEach((element) => {
        const ruleObject = {} as any;
        switch (element.field.name) {
          case 'Product name':
            ruleObject.fact = 'productName';
            break;
          case 'Product type':
            ruleObject.fact = 'productType';
            break;
          case 'Vendor Name':
            ruleObject.fact = 'vendorName';
            break;
          case 'Product tag':
            ruleObject.fact = 'productTag';
            break;
          case 'Product price':
            ruleObject.fact = 'productPrice';
            break;
          case 'Product cost':
            ruleObject.fact = 'productCost';
            break;
          case 'Product stock':
            ruleObject.fact = 'inventoryStock';
            break;
          case 'Collection Name':
            ruleObject.fact = 'collection';
            break;
        }
        switch (element.condition) {
          case 'is equal to':
            ruleObject.operator = 'equal';
            break;
          case 'is not equal to':
            ruleObject.operator = 'notEqual';
            break;
          case 'is greater than':
            ruleObject.operator = 'greaterThan';
            break;
          case 'is less then':
            ruleObject.operator = 'lessThan';
            break;
          case 'contains':
            ruleObject.operator = 'stringContains';
            break;
          case 'does not contains':
            ruleObject.operator = 'stringNotContains';
            break;
        }
        ruleObject.value = element.category;

        if (typeof element.category == 'string' && element.category.trim().length === 0) {
          isError = true;
          element.errorMessage = `Field can't be empty`;
        } else {
          element.errorMessage = ``;
        }
        productAssignmentRules.push(ruleObject);
      });

      if (!rulesEngineConditions.length) {
        isError = true;
        showToast({
          errorText: 'Error',
          message: `Product condition not specified`
        });
      }
    }

    if (isError) {
      setFormChanges(false);
      setRulesChanges(false);
      emitCustomEvent('endSaveModuleRules');
    }

    if (!isError) {
      editCollectionRules({
        variables: {
          input: {
            // id: collectionId ? parseInt(collectionId, 10) : null,
            id: null,
            type: state.selectedProductAssignmentMethod,
            rulesMatch: allConditionMatch ? 'ALL' : 'ANY',
            productAssignmentRules: JSON.stringify(productAssignmentRules),
            productAssignmentRulesLayout: JSON.stringify(rulesEngineConditions)
          }
        }
      });
    }
  };

  const updateChannelValues = (data) => {
    let tempChannels = channels;

    if (data.key === constVariables.common.channelList.channel2Key) {
      tempChannels = { ...channels, mobile: { ...channels.mobile, value: !channels.mobile.value } };
    }

    /* Don't remove this */
    // if (data.key === constVariables.common.channelList.channel1Key) {
    //   tempChannels = { ...channels, online: { ...channels.online, value: !channels.online.value } };
    // } else if (data.key === constVariables.common.channelList.channel2Key) {
    //   tempChannels = { ...channels, mobile: { ...channels.mobile, value: !channels.mobile.value } };
    // } else if (data.key === constVariables.common.channelList.channel3Key) {
    //   tempChannels = { ...channels, facebook: { ...channels.facebook, value: !channels.facebook.value } };
    // }

    setChannels(tempChannels);
  };

  const selectProductAssignmentMethod = (productAssignmentMethod) => {
    setState({
      selectedProductAssignmentMethod: productAssignmentMethod
    });
  };

  const closeConfirmDeleteModal = () => {
    document.body.style.overflow = 'unset';
    setShowConfirmModal(false);
  };

  return (
    <>
      {formChanges && (
        <SaveHeader
          saveDisabled={disableSaveButton}
          onSave={saveCollection}
          onDiscard={() => {
            setModal((prev) => ({
              ...prev,
              discardModal: !modal.discardModal
            }));
          }}
        />
      )}
      <div className="details-page">
        <div className={`details-page-container ${formChanges ? 'pt-0' : ''}`}>
          <div className="w-100 hide-scrollbar">
            <FlexBox className={`align-items-center justify-content-between ${formChanges ? 'p-t-24 p-b-40' : 'p-y-40'}`}>
              <FlexBox className="align-items-center">
                <img src={BackRoundedIcon} alt={BackRoundedIcon} className="cursor-pointer" onClick={backbtnHandler} />
                <h2 className="page-title m-0 px-4">
                  {collectionId
                    ? constVariables.ProductCategoryMenu.collections.editCollection
                    : constVariables.ProductCategoryMenu.collections.createCollection}
                </h2>
              </FlexBox>
              {collectionId && (
                <FlexBox className="align-items-center">
                  {/* <button className="btn btn-sm btn-outlined-primary">{constVariables.collections.archiveCollection}</button> */}
                  <button className="btn btn-danger btn-sm ms-3 me-0" onClick={delCol}>
                    {constVariables.collections.deleteCollection}
                  </button>
                </FlexBox>
              )}
            </FlexBox>
          </div>
          <div className="width-animation w-100">
            {isPageLoading ? (
              <Loader type="page" className="placeholder-content card" />
            ) : (
              <CreateCollectionPresentational
                generalInfo={generalInfo}
                setGeneralInfo={setGeneralInfo}
                formBackBtnHandler={backbtnHandler}
                errorMessages={errorMessages}
                modal={modal}
                formChanges={formChanges}
                statusInfo={statusInfo}
                channels={channels}
                organization={organization}
                thumbnailUrlData={thumbnailUrlData}
                setThumbnailUrlData={setThumbnailUrlData}
                updateChannelValues={updateChannelValues}
                status={collectionStatus}
                setStatus={setCollectionStatus}
                onDiscard={onDiscardHandler}
                leavePageHandler={leavePageHandler}
                setModal={setModal}
                saveCollection={saveCollection}
                setOrganization={setOrganization}
                selectedOrganization={selectedOrganization}
                // Rules engine
                selectedProductAssignmentMethod={state.selectedProductAssignmentMethod}
                selectProductAssignmentMethod={selectProductAssignmentMethod}
                rulesEngineConditions={rulesEngineConditions}
                setRulesEngineConditions={setRulesEngineConditions}
                fieldDropdown={fieldDropdown}
                setAllConditionMatch={setAllConditionMatch}
                allConditionMatch={allConditionMatch}
                conditionDropdownArray={conditionDropdownArray}
                setConditionDropdownArray={setConditionDropdownArray}
                setFormChanges={setFormChanges}
                setRulesChanges={setRulesChanges}
                rulesChanges={rulesChanges}
                saveModuleRules={saveModuleRules}
                setColApiCall={setColApiCall}
                productListData={productListData}
                setProductListData={setProductListData}
                // Rules engine
              />
            )}
          </div>
        </div>
      </div>
      {showConfirmModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showConfirmModal} closeModal={closeConfirmDeleteModal}>
          <DeleteConfirmModal
            title={constVariables.collections.deleteConfirmTitle}
            actionBtnTitle="Yes, Delete"
            cancelBtnTitle="Cancel"
            message={constVariables.collections.deleteConfirmMessage}
            cancelBtnHandler={closeConfirmDeleteModal}
            actionBtnHandler={handleDeleteCollection}
          ></DeleteConfirmModal>
        </CustomModal>
      )}
    </>
  );
};

export default CreateCollection;
