import { KeyboardEvent, useEffect, useState, useRef, FC, createRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { FlexBox } from 'src/components/atoms';
import { Dropdown, TimeDropdown } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { TimePickerProps } from './TimePicker.types';
import { TimeZoneFormat } from './_pickerData';

// Styles
import './_timePicker.scss';
import moment from 'moment/moment';

const TimePicker: FC<TimePickerProps> = ({
  title,
  titleClass,
  otherClasses,
  value,
  placeholder,
  isDisabled,
  isMandatory,
  isError,
  dateValue,
  error,
  isInvalidValue,
  setValue,
  validateTimes,
  timeDropDownDataAm,
  timeDropDownDataPm,
  isShowTimeSlots,
  comparedDate
}) => {
  // State Params
  const formatRef = createRef<HTMLDivElement>();
  const inputRef = createRef<any>();
  const [showInput, setShowInput] = useState<boolean>(false);
  const [option, setOptions] = useState<boolean>(false);
  const [timeValue, setTimeValue] = useState<string>(value && value.length > 2 ? value.slice(0, value.length - 2) : '');
  const [errorMessage, setErrorMessage] = useState<string>(error ? error : '');
  const [defaultValue, setDefaultValue] = useState<string>(
    value && value.length > 2 ? value.slice(value.length - 2, value.length) : constVariables.LiveShow.liveShowTimeFormat.AM
  );
  const [showTimeSlotsDropdown, setShowTimeSlotsDropdown] = useState(false);
  const stateRef: any = useRef();
  stateRef.current = defaultValue;

  useEffect(() => {
    if (value && value.length > 2) {
      setTimeValue(value.slice(0, value.length - 2));
      setDefaultValue(value.slice(value.length - 2, value.length));
    }
    if (value === '') {
      setTimeValue('');
      setDefaultValue(constVariables.LiveShow.liveShowTimeFormat.AM);
      setErrorMessage('');
    }
  }, [value]);
  useEffect(() => {
    if (value && value !== '') {
      validateTimeValue();
    }
  }, [dateValue]);
  useEffect(() => {
    setErrorMessage(error || '');
  }, [error, isError]);

  // Event Handlers
  const enterKeyDownHandler = (e: any) => {
    if (e.key === 'Enter') {
      formatRef?.current?.click();
    }
  };
  const selectStatus = (e: any) => {
    setOptions(!option);
    setDefaultValue(e.name);
    setValue && setValue(timeValue + e.name);
  };
  const restrictChars = (e: KeyboardEvent<HTMLElement>) => {
    const shouldBlockKeyboard = () => {
      if (e.shiftKey && e.key === ':') return false;
      if (e.key !== 'Backspace' && !(+e.key >= 0 && +e.key <= 9)) return true;

      const time = value?.replace('AM', '').replace('PM', '');

      if (time?.length === 0 && +e.key !== 0 && +e.key !== 1) return true;
      if (time?.length === 1 && time === '1' && +e.key > 2) return true;
      if (time?.length === 1 && time === '0' && +e.key === 0) return true;
      if (time?.length === 1 && time === '0' && +e.key === 0) return true;
      if (time?.length === 3 && +e.key > 5) return true;
      return false;
    };

    if (e.key !== 'Tab') {
      if (shouldBlockKeyboard()) {
        e.preventDefault();
        return false;
      }
    } else if (e.key === 'Tab') {
      inputRef.current.blur();
      formatRef?.current?.focus();
      formatRef?.current?.click();
      e.preventDefault();
    }
  };
  const filterTime = (e) => {
    let timeInput = e.target.value;
    timeInput = timeInput.replace(':', '').slice(0, 4);

    let updatedValue = timeInput;
    // if (!dateValue || (dateValue && dateValue === new Date())) {
    if (e.nativeEvent.data === null) {
      if (timeInput.charAt(timeInput.length) === ':') {
        timeInput = timeInput.replace(':', '');
      }
      if (timeInput.length >= 3) {
        const newValue = `${timeInput.slice(0, 2)}:${timeInput.slice(2, timeInput.length)}`;
        updatedValue = newValue;
      }
    } else {
      let newValue = '';
      if (timeInput.length === 2) {
        newValue = `${timeInput.slice(0, 2)}:`;
        updatedValue = newValue;
      } else if (timeInput.length > 2) {
        newValue = `${timeInput.slice(0, 2)}:${timeInput.slice(2, timeInput.length)}`;
        updatedValue = newValue;
      } else {
        updatedValue = timeInput;
      }
      if (newValue.length > 3) {
        const newArr = newValue.split(':');
        if (Number(newArr[0]) > 12) {
          newArr[0] = '12';
        }
        if (Number(newArr[1]) > 59) {
          newArr[1] = '00';
        }
        updatedValue = `${newArr[0]}:${newArr[1]}`;
      }
    }
    setTimeValue(updatedValue);
    setValue && setValue(updatedValue + stateRef.current);
  };
  const validateTimeValue = () => {
    if (timeValue !== '' && comparedDate) {
      const timeArr = timeValue.split(':');
      const hrs = timeArr[0] || undefined;
      const min = timeArr[1] || undefined;
      if (!min || min.length <= 1 || !hrs || hrs.length <= 1 || +hrs[0] > 1 || (+hrs[0] === 1 && +hrs[1] > 2) || +min[0] > 5) {
        setErrorMessage('Wrong format');
      } else {
        if (dateValue && new Date(dateValue)?.getDate() === new Date().getDate()) {
          const dateTime = new Date(dateValue ? dateValue : new Date());
          const timeArr: any = timeValue.split(':');
          timeArr[0] = timeArr[0] === '12' ? Number(0) : Number(timeArr[0]);
          timeArr[0] = stateRef.current === 'PM' ? Number(timeArr[0] + 12) : Number(timeArr[0]);

          dateTime.setHours(timeArr[0]);
          dateTime.setMinutes(Number(timeArr[1]));

          if (dateTime.getHours() < new Date(comparedDate).getHours()) {
            setErrorMessage('Invalid Time');
          } else if (
            dateTime.getHours() === new Date(comparedDate).getHours() &&
            dateTime.getMinutes() < new Date(comparedDate).getMinutes()
          ) {
            setErrorMessage('Invalid Time');
          } else {
            setErrorMessage('');
          }
        } else {
          setErrorMessage('');
        }
      }
    }
    validateTimes && validateTimes();
  };

  const handleClickTimeView = () => {
    setShowInput(true);
    if (isShowTimeSlots) setShowTimeSlotsDropdown(true);
  };

  const handleTimeOption = (option) => {
    const timeString = moment(option?.name).format('hh:mm');
    setValue && setValue(timeString + stateRef.current);
    setTimeValue(timeString);
    setShowTimeSlotsDropdown(false);
  };

  useEffect(() => {
    if (value) {
      validateTimeValue();
    }
  }, [value]);

  return (
    <div className={`${otherClasses} time-picker w-100`}>
      <div className="d-flex">
        {title ? (
          <div className={`poppins-semibold fs-6 mb-3 ${titleClass}`}>
            {title}
            {isMandatory ? <span className="text-danger fs-4 px-1">*</span> : null}
          </div>
        ) : null}
      </div>
      <FlexBox className="time-picker-container justify-content-between">
        <div
          className={`position-relative col form-control form-control-lg p-0 w-100 timeAMPM ${
            isShowTimeSlots ? 'cursor-pointer' : ''
          } ${isShowTimeSlots && showTimeSlotsDropdown ? 'dropdown-box-active' : ''} ${
            isError || errorMessage !== '' ? 'form-control-error' : ''
          }`}
        >
          <div onClick={handleClickTimeView} className="position-absolute w-100 h-100 px-4">
            {showInput ? (
              <input
                ref={inputRef}
                autoComplete="off"
                className="w-100 h-100 border-0"
                type="text"
                placeholder={placeholder}
                value={timeValue}
                disabled={isDisabled}
                onKeyDown={restrictChars}
                onChange={filterTime}
                // onBlur={validateTimeValue}
                maxLength={5}
                autoFocus
              />
            ) : (
              <FlexBox className="w-100 h-100 time-value-view align-items-center">
                {timeValue ? timeValue : <span className="placeholder-text">HH:MM</span>}
              </FlexBox>
            )}
          </div>
          <OutsideClickHandler onOutsideClick={() => setShowTimeSlotsDropdown(false)}>
            <TimeDropdown
              className="mt-1 full-width-dropdown"
              data={value?.includes('AM') ? timeDropDownDataAm : timeDropDownDataPm}
              selected={isShowTimeSlots && showTimeSlotsDropdown}
              onSelect={handleTimeOption}
              value={timeValue}
              comparedTime={new Date()}
              closeDropdown={() => setShowTimeSlotsDropdown(false)}
            />
          </OutsideClickHandler>
        </div>

        <div className="position-relative">
          <div
            className={`d-flex form-control form-control-lg h-100 px-4 justify-content-between align-items-center cursor-pointer dropdown-box ${
              option ? 'dropdown-box-active' : ''
            }  ${isError || errorMessage !== '' ? 'form-control-error' : ''}`}
            ref={formatRef}
            onKeyDown={enterKeyDownHandler}
            tabIndex={-1}
            onClick={() => {
              setOptions(!option);
            }}
          >
            <div
              className="helper-text-one ampm-text"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {defaultValue}
            </div>
            <div className="ms-1">
              <KTSVG path={DownArrowIcon} />
            </div>
          </div>
          <OutsideClickHandler
            onOutsideClick={() => {
              if (option) {
                setOptions(!option);
              }
            }}
          >
            <Dropdown
              data={TimeZoneFormat}
              selected={option}
              value={defaultValue}
              onSelect={selectStatus}
              noDisableEffect={true}
              closeDropdown={() => setOptions(false)}
              className="full-width-dropdown"
            />
          </OutsideClickHandler>
        </div>
      </FlexBox>
      {/* Error Message */}

      <div className="d-flex error-message">
        {errorMessage !== '' && <div className={`text-danger poppins-regular fs-7 mb-3`}>{errorMessage}</div>}
      </div>
    </div>
  );
};

export default TimePicker;
