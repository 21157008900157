import React from 'react';
import { OrderStatus } from 'src/components/oraganisms/OrdersList/OrdersList.types';

export const getStatusByTab = (selectedTab: string, setSelectedStatus: React.Dispatch<React.SetStateAction<OrderStatus[]>>) => {
  if (selectedTab.toUpperCase() === 'PENDING') {
    setSelectedStatus([OrderStatus.PENDING]);
  } else if (selectedTab.toUpperCase() === 'TO PROCESS') {
    setSelectedStatus([OrderStatus.AWAITING_PROCESSING]);
  } else if (selectedTab.toUpperCase() === 'TO FULFILL') {
    setSelectedStatus([OrderStatus.AWAITING_FULFILLMENT]);
  } else if (selectedTab.toUpperCase() === 'TO SHIP') {
    setSelectedStatus([OrderStatus.AWAITING_SHIPMENT]);
  } else if (selectedTab.toUpperCase() === 'SHIPPED') {
    setSelectedStatus([OrderStatus.SHIPPED, OrderStatus.COMPLETED]);
  } else {
    setSelectedStatus([]);
  }
};
