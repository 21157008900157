import { gql } from '@apollo/client';

export const GET_CONNECTION_STATUS = gql`
  query GetConnectionStatus @api(name: "shopifyAPI") {
    getConnectionStatus {
      __typename
      name
      shop
      token
      id
      status
      count {
        products
        orders
        coupons
        customers
      }
      lastSyncedAt
      storeId
      updatedAt
      createdAt
      initialSync
      initialSyncStatus {
        product {
          status
          total
          completed
        }
        coupon {
          status
          total
          completed
        }
      }
    }
  }
`;
