import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import moment from 'moment';
import momentz from 'moment-timezone';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_LIVE_STATS, GET_USER_BASIC_INFO } from 'src/apollo/queries';
import { GET_STORE_ANALYTICS } from 'src/apollo/queries/getAnalytics';

// Components
import { FlexBox, Img, Loader } from 'src/components/atoms';
import FilterMenuDropdown from '../../home/FilterMenuDropdown';
import AnalyticsChart from '../../home/AnalyticsChart';
// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { numberWithCommas } from 'src/utils/numberWithCommas';
import { useToast } from 'src/utils/hooks/useToast';
import useTitle from 'src/utils/hooks/useTitle';

// Icons
import { LiveReportSelectIcon, AppArrowRightIcon, AppArrowLeftIcon, BackRoundedIcon } from 'src/assets/icons';

// Types
import { DateFilter, IDateRange, ANALYTICS_CHANNEL } from '../../home/HomePage.type';

// Styles
import './_liveShowReport.scss';
import { GET_LIVE_SHOW_ANALYTICS } from 'src/apollo/queries/getLiveshowAnalytics';
import AnalyticsCardForLive from './AnalyticsCardForLive';
import { LiveProductsBlock } from '../../../molecules';
import Slider from 'react-slick';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

export interface LiveFilterOptionItem {
  id: number;
  name: LiveChannelFilter;
}
export enum LiveChannelFilter {
  'All' = 'All',
  'During Liveshow' = 'During Liveshow',
  'After Liveshow' = 'After Liveshow'
}

const channelFilterList: LiveFilterOptionItem[] = [
  { id: 1, name: LiveChannelFilter.All },
  { id: 2, name: LiveChannelFilter['During Liveshow'] },
  { id: 3, name: LiveChannelFilter['After Liveshow'] }
];

const getDuration = (liveAnalytic) => {
  if (!liveAnalytic) {
    return '';
  }
  const start = new Date(liveAnalytic.startTime);
  const end = new Date(liveAnalytic.endTIme);

  // @ts-ignore
  const diffInMs = end - start;

  const hours = Math.floor(diffInMs / (1000 * 60 * 60));
  const minutes = Math.ceil((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

  return `${hours}h ${minutes}m`;
};

const LiveProductTable = ({ getSelectValue, id, isLoadingAnalytic }) => {
  const [selectedProductIDs, setSelectedProductIDs] = useState<Array<string>>([]);
  const handleUpdateSelectedProductIDs = (updatedProducts) => {
    console.log('handleUpdateSelectedProductIDs', updatedProducts);
    setSelectedProductIDs([...updatedProducts]);
  };
  return (
    <div
      className="w-100 p-x-32 p-t-32 m-t-28 live-product-table-container"
      style={{
        background: isLoadingAnalytic ? 'transparent' : 'white',
        borderRadius: '10px'
      }}
    >
      {!isLoadingAnalytic && (
        <div
          style={{
            marginBottom: '20px'
          }}
        >
          <span
            style={{
              fontSize: '20px',
              color: '#1E2749',
              fontWeight: 600
            }}
          >
            Products
          </span>
        </div>
      )}
      <LiveProductsBlock
        selectedProductIDs={selectedProductIDs}
        handleUpdateSelectedProductIDs={handleUpdateSelectedProductIDs}
        shouldShowVariants={true}
        isShowingOnModal={false}
        disabledIds={[]}
        isShowOnlyActiveProducts={true}
        liveId={id}
        getSelectValue={getSelectValue}
        isLoadingAnalytic={isLoadingAnalytic}
      />
    </div>
  );
};
const LiveShowReport: FC = () => {
  useTitle('LiveShow Report');

  const location = useLocation();
  const queryString = location.search;

  const isEndLive = useMemo(() => {
    if (queryString) {
      const searchParams = Object.fromEntries(new URLSearchParams(queryString).entries());
      return searchParams.isEndLive === 'true';
    }
    return false;
  }, [queryString]);

  const { liveId } = useParams<{ liveId: string }>();

  const { loading: isLoadingAnalytic, data: liveAnalyticResponse } = useQuery(GET_LIVE_SHOW_ANALYTICS, {
    variables: { id: liveId },
    skip: !liveId,
    pollInterval: isEndLive ? 30000 : undefined,
    onError: (error: any) => {
      showToast({
        errorText: error.message,
        message: `Error occured while get live show analytic: ${error.message}`
      });
    }
  });

  const liveAnalytic = liveAnalyticResponse && liveAnalyticResponse.getLiveshowAnalytics;
  const getDateRange = useCallback((daySelector: DateFilter): IDateRange => {
    switch (daySelector) {
      case DateFilter.Today:
      default: {
        return {
          startTime: moment().startOf('day').toDate(),
          endTime: moment().endOf('day').toDate()
        };
      }
      case DateFilter.Yesterday: {
        return {
          startTime: moment().subtract(1, 'days').startOf('day').toDate(),
          endTime: moment().subtract(1, 'days').endOf('day').toDate()
        };
      }
      case DateFilter['Last week']: {
        return {
          startTime: moment().subtract(7, 'days').startOf('day').toDate(),
          endTime: moment().toDate()
        };
      }
      case DateFilter['Last month']: {
        return {
          startTime: moment().subtract(30, 'days').startOf('day').toDate(),
          endTime: moment().toDate()
        };
      }
      case DateFilter['Last year']: {
        return {
          startTime: moment().subtract(365, 'days').startOf('month').toDate(),
          endTime: moment().toDate()
        };
      }
    }
  }, []);

  const { showToast } = useToast();

  const [dateRange, setDateRange] = useState<IDateRange>(getDateRange(DateFilter.Today));
  const [showDateDropdown, setShowDateDropdown] = useState(false);
  const [showChannelDropdown, setShowChannelDropdown] = useState(false);
  const [selectedChannelFilter, setSelectedChannelFilter] = useState(channelFilterList[0]);
  const [greet, setGreet] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);

  const getSelectValue = (obj) => {
    if (obj) {
      if (selectedChannelFilter.id === 2) {
        return obj.live;
      }
      if (selectedChannelFilter.id === 3) {
        return obj.vod;
      }
      return obj.total;
    }
    return '';
  };

  const handleSelectChannelFilter = (selectedId: number) => {
    const selected = channelFilterList.filter((filterItem) => filterItem.id === selectedId)[0];
    setSelectedChannelFilter(selected);
    setShowChannelDropdown(false);
  };

  const analyticsChannel = useMemo(() => {
    switch (selectedChannelFilter?.name) {
      case LiveChannelFilter['All channels']:
      default:
        return null;
      case LiveChannelFilter['Shopper app']:
        return ANALYTICS_CHANNEL.MOBILE_STORE;
    }
  }, [selectedChannelFilter]);

  useEffect(() => {
    const hours = new Date().getHours();
    if (hours >= 4 && hours < 12) {
      setGreet('Morning');
    } else if (hours >= 12 && hours < 18) {
      setGreet('Afternoon');
    } else {
      setGreet('Evening');
    }
  }, []);

  const NextArrow = ({ onClick, currentSlide, slideCount }) => {
    const isLastSlide = currentSlide >= slideCount - 1;
    if (isLastSlide) return null;
    return (
      <div className="custom-arrow slick-next" onClick={onClick}>
        <KTSVG path={AppArrowRightIcon} className="cursor-pointer" />
      </div>
    );
  };

  const PrevArrow = ({ onClick, currentSlide }) => {
    if (currentSlide === 0) return null;

    return (
      <div className="custom-arrow slick-prev" onClick={onClick}>
        <KTSVG path={AppArrowLeftIcon} className="cursor-pointer" />
      </div>
    );
  };

  const settings = {
    dots: false, // Show dots for navigation
    infinite: false, // Infinite scroll
    speed: 500,
    rows: 2,
    slidesPerRow: 4,
    slidesToScroll: 1, // Number of slides to scroll at once
    // @ts-ignore
    nextArrow: <NextArrow />, // Custom next arrow
    // @ts-ignore
    prevArrow: <PrevArrow /> // Custom previous arrow
  };
  const history = useHistory();
  return (
    <div className="details-page">
      <div className="details-page-container">
        {isLoadingAnalytic || !liveAnalyticResponse ? (
          <Loader type="page" className="h-600px" />
        ) : (
          <>
            <div className="m-t-40">
              <FlexBox className="align-items-center m-b-8">
                <img
                  src={BackRoundedIcon}
                  alt={BackRoundedIcon}
                  className="cursor-pointer"
                  onClick={() => history.push('/liveShow/calendar')}
                />
                <h2 className="live-reprot-page-title px-4">{liveAnalytic.title}</h2>
              </FlexBox>
              <p className="mb-0 live-description">
                {new Date(liveAnalytic.startTime).toLocaleDateString('en-US', {
                  day: 'numeric'
                })}
                {` `}
                {new Date(liveAnalytic.startTime).toLocaleDateString('en-US', {
                  month: 'long',
                  year: 'numeric'
                })}
                {` `}•{` `}
                {new Date(liveAnalytic.startTime).toLocaleDateString('en-US', {
                  weekday: 'long'
                })}{' '}
                • {new Date(liveAnalytic.startTime).toLocaleTimeString('en-US', { timeStyle: 'short' })}
                {` `}-{` `}
                {new Date(liveAnalytic.endTIme).toLocaleTimeString('en-US', { timeStyle: 'short' })} ({getDuration(liveAnalytic)})
              </p>
            </div>
            <div className="p-t-40">
              <div>
                <h5 className="home-subtitle poppins-semibold mb-0">Overview</h5>
                <FlexBox className="m-t-32 m-b-20 align-items-center justify-content-between">
                  <FlexBox className="align-items-center">
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setShowChannelDropdown(false);
                      }}
                    >
                      <button
                        className={`btn btn-sm btn-flex btn-icon-text align-items-center m-0 ${
                          showChannelDropdown ? 'btn-primary' : 'btn-secondary'
                        }`}
                        onClick={() => setShowChannelDropdown(true)}
                      >
                        <KTSVG path={LiveReportSelectIcon} className="me-2 svg-icon svg-icon-select" />
                        <span className="poppins-regular">{selectedChannelFilter.name}</span>
                      </button>
                      <FilterMenuDropdown
                        isShowDropdown={showChannelDropdown}
                        data={channelFilterList}
                        selectedValue={selectedChannelFilter}
                        onSelect={handleSelectChannelFilter}
                      />
                    </OutsideClickHandler>
                  </FlexBox>
                </FlexBox>
              </div>
              <div>
                <Slider {...settings}>
                  <div className="live-indicator-slide first">
                    <AnalyticsCardForLive
                      type="viewer"
                      value={numberWithCommas(getSelectValue(liveAnalytic?.stats?.viewers))}
                      text="Viewers"
                    />
                  </div>
                  <div className="live-indicator-slide">
                    <AnalyticsCardForLive
                      type="viewerTime"
                      value={`${Math.ceil(getSelectValue(liveAnalytic?.stats?.avgViewersTime) / 60)}m`}
                      text="Avg Viewer Time"
                    />
                  </div>
                  <div className="live-indicator-slide">
                    <AnalyticsCardForLive type="ctr" value={`${getSelectValue(liveAnalytic?.stats?.ctr)}%`} text="CTR" />
                  </div>
                  <div className="live-indicator-slide last">
                    <AnalyticsCardForLive
                      type="cart"
                      value={`$${numberWithCommas(getSelectValue(liveAnalytic?.stats?.avgCartSize))}`}
                      text="Avg. Cart Size"
                    />
                  </div>
                  <div className="live-indicator-slide first">
                    <AnalyticsCardForLive
                      type="sales"
                      value={`$${numberWithCommas(getSelectValue(liveAnalytic?.stats?.sales))}`}
                      text="Sales"
                    />
                  </div>
                  <div className="live-indicator-slide">
                    <AnalyticsCardForLive
                      type="profit"
                      value={`$${numberWithCommas(getSelectValue(liveAnalytic?.stats?.profit))}`}
                      text="Profit"
                    />
                  </div>
                  <div className="live-indicator-slide">
                    <AnalyticsCardForLive
                      type="paidOrders"
                      value={getSelectValue(liveAnalytic?.stats?.paidOrders)}
                      text="Paid Orders"
                    />
                  </div>
                  <div className="live-indicator-slide last">
                    <AnalyticsCardForLive
                      type="unpaidOrders"
                      value={getSelectValue(liveAnalytic?.stats?.unpaidOrders)}
                      text="Unpaid Orders"
                    />
                  </div>
                  <div className="live-indicator-slide first">
                    <AnalyticsCardForLive type="buyers" value={getSelectValue(liveAnalytic?.stats?.buyers)} text="Buyers" />
                  </div>
                  <div className="live-indicator-slide">
                    <AnalyticsCardForLive type="likes" value={getSelectValue(liveAnalytic?.stats?.likes)} text="Likes" />
                  </div>
                </Slider>
              </div>
            </div>
          </>
        )}
        <LiveProductTable getSelectValue={getSelectValue} id={liveId} isLoadingAnalytic={isLoadingAnalytic} />
      </div>
    </div>
  );
};

export default LiveShowReport;
