/* eslint-disable no-nested-ternary */
import { FC, useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';

// Components
import TagMenuItem from './TagMenuItem';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon } from 'src/assets/icons';

// Types
import { TagDropdownProps } from './TagAndCollectionDropdown.types';

// Styles
import './_tagAndCollectionDropdown.scss';

import { List } from 'react-virtualized';

const TagDropdown: FC<TagDropdownProps> = ({
  allItems = [],
  appliedItems = [],
  selected,
  onlyAppliedTags,
  changeAppliedItems,
  type,
  className,
  addToTag,
  addToCollection,
  addToSubCategory,
  moveToRight,
  endItem,
  isBulkEditorModal,
  isCreatable,
  closeDropdown,
  handleDeleteCustomerTag,
  isEditAndDeletable,
  containerWidth,
  shouldApplyVirtual,
  setSearch
}) => {
  const [filter, setFilter] = useState('');

  useEffect(() => {
    setSearch && setSearch(filter);
  }, [filter]);

  const filteredData = allItems.filter(
    (item) => item.name.toLowerCase().includes(filter.toLowerCase()) && !appliedItems.some((applied) => applied.id === item.id)
  );

  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        closeDropdown();
      }
    },
    [closeDropdown]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction);
    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  const handleChange = (event) => {
    if (event.key === ',') {
      event.preventDefault();
    }
    setFilter(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === ',') {
      event.preventDefault();
    }
    if (event.key === 'Enter') {
      const name = event.target.value.includes(',') ? event.target.value.split(',')[0] : event.target.value;
      createItem(name);
      setFilter('');
    }
  };

  const createItem = (name) => {
    if (type === 'Collection') {
      addToCollection({
        variables: {
          input: {
            parentId: null,
            name,
            description: '',
            status: 'ACTIVE',
            type: 'MANUAL',
            thumbnailUrl: '',
            productIds: null
          }
        }
      });
    } else if (type === 'Tag') {
      addToTag({
        variables: {
          input: {
            name,
            type: 'TAG'
          }
        }
      });
    } else if (addToSubCategory) {
      addToSubCategory(name);
    }
  };

  return (
    <div
      className={clsx(
        className,
        'overflow-hidden mt-1 menu menu-sub menu-background menu-sub-dropdown menu-column menu-rounded menu-gray-600 w-100 px-0 dropdown-height',
        {
          show: selected,
          'end-0': !endItem,
          'position-absolute': !endItem,
          'position-fixed': endItem,
          'd-flex flex-column': selected,
          'dropdown-width': onlyAppliedTags,
          'tags-menu-dropdown': !onlyAppliedTags,
          'w-250px': isBulkEditorModal,
          moveToRight
        }
      )}
      data-kt-menu="true"
    >
      <div
        className={clsx('px-3', {
          'border-bottom': !onlyAppliedTags,
          'border-bottom-secondary': !onlyAppliedTags,
          'py-3': appliedItems.length > 0
        })}
      >
        {appliedItems.map((item, index) => (
          <span
            key={index}
            className={clsx('tag-item', { 'collection-badge': type !== 'Tag', 'disabled-tag': item.disabled })}
            style={{ color: item.textColorCode, backgroundColor: item.backgroundColorCode, borderRadius: '4px' }}
          >
            <span>{item.name}</span>
            {!onlyAppliedTags && (
              <KTSVG
                path={CloseIcon}
                className={clsx('cross-color-class', { 'cursor-pointer': !item.disabled })}
                onClick={() => !item.disabled && changeAppliedItems(item, type, 'remove')}
                style={{ '--textColorCode': item.textColorCode || (item.disabled ? '#a1a5b7' : '#66728b') }}
                svgClassName={`badge-${index % 7} bg-transparent`}
              />
            )}
          </span>
        ))}
      </div>
      {!onlyAppliedTags && (
        <>
          <div className="border-bottom border-bottom-secondary search-box">
            <input
              autoComplete="off"
              type="text"
              className="form-control form-control-solid px-2 w-100"
              value={filter}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              name="search"
              placeholder={isCreatable ? 'Search or Create new' : 'Search'}
            />
          </div>
          <div className="overflow-scroll tags-list h-100">
            {filteredData.length > 0 ? (
              shouldApplyVirtual ? (
                <List
                  width={containerWidth && containerWidth > 13 ? containerWidth - 13 : 257}
                  height={41 * filteredData.length}
                  rowHeight={41}
                  rowCount={filteredData.length}
                  rowRenderer={({ key, index, style }) => {
                    return (
                      <TagMenuItem
                        key={key}
                        item={filteredData[index]}
                        type={type}
                        changeAppliedItems={changeAppliedItems}
                        data={allItems}
                        showDropdown={selected}
                        handleDeleteCustomerTag={handleDeleteCustomerTag}
                        isEditAndDeletable={isEditAndDeletable}
                      />
                    );
                  }}
                />
              ) : (
                <>
                  {filteredData.map((item, index) => (
                    <TagMenuItem
                      key={index}
                      item={item}
                      type={type}
                      changeAppliedItems={changeAppliedItems}
                      data={allItems}
                      showDropdown={selected}
                      handleDeleteCustomerTag={handleDeleteCustomerTag}
                      isEditAndDeletable={isEditAndDeletable}
                    />
                  ))}
                </>
              )
            ) : (
              <div className="px-2 text-center">
                <span className="f-14px text-muted opacity-50">
                  {isCreatable ? `Press Enter or comma to create a new ${type.toLowerCase()}` : `Not Found ${type}`}
                </span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TagDropdown;
