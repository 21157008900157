import { FC, Fragment } from 'react';

// Components
import { FlexBox } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon, RightNavigtor } from 'src/assets/icons';

// Styles
import './_tiktokResponseDrawer.scss';
import StatusCell from '../../../tiktok-integration/components/StatusCell';

interface TiktokResponseDrawerProps {
  tiktokResponse: any;
  showDrawer: boolean;
  closeDrawer: () => void;
  isTiktokFixed: boolean;
  rowData?: any;
}

const TiktokResponseDrawer: FC<TiktokResponseDrawerProps> = ({
  tiktokResponse,
  showDrawer,
  closeDrawer,
  isTiktokFixed,
  rowData
}) => {
  let allErrors = [];
  if (tiktokResponse?.buysocial && tiktokResponse?.buysocial?.length > 0) {
    // @ts-ignore
    allErrors = [...allErrors, ...tiktokResponse?.buysocial];
  }
  if (tiktokResponse?.tiktok?.failReasons && tiktokResponse?.tiktok?.failReasons?.length > 0) {
    // @ts-ignore
    allErrors = [...allErrors, ...tiktokResponse?.tiktok?.failReasons];
  }
  return (
    <div
      id="kt_activities"
      className={`bg-body drawer drawer-end liveshow-event-preview overflow-visible ${showDrawer ? 'drawer-on' : ''}`}
      data-kt-drawer="true"
      data-kt-drawer-name="activities"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'419.5px', 'sm': '419.5px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_activities_toggle"
      data-kt-drawer-close="#kt_activities_close"
    >
      <div className="position-relative">
        <div className={`bar-toggle-arrow bg-primary ${!showDrawer ? 'd-none' : ''}`} onClick={closeDrawer}>
          <img src={RightNavigtor} alt="" />
        </div>
        <div className="tiktok-response-drawer p-x-30 p-y-36">
          <FlexBox className="align-items-center justify-content-between">
            <h6 className="m-0 section-title">TikTok Response</h6>
            <KTSVG path={CloseIcon} className="svg-icon-2 close-icon cursor-pointer" onClick={closeDrawer} />
          </FlexBox>
          {rowData && (
            <div className="mt-4 border-secondary">
              <StatusCell data={rowData} />
              <div
                className="my-4"
                style={{
                  height: '1px',
                  background: '#E9E9EB'
                }}
              ></div>
            </div>
          )}
          <div className="p-16 m-y-24 tiktok-response-drawer-description">
            {isTiktokFixed
              ? 'no suggestions text'
              : 'Please fix the list below to comply with the TikTok rules and push this product to TikTok.'}
          </div>
          <div className="scroll-y">
            {allErrors.length > 0 && (
              <div className="tiktok-response-item">
                <h5 className="error-title">Errors</h5>
                <ul>
                  {tiktokResponse?.buysocial?.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                  {tiktokResponse?.tiktok?.failReasons?.map((error, index) => (
                    <li key={index}>{error?.message}</li>
                  ))}
                </ul>
              </div>
            )}
            {tiktokResponse?.tiktok?.diagnoses?.map((diagnoseItem, index) => (
              <Fragment key={index}>
                <div className="tiktok-response-item m-y-12">
                  <h5>{diagnoseItem?.field?.toLowerCase()}</h5>
                  <ul>
                    {diagnoseItem?.diagnosisResults?.map((solution, sIndex) => (
                      <li key={sIndex}>{solution?.howToSolve}</li>
                    ))}
                  </ul>
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TiktokResponseDrawer;
