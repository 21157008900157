import { FC, useState } from 'react';

// Components
import AddProductVariantPresentational from './AddProductVariant.presentational';

// ConstVariables
import { modifyArray, modifyArrayItem } from './helper/variants';

// Types
import { VariantOptions, VariantOptionsSection } from 'src/components/pages/products/AddProduct/AddProduct.types';
import { ArrayItems, ArrayItemsWithIndex, TableData } from './AddProductVariant.types';

// Styles
import './AddProductVariants.scss';
import { SPLITER } from '../../../constants';

const AddproductVariants: FC<VariantOptionsSection> = ({
  pricing,
  options,
  setOptions,
  optionChecked,
  setOptionChecked,
  tableData,
  setTableData,
  errorMessages,
  setErrorMessages,
  setOptionDone,
  setSelectedVariantOption,
  selectedVariantOption,
  setCheckedVariantOption,
  checkedVariantOption,
  optionDone,
  productMedia,
  setProductMedia,
  updateProductMedia,
  inventory,
  setInventory,
  formChanges,
  setLabelPrintType,
  setShowLabelPrintModal,
  setShowProductSavingModal,
  selectedVariants,
  setSelectedVariants,
  setSelectedVariantIds
}) => {
  const [variantOption, setVariationOption] = useState<{ id: number; name: string }[]>([
    { id: 1, name: 'Size' },
    { id: 2, name: 'Color' },
    { id: 3, name: 'Material' },
    { id: 4, name: 'Style' }
  ]);
  const [filteredOptions, setFilteredOptions] = useState<{ id: number; name: string }[]>([
    { id: 1, name: 'Size' },
    { id: 2, name: 'Color' },
    { id: 3, name: 'Material' },
    { id: 4, name: 'Style' }
  ]);
  // add tag to option.value
  const addTagHandler = (tags: string[], index: number) => {
    setOptions(modifyArrayItem({ data: options, fieldname: 'value', value: tags, index }));
  };

  // on drag update options array
  const onDragEnd = (e: any) => {
    if (!e.destination) {
      return;
    }
    const arr = Array.from(options);
    const [removed] = arr.splice(e.source.index, 1);
    arr.splice(e.destination.index, 0, removed);
    setOptions(arr);
  };
  // change title of option
  const onChangeTitle = (e: string, index: number) => {
    setOptions(modifyArrayItem({ data: options, fieldname: 'title', value: e, index }));
  };

  // add option field
  const addOptionHandler = () => {
    const obj: VariantOptions = {
      title: options.length <= 3 ? filteredOptions[0].name : '',
      value: [],
      errTitle: false,
      errVariant: false
    };
    const arr = [...options, obj];
    setOptions(arr);
  };

  // remove option field
  const removeOptionHandler = (key: number) => {
    if (options.length === 1) {
      setOptionChecked(false);
      setOptions([{ title: 'Size', value: [], errTitle: false, errVariant: false }]);
    } else {
      const obj = options.filter((_, index) => index !== key);
      setOptions(obj);
    }
  };

  // toggle option check
  const optionCheckHandler = () => {
    if (options.length === 0) {
      setOptions([{ title: '', value: [], errTitle: false, errVariant: false }]);
    }
    if (optionChecked) {
      setOptionChecked(false);
    } else {
      setOptionChecked(true);
    }
  };
  const onSubmitOptionsHandler = () => {
    const errArrTitle: any[] = [];
    const errArrvalue: any[] = [];
    setOptions(
      options.map((item, index) => {
        if (item.title === '') {
          item.errTitle = true;
          errArrTitle.push(index);
        } else {
          item.errTitle = false;
        }
        return item;
      })
    );
    setOptions(
      options.map((item, index) => {
        if (item.value.length === 0) {
          item.errVariant = true;
          errArrvalue.push(index);
        } else {
          item.errVariant = false;
        }
        return item;
      })
    );
    if (errArrTitle.length <= 0 && errArrvalue.length <= 0) {
      setOptionDone(true);
    } else {
      setOptionDone(false);
    }
    createCombinations();
  };
  const checkTitleError = (index: number) => {
    const checkTitle = options.findIndex((item, i) => i === index && item.title === '');
    if (checkTitle >= 0) {
      setOptions(modifyArrayItem({ data: options, fieldname: 'errTitle', value: true, index }));
    } else {
      setOptions(modifyArrayItem({ data: options, fieldname: 'errTitle', value: false, index }));
    }
  };
  const checkTagError = (index: number) => {
    const checkValue = options.findIndex((item, i) => i === index && item.value.length === 0);
    if (checkValue >= 0) {
      setOptions(modifyArrayItem({ data: options, fieldname: 'errVariant', value: true, index }));
    } else {
      setOptions(modifyArrayItem({ data: options, fieldname: 'errVariant', value: false, index }));
    }
  };

  const checkCacheForVariant = (variantString: string, itrationIndex: number, isFirstItem: boolean): TableData => {
    const previousVariantString = variantString.split(SPLITER).reverse().join(SPLITER);
    const prevData = tableData?.find((item) => item.variantType === variantString);
    const onlyDataExchanged = tableData?.find((item) => item.variantType === previousVariantString);
    const warehouseInventory = inventory?.inventoryData
      ?.filter((item) => item.inventoryWarehouseId)
      .map((item) => {
        if (isFirstItem) {
          return { warehouseId: item.inventoryWarehouseId, quantity: item.inventoryQuantityValue };
        }
        return { warehouseId: item.inventoryWarehouseId, quantity: 0 };
      });
    if (prevData) {
      return {
        id: prevData.id,
        variantType: variantString,
        price: prevData.price,
        // sku: `ZG-011A${itrationIndex}`,
        sku: prevData?.sku,
        img: prevData.img,
        barcode: prevData.barcode,
        status: prevData.status,
        weight: prevData.weight,
        warehouseInventory: prevData.warehouseInventory,
        media: prevData.media
      };
    } else {
      return {
        variantType: variantString,
        // eslint-disable-next-line no-nested-ternary
        price: onlyDataExchanged ? onlyDataExchanged.price : pricing.salePrice ? pricing.salePrice.toString() : '0',
        // sku: `ZG-011A${itrationIndex}`,
        sku: onlyDataExchanged ? onlyDataExchanged?.sku : '',
        img: onlyDataExchanged ? onlyDataExchanged.img : '',
        barcode: onlyDataExchanged ? onlyDataExchanged.barcode : '',
        status: onlyDataExchanged ? onlyDataExchanged.status : 'ACTIVE',
        weight: onlyDataExchanged ? onlyDataExchanged.weight : '0',
        warehouseInventory: onlyDataExchanged ? onlyDataExchanged.warehouseInventory : warehouseInventory
      };
    }
  };

  const createCombinations = () => {
    const temp: TableData[] = [];
    let isFirstItem = false;
    switch (options.length) {
      case 1:
        for (let i = 0; i < options[0].value.length; i++) {
          isFirstItem = i === 0 ? true : false;
          temp.push(checkCacheForVariant(`${options[0].value[i]}`, i, isFirstItem));
        }
        break;
      case 2:
        for (let i = 0; i < options[0].value.length; i++) {
          for (let j = 0; j < options[1].value.length; j++) {
            isFirstItem = i === 0 && j === 0 ? true : false;
            temp.push(checkCacheForVariant(`${options[0].value[i]}/${options[1].value[j]}`, i, isFirstItem));
          }
        }
        break;
      case 3:
        for (let i = 0; i < options[0].value.length; i++) {
          for (let j = 0; j < options[1].value.length; j++) {
            for (let k = 0; k < options[2].value.length; k++) {
              isFirstItem = i === 0 && j === 0 && k === 0 ? true : false;
              temp.push(
                checkCacheForVariant(`${options[0].value[i]}/${options[1].value[j]}/${options[2].value[k]}`, i, isFirstItem)
              );
            }
          }
        }
        break;
    }

    if (temp.length > 0) {
      setTableData(temp);
    }
  };

  const changeFieldValue = ({ e, fieldname, value, index, subIndex }: ArrayItemsWithIndex) => {
    if (e.code === 'Enter' && value !== '') {
      if (fieldname !== 'warehouseInventory') {
        setTableData(modifyArrayItem({ data: tableData, fieldname, value, index }));
      } else {
        if (subIndex !== undefined) {
          let modifiedInventories;
          const existingInventory = tableData[index].warehouseInventory.find(
            (item) => item.warehouseId === value.warehouseId.toString()
          );
          if (existingInventory) {
            modifiedInventories = tableData[index].warehouseInventory.map((item) => {
              if (item.warehouseId === value.warehouseId.toString()) {
                return { id: item.id, warehouseId: item.warehouseId, quantity: value.quantity };
              }
              return item;
            });
          } else {
            modifiedInventories = [...tableData[index].warehouseInventory, value];
          }
          setTableData(modifyArrayItem({ data: tableData, fieldname, value: modifiedInventories, index }));
        }
      }
      e.target.blur();
    }
    if (e.type === 'blur' && value !== '') {
      if (fieldname !== 'warehouseInventory') {
        setTableData(modifyArrayItem({ data: tableData, fieldname, value, index }));
      } else {
        if (subIndex !== undefined) {
          let modifiedInventories;
          const existingInventory = tableData[index].warehouseInventory.find((item) => item.warehouseId === value.warehouseId);
          if (existingInventory) {
            const currentTableCellValue = tableData[index].warehouseInventory.find(
              (item) => item.warehouseId === value.warehouseId
            )?.quantity;
            const updatedInventoryArray = inventory.inventoryData.map((item) => {
              if (item.inventoryWarehouseId === value.warehouseId) {
                return {
                  ...item,
                  inventoryQuantityValue:
                    currentTableCellValue !== value.quantity
                      ? item.inventoryQuantityValue +
                        value.quantity -
                        (currentTableCellValue !== undefined ? currentTableCellValue : 0)
                      : item.inventoryQuantityValue
                };
              }
              return item;
            });
            const updatedInventory = { ...inventory, inventoryData: updatedInventoryArray };
            setInventory(updatedInventory);
            modifiedInventories = tableData[index].warehouseInventory.map((item) => {
              if (item.warehouseId === value.warehouseId) {
                return { id: item.id, warehouseId: item.warehouseId, quantity: value.quantity };
              }
              return item;
            });
          } else {
            modifiedInventories = [...tableData[index].warehouseInventory, value];
          }

          setTableData(modifyArrayItem({ data: tableData, fieldname, value: modifiedInventories, index }));
        }
      }
    }
    if (e.type === 'click' && value !== '') {
      setTableData(modifyArrayItem({ data: tableData, fieldname, value, index }));
    }
  };

  const changeValue = ({ e, fieldname, value }: ArrayItems) => {
    if (e.code === 'Enter' && value !== '') {
      setTableData(modifyArray({ data: tableData, fieldname, value }));
      e.target.blur();
    }
    if (e.type === 'blur' && value !== '') {
      setTableData(modifyArray({ data: tableData, fieldname, value }));
    }
    if (e.type === 'click' && value !== '') {
      setTableData(modifyArray({ data: tableData, fieldname, value }));
    }
  };
  return (
    <AddProductVariantPresentational
      addOptionHandler={addOptionHandler}
      addTagHandler={addTagHandler}
      changeFieldValue={changeFieldValue}
      changeValue={changeValue}
      checkTagError={checkTagError}
      checkTitleError={checkTitleError}
      onChangeTitle={onChangeTitle}
      onDragEnd={onDragEnd}
      onSubmitOptionsHandler={onSubmitOptionsHandler}
      optionChecked={optionChecked}
      optionCheckHandler={optionCheckHandler}
      optionDone={optionDone}
      options={options}
      setOptions={setOptions}
      removeOptionHandler={removeOptionHandler}
      setOptionDone={setOptionDone}
      tableData={tableData}
      variantOption={variantOption}
      setVariationOption={setVariationOption}
      setTableData={setTableData}
      selectedVariantOption={selectedVariantOption}
      setSelectedVariantOption={setSelectedVariantOption}
      checkedVariantOption={checkedVariantOption}
      setCheckedVariantOption={setCheckedVariantOption}
      setFilteredOptions={setFilteredOptions}
      filteredOptions={filteredOptions}
      setOptionChecked={setOptionChecked}
      pricing={pricing}
      productMedia={productMedia}
      setProductMedia={setProductMedia}
      updateProductMedia={updateProductMedia}
      inventory={inventory}
      formChanges={formChanges}
      setLabelPrintType={setLabelPrintType}
      setShowLabelPrintModal={setShowLabelPrintModal}
      setShowProductSavingModal={setShowProductSavingModal}
      selectedVariants={selectedVariants}
      setSelectedVariants={setSelectedVariants}
      setSelectedVariantIds={setSelectedVariantIds}
    />
  );
};

export default AddproductVariants;
