import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

// Components
import { Loader } from 'src/components/atoms';

// ConstVariables
import { ROUTES, envUrls } from 'src/constants';

const ShopifyCallback: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const shop = urlParams.get('shop');
  const token = localStorage.getItem('token');

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set('Authorization', token ? token : '');

  const isPassedGenerateUrl = localStorage.getItem('isPassedGenerateUrl');

  useEffect(() => {
    console.log('useEffect shop', shop);
    const gernerateUrl = async () => {
      const response = await fetch(`${envUrls.shopifyCallbackUrl}/generateURL?shop=${shop}`, {
        method: 'GET',
        headers: requestHeaders
      });

      if (response.ok) {
        const responseData = await response.json();
        const location = responseData?.headers?.Location;
        localStorage.setItem('isPassedGenerateUrl', 'true');
        window.location.href = location;
      }
    };

    const configureShopifyStore = async () => {
      console.log('configureShopifyStore - ', queryString);
      console.log('configureShopifyStore envUrls - ', envUrls);
      console.log('configureShopifyStore envUrls.shopifyCallbackUrl - ', envUrls.shopifyCallbackUrl);
      const response = await fetch(`${envUrls.shopifyCallbackUrl}/callback${queryString}`, {
        method: 'GET',
        headers: requestHeaders
      });

      if (response.ok) {
        localStorage.removeItem('isPassedGenerateUrl');
        history.push(ROUTES.settings.integrations.main);
      }
    };

    console.log('useEffect isPassedGenerateUrl', isPassedGenerateUrl);

    if (isPassedGenerateUrl !== 'true') {
      gernerateUrl();
    } else {
      configureShopifyStore();
    }
  }, [shop]);

  return (
    <div className="w-100 full-height d-flex align-items-center justify-content-center">
      <div>
        <Loader type="page" className="m-b-24" />
        <h1>Shopify Connecting ...</h1>
      </div>
    </div>
  );
};

export default ShopifyCallback;
