import { useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router';
// import { CSVBoxButton } from '@csvbox/react';
// import { Link } from 'react-router-dom';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { AsideMenuSubItem } from './AsideMenuSubItem';
import { AsideMenuItem } from './AsideMenuItem';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';

// Hooks && Utils && Helpers
import { checkIsActive } from 'src/helpers';

// Icons
import {
  OrderIcon,
  ProductIcon,
  AsideSettingIcon,
  SelectedProductIcon,
  SelectedOrderIcon,
  SelectedAsideSettingIcon,
  SelectedMarketingIcon,
  MarketingIcon,
  SelectedCustomerIcon,
  CustomerIcon,
  SelectedliveShowIcon,
  LiveShowIcon,
  SelectedCouponIcon,
  CouponIcon,
  HomeIcon,
  SelectedHomeIcon
  // AnalyticsIcon,
  // VendorManagementIcon,
  // SelectedVendorManagementIcon,
  // SelectedAnalyticsIcon,
} from 'src/assets/icons';

// ConstVariables
import { ROUTES, constVariables, ENV, envStatuses } from 'src/constants';

// Types
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

export function AsideMenuMain(): JSX.Element {
  const { pathname } = useLocation();
  const history = useHistory();

  let { role, permission } = useAppSelector((state) => state.userInfo.userInfo);
  let localRole = localStorage.getItem('userRole') || '';
  role = role || localRole;
  let localPermission = JSON.parse(localStorage.getItem('permission') || '[]');
  permission = permission || localPermission;
  const isVisibleHome = useMemo(() => {
    if (role === UserRoles.OWNER) return true;

    const isFilteredHomePart = permission?.filter((permissionItem) => permissionItem.section === 'HOME_DASHBOARD')?.[0];
    if (isFilteredHomePart) {
      if (isFilteredHomePart.permissions.includes('FULL')) {
        return true;
      }
    }

    return false;
  }, [permission]);

  const isVisibleLiveshow = useMemo(() => {
    if (role === UserRoles.OWNER || role === UserRoles.ADMIN) return true;
    return false;
  }, [permission]);

  const isVisibleCustomers = useMemo(() => {
    if (role === UserRoles.OWNER) return true;

    const isFilteredCustomersPart = permission?.filter((permissionItem) => permissionItem.section === 'CUSTOMERS')?.[0];
    if (isFilteredCustomersPart) {
      if (isFilteredCustomersPart.permissions.includes('FULL')) {
        return true;
      }
    }

    return false;
  }, [permission]);

  const isVisibleSettings = useMemo(() => {
    if (role === UserRoles.OWNER || role === UserRoles.ADMIN) return true;
    return false;
  }, [role]);

  const isVisibleMarketing = useMemo(() => {
    if (role === UserRoles.OWNER) return true;

    const isFilteredMarketingPart = permission?.filter((permissionItem) => permissionItem.section === 'MARKETING')?.[0];
    if (isFilteredMarketingPart) {
      if (isFilteredMarketingPart.permissions.includes('FULL')) {
        return true;
      }
    }

    return false;
  }, [permission]);

  const isVisibleCoupons = useMemo(() => {
    if (role === UserRoles.OWNER) return true;

    const isFilteredCouponsPart = permission?.filter((permissionItem) => permissionItem.section === 'COUPONS')?.[0];
    if (isFilteredCouponsPart) {
      if (isFilteredCouponsPart.permissions.includes('FULL')) {
        return true;
      }
    }

    return false;
  }, [permission]);

  const isVisibleCollections = useMemo(() => {
    if (role === UserRoles.OWNER || role === UserRoles.ADMIN || role === UserRoles.ADMIN_ASSOCIATE) return true;
    return false;
  }, [role]);

  return (
    <>
      {/* Home Page */}
      {isVisibleHome && (
        <AsideMenuItem
          to={ROUTES.home.main}
          icon={checkIsActive(pathname, ROUTES.home.main) ? SelectedHomeIcon : HomeIcon}
          title={constVariables.MainMenuBar.home}
        />
      )}

      {/* LiveShow Pages */}
      {isVisibleLiveshow && (
        <AsideMenuItemWithSub
          to={ROUTES.liveShow.main}
          icon={checkIsActive(pathname, ROUTES.liveShow.main) ? SelectedliveShowIcon : LiveShowIcon}
          title={constVariables.MainMenuBar.liveShow}
          defaultRedirectRoute={ROUTES.liveShow.calendar.main}
          autoSelectSubItem
        >
          <AsideMenuSubItem to={ROUTES.liveShow.calendar.main} subMenuItem={constVariables.liveShowBar.calendar} />
          <AsideMenuSubItem to={ROUTES.liveShow.management.main} subMenuItem={constVariables.liveShowBar.management} />
        </AsideMenuItemWithSub>
      )}

      {/* Vendors Pages */}
      {/* <AsideMenuItemWithSub
        to={ROUTES.vendorManagement.main}
        icon={checkIsActive(pathname, ROUTES.vendorManagement.main) ? SelectedVendorManagementIcon : VendorManagementIcon}
        title={constVariables.MainMenuBar.vendorsManagement}
        defaultRedirectRoute={ROUTES.vendorManagement.vendors.main}
        autoSelectSubItem
      >
        <AsideMenuSubItem to={ROUTES.vendorManagement.vendors.main} subMenuItem={constVariables.VendorManagementBar.allVendors} />
        <AsideMenuSubItem
          to={ROUTES.vendorManagement.purchaseOrders.main}
          subMenuItem={constVariables.VendorManagementBar.allPurchaseOrders}
        />
      </AsideMenuItemWithSub> */}

      {/* Product Pages */}
      <AsideMenuItemWithSub
        to={ROUTES.products.main}
        title={constVariables.MainMenuBar.products}
        icon={checkIsActive(pathname, ROUTES.products.main) ? SelectedProductIcon : ProductIcon}
        defaultRedirectRoute={ROUTES.products.allProducts.main}
        autoSelectSubItem
      >
        <AsideMenuSubItem to={ROUTES.products.allProducts.main} subMenuItem={constVariables.SideMenuBar.allProducts} />
        <AsideMenuSubItem to={ROUTES.products.inventory.main} subMenuItem={constVariables.SideMenuBar.inventory} />
        {isVisibleCollections && (
          <AsideMenuSubItem
            to={ROUTES.products.productCategory.collections.allCollections}
            subMenuItem={constVariables.SideMenuBar.collections}
          />
        )}

        {/* <div className="sub-menu-item">
          <Link className="menu-link without-sub" to="#">
            <CSVBoxButton
              licenseKey={envUrls.csvBoxLicenseKey}
              user={{
                userId: localStorage.getItem('userId') ? localStorage.getItem('userId') : null,
                storeId: localStorage.getItem('storeId') ? localStorage.getItem('storeId') : null
              }}
              onImport={async (result, data) => {
                if (result) {
                  console.log('success');
                  console.log(` ${data.row_success} rows uploaded`);
                  console.log('data we had -', data);
                  // await axios.post(envUrls.csvBoxImportURL, data).then();
                } else {
                  console.log('fail');
                  // custom code
                }
              }}
            >
              Import
            </CSVBoxButton>
          </Link>
        </div> */}
        {/* <AsideMenuSubItem to={ROUTES.products.returns.main} subMenuItem={constVariables.SideMenuBar.returns} /> */}
      </AsideMenuItemWithSub>

      {/* Order Pages */}
      <AsideMenuItemWithSub
        to={ROUTES.orders.main}
        title={constVariables.MainMenuBar.orders}
        icon={checkIsActive(pathname, ROUTES.orders.main) ? SelectedOrderIcon : OrderIcon}
        defaultRedirectRoute={ROUTES.orders.allOrders.main}
        autoSelectSubItem
      >
        <AsideMenuSubItem to={ROUTES.orders.allOrders.main} subMenuItem={constVariables.SideMenuBar.allOrders} />
        <AsideMenuSubItem
          to={ROUTES.orders.allOrders.fulfillmentCenter}
          subMenuItem={constVariables.SideMenuBar.fulfillmentCenter}
        />
        <AsideMenuSubItem to={ROUTES.orders.allOrders.waitlists} subMenuItem={constVariables.SideMenuBar.waitlists} />
      </AsideMenuItemWithSub>

      {/* <AsideMenuItem
        to={ROUTES.analytics.main}
        title={constVariables.MainMenuBar.analytics}
        icon={checkIsActive(pathname, ROUTES.analytics.main) ? SelectedAnalyticsIcon : AnalyticsIcon}
      /> */}

      {/* Customers Page */}
      {isVisibleCustomers && (
        <AsideMenuItemWithSub
          to={ROUTES.customers.main}
          title={constVariables.MainMenuBar.customer}
          icon={checkIsActive(pathname, ROUTES.customers.main) ? SelectedCustomerIcon : CustomerIcon}
          defaultRedirectRoute={ROUTES.customers.allCustomers.main}
          autoSelectSubItem
        >
          <AsideMenuSubItem to={ROUTES.customers.allCustomers.main} subMenuItem={constVariables.SideMenuBar.allCustomer} />
        </AsideMenuItemWithSub>
      )}

      {/* Marketing Pages */}
      {isVisibleMarketing && (
        <AsideMenuItemWithSub
          to={ROUTES.marketing.main}
          title={constVariables.MainMenuBar.marketing}
          icon={checkIsActive(pathname, ROUTES.marketing.main) ? SelectedMarketingIcon : MarketingIcon}
          defaultRedirectRoute={ROUTES.marketing.catalogs.allCatalogs}
          autoSelectSubItem
        >
          <AsideMenuSubItem to={ROUTES.marketing.catalogs.allCatalogs} subMenuItem={constVariables.SideMenuBar.catalogs} />
          <AsideMenuSubItem to={ROUTES.marketing.featuredProducts.main} subMenuItem={constVariables.SideMenuBar.featuredProducts} />
          {envStatuses !== ENV.STAGING && (
            <AsideMenuSubItem
              to={ROUTES.marketing.tiktokIntegration.main}
              subMenuItem={constVariables.SideMenuBar.tiktokIntegration}
            />
          )}
        </AsideMenuItemWithSub>
      )}

      {/* Coupon Page */}
      {isVisibleCoupons && (
        <AsideMenuItem
          to={ROUTES.coupons.allCoupons.main}
          title={constVariables.MainMenuBar.coupons}
          icon={checkIsActive(pathname, ROUTES.coupons.main) ? SelectedCouponIcon : CouponIcon}
        />
      )}

      {/* Settings Page */}
      {isVisibleSettings && (
        <AsideMenuItem
          to={ROUTES.settings.main}
          title={constVariables.MainMenuBar.settings}
          icon={checkIsActive(pathname, ROUTES.settings.main) ? SelectedAsideSettingIcon : AsideSettingIcon}
        />
      )}
    </>
  );
}
