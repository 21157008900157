import { useState, useEffect, FC, memo } from 'react';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_FACEBOOK_CHANNELS } from 'src/apollo/queries';

// Components
import { FlexBox } from 'src/components/atoms';
import { Checkbox, CustomeDatePicker, EmojiInput, TimePicker } from 'src/components/molecules';
import { Thumbnail } from 'src/components/oraganisms';

// Icons
import { UploadThumbnailIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { CreateLiveShowDetailStepProps, LiveShowDetailInfo } from './CreateLiveShowDetailStep.types';

// Styles
import './_createLiveShowDetailStep.scss';

import cloneDeep from 'lodash/cloneDeep';

const CreateLiveShowDetailStepPresentational: FC<CreateLiveShowDetailStepProps> = ({
  liveShowDetailInfo,
  liveShowInfoError,
  handleSendSuccessURL,
  updateLiveShowDetailInfo,
  updateLiveShowInfoErrors,
  removeThumbnail,
  updateDate,
  updateStartTime,
  updateEndTime,
  validateTimes,
  startDateMoment,
  endDateMoment
}) => {
  const { isComplete, facebookChannels } = liveShowDetailInfo;
  const [fbChannelsChecked, setFBChannelsChecked] = useState(false);
  const [showFBChannels, setShowFBChannels] = useState(true);

  useEffect(() => {
    if (facebookChannels?.length > 0) {
      setFBChannelsChecked(true);
    } else {
      setFBChannelsChecked(false);
    }
  }, [facebookChannels]);

  // Event handlers
  const changeLiveShowTitle = (text: string): void => {
    const updateData: LiveShowDetailInfo = cloneDeep(liveShowDetailInfo);
    updateData.title = text;
    updateData.notification.app.title = text;
    updateData.notification.messenger.description = text;
    updateData.notification.textMessage = text;
    updateLiveShowDetailInfo(updateData);
    if (text.length > 0) {
      updateLiveShowInfoErrors({ ...liveShowInfoError, titleError: '' });
    }
  };

  const { data } = useQuery(GET_FACEBOOK_CHANNELS);

  const allowUnSelectChannel = (e) => {
    if (e.target.checked) return true;

    if (
      e.target.name === constVariables.LiveShow.inputFields.facebookChannels &&
      !liveShowDetailInfo.streamOnApp &&
      !liveShowDetailInfo.streamOnWebsite
    )
      return false;

    let selectedLength = liveShowDetailInfo.facebookChannels.length;
    if (liveShowDetailInfo.streamOnApp) ++selectedLength;
    if (liveShowDetailInfo.streamOnWebsite) ++selectedLength;
    return selectedLength > 1;
  };

  const handleSelectChannel = (e, channel: string) => {
    if (!allowUnSelectChannel(e)) return;

    const updateData = Object.assign({}, liveShowDetailInfo);
    updateData[channel] = e.target.checked;
    updateLiveShowDetailInfo(updateData);
  };

  const handleAllFacebookChannels = (e) => {
    if (!allowUnSelectChannel(e)) return;
    const updateData: LiveShowDetailInfo = Object.assign({}, liveShowDetailInfo);
    if (e.target.checked) {
      updateData.facebookChannels = data?.getFacebookChannels.map((channel) => {
        return { id: channel.id, type: channel.type };
      });
    } else {
      updateData.facebookChannels = [];
    }
    updateLiveShowDetailInfo(updateData);
  };

  const handleFacebookChannel = (e, channel) => {
    if (!allowUnSelectChannel(e)) return;

    const updateData: LiveShowDetailInfo = Object.assign({}, liveShowDetailInfo);
    if (e.target.checked) {
      const updatedChannel = { id: channel.id, type: channel.type };
      updateData.facebookChannels = [...updateData.facebookChannels, updatedChannel];
    } else {
      updateData.facebookChannels = updateData.facebookChannels.filter((item) => item.id !== channel.id);
    }
    updateLiveShowDetailInfo(updateData);
  };
  const [startTimeDropDownDataAm, setStartTimeDropDownDataAm] = useState<{ id: number; name: Date }[]>();
  const [endTimeDropDownDataPm, setEndTimeDropDownDataPm] = useState<{ id: number; name: Date }[]>();

  useEffect(() => {
    if (liveShowDetailInfo.date) {
      const tempAm: { id: number; name: Date }[] = [];
      const tempPm: { id: number; name: Date }[] = [];

      tempAm.push({
        id: 12,
        name: new Date(new Date(liveShowDetailInfo.date).setHours(0, 0))
      });
      tempAm.push({
        id: 124,
        name: new Date(new Date(liveShowDetailInfo.date).setHours(0, 30))
      });
      for (let i = 1; i < 12; i++) {
        tempAm.push({
          id: i,
          name: new Date(new Date(liveShowDetailInfo.date).setHours(i, 0))
        });
        tempAm.push({
          id: i * 12,
          name: new Date(new Date(liveShowDetailInfo.date).setHours(i, 30))
        });
      }
      setStartTimeDropDownDataAm(tempAm);

      tempPm.push({
        id: 0,
        name: new Date(new Date(liveShowDetailInfo.date).setHours(12, 0))
      });
      tempPm.push({
        id: 123,
        name: new Date(new Date(liveShowDetailInfo.date).setHours(12, 30))
      });

      for (let i = 13; i < 24; i++) {
        tempPm.push({
          id: i,
          name: new Date(new Date(liveShowDetailInfo.date).setHours(i, 0))
        });
        tempPm.push({
          id: i * 12,
          name: new Date(new Date(liveShowDetailInfo.date).setHours(i, 30))
        });
      }
      setEndTimeDropDownDataPm(tempPm);
    }
  }, [liveShowDetailInfo.date, liveShowDetailInfo.startingAt, liveShowDetailInfo.endingAt]);

  return (
    <div className="create-live-show-detail-step-div">
      <FlexBox className="content">
        <div className="input-view">
          <div className={`p-7 input-fields ${isComplete ? 'no-border' : ''}`}>
            <div className="w-100">
              <EmojiInput
                isError={liveShowInfoError.titleError !== ''}
                errorMessage={liveShowInfoError.titleError}
                title={constVariables.LiveShow.inputFields.liveShowTitle}
                placeholder={constVariables.LiveShow.inputFields.liveShowTitlePlaceholder}
                isMandatory={true}
                value={liveShowDetailInfo.title}
                showCount={true}
                max={constVariables.emojiInputCountLimit}
                onChangeText={changeLiveShowTitle}
              ></EmojiInput>
            </div>
            {!isComplete && (
              <div className="w-100 date-time-picker">
                <FlexBox className="align-items-center justify-content-between mb-10">
                  <CustomeDatePicker
                    otherClasses="date-class"
                    title={constVariables.LiveShow.inputFields.liveShowDate}
                    defaultDate={liveShowDetailInfo.date}
                    placeholder={constVariables.LiveShow.inputFields.liveShowDatePlaceholder}
                    isMandatory={true}
                    updateDateValue={updateDate}
                  ></CustomeDatePicker>
                  <TimePicker
                    otherClasses="start-time-class ps-3"
                    title={constVariables.LiveShow.inputFields.startTime}
                    placeholder={constVariables.LiveShow.inputFields.liveShowTimePlaceholder}
                    value={liveShowDetailInfo.startingAt}
                    dateValue={liveShowDetailInfo.date}
                    isMandatory={true}
                    isError={!!liveShowInfoError?.timeDurationError || !!liveShowInfoError?.startingAtError}
                    error={liveShowInfoError.startingAtError || liveShowInfoError.timeDurationError}
                    setValue={updateStartTime}
                    validateTimes={validateTimes}
                    isShowTimeSlots={true}
                    comparedDate={new Date()}
                    timeDropDownDataAm={startTimeDropDownDataAm}
                    timeDropDownDataPm={endTimeDropDownDataPm}
                  ></TimePicker>
                  <TimePicker
                    otherClasses="end-time-class ps-3"
                    title={constVariables.LiveShow.inputFields.endTime}
                    placeholder={constVariables.LiveShow.inputFields.liveShowTimePlaceholder}
                    value={liveShowDetailInfo.endingAt}
                    dateValue={liveShowDetailInfo.date}
                    isMandatory={true}
                    isError={liveShowInfoError.timeDurationError !== '' || liveShowInfoError.endingAtError !== ''}
                    error={liveShowInfoError.endingAtError}
                    setValue={updateEndTime}
                    validateTimes={validateTimes}
                    isShowTimeSlots={true}
                    comparedDate={endDateMoment?.toDate()}
                    timeDropDownDataAm={startTimeDropDownDataAm}
                    timeDropDownDataPm={endTimeDropDownDataPm}
                  ></TimePicker>
                </FlexBox>
              </div>
            )}
          </div>
          {!isComplete && (
            <div className="w-100 p-7 channels-section">
              <div className={`poppins-semibold fs-6 mb-3`}>
                {constVariables.LiveShow.inputFields.streamChannel}
                <span className="text-danger fs-4 px-1">*</span>
              </div>
              <>
                {Object.keys(constVariables.LiveShow.inputFields.streamChannels).map((channel, index) => {
                  return (
                    <div className="w-100" key={index}>
                      <Checkbox
                        tagclassname="poppins-medium final-scale-checkbox"
                        labelclassname="pb-3"
                        name={constVariables.LiveShow.inputFields.streamChannels[channel]}
                        value={liveShowDetailInfo[channel]}
                        onChangeValue={(e) => handleSelectChannel(e, channel)}
                        smaller
                        inputclassname="smallerInput"
                      />
                    </div>
                  );
                })}
                {data?.getFacebookChannels && data?.getFacebookChannels?.length > 0 && (
                  <div>
                    <div className="w-100">
                      <Checkbox
                        tagclassname="poppins-medium final-scale-checkbox"
                        labelclassname="pb-3"
                        name={constVariables.LiveShow.inputFields.facebookChannels}
                        value={fbChannelsChecked}
                        onChangeValue={(e) => {
                          handleAllFacebookChannels(e);
                        }}
                        smaller
                        downArrow
                        isRotateDownArrow={showFBChannels}
                        downArrowClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowFBChannels(!showFBChannels);
                        }}
                        inputclassname="smallerInput"
                      />
                    </div>
                    <div className="ps-6">
                      {showFBChannels &&
                        data?.getFacebookChannels?.map((channel, index) => (
                          <div className="w-100" key={index}>
                            <Checkbox
                              tagclassname="poppins-medium final-scale-checkbox"
                              labelclassname="pb-3"
                              name={`${channel.name} ${channel.type ? `(${channel.type})` : ''}`}
                              value={facebookChannels.map((item) => item.id).includes(channel.id)}
                              onChangeValue={(e) => {
                                handleFacebookChannel(e, channel);
                              }}
                              smaller
                              inputclassname="smallerInput"
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                <div className="ps-5">
                  {liveShowInfoError.channelError && (
                    <div className={`text-danger poppins-regular fs-7 mb-3`}>{liveShowInfoError.channelError}</div>
                  )}
                </div>
              </>
            </div>
          )}
        </div>
        <div className="thumbnail-view text-center">
          <Thumbnail
            title={liveShowDetailInfo.title}
            type="liveshow"
            thumbnailUrlData={liveShowDetailInfo.thumbnailUrl}
            hidePlaceholderText={true}
            classes={'thumbnail-title'}
            showDeleteIcon={true}
            placeholderImg={UploadThumbnailIcon}
            removeThumbnail={removeThumbnail}
            setThumbnailUrlData={handleSendSuccessURL}
            uploadeType="LIVE_STREAM_THUMBNAIL"
          />
        </div>
      </FlexBox>
    </div>
  );
};
export default memo(CreateLiveShowDetailStepPresentational);
