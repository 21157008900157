import React, { forwardRef, useImperativeHandle, useState, createRef, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';

// Apollo
import { useMutation, useLazyQuery } from '@apollo/client';
import { DUMP_CART, ADD_LIVE_STORE_CREDIT } from 'src/apollo/mutations';
import { GET_LIVE_VIEWERS } from 'src/apollo/queries';

// Components
import { FlexBox } from 'src/components/atoms';
import { CustomModal, DeleteConfirmModal, AddOrSubtractModal } from 'src/components/oraganisms';
import { Dropdown, Portal } from 'src/components/molecules';
import LiveViewerItem from './LiveViewerItem';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import useWindowDimensions from 'src/utils/UseWindowDimensions/useWindowDimensions';

// Icons
import {
  LiveViewerEye,
  ThreeDotsIcon,
  RemoveCartIcon
  // LiveViewerCart
} from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { ILiveUser } from 'src/components/pages/liveshow/LiveshowManager/LiveshowManager.types';
import { LivsDashboardCustomModalsRefMethods } from 'src/components/pages/liveshow/LiveshowManager/LivsDashboardCustomModals';

// Styles
import './_liveViewers.scss';

interface LiveViewersProps {
  liveShowId: string | null;
  onBlockUser: LivsDashboardCustomModalsRefMethods['onBlockUser'];
  onAddStoreCredit: LivsDashboardCustomModalsRefMethods['onAddStoreCredit'];
  onShowCart: LivsDashboardCustomModalsRefMethods['onShowCart'];
  onDumpCart: LivsDashboardCustomModalsRefMethods['onDumpCart'];
}

export interface LiveViewersRefMethods {
  onUserJoined: (viewersDetailsType: ILiveUser) => void;
  onUserExited: (userId: ILiveUser['id']) => void;
  onUserUpdated: (viewersDetailsType: ILiveUser) => void;
  setLiveViwersCount: React.Dispatch<React.SetStateAction<viewerCountType>>;
  dumpAllUserCarts: () => void;
}

type viewerCountType = {
  mobile: number;
  facebook: number;
};
const PARENT_ID = 'live-viewer-list';
const LiveViewers = forwardRef<LiveViewersRefMethods, LiveViewersProps>(
  ({ liveShowId, onBlockUser, onAddStoreCredit, onShowCart, onDumpCart }, ref) => {
    const menuIcon = createRef<any>();
    const { width } = useWindowDimensions();
    const [visible, setVisible] = useState(false);
    const [viewersList, setViewersList] = useState<ILiveUser[]>([]);
    const [newlyJoinedUser, setNewlyJoinedUser] = useState<string>('');
    const [liveViewerCount, setLiveViewerCount] = useState<viewerCountType>({
      facebook: 0,
      mobile: 0
    });
    const [viewersMap, setViewersMap] = useState<any>({});
    const [showDropDown, setShowDropDown] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isDumpAllSuccess, setIsDumpAllSuccess] = useState(false);
    const [showStoreCredit, setShowStoreCredit] = useState(false);
    const [isStoreSucess, setIsStoreSucess] = useState(false);
    const [menuPositions, setMenuPositions] = useState({
      top: 0,
      left: 0
    });
    const [storeCreditValue, setStoreCreditValue] = useState('');

    const [dumpCart] = useMutation(DUMP_CART, {
      onCompleted: (response) => {
        if (response.dumpCart.success) {
          setIsDumpAllSuccess(true);
        }
      },
      onError: (error) => {
        console.log('error', error);
      }
    });

    const [addLiveStoreCredit] = useMutation(ADD_LIVE_STORE_CREDIT, {
      onCompleted: (response) => {
        if (response.addLiveStoreCredit.success) {
          setIsStoreSucess(true);
        }
      },
      onError: (error) => {
        console.log('error', error);
      }
    });

    const input = {
      liveShowId,
      isMocked: false,
      pageInfo: {
        skipCount: 0,
        limitCount: 20
      }
    };

    const [getLiveViewers] = useLazyQuery(GET_LIVE_VIEWERS, {
      onCompleted: (data) => {
        setViewersList((d) => [...d, ...data?.getViewersDetails]);
      }
    });

    useImperativeHandle(ref, () => ({
      onUserExited: (userId) => {
        if (viewersMap[`${userId}`] > 1) {
          setViewersMap((l) => {
            l[`${userId}`] = l[`${userId}`] - 1;
            return l;
          });
        } else {
          setViewersList(viewersList?.filter(({ id }) => `${id}` !== `${userId}`));
          setViewersMap((l) => {
            delete l[`${userId}`];
            return l;
          });
        }
      },

      onUserJoined: (newViewer) => {
        if (viewersMap[`${newViewer?.id}`]) {
          setViewersMap((l) => {
            l[`${newViewer?.id}`] = l[`${newViewer?.id}`] + 1;
            return l;
          });
        } else {
          setViewersMap((l) => {
            l[`${newViewer?.id}`] = 1;
            return l;
          });

          setViewersList([...viewersList, newViewer]);
          setNewlyJoinedUser(`${newViewer.id}`);

          setTimeout(() => {
            setNewlyJoinedUser('');
          }, 2000);
        }
      },
      onUserUpdated: (viewerData) => {
        handleUpdateViewer(viewerData);
      },

      setLiveViwersCount: setLiveViewerCount,
      dumpAllUserCarts: () => {
        dumpAllCarts();
      }
    }));

    useEffect(() => {
      if (liveShowId) {
        getLiveViewers({
          variables: {
            input
          }
        });
      }
    }, []);

    const dumpAllCarts = () => {
      setViewersList((viewers) => {
        return viewers?.map((viewer) => ({ ...viewer, noOfCartItems: 0 }));
      });
    };

    const handleUpdateViewer = (viewerData) => {
      const currIndex = viewersList?.findIndex(({ id }) => `${id}` === `${viewerData?.id}`);
      if (currIndex > -1) {
        setViewersList([...viewersList.slice(0, currIndex), viewerData, ...viewersList.slice(currIndex + 1)]);
      }
    };

    const openDropDown = (e) => {
      e.stopPropagation();
      setShowDropDown(true);

      if (menuIcon.current) {
        const position = menuIcon.current.getBoundingClientRect();
        const { top, left } = position;
        const spaceBelow = window.innerHeight - position.bottom;
        setMenuPositions({
          top: spaceBelow < 150 ? window.scrollY + top - 150 : window.scrollY + top + 30,
          left: left - 250
        });
      }
    };

    const closeDropDown = () => setShowDropDown(false);

    const selectingMenuItem = (option) => {
      if (option.id === 1) {
        setShowStoreCredit(true);
      }
      if (option.id === 2) {
        setShowConfirmModal(true);
      }
      closeDropDown();
    };

    const handleDumpAllCarts = () => {
      dumpCart({
        variables: {
          input: {
            liveShowId
          }
        }
      });
      setShowConfirmModal(false);
    };

    const handleAddStoreCreditHandler = () => {
      addLiveStoreCredit({
        variables: {
          input: {
            liveShowId,
            amountToAdd: storeCreditValue
          }
        }
      });
      setShowStoreCredit(false);
    };

    const isItemLoaded = (index) => index < viewersList.length;

    function loadMoreRows() {
      getLiveViewers({
        variables: {
          input: {
            ...input,
            pageInfo: {
              skipCount: viewersList.length,
              limitCount: 20
            }
          }
        }
      });
    }

    const [itemHeight, setItemHeight] = useState(53);

    const setRowHeight = (height) => {
      if (itemHeight !== height) {
        setItemHeight(height);
      }
    };

    const closeConfirmModal = () => {
      document.body.style.overflow = 'unset';
      setShowConfirmModal(false);
    };

    const closeCreditModal = () => {
      document.body.style.overflow = 'unset';
      setStoreCreditValue('');
      setShowStoreCredit(false);
    };

    return (
      <>
        <div className="h-100 card p-0 m-0">
          <div
            className="d-flex live-show-viewers-header position-relative justify-content-between p-x-20 p-t-24"
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
          >
            <h4 className="liveView-header liveshow-section-title">Live Viewers</h4>
            <div className={`live-show-viewers-header-more position-absolute ${!visible ? 'hidden' : ''}`} ref={menuIcon}>
              <KTSVG onClick={openDropDown} className="cursor-pointer" path={ThreeDotsIcon} />
            </div>
            {showDropDown && (
              <Portal id="kt_body">
                <OutsideClickHandler onOutsideClick={closeDropDown}>
                  <div className="position-absolute" style={{ top: menuPositions.top, left: menuPositions.left, zIndex: 200 }}>
                    <Dropdown
                      data={constVariables.liveShowViewerHeaderDropdownData}
                      onSelect={selectingMenuItem}
                      selected={showDropDown}
                      className="viewers-action-drop-down"
                      noScroll={true}
                      closeDropdown={() => setShowDropDown(false)}
                    />
                  </div>
                </OutsideClickHandler>
              </Portal>
            )}
          </div>
          <FlexBox className="live-viewers-count-container align-items-center">
            <FlexBox className="align-items-center">
              <img src={LiveViewerEye} alt="live count" className="live-count-icon-total" />
              <span className="live-viewer-count">{liveViewerCount?.mobile + liveViewerCount?.facebook}</span>
            </FlexBox>
            {/* 
            <FlexBox className="align-items-center">
              <img src={LiveViewerPhone} alt="live count app" className="live-count-icon-mobile" />
              <span className="live-viewer-count">{liveViewerCount?.mobile}</span>
            </FlexBox>
            <FlexBox className="align-items-center">
              <img src={LiveViewerFacebook} alt="live count facebook" className="live-count-icon-facebook" />
              <span className="live-viewer-count">{liveViewerCount?.facebook}</span>
            </FlexBox>
          */}
          </FlexBox>

          <div id={PARENT_ID} className="live-viewers-details h-100 w-100">
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              loadMoreItems={loadMoreRows}
              itemCount={viewersList.length + 10}
              threshold={1}
            >
              {({ onItemsRendered, ref }) => (
                <AutoSizer>
                  {({ height, width }) => (
                    <List
                      height={height}
                      onItemsRendered={onItemsRendered}
                      ref={ref}
                      itemCount={viewersList.length}
                      itemSize={itemHeight}
                      width={width}
                      itemData={{
                        viewersList,
                        newlyJoinedUser,
                        onBlockUser,
                        onAddStoreCredit,
                        onShowCart,
                        onDumpCart,
                        setRowHeight
                      }}
                    >
                      {LiveViewerItem}
                    </List>
                  )}
                </AutoSizer>
              )}
            </InfiniteLoader>
          </div>
        </div>

        {/* All Dump Cart Confirm Modal */}
        {showConfirmModal && (
          <CustomModal bodyClassname="w-90 w-md-150" show={showConfirmModal} closeModal={closeConfirmModal}>
            <DeleteConfirmModal
              title={constVariables.LiveShow.dumpAllViewers.title}
              actionBtnTitle="Yes"
              cancelBtnTitle="No"
              cancelBtnHandler={closeConfirmModal}
              actionBtnHandler={handleDumpAllCarts}
              deleteIcon={RemoveCartIcon}
            ></DeleteConfirmModal>
          </CustomModal>
        )}

        {/* Add Store Credit Modal */}
        {showStoreCredit && (
          <CustomModal bodyClassname="w-90 w-md-150" show={showStoreCredit} closeModal={closeCreditModal}>
            <AddOrSubtractModal
              title="Add store credit"
              message="Enter store credit value to be added to Selected viewers"
              value={storeCreditValue}
              setValue={setStoreCreditValue}
              actionBtnTitle="Add"
              cancelBtnTitle="Cancel"
              actionBtnHandler={handleAddStoreCreditHandler}
              cancelBtnHandler={closeCreditModal}
            />
          </CustomModal>
        )}
      </>
    );
  }
);

export default LiveViewers;
