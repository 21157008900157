import { gql } from '@apollo/client';

export const GET_ROLES = gql`
  query GetRoles @api(name: "authAPI") {
    getRoles {
      __typename
      roles {
        __typename
        id
        role
        roleDisplayName
        permissions {
          section
          permissions
        }
        canBeNotifiedWhenOrderPlaced
      }
    }
  }
`;
