import { FC } from 'react';
import clsx from 'clsx';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { FlexBox, RadioButton } from 'src/components/atoms';
import { Checkbox, Dropdown, InputBox } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import getDeviceData from 'src/utils/UseDevice/useDevice';

// Icons
import { CloseIcon, DownArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { AddWarehousePresentationalProps } from './AddCustomerAddress.types';

// Styles
import './_addWarehouse.scss';

const AddCustomerAddressPresentational: FC<AddWarehousePresentationalProps> = ({
  show,
  isEdit,
  customerAddressData,
  countryListFiltered,
  cityListFiltered,
  stateListFiltered,
  phoneList,
  addCustomerAddress,
  addReciverName,
  disableSaveButton,
  closeAddressModal,
  changeAddressLineOne,
  changeAddressLineTwo,
  changePostalCode,
  changeCountry,
  changeCity,
  changeState,
  changePhoneNumber,
  toggleCountryCodeDropDown,
  onSelectCountryCode,
  toggleCityDropDown,
  onSelectCity,
  toggleStateDropDown,
  onSelectState,
  togglePhoneDropDown,
  onSelectPhone,
  toggleAddressDropDownOne,
  toggleAddressDropDownTwo,
  addressListFiltered,
  onSelectAddress,
  setCustomerAddressData,
  addEmailAddress,
  changeAddressType,
  toggleDefaultShippingAddress
}) => {
  const deviceData = getDeviceData();

  const setHomeAddress = () => {
    changeAddressType('HOME');
  };
  const setOfficeAddress = () => {
    changeAddressType('WORK');
  };
  const setOtherAddress = () => {
    changeAddressType('OTHERS');
  };

  return (
    <>
      {show && (
        <div
          className={clsx('add-vendor modal fade opacity-100', {
            show
          })}
          aria-modal="true"
          tabIndex={-1}
          id="kt_modal_1"
        >
          <div className={`modal-dialog ${deviceData.isWeb ? 'flex-center' : 'w-90 w-md-75'} h-94vh`}>
            <div className={`modal-content ${deviceData.isWeb ? 'w-900px' : ''} h-90`}>
              <div className="modal-header">
                <h5 className="modal-title">
                  {isEdit && isEdit
                    ? constVariables.customerDetails.addCustomerAddress.titileTwo
                    : constVariables.customerDetails.addCustomerAddress.title}
                </h5>
                <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={closeAddressModal}>
                  <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
                </div>
              </div>
              <div className="p-7 border-bottom border-bottom-light">
                <div className="d-block d-md-flex mt-5">
                  <div
                    className="me-md-8 w-100 w-md-50"
                    style={{
                      paddingRight: '1rem'
                    }}
                  >
                    <div className="text-nowrap poppins-semibold fs-6 mb-2">
                      {constVariables.customerDetails.addCustomerAddress.receiverName}
                    </div>
                    <InputBox
                      inputClass="form-control-lg"
                      value={customerAddressData?.reciverName}
                      onChangeText={addReciverName}
                      name={constVariables.customerDetails.addCustomerAddress.receiverName}
                      placeholder={constVariables.customerDetails.addCustomerAddress.receiverNamePH}
                    />
                  </div>
                </div>

                {/* Phone number and Address Type */}

                <div className=" d-block d-md-flex mt-5">
                  <div className="position-relative me-md-4 w-100 w-md-50">
                    {' '}
                    <div className="d-flex">
                      <div className="text-nowrap poppins-semibold fs-6 mb-2">
                        {constVariables.customerDetails.addCustomerAddress.phone}
                      </div>
                    </div>
                    <div className="d-flex">
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          setCustomerAddressData((prev) => ({
                            ...prev,
                            phoneDropDown: false
                          }));
                        }}
                      >
                        <FlexBox
                          className={`right-border-flat form-control form-control-lg  justify-content-between align-items-center cursor-pointer dropdown-box`}
                          onClick={togglePhoneDropDown}
                        >
                          <div className="helper-text-one">
                            {customerAddressData?.selectedPhone ? customerAddressData?.selectedPhone : 'Select'}
                          </div>
                          <div>
                            <KTSVG path={DownArrowIcon} />
                          </div>
                        </FlexBox>
                      </OutsideClickHandler>
                      <InputBox
                        mainclass="col"
                        inputClass="form-control-lg left-border-flat"
                        value={customerAddressData.phoneNumber}
                        onChangeText={changePhoneNumber}
                        name={constVariables.customerDetails.addCustomerAddress.phone}
                        placeholder={constVariables.customerDetails.addCustomerAddress.phonePH}
                        charLimit={15}
                      />
                    </div>
                    {phoneList && phoneList.length > 0 && (
                      <Dropdown
                        className="dropdown-custom-width"
                        data={phoneList}
                        selected={customerAddressData?.phoneDropDown}
                        value={customerAddressData?.selectedPhone}
                        onSelect={onSelectPhone}
                        closeDropdown={() =>
                          setCustomerAddressData((prev) => ({
                            ...prev,
                            phoneDropDown: false
                          }))
                        }
                      />
                    )}
                  </div>
                  <div className="ms-md-4 w-100 w-md-50">
                    <div className="text-nowrap poppins-semibold fs-6 mb-2">
                      {constVariables.customerDetails.addCustomerAddress.addressType}
                    </div>
                    <div className="form-control form-control-lg border-0 px-0 align-items-center d-flex">
                      <div className="d-flex align-items-center">
                        <RadioButton
                          id="customerHomeAddress"
                          name="customerHomeAddress"
                          isChecked={customerAddressData?.addressType?.toUpperCase() === 'HOME'}
                          handleChange={setHomeAddress}
                          noMargin
                        />
                        <div className="ms-2 poppins-medium fs-6">Home</div>
                      </div>
                      <div className="ms-2 d-flex align-items-center">
                        <RadioButton
                          id="customerOfficeAddress"
                          name="customerOfficeAddress"
                          isChecked={customerAddressData?.addressType?.toUpperCase() === 'WORK'}
                          handleChange={setOfficeAddress}
                        />
                        <div className="ms-2 poppins-medium fs-6">Office</div>
                      </div>
                      <div className="ms-2 d-flex align-items-center">
                        <RadioButton
                          id="customerOtherAddress"
                          name="customerOtherAddress"
                          isChecked={customerAddressData?.addressType?.toUpperCase() === 'OTHERS'}
                          handleChange={setOtherAddress}
                        />
                        <div className="ms-2 poppins-medium fs-6">Other</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" overflow-scroll border-bottom border-bottom-light p-0 ">
                <div className="p-7 pt-0">
                  <h5 className="mt-6 mb-2 fs-4 poppins-regular">
                    {constVariables.customerDetails.addCustomerAddress.shippingAddress}
                  </h5>
                  <div className="container px-0 ">
                    {/* Country and Address One */}
                    <div className="d-block d-md-flex mt-5">
                      <div className="position-relative me-md-4 w-100 w-md-50">
                        <div className="text-nowrap poppins-semibold fs-6 mb-2">
                          {constVariables.customerDetails.addCustomerAddress.address}
                        </div>
                        <div onClick={toggleAddressDropDownOne}>
                          <InputBox
                            inputClass="form-control-lg"
                            value={customerAddressData?.addressLineOne}
                            onChangeText={changeAddressLineOne}
                            name={constVariables.customerDetails.addCustomerAddress.address}
                            placeholder={constVariables.customerDetails.addCustomerAddress.addressPH}
                            // warningText={
                            //   customerAddressData?.addressError && customerAddressData?.addressLineOne
                            //     ? constVariables.customerDetails.addCustomerAddress.addressNotVerified
                            //     : ''
                            // }
                            // warningTextIcon
                          />
                        </div>

                        {addressListFiltered &&
                          customerAddressData?.addressLineOne?.length > 0 &&
                          addressListFiltered?.length > 0 && (
                            <OutsideClickHandler
                              onOutsideClick={() => {
                                if (customerAddressData.addressDropDownOne) {
                                  toggleAddressDropDownOne();
                                }
                              }}
                            >
                              <Dropdown
                                className="dropdown-custom-width"
                                data={addressListFiltered}
                                selected={customerAddressData.addressDropDownOne}
                                value={customerAddressData?.addressLineOne}
                                onSelect={onSelectAddress}
                                closeDropdown={() =>
                                  setCustomerAddressData((prev) => ({
                                    ...prev,
                                    addressDropDownOne: false
                                  }))
                                }
                              />
                            </OutsideClickHandler>
                          )}
                      </div>
                      <div className="position-relative ms-md-4 w-100 w-md-50">
                        <div className="text-nowrap poppins-semibold fs-6 mb-2">
                          {constVariables.customerDetails.addCustomerAddress.apartment}
                        </div>
                        <div onClick={toggleAddressDropDownTwo}>
                          <InputBox
                            inputClass="form-control-lg"
                            value={customerAddressData?.addressLineTwo}
                            onChangeText={changeAddressLineTwo}
                            name={constVariables.customerDetails.addCustomerAddress.apartment}
                            placeholder={constVariables.customerDetails.addCustomerAddress.apartmentPH}
                          />
                        </div>

                        {addressListFiltered &&
                          customerAddressData?.addressLineTwo?.length > 0 &&
                          addressListFiltered?.length > 0 && (
                            <OutsideClickHandler
                              onOutsideClick={() => {
                                if (customerAddressData.addressDropDownTwo) {
                                  toggleAddressDropDownTwo();
                                }
                              }}
                            >
                              <Dropdown
                                className="dropdown-custom-width"
                                data={addressListFiltered}
                                selected={customerAddressData.addressDropDownTwo}
                                value={customerAddressData?.addressLineOne}
                                onSelect={onSelectAddress}
                                closeDropdown={() =>
                                  setCustomerAddressData((prev) => ({
                                    ...prev,
                                    addressDropDownTwo: false
                                  }))
                                }
                              />
                            </OutsideClickHandler>
                          )}
                      </div>
                    </div>

                    {/* Address Two and postal code */}
                    <div className="d-block d-md-flex mt-5">
                      <div className="me-md-4 w-100 w-md-50">
                        <div className="text-nowrap poppins-semibold fs-6 mb-2">
                          {constVariables.customerDetails.addCustomerAddress.city}
                        </div>
                        <OutsideClickHandler
                          onOutsideClick={() => {
                            setCustomerAddressData((prev) => ({
                              ...prev,
                              cityDropDown: false
                            }));
                          }}
                        >
                          <div className="position-relative">
                            <FlexBox
                              className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box `}
                              onClick={toggleCityDropDown}
                            >
                              <InputBox
                                value={customerAddressData?.selectedCity}
                                onChangeText={changeCity}
                                name={constVariables.customerDetails.addCustomerAddress.city}
                                placeholder={constVariables.customerDetails.addCustomerAddress.cityPH}
                                noBorders
                              />
                              <KTSVG path={DownArrowIcon} />
                            </FlexBox>

                            {cityListFiltered && cityListFiltered?.length > 0 && (
                              <Dropdown
                                className="dropdown-custom-width"
                                data={cityListFiltered}
                                selected={customerAddressData?.cityDropDown}
                                value={customerAddressData?.selectedCity}
                                onSelect={onSelectCity}
                                closeDropdown={() =>
                                  setCustomerAddressData((prev) => ({
                                    ...prev,
                                    cityDropDown: false
                                  }))
                                }
                              />
                            )}
                          </div>
                        </OutsideClickHandler>
                      </div>
                      <div className="ms-md-4 w-100 w-md-50">
                        <div className="text-nowrap poppins-semibold fs-6 mb-2">
                          {constVariables.customerDetails.addCustomerAddress.state}
                        </div>
                        <OutsideClickHandler
                          onOutsideClick={() => {
                            setCustomerAddressData((prev) => ({
                              ...prev,
                              stateDropDown: false
                            }));
                          }}
                        >
                          <div className="position-relative">
                            <FlexBox
                              className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box `}
                              onClick={toggleStateDropDown}
                            >
                              <InputBox
                                value={customerAddressData?.selectedState}
                                onChangeText={changeState}
                                name={constVariables.customerDetails.addCustomerAddress.state}
                                placeholder={constVariables.customerDetails.addCustomerAddress.statePH}
                                noBorders
                              />
                              <KTSVG path={DownArrowIcon} />
                            </FlexBox>

                            {stateListFiltered && stateListFiltered?.length > 0 && (
                              <Dropdown
                                className="dropdown-custom-width"
                                data={stateListFiltered}
                                selected={customerAddressData?.stateDropDown}
                                value={customerAddressData?.selectedState}
                                onSelect={onSelectState}
                                closeDropdown={() =>
                                  setCustomerAddressData((prev) => ({
                                    ...prev,
                                    stateDropDown: false
                                  }))
                                }
                              />
                            )}
                          </div>
                        </OutsideClickHandler>
                      </div>
                    </div>

                    {/* State city */}
                    <div className="d-block d-md-flex mt-5">
                      <div className="me-md-4 w-100 w-md-50">
                        <div className="text-nowrap poppins-semibold fs-6 mb-2">
                          {constVariables.customerDetails.addCustomerAddress.postalCode}
                        </div>
                        <InputBox
                          inputClass="form-control-lg"
                          value={customerAddressData?.postalCode}
                          onChangeText={changePostalCode}
                          name={constVariables.customerDetails.addCustomerAddress.postalCode}
                          placeholder={constVariables.customerDetails.addCustomerAddress.postalCodePH}
                          charLimit={10}
                        />
                      </div>
                      <div className="ms-md-4 w-100 w-md-50">
                        <div className="text-nowrap poppins-semibold fs-6 mb-2">
                          {constVariables.customerDetails.addCustomerAddress.country}
                        </div>
                        <OutsideClickHandler
                          onOutsideClick={() => {
                            setCustomerAddressData((prev) => ({
                              ...prev,
                              countryDropDown: false
                            }));
                          }}
                        >
                          <div className="position-relative">
                            <FlexBox
                              className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box `}
                              onClick={toggleCountryCodeDropDown}
                            >
                              <InputBox
                                value={customerAddressData?.selectedCountry}
                                onChangeText={changeCountry}
                                name={constVariables.customerDetails.addCustomerAddress.country}
                                placeholder={constVariables.customerDetails.addCustomerAddress.countryPH}
                                noBorders
                              />
                              <KTSVG path={DownArrowIcon} />
                            </FlexBox>
                            {countryListFiltered && countryListFiltered.length > 0 && (
                              <Dropdown
                                className="dropdown-custom-width"
                                data={countryListFiltered}
                                selected={customerAddressData?.countryDropDown}
                                value={customerAddressData?.selectedCountryCode}
                                onSelect={onSelectCountryCode}
                                closeDropdown={() =>
                                  setCustomerAddressData((prev) => ({
                                    ...prev,
                                    countryDropDown: false
                                  }))
                                }
                              />
                            )}
                          </div>
                        </OutsideClickHandler>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 d-flex align-items-center">
                    <Checkbox
                      tagclassname="poppins-medium  final-scale-checkbox"
                      labelclassname="pb-3 mt-2"
                      value={customerAddressData?.defaultShippingAddress}
                      onChangeValue={toggleDefaultShippingAddress}
                      smaller
                      inputclassname="smallerInput"
                    />
                    <div className="ms-2 poppins-medium fs-6">
                      {constVariables.customerDetails.addCustomerAddress.defaultShippingAddress}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-outlined-secondary btn-md" onClick={closeAddressModal}>
                  {constVariables.customerDetails.addCustomerAddress.cancel}
                </button>
                <button
                  disabled={
                    !customerAddressData.reciverName ||
                    !customerAddressData.addressLineOne ||
                    !customerAddressData.postalCode ||
                    !customerAddressData.selectedCountry ||
                    !customerAddressData.selectedState ||
                    !customerAddressData.selectedCity ||
                    !customerAddressData.addressType ||
                    disableSaveButton
                  }
                  type="button"
                  className="btn btn-primary btn-md"
                  onClick={addCustomerAddress}
                >
                  {constVariables.customerDetails.addCustomerAddress.saveAddress}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddCustomerAddressPresentational;
