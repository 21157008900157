import { gql } from '@apollo/client';

export const GET_STORE_USERS = gql`
  query GetBackStoreUsers($input: GetBackStoreUsersInut) @api(name: "authAPI") {
    getBackStoreUsers(input: $input) {
      __typename
      totalUsers
      users {
        __typename
        storeUserId
        email
        phoneNo
        isEmailVerified
        createdAt
        updatedAt
        firstName
        storeId
        signupSource
        isPhoneNoVerified
        lastName
        medium
        tenantId
        isVerifiedOnce
        invitationStatus
        invitedOn
        invitationAcceptedOn
        invitedBy
        notifyWhenOrderPlaced
        lastLoggedinDate
        role
        roleDisplayName
        permissions {
          section
          permissions
        }
        canBeNotifiedWhenOrderPlaced
        profileUrl
      }
    }
  }
`;
