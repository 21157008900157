import { FC, useMemo } from 'react';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { SettingsBlock } from 'src/components/molecules';
import { PageHeader } from 'src/components/oraganisms';
import { Loader } from 'src/components/atoms';

// Hooks && Utils && Helpers
import useTitle from 'src/utils/hooks/useTitle';

// ConstVariables
import { constVariables } from 'src/constants';
import SettingsScreenData from './SettingsScreenData';

// Types
import { UserRoles } from './UserPermissions/UserPermissions.type';
import useSettingsCacher from 'src/utils/hooks/useSettingsCacher';

// import { KTSVG } from 'src/helpers';
// import { FilterIcon } from 'src/assets/icons';

const SettingsScreen: FC = () => {
  useTitle('Settings');
  useSettingsCacher();
  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const settingsData = useMemo(() => {
    if (role === UserRoles.ADMIN) {
      return SettingsScreenData.filter((dataItem) => dataItem.isAccessFree);
    }
    return SettingsScreenData;
  }, [role]);

  return (
    <div className="width-animation w-100 d-flex flex-column hide-scrollbar details-page p-y-40">
      <div className="details-page-container">
        <PageHeader pageTitle={constVariables.SettingsScreen.settings} removeExportImport={true} />
        <div className="row p-0">
          {settingsData.map((data, index) => (
            <SettingsBlock blockData={data} key={index} />
          ))}
        </div>
      </div>
      {/* <div className="card p-3">
        <div className="mt-2">
          <button className="btn btn-outlined-secondary btn-lg me-1">
            Button Text
            <Loader type='button' className='h-20px w-20px' />            
          </button>
          <button className="btn btn-outlined-primary btn-lg me-1">
            Button Text
            <Loader type='button' className='h-20px w-20px' />
          </button>
          <button className="btn btn-primary btn-lg me-1">
            Button Text
            <Loader type='button' className='h-20px w-20px' />
          </button>
          <button className="btn btn-secondary btn-lg me-1">
            Button Text
            <Loader type='button' className='h-20px w-20px' />
          </button>
          <button className="btn btn-success btn-lg me-1">
            Button Text
            <Loader type='button' className='h-20px w-20px' />
          </button>
          <button className="btn btn-warning btn-lg me-1">
            Button Text
            <Loader type='button' className='h-20px w-20px' />
          </button>
          <button className="btn btn-danger btn-lg me-1">
            Button Text
            <Loader type='button' className='h-20px w-20px' />
          </button>
          <button className="btn btn-danger btn-lg me-1" disabled>
            Button Text
            <Loader type='button' className='h-20px w-20px' />
          </button>
        </div>
        <div className="mt-2">
          <button className="btn btn-lg btn-icon-text btn-flex me-1">
            <KTSVG path={FilterIcon} className="me-2" />
            <span className="me-0">Button</span>
          </button>
          <button className="btn btn-outlined-secondary btn-lg me-1">Button</button>
          <button className="btn btn-outlined-primary btn-lg me-1">Button</button>
          <button className="btn btn-primary btn-lg me-1">Button</button>
          <button className="btn btn-secondary btn-lg me-1">Button</button>
          <button className="btn btn-success btn-lg me-1">Button</button>
          <button className="btn btn-warning btn-lg me-1">Button</button>
          <button className="btn btn-danger btn-lg me-1">Button</button>
          <button className="btn btn-danger btn-lg me-1" disabled>
            Button
          </button>          
        </div>
        <div className="mt-2">
          <button className="btn btn-md btn-icon-text btn-flex align-items-center me-1">
            <KTSVG path={FilterIcon} className="me-2" />
            <span className="me-0">Button</span>
          </button>
          <button className="btn btn-outlined-secondary btn-md me-1">Button</button>
          <button className="btn btn-outlined-primary btn-md me-1">Button</button>
          <button className="btn btn-primary btn-md me-1">Button</button>
          <button className="btn btn-secondary btn-md me-1">Button</button>
          <button className="btn btn-success btn-md me-1">Button</button>
          <button className="btn btn-warning btn-md me-1">Button</button>
          <button className="btn btn-danger btn-md me-1">Button</button>
          <button className="btn btn-danger btn-md me-1" disabled>
            Button
          </button>
        </div>
        <div className="mt-2">
          <button className="btn btn-sm btn-icon-text btn-flex align-items-center me-1">
            <KTSVG path={FilterIcon} className="me-2" />
            <span className="me-0">Button</span>
          </button>
          <button className="btn btn-outlined-secondary btn-sm me-1">Button</button>
          <button className="btn btn-outlined-primary btn-sm me-1">Button</button>
          <button className="btn btn-primary btn-sm me-1">Button</button>
          <button className="btn btn-secondary btn-sm me-1">Button</button>
          <button className="btn btn-success btn-sm me-1">Button</button>
          <button className="btn btn-warning btn-sm me-1">Button</button>
          <button className="btn btn-danger btn-sm me-1">Button</button>
          <button className="btn btn-danger btn-sm me-1" disabled>
            Button
          </button>
        </div>
        <div className="mt-2">
          <button className="btn btn-xs btn-icon-text btn-flex align-items-center me-1">
            <KTSVG path={FilterIcon} className="me-2" />
            <span className="me-0">Button</span>
          </button>
          <button className="btn btn-outlined-secondary btn-xs me-1">Button</button>
          <button className="btn btn-outlined-primary btn-xs me-1">Button</button>
          <button className="btn btn-primary btn-xs me-1">Button</button>
          <button className="btn btn-secondary btn-xs me-1">Button</button>
          <button className="btn btn-success btn-xs me-1">Button</button>
          <button className="btn btn-warning btn-xs me-1">Button</button>
          <button className="btn btn-danger btn-xs me-1">Button</button>
          <button className="btn btn-danger btn-xs me-1" disabled>
            Button
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default SettingsScreen;
