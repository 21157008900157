import { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { WARMUPLIVEDASHBOARD } from 'src/apollo/queries/warmupLiveDashboard';

export const useWarmupLiveDashboard = () => {
  const [nextCallTimeout, setNextCallTimeout] = useState<NodeJS.Timeout>();
  const [nextScheduledAt, setNextScheduledAt] = useState<number>();
  const { data, refetch } = useQuery(WARMUPLIVEDASHBOARD);

  useEffect(() => {
    const clearTimeoutEffect = () => {
      if (nextCallTimeout) {
        clearTimeout(nextCallTimeout);
      }
    };
    if (!nextScheduledAt || nextScheduledAt <= Date.now()) {
      if (data && data?.warmupLiveDashboard) {
        setNextScheduledAt(data?.warmupLiveDashboard);
        const currentTime = new Date().getTime();
        const timestampTime = +data?.warmupLiveDashboard;
        const timeUntilNextCall = timestampTime - currentTime;
        const timeout = setTimeout(() => {
          refetch();
        }, timeUntilNextCall);

        setNextCallTimeout(timeout);
      }
    }
    return () => clearTimeoutEffect();
  }, [data, refetch]); // Re-run whenever `data` or `refetch` changes

  return {};
};
