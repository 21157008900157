import React, { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

// Appollo
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_PRODUCTS_V3, GET_SHOPIFY_ADMIN_BASE_URL } from 'src/apollo/queries';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { CustomModal, DiscardModal, AddingProductsModal } from 'src/components/oraganisms';
import { SearchBar, CatalogSortDropdown, FixedHeaderTable, Tooltip, ShopifySyncTooltip } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { KTSVG } from 'src/helpers';
import { SortIds } from './data';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';
import { useToast } from 'src/utils/hooks/useToast';

// Icons
import { DefaultImage, SortIcon, ShopifyIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

// Types
import { RowObjectProps, TablesProps } from '../Table/Table.types';
import { getProductInputType } from 'src/components/pages/products/ProductPage/productPage.types';
import { ProductDetailType } from 'src/components/oraganisms/BulkEditorModal/BulkEditorModal.types';
import { RulesEngineProductListTypes } from './RulesEngineProducts.types';
import { getProductFilterInput } from 'src/utils/convertProductAPIInput';
// Styles
import './_rulesEngineProducts.scss';

interface queryResult {
  getAllProductsV3: {
    totalProducts: number;
    products: any;
  };
}

const MAX_FETCH_LIMIT = 800;

let chunked = [];
const getChunkedArray = (array, chunkSize) => {
  if (chunked.length > 0) {
    return chunked;
  }
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    // @ts-ignore
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

const RulesEngineProducts: React.FC<RulesEngineProductListTypes> = ({
  type,
  productListIds,
  selectedProductsIds,
  setProductListData,
  isAutoType,
  hasConditions,
  removeProducts,
  setFormChanges,
  detailId
}) => {
  const { showToast } = useToast();
  const [allProducts, setAllProducts] = useState<ProductDetailType[]>([]);
  const [showRemove, setShowRemove] = useState(false);
  const [isLoadingMoreProducts, setIsLoadingMoreProducts] = useState<boolean>(false);
  const [sortBy, setSortby] = useState({
    column: 'NAME',
    order: 'ASC',
    id: 619,
    displayName: 'Product'
  });
  const [totalProducts, setTotalProducts] = useState(0);
  const [openSortDropdown, setOpenSortDropdown] = useState(false);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const getSortColumnId = (column: string): number => {
    switch (column) {
      case 'NAME':
        return 21;
      case 'QUANTITY':
        return 22;
      case 'PRICE':
        return 28;
      case 'STATUS':
        return 25;
      default:
        return 21;
    }
  };

  const [getProductInput, setProductInput] = useState<getProductInputType>({
    productIds: productListIds.length > 0 ? productListIds : [],
    sortBy: {
      fieldID: getSortColumnId(sortBy.column),
      type: sortBy.order
    },
    filters: {
      searchText: '',
      variantTypeIDs: [],
      PriceRange: {
        min: null,
        max: null
      },
      filterStatus: [],
      categoryIds: [],
      vendorIDs: [],
      status: null,
      tags: [],
      collections: [],
      productTypes: []
    },
    pageInfo: {
      limitCount: 10,
      skipCount: 0
    },
    detailed: false
  });

  useEffect(() => {
    resetHasMore();
    if (productListIds?.length > 0) {
      chunked = [];
      setProductInput((productInput) => {
        return { ...productInput, productIds: productListIds };
      });
    }

    if (productListIds?.length === 0) {
      chunked = [];
      setAllProducts([]);
      setTotalProducts(0);
    }
  }, [productListIds]);

  useEffect(() => {
    if (productListIds?.length > 0) {
      //TOOD load more will not update getProductInput
      resetHasMore();
      const processedInput = getProductFilterInput({
        ...getProductInput
      });
      fetchProductsInChunks(processedInput);
    }
  }, [getProductInput]);

  useEffect(() => {
    setProductInput((productInput) => {
      return {
        ...productInput,
        sortBy: {
          fieldID: getSortColumnId(sortBy.column),
          type: sortBy.order
        }
      };
    });
  }, [sortBy]);

  const { data: { shopifyAdminBaseUrl } = { shopifyAdminBaseUrl: null } } = useQuery(GET_SHOPIFY_ADMIN_BASE_URL);

  const [getAllProducts, { data: products, loading: isLoading, fetchMore }] = useLazyQuery(GET_PRODUCTS_V3, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network'
    // onCompleted: (res) => {
    //   if (res?.getAllProductsV3) {
    //     setAllProducts(res?.getAllProductsV3?.products);
    //     setTotalProducts(res.getAllProductsV3?.totalProducts);
    //   }
    //
    //   if (totalProducts === res?.getAllProductsV3?.products?.length) {
    //     setIsLoadingMoreProducts(false);
    //   }
    // }
  });

  const fetchProductsInChunks = async (getProductInput) => {
    const chunks = getChunkedArray(productListIds, MAX_FETCH_LIMIT);

    const allResults = [];
    let total = 0;
    for (const chunk of chunks) {
      try {
        const processedInput = getProductFilterInput({
          ...getProductInput,
          productIds: chunk
        });
        const { data: res } = await getAllProducts({
          variables: {
            input: processedInput
          }
        });

        if (res?.getAllProductsV3) {
          // @ts-ignore
          allResults.push(...res.getAllProductsV3.products);
          total = total + res.getAllProductsV3?.totalProducts;
        }
      } catch (fetchError) {
        showToast({
          errorText: 'Error occured while fetching products in chunk',
          message: `Error occured while fetching products in chunk`
        });
      }
    }

    if (getProductInput.pageInfo.skipCount > 0) {
      setAllProducts([...allProducts, ...allResults]);
    } else {
      setAllProducts([...allResults]);
    }

    if (allResults.length === 0) {
      setHasMore(false);
    }
    setTotalProducts(total);
    setIsLoadingMoreProducts(false);
  };

  const tableData: TablesProps = useMemo(() => {
    return {
      isCatalog: true,
      sortByCategory: sortBy.column,
      sortByType: sortBy.order,
      isDragDisabled: true,
      headerClassName: 'catalog-header bg-light',
      className: 'overflow-visible',
      categoryInfo: {
        title: type,
        isAuto: isAutoType,
        hasConditions
      }
    };
  }, [sortBy]);

  const ChangeSortBy = (field: string, id: number, displayName: string) => {
    if (sortBy.column === field) {
      if (sortBy.order === 'DESC') {
        setSortby({ ...sortBy, order: 'ASC' });
      } else {
        setSortby({ ...sortBy, order: 'DESC' });
      }
    } else {
      setSortby({ column: field, id, displayName, order: 'DESC' });
    }
    setOpenSortDropdown(false);
  };

  tableData.headers = [
    {
      id: 1,
      className: `align-middle w-40px table-header-background px-2 py-0 ${isAutoType ? 'd-none' : ''}`,
      colName: (
        <div className="ms-2 form-check form-check-sm form-check-custom">
          <input
            className=" form-check-input widget-9-check"
            type="checkbox"
            checked={allProducts?.length > 0 && allProducts?.length === selectedProductsIds.length}
            onChange={() => {
              if (allProducts?.length === selectedProductsIds.length) {
                setProductListData((prev) => ({
                  ...prev,
                  selectedProductsIds: []
                }));
              } else {
                selectProduct('All');
              }
            }}
          />
        </div>
      )
    },
    {
      id: 2,
      className: `align-middle p-0 border-bottom-0 table-header-background ps-5 ${isAutoType ? 'w-340px' : 'w-300px'}`,
      colName: 'Product',
      sorting: true,
      applySort: () => {
        ChangeSortBy('NAME', 619, 'Product');
      },
      category: 'name'
    },
    {
      id: 3,
      className: 'align-middle p-0 pe-3 border-bottom-0 table-header-background',
      colName: 'Inventory',
      sorting: true,
      applySort: () => {
        ChangeSortBy('QUANTITY', 620, 'Inventory');
      },
      category: 'quantityInStock'
    },
    {
      id: 4,
      className: 'align-middle p-0 ps-1 border-bottom-0 table-header-background',
      colName: 'Price',
      sorting: true,
      applySort: () => {
        ChangeSortBy('PRICE', 622, 'Price');
      },
      category: 'salePrice'
    },
    {
      id: 5,
      className: 'align-middle border-bottom-0 table-header-background',
      colName: 'Status',
      sorting: true,
      applySort: () => {
        ChangeSortBy('STATUS', 623, 'Status');
      }
    }
  ];

  const arr: Array<RowObjectProps> = []; // DECLARING ARRAY
  const selectProductStatusClass = (productStatus: string | undefined) => {
    switch (productStatus) {
      case constVariables.ProductsTab.active.toLowerCase():
        return 'active';
      case constVariables.ProductsTab.draft.toLowerCase():
        return 'draft';
      default:
        return 'archive';
    }
  };

  const renderProductStatus = (productStatus) => {
    return constVariables.statusDropdownData.find((status) => {
      if (productStatus === 'ARCHIVED') {
        return status.name.toLowerCase() === 'archive';
      }
      return status.name.toLowerCase() === productStatus?.toLowerCase();
    })?.name;
  };

  const selectProduct = (e: any, product?: any) => {
    if (e === 'All') {
      if (allProducts) {
        const tempArr: Array<number> = allProducts.map((product) => product.productId);

        setProductListData((prev) => ({
          ...prev,
          selectedProductsIds: tempArr
        }));
      }
    } else if (e.target.checked) {
      const arr = selectedProductsIds.concat(parseInt(product.productId, 10));

      setProductListData((prev) => ({
        ...prev,
        selectedProductsIds: arr
      }));
    } else {
      const changeArray = selectedProductsIds.filter((id) => {
        return id !== product.productId;
      });

      setProductListData((prev) => ({
        ...prev,
        selectedProductsIds: changeArray
      }));
    }
  };

  const locationState = useMemo(() => {
    if (detailId) {
      if (type === 'collection') {
        return `/products/collections/edit/${detailId}`;
      }
      if (type === 'catalog') {
        return `/marketing/catalogs/edit/${detailId}`;
      }
      if (type === 'coupon') {
        return `/coupons/createCoupon/${detailId}`;
      }
    } else {
      return '/marketing/featuredProducts';
    }
  }, [type, detailId]);

  const goToShopifyPage = (shopifyProductId) => {
    if (shopifyAdminBaseUrl) window.open(`${shopifyAdminBaseUrl}products/${shopifyProductId}`, '_blank');
  };

  // MAPPING THROUGH DATA
  allProducts?.map((product, index) => {
    const cells = [
      {
        className: `align-middle w-40px px-2 py-0 ${index !== 0 ? 'table-top-border' : 'border-0'} ${isAutoType ? 'd-none' : ''}`,
        value: (
          <div className="form-check form-check-sm form-check-custom ms-2">
            <input
              className="me-5 form-check-input widget-9-check"
              type="checkbox"
              checked={selectedProductsIds.includes(product.productId)}
              onChange={(e) => {
                selectProduct(e, product);
              }}
              onClick={(e) => {
                selectProduct(e, product);
              }}
            />
          </div>
        )
      },
      {
        className: `align-middle ps-5 py-0 product-name-cell ${index !== 0 ? 'table-top-border' : 'border-0'} ${
          isAutoType ? 'w-340px' : 'w-300px'
        }`,
        value: (
          <FlexBox className="align-items-center">
            <div className="symbol m-r-20">
              <Img
                src={setImageSrc(
                  product?.productMediasJson
                    ? generateCompressedImageURL(product?.productMediasJson[0]?.productMediaUrl, '50')
                    : DefaultImage
                )}
                placeholderImg={DefaultImage}
                errorImg={DefaultImage}
                className="object-fit-scale-down bg-white border border-light border-2"
              />
              {product?.externalProductProvider === 'SHOPIFY' && (
                <>
                  <img
                    src={ShopifyIcon}
                    className="cursor-pointer ms-1 shopify-icon position-absolute"
                    data-tip
                    data-for="shopifySyncIcon"
                    onClick={() => goToShopifyPage(product?.externalProductId)}
                  />
                  <ShopifySyncTooltip tooltipText="Product from Shopify" />
                </>
              )}
            </div>

            <Link
              to={{ pathname: `/products/allProducts/edit/${product.productId}`, state: { from: locationState } }}
              className="main-cell"
            >
              <span className="d-block" data-tip data-for={`rulesEngineProductName-${product.productId}`}>
                {product.productName}
              </span>
              <Tooltip
                tooltipId={`rulesEngineProductName-${product.productId}`}
                place="top"
                content={() => {
                  return <>{product.productName}</>;
                }}
              />
            </Link>
          </FlexBox>
        )
      },
      {
        className: `align-middle p-0 pe-3 text-muted ${index !== 0 ? 'table-top-border' : 'border-0'}`,
        value: `${product?.productQty ? product?.productQty : 0} in stock`
      },
      {
        className: `align-middle p-0 ps-1 text-muted ${index !== 0 ? 'table-top-border' : 'border-0'}`,
        value: `${product?.salePrice ? `$${product?.salePrice}` : '-'}`
      },
      {
        className: `align-middle p-0 position-relative ${index !== 0 ? 'table-top-border' : 'border-0'}`,
        value: (
          <div
            className={`p-2 poppins-medium text-center points-none ${selectProductStatusClass(
              product?.productStatus?.toLowerCase()
            )}-status-box`}
          >
            <span>{renderProductStatus(product?.productStatus)}</span>
          </div>
        )
      }
    ];
    arr.push({
      className: `position-relative h-fit-content table-row cursor-pointer`,
      id: product.id,
      cells
    });
    return null;
  });
  tableData.rows = arr; // PUSHING DATA IN TABLE

  const emptySelectedProducts = () => {
    setProductListData((prev) => ({
      ...prev,
      selectedProductsIds: []
    }));
  };
  const removeProductsFromList = () => {
    const temp = productListIds.filter((n) => !selectedProductsIds.includes(n));
    setProductListData((prev) => ({
      ...prev,
      manualProductListIds: temp,
      selectedProductsIds: []
    }));
    closeModal();
    showToast({
      errorText: `${selectedProductsIds.length} ${
        selectedProductsIds.length > 1
          ? constVariables.Catalogs.catalogProductTooltip.mainTitle
          : constVariables.Catalogs.catalogProductTooltip.secondtitle
      }`,
      message: `${constVariables.Catalogs.catalogProductTooltip.mainMessage} ${selectedProductsIds.length}  ${
        selectedProductsIds.length > 1
          ? constVariables.Catalogs.catalogProductTooltip.mainMessageTwo
          : constVariables.Catalogs.catalogProductTooltip.mainMessageThree
      }`
    });
    if (removeProducts) {
      removeProducts(selectedProductsIds);
    }
  };

  const handleSearch = (value) => {
    resetHasMore();
    setProductInput((getProductInput) => {
      return {
        ...getProductInput,
        filters: {
          ...getProductInput.filters,
          searchText: value
        }
      };
    });
  };

  const handleTableScroll = (event: React.UIEvent<HTMLDivElement>) => {
    debouncedScroll(event);
  };

  const [skipCount, setSkipCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const resetHasMore = () => {
    setSkipCount(0);
    setHasMore(true);
  };

  const debouncedScroll = debounce(async (event) => {
    const scrollPosition = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const offsetHeight = event.target.offsetHeight;

    if (isLoading || isLoadingMoreProducts) return;

    if (scrollPosition + offsetHeight > scrollHeight - 10) {
      if (!hasMore) {
        return;
      }

      const getProductInputCopy = {
        ...getProductInput,
        pageInfo: {
          ...getProductInput.pageInfo,
          skipCount: skipCount + 10
        }
      };
      setSkipCount((s) => s + 10);
      setIsLoadingMoreProducts(true);

      fetchProductsInChunks(getProductInputCopy);
    }
  }, 500);

  const handleCloseAddingProductsModal = () => {
    document.body.style.overflow = 'unset';
    setShowAddProductModal(false);
  };

  const handleAddProducts = (productIDs) => {
    const newProductIds = [...new Set([...productListIds, ...productIDs])];
    setProductListData((prev) => ({
      ...prev,
      manualProductListIds: newProductIds
    }));
    setFormChanges(true);
    handleCloseAddingProductsModal();
  };

  const closeModal = () => {
    document.body.style.overflow = 'unset';
    setShowRemove(false);
  };

  return (
    <>
      <div className={`card w-100 ${type === 'coupon' ? '' : 'mb-0'}`}>
        <h4 className="section-title m-b-32">{constVariables.Catalogs.addCatalog.poductListHeading}</h4>
        <div>
          <FlexBox className="justify-content-between w-100 m-b-32">
            <span className="searchBar">
              <SearchBar placeholder={constVariables.Catalogs.serchForProducts} onChange={handleSearch} />
            </span>
            <FlexBox className="align-items-center">
              {!isAutoType && productListIds?.length > 0 && (
                <div className="btn text-btn text-primary btn-sm m-0 p-0" onClick={() => setShowAddProductModal(true)}>
                  Add Products
                </div>
              )}
              <div className="position-relative m-l-16">
                <OutsideClickHandler
                  onOutsideClick={() => {
                    if (openSortDropdown) setOpenSortDropdown(false);
                  }}
                >
                  <button
                    className={`btn btn-sm btn-flex btn-icon-text align-items-center ${
                      openSortDropdown ? 'btn-primary' : 'btn-active-primary'
                    } m-0`}
                    onClick={() => {
                      setOpenSortDropdown(!openSortDropdown);
                    }}
                  >
                    <KTSVG path={SortIcon} className="me-1" />
                    <span className="my-auto me-0">
                      Sort:
                      <span className="mx-2">{sortBy.displayName}</span>
                    </span>
                  </button>
                  <CatalogSortDropdown data={SortIds} value={sortBy} onSelect={ChangeSortBy} selected={openSortDropdown} />
                </OutsideClickHandler>
              </div>
            </FlexBox>
          </FlexBox>
          {selectedProductsIds.length > 0 && (
            <FlexBox className="m-t-24 m-b-32 justify-content-start align-items-center">
              <button className="btn btn-outlined-primary btn-xs me-2">
                <div onClick={emptySelectedProducts}>
                  <input
                    className={'form-check-input small-checkbox-size shadow-none opacity-100 bg-primary rounded-sm m-0'}
                    type="checkbox"
                    disabled={true}
                    ref={(input) => {
                      if (input) {
                        input.indeterminate = true;
                      }
                    }}
                  />
                  <span className="ms-2">{selectedProductsIds.length} Selected</span>
                </div>
              </button>

              <button
                className="btn btn-danger btn-xs me-2"
                onClick={() => {
                  setShowRemove(!showRemove);
                }}
              >
                Remove from {type}
              </button>
            </FlexBox>
          )}

          {/* SHOWING TABLE HERE */}
          <div className="table-wrapper rules-engine-product-table">
            <FixedHeaderTable
              headers={tableData.headers}
              sortByType={tableData.sortByType}
              sortByCategory={tableData.sortByCategory}
              rows={tableData.rows}
              isLoading={isLoading || isLoadingMoreProducts}
              loadingPosition={isLoadingMoreProducts ? 'bottom' : 'center'}
              searchText={getProductInput.filters.searchText}
              isCatalog={tableData.isCatalog}
              categoryInfo={tableData.categoryInfo}
              type="Product"
              containerHeight={500}
              onScroll={handleTableScroll}
              noMargin
              shouldShowAddProductButton={!isAutoType ? true : false}
              handleAddButton={() => setShowAddProductModal(true)}
            />
          </div>
        </div>
      </div>
      {showRemove && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showRemove} closeModal={closeModal}>
          <DiscardModal
            deleteProductMedia
            title={`Remove products from ${type}`}
            message={
              selectedProductsIds.length > 1
                ? `${constVariables.common.deleteProductFromCatalog.message} ${selectedProductsIds.length} ${constVariables.common.deleteProductFromCatalog.secondMessage}`
                : `${constVariables.common.deleteProductFromCatalog.mainMessage}`
            }
            actionBtnTitle={constVariables.common.deleteProductFromCatalog.action}
            cancelBtnTitle={constVariables.common.deleteProductFromCatalog.cancel}
            actionBtnHandler={removeProductsFromList}
            cancelBtnHandler={() => {
              emptySelectedProducts();
              closeModal();
            }}
          />
        </CustomModal>
      )}
      {showAddProductModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showAddProductModal} closeModal={handleCloseAddingProductsModal}>
          <AddingProductsModal
            closeModal={handleCloseAddingProductsModal}
            shouldShowVariants={false}
            handleAddProducts={handleAddProducts}
            isShowOnlyActiveProducts={false}
          />
        </CustomModal>
      )}
    </>
  );
};

export default RulesEngineProducts;
