/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';

// Apollo
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_PRODUCTS_V3, GET_PRODUCT_NOTE, PRODUCT_DESCRIPTON_AND_VARIANTS } from 'src/apollo/queries';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { Tooltip } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { pad } from 'src/utils/countdown';
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons && Images
import { DefaultImage, GoBackArrow, LeftArrow, NoProductSelected, RightArrow, SeeMore } from 'src/assets/icons';
import { Ring } from 'src/assets/images';

// Styles
import './_currentProductDetail.scss';
import { useToast } from 'src/utils/hooks/useToast';
import { SPLITER } from '../../../constants';

interface CurrentProductDetailProps {
  productDetails: any;
  preSelectedProduct: any;
  nextSelectedProduct: any;
  setSelectedProduct: (detail: any) => void;
}

const LiveDot = () => {
  return <div style={{ marginLeft: '4px', background: '#F1416C', width: '8px', height: '8px', borderRadius: '50%' }}></div>;
};

const CurrentProductDetail: React.FC<CurrentProductDetailProps> = ({
  productDetails,
  preSelectedProduct,
  nextSelectedProduct,
  setSelectedProduct
}) => {
  const { showToast } = useToast();

  const [isProductVisible, setIsProductVisible] = useState<boolean>(true);
  const [productNotes, setProductNotes] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<string>('description');

  const getUpdatedAt = (updatedAt) => {
    const timeStamp = moment(updatedAt);
    return `${timeStamp.format('MMM DD,YYYY')} at ${pad(timeStamp.hours())}:${pad(timeStamp.minutes())}`;
  };

  const toggleViewDescriptionNotes = () => {
    setIsProductVisible((prev) => !prev);
  };

  const { loading: productNotesLoading } = useQuery(GET_PRODUCT_NOTE, {
    variables: {
      input: {
        productId: productDetails?.productId
      }
    },
    skip: !productDetails?.productId,

    onCompleted: (data) => {
      setProductNotes(data?.getProductNote);
    }
  });

  const { data } = useQuery(GET_PRODUCTS_V3, {
    variables: {
      input: {
        productIdsString: JSON.stringify([productDetails?.productId]),
        detailed: true,
        filters: {
          locationIdsString: '[]'
        }
      }
    },
    skip: !productDetails?.productId,
    onError: (error) => {
      showToast({
        errorText: 'Error in Getting product',
        message: `Error in Getting product details`
      });
    }
  });

  const productDetail = data?.getAllProductsV3?.products[0];
  const variants = useMemo(() => {
    const activeVariants: {
      id: number;
      quantity: number;
      varaint: string;
    }[] = [];
    productDetail?.variantLocationDetails?.forEach(
      ({ variantLocation, variantStatus, variantTypes, productVariantId, variantUsersQuantity }) => {
        if (variantStatus === 'ACTIVE') {
          activeVariants.push({
            id: productVariantId,
            quantity: variantLocation[0]?.productVariantLocationQuantity || variantUsersQuantity,
            varaint: variantTypes?.map(({ variantTypeValue }) => variantTypeValue).join(SPLITER)
          });
        }
      }
    );

    return activeVariants;
  }, [productDetail]);

  const handleClickPrevious = () => {
    if (preSelectedProduct) {
      setSelectedProduct(preSelectedProduct);
    }
  };
  const handleClickNext = () => {
    if (nextSelectedProduct) {
      setSelectedProduct(nextSelectedProduct);
    }
  };

  return (
    <div>
      <h4 className="liveshow-section-title">Products</h4>
      {productDetails?.id ? (
        isProductVisible ? (
          <div className="current-product-container d-flex flex-column p-x-20 p-y-16 m-t-16 m-b-32">
            <FlexBox className="current-product-header justify-content-between align-items-center w-100">
              <FlexBox className="align-items-center">
                <span>Live</span>
                <LiveDot />
              </FlexBox>
              <FlexBox className="align-items-center" style={{ marginRight: '-10px' }}>
                <FlexBox
                  className="arrow-icon align-items-center justify-content-center cursor-pointer"
                  onClick={handleClickPrevious}
                  style={{
                    cursor: preSelectedProduct ? 'pointer' : 'not-allowed'
                  }}
                >
                  <img src={LeftArrow} alt="prev" />
                </FlexBox>
                <FlexBox
                  className="arrow-icon align-items-center justify-content-center cursor-pointer"
                  onClick={handleClickNext}
                  style={{
                    cursor: nextSelectedProduct ? 'pointer' : 'not-allowed'
                  }}
                >
                  <img src={RightArrow} alt="next" />
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox className="current-product-content">
              <div className="d-flex flex-column justify-content-between mt-1">
                <FlexBox className="current-product-image flex-column align-items-center">
                  <Img
                    src={setImageSrc(
                      productDetails?.imageUrl ? generateCompressedImageURL(productDetails.imageUrl, '500') : DefaultImage
                    )}
                    placeholderImg={DefaultImage}
                    errorImg={DefaultImage}
                  />
                  <div className="current-product-id text-center w-100">#{productDetails?.productReferenceId}</div>
                </FlexBox>
                <div className="current-product-name poppins-medium m-t-8 m-b-4" data-tip data-for="liveCurrentProductName">
                  {productDetails?.name}
                </div>
                <Tooltip
                  tooltipId="liveCurrentProductName"
                  place="top"
                  content={() => {
                    return <>{productDetails?.name}</>;
                  }}
                />
                <div className="d-flex align-items-center poppins-medium">
                  <div className="current-product-price">${productDetails?.salePrice}</div>
                  <div className="cursor-pointer ms-1" onClick={toggleViewDescriptionNotes}>
                    <img src={SeeMore} alt="see more" className="search-icon" />
                  </div>
                </div>
              </div>
              <FlexBox className="current-product-variants-table flex-column w-100">
                <FlexBox className="current-product-variants-table-header justify-content-between poppins-medium">
                  <span className="current-product-variants-table-title">Variant</span>
                  <span className="current-product-variants-table-title">Stock</span>
                </FlexBox>
                <div className="current-product-variants-table-content">
                  {variants?.length > 0
                    ? variants?.map(({ id, quantity, varaint }) => (
                        <FlexBox className="current-product-variants-table-cell justify-content-between" key={id}>
                          <span>{varaint}</span>
                          <span>{quantity}</span>
                        </FlexBox>
                      ))
                    : undefined}
                </div>
              </FlexBox>
            </FlexBox>
          </div>
        ) : (
          <FlexBox className="current-product-description flex-column">
            <FlexBox className="current-product-description-header align-items-center justify-content-between">
              <div className="cursor-pointer ms-1" onClick={toggleViewDescriptionNotes}>
                <img src={GoBackArrow} alt="Go back" className="go-back-icon" />
              </div>
              <div
                onClick={() => setActiveTab('description')}
                className={`current-product-tab-name cursor-pointer ${activeTab === 'description' && 'active'}`}
              >
                Description
              </div>
              <div
                onClick={() => setActiveTab('notes')}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '4px' }}
                className={`current-product-tab-name cursor-pointer ${activeTab === 'notes' && 'active'}`}
              >
                Notes
                {productNotes?.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#00A3FF',
                      height: '8px',
                      width: '8px',
                      borderRadius: '50%',
                      color: '#ffffff',
                      padding: '8px',
                      marginLeft: '8px'
                    }}
                  >
                    {productNotes.length}
                  </div>
                )}
              </div>
            </FlexBox>
            {activeTab === 'description' && (
              <div
                className="current-product-description-body d-flex flex-column justify-content-center"
                dangerouslySetInnerHTML={{ __html: productDetail?.productDescription }}
              />
            )}
            {activeTab === 'notes' &&
              (productNotesLoading ? (
                <div className="spinner">
                  <img src={Ring} role="status"></img>
                </div>
              ) : (
                <FlexBox className="current-product-description-body d-flex flex-column justify-content-center">
                  {productNotes?.map((note) => {
                    return (
                      <div key={note?.id} className="notes-container">
                        <FlexBox className="title-name-date align-items-center justify-content-between w-100">
                          <FlexBox className="align-items-center">
                            <div className="circle-image-container">
                              <img
                                height={34}
                                width={34}
                                style={{ borderRadius: '50%' }}
                                src={note?.imageUrl ? note?.imageUrl : DefaultImage}
                                alt={note?.imageUrl ? note?.imageUrl : DefaultImage}
                              />
                            </div>
                            <div className="notes-username">
                              <span>{note?.userName}</span>
                            </div>
                          </FlexBox>
                          <div className="note-updated">
                            <span>{getUpdatedAt(note?.updatedAt)}</span>
                          </div>
                        </FlexBox>
                        <div className="notes-details">
                          <span>{note?.note}</span>
                        </div>
                      </div>
                    );
                  })}
                </FlexBox>
              ))}
          </FlexBox>
        )
      ) : (
        <FlexBox className="current-product-container-no-product display-flex flex-direction-column justify-content-center align-items-center text-center flex-column p-x-20 p-y-16 m-t-16 m-b-32">
          <img src={NoProductSelected} alt="no product selected" />
          <span>No Product is selected</span>
          <h6 className="no-select-text-product">Please select a product to showcase it</h6>
        </FlexBox>
      )}
    </div>
  );
};

export default CurrentProductDetail;
