import { FC, useState, createRef, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { FlexBox } from 'src/components/atoms';
import { Dropdown, Portal } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';
import { TIKTOK_PRODUCT_STATUS } from '../TiktokIntegration.types';

// Types

interface StatusCellProps {
  id: string | number;
  product?: any;
  handleUpdate?: (product: any, value: any) => void;
}

const StatusCellForActive: FC<StatusCellProps> = ({ id, product, handleUpdate }) => {
  const statusRef = createRef<any>();
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0
  });

  const handleShowDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOptionOpen(true);

    if (statusRef.current) {
      const position = statusRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropdownPositions({
        top: spaceBelow < 150 ? window.scrollY + position.top - 70 : window.scrollY + position.top + 35,
        left: position.left - 70
      });
    }
  };

  const statusText = (title: string) => {
    if (title === TIKTOK_PRODUCT_STATUS.ACTIVE) {
      return 'Active';
    }
    return 'Inactive';
  };

  const handleUpdateStatus = (value, e) => {
    console.log('value', value);
    e.preventDefault();
    e.stopPropagation();
    setIsOptionOpen(false);
    if (handleUpdate) {
      handleUpdate(product, value);
    }
    // if (value === 'Active') {
    //
    // }
    // console.log('click', value);
  };

  const selectCollectionStatusClass = (collectionStatus: string | undefined) => {
    switch (collectionStatus) {
      case 'active':
        return 'active';
      default:
        return 'inactive';
    }
  };

  const [dropDown, setDropDown] = useState<{ id: number; name: string }[]>([]);
  useEffect(() => {
    if (product.status === TIKTOK_PRODUCT_STATUS.ACTIVE) {
      setDropDown([
        {
          id: 2,
          name: 'Inactive'
        }
      ]);
    }
    if (product.status === TIKTOK_PRODUCT_STATUS.INACTIVE) {
      setDropDown([
        {
          id: 1,
          name: 'Active'
        }
      ]);
    }
  }, [product]);

  return (
    <>
      <FlexBox
        className={`p-2 poppins-medium justify-content-between ${selectCollectionStatusClass(
          product.status?.toLowerCase()
        )}-status-box ${
          product.status === TIKTOK_PRODUCT_STATUS.ACTIVE || product.status === TIKTOK_PRODUCT_STATUS.INACTIVE
            ? 'cursor-pointer'
            : ''
        }`}
        onClick={handleShowDropdown}
      >
        <div className="d-flex align-items-center justify-content-between w-100" ref={statusRef}>
          <span>{statusText(product.status ? product.status : '')}</span>
          {(product.status === TIKTOK_PRODUCT_STATUS.ACTIVE || product.status === TIKTOK_PRODUCT_STATUS.INACTIVE) && (
            <KTSVG
              path={DownArrowIcon}
              className={`m-auto-0 ${selectCollectionStatusClass(product.status?.toLocaleLowerCase())}-arrow`}
            />
          )}
        </div>
      </FlexBox>
      {isOptionOpen && (
        <Portal id="kt_body">
          <OutsideClickHandler
            onOutsideClick={() => {
              if (isOptionOpen) setIsOptionOpen(false);
            }}
          >
            <div className="position-absolute" style={{ top: dropdownPositions.top, left: dropdownPositions.left }}>
              <Dropdown
                data={dropDown}
                selected={isOptionOpen}
                value={product.status}
                onSelect={(value, e) => handleUpdateStatus(value, e)}
                closeDropdown={() => setIsOptionOpen(false)}
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </>
  );
};

export default StatusCellForActive;
