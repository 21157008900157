import { FC, useCallback } from 'react';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { RoundWarningIcon, HideIcon, ShowIcon } from 'src/assets/icons';

// Types
import { InputBoxProps } from './inputBox.types';

// Styles
import './_inputbox.scss';

const InputBox: FC<InputBoxProps> = ({
  isCatalog,
  helperText,
  imageLeft,
  imageRight,
  inputClass,
  inputProps,
  mainclass,
  name,
  onChangeText,
  placeholder,
  title,
  value,
  warningText,
  type,
  disable,
  min,
  max,
  dollarSign,
  charLimit,
  titleClass,
  inputIcon,
  inputIconColor,
  noBorders,
  onKeyDown,
  maxLength,
  warningTextIcon,
  readOnly,
  onBlur,
  isPasswordInput,
  toggleShowPassword,
  isAutoFocus,
  step
}) => {
  const whatInput = (e) => {
    if (onChangeText) {
      if (dollarSign) {
        if (e.target.value[0] === '$') {
          if (e.which === 32) {
            return false;
          }
          onChangeText(e.target.value.substring(1));
        } else {
          onChangeText(e.target.value);
        }
      } else {
        onChangeText(e.target.value);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (onKeyDown) {
      if (dollarSign) {
        if (e.which === 32) {
          e.preventDefault();
          return false;
        } else {
          onKeyDown(e);
        }
      } else {
        onKeyDown(e);
      }
    }
  };

  const callbackRef = useCallback(
    (inputElement) => {
      if (inputElement && isAutoFocus) {
        setTimeout(() => {
          inputElement.focus();
          inputElement.select();
        }, 100);
      }
    },
    [isAutoFocus]
  );

  return (
    <div className={`col-sm d-flex flex-column ${mainclass}`}>
      {imageLeft || imageRight || title ? (
        <div className="d-flex align-item-center position-relative input-title-section m-b-8">
          {imageLeft}
          {title ? <div className={`${isCatalog ? 'status_input_title' : ''} input-title mb-0 ${titleClass}`}>{title}</div> : null}
          {imageRight}
        </div>
      ) : null}
      <div className={`d-flex ${noBorders ? '' : 'form-control p-0'} align-item-center ${inputClass} input-box position-relative`}>
        {inputIcon && (
          <KTSVG
            svgColor={inputIconColor}
            svgClassName="h-20px w-20px me-3"
            path={inputIcon}
            className="m-auto-0 input-icon position-absolute translate-middle-y top-50"
          />
        )}
        <input
          autoComplete="off"
          className={`w-100 h-100 px-5 ${inputIcon ? 'ps-15' : ''} ${warningText ? 'input-error' : ''}`}
          type={type}
          name={name}
          placeholder={placeholder}
          value={
            dollarSign
              ? `$${charLimit ? value?.toString().slice(0, charLimit) : value?.toString()}`
              : `${charLimit ? value?.toString().slice(0, charLimit) : value?.toString()}`
          }
          onChange={(e) => {
            whatInput(e);
          }}
          {...inputProps}
          disabled={disable}
          min={min}
          max={max}
          onKeyDown={handleKeyDown}
          maxLength={maxLength}
          readOnly={readOnly}
          onBlur={(e) => (onBlur ? onBlur(e) : null)}
          ref={callbackRef}
          step={type === 'number' && step ? step : 1}
        />
        {isPasswordInput && (
          <div className="password-icon position-absolute">
            <img src={type === 'password' ? HideIcon : ShowIcon} className="cursor-pointer" onClick={toggleShowPassword} />
          </div>
        )}
      </div>

      {warningText ? (
        <div className="w-100 ">
          {warningTextIcon ? (
            <span>
              <img src={RoundWarningIcon} className="h-10px w-10px me-1" />
            </span>
          ) : null}
          <span className="p-0 m-0">
            <div className="warning-text p-0 fs-7">{warningText}</div>
          </span>
        </div>
      ) : null}
      {helperText && <span className="helper-text m-t-8">{helperText}</span>}
    </div>
  );
};

export default InputBox;
