/* eslint-disable no-nested-ternary */
import { FC, createRef, useEffect, useState, Fragment, useMemo } from 'react';

// Components
import { Loader } from 'src/components/atoms';
import { NotFound } from 'src/components/molecules';
import CollectionTreeRow from 'src/components/oraganisms/BulkEditorModal/CollectionTreeRow';
import LiveShowAddProductVariantRow from 'src/components/oraganisms/LiveshowProductList/LiveShowAddProductVariantRow';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon, UpArrowIcon } from 'src/assets/icons';

// Types
import { FixedHeaderTableProps } from './FixedHeaderTable.types';

// Styles
import './_fixedHeaderTable.scss';
import LiveProductVariantRow from '../LiveProductsBlock/LiveProductVariantRow';

const FixedHeaderTable: FC<FixedHeaderTableProps> = ({
  headers,
  sortByCategory,
  sortByType,
  rows,
  isLoading,
  loadingPosition,
  searchText,
  isCatalog,
  categoryInfo,
  type,
  onScroll,
  containerHeight,
  noMargin,
  shouldShowAddProductButton,
  handleAddButton
}) => {
  const tableContainer = createRef<any>();
  const [tableBodyWidth, setTableBodyWidth] = useState(0);

  const NotFoundRow = () => {
    return (
      <tr className="no-hover-row">
        <td colSpan={headers?.length} className="w-100 text-center">
          <NotFound
            type={type ? type : 'product'}
            isCatalog={isCatalog}
            categoryInfo={categoryInfo}
            searchText={searchText}
            shouldShowAddProductButton={shouldShowAddProductButton}
            handleAddButton={handleAddButton}
          />
        </td>
      </tr>
    );
  };

  const LoadingRow = () => {
    return (
      <tr className="no-hover-row">
        <td colSpan={headers?.length} className="w-100 text-center border-none align-middle">
          <Loader type="page" className={`${loadingPosition === 'center' ? 'h-400px' : ''}`} />
        </td>
      </tr>
    );
  };

  const tableContent = useMemo(() => {
    return (
      <>
        {rows && rows?.length > 0 ? (
          <>
            {rows?.map((row, index) => {
              return (
                <Fragment key={`${index}${row.id}`}>
                  <tr id={`${type === 'Live Product' ? 'live-product-' : type}${row.id}`} className={row.className}>
                    {row?.cells?.map((cell, i) => {
                      return (
                        <td className={`${cell.className}`} key={i}>
                          {cell.value}
                        </td>
                      );
                    })}
                    <td style={{ width: 8 }} className={`${index !== 0 ? 'table-top-border' : 'border-0'}`}></td>
                  </tr>
                  {(row.collectionDetail &&
                    row.showCollectionDetails &&
                    row.showCollectionDetails?.findIndex((data) => data === row.id) > -1) ||
                  row.showAllDetails
                    ? row.collectionDetail?.collection?.children?.map((item, itemIndex) => (
                        <CollectionTreeRow
                          key={`${itemIndex}${item.id}`}
                          collection={item}
                          selectedCollections={row?.collectionDetail?.selectedCollections}
                          setSelectedCollections={row?.collectionDetail?.setSelectedCollections}
                          showAllDetails={row?.showAllDetails}
                        />
                      ))
                    : null}
                  {(row.variantDetail &&
                    row.showVariantDetails &&
                    row.showVariantDetails?.findIndex((data) => data === row.id) > -1) ||
                  row.showAllVariants
                    ? row.variantDetail?.product?.variantLocationDetails?.map((item, itemIndex) => (
                        <LiveShowAddProductVariantRow key={`${itemIndex}${item.id}`} variant={item} />
                      ))
                    : null}
                  {(row.analyticDetail &&
                    row.showVariantAnalytics &&
                    row.showVariantAnalytics?.findIndex((data) => data === row.id) > -1) ||
                  row.showAllVariantAnalytics ? (
                    <>
                      {row.loadingVariantState &&
                        row.loadingVariantState?.findIndex((data) => data === row.id) > -1 &&
                        (!row.analyticDetail?.product?.variantAnalytics ||
                          (row.analyticDetail?.product?.variantAnalytics &&
                            row.analyticDetail?.product?.variantAnalytics.length === 0)) && (
                          <tr
                            style={{
                              height: 70,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: tableBodyWidth
                            }}
                            className="ReactVirtualized__Table__row"
                          >
                            <Loader type="page" />
                          </tr>
                        )}
                      {row.analyticDetail?.product?.variantAnalytics?.map((item, itemIndex) => (
                        <LiveProductVariantRow
                          key={`${itemIndex}${item.id}`}
                          variant={item}
                          getSelectValue={row.analyticDetail?.getSelectValue}
                        />
                      ))}
                    </>
                  ) : null}
                </Fragment>
              );
            })}
          </>
        ) : (
          <NotFoundRow />
        )}
      </>
    );
  }, [rows]);

  const handleHorizontalScroll = (event) => {
    if (tableContainer.current) {
      const position = tableContainer.current.getBoundingClientRect();
      const updatedTableWidth = position.width + event.currentTarget.scrollLeft;
      setTableBodyWidth(updatedTableWidth);
    }
  };

  useEffect(() => {
    if (tableContainer.current) {
      const position = tableContainer.current.getBoundingClientRect();
      setTableBodyWidth(position.width);
    }
  }, [rows]);

  return (
    <div className={`pane ${noMargin ? 'mb-0' : 'm-b-24'}`}>
      <div className="pane-hScroll" onScroll={handleHorizontalScroll} ref={tableContainer}>
        <table className="table mb-0">
          <thead>
            <tr>
              {headers?.map((header, index) => {
                return (
                  <th key={index} className={header.className}>
                    <div className="d-flex align-items-center">
                      <div key={index} className={header.textClass}>
                        {header.colName}
                      </div>
                      {header.sorting && (
                        <button
                          className="bg-transparent border border-transparent"
                          onClick={() => {
                            header.applySort();
                          }}
                        >
                          <KTSVG
                            path={sortByCategory === header.category && sortByType === 'ASC' ? UpArrowIcon : DownArrowIcon}
                            className="expand-arrow-icon"
                            svgClassName="cursor-pointer collapseFilter collapseIcon"
                          />
                        </button>
                      )}
                    </div>
                  </th>
                );
              })}
              <th className="scrollbar-cell table-header-background"></th>
            </tr>
          </thead>
        </table>

        <div
          className="pane-vScroll"
          style={{ width: tableBodyWidth, height: containerHeight ? containerHeight : 'unset' }}
          onScroll={onScroll}
        >
          <table className={`table ${noMargin ? 'mb-0' : ''}`}>
            <tbody>
              {loadingPosition === 'center' && (isLoading ? <LoadingRow /> : tableContent)}
              {loadingPosition === 'bottom' && (
                <>
                  {tableContent}
                  {isLoading && <LoadingRow />}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FixedHeaderTable;
