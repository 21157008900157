import { useContext } from 'react';
import { ToastHandler } from 'src/helpers/context/toastContext';

interface ToastData {
  showToast: (data: ToastInput) => void;
}
interface ToastInput {
  title?: string;
  message: string;
  errorText?: string;
  successText?: string;
  undoButton?: boolean;
  undoBtnHandler?: (...args: any) => void;
  editBtnText?: string;
  editBtnHandler?: (...args: any) => void;
  width?: number;
  style?: any;
}

export const useToast = (): ToastData => {
  const { setToastValues } = useContext(ToastHandler);

  const showToast = (data: ToastInput) => {
    setToastValues({
      title: data.title || '',
      message: data.message,
      errorText: data?.errorText || '',
      successText: data?.successText || '',
      undoButton: data?.undoButton || false,
      undoBtnHandler: data?.undoBtnHandler || undefined,
      editBtnText: data?.editBtnText || '',
      editBtnHandler: data?.editBtnHandler || undefined,
      width: data?.width,
      style: data?.style
    });
  };

  return { showToast };
};
