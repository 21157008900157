import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

// Apollo
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CONFIGURE_SHIPPO } from 'src/apollo/mutations';
import { GET_SHIPPING_PROVIDERS } from 'src/apollo/queries';
import { shippoConfigCode } from 'src/apollo/reactiveVariables';

// Components
import { FlexBox, Loader } from 'src/components/atoms';
import { Toast, Tooltip } from 'src/components/molecules';

// Hooks && Utils && Helpers
import useTitle from 'src/utils/hooks/useTitle';
import { useToast } from 'src/utils/hooks/useToast';

// Icons
import { BackRoundedIcon, FilledCheckCircle, InfoIcon, ShippoLogo } from 'src/assets/icons';

// ConstVariables
import { constVariables, envUrls, ROUTES } from 'src/constants';

// Styles
import './Shipping.scss';
import ShippingSettingsShippingMode from '../../../oraganisms/ShippingSettingsShippingMode/ShippingSettingsShippingMode';
import ShippingSettingsFreeShippingWindow from '../../../oraganisms/ShippingSettingsFreeShippingWindow/ShippingSettingsFreeShippingWindow';
import { GET_SHIPPING_SETTINGS } from '../../../../apollo/queries/getShippingSettings';
import ShippingSettingsFressShippingCoupons from '../../../oraganisms/ShippingSettingsFressShippingCoupons/ShippingSettingsFressShippingCoupons';
import { CustomModal, DiscardModal, ErrorModal, SaveHeader } from '../../../oraganisms';
import { toast } from 'react-toastify';
import { UPSERT_SHIPPING_SETTINGS } from '../../../../apollo/mutations/upsertShippingSettings';
import Shipstation from './Components/Shipstation';

const Shipping: FC = () => {
  useTitle('Settings - Shipping');
  const history = useHistory();
  const { showToast } = useToast();
  const [configureShippo] = useMutation(CONFIGURE_SHIPPO, {
    onCompleted: () => {
      shippoConfigCode('');
      showToast({
        successText: constVariables.shipping.modal.title,
        message: constVariables.shipping.modal.message
      });
    }
  });

  const { loading: isLoading, data, refetch } = useQuery(GET_SHIPPING_PROVIDERS);

  useEffect(() => {
    refetch();
    if (shippoConfigCode()) {
      configureShippo({
        variables: {
          input: {
            code: shippoConfigCode()
          }
        }
      });
    }
    const userId = localStorage.getItem('userId');
    if (userId) {
      getShippingSettings({
        variables: {
          input: {
            userId
          }
        }
      });
    }
  }, []);

  const returnBack = () => {
    history.push(ROUTES.settings.main);
  };

  const [shippingSettings, setShippingSettings] = useState({
    mode: undefined,
    maximumRate: false,
    flatRate: false,
    isFreeShippingWindow: false,
    freeShippingWindowCondition: undefined,
    minimumPurchaseAmount: false,
    minimumQtyOfItems: false,
    freeShippingWindowOpenFor: undefined,
    freeShippingWindowDuration: false
  });

  const [getShippingSettings] = useLazyQuery(GET_SHIPPING_SETTINGS, {
    onCompleted: (res) => {
      if (res.getShippingSettings) {
        setShippingSettings(res.getShippingSettings);
        setFormChanges(false);
      }
    }
  });

  const [isDiscardModal, setIsDiscardModal] = useState(false);
  const [isLeaveModal, setIsLeaveModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [targettedPath, setTargettedPath] = useState();

  const [errorMessages, setErrorMessages] = useState({
    flatRate: '',
    maximumRate: '',
    minimumPurchaseAmount: '',
    minimumQtyOfItems: '',
    freeShippingWindowDuration: ''
  });
  const [formChanges, setFormChanges] = useState(false);

  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const [upsertShippingSettings] = useMutation(UPSERT_SHIPPING_SETTINGS, {
    onError: (error) => {
      setFormChanges(false);
      setDisableSaveButton(false);
      showToast({
        errorText: error.message,
        message: `Error occured while save shipping settings ${error.message}`
      });
    },
    onCompleted: async (response) => {
      setFormChanges(false);
      setDisableSaveButton(false);
      toast(
        <Toast
          message={'The Shipping settings has been saved sucessfully'}
          successText={'Shipping settings saved successfully'}
          errorText={''}
          title={''}
        />,
        { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, hideProgressBar: true }
      );
    }
  });
  const onSaveShippingSettings = () => {
    let error: any = {};
    if (shippingSettings?.mode === 'AUTOMATIC_SHIPPING_RATE') {
      if (!shippingSettings?.maximumRate) {
        error.maximumRate = 'Maximum shipping rate can’t be left empty';
      }
    }

    if (shippingSettings?.mode === 'FLAT_SHIPPING_RATE') {
      if (!shippingSettings?.flatRate) {
        error.flatRate = 'Flat rate can’t be left empty';
      }
    }

    if (shippingSettings?.isFreeShippingWindow) {
      if (shippingSettings?.freeShippingWindowCondition === 'MINIMUM_PURCHASE') {
        if (!shippingSettings?.minimumPurchaseAmount) {
          error.minimumPurchaseAmount = 'Minimum purchase amount can’t be left empty';
        }
      }

      if (shippingSettings?.freeShippingWindowCondition === 'MINIMUM_QUANTITY') {
        if (!shippingSettings?.minimumQtyOfItems) {
          error.minimumQtyOfItems = 'Minimum quantity of items can’t be left empty';
        }
      }

      if (shippingSettings?.freeShippingWindowOpenFor === 'HOURS') {
        if (!shippingSettings?.freeShippingWindowDuration) {
          error.freeShippingWindowDuration = 'Hours input field can’t be left empty';
        }
      }

      if (shippingSettings?.freeShippingWindowOpenFor === 'DAYS') {
        if (!shippingSettings?.freeShippingWindowDuration) {
          error.freeShippingWindowDuration = 'Days input field can’t be left empty';
        }
      }
    }

    setErrorMessages(error);
    if (Object.keys(error).length > 0) {
      setSaveModal(true);
      return;
    }
    if (!shippingSettings.isFreeShippingWindow) {
      // @ts-ignore
      shippingSettings.freeShippingWindowDuration = null;
      // @ts-ignore
      shippingSettings.minimumQtyOfItems = null;
      // @ts-ignore
      shippingSettings.minimumPurchaseAmount = null;
    }
    setDisableSaveButton(true);
    upsertShippingSettings({
      variables: {
        input: {
          ...shippingSettings,
          id: undefined
        }
      }
    });
  };
  const openDiscardModal = () => {
    setIsDiscardModal(true);
  };

  const discardAllChanges = () => {
    document.body.style.overflow = 'unset';
    setTimeout(() => {
      history.push(targettedPath ? targettedPath : '/settings');
    }, 100);

    setIsDiscardModal(false);
    setFormChanges(false);
  };

  const closeDiscardModal = () => {
    document.body.style.overflow = 'unset';
    setIsDiscardModal(false);
  };

  const closeLeaveModal = () => {
    document.body.style.overflow = 'unset';
    setIsLeaveModal(false);
  };

  const leavePageHandler = () => {
    document.body.style.overflow = 'unset';
    setFormChanges(false);
    // NOTE: Push to targetted path or products, handled with timeout for now.
    setTimeout(() => {
      history.push(targettedPath ? targettedPath : '/settings');
    }, 100);
  };

  const setShippingSettingsWithFlag = (setting) => {
    setShippingSettings(setting);
    setFormChanges(true);
  };

  const isConnected = useMemo(() => {
    if (data) {
      return data?.getShippingProviders?.isShippoConnectd || data?.getShippingProviders?.isShipStationConnectd;
    }
    return false;
  }, [data]);
  return (
    <>
      {formChanges ? (
        <SaveHeader onSave={onSaveShippingSettings} saveDisabled={disableSaveButton} onDiscard={openDiscardModal} />
      ) : null}
      <FlexBox className="shipping">
        <div className="width-animation w-100">
          <div className="details-page">
            <div className={`details-page-container ${formChanges ? 'has-change' : ''} ${isConnected ? 'is-connected' : ''}`}>
              <FlexBox
                className={`align-items-center m-y-40 ${
                  formChanges ? 'has-change shipping-setting-title' : 'shipping-setting-title'
                }`}
              >
                <img src={BackRoundedIcon} alt={BackRoundedIcon} onClick={returnBack} className="cursor-pointer" />
                <h2 className="page-title px-4 mb-0">{constVariables.shipping.key}</h2>
              </FlexBox>
              <div className="main-detail-layout">
                <div className="left-pane">
                  <div className="card w-100">
                    <div>
                      <img src={ShippoLogo} />
                      <img src={InfoIcon} className="px-3" data-tip data-for="shippingInfoIcon" />
                      <Tooltip
                        tooltipId="shippingInfoIcon"
                        className="toolTipShip"
                        place="right"
                        content={() => {
                          return (
                            <div>
                              {constVariables.shipping.hoverMessage}
                              <a className="text-primary">{constVariables.shipping.clickHere}</a>
                              {constVariables.shipping.moreInfo}
                            </div>
                          );
                        }}
                      />
                      {data?.getShippingProviders?.isShippoConnectd ? (
                        <div className="mt-10 d-flex align-items-center">
                          {isLoading && !data?.getShippingProviders ? (
                            <Loader type="page" />
                          ) : (
                            <>
                              <img src={FilledCheckCircle} />
                              <span className="ms-2 text-success">{constVariables.shipping.accountConnectedMessage}</span>
                            </>
                          )}
                        </div>
                      ) : (
                        <>
                          {isLoading && !data?.getShippingProviders ? (
                            <Loader type="page" />
                          ) : (
                            <>
                              <p className="poppins-regular mt-5">{constVariables.shipping.shippoMessage}</p>
                              <a
                                className="btn btn-primary mt-5"
                                href={`https://goshippo.com/oauth/authorize?response_type=code&client_id=${envUrls.shippoClientId}&scope=*&state=YOUR_RANDOM_STRING`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {constVariables.shipping.connectBtn}
                              </a>
                            </>
                          )}
                        </>
                      )}
                      {/* NOTE: commented Success message for Future */}
                      {/* <>
                      <img src={Checked} />
                      <span className="connected">{constVariables.shipping.connectedMessage}</span>
                    </> */}
                    </div>
                  </div>
                  <Shipstation />
                  {isConnected && (
                    <>
                      <ShippingSettingsShippingMode
                        shippingSettings={shippingSettings}
                        setShippingSettings={setShippingSettingsWithFlag}
                        errorMessages={errorMessages}
                        setErrorMessages={setErrorMessages}
                      />
                      <ShippingSettingsFreeShippingWindow
                        shippingSettings={shippingSettings}
                        setShippingSettings={setShippingSettingsWithFlag}
                        errorMessages={errorMessages}
                        setErrorMessages={setErrorMessages}
                      />
                      <ShippingSettingsFressShippingCoupons />
                    </>
                  )}
                </div>
                {!isConnected && (
                  <div className="right-pane">
                    <div className="card w-100">
                      <h2 className="poppins-regular fs-3 m-auto-0 text-nowrap dark-color">
                        {constVariables.shipping.steps.title}
                      </h2>
                      <p className="mt-5 mediaGreyText">{constVariables.shipping.steps.stepTitle}</p>
                      <p className="mt-5 mediaGreyText">
                        {constVariables.shipping.steps.step1}
                        <span className="unLine cursor-pointer">
                          <a className="text-primary" href="https://apps.goshippo.com/join" rel="noreferrer" target="_blank">
                            {constVariables.shipping.steps.signUp}
                          </a>
                        </span>
                      </p>
                      <p className="mediaGreyText">
                        {constVariables.shipping.steps.step2}
                        <span className="unLine cursor-pointer">
                          <a
                            className="text-primary"
                            href="https://dashboard.sezzle.com/merchant/login/"
                            rel="noreferrer"
                            target="_blank"
                          >
                            {constVariables.shipping.sezzeleDashboard}
                          </a>
                        </span>
                      </p>
                      <p className="mediaGreyText mb-0">{constVariables.shipping.steps.step3}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </FlexBox>
      {isDiscardModal && (
        <CustomModal bodyClassname="w-90 w-md-50" show={isDiscardModal} closeModal={closeDiscardModal}>
          <DiscardModal
            actionBtnTitle={constVariables.common.discardForm.action}
            cancelBtnTitle={constVariables.common.discardForm.cancel}
            message={constVariables.common.discardForm.message}
            title={constVariables.common.discardForm.title}
            actionBtnHandler={discardAllChanges}
            cancelBtnHandler={closeDiscardModal}
          />
        </CustomModal>
      )}
      {isLeaveModal && (
        <CustomModal show={isLeaveModal} bodyClassname="w-90 w-md-50" closeModal={closeLeaveModal}>
          <DiscardModal
            actionBtnTitle={constVariables.common.LeaveForm.action}
            cancelBtnTitle={constVariables.common.LeaveForm.cancel}
            message={constVariables.common.LeaveForm.message}
            title={constVariables.common.LeaveForm.title}
            actionBtnHandler={leavePageHandler}
            cancelBtnHandler={closeLeaveModal}
            deleteProductMedia
          />
        </CustomModal>
      )}
      {saveModal && (
        <CustomModal bodyClassname="w-90 w-md-50" show={saveModal} closeModal={() => setSaveModal(false)}>
          <ErrorModal
            message={constVariables.common.onSaveError.message}
            actionTitle={constVariables.common.onSaveError.action}
            actionHandler={() => setSaveModal(false)}
            errors={Object.values(errorMessages)}
          />
        </CustomModal>
      )}
    </>
  );
};

export default Shipping;
