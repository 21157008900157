import { FC, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { TagAndCollectionDropdown } from 'src/components/molecules';
import { TagColors, TagColorsExtra } from 'src/components/oraganisms/OrdersList/tagColors';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon } from 'src/assets/icons';

// Types
import { OrderTagsBlockProps } from './OrderTagsBlock.types';
import { constVariables } from 'src/constants';

const OrderTagsBlock: FC<OrderTagsBlockProps> = ({ tags, isCombined }) => {
  const [isBlockSelected, setIsBlockSelected] = useState(false);
  const openCloseBlock = () => {
    setIsBlockSelected(!isBlockSelected);
  };
  const closeBlock = () => {
    if (isBlockSelected) {
      setIsBlockSelected(false);
    }
  };
  if (tags) {
    let temp = JSON.parse(tags).tags;
    let tempData;
    if (isCombined) {
      if (temp && temp?.length > 0) {
        temp = [...temp, constVariables.orders.combined];
      } else {
        temp = [constVariables.orders.combined];
      }
    }
    if (temp?.length > 0) {
      let tempIndex = 0;
      tempData = temp.map((x, y) => {
        if (y > 0) {
          if (tempIndex > 2) {
            tempIndex = 0;
          } else {
            tempIndex = tempIndex + 1;
          }
        }

        return {
          id: y,
          name: x,
          textColorCode: TagColors[tempIndex].color,
          backgroundColorCode: TagColors[tempIndex].background
        };
      });
    }

    if (temp) {
      if (temp.length > 3) {
        return (
          <>
            <div className="position-relative w-200px d-flex flex-wrap ">
              {temp.slice(0, 3).map((data, index) => (
                <div
                  style={{ backgroundColor: TagColors[index].background, color: TagColors[index].color }}
                  className="text-nowrap m-1 px-2 py-1 rounded poppins-medium"
                  key={index}
                >
                  {data}
                </div>
              ))}

              <OutsideClickHandler onOutsideClick={closeBlock}>
                <div
                  style={{ backgroundColor: TagColorsExtra.background, color: TagColorsExtra.color }}
                  className="align-items-center d-flex m-1 px-2 py-1 rounded poppins-medium"
                  onClick={openCloseBlock}
                >
                  <div>{`+${temp.length - 1}`}</div>

                  <KTSVG path={DownArrowIcon} />
                </div>
              </OutsideClickHandler>
            </div>

            <TagAndCollectionDropdown
              type="Tag"
              onlyAppliedTags={true}
              allItems={tempData}
              appliedItems={tempData}
              selected={isBlockSelected}
              isCreatable
              isEditAndDeletable={true}
              closeDropdown={closeBlock}
            />
          </>
        );
      } else {
        return (
          <div className="w-200px d-flex flex-wrap">
            {temp.map((data, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: TagColors[index].background,
                  color: TagColors[index].color,
                  width: 'fit-content'
                }}
                className="text-nowrap m-1 px-2 py-1 rounded poppins-medium"
              >
                {data}
              </div>
            ))}
          </div>
        );
      }
    }
  } else {
    if (isCombined) {
      return (
        <div className="w-200px d-flex flex-wrap">
          {[constVariables.orders.combined].map((data, index) => (
            <div
              key={index}
              style={{
                backgroundColor: TagColors[index].background,
                color: TagColors[index].color,
                width: 'fit-content'
              }}
              className="text-nowrap m-1 px-2 py-1 rounded poppins-medium"
            >
              {data}
            </div>
          ))}
        </div>
      );
    }
  }
  return null;
};

export default OrderTagsBlock;
