import { gql } from '@apollo/client';

export const REMOVE_PRODUCTS_FROM_DRAFTS = gql`
  mutation removeProductsFromDrafts($input: RemoveProductsFromDrafts!) @api(name: "tiktokAPI") {
    removeProductsFromDrafts(input: $input) {
      id
      success
      message
    }
  }
`;
