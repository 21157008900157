import { FC } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { FlexBox } from 'src/components/atoms';
import { EditColumn, Tabs } from 'src/components/molecules';
import { PageHeader } from 'src/components/oraganisms';

// Types
import { ScreenHeaderProps } from './ScreenHeader.types';

// Styles
import './ScreenHeader.scss';

const ScreenHeader: FC<ScreenHeaderProps> = ({
  breadCrumbsList,
  headerTitle,
  headerButtonText,
  removeExportImport,
  selectedTab,
  tabList,
  openEditColumnDropdown,
  editColumnText,
  editColumnData,
  csvTemplate,
  showCountsOnTab,
  tabCountValue,
  setSelectedTab,
  setOpenEditColumnDropdown,
  setEditColumnData,
  headerButtonClick,
  HeadingExtenstion,
  onlyImport,
  onlyExport,
  orderToggle,
  selectedOrderType,
  changeOrderType,
  isAllSwitchableColumns,
  backButton,
  backbtnHandler
}) => {
  const setOrderTypeIndi = () => {
    if (changeOrderType) {
      changeOrderType('INDIVIDUAL');
    }
  };
  const setOrderTypeComb = () => {
    if (changeOrderType) {
      changeOrderType('COMBINED');
    }
  };
  return (
    <div className="screenHeader">
      <PageHeader
        pageTitle={headerTitle}
        addButton={headerButtonText}
        removeExportImport={removeExportImport}
        onAddButton={headerButtonClick}
        csvTemplate={csvTemplate}
        backButton={backButton}
        backbtnHandler={backbtnHandler}
        // HeadingExtenstion={HeadingExtenstion}
      />
      <FlexBox className="justify-content-between">
        {selectedTab && setSelectedTab && tabList && (
          <div className="d-flex align-items-center w-100 justify-content-between m-b-40">
            <Tabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabList}
              showCount={showCountsOnTab}
              countValue={tabCountValue}
            />
            <div className={`d-flex align-items-center`}>
              {editColumnText && (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    if (setOpenEditColumnDropdown) setOpenEditColumnDropdown(false);
                  }}
                >
                  <div className="position-relative me-3">
                    <div
                      className="text-primary text-btn cursor-pointer"
                      onClick={() => {
                        if (setOpenEditColumnDropdown) setOpenEditColumnDropdown(!openEditColumnDropdown);
                      }}
                    >
                      {editColumnText}
                    </div>
                    <EditColumn
                      show={openEditColumnDropdown ? openEditColumnDropdown : false}
                      columns={editColumnData || []}
                      setEditColumnData={setEditColumnData}
                      allSwitchable={isAllSwitchableColumns}
                    />
                  </div>
                </OutsideClickHandler>
              )}
              {/* {orderToggle ? (
                <div>
                  <div className="orderTypeSH p-2 rounded">
                    <div
                      className={`p-2 order-text rounded rounded ${
                        selectedOrderType === 'INDIVIDUAL' ? 'selectedOrderTypeSH' : ''
                      } `}
                      onClick={setOrderTypeIndi}
                    >
                      Individual Orders
                    </div>
                    <div
                      className={`p-2 order-text rounded rounded ${selectedOrderType === 'COMBINED' ? 'selectedOrderTypeSH' : ''} `}
                      onClick={setOrderTypeComb}
                    >
                      Combined orders
                    </div>
                  </div>
                </div>
              ) : null} */}
            </div>
          </div>
        )}
      </FlexBox>
    </div>
  );
};

export default ScreenHeader;
