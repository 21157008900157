import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';

// Components
import { FlexBox, Loader, SelectionCircle } from 'src/components/atoms';
import { Divider, Dropdown } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { MoveWarehouseIcon, CloseIcon, WarehouseBuilding, DownArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { DeleteAndMoveWarehouseModalProps } from './Modal.types';

// Styles
import './modalStyles.scss';
import OutsideClickHandler from 'react-outside-click-handler';
import { WarehouseOptionItem } from '../../pages/inventory/InventoryManagementPage.type';

const MoveWarehouseModal: FC<DeleteAndMoveWarehouseModalProps> = ({
  warehouseList,
  cancelBtnHandler,
  actionBtnHandler,
  deleteAndMove,
  showDeleteModal,
  isDeleting
}) => {
  useEffect(() => {
    setSelectedMove(false);
    setSelectedDelete(false);
  }, [showDeleteModal]);

  const [selectedMove, setSelectedMove] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState(false);

  const checkUnCheckMove = () => {
    if (selectedMove) {
      setSelectedMove(false);
    } else {
      setSelectedMove(true);
      setSelectedDelete(false);
    }
  };
  const checkUnCheckDelete = () => {
    if (selectedDelete) {
      setSelectedDelete(false);
    } else {
      setSelectedDelete(true);
      setSelectedMove(false);
    }
  };

  const [isWarehouseDropdown, setIsWarehouseDropdown] = useState(false);
  const [selectedWareHouse, setSelectedWareHouse] = useState<WarehouseOptionItem>();
  const openWarehouseDropdown = () => {
    setIsWarehouseDropdown(true);
  };
  const closeWarehouseDropdown = () => {
    setIsWarehouseDropdown(false);
  };

  const selectWarehouse = (data: any) => {
    setSelectedWareHouse(data);
    closeWarehouseDropdown();
  };
  const WarehouseSelector = () => (
    <div className="position-relative ms-5">
      <OutsideClickHandler onOutsideClick={closeWarehouseDropdown}>
        <FlexBox
          className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box ${
            isWarehouseDropdown ? 'dropdown-box-active' : ''
          }`}
          onClick={openWarehouseDropdown}
        >
          {selectedWareHouse ? (
            <FlexBox className="align-items-center">
              <KTSVG svgColor="#706FD3" path={WarehouseBuilding} />
              <div className="ms-2">{selectedWareHouse.name}</div>
            </FlexBox>
          ) : (
            <FlexBox className="align-items-center">
              <KTSVG svgColor="#706FD3" path={WarehouseBuilding} />
              <div className="ms-2">Select Warehouse</div>
            </FlexBox>
          )}
          <div>
            <KTSVG path={DownArrowIcon} />
          </div>
        </FlexBox>
        {warehouseList ? (
          <Dropdown
            className="dropdown-custom-width mt-3"
            data={warehouseList}
            selected={isWarehouseDropdown}
            value={selectedWareHouse?.name}
            onSelect={selectWarehouse}
            closeDropdown={() => setIsWarehouseDropdown(false)}
          />
        ) : null}
      </OutsideClickHandler>
    </div>
  );

  return (
    <div className={clsx('modal-content modal-large-content delete-and-move-modal')}>
      <div className="w-100 position-relative">
        <KTSVG
          path={CloseIcon}
          className="svg-icon cursor-pointer svg-icon-2x position-absolute top-10 end-10"
          onClick={cancelBtnHandler}
        />
        <div
          className="p-t-40 p-b-20 px-4 text-center"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              width: '70px',
              height: '50px'
            }}
          >
            <KTSVG path={MoveWarehouseIcon} className="svg-icon svg-icon-5x my-2" />
          </div>
          <h5 className="modal-title text-center">{constVariables.common.MoveWarehouse.titleForMove}</h5>
          <div className="d-flex">
            <div className="me-3" />
            <div className="helper-text leftPaddingDelMoveModal  fs-16px mt-2">{constVariables.common.MoveWarehouse.moveText}</div>
          </div>
        </div>
        <Divider
          style={{
            color: '#E9E9EB'
          }}
        />
        <div className="mb-6">
          <div className="helper-text leftPaddingDelMoveModal  fs-16px move-modal-select-label">Choose Warehouse</div>
          <WarehouseSelector />
        </div>
        {selectedWareHouse && (
          <>
            <Divider
              style={{
                color: '#E9E9EB'
              }}
            />
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outlined-secondary btn-md"
                data-bs-dismiss="modal"
                onClick={cancelBtnHandler}
              >
                Cancel
              </button>
              <button
                disabled={!selectedWareHouse || isDeleting}
                type="button"
                className="btn btn-primary btn-md"
                onClick={() => selectedWareHouse && deleteAndMove(selectedWareHouse.id)}
              >
                {isDeleting && <Loader type="button" className="h-10px w-10px ms-0" />}
                Move
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MoveWarehouseModal;
