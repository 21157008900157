import { FC, useState, createRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { Dropdown, Portal } from 'src/components/molecules';
import { CustomModal, DeleteConfirmModal } from 'src/components/oraganisms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import useWindowDimensions from 'src/utils/UseWindowDimensions/useWindowDimensions';
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { DefaultImage, ThreeDotsIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

const PARENT_ID = 'LiveProductList';
const LiveshowProductLineItem: FC<any> = ({
  product,
  selectedProduct,
  setSelectedProduct,
  handleDeleteProduct,
  visitedProductIds
}) => {
  const menuIcon = createRef<any>();
  const { width } = useWindowDimensions();
  const [showDropDown, setShowDropDown] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [menuPositions, setMenuPositions] = useState({
    top: 0,
    left: 0
  });

  const closeDropDown = () => {
    setShowDropDown(false);
  };

  const openDropDown = (e) => {
    e.stopPropagation();
    setShowDropDown(true);

    const parentElement = document.getElementById(PARENT_ID);
    if (menuIcon.current && parentElement) {
      const position = menuIcon.current.getBoundingClientRect();
      const parentRect = parentElement.getBoundingClientRect();
      setMenuPositions({
        // top: spaceBelow < 150 ? window.scrollY + top - 80 : window.scrollY + top + 30,
        // left
        top: position.top - parentRect.top,
        left: position.left - parentRect.left - 160
      });
    }
  };

  const selectingMenuItem = (data) => {
    if (data.name === 'Product detail') {
      window.open(`/products/allProducts/edit/${product.productId}`, '_blank');
    }
    if (data.name === 'Remove product') {
      setShowDeleteConfirm(true);
    }
    closeDropDown();
  };

  const getItemClassName = useMemo(() => {
    if (selectedProduct?.productId === product?.productId) {
      return 'active';
    }
    if (product?.isLowStockTriggred) {
      return 'low-stock';
    }
    if (visitedProductIds.includes(product?.productId)) {
      return 'visited';
    }
    return '';
  }, [product?.isComplete, product?.productId, product?.isLowStockTriggred, selectedProduct, visitedProductIds]);

  const menuList = useMemo(() => {
    if (visitedProductIds.includes(product?.productId)) {
      return constVariables.liveShowDashboardProductListDropdownData.map((p) => {
        if (p.id === 2) {
          // @ts-ignore
          return {
            ...p,
            disabled: true
          };
        }
        return p;
      });
    }
    return constVariables.liveShowDashboardProductListDropdownData;
  }, [visitedProductIds, product?.productId]);
  const handleRemoveProduct = () => {
    handleDeleteProduct(product.id.toString());
    closeConfirmModal();
  };

  const closeConfirmModal = () => {
    document.body.style.overflow = 'unset';
    setShowDeleteConfirm(false);
  };

  return (
    <>
      <tr
        className={`product-list-product-detail align-items-center position-relative cursor-pointer ${getItemClassName}`}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        onClick={() => {
          setSelectedProduct(product);
        }}
      >
        <td>
          <div className="product-list-product-main left-section align-items-center">
            <div className="product-image-container">
              <Img
                src={setImageSrc(product?.imageUrl ? generateCompressedImageURL(product.imageUrl, '50') : DefaultImage)}
                placeholderImg={DefaultImage}
                errorImg={DefaultImage}
                width={44}
                height={44}
              />
            </div>
            <FlexBox className="product-name-price-container flex-column align-items-start justify-content-center">
              <span className="product-name poppins-medium">{product.name}</span>
              <FlexBox className="align-items-center">
                <span className="product-price">${product.salePrice}</span>
                <div className="product-reference-id">
                  <span>#{product.productReferenceId}</span>
                </div>
              </FlexBox>
            </FlexBox>
          </div>
        </td>
        <td className="value">
          <span>{product.quantity}</span>
        </td>
        <td className="value">
          <span>{product.noOfOrders}</span>
        </td>
        <td className="value">
          <span>{product.noOfOrdersPaid}</span>
        </td>
        <td className="value">
          <span
            style={{
              paddingRight: '6px'
            }}
          >
            ${product.revenue.toFixed(2)}
          </span>
        </td>
        <td className="value">
          <div ref={menuIcon} className="live-product-item-more value">
            <KTSVG onClick={openDropDown} className={`cursor-pointer ${visible ? '' : 'opacity-0'}`} path={ThreeDotsIcon} />
          </div>
        </td>
        {showDropDown && (
          <Portal id={PARENT_ID}>
            <OutsideClickHandler onOutsideClick={closeDropDown}>
              <div className="position-absolute" style={{ top: menuPositions.top, left: menuPositions.left }}>
                <Dropdown
                  data={menuList}
                  onSelect={selectingMenuItem}
                  selected={showDropDown}
                  className="live-product-menu-dropdown"
                  noScroll={true}
                  closeDropdown={() => setShowDropDown(false)}
                />
              </div>
            </OutsideClickHandler>
          </Portal>
        )}
      </tr>

      {showDeleteConfirm && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showDeleteConfirm} closeModal={closeConfirmModal}>
          <DeleteConfirmModal
            title={constVariables.LiveShow.deleteProduct.title}
            message={constVariables.LiveShow.deleteProduct.message}
            actionBtnTitle="Yes, Remove"
            cancelBtnTitle="Cancel"
            cancelBtnHandler={closeConfirmModal}
            actionBtnHandler={handleRemoveProduct}
          ></DeleteConfirmModal>
        </CustomModal>
      )}
    </>
  );
};

export default LiveshowProductLineItem;
