import React, { useState, useEffect, FC } from 'react';
import { useHistory } from 'react-router';
import clsx from 'clsx';

// Components
import { Pagination } from 'src/components/molecules';
import { InventoryList, EmptyList, PageHeader } from 'src/components/oraganisms';
import ProductFilterChips from 'src/components/oraganisms/ProductList/components/ProductFilterChips';

// Icons
import { NoInventoryIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { InventoryManagementPagePresentationalProps } from './InventoryManagementPage.type';
import { ColumnType } from 'src/components/molecules/Table/Table.types';

const initialInventoryTableColumns: ColumnType[] = [
  { name: 'SKU', status: true },
  { name: 'Location', status: true },
  // { name: 'Incoming', status: true },
  { name: 'Available for sale', status: true },
  { name: 'Committed', status: true },
  { name: 'Low stock alert', status: true },
  { name: 'Stock level', status: true }
];

const InventoryManagementPagePresentational: FC<InventoryManagementPagePresentationalProps> = (props) => {
  const {
    HeadingExtenstion,
    updateInventoryList,
    setInventoryList,
    inventoryListData,
    sortBy,
    setSortBy,
    getInventoryInput,
    setInventoryInput,
    isLoading,
    totalCount,
    selectedWarehouseId,
    inventoryFilters,
    setInventoryFilters,
    handleClearInventoryFilter,
    showFilterChips,
    setShowFilterChips,
    handleUpdateInventoryFilters
  } = props;

  const history = useHistory();

  const [openEditColumnDropdown, setOpenEditColumnDropdown] = useState(false);
  const [editColumnData, setEditColumnData] = useState<ColumnType[]>([]);
  const [activeColumns, setActiveColumns] = useState<ColumnType[]>([]);

  useEffect(() => {
    const tableColumns = localStorage.getItem('inventoryTable');
    if (tableColumns) {
      setEditColumnData(JSON.parse(tableColumns));
    } else {
      setEditColumnData(initialInventoryTableColumns);
    }
  }, []);

  useEffect(() => {
    if (editColumnData?.length > 0) {
      const arr = Array.from(editColumnData);
      editColumnData.map((column) => {
        if (!column.status) {
          arr.splice(arr.indexOf(column), 1);
        }
        return null;
      });
      setActiveColumns([...arr]);
      localStorage.setItem('inventoryTable', JSON.stringify(editColumnData));
    }
  }, [editColumnData]);

  return (
    <div className="p-40" id="tablePage">
      <div className={`width-animation w-100 d-flex flex-column hide-scrollbar`}>
        <PageHeader
          pageTitle={constVariables.InventoryMenu.inventoryManagement}
          addButton={constVariables.InventoryMenu.viewProducts}
          removeExportImport={true}
          isEditFields={true}
          openEditColumnDropdown={openEditColumnDropdown}
          setOpenEditColumnDropdown={setOpenEditColumnDropdown}
          editColumnData={editColumnData}
          setEditColumnData={setEditColumnData}
          onAddButton={() => history.push('/products/allProducts')}
          HeadingExtenstion={HeadingExtenstion}
        />
        <div className="card w-100 mb-0 p-0">
          <div className="p-t-32 p-x-32">
            {!isLoading && totalCount === 0 && !getInventoryInput.filters.searchText && (
              <>
                {showFilterChips && (
                  <ProductFilterChips
                    filters={inventoryFilters}
                    setProductFilters={setInventoryFilters}
                    updateProductFilters={handleUpdateInventoryFilters}
                    handleClearProductFilter={handleClearInventoryFilter}
                  />
                )}
                <EmptyList
                  title={constVariables.emptyDataList.inventory}
                  description={constVariables.emptyDataList.addProductsDescription}
                  addButtonText={constVariables.emptyDataList.inventoryAction}
                  onAddButton={() => history.push('/products/allProducts/add')}
                  icon={NoInventoryIcon}
                />
              </>
            )}
            <InventoryList
              inventoryProductList={inventoryListData}
              setSortBy={setSortBy}
              sortBy={sortBy}
              rapidActionOpen={false}
              isLoading={isLoading}
              totalCount={totalCount}
              selectedWarehouseId={selectedWarehouseId}
              updateInventoryList={updateInventoryList}
              activeColumns={activeColumns}
              setInventoryList={setInventoryList}
              inventoryFilters={inventoryFilters}
              setInventoryFilters={setInventoryFilters}
              getInventoryInput={getInventoryInput}
              setInventoryInput={setInventoryInput}
              handleClearInventoryFilter={handleClearInventoryFilter}
              showFilterChips={showFilterChips}
              setShowFilterChips={setShowFilterChips}
              handleUpdateInventoryFilters={handleUpdateInventoryFilters}
            />
          </div>
          <div className={clsx({ 'display-none': totalCount === 0 && !getInventoryInput.filters.searchText })}>
            <Pagination
              skipCount={getInventoryInput?.pageInfo?.skipCount}
              limitCount={getInventoryInput?.pageInfo?.limitCount}
              setSkipCount={(num: number) => {
                setInventoryInput((prev) => ({
                  ...prev,
                  pageInfo: {
                    ...prev.pageInfo,
                    skipCount: num
                  }
                }));
              }}
              total={totalCount}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(InventoryManagementPagePresentational);
