import { gql } from '@apollo/client/core';

export const GET_LIVE_PRODUCT_ANALYTICS = gql`
  query GetLiveProductAnalytics($id: String!) @api(name: "liveStreamingAPI") {
    getLiveProductAnalytics(id: $id) {
      id
      title
      imageUrl
      isDisplayed
      impressions {
        live
        vod
        total
      }
      ctr {
        live
        vod
        total
      }
      orders {
        live
        vod
        total
      }
      price {
        live
        vod
        total
      }
      revenue {
        live
        vod
        total
      }
      productId
      hasVariants
    }
  }
`;
